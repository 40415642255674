import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import _ from 'lodash';
import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Elastic, Power2, TimelineLite, TweenLite } from 'gsap';
import { IEventInstanceViewModel } from '../AnnualCycleRightMenu';
import {
  EventInstance,
  FieldsOnCalendarRingFragment,
  YearWheelYearWheel,
} from '../../../models/types';
import { WheelMock } from './WheelMock';
import { WheelConstants, WheelUtils } from './utils';
import { H2 } from '../../basic/Structural/typography';
import { Colors, safeInvoke, safeInvokeDeprecated } from '../../common';
import { WheelSummary } from './WheelSummary';
import { MunikumKeys } from '../../common/keys';
import { DebugInfo } from './DebugInfo';
import { YearSelector } from '../YearSelector';

const UNSELECTED_OPACITY = 0.5;
const UNSELECTED_TEXT_OPACITY = 0.5;
const SELECTED_OPACITY = 1;
const HOVER_OPACITY = 1;

export enum WheelToggleMode {
  /**
   * remember months or pies you select
   * we don't deselect already selected months or pies when selecting new months or pies
   */
  REMEMBER = 'REMEMBER',

  /**
   * use control or Cmd(mac) to select multiple months or pies
   * NOTE: find a lib to fix Ctrl, Cmd + different keycodes in different browsers? draft.js build in?
   * @type {string}
   */
  CONTROL_CLICK = 'CONTROL_CLICK',
}

export enum WheelSelectionMode {
  /**
   * Select entire month
   */
  MONTH = 'MONTH',

  /**
   * Select a small pie from one ring in a month
   * @type {string}
   */
  SMALLPIE = 'SMALLPIE',
}

export enum WheelDirection {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export type SelectedMonthsDict = {
  [monthIndex: number]: {
    isEntireMonthSelected: boolean;
    selectedRings: Array<string>; // Array<MyYearWheel.MyYearWheel>;
  };
};

export interface IWheelProps {
  style?: CSSProperties;

  showGrid?: boolean;
  showMock?: boolean;
  showCircleHelpers?: boolean;
  showStateAndProps?: boolean;

  /**
   * Rings to display in wheel. All rings are visible
   */
  rings?: Array<FieldsOnCalendarRingFragment>;

  // hmm.. we dont need subdata in the wheel (yet)
  // when SUBDATA is ready: (has tasks and stuff!)
  // calendars?: { [calendarId: string]: YearWheelRing.Calendar}

  // filtered tasks, use to show summary in middle of wheel
  eventInstances: Array<IEventInstanceViewModel>;

  // year: number;

  /**
   * select entire month or smaller pieces
   */
  selectionMode?: WheelSelectionMode;

  toggleMode?: WheelToggleMode;

  /**
   * Svein-Arild's direction :-)
   */
  direction?: WheelDirection;

  /**
   * we can use this to highlight items in tasklist?
   * @param {number} monthIndex
   * @param {string} month
   */
  onHoverMonth?: (monthIndex: number, month: string, ringId: string) => void;

  /***
   * clear highlight in tasklist
   */
  onHoverMonthLeave?: () => void;

  /**
   * event that tells you what the user has selected in the wheel (months or ringsPerMonth)
   */
  onSelectedChanged?: (selectedMonths: SelectedMonthsDict) => void;

  /**
   * set this to put wheel in controlled mode where you control which months and pies are selected
   */
  selectedMonths?: SelectedMonthsDict;

  startMonth?: string;

  onSelectedYearChanged?: (year: number, year2: number) => void;

  year?: number;
  year2?: number;
}

interface IWheelState {
  selectedMonths: SelectedMonthsDict;

  isControlPressed: boolean;

  direction: WheelDirection;

  isReady: boolean;
  year: number;
  year2: number;
}

interface IMonthSvgTextProps {
  isSelected: boolean;
}

const MonthSvgText = styled.text`
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  font-size: 1.2em;

  @media print {
    font-size: 18pt;
  }

  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: bold;

  cursor: pointer;

  fill: ${(props: IMonthSvgTextProps) =>
    props.isSelected ? Colors.WHITE : Colors.BLACK};
`;

const TextPiePath = styled.path`
  fill-opacity: ${(props: IMonthSvgTextProps) =>
    props.isSelected ? SELECTED_OPACITY : UNSELECTED_TEXT_OPACITY};
  fill: ${(props: IMonthSvgTextProps) =>
    props.isSelected ? Colors.BLUE : Colors.GREY208};

  cursor: pointer;
`;

// this is the svg month pie group
const MonthPieGroup = styled.g`
  //fill-opacity: 0.2;
  cursor: pointer;
`;

const YearSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
`;

interface IRingPiePathProps {
  isMonthSelected: boolean;
  isRingPieSelected: boolean;
  isNothingSelected: boolean;
}

const RingPiePath = styled.path`
  fill-opacity: ${(props: IRingPiePathProps) =>
    props.isRingPieSelected || props.isMonthSelected || props.isNothingSelected
      ? SELECTED_OPACITY
      : UNSELECTED_OPACITY};
  //
  //transition: fill-opacity;
  //
  //&:hover {
  //  fill-opacity: 1;
  //}
`;

const WheelOuterContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
`;

const SummaryContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 30%;
  right: 30%;
  bottom: 30%;
`;
const WheelContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const YearSelectorDiv = styled.div`
  position: absolute;
  top: 25%;
  left: 30%;
  right: 30%;
  bottom: 30%;
`;

interface ISelectedStuff {
  title: JSX.Element | string | null | false;
  description: JSX.Element | string | null | false;
  completedTasks: number;
  totalTasks: number;
}

export function getEmptySelection(
  selectCurrentMonth: boolean,
  currentYear: number,
  rings: ReadonlyArray<FieldsOnCalendarRingFragment>
) {
  let blank2: SelectedMonthsDict = {};
  WheelConstants.monthNames.map((m: string, index: number) => {
    blank2[index] = {
      isEntireMonthSelected: false,
      selectedRings: [],
    };
  });

  if (selectCurrentMonth) {
    // only select current month IF current year is selected..

    if (currentYear === new Date().getFullYear()) {
      const mooo = new Date().getMonth();

      blank2[mooo] = {
        isEntireMonthSelected: true,
        selectedRings: rings.map(ring => {
          return ring.id;
        }), // NOTE: this doesnt work.. async loading of rings..
      };
    }
  }

  // console.log('EMPTY_SELECTION: ', blank2);
  return blank2;
}

/***
 * Wheel
 */
class WheelComp extends React.PureComponent<
  IWheelProps & InjectedIntlProps,
  IWheelState
> {
  public static defaultProps: IWheelProps = {
    direction: WheelDirection.RIGHT,

    selectionMode: WheelSelectionMode.MONTH,
    toggleMode: WheelToggleMode.REMEMBER,

    eventInstances: [],
  };

  private readonly myMonthPieRefs: Array<any>;
  private readonly myTextPieRefs: Array<any>;
  private readonly myRingPieRefs: Array<{ [ringId: string]: any }>;

  private readonly introTimelineRight: TimelineLite;
  private readonly introTimelineLeft: TimelineLite;

  constructor(props: IWheelProps & InjectedIntlProps) {
    super(props);

    this.state = {
      isControlPressed: false,
      direction: props.direction || WheelDirection.RIGHT,
      isReady: false,
      year: this.props.year,
      year2: this.props.year2,
      selectedMonths:
        props.selectedMonths || getEmptySelection(false, -1, props.rings || []),
    };

    let tempArrWithDicts: Array<any> = [];
    for (let i = 0; i < 12; i++) {
      tempArrWithDicts[i] = {};
    }

    this.myMonthPieRefs = [];
    this.myTextPieRefs = [];
    this.myRingPieRefs = tempArrWithDicts;

    this.introTimelineRight = new TimelineLite({
      paused: true,
    });

    this.introTimelineLeft = new TimelineLite({
      paused: true,
    });
  }

  getSelectedStuffStatusText = (): ISelectedStuff => {
    // TODO: we could improve speed here with reselect!

    const { selectionMode, rings, eventInstances } = this.props;
    const { selectedMonths } = this.state;

    const selectedMonthNames: Array<string> = [];
    const selectedMonthNamesShort: Array<string> = [];
    const selectedMonthNamesShortWithSomeOrAllRingsSelected: Array<string> = [];

    let numSelectedPies = 0;
    // let totalSelectedTasksCount = 0;
    // let completedSelectedTasksCount = 0;

    // TODO: it NOTHING is selected, we explicitly select the entire year.. and should return sum of everything? =)

    for (let i = 0; i < 12; i++) {
      if (
        selectedMonths &&
        selectedMonths[i] &&
        selectedMonths[i].isEntireMonthSelected
      ) {
        selectedMonthNames.push(WheelConstants.monthNames[i]);
        selectedMonthNamesShort.push(WheelConstants.monthNamesShort[i]);
        selectedMonthNamesShortWithSomeOrAllRingsSelected.push(
          WheelConstants.monthNamesShort[i]
        );

        numSelectedPies += rings ? rings.length : 0;
      } else if (
        selectedMonths &&
        selectedMonths[i] &&
        selectedMonths[i].selectedRings.length > 0
      ) {
        numSelectedPies += selectedMonths[i].selectedRings.length;
      }

      if (
        selectedMonths &&
        selectedMonths[i] &&
        selectedMonths[i].selectedRings.length > 0
      ) {
        selectedMonthNamesShortWithSomeOrAllRingsSelected.push(
          WheelConstants.monthNamesShort[i]
        );
      }
    }

    // console.log(`RESULT: ${completedSelectedTasksCount} of ${totalSelectedTasksCount} completed. in ${numSelectedPies} pies in ${selectedMonthNames.length} months`);

    const totalSelectedTasksCount: number = eventInstances.length;
    const completedSelectedTasksCount: number = _.filter(
      eventInstances,
      (insta: EventInstance) => {
        return insta.isDone;
      }
    ).length;

    const myVars = {
      br: <br />,
      total: totalSelectedTasksCount,
      completed: completedSelectedTasksCount,
      percentage: (totalSelectedTasksCount !== 0
        ? (completedSelectedTasksCount / totalSelectedTasksCount) * 100.0
        : 0
      ).toFixed(0),
      numSelectedPies: numSelectedPies,
      numSelectedMonths:
        selectedMonthNamesShortWithSomeOrAllRingsSelected.length,
      monthName: selectedMonthNames.length === 1 ? selectedMonthNames[0] : 'NA',
    };

    const retVal: ISelectedStuff = {
      title: (
        <FormattedMessage
          id={'Wheel.unselectedTitle'}
          defaultMessage={'{year}'}
          values={myVars}
        />
      ),
      description: (
        <FormattedMessage
          id={'Wheel.unselectedDescription'}
          defaultMessage={'No months or pies selected'}
          values={myVars}
        />
      ),
      completedTasks: completedSelectedTasksCount,
      totalTasks: totalSelectedTasksCount,
    };

    if (selectionMode === WheelSelectionMode.MONTH) {
      if (selectedMonthNames.length === 0) {
        retVal.description = (
          <FormattedMessage
            id={'Wheel.monthModeUnselectedDescription'}
            defaultMessage={'No months selected'}
            values={myVars}
          />
        );
      }
      if (selectedMonthNames.length === 1) {
        retVal.title = selectedMonthNames[0]; // January
        retVal.description = (
          <FormattedMessage
            id={'Wheel.oneMonthSelectedDescription'}
            defaultMessage={'{numSelectedPies} pies selected in {monthName}'}
            values={myVars}
          />
        );
      } else if (selectedMonthNames.length > 1) {
        retVal.title = selectedMonthNamesShort.join(', '); // Jan, Feb, etc.
        retVal.description = (
          <FormattedMessage
            id={'Wheel.moreThanOneMonthSelectedDescription'}
            defaultMessage={
              '{numSelectedPies} pies selected in {numSelectedMonths} months. {percentage}%'
            }
            values={myVars}
          />
        );
      }
    } else {
      if (selectedMonthNamesShortWithSomeOrAllRingsSelected.length === 0) {
        retVal.description = (
          <FormattedMessage
            id={'Wheel.pieModeUnselectedDescription'}
            defaultMessage={'No pies selected'}
            values={myVars}
          />
        );
      } else {
        retVal.title = selectedMonthNamesShortWithSomeOrAllRingsSelected.join(
          ', '
        );
        retVal.description = (
          <FormattedMessage
            id={'Wheel.pieModeSomePiesSelectedInfo'}
            defaultMessage={
              '{numSelectedPies} pies selected in {numSelectedMonths} months'
            }
            values={myVars}
          />
        );
      }
    }

    // console.log('RETVALRETVAL ', retVal);
    // console.log('MYVARS', myVars);
    return retVal;
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);

    this.introTimelineRight.kill();
    this.introTimelineLeft.kill();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);

    this.introTimelineRight
      .set(this.myMonthPieRefs, {
        scale: 0.4,
        rotation: 0,
        opacity: 0,
        transformOrigin: '50% 50%',
      })
      .staggerTo(
        this.myMonthPieRefs,
        1,
        {
          scale: 1,
          opacity: 1,
          ease: Elastic.easeInOut.config(1, 0.7), // first == how much to overshoot, second == smaller is more bounces
        },
        0.1, // stagger
        'stagger-test-post',
        () => {
          // console.log('stagger IN RIGHT complete');
          this.setState({
            isReady: true,
          });
        }
      )
      .timeScale(2.8);

    this.introTimelineLeft
      .set(this.myMonthPieRefs, {
        scale: 0.4,
        rotation: 0,
        opacity: 0,
        transformOrigin: '50% 50%',
      })
      .staggerTo(
        this.myMonthPieRefs,
        1,
        {
          scale: 1,
          opacity: 1,
          ease: Elastic.easeInOut.config(1, 0.7), // first == how much to overshoot, second == smaller is more bounces
        },
        0.1, // stagger // TODO: draw in opposite order in render? or reverse refs here? and remove -?
        'stagger-test-post',
        () => {
          // console.log('stagger IN RIGHT complete');
          this.setState({
            isReady: true,
          });
        }
      )
      .timeScale(2.8);

    if (this.state.direction === WheelDirection.RIGHT) {
      this.introTimelineRight.play();
    } else {
      this.introTimelineLeft.play();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IWheelProps) {
    // console.log('Wheel.willReceive next,this', nextProps, this.props);
    if (nextProps.year2 !== this.state.year2) {
      this.setState({
        year2: nextProps.year2,
      });
    }

    if (
      nextProps.direction !== undefined &&
      nextProps.direction !== this.state.direction
    ) {
      if (this.state.isReady) {
        // console.log('SET DIRECTION');
        this.setState(
          {
            direction: nextProps.direction,
          },
          () => {
            // NOTE: here are some issues...:
            // we change direction, that cause a re-render -> pies are rendered in opposite direction!
            // -> refs are set to new dom-elements
            // -> I think gsap's timelines and tweens gets messed up... maybe the store transformOringin
            // somewhere globally? or maybe its calculated when elements are not in dom, choosing viewports 50%?
            //

            // this.fadeoutWheel();

            this.introduceWheel();
            // this.introTimeline.play();
          }
        );
      } else {
        // TODO: this isn't good enough... we need to delay set direction state until animation is done
        // or else we risk loosing refs when comp re-renders!
        // for now thw button just wont work if its animating..
        console.warn(
          'cannot change direction while animating.. todo: fix later...'
        );
      }
    }

    // TODO: compare selected optimize..
    if (
      nextProps.selectedMonths !== undefined &&
      nextProps.selectedMonths !== this.state.selectedMonths
    ) {
      if (
        nextProps.selectedMonths === undefined ||
        _.keys(nextProps.selectedMonths).length < 12
      ) {
        throw Error('Invalid selectedMonths');
      }

      // console.log('set selected months');
      this.setState({
        selectedMonths: nextProps.selectedMonths,
      });
    }
  }

  public introduceWheel = () => {
    // console.log('INTRO wheel ' + this.state.isReady.toString());

    if (this.state.isReady) {
      this.setState(
        {
          // animationState: 'in',
          isReady: false,
        },
        () => {
          if (this.state.direction === WheelDirection.RIGHT) {
            if (this.introTimelineRight) {
              this.introTimelineRight.play(0);
            }
          } else {
            if (this.introTimelineLeft) {
              this.introTimelineLeft.play(0);
            }
          }
        }
      );
    }
  };

  protected handleKeyDown = (e: any) => {
    // console.log('key down: ' + e.which + ', meta: ' + e.metaKey);
    if (e.which === MunikumKeys.CONTROL || e.metaKey === true) {
      this.setState({
        isControlPressed: true,
      });
    }
  };

  protected handleKeyUp = (e: any) => {
    // console.log('key up: ' + e.which + ', meta: ' + e.metaKey);
    // TODO: bug? how to detect cmd properly on mac?
    if (
      e.which === MunikumKeys.CONTROL ||
      (e.metaKey === false && this.state.isControlPressed)
    ) {
      this.setState({
        isControlPressed: false,
      });
    }
  };

  handleEnterMonth = (
    monthIndex: number,
    ringId: string
    // elementId: string
  ) => {
    if (!this.state.isReady) {
      return; // test no hover when animating
    }

    if (this.props.selectionMode === WheelSelectionMode.MONTH) {
      // if (!this.state.selectedMonths[monthIndex].isEntireMonthSelected) {
      TweenLite.to(this.myMonthPieRefs[monthIndex], 0.4, {
        scale: 1.05,
        // fillOpacity: HOVER_OPACITY,
        transformOrigin: '50% 50%',
        ease: Power2.easeOut,
      });
      // }
    } else if (this.props.selectionMode === WheelSelectionMode.SMALLPIE) {
      if (ringId === WheelConstants.outerRingId) {
        TweenLite.to(this.myTextPieRefs[monthIndex], 0.4, {
          scale: 1.05,
          // fillOpacity: HOVER_OPACITY,
          transformOrigin: '50% 50%',
          ease: Power2.easeOut,
        });
      } else {
        TweenLite.to(this.myRingPieRefs[monthIndex][ringId], 0.4, {
          scale: 1.05,
          // fillOpacity: HOVER_OPACITY,
          transformOrigin: '50% 50%',
          ease: Power2.easeOut,
        });
      }
    }

    safeInvokeDeprecated(
      this.props.onHoverMonth,
      monthIndex,
      WheelConstants.monthNames[monthIndex],
      ringId
    );
  };

  handleLeaveMonth = (monthIndex: number, ringId: string) => {
    if (!this.state.isReady) {
      return; // test no hover when animating
    }

    if (this.props.selectionMode === WheelSelectionMode.MONTH) {
      // if (!this.state.selectedMonths[monthIndex].isEntireMonthSelected) {
      TweenLite.to(this.myMonthPieRefs[monthIndex], 0.3, {
        scale: 1.0,
        // fillOpacity: this.state.selectedMonths[monthIndex].isEntireMonthSelected
        //   ? SELECTED_OPACITY
        //   : UNSELECTED_OPACITY,
        transformOrigin: '50% 50%',
        ease: Power2.easeOut,
      });
      // }
    } else {
      if (ringId === WheelConstants.outerRingId) {
        TweenLite.to(this.myTextPieRefs[monthIndex], 0.3, {
          scale: 1.0,
          // fillOpacity: this.state.selectedMonths[monthIndex]
          //   .isEntireMonthSelected
          //   ? SELECTED_OPACITY
          //   : UNSELECTED_OPACITY,
          transformOrigin: '50% 50%',
          ease: Power2.easeOut,
        });
      } else {
        TweenLite.to(this.myRingPieRefs[monthIndex][ringId], 0.3, {
          scale: 1.0,
          // fillOpacity:
          //   this.state.selectedMonths[monthIndex].selectedRings.indexOf(
          //     ringId
          //   ) > -1
          //     ? SELECTED_OPACITY
          //     : UNSELECTED_OPACITY,
          transformOrigin: '50% 50%',
          ease: Power2.easeOut,
        });
      }
    }

    safeInvokeDeprecated(
      this.props.onHoverMonthLeave,
      monthIndex,
      WheelConstants.monthNames[monthIndex],
      ringId
    );
  };

  handleClickMonthText = (monthIndex: number) => {
    if (!this.state.isReady) {
      return; // test no hover when animating
    }

    // console.log('click text ' + monthIndex, this.props.toggleMode);

    const { selectionMode, toggleMode } = this.props;
    let temp = _.clone(this.state.selectedMonths);

    if (selectionMode === WheelSelectionMode.MONTH) {
      temp[monthIndex].isEntireMonthSelected = !this.state.selectedMonths[
        monthIndex
      ].isEntireMonthSelected;

      if (
        toggleMode === WheelToggleMode.CONTROL_CLICK &&
        !this.state.isControlPressed
      ) {
        for (let i = 0; i < 12; i++) {
          if (i !== monthIndex) {
            temp[i].isEntireMonthSelected = false;
          }
        }
      }
    } else if (this.props.selectionMode === WheelSelectionMode.SMALLPIE) {
      if (
        toggleMode === WheelToggleMode.CONTROL_CLICK &&
        !this.state.isControlPressed
      ) {
        // select month and all pies, deselect other pies and rings
        if (temp[monthIndex].isEntireMonthSelected) {
          // deselect ALL
          temp = getEmptySelection(false, -1, this.props.rings || []);
        } else {
          temp = getEmptySelection(false, -1, this.props.rings || []);
          temp[monthIndex].isEntireMonthSelected = true;
          temp[monthIndex].selectedRings =
            (this.props.rings &&
              this.props.rings.map((ring: YearWheelYearWheel) => ring.id)) ||
            [];
        }
      } else {
        if (this.state.selectedMonths[monthIndex].isEntireMonthSelected) {
          temp[monthIndex].isEntireMonthSelected = false;
          temp[monthIndex].selectedRings = [];
        } else {
          temp[monthIndex].isEntireMonthSelected = true;
          temp[monthIndex].selectedRings =
            (this.props.rings &&
              this.props.rings.map((ring: YearWheelYearWheel) => ring.id)) ||
            [];
        }
      }
    }

    this.setState(
      {
        selectedMonths: temp,
      },
      () => {
        safeInvokeDeprecated(
          this.props.onSelectedChanged,
          this.state.selectedMonths
        );
      }
    );
  };

  handleClickRingPie = (monthIndex: number, ring: YearWheelYearWheel) => {
    if (!this.state.isReady) {
      return; // test no hover when animating
    }

    let temp = _.clone(this.state.selectedMonths);
    const { selectionMode, toggleMode } = this.props;

    // console.log('toggleMode', toggleMode);

    if (selectionMode === WheelSelectionMode.MONTH) {
      temp[monthIndex].isEntireMonthSelected = !temp[monthIndex]
        .isEntireMonthSelected;

      // deselect other months if needed:
      if (
        toggleMode === WheelToggleMode.CONTROL_CLICK &&
        !this.state.isControlPressed
      ) {
        for (let i = 0; i < 12; i++) {
          if (i !== monthIndex) {
            temp[i].isEntireMonthSelected = false;
          }
        }
      }

      // reset selected rings if selectionMode=MONTH:
      for (let i = 0; i < 12; i++) {
        temp[i].selectedRings = []; // empty array means every ring is checked
      }
    } else if (this.props.selectionMode === WheelSelectionMode.SMALLPIE) {
      if (
        toggleMode === WheelToggleMode.CONTROL_CLICK &&
        !this.state.isControlPressed
      ) {
        // only select current pie
        const isPieSelected =
          temp[monthIndex].selectedRings.indexOf(ring.id) > -1;
        temp = getEmptySelection(false, -1, this.props.rings || []);
        temp[monthIndex].selectedRings = isPieSelected ? [] : [ring.id];

        if (this.props.rings && this.props.rings.length === 1) {
          temp[monthIndex].isEntireMonthSelected = true;
        }
      } else {
        // if (toggleMode === WheelToggleMode.REMEMBER) {
        // toggle selected pie, set entireSelected to true if count == ringcount?

        const selectedRings = temp[monthIndex].selectedRings.slice();
        const isSelectedAlready = selectedRings.indexOf(ring.id) > -1;

        if (isSelectedAlready) {
          selectedRings.splice(selectedRings.indexOf(ring.id), 1);
        } else {
          selectedRings.push(ring.id);
        }

        temp[monthIndex].selectedRings = selectedRings;
        temp[monthIndex].isEntireMonthSelected =
          (this.props.rings &&
            selectedRings.length === this.props.rings.length) ||
          false;
      }
    }

    this.setState(
      {
        selectedMonths: temp,
      },
      () => {
        // this.applyAnimation();
        safeInvokeDeprecated(
          this.props.onSelectedChanged,
          this.state.selectedMonths
        );
      }
    );
  };

  renderMonthPies() {
    // console.log('render month pies');
    const { rings } = this.props;
    const { direction } = this.state;

    if (!rings) {
      return null;
    }

    const monthPies: { [byId: number]: any } = {};
    const depthPerRing = WheelConstants.totalRingDepth / rings.length;

    let startAngle = WheelConstants.degreesGutterHalf;
    let endAngle = startAngle + WheelConstants.degreesPerMonth;

    if (direction === WheelDirection.LEFT) {
      startAngle =
        360 - WheelConstants.degreesGutterHalf - WheelConstants.degreesPerMonth;
      endAngle = startAngle + WheelConstants.degreesPerMonth;
    }

    // check if NOTHING is selected:
    let isNothingSelected = true;
    for (let i = 0; i < 12; i++) {
      if (
        this.state.selectedMonths[i] !== undefined &&
        (this.state.selectedMonths[i].isEntireMonthSelected ||
          (this.state.selectedMonths[i].selectedRings !== undefined &&
            this.state.selectedMonths[i].selectedRings.length > 0))
      ) {
        isNothingSelected = false;
        break;
      }
    }

    // TEXT STUFF:
    const innerTextRadius = WheelConstants.maxRadius + 15;
    const textDepth = 50;

    let monster = 0;

    if (this.props.startMonth !== undefined) {
      monster = parseInt(this.props.startMonth, 10);
    }

    for (let pieCount = 0; pieCount < 12; pieCount++) {
      let monthIndex = monster; // <= need this to capture monthIndex for calling eventHandlers later..
      const month = WheelConstants.monthNames[monthIndex];

      // START

      // TEXT PIE:
      const textPiePath = WheelUtils.describePie(
        WheelConstants.cx,
        WheelConstants.cy,
        WheelConstants.maxRadius,
        textDepth,
        startAngle,
        endAngle
      );

      const textArcData = WheelUtils.makeArc(
        WheelConstants.cx,
        WheelConstants.cy,
        innerTextRadius,
        startAngle,
        endAngle
      );

      // reversed arc to get text on correct side
      const textPathD = [
        'M',
        textArcData.end.x,
        textArcData.end.y,
        'A',
        innerTextRadius,
        innerTextRadius,
        0,
        textArcData.largeArcFlag,
        1,
        textArcData.start.x,
        textArcData.start.y,
      ].join(' ');

      const isMonthSelected =
        this.state.selectedMonths &&
        this.state.selectedMonths[monthIndex] &&
        this.state.selectedMonths[monthIndex].isEntireMonthSelected;

      const textPie = (
        <g
          id={'text_g_' + pieCount}
          key={'text_g_' + pieCount}
          onClick={() => {
            this.handleClickMonthText(monthIndex);
          }}
          onMouseEnter={() => {
            this.handleEnterMonth(
              monthIndex,
              WheelConstants.outerRingId
              // 'text_g_' + pieCount
            );
          }}
          onMouseLeave={() =>
            this.handleLeaveMonth(monthIndex, WheelConstants.outerRingId)
          }
          ref={ref => (this.myTextPieRefs[monthIndex] = ref)}
        >
          <TextPiePath
            id={'text_pie_' + monthIndex}
            key={'text_pie_' + monthIndex}
            d={textPiePath}
            isSelected={isMonthSelected}
          />
          <path
            id={'text_path_' + monthIndex}
            d={textPathD}
            // stroke={'red'}
            // strokeWidth={1}
            fill={'transparent'}
          />
          <MonthSvgText isSelected={isMonthSelected}>
            <textPath
              href={'#text_path_' + monthIndex}
              startOffset={'50%'}
              textAnchor={'middle'}
              method={'align'}
              // side="right"
            >
              {month}
            </textPath>
          </MonthSvgText>
        </g>
      );

      // RING PIES:

      let ringInnerRadius = WheelConstants.midRadius;
      const ringPies: Array<any> = [];
      // const isMonthSelected = this.state.selectedMonths[monthIndex]
      //   .isEntireMonthSelected;

      // NOTE: we could sort rings by order here (and memoize)?

      rings.map((ring: YearWheelYearWheel, ringIndex: number) => {
        // console.log('RING: ' + ring.title);

        const ringPiePathData = WheelUtils.describePie(
          WheelConstants.cx,
          WheelConstants.cy,
          ringInnerRadius,
          depthPerRing,
          startAngle,
          endAngle
        );

        const isRingPieSelected =
          this.state.selectedMonths &&
          this.state.selectedMonths[monthIndex] &&
          this.state.selectedMonths[monthIndex].selectedRings.indexOf(ring.id) >
            -1;
        const path = (
          <RingPiePath
            id={'ringPie_' + monthIndex + '_' + ringIndex}
            key={'ringPie_' + ringIndex}
            d={ringPiePathData}
            // stroke={'black'}
            fill={ring.color}
            fillOpacity={1} // TODO: disabled
            onClick={() => this.handleClickRingPie(monthIndex, ring)}
            onMouseEnter={() =>
              this.handleEnterMonth(
                monthIndex,
                ring.id
                // 'ringPie_' + monthIndex + '_' + ringIndex
              )
            }
            onMouseLeave={() => this.handleLeaveMonth(monthIndex, ring.id)}
            isMonthSelected={isMonthSelected}
            isRingPieSelected={isRingPieSelected}
            isNothingSelected={isNothingSelected}
            ref={(pathRef: any) =>
              (this.myRingPieRefs[monthIndex][ring.id] = pathRef)
            }
          />
        );

        ringPies.push(path);

        ringInnerRadius = ringInnerRadius + depthPerRing;
      });

      const monthPie = (
        <MonthPieGroup
          id={'monthPie_' + monthIndex}
          key={'monthPie_' + monthIndex}
          className={'monthPie'}
          ref={(path: any) => (this.myMonthPieRefs[monthIndex] = path)}
        >
          {textPie}
          {ringPies}
        </MonthPieGroup>
      );

      monthPies[monthIndex] = monthPie;

      // console.log('MONTH: ' + month + ' startAngle: ' + startAngle + ' endAngle: ' + endAngle);
      if (direction === WheelDirection.RIGHT) {
        startAngle =
          startAngle +
          WheelConstants.degreesPerMonth +
          WheelConstants.degreesGutterHalf +
          WheelConstants.degreesGutterHalf;
        endAngle = startAngle + WheelConstants.degreesPerMonth;
      } else {
        startAngle =
          startAngle -
          WheelConstants.degreesPerMonth -
          WheelConstants.degreesGutterHalf -
          WheelConstants.degreesGutterHalf;
        endAngle = startAngle + WheelConstants.degreesPerMonth;
      }

      // END

      monster++;

      if (monster === 12) {
        monster = 0;
      }
    }

    return _.values(monthPies);
  }

  render() {
    // console.log(this.state.year, this.state.year2);
    const {
      style,
      showGrid,
      showMock,
      showCircleHelpers,
      showStateAndProps,
    } = this.props;

    // console.log('render Wheel', this.props, this.state);

    /**
     * IE 11 SVG scale known BUG:
     * https://caniuse.com/#search=svg
     * - we'll just set fixed height for now... then we'll fix it later if we need different sizes...
     * - IE 11 sucks...
     */
    const info = this.getSelectedStuffStatusText();

    const svg = (
      <svg
        id="WheelLayerGroup"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        // dont know why but this fixed scaling in ie11
        preserveAspectRatio="xMidYMin slice"
        x="0"
        y="15"
        // preserveAspectRatio={'xMidYMid meet'} // set to none will scale width 100% in IE 11...
        // OMFG!!: this seems to work in IE11!: (DON't TOUCH THIS WOOOOOOOO ;-) )
        style={{ width: '100%', maxWidth: '100%', height: '100%' }}
      >
        {(showGrid || showCircleHelpers) && (
          <DebugInfo
            showCircle={showCircleHelpers || false}
            showGrid={showGrid || false}
          />
        )}
        {showMock && <WheelMock />}
        {this.renderMonthPies()}
        {/*{this.state.hoveredId && <use id={'use'} href={'#' + this.state.hoveredId} />}*/}
      </svg>
    );

    const myWheel = (
      <WheelOuterContainer>
        <WheelContainer>
          {svg}

          <SummaryContainer>
            <div style={{ marginBottom: '-2.5em' }}>
              <YearSelector
                onYearChanged={(year: number, year2?: number) => {
                  if (year !== this.state.year) {
                    this.setState(
                      {
                        year: year,
                        year2: year2,
                      },
                      () => {
                        safeInvoke(
                          this.props.onSelectedYearChanged,
                          this.state.year,
                          this.state.year2
                        );
                      }
                    );
                  }
                }}
                year={this.state.year}
                year2={this.state.year2}
              />
            </div>
            <div style={{ marginTop: '-.5em' }}>
              <WheelSummary
                title={info.title}
                completed={info.completedTasks}
                count={info.totalTasks}
              />
            </div>
          </SummaryContainer>
        </WheelContainer>
      </WheelOuterContainer>
    );

    return showStateAndProps ? (
      <>
        <div style={{ display: 'flex' }} id={'remove me'}>
          <div id={'remove me'} style={{ width: '425px' }}>
            {myWheel}
          </div>
          <div
            style={{
              backgroundColor: 'pink',
              fontFamily: 'Courier new',
              fontSize: '10px',
              padding: '4px',
            }}
          >
            <H2>state</H2>
            <div style={{ padding: '4px' }}>
              <pre>{JSON.stringify(this.state, null, 2)}</pre>
            </div>
          </div>
          <div
            style={{
              backgroundColor: 'pink',
              fontFamily: 'Courier new',
              fontSize: '10px',
              padding: '4px',
            }}
          >
            <H2>props</H2>
            <div>
              <pre>{JSON.stringify(this.props, null, 2)}</pre>
            </div>
          </div>
        </div>
      </>
    ) : (
      myWheel
    );
  }
}

export const Wheel = injectIntl(WheelComp);
