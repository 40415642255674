import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { defineMessages, injectIntl, InjectedIntlProps } from 'react-intl';
import {
  BackendLocaleMap,
  localeSelector,
  setLocaleActionCreator,
  SupportedUserLocale,
  SupportedUserLocaleNames,
} from '../../../modules/settings/settings';
import { safeInvokeDeprecated } from '../../common';
import { RootState } from '../../../modules/rootReducer';
import {
  SelectMode,
  UltimateDropdown,
} from '../../basic/DropDownSelectUltimate/UltimateDropdown';
import _ from 'lodash';
import { MenuItem } from '../../basic/Menu';
import { IconBaseProps } from 'react-icons/src/iconBase';
import { commonMessages } from '../commonMessages';
import gql from 'graphql-tag';

interface LangItem {
  id: string;
  title: string;
  backendKey: string;
}

const FlagDropdown = UltimateDropdown.ofType<LangItem>();
const no = require('./no.png') as string;
const en = require('./gb.png') as string;

const messages = defineMessages({
  changeLanguage: {
    id: 'languageselector.changelanguage',
    defaultMessage: 'Language settings',
  },
});

export interface ILanguageSelectorProps {
  activeLocale: SupportedUserLocale;
  onChangeLocale?: (locale: SupportedUserLocale) => void;
}

const EnFlag = (props: IconBaseProps): JSX.Element => <img src={en} />;
const NoFlag = (props: IconBaseProps): JSX.Element => <img src={no} />;

const myItems = _.keys(SupportedUserLocaleNames).map(key => ({
  id: key,
  title: SupportedUserLocaleNames[key],
  backendKey: BackendLocaleMap[key],
}));

export const GET_LOCALE_QUERY = gql`
  query GetLocale {
    me {
      id
      name
      description
      jobTitle
      department
      language
      kostraFunctions {
        id
      }
    }
  }
`;

export const UPDATE_LOCALE = gql`
  mutation UpdateLocale($input: UpdateMeInput!) {
    updateMe(input: $input) {
      id
      language
    }
  }
`;

// class QueryLocale extends Query<GetLocaleQuery, GetLocaleVariables> {}
// class UpdateLocale extends Mutation<UpdateLocaleMutation, UpdateLocaleVariables> {}

class LanguageSelectorComp extends React.PureComponent<
  ILanguageSelectorProps & InjectedIntlProps,
  {}
> {
  // handleChangeLocale = (selectedKey: string) => {
  //   safeInvoke(this.props.onChangeLocale, selectedKey);
  // };

  constructor(props: any) {
    super(props);

    // this.state = {
    //   xLocale: 'nn'
    // }
  }

  render() {
    const { intl, activeLocale } = this.props;

    // const myRealLocale = (data && data.me && data.me.language) ? FrontendLocaleMap[data.me.language] : 'nn';

    // const activeLocale = getLocaleHelperFromBackend(data && data.me && data.me.language || '');
    const mySelected = myItems.filter(c => c.id === activeLocale); // intl.locale.toLowerCase());
    // console.log('RNDR q', mySelected, intl.locale.toLowerCase(), myRealLocale);

    return (
      <FlagDropdown
        label={intl.formatMessage(messages.changeLanguage)}
        defaultText={'Choose your language...'}
        items={myItems}
        selectedItems={mySelected}
        leftIcon={(props: IconBaseProps) => {
          return mySelected.length === 1 && mySelected[0].id === 'en' ? (
            <img src={en} />
          ) : (
            <img src={no} />
          );
        }}
        onSelected={(item: LangItem) => {
          safeInvokeDeprecated(this.props.onChangeLocale, item.id);
        }}
        selectMode={SelectMode.SINGLE}
        canDeselect={false}
        itemRenderFunc={(item, props) => {
          // console.log('x', item, props);

          return (
            <MenuItem
              key={props.key}
              onClick={() => {
                props.handleClick();
                // this.handleChangeLocale(item.id);
              }}
              text={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    // width: '12em'
                  }}
                >
                  <div
                    style={
                      {
                        // fontWeight: props.isSelected ? 700 : 500
                      }
                    }
                  >
                    {item.title}
                  </div>
                  <div
                    style={{
                      fontSize: '.75em',
                      color: props.isActive ? '#fff' : 'grey',
                    }}
                  >
                    {props.isSelected
                      ? '(' + intl.formatMessage(commonMessages.selected) + ')'
                      : ''}
                  </div>
                </div>
              }
              leftIcon={item.id === 'en' ? EnFlag : NoFlag}
              // leftIcon={props.isSelected ? MunikumIcons.LeftCheck : undefined}
            />
          );
        }}
      />
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: {}
): ILanguageSelectorProps => ({
  activeLocale: localeSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({
  onChangeLocale: (locale: SupportedUserLocale) => {
    dispatch(setLocaleActionCreator({ locale: locale }));

    // TODO: also add MUTATION here to save to Graphql! (or do that in redux module)
  },
});

export const LanguageSelectorLang = injectIntl(LanguageSelectorComp);

/**
 * ConnectedLanguageSelector is a ready to use dropdown connected to redux (settings.locale)
 */
export const LanguageSelector = connect<ILanguageSelectorProps, {}, {}>(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSelectorLang);
