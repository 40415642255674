import { LocaleObject } from 'yup';

export const enYupLocale: LocaleObject = {
  mixed: {
    default: '${path} is invalid',
    required: '${path} is a required field',
    oneOf: '${path} must be one of the following values: ${values}',
    notOneOf: '${path} must not be one of the following values: ${values}',
  },
  string: {
    min: '${path} must be at least ${min} characters',
    max: '${path} must be at most ${max} characters',
    matches: '${path} must match the following: "${regex}"',
    email: '${path} must be a valid email',
    url: '${path} must be a valid URL',
    trim: '${path} must be a trimmed string',
    lowercase: '${path} must be a lowercase string',
    uppercase: '${path} must be a upper case string',
  },
  number: {
    min: '${path} must be greater than or equal to ${min}',
    max: '${path} must be less than or equal to ${max}',
    lessThan: '${path} must be less than ${less}',
    moreThan: '${path} must be greater than ${more}',
    positive: '${path} must be a positive number',
    negative: '${path} must be a negative number',
    integer: '${path} must be an integer',
  },
  boolean: {},
  bool: {},
  date: {
    min: '${path} field must be later than ${min}',
    max: '${path} field must be at earlier than ${max}',
  },
  array: {
    min: '${path} field must have at least ${min} items',
    max: '${path} field must have less than or equal to ${max} items',
  },
  object: {
    noUnknown:
      '${path} field cannot have keys not specified in the object shape',
  },
};

export const nnYupLocale: LocaleObject = {
  mixed: {
    default: '${path} er ugyldig',
    required: '${path} er eit obligatorisk felt',
    oneOf: '${path} må være ein av følgande verdiar: ${values}',
    notOneOf: '${path} kan ikkje være ein av følgande verdiar: ${values}',
  },
  string: {
    min: '${path} må være minst ${min} tegn',
    max: '${path} kan ikkje være meir enn ${max} tegn',
    matches: '${path} må samsvare med: "${regex}"',
    email: '${path} må være ein gyldig e-post',
    url: '${path} må være ein gyldig URL',
    trim: '${path} kan ikkje byrje eller slutte med mellomrom',
    lowercase: '${path} må være små bokstavar',
    uppercase: '${path} må være store bokstavar',
  },
  number: {
    min: '${path} må være større enn eller lik ${min}',
    max: '${path} må være mindre enn eller lik ${max}',
    lessThan: '${path} må være mindre enn ${less}',
    moreThan: '${path} må være større enn ${more}',
    positive: '${path} må være eit positivt tal',
    negative: '${path} må være eit negativt tal',
    integer: '${path} må være eit heiltal',
  },
  boolean: {},
  bool: {},
  date: {
    min: '${path} feltet må være etter ${min}',
    max: '${path} feltet må være tidligare enn  ${max}',
  },
  array: {
    min: '${path} feltet må ha minst ${min} element',
    max: '${path} feltet må ha mindre enn eller lik ${max} element',
  },
  object: {
    noUnknown:
      '${path} feltet kan ikkje ha namn som ikkje er definert på objektet',
  },
};

export const nbYupLocale: LocaleObject = {
  mixed: {
    default: '${path} er ugyldig',
    required: '${path} er et obligatorisk felt',
    oneOf: '${path} må være en av følgende verdier: ${values}',
    notOneOf: '${path} kan ikke være en av følgende verdier: ${values}',
  },
  string: {
    min: '${path} må være minst ${min} tegn',
    max: '${path} kan ikke være mer enn ${max} tegn',
    matches: '${path} må samsvare med: "${regex}"',
    email: '${path} må være en gyldig e-post',
    url: '${path} må være en gyldig URL',
    trim: '${path} kan ikke begynne eller slutte med mellomrom',
    lowercase: '${path} må være små bokstaver',
    uppercase: '${path} må være store bokstaver',
  },
  number: {
    min: '${path} må være større enn eller lik ${min}',
    max: '${path} må være mindre enn eller lik ${max}',
    lessThan: '${path} må være mindre enn ${less}',
    moreThan: '${path} må være større enn ${more}',
    positive: '${path} må være et positivt tall',
    negative: '${path} må være et negativt tall',
    integer: '${path} må være et heltal',
  },
  boolean: {},
  bool: {},
  date: {
    min: '${path} feltet må være etter ${min}',
    max: '${path} feltet må være tidligere enn  ${max}',
  },
  array: {
    min: '${path} feltet må ha minst ${min} element',
    max: '${path} feltet må ha mindre enn eller lik ${max} element',
  },
  object: {
    noUnknown:
      '${path} feltet kan ikke ha navn som ikkee er definert på objektet',
  },
};
