import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Colors } from '../common';

const AvatarDiv = styled.div`
  background-color: ${Colors.RED};
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;

  color: #fff;

  width: 1.85em; // 25px; thomas changed to em so we can reuse component in smaller size when printing
  height: 1.85em; // 25px;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Lato, sans-serif;
  font-size: 0.75em;
  font-weight: 300;
  text-transform: uppercase;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface IAvatarProps {
  name: string;
  style?: CSSProperties;
  tooltip?: JSX.Element | string;
}

function splitName(name: string) {
  const temp = name
    .split(' ')
    .map((n, i, a) => (i === 0 || i + 1 === a.length ? n[0] : null))
    .join('');
  return temp;
}

export const Avatar: React.FunctionComponent<IAvatarProps> = (
  props: IAvatarProps
) => {
  return <AvatarDiv style={props.style}>{splitName(props.name)}</AvatarDiv>;
};
