import * as React from 'react';
import { CSSProperties } from 'react';
import * as marked from 'marked';
import { Renderer } from 'marked';
import './MarkdownPreview.css';
import styled from 'styled-components';
import { Colors } from '../../common';

export enum RenderMode {
  /**
   * Only render SIMPLe markdown used in our discussions, comments, answers etc.
   * No support for header tags, advanced markdown. ONLY simple stuff as paragraphs, bold, italic
   *
   * NOTE: don't use this?
   */
  Simple,

  /**
   * Default ,full markdown rendering provided by marked lib with customizations
   */
  Full,
}

export interface IMarkdownPreviewProps {
  /**
   * raw markdown to be rendered as HTML
   */
  rawMarkdown: string;

  /**
   * add target="_blank" to all links? default = true
   */
  addBlankTarget?: boolean;

  /**
   * Style on markdown container
   */
  style?: CSSProperties;

  renderMode: RenderMode;
}

export interface IMarkdownPreviewState {
  /**
   * need to store this in state to apply render options on marked Renderer component..
   */
  addBlankTarget: boolean;
}

/**
 * Default markdown container, can be styled by consumer
 */
const RenderedMarkdownContainer = styled.div`
  font-family: Lato, sans-serif;
  font-size: 1em;
  color: ${Colors.BLACK};

  //background-color: orange;
`;

/**
 * @deprecated dont use this
 * A component to render raw markdown as HTML
 * - custom link renderer adding target = _blank
 * - two rendering modes, simple and full. Use full for rendering complete markdown,
 *   simple if you want to render comments, questions, etc. without support for headers, ++
 */
export class MarkdownPreview extends React.PureComponent<
  IMarkdownPreviewProps,
  IMarkdownPreviewState
> {
  public static defaultProps: IMarkdownPreviewProps = {
    addBlankTarget: true,
    rawMarkdown: '',
    renderMode: RenderMode.Full,
  };

  /**
   * custom markdown renderer
   */
  _renderer: Renderer;

  constructor(props: IMarkdownPreviewProps) {
    super(props);

    this.state = {
      addBlankTarget:
        props.addBlankTarget !== undefined ? props.addBlankTarget : true,
    };

    this._renderer = new marked.Renderer();
    this.applyRendererOptions();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IMarkdownPreviewProps) {
    const { addBlankTarget } = nextProps;

    if (
      addBlankTarget !== undefined &&
      this.state.addBlankTarget !== addBlankTarget
    ) {
      this.setState(
        {
          ...this.state,
          addBlankTarget: addBlankTarget,
        },
        () => {
          this.applyRendererOptions();
        }
      );
    }
  }

  /**
   * apply renderer options (configured in state) to this._renderer
   *
   * TODO: add more custom CSS / rendering for rendering HTML from markdown, see: https://marked.js.org/#/USING_PRO.md
   */
  applyRendererOptions = () => {
    this._renderer.link = (
      href: string,
      title: string,
      text: string
    ): string => {
      const targetString = this.state.addBlankTarget ? 'target="_blank"' : '';
      return `
        <a href="${href}" title="${title}" ${targetString} class="markedLink" >${text}</a>
      `;
    };

    this._renderer.heading = (
      text: string,
      level: number,
      raw: string
    ): string => {
      return this.props.renderMode === RenderMode.Full
        ? `<h${level} class="markedHeader">${text}</h${level}>`
        : raw;
    };

    this._renderer.paragraph = (text: string): string => {
      return `<p class="markedParagraph">${text}</p>`;
    };
  };

  /**
   * get markdown render options (including custom renderer)
   * @returns {marked.MarkedOptions}
   */
  getOptions = (): marked.MarkedOptions => {
    const options: marked.MarkedOptions = {
      sanitize: false,
      sanitizer: (html: string) => {
        return html;
      },
      renderer: this._renderer,
    };

    // dont need this I think, but doesnt hurt either :p
    marked.setOptions(options);
    return options;
  };

  render() {
    const { rawMarkdown, style } = this.props;

    // console.log('rndr.markdown', rawMarkdown);

    const options = this.getOptions();
    const rawHtml = marked.parse(rawMarkdown, options);
    return (
      <RenderedMarkdownContainer
        dangerouslySetInnerHTML={{ __html: rawHtml }}
        style={style}
        className={'markedContainer'}
      />
    );
  }
}
