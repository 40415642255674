import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import styled from 'styled-components';
import { RefHandler } from 'react-popper';
import { Option } from 'react-select';

import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';

import * as _ from 'lodash';
import { hideOutline, safeInvokeDeprecated } from '../../common';
import { MunikumKeys } from '../../common/keys';
import { FormLabel } from '../FormLabel/FormLabel';
import { Tag } from '../Tag/Tag';
import { Popover } from '../Popover/Popover';
import { MunikumIcons } from '../../common/icons';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'searchPlaceholder.EmailNameOrMunicipality  ',
    defaultMessage: 'Email, name or municipality',
  },

  to: {
    id: 'to.to',
    defaultMessage: 'TO:',
  },
});
// border: ${(props: { dropDownMode: boolean }) =>
// props.dropDownMode
//   ? '1px solid #D0D3D4'
//   : ' 1px solid rgba(208, 211, 212, 0.2)'};

// box-shadow: ${(props: { dropDownMode: boolean }) =>
//   props.dropDownMode ? '' : '0 2px 7px 0 rgba(0, 0, 0, 0.1)'};

export const AddMemmbersContainer = styled.div`
  display: flex;
  padding: 0.2em;
  border-radius: 3px;
  background-color: ${(props: { disabled: boolean }) =>
    props.disabled ? '#cccccc' : '#fff'};
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(208, 211, 212, 0.2);

  position: relative;
  //border: 1px solid rgba(208, 211, 212, 0.2);
  min-height: 26px;
  width: 100%;

  &:hover {
    cursor: text;
  }
  transition: box-shadow 0.2s ease-in-out;
`;

export const AddMemberContentCont = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /*Removes the default x on input field in ie11*/
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  overflow-wrap: break-word;

  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
`;

const LiDiv = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style-type: none;
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  margin-left: 0.5em;
  margin-right: 0.5em;

  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  padding: 0.5em;

  font-weight: bold;
`;

const MyCustomInput = styled.input`
  border: none;
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  background-color: #fff;

  margin-left: 0.5em;
  -webkit-appearance: none;
  -webkit-moz-appearance: none;
  appearance: none;
  flex: 1 1 auto;
  font-weight: bold;
  ${hideOutline()};
`;

const NoTagsDiv = styled.div`
  font-family: Lato, sans-serif;
  color: #333;
  font-size: 14px;
  display: flex;
  flex: 0 0 90%;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ResultContainer = styled.ul`
  padding: 0.5em;
  max-height: 30em;
  overflow-x: auto;
  overflow-y: auto;
`;

const TagsCont = styled.div`
  display: flex;
  align-items: center;
  font-family: Lato, sans-serif;
  color: #333;
  flex-wrap: wrap;
  font-size: 14px;
`;
const DeleteAllDiv = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  margin-left: 0.5em;
  align-items: flex-end;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.3);
    cursor: pointer;
  }
`;

export enum tagsPositionEnum {
  UNDER = 'UNDER',
  INSIDE = 'INSIDE',
  OVER = 'OVER',
}

interface IAddMemberCompState {
  searchValue: string;
  isResultOpen: boolean;
  selectedIndex: number;
  searchResult: Array<Option>;
  hasFocus: boolean;

  currentItems: Array<Option>;
}
interface IAddMemberCompProps {
  label?: string;
  onChange?: (items: Array<any>) => void;
  selectedItems: Array<Option>;
  style?: CSSProperties;
  textPlaceHolder?: string;
  disabled?: boolean;
  // showTags?: boolean;
  tagsPosition: tagsPositionEnum;
  /**
   * Makes it look like dropDownSelectcomponent
   */
  dropDownMode?: boolean;
  /*
  ONLY WORKS WITH 1 now
  */
  maxElements?: number;
  onTagRemoved?: (item: string) => void;
  loadItems: (
    input: string,
    preloadedItems?: Array<Option<string>>
  ) => Promise<Array<Option<string>>>;
}

function MathiasDropDownFunctionalComp(
  props: IAddMemberCompProps & InjectedIntlProps
) {
  const [searchValue, setSearchValue] = React.useState('');
  const [isResultOpen, setResultContainerState] = React.useState(false);
  const [selectedIndex, setIndex] = React.useState(-1);
  const [currentItems, setCurrentItems] = React.useState(props.selectedItems);
  const [searchResult, setSearchResult] = React.useState([]);
  return <></>;
}
class MathiasDropDownComp extends React.PureComponent<
  IAddMemberCompProps & InjectedIntlProps,
  IAddMemberCompState
> {
  private resultContRef: RefObject<any>;
  private inputRef: RefObject<any>;
  private containerRef: RefObject<any>;
  private debouncedFn: any;

  constructor(props: IAddMemberCompProps & InjectedIntlProps) {
    super(props);
    this.resultContRef = React.createRef();
    this.inputRef = React.createRef();
    this.containerRef = React.createRef();
    this.state = {
      selectedIndex: -1,
      currentItems: this.props.selectedItems,
      searchValue: '',
      isResultOpen: false,
      searchResult: [],
      hasFocus: false,
    };
  }

  protected handleKeyUp = (e: any) => {
    if (e.which === MunikumKeys.ARROW_UP) {
      if (this.state.selectedIndex > 0) {
        this.setState({
          selectedIndex: this.state.selectedIndex - 1,
        });
      }
    } else if (e.which === MunikumKeys.ARROW_DOWN) {
      if (this.state.selectedIndex < this.state.searchResult.length - 1) {
        this.setState({
          selectedIndex: this.state.selectedIndex + 1,
        });
      }
    } else if (e.which === MunikumKeys.ENTER) {
      if (this.state.isResultOpen && this.state.selectedIndex > -1) {
        if (
          this.resultContRef &&
          !this.resultContRef.current.contains(e.target)
        ) {
          const selectedItem = this.state.searchResult[
            this.state.selectedIndex
          ];
          this.handleClickMember(selectedItem);
        }
      }
    } else if (e.which === MunikumKeys.ESCAPE) {
      this.setState({
        isResultOpen: false,
      });
    }
  };

  protected handleClickOutSide = (e: any) => {
    if (this.state.isResultOpen) {
      if (
        this.resultContRef &&
        !(
          this.resultContRef.current.contains(e.target) ||
          this.containerRef.current.contains(e.target)
        )
      ) {
        this.setState({
          isResultOpen: false,
        });
      }
    }
  };
  protected handleKeyDown = (e: any) => {
    if (
      this.state.hasFocus &&
      e.which === MunikumKeys.BACKSPACE &&
      this.state.searchValue === ''
    ) {
      const temp = this.state.currentItems.slice();
      temp.pop();
      this.setState({
        isResultOpen: false,
        currentItems: temp,
      });
    }
  };
  handleFocus = (e: any) => {
    this.setState({
      hasFocus: true,
    });
  };
  handleBlur = (e: any) => {
    this.setState({
      hasFocus: false,
    });
  };
  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyUp);
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousedown', this.handleClickOutSide);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mousedown', this.handleClickOutSide);
  }

  handleChange = (e: any) => {
    e.persist();
    const currentinputText = e.target.value;
    this.setState({
      searchValue: currentinputText,
    });
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(() => {
        this.props
          .loadItems(e.target.value)
          .then((result: Array<Option<string>>) => {
            this.setState({
              searchResult: result.map(t => {
                return { label: t.label, value: t.value.toString() };
              }),
              selectedIndex: -1,
              isResultOpen: currentinputText.length > 0,
            });
          });
      }, 300);
    }
    this.debouncedFn();
  };

  handleClickMember = (value: any) => {
    if (this.props.maxElements && this.props.maxElements === 1) {
      this.setState(
        {
          currentItems: [],
          searchValue: '',
        },
        () => {
          this.state.currentItems.push(value);
          safeInvokeDeprecated(this.props.onChange, this.state.currentItems);
        }
      );
    } else {
      let checkedOrNot = false;
      this.state.currentItems.forEach(t => {
        if (_.isEqual(value.value, t.value)) {
          checkedOrNot = true;
        }
      });
      if (!checkedOrNot) {
        this.state.currentItems.push(value);
        this.setState(
          {
            searchValue: '',
          },
          () => {
            safeInvokeDeprecated(this.props.onChange, this.state.currentItems);
          }
        );
      } else {
        const temp = this.state.currentItems.slice();
        temp.splice(this.state.currentItems.indexOf(value), 1);
        this.setState(
          {
            searchValue: '',
            currentItems: temp,
          },
          () => {
            safeInvokeDeprecated(this.props.onChange, this.state.currentItems);
          }
        );
      }
    }
  };
  render() {
    const { intl } = this.props;
    const placeHolderInputText =
      this.state.currentItems.length === 0 ? 'Søk her...' : '';

    return (
      <>
        {this.props.label && <FormLabel>{this.props.label}</FormLabel>}
        <TagsCont>
          {this.props.tagsPosition === tagsPositionEnum.OVER &&
            this.state.currentItems.length > 0 &&
            this.state.currentItems.map((item, i) => (
              <Tag
                key={'ga' + i}
                onClick={(e: any) => {
                  e.target.preventDefault();
                  e.target.stopPropagation();
                }}
                uppercase={false}
                hasShadow={false}
                style={{
                  flex: '0 0 auto',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: 'Lato',
                  margin: '4px',
                  flexWrap: 'wrap',
                }}
                // styleText={{maxWidth: '100px'}}
                text={item.label}
                color={'#00B0B9'}
                canClose={true}
                onClickClose={e => {
                  const temp = this.state.currentItems.slice();
                  temp.splice(i, 1);
                  this.setState(
                    {
                      searchValue: '',
                      currentItems: temp,
                    },
                    () => {
                      safeInvokeDeprecated(this.props.onTagRemoved, item.value);
                      safeInvokeDeprecated(
                        this.props.onChange,
                        this.state.currentItems
                      );
                    }
                  );
                }}
              />
            ))}
        </TagsCont>
        <Popover
          isOpen={this.state.isResultOpen}
          style={{ padding: '.2em', width: '28em' }}
          position={'bottom'}
          renderTarget={(ref: RefHandler) => {
            return (
              <AddMemmbersContainer
                disabled={this.props.disabled}
                // dropDownMode={this.props.dropDownMode}
                ref={ref}
                style={this.props.style}
                onClick={() => {
                  if (this.inputRef.current) {
                    this.inputRef.current.focus();
                    this.setState({
                      isResultOpen: true,
                    });
                  }
                }}
              >
                <AddMemberContentCont
                  ref={this.containerRef}
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <TagsCont>
                    {this.state.currentItems.length === 0 &&
                      !this.props.dropDownMode && (
                        <MunikumIcons.Search
                          style={{
                            color: '#FF5C39',
                            minWidth: '1.250em',
                            minHeight: '1.250em',
                          }}
                        />
                      )}
                    {this.props.tagsPosition === tagsPositionEnum.INSIDE &&
                      this.state.currentItems.length > 0 &&
                      this.state.currentItems.map((item, i) => (
                        <Tag
                          key={'ga' + i}
                          uppercase={false}
                          hasShadow={false}
                          style={{
                            flex: '0 0 auto',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#fff',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            fontFamily: 'Lato',
                            margin: '4px',
                            flexWrap: 'wrap',
                          }}
                          text={item.label}
                          color={'#00B0B9'}
                          canClose={true}
                          onClickClose={e => {
                            if (!this.props.disabled) {
                              const temp = this.state.currentItems.slice();
                              temp.splice(i, 1);
                              this.setState(
                                {
                                  searchValue: '',
                                  currentItems: temp,
                                  isResultOpen: false,
                                },
                                () => {
                                  safeInvokeDeprecated(
                                    this.props.onTagRemoved,
                                    item.value
                                  );
                                  safeInvokeDeprecated(
                                    this.props.onChange,
                                    this.state.currentItems
                                  );
                                }
                              );
                            }
                          }}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        />
                      ))}
                    {/*{!this.props.showTags &&*/}
                    {/*this.state.currentItems. length > 0 &&*/}
                    {/*<NoTagsDiv>*/}
                    {/*{this.state.currentItems.map(o => o.label).join(',')}*/}
                    {/*/!*{this.state.currentItems.map((value: Option, i)=> {*!/*/}
                    {/*/!*return (*!/*/}
                    {/*/!*<div key={'nmotagga' + i} style={{marginRight: '.5em'}}>*!/*/}
                    {/*/!*{value.label}*!/*/}
                    {/*/!*</div>*!/*/}
                    {/*/!*);*!/*/}
                    {/*/!*})}*!/*/}
                    {/*</NoTagsDiv>*/}
                    {/*}*/}
                    {!this.props.disabled && (
                      <MyCustomInput
                        // list="ice-cream-flavors"
                        disabled={this.props.disabled}
                        type="search"
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        ref={this.inputRef}
                        onKeyDown={(event: any) => {
                          if (event.keyCode === MunikumKeys.ENTER) {
                            event.preventDefault();
                            event.stopPropagation();
                          }
                        }}
                        style={{
                          border: 'none',
                          width:
                            this.state.currentItems.length > 0
                              ? '4em'
                              : 'max-content',
                        }}
                        placeholder={
                          this.state.currentItems.length === 0
                            ? this.props.textPlaceHolder || placeHolderInputText
                            : ''
                        }
                        value={this.state.searchValue}
                        onChange={this.handleChange}
                      />
                    )}
                  </TagsCont>

                  {!this.props.dropDownMode && !this.props.disabled && (
                    <DeleteAllDiv>
                      <MunikumIcons.Cancel
                        style={{ color: 'rgb(242, 169, 0)' }}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.setState(
                            {
                              currentItems: [],
                              searchValue: '',
                            },
                            () => {
                              safeInvokeDeprecated(
                                this.props.onChange,
                                this.state.currentItems
                              );
                            }
                          );
                        }}
                      />
                    </DeleteAllDiv>
                  )}
                </AddMemberContentCont>
              </AddMemmbersContainer>
            );
          }}
        >
          <ResultContainer ref={this.resultContRef}>
            {this.state.searchResult.map((value: Option, i) => {
              let isChecked = false;
              this.state.currentItems.forEach(t => {
                if (t.value === value.value) {
                  isChecked = true;
                }
              });
              const isActive = this.state.selectedIndex === i;
              return (
                <LiDiv
                  key={i}
                  style={{
                    backgroundColor: isActive ? '#00B0B9' : '#fff',
                  }}
                  onMouseEnter={() => {
                    this.setState({
                      selectedIndex: i,
                    });
                  }}
                  onMouseLeave={() => {
                    this.setState({
                      selectedIndex: -1,
                    });
                  }}
                  onClick={() => {
                    this.handleClickMember(value);
                  }}
                >
                  {/*<Checkbox*/}
                  {/*checked={isChecked}*/}
                  {/*style={{marginRight : '1em'}}*/}
                  {/*onClick={()=> {*/}
                  {/*this.handleClickMember(value);*/}
                  {/*}}*/}
                  {/*/>*/}
                  <MunikumIcons.LeftCheck
                    style={{
                      color: isChecked ? '#FF5C39' : 'lightGrey',
                      marginRight: '.5em',
                    }}
                  >
                    &#10003;
                  </MunikumIcons.LeftCheck>
                  {value.label}
                </LiDiv>
              );
            })}
          </ResultContainer>
        </Popover>
        <TagsCont style={{ marginTop: '4px' }}>
          {this.props.tagsPosition === tagsPositionEnum.UNDER &&
            this.state.currentItems.length > 0 &&
            this.state.currentItems.map((item, i) => (
              <Tag
                key={'ga' + i}
                uppercase={false}
                hasShadow={false}
                style={{
                  flex: '0 0 auto',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#fff',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: 'Lato',
                  margin: '4px',
                  flexWrap: 'wrap',
                }}
                text={item.label}
                color={'#00B0B9'}
                canClose={true}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onClickClose={e => {
                  const temp = this.state.currentItems.slice();
                  temp.splice(i, 1);
                  this.setState(
                    {
                      searchValue: '',
                      currentItems: temp,
                    },
                    () => {
                      safeInvokeDeprecated(this.props.onTagRemoved, item.value);
                      safeInvokeDeprecated(
                        this.props.onChange,
                        this.state.currentItems
                      );
                    }
                  );
                }}
              />
            ))}
        </TagsCont>
      </>
    );
  }
}

export const MathiasDropDown = injectIntl(MathiasDropDownComp);
MathiasDropDown.defaultProps = {
  dropDownMode: false,
  disabled: false,
};
