import ColorLib from 'color';
import * as React from 'react';
import { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { MunikumIcons } from '../../common/icons';
import { Colors, safeInvoke } from '../../common';

interface ICheckboxLightProps {
  checked: boolean;
  onClick?: () => void;
  color?: string;
  style?: CSSProperties;

  isHoveringOverride?: boolean;
}

const Box = styled.div`
  width: 1.25em;
  height: 1.25em;
  border-radius: 4px;
  background-color: ${props => props.color};
  position: relative;
  cursor: pointer;
  outline: none;

  border: 1px solid ${props => props.color};
  box-sizing: border-box;
`;

const StyledCheckIcon = styled(MunikumIcons.Check)`
  position: absolute;
  top: 4px;
  left: 4px;

  fill: #fff;
  width: 10px;
  height: 10px;
  size: 10px;

  transition: opacity 0.15s ease-in, transform 0.15s ease-in;
`;

// opacity: ${(props: { ischecked: boolean; ishovering: boolean }) =>
// props.ischecked ? 1 : props.ishovering ? 0.4 : 0};
// transform: ${(props: { ischecked: boolean; ishovering: boolean }) =>
// props.ishovering ? 'scale(1.1)' : 'scale(1)'};

const StyledCheck: React.FunctionComponent<{
  isChecked: boolean;
  isHovering: boolean;
}> = props => {
  const { isChecked, isHovering, ...rest } = props;

  return (
    <StyledCheckIcon
      {...rest}
      style={{
        opacity: isChecked ? 1 : isHovering ? 0.8 : 0,
        transform: isHovering ? 'scale(1.5)' : 'scale(1)',
      }}
    />
  );
};

/**
 * CheckboxLight
 * A custom super light checkbox to keep IE11 happy.....
 * - avoid deep nesting of divs (no label, validationsummary, real form input, etc.)
 * @param props
 * @constructor
 */
export const CheckboxLight: React.FunctionComponent<
  ICheckboxLightProps
> = props => {
  const [isHovering, setIsHovering] = useState(false);
  // const [isChecked, setIsChecked] = useState(props.checked);
  //
  // const realIsChecked = props.checked !== undefined ? props.checked : isChecked;

  const boxColor = props.color || Colors.BLUE;

  const hover1 = ColorLib(boxColor)
    .darken(0.4)
    .string();
  const hover2 = ColorLib(boxColor)
    .lighten(0.6)
    .string();

  return (
    <Box
      style={props.style}
      color={boxColor}
      onClick={(e: any) => {
        e.stopPropagation();
        // setIsChecked(!isChecked);
        safeInvoke(props.onClick);
      }}
      onMouseEnter={e => {
        setIsHovering(true);
      }}
      onMouseLeave={e => {
        setIsHovering(false);
      }}
      tabIndex={0}
    >
      <StyledCheck
        isChecked={props.checked}
        isHovering={isHovering || props.isHoveringOverride}
      />
    </Box>
  );
};
