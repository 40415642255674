import { getApiRequest, parsedResponse } from './api';
import { INotification } from './models/notification';

export async function getNotificationList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<INotification>> {
  if (params) {
    return await parsedResponse<ReadonlyArray<INotification>>(
      await getApiRequest('/notification/list', 'POST', params)
    );
  }
  const response = await getApiRequest('/notification/list', 'GET');
  return await parsedResponse<ReadonlyArray<INotification>>(response);
}

export interface ISendNotification {
  email?: string;
  message: string;
  to?: string; // person id
}

export async function sendNotification(
  notification: ISendNotification
): Promise<boolean> {
  console.log('sending notification', notification);
  const result = await getApiRequest(
    '/notification/send',
    'POST',
    notification
  );
  console.log('send result', result);

  return result.ok;
}

export async function getNotificationsInfo(): Promise<Date> {
  const result = await getApiRequest('/notification/last-check', 'GET');

  return await parsedResponse<Date>(result);
}

export async function checkNotifications(): Promise<Date> {
  const result = await getApiRequest('/notification/check', 'GET');

  return await parsedResponse<Date>(result);
}

export async function readNotification(id: string): Promise<boolean> {
  const result = await getApiRequest('/notification/read/' + id, 'GET');

  return result.ok;
}

export async function getNotification(id: string): Promise<INotification> {
  const result = await getApiRequest('/notification/get/' + id, 'GET');

  return await parsedResponse<INotification>(result);
}
