import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { TextNormal } from '../Structural/typography';
import { Colors } from '../../common';

export interface INewtonProps {
  color1?: string;
  color2?: string;
  color3?: string;
  color4?: string;
  color5?: string;
  color6?: string;
  color7?: string;
  loadingMessages?: Array<string>;
}

const leftBall = keyframes`
  0% {
    transform: rotate(0deg) translateY(0px);
  }
  50% {
    transform: rotate(0deg) translateY(0px);
}
  100% {
    transform: rotate(50deg) translateY(-20px);
}
`;

const rightBall = keyframes`
0% {
    transform: rotate(-50deg) translateY(-20px);
}
  50% {
    transform: rotate(0deg) translateY(0px);
}
  100% {
    transform: rotate(0deg) translateY(0px)
  translateX(0px);`;
const leftShadow = keyframes`
  0% {
    transform: translateX(0px);
}
  50% {
    transform: translateX(0px);
}
  100% {
    transform: translateX(-25px);
}
`;
const rightShadow = keyframes`
  0% {
    transform: translateX(25px);
}
  50% {
    transform: translateY(0px);
}
  100% {
    transform: translateY(0px);
}
`;

const Container = styled.div`
  height: 150px;
  left: 50%;
  margin: -75px 0 0 -53px;
  position: absolute;
  top: 50%;
  width: 106px;
  text-align: center;
`;
const MessageContainer = styled.div`
  margin-top: 1em;
`;
const Cord = styled.div`
  padding-top: 100px;
  width: 15px;
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  float: left;
`;
const CordLeftMove = styled(Cord)`
  animation: ${leftBall} 0.5s ease-in-out 0s infinite alternate;
`;
const CordRightMove = styled(Cord)`
  animation: ${rightBall} 0.5s ease-in-out 0s infinite alternate;
`;
const Shadows = styled.div`
  clear: left;
  padding-top: 20px;
  margin-left: -2px;
`;
const Shadow = styled.div`
  float: left;
  margin-left: 2px;
  width: 13px;
  height: 3px;
  border-radius: 50%;
  box-shadow: 0px 0px 3px rgba(204, 204, 204, 0.3);
  background: rgba(204, 204, 204, 0.3);
`;
const LeftShadow = styled(Shadow)`
  animation: ${leftShadow} 0.5s ease-in-out 0s infinite alternate;
`;
const RightShadow = styled(Cord)`
  animation: ${rightShadow} 0.5s ease-in-out 0s infinite alternate;
`;
const Ball = styled.div`
  background: #333;
  width: 15px;
  height: 15px;
  float: left;
  border-radius: 50%;
`;

export class Newton extends React.PureComponent<INewtonProps, {}> {
  public static defaultProps: INewtonProps = {
    color1: Colors.BLUE,
    color2: Colors.RED,
    color3: Colors.YELLOW,
    color4: Colors.GREY,
    color5: Colors.GREYLABEL,
    color6: Colors.DARKRED,
    color7: Colors.DARKBLUE,
  };

  render() {
    const {
      color1,
      color2,
      color3,
      color4,
      color5,
      color6,
      color7,
      loadingMessages,
    } = this.props;

    return (
      <Container>
        <CordLeftMove>
          <Ball style={{ backgroundColor: color1 }} />
        </CordLeftMove>
        <Cord>
          <Ball style={{ backgroundColor: color2 }} />
        </Cord>
        <Cord>
          <Ball style={{ backgroundColor: color3 }} />
        </Cord>
        <Cord>
          <Ball style={{ backgroundColor: color4 }} />
        </Cord>
        <Cord>
          <Ball style={{ backgroundColor: color5 }} />
        </Cord>
        <Cord>
          <Ball style={{ backgroundColor: color6 }} />
        </Cord>
        <CordRightMove>
          <Ball style={{ backgroundColor: color7 }} />
        </CordRightMove>

        <Shadows>
          <LeftShadow />
          <Shadow />
          <Shadow />
          <Shadow />
          <Shadow />
          <Shadow />
          <RightShadow />
        </Shadows>
        <MessageContainer>
          {loadingMessages &&
            loadingMessages.map((msg, index) => (
              <TextNormal key={index + '_lm'}>{msg}</TextNormal>
            ))}
        </MessageContainer>
      </Container>
    );
  }
}
