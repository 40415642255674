import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import { RefHandler } from 'react-popper';
import { Placement, Popover } from './Popover';
import { MunikumKeys } from '../../common/keys';
import { isFunction } from '../../common';

export interface ITogglePopoverProps {
  position?: Placement;

  isDefaultOpen?: boolean;

  renderTarget: (ref: RefHandler) => JSX.Element;

  usePortal?: boolean;

  style?: CSSProperties;

  isOpen?: boolean;
}

interface ITogglePopoverState {
  isOpen: boolean;
}

/**
 * TogglePopover
 * Use this to quickly get a popover with TOGGLE behaviour
 * - just add your target button in renderTarget render-func and content as children!
 *
 * This component will handle onClick, click outside,
 * key escape and close/open popover accordingly =)
 */
export class TogglePopover extends React.PureComponent<
  ITogglePopoverProps,
  ITogglePopoverState
> {
  // use this to tell if clicks are coming from inside the target wrapper div
  private readonly wrapperRef: RefObject<HTMLDivElement>;

  // use this to whitelist content inside popover
  private readonly whiteRef: RefObject<HTMLDivElement>;

  constructor(props: ITogglePopoverProps) {
    super(props);
    this.state = {
      isOpen: props.isDefaultOpen !== undefined ? props.isDefaultOpen : false,
    };

    this.wrapperRef = React.createRef();
    this.whiteRef = React.createRef();

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITogglePopoverProps) {
    if (
      nextProps.isOpen !== undefined &&
      nextProps.isOpen !== this.state.isOpen
    ) {
      this.setState({
        isOpen: nextProps.isOpen,
      });
    }
  }

  handleKeyUp(e: any) {
    if (this.state.isOpen) {
      // only react to keyevents if expanded.
      if (e.keyCode === MunikumKeys.ESCAPE) {
        this.setState({
          isOpen: false,
        });
      }
    }
  }

  handleClickOutside(e: any) {
    if (this.wrapperRef.current && this.whiteRef.current) {
      const isInsideTargetWrapper = this.wrapperRef.current.contains(e.target);
      const isInsideWhiteWrapper = this.whiteRef.current.contains(e.target);

      if (
        this.state.isOpen &&
        !isInsideTargetWrapper &&
        !isInsideWhiteWrapper
      ) {
        this.setState({
          isOpen: false,
        });
      } else if (
        this.state.isOpen &&
        !isInsideTargetWrapper &&
        isInsideWhiteWrapper
      ) {
        // console.log('you clicked INSIDE children wrapper..');
        // TODO: how to close popover AFTER MenuItem has dispatched it's event???
        // I think we have to analyze MenuItem children in Menu's render() . . .
        // super hacky fix:
        // cannot hide yet! because children has to dispatch event first...
        // setTimeout(() => {
        //   this.setState({
        //     isOpen: false
        //   });
        // }, 5000);
      }
    }
  }

  handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // console.log('onClick TARGET wrapper');
    e.preventDefault();
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { position, renderTarget, style, usePortal } = this.props;
    const { isOpen } = this.state;

    const myStyle = Object.assign({ padding: '.5em' }, style);

    return (
      <Popover
        style={myStyle}
        isDefaultOpen={false}
        position={position || 'right'}
        isOpen={isOpen}
        showDebugInfo={false}
        usePortal={usePortal}
        renderTarget={ref => {
          let targetToRender: JSX.Element;

          if (isFunction(renderTarget)) {
            targetToRender = renderTarget(ref);

            return (
              <div ref={this.wrapperRef} onClick={this.handleClick}>
                {targetToRender}
              </div>
            );
          }

          throw Error('no target to render');
        }}
      >
        <div ref={this.whiteRef}>{this.props.children}</div>
      </Popover>
    );
  }
}
