import * as React from 'react';
import { defineMessages, InjectedIntl } from 'react-intl';
import moment from 'moment';
import {
  IUltimateEnumItem,
  UltimateDropdown,
} from '../basic/DropDownSelectUltimate/UltimateDropdown';

export enum ReminderOptions {
  NONE = 'NONE',
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEK = 'TWO_WEEK',
  FOUR_WEEKS = 'FOUR_WEEKS',
}

const reminderMessages = defineMessages({
  NONE: {
    id: 'ReminderOptions.NONE',
    defaultMessage: 'None',
  },
  ONE_WEEK: {
    id: 'ReminderOptions.ONE_WEEK',
    defaultMessage: 'One week',
  },
  TWO_WEEK: {
    id: 'ReminderOptions.TWO_WEEK',
    defaultMessage: 'Two weeks',
  },
  FOUR_WEEKS: {
    id: 'ReminderOptions.FOUR_WEEKS',
    defaultMessage: 'Four weeks',
  },
});

export const ReminderDropdown = UltimateDropdown.ofEnumType(
  ReminderOptions,
  reminderMessages
);

export function getReminderDate(deadline: Date, interval: ReminderOptions) {
  let reminder = moment(deadline);
  if (interval === ReminderOptions.NONE) {
    return undefined;
  }
  if (interval === ReminderOptions.ONE_WEEK) {
    reminder = reminder.subtract(1, 'week');
  } else if (interval === ReminderOptions.TWO_WEEK) {
    reminder = reminder.subtract(2, 'week');
  } else if (interval === ReminderOptions.FOUR_WEEKS) {
    reminder = reminder.subtract(4, 'week');
  }

  // console.log('deadline: ' + deadline.toDateString() + ' reminder:' + reminder.toDate().toDateString());

  return reminder.toDate();
}

export function getReminderInterval(
  deadline?: Date,
  reminderDate?: Date
): ReminderOptions {
  // console.log('asdf');

  if (!reminderDate || !deadline) {
    return ReminderOptions.NONE;
  }

  const from = moment(deadline);
  const to = moment(reminderDate);
  const days = from.diff(to, 'days');
  if (days === 7) {
    return ReminderOptions.ONE_WEEK;
  } else if (days === 14) {
    return ReminderOptions.TWO_WEEK;
  } else if (days === 28) {
    return ReminderOptions.FOUR_WEEKS;
  }
  return ReminderOptions.NONE;
}

export function getReminderIntervalDropdown(
  intl: InjectedIntl,
  deadline?: Date,
  reminderDate?: Date
) {
  // console.log(Date);
  // console.log(reminderDate);

  const options = getReminderInterval(deadline, reminderDate);
  const temp: IUltimateEnumItem = {
    id: options.toString(),
    value: intl.formatMessage(reminderMessages[options.toString()]), // options.toString(),
  };
  return temp;
}
