import { call, fork, put, race, take } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { delay } from 'redux-saga';
import {
  forceRefreshTokenGetProfileAndSignIn,
  signinFailed,
  signout,
  signoutSuccess,
  userReady,
} from '../auth/auth';
import {
  IEntitiesState,
  IFullReload,
  ILastUpdatedParams,
  loadDataActionCreator,
} from './entities';
import { IEntity } from '../../services/models/entity';
import { IApiError, wrapApiError } from '../../services/api';
import { IAsyncActionCreators } from '../actionCreatorFactory';

interface IEntitySagaFactoryOptions {
  entityKey: keyof IEntitiesState;
  actionCreatorList?: IAsyncActionCreators<
    IFullReload & ILastUpdatedParams,
    ReadonlyArray<IEntity>,
    IApiError
  >;
  getEntities?: () => Promise<ReadonlyArray<IEntity>>;

  actionCreatorItem?: IAsyncActionCreators<IEntity, IEntity, IApiError>;
  getEntity?: (id: string) => Promise<IEntity>;

  enablePolling?: boolean;
  pollingIntervalInSeconds?: number;

  // TODO: implicitly just start polling? or control manually?
  // startPollingActionCreator?: ActionCreator<{}>;
  // stopPollingActionCreator?: ActionCreator<{}>;
}

const NUM_RETRIES = 5;
const RETRY_DELAY = 2000;

/**
 * HOC entity saga-loop factory
 * This returns a redux-saga which handles typical async-actions from redux-fsa in a generic way
 */
export function entitySagaFactory<TEntity extends IEntity>(
  options: IEntitySagaFactoryOptions
) {
  function* fetchListData() {
    if (
      options.actionCreatorList !== undefined &&
      options.getEntities !== undefined
    ) {
      while (true) {
        const action = yield take(options.actionCreatorList.started.type);

        // retrying failing requests 5 times
        for (let i = 0; i < NUM_RETRIES; i++) {
          try {
            if (isEmpty(action.payload)) {
              // console.log('empty, full update');
              const result = yield call(options.getEntities);
              yield put(
                options.actionCreatorList.success({
                  params: {},
                  result: result,
                })
              );
              break;
            } else if (
              (action.payload.isFullReload === undefined ||
                action.payload.isFullReload === false) &&
              action.payload.lastUpdated !== undefined
            ) {
              // partial update
              const params = { lastUpdated: action.payload.lastUpdated };
              // console.log('partial update', params);
              const result = yield call(options.getEntities, params);
              yield put(
                options.actionCreatorList.success({
                  params: params,
                  result: result,
                })
              );
              break;
            } else {
              // console.log('not empty, but full update');
              const result = yield call(options.getEntities);
              yield put(
                options.actionCreatorList.success({
                  params: {},
                  result: result,
                })
              );
              break;
            }
          } catch (e) {
            const tempError = wrapApiError(e);

            if (i < 4) {
              if (e.statusCode === 401) {
                // NOTE: Scenario: offline.. We won't get 401 when offline anyways? So we will never
                // be offline when we het here?

                // we don't want to depend on state, reusing method.. (we could use a channel):
                yield call(forceRefreshTokenGetProfileAndSignIn);
              } else {
                yield call(delay, RETRY_DELAY);
              }
            } else {
              console.log('failed 5 times, putting failed state..');
              yield put(
                options.actionCreatorList.failed({
                  params: {},
                  error: tempError,
                })
              );
            }
          }
        }
      }
    }
  }

  function* fetchSingleData() {
    if (
      options.actionCreatorItem !== undefined &&
      options.getEntity !== undefined
    ) {
      while (true) {
        const action = yield take(options.actionCreatorItem.started.type);
        for (let i = 0; i < NUM_RETRIES; i++) {
          try {
            const result: TEntity = yield call(
              options.getEntity,
              action.payload.id
            );
            yield put(
              options.actionCreatorItem.success({
                params: action.payload,
                result: result,
              })
            );
            break;
          } catch (e) {
            const tempError = wrapApiError(e);

            if (i < 4) {
              if (e.statusCode === 401) {
                // we don't want to depend on state, reusing method.. (we could use a channel):
                yield call(forceRefreshTokenGetProfileAndSignIn);
              } else {
                yield call(delay, RETRY_DELAY);
              }
            } else {
              console.log('failed 5 times, putting failed state..');
              yield put(
                options.actionCreatorItem.failed({
                  params: {
                    id: action.payload.id,
                  },
                  error: tempError,
                })
              );
            }
          }
        }
      }
    }
  }

  function* pollDataWorker() {
    if (!options.pollingIntervalInSeconds) {
      console.log('warning: pollingInterval not set in entitySagaFactory! ');
      return;
    }

    try {
      yield put(loadDataActionCreator({ keys: [options.entityKey] }));
      yield call(delay, 1000 * options.pollingIntervalInSeconds);
    } catch (e) {
      console.log('polling error ', e);
    }
  }

  function* pollData() {
    while (true) {
      // console.log('poller waiting for user to log in ' + options.entityKey);
      yield take(userReady);

      if (
        options.enablePolling &&
        options.pollingIntervalInSeconds !== undefined
      ) {
        // console.log(
        //   'Polling enabled for ' +
        //     options.entityKey +
        //     ', starting polling data each ' +
        //     options.pollingIntervalInSeconds +
        //     ' seconds'
        // );
        while (true) {
          const {
            signoutRequested2,
            signoutSuccess2,
            refreshFailed2,
            updateTimer,
          } = yield race({
            signoutSuccess2: take(signoutSuccess.type),
            signoutRequested2: take(signout.type),
            refreshFailed2: take(signinFailed.type),
            updateTimer: call(delay, options.pollingIntervalInSeconds * 1000),
          });

          if (signoutRequested2) {
            console.log(
              'you signed out, polling paused for entity ' + options.entityKey
            );
            break;
          } else if (signoutSuccess2) {
            console.log(
              'you signed out, polling paused for entity ' + options.entityKey
            );
            break;
          } else if (refreshFailed2) {
            console.log('refresh token failed, polling paused..');
          } else {
            // console.log('poll ' + options.entityKey);
            yield call(pollDataWorker);
          }
        }
      }
    }
  }

  return function* improvedEntitySaga() {
    if (options.actionCreatorList) {
      yield fork(fetchListData);
    }
    if (options.actionCreatorItem) {
      yield fork(fetchSingleData);
    }
    if (options.enablePolling) {
      yield fork(pollData);
    }
  };
}
