import { actionCreatorFactory } from '../actionCreatorFactory';
import { call, put, take } from 'redux-saga/effects';
import { buffers, END, eventChannel } from 'redux-saga';
import { getAccessTokenFromStore } from '../../services/api';

const actionCreator = actionCreatorFactory('FILE');

export interface IRequestFileUpload {
  file: File;
  metaid?: string;
}

export const uploadRequestActionCreator = actionCreator<IRequestFileUpload>(
  'UPLOAD_REQUEST'
);

export const uploadProgressActionCreator = actionCreator<{ progress: number }>(
  'UPLOAD_PROGRESS'
);

export const uploadSuccessActionCreator = actionCreator<{}>('UPLOAD_SUCCESS');

export const uploadFailureActionCreator = actionCreator<{ error: string }>(
  'UPLOAD_FAILURE'
);

export function* uploadRequestWatcherSaga() {
  while (true) {
    const action = yield take(uploadRequestActionCreator.type);

    const file = action.payload;
    yield call(uploadFileSaga, file);
  }
}

function* uploadFileSaga(file: IRequestFileUpload) {
  const channel = yield call(createUploadFileChannel, file);
  //
  while (true) {
    const { progress = 0, err, success } = yield take(channel);
    if (err) {
      yield put(uploadFailureActionCreator({ error: err.message }));
      return;
    }
    if (success) {
      yield put(uploadSuccessActionCreator({}));
      return;
    }
    yield put(uploadProgressActionCreator({ progress: progress }));
  }
}

export function createUploadFileChannel(file: IRequestFileUpload) {
  let apiUrl = process.env.REACT_APP_API;

  return eventChannel(emitter => {
    const onProgress = (e: ProgressEvent) => {
      console.log('OK3', e);
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = (e: ErrorEvent) => {
      emitter({ err: new Error('Upload failed') });
      emitter(END);
    };

    const xhr = new XMLHttpRequest();

    let url = apiUrl + '/file/upload';
    xhr.open('POST', url, true);

    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);

    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 200) {
          console.log(xhr.response);
          emitter({ success: true });
          emitter(END);
        } else {
          emitter({ err: new Error('Upload failed') });
          emitter(END);
        }
      }
    };

    xhr.onprogress = function() {
      console.log('LOADING', xhr.status);
    };

    let token = getAccessTokenFromStore();

    let formData = new FormData();
    formData.append('type', file.file.type);
    formData.append('file', file.file);
    formData.append('metaid', file.metaid ? '' + file.metaid : '');

    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.send(formData);

    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}
