import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {
  GetKostraFunctionsQuery,
  GetKostraFunctionsVariables,
} from '../../models/types';

export const fieldsOnKostraFunctionGroup = gql`
  fragment FieldsOnKostraFunctionGroup on KostraFunctionGroup {
    id
    kid
    title
    identifier
    description
  }
`;

export const fieldsOnKostraFunction = gql`
  fragment FieldsOnKostraFunction on KostraFunction {
    id
    kid
    description
    title
    group {
      ...FieldsOnKostraFunctionGroup
    }
  }
  ${fieldsOnKostraFunctionGroup}
`;

export const GET_KOSTRA_FUNCTIONS = gql`
  query GetKostraFunctions {
    allKostraFunctions {
      ...FieldsOnKostraFunction
    }
  }
  ${fieldsOnKostraFunction}
`;

export const MY_GET_KOSTRA_FUNCTIONS = gql`
  query MyGetKostraFunctions {
    me {
      id
      kostraFunctions {
        id
      }
    }
  }
`;

export class GetKostraFunctions extends Query<
  GetKostraFunctionsQuery,
  GetKostraFunctionsVariables
> {}
