import * as React from 'react';
import { CSSProperties } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { commonMessages } from '../../language/commonMessages';
import { Button } from '..';
import { MunikumIcons } from '../../common/icons';
import { ColorTheme } from '../../common';

/**
 * MuButtons
 * just simple buttons for discard, cancel, save, edit, etc.
 */

export interface IMuButtonProps {
  onClick?: () => void;
  style?: CSSProperties;
}

export const MuDiscardButtonComp: React.SFC<
  IMuButtonProps & InjectedIntlProps
> = props => (
  <Button
    text={props.intl.formatMessage(commonMessages.discard)}
    leftIcon={MunikumIcons.Delete}
    theme={ColorTheme.White}
    onClick={props.onClick}
    style={props.style}
  />
);

export const MuDiscardButton = injectIntl(MuDiscardButtonComp);

const MuUploadButtonComp: React.SFC<
  IMuButtonProps & InjectedIntlProps
> = props => (
  <Button
    text={props.intl.formatMessage(commonMessages.uploadFiles)}
    leftIcon={MunikumIcons.FileUpload}
    theme={ColorTheme.White}
    onClick={props.onClick}
    style={props.style}
  />
);

export const MuUploadButton = injectIntl(MuUploadButtonComp);

const MuCancelButtonComp: React.SFC<
  IMuButtonProps & InjectedIntlProps
> = props => (
  <Button
    text={props.intl.formatMessage(commonMessages.cancel)}
    leftIcon={MunikumIcons.Cancel}
    theme={ColorTheme.White}
    onClick={props.onClick}
    style={props.style}
  />
);

export const MuCancelButton = injectIntl(MuCancelButtonComp);

const MuSaveButtonComp: React.SFC<
  IMuButtonProps & InjectedIntlProps
> = props => (
  <Button
    text={props.intl.formatMessage(commonMessages.save)}
    leftIcon={MunikumIcons.Save}
    theme={ColorTheme.Red}
    onClick={props.onClick}
    style={props.style}
  />
);

export const MuSaveButton = injectIntl(MuSaveButtonComp);

const MuEditButtonComp: React.SFC<
  IMuButtonProps & InjectedIntlProps
> = props => (
  <Button
    text={props.intl.formatMessage(commonMessages.edit)}
    leftIcon={MunikumIcons.Edit}
    theme={ColorTheme.White}
    onClick={props.onClick}
    style={props.style}
  />
);

export const MuEditButton = injectIntl(MuEditButtonComp);

interface IMuButtonOptions {
  /**
   * override default text
   */
  text?: string;
  isVisible: boolean;
}
//
// interface IMuButtonsOptions {
//   discard: IMuButtonOptions;
//   upload: IMuButtonOptions;
//   cancel: IMuButtonOptions;
//   save: IMuButtonOptions;
// }
