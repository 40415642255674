import * as _ from 'lodash';
import * as React from 'react';
import { FieldArray, Formik, FormikProps } from 'formik';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { commonLabels } from '../language/commonLabels';
import { Button, TextField, ValidationMessage } from '../basic';
import { BreadCrumb, BreadCrumbs } from './BreadCrumbs';
import { MunikumIcons } from '../common/icons';
import { TextArea } from '../basic/TextArea/TextArea';
import { SelectMode } from '../basic/DropDownSelectUltimate/UltimateDropdown';
import {
  AddTaskCollectionMutationMutation,
  AddTaskCollectionMutationVariables,
  ContentStatus,
  CreateEventInput,
  CreateTaskInput,
  TaskTypeEnum,
  YearWheelMyCalendars,
} from '../../models/types';
import { YEAR_WHEEL } from '../../containers/AnnualCycle/calendar-queries';
import { ColorTheme, MessageType, safeInvokeDeprecated } from '../common';
import { DialogBox } from '../layout/Box/Box';
import { EventCard } from './EventCard';
import { CreateCard } from './CreateCard';
import { H4 } from '../basic/Structural/typography';
import { commonMessages } from '../language/commonMessages';
import {
  StatusMessageDialog,
  ThemeColor,
} from '../basic/StatusMessage/StatusMessage';
import { CalendarsDropdown } from './CalendarsDropdown';
import { messages } from './messages';

interface ICreateTaskCollectionFormProps {
  onCancel?: () => void;
  onSaveSuccess?: () => void;
  color?: string;
  year: number;
  myCalendars?: Array<YearWheelMyCalendars>;
  userHash: string;
}

const CustomDialogBox = styled(DialogBox)`
  padding: 0px;
`;

const LeftContainer = styled.div`
  box-sizing: border-box;
  flex: 0 0 18.75em;
  background-color: #fff;
  padding: 1.5em 1.875em;
  min-width: 24em;
  max-width: 24em;
`;

const FlexBox = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  box-sizing: border-box;
  padding: 3.5em 1.5em 1.875em;
  background-color: #efefef;
  flex: 1 1 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Butts = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 1em;
`;

interface ICreateCollectionTaskState {
  showError: boolean;
  showSuccess: boolean;
  debug: boolean;

  // editEvent?: CreateEventInput;
  editEventIndex?: number;
}

let calendarColor = '';

export const ADD_TASK = gql`
  mutation AddTaskCollectionMutation($input: CreateTaskInput!) {
    createTask(input: $input) {
      id
      title
      events {
        id
      }
    }
  }
`;

export class CreateTaskMutation extends Mutation<
  AddTaskCollectionMutationMutation,
  AddTaskCollectionMutationVariables
> {}

class CreateTaskCollectionFormComp extends React.PureComponent<
  ICreateTaskCollectionFormProps & InjectedIntlProps,
  ICreateCollectionTaskState
> {
  constructor(props: ICreateTaskCollectionFormProps & InjectedIntlProps) {
    super(props);

    this.state = {
      showError: false,
      showSuccess: false,
      debug: false,
    };
  }

  handleError = () => {
    this.setState({
      showError: true,
    });
  };

  handleCompleted = (result: any) => {
    this.setState({
      showSuccess: true,
    });
  };

  handleDismissError = () => {
    this.setState({
      showError: false,
    });
  };

  handleDismissSuccess = () => {
    this.setState(
      {
        showSuccess: false,
      },
      () => safeInvokeDeprecated(this.props.onSaveSuccess)
    );
  };

  render() {
    const { intl } = this.props;
    return (
      <CreateTaskMutation
        mutation={ADD_TASK}
        refetchQueries={[
          {
            query: YEAR_WHEEL,
            variables: { year: this.props.year, person: this.props.userHash },
          },
        ]}
        onCompleted={this.handleCompleted}
        onError={this.handleError}
      >
        {(addTask, { data: data1, loading, called, error }) => {
          if (this.state.showError && error) {
            return (
              <StatusMessageDialog
                theme={ThemeColor.ERROR_BLACK}
                title={'Error'}
                text={'An error occured: ' + 'error.message'}
                buttonText={'Dismiss'} // we could also retry? check if apollo has a retry mechanismn?
                onClickButton={this.handleDismissError}
              />
            );
          }

          if (this.state.showSuccess && called) {
            return (
              <StatusMessageDialog
                theme={ThemeColor.SUCCESS_BLUE}
                title={'Success'}
                text={'Oppgaven er lagt til'}
                buttonText={'Flink!'} // we could also retry? check if apollo has a retry mechanismn?
                onClickButton={this.handleDismissSuccess}
              />
            );
          }

          // default form (task collection):
          return (
            <CustomDialogBox>
              <Formik
                validateOnBlur={true}
                validationSchema={Yup.object().shape({
                  title: Yup.string()
                    .label(intl.formatMessage(commonLabels.title))
                    .required(),
                  calendar: Yup.string()
                    .label(intl.formatMessage(messages.calendar))
                    .required(),
                  events: Yup.array(Yup.object<CreateEventInput>()).min(
                    1,
                    intl.formatMessage(commonMessages.eventRequired)
                  ),
                  // startDate: Yup.date()
                  //   .label(intl.formatMessage(commonLabels.startDate))
                  //   .required(),
                  // endDate: Yup.date()
                  //   .label(intl.formatMessage(commonLabels.endDate))
                  //   .required(),
                })}
                onSubmit={(values: CreateTaskInput) => {
                  // console.log('SUBMIT FORM');
                  // console.log('values', values);
                  // console.log('values', JSON.stringify(values));

                  addTask({
                    variables: {
                      input: values,
                    },
                  })
                    .then(result => {
                      // console.log('GREAT SUCCESS', result);
                    })
                    .catch(err => {
                      console.log('BORAT', err);
                    });
                }}
                initialValues={{
                  calendar: '',
                  title: '', // TODO: implement placeholder on contentEditable? is that possible?
                  description: '',
                  status: ContentStatus.PUBLISHED,
                  events: [],
                  type: TaskTypeEnum.DEFAULT,
                }}
                render={(formikProps: FormikProps<CreateTaskInput>) => {
                  const selectedCalendar = _.find(
                    this.props.myCalendars,
                    cal => cal.id === formikProps.values.calendar
                  );
                  return (
                    <div
                    // onSubmit={formikProps.handleSubmit}
                    // onKeyDown={(e: any) => {
                    //   console.log('down');
                    //   if (e.keyCode === MunikumKeys.ENTER) {
                    //     console.log('this should stop form from submitting?');
                    //     e.preventDefault();
                    //     e.stopPropagation();
                    //   }
                    // }}
                    >
                      <FlexBox>
                        <LeftContainer>
                          <BreadCrumbs>
                            <BreadCrumb
                              isDisabled={true}
                              leftIcon={MunikumIcons.Folder}
                            >
                              {formikProps.values.title.length > 0 ? (
                                formikProps.values.title
                              ) : (
                                <FormattedMessage
                                  id={'CreateTaskCollectionForm.collection'}
                                  defaultMessage={'Collection of activities'}
                                />
                              )}
                            </BreadCrumb>
                          </BreadCrumbs>

                          {/* === TASK ===  */}

                          <div
                            style={{
                              paddingRight: '1.5em',
                              marginTop: '1em',
                            }}
                          >
                            <TextField
                              name={'title'}
                              autoComplete={'off'}
                              label={intl.formatMessage(commonLabels.title)}
                              placeholder={intl.formatMessage(
                                commonMessages.writeTitleHere
                              )}
                              onChange={(e: any) => {
                                formikProps.setFieldValue(
                                  'title',
                                  e.target.value
                                );

                                // NOTE: doesnt apply to create collection of activities..:
                                // if (
                                //   formikProps.values.events &&
                                //   formikProps.values.events.length === 1
                                // ) {
                                //   // TODO: does this work?
                                //   const youTouchedIt =
                                //     formikProps.touched.events &&
                                //     formikProps.touched.events[0] &&
                                //     formikProps.touched.events[0].title ===
                                //       true;
                                //   if (!youTouchedIt) {
                                //     formikProps.setFieldValue(
                                //       'events.0.title',
                                //       e.target.value
                                //     );
                                //   }
                                // }
                              }}
                              onBlur={formikProps.handleBlur}
                              value={formikProps.values.title}
                              error={
                                formikProps.touched.title &&
                                formikProps.errors.title
                              }
                              style={{
                                width: '100%',
                              }}
                            />

                            <TextArea
                              label={intl.formatMessage(
                                commonMessages.description
                              )}
                              name={'description'}
                              value={formikProps.values.description || ''}
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              placeholder={intl.formatMessage(
                                commonMessages.writeDescriptionHere
                              )}
                              error={
                                formikProps.touched.description &&
                                formikProps.errors.description
                              }
                              // fieldGroupStyle={{
                              //   paddingBottom: 0,
                              // }}
                              style={{
                                width: '100%',
                                height: '10em',
                              }}
                            />
                          </div>

                          <CalendarsDropdown
                            name={'calendar'}
                            label={intl.formatMessage(messages.chooseCalendar)}
                            onBlur={formikProps.handleBlur}
                            selectMode={SelectMode.SINGLE}
                            onSelected={selectedItem => {
                              formikProps.setFieldValue(
                                'calendar',
                                selectedItem.id
                              );

                              // TODO: update all events! => no.. no calendar on event..
                            }}
                            selectedItems={
                              selectedCalendar ? [selectedCalendar] : []
                            }
                            error={
                              formikProps.touched.calendar &&
                              formikProps.errors.calendar
                            }
                            style={{
                              width: '100%',
                            }}
                          />

                          {/* === EVENT LIST ===*/}
                        </LeftContainer>

                        {/* EVENT SUB FORM */}
                        <FieldArray
                          name={'events'}
                          render={arrHelpers => {
                            // console.log('render fieldarray events!');

                            // TODO: hide subform (but dont remove from DOM) if not open..

                            // TODO: optimize... this will deep clone on each render!! (slow)

                            const isSubFormVisible =
                              this.state.editEventIndex !== undefined;

                            if (!isSubFormVisible) {
                              return null; // TODO: check if this will break stuff, but think its ok? values are saved before we remove from DOM
                            }

                            const tempEditForm =
                              (formikProps.values.events &&
                                this.state.editEventIndex !== undefined &&
                                formikProps.values.events[
                                  this.state.editEventIndex
                                ]) ||
                              undefined;

                            const tempDolly = _.cloneDeep(tempEditForm);
                            const tempTaskForm = _.cloneDeep(
                              formikProps.values
                            );

                            // console.log(
                            //   'calendar on task stuff: ' + tempTaskForm.calendar
                            // ); // OK

                            // NOTE: removing from DOM if not visible (to reset Formiks initialValues)

                            // return isSubFormVisible ? (
                            //   <Overlay isOpen={isSubFormVisible}>
                            //     {this.state.editEventIndex !== undefined &&
                            //       formikProps.values.events && (
                            //         <CreateEventForm
                            //           year={this.props.year}
                            //           defaultValues={tempDolly}
                            //           myCalendars={this.props.myCalendars}
                            //           attachedTaskValues={tempTaskForm}
                            //           onCancel={() => {
                            //             this.setState({
                            //               editEventIndex: undefined,
                            //             });
                            //           }}
                            //           onSubmit={(
                            //             form: ICreateEventFormViewModel
                            //           ) => {
                            //             // console.log(
                            //             //   'adding updated event to formik state',
                            //             //   form
                            //             // );
                            //
                            //             if (
                            //               formikProps.values.events &&
                            //               this.state.editEventIndex !==
                            //                 undefined
                            //             ) {
                            //               arrHelpers.replace(
                            //                 this.state.editEventIndex,
                            //                 form.form
                            //               );
                            //             }
                            //
                            //             if (
                            //               form.calendar !==
                            //               formikProps.values.calendar
                            //             ) {
                            //               formikProps.setFieldValue(
                            //                 'calendar',
                            //                 form.calendar
                            //               );
                            //             }
                            //
                            //             this.setState({
                            //               editEventIndex: undefined,
                            //             });
                            //           }}
                            //         />
                            //       )}
                            //   </Overlay>
                            // ) : null;
                          }}
                        />

                        {/* === EVENT FORM === */}

                        <RightContainer>
                          <div>
                            <H4>
                              <FormattedMessage
                                id={'CreateTaskCollectionform.activities'}
                                defaultMessage={'Activities'}
                              />
                            </H4>

                            <FieldArray
                              name={'events'}
                              render={arrayHelpers => {
                                return (
                                  <div style={{ marginTop: '1em' }}>
                                    <CreateCard
                                      disabled={false}
                                      name={'events'}
                                      placeholder={intl.formatMessage(
                                        messages.addActivityPlaceholder
                                      )}
                                      style={{
                                        marginTop: '1em',
                                      }}
                                      onSubmit={title => {
                                        // console.log(title, 'waa');
                                        const temp: CreateEventInput = {
                                          title: title,
                                          startDate: new Date(),
                                          endDate: new Date(),
                                          status: ContentStatus.PUBLISHED,
                                        };
                                        formikProps.values.events.push(temp);
                                        // arrayHelpers.insert(0, temp);
                                      }}
                                      onBlur={formikProps.handleBlur}
                                    />
                                    <div
                                      style={{
                                        marginTop: '1em',
                                      }}
                                    >
                                      {formikProps.errors.events &&
                                        formikProps.touched.events && (
                                          <ValidationMessage
                                            type={MessageType.ERROR}
                                            msg={intl.formatMessage(
                                              commonMessages.eventRequired
                                            )}
                                          />
                                        )}

                                      {formikProps.values.events &&
                                        formikProps.values.events.map(
                                          (createEventInput, index) => {
                                            return createEventInput ? (
                                              <EventCard
                                                key={'event_' + index}
                                                title={createEventInput.title}
                                                personName={'No name'} // TODO: responsible should be YOU as default
                                                startDate={
                                                  createEventInput.endDate
                                                }
                                                onDeleteClicked={() => {
                                                  const test: any =
                                                    formikProps.values.events &&
                                                    formikProps.values.events.slice();
                                                  test.splice(index, 1);
                                                  formikProps.setFieldValue(
                                                    'events',
                                                    test
                                                  );
                                                }}
                                                canDelete={true}
                                                style={{
                                                  marginBottom: '.5em',
                                                }}
                                                onCardClick={() => {
                                                  // console.log(
                                                  //   'open another popup...'
                                                  // );
                                                  this.setState({
                                                    editEventIndex: index,
                                                  });
                                                }}
                                              />
                                            ) : null;
                                          }
                                        )}
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </div>

                          <Butts>
                            {/*<Button*/}
                            {/*text={'Debug'}*/}
                            {/*theme={ColorTheme.Yellow}*/}
                            {/*onClick={() =>*/}
                            {/*this.setState({ debug: !this.state.debug })*/}
                            {/*}*/}
                            {/*/>*/}
                            <Button
                              text={intl.formatMessage(commonMessages.cancel)}
                              leftIcon={MunikumIcons.Cancel}
                              theme={ColorTheme.White}
                              style={{ marginLeft: '.5em' }}
                              onClick={() =>
                                safeInvokeDeprecated(this.props.onCancel)
                              }
                            />
                            <Button
                              isLoading={loading}
                              text={intl.formatMessage(commonMessages.save)}
                              theme={ColorTheme.Red}
                              // type={'submit'}
                              onClick={() => formikProps.submitForm()}
                              style={{ marginLeft: '.5em' }}
                              leftIcon={MunikumIcons.Save}
                              // disabled={!formikProps.isValid}
                            />
                          </Butts>
                        </RightContainer>
                      </FlexBox>
                      {this.state.debug && (
                        <div style={{ display: 'flex' }}>
                          <pre
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Courier new',
                              marginLeft: '.5em',
                            }}
                          >
                            values: <br />
                            {JSON.stringify(formikProps.values, null, 2)}
                          </pre>
                          <pre
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Courier new',
                              marginLeft: '.5em',
                            }}
                          >
                            touched: <br />
                            {JSON.stringify(formikProps.touched, null, 2)}
                          </pre>
                          <pre
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Courier new',
                              marginLeft: '.5em',
                            }}
                          >
                            errors: <br />
                            {JSON.stringify(formikProps.errors, null, 2)}
                          </pre>
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </CustomDialogBox>
          );
        }}
      </CreateTaskMutation>
    );
  }
}

export const CreateTaskCollectionForm = injectIntl(
  CreateTaskCollectionFormComp
);
