import * as React from 'react';
import { RefObject } from 'react';
import styled from 'styled-components';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import moment from 'moment';
import * as _ from 'lodash';
import memoizeOne from 'memoize-one';
import { commonLabels } from '../language/commonLabels';
import { CleanDialog, CloseWarningOption } from '../basic/Dialog/CleanDialog';
import {
  Access,
  AccessEnum,
  FieldsOnEventInstanceFragment,
  PersonAccess,
  UpdateEventInstanceInput,
} from '../../models/types';
import { EditEventInstanceForm } from './event/EditEventInstanceForm';
import {
  Colors,
  ColorTheme,
  hideOutline,
  safeInvoke,
  safeInvokeDeprecated,
} from '../common';
import { IAnnualCycleFilter } from '../../containers/AnnualCycle/calendar-selectors';
import { WheelConstants } from './Wheel/utils';
import { Button, ButtonSize } from '../basic';
import { Checkbox } from '../basic/Checkbox/Checkbox';
import { AutoSizer, List, ListRowProps } from 'react-virtualized';
import { sizes } from '../common/media';
import MediaQuery from 'react-responsive';
import { Toaster } from '../basic/Toaster/Toaster';
import { ToastType } from '../basic/Toaster/Toast';
import { MunikumIcons } from '../common/icons';
import { ShareButton } from '../meta/ShareButton';
import { IShareForm } from '../../services/metaService';
import { CopyAnnualCycle } from './copyAnnualCycle';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import { COPY_CALENDAR } from './EditAnnualCycleForm';
import { CopyToast } from '../../containers/AnnualCycle/CalendarPage';
import { Overlay } from '../basic/Overlay/Overlay';
import { UPDATE_SETTINGS } from '../../containers/AnnualCycle/calendar-queries';
import { TaskComp } from './TaskComp';
import { commonMessages } from '../language/commonMessages';
import { CircleSpinnerAnimation } from '../basic/Loader/CircleSpinner';

const pling4 = require('./sound/click2.mp3');

/**
 * NOTE: GraphQL has functionality for adding data to models client side!
 * We could refactor and add extra fields as @local stuff :-)
 *
 * https://www.apollographql.com/docs/react/essentials/local-state.html
 */
export interface IEventInstanceViewModel extends FieldsOnEventInstanceFragment {
  taskId: string;
  taskTitle: string;
  taskCalendarId: string;
  color: string;
  ringid?: string;
  access: Access;
  year: number;
  // active rings sent to eventform
}

const OuterContainer = styled.div`
  flex: 1 1 auto;
  //background-color: orange;

  overflow-y: hidden;

  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // hacks to enable shadow + position scrollbar correctly + enable virualization
  margin-left: -10px;
  margin-right: -10px;

  ${hideOutline()};
`;

const MyListContainer = styled.div`
  //overflow-y: auto;
  //overflow-x: visible;

  flex: 1 1 auto;

  //background-color: yellow;

  //padding-left: 10px;
  //padding-right: 0px;

  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

const MonthItem = styled.div`
  text-transform: uppercase;
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 12px;
  padding-top: 24px;
`;

const TopDiv2 = styled.div`
  display: flex;
  align-items: center;

  flex: 0 0 auto;

  padding-left: 10px;
  padding-right: 10px;
`;

const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  margin-bottom: 1em;

  flex: 0 0 auto;

  padding-left: 10px;
  padding-right: 10px;
`;

const DateDiv = styled.div`
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
  text-align: center;
`;

const messages = defineMessages({
  myTasks: {
    id: 'AnnualCycleRightMenu.myTasks',
    defaultMessage: 'MY TASKS',
  },
  youHaveToCreateAYearWheel: {
    id: 'AnnualCycleRightMenu.youHaveToCreateAYearWheel',
    defaultMessage:
      'You have to create a year wheel before you can create a task',
  },
  finishedTasks: {
    id: 'AnnualCycleRightMenu.finishedTasks',
    defaultMessage: 'FINISHED TASKS',
  },
  hideFinnishedTasks: {
    id: 'AnnualCycleRightMenu.hideFinnishedTasks',
    defaultMessage: 'Hide finnished tasks',
  },
  othersTasks: {
    id: 'AnnualCycleRightMenu.othersTasks',
    defaultMessage: 'OTHERS TASKS',
  },
  responsible: {
    id: 'AnnualCycleRightMenu.responsible',
    defaultMessage: 'Responsible',
  },
  noPermissionEdit: {
    id: 'AnnualCycleRightMenu.noPermissionEdit',
    defaultMessage: 'You do not have permission to edit this task',
  },
  activitiesTitle: {
    id: 'AnnualCycleRightMenu.activitiesTitle',
    defaultMessage: 'Activities',
  },
});

interface IAnnualCycleRightMenuCompProps {
  onClickNewTask?: () => void;
  year?: number; // selected year
  yearFrom?: Date;
  yearTo?: Date;
  startMonth: string;
  isSharing?: boolean;
  onShare?: (form: IShareForm) => void;
  calendar?: any;
  isCalendarPage?: boolean;
  checkBoxColor?: any;
  eventInstances: Array<IEventInstanceViewModel>; // filtered tasks to display in right menu
  me?: any;
  isMyYearWheel: boolean;
  isNothingSelected: boolean;
  isMyAssignedTasksSelected?: boolean;
  userHash: string;
  onMagicRingClick?: (isActive: boolean) => void;
  canWrite?: boolean;
  isEverythingSelected: boolean;
  filter: IAnnualCycleFilter;
  onTempHack?: (foreceReloadAll: boolean) => void;
  activeYearwheels?: Array<any>;
  doIhaveCalendars: boolean;
  // if the user presses new activity but doesn't have any yearwheels, open create yearwheel after alert message
  openCreateYearWheel?: () => void;
  hideFinishedTasks: boolean;
  fromYear?: number;
  toYear?: number;
  loadingInstances?: boolean;
}

interface IAnnualCycleRightMenuCompState {
  currentTab: number;
  hideFinnishedTasks: boolean;
  isCopyYearWheelOpen: boolean;
  showAssignedTasks: boolean;
  ringId: string;
  editEventInstanceData?: {
    taskId: string;
    eventInstanceId: string;
  };
  isEditEventInstanceOpen: boolean;
}

interface IEditInstanceStatusProps {
  t?: UpdateEventInstanceInput;
}

interface MyItem {
  item?: IEventInstanceViewModel;
  monthName?: string;
  isMonthItem: boolean;
  doIHaveAccess: boolean;
}

class AnnualCycleRightMenuComp extends React.PureComponent<
  IAnnualCycleRightMenuCompProps & IEditInstanceStatusProps & InjectedIntlProps,
  IAnnualCycleRightMenuCompState
> {
  private toastRef: React.RefObject<Toaster> = null;

  private readonly myAudioRef4: RefObject<any>;

  getFlatFakeList = memoizeOne(
    (myDict: {
      [key: string]: IEventInstanceViewModel[];
    }): ReadonlyArray<MyItem> => {
      if (!myDict) {
        return [];
      }

      const tempList: Array<MyItem> = [];

      const sm = parseInt(this.props.startMonth, 10);
      const isTwoMode = sm > 0;
      let printYear = -1;
      if (!isTwoMode && this.props.year !== undefined) {
        printYear = this.props.year;
      } else if (isTwoMode && this.props.yearFrom !== undefined) {
        printYear = this.props.yearFrom.getFullYear();
      }

      for (let count = 0, m = sm; count < 12; count++, m++) {
        const mindex = m % 12;
        const monthName = WheelConstants.monthNames[mindex];

        if (m === 12) {
          printYear++;
        }

        let py = ' ' + (printYear > 0 ? printYear : '');

        // Ugly hack to remove months that are empty
        if (myDict && myDict.hasOwnProperty(mindex)) {
          const myItemsThisMonth = myDict[mindex] as Array<
            IEventInstanceViewModel
          >;

          if (!myItemsThisMonth) {
            continue;
          } else if (myItemsThisMonth.length === 0) {
            continue;
          }
        }

        // Ugly hack part 2
        // Removes months that are filtered away
        if (!myDict.hasOwnProperty(mindex)) {
          continue;
        }

        tempList.push({
          isMonthItem: true,
          doIHaveAccess: true,
          monthName: monthName + py,
        });

        if (myDict && myDict.hasOwnProperty(mindex)) {
          const myItemsThisMonth = myDict[mindex] as Array<
            IEventInstanceViewModel
          >;

          if (myItemsThisMonth) {
            myItemsThisMonth.forEach(item => {
              const g = item;

              let doIhaveAccess = false;

              if (g.createdBy.id === this.props.me.id) {
                doIhaveAccess = true;
              } else if (this.props.me.isAdmin) {
                doIhaveAccess = true;
              } else {
                g.access &&
                  g.access.persons.length > 0 &&
                  g.access.persons.forEach((c: PersonAccess) => {
                    if (c.person.id === this.props.me.id) {
                      if (c.access === AccessEnum.WRITE) {
                        doIhaveAccess = true;
                      }
                    }
                  });
              }

              tempList.push({
                item: item,
                isMonthItem: false,
                doIHaveAccess: doIhaveAccess,
              });
            });
          }
        }
      }

      // WheelConstants.monthNames.forEach((monthName: string, mindex: number) => {
      //   tempList.push({
      //     isMonthItem: true,
      //     doIHaveAccess: true,
      //     monthName: monthName,
      //   });
      //
      //   if (myDict && myDict.hasOwnProperty(mindex)) {
      //     const myItemsThisMonth = myDict[mindex] as Array<
      //       IEventInstanceViewModel
      //     >;
      //     if (myItemsThisMonth) {
      //       myItemsThisMonth.forEach(item => {
      //         const g = item;
      //
      //         let doIhaveAccess = false;
      //         if (g.createdBy.id === this.props.me.id) {
      //           doIhaveAccess = true;
      //         } else {
      //           g.access &&
      //             g.access.persons.length > 0 &&
      //             g.access.persons.forEach((c: PersonAccess) => {
      //               if (c.person.id === this.props.me.id) {
      //                 if (c.access === AccessEnum.WRITE) {
      //                   doIhaveAccess = true;
      //                 }
      //               }
      //             });
      //         }
      //         if (this.props.me.isAdmin) {
      //           doIhaveAccess = true;
      //         }
      //
      //         tempList.push({
      //           item: item,
      //           isMonthItem: false,
      //           doIHaveAccess: doIhaveAccess,
      //         });
      //       });
      //     }
      //   }
      // });

      return tempList;
    }
  );

  rowRenderer = (
    myList: ReadonlyArray<MyItem>,
    props: ListRowProps
  ): React.ReactNode => {
    const item = myList[props.index];
    if (item.isMonthItem) {
      return (
        <MonthItem
          key={'virtual_' + props.index}
          style={Object.assign({}, props.style, {
            height: '36px',
            marginBottom: '8px',
            marginLeft: '10px',
          })}
        >
          {item.monthName}
        </MonthItem>
      );
    } else {
      const g = item.item;

      return (
        <div
          key={'virtb_' + props.index}
          style={Object.assign({}, props.style, {
            // width: '100%',
            height: '36px',
            // marginRight: '10px'
          })}
        >
          <TaskComp
            canWrite={item.doIHaveAccess}
            eventInstance={g}
            taskId={g.taskId}
            year={g.year}
            calendarCode={(this.props.me && this.props.me.calendarCode) || 'NA'}
            onClick={() => {
              // console.log('onclick task comp');
              this.setState({
                ringId: g.ringid,
                editEventInstanceData: {
                  taskId: g.taskId,
                  eventInstanceId: g.id || '',
                },
              });
            }}
            onCompleted={() => {
              this.playFancySound();
              safeInvokeDeprecated(this.props.onTempHack);
            }}
            onRestored={() => {
              // console.log('restored..');
              safeInvokeDeprecated(this.props.onTempHack);
            }}
            checkBoxColor={g.color}
            key={'virtual_' + props.index}
            style={{
              // width: '100%',
              // height: '36px',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          />
        </div>
      );
    }
  };

  getGroupedList = memoizeOne(
    (
      instances: Array<IEventInstanceViewModel>,
      isNothingSelected: boolean,
      isEverythingSelected: boolean,
      filter: IAnnualCycleFilter,
      hideFinished: boolean
    ) => {
      let sortedItems = _.sortBy(instances, 'isDone');
      if (hideFinished) {
        sortedItems = _.filter(sortedItems, c => !c.isDone);
      }

      const groupedInstances = _.groupBy(
        sortedItems,
        (instance: IEventInstanceViewModel) => {
          const month = moment(instance.endDate || undefined).month();
          return month;
        }
      );

      const showAllOrNothing = isNothingSelected || isEverythingSelected;
      let myDict = {}; // TODO: add type?

      if (showAllOrNothing) {
        // show ALL months:
        for (let i = 0; i < 12; i++) {
          myDict[i] = groupedInstances.hasOwnProperty(i)
            ? groupedInstances[i]
            : [];
        }
      } else {
        // only show SELECTED month groups (even if empty)
        for (let i = 0; i < 12; i++) {
          const mm = filter.selectedMonths[i];

          if (
            mm.isEntireMonthSelected ||
            (mm.selectedRings && mm.selectedRings.length > 0)
          ) {
            myDict[i] = groupedInstances.hasOwnProperty(i)
              ? groupedInstances[i]
              : [];
          }
        }
      }

      return myDict;
    }
  );

  constructor(
    props: IAnnualCycleRightMenuCompProps &
      InjectedIntlProps &
      IEditInstanceStatusProps
  ) {
    super(props);
    this.toastRef = React.createRef();
    this.myAudioRef4 = React.createRef();

    this.state = {
      hideFinnishedTasks: this.props.hideFinishedTasks,
      currentTab: 0,
      isCopyYearWheelOpen: false,
      editEventInstanceData: undefined,
      ringId: undefined,
      isEditEventInstanceOpen: false,
      showAssignedTasks: this.props.isMyAssignedTasksSelected,
    };
  }

  playFancySound = () => {
    if (this.myAudioRef4.current) {
      this.myAudioRef4.current.play();
    }
  };

  render() {
    const {
      filter,
      intl,
      isMyYearWheel,
      eventInstances,
      isEverythingSelected,
      isNothingSelected,
      loadingInstances,
    } = this.props;

    let myInstancesSorted = [];
    if (this.props.isCalendarPage) {
      eventInstances.forEach((c: any) => {
        if (new Date(c.endDate).getFullYear() === this.props.year) {
          myInstancesSorted.push(c);
        }
      });
    } else {
      eventInstances.forEach((c: any) => {
        if (new Date(c.endDate).getFullYear() === this.props.fromYear) {
          myInstancesSorted.push(c);
        } else if (
          this.props.toYear !== undefined &&
          new Date(c.endDate).getFullYear() === this.props.toYear
        ) {
          myInstancesSorted.push(c);
        }
      });
    }

    const myDict = this.getGroupedList(
      myInstancesSorted,
      isNothingSelected,
      isEverythingSelected,
      filter,
      this.state.hideFinnishedTasks
    );

    const myList = this.getFlatFakeList(myDict);

    return (
      <OuterContainer>
        <audio src={pling4} autoPlay={false} ref={this.myAudioRef4} />

        <TopDiv>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DateDiv>{intl.formatMessage(messages.activitiesTitle)}</DateDiv>
            {loadingInstances && (
              <div
                style={{
                  flex: '0 0 auto',
                  paddingLeft: '3em',
                }}
              >
                <CircleSpinnerAnimation
                  style={{
                    width: '1.5em',
                    height: '1.5em',
                    position: 'relative',
                    left: '-1em',
                    top: '0.4em',
                  }}
                />
                Lastar...
              </div>
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {this.props.isCalendarPage && (
              <div style={{ display: 'flex' }}>
                <Toaster ref={this.toastRef} theme={ToastType.LOADING} />
                <Button
                  leftIcon={MunikumIcons.copy}
                  size={ButtonSize.Small}
                  style={{ backgroundColor: '#FFF' }}
                  text={'Kopier'}
                  theme={ColorTheme.White}
                  onClick={() => {
                    this.setState({
                      isCopyYearWheelOpen: true,
                    });
                  }}
                  // disabled={loading}
                />

                <ShareButton
                  meta={this.props.calendar.id}
                  style={{
                    marginLeft: '.5em',
                    marginRight: '.5em',
                  }}
                  isSharing={this.props.isSharing}
                  onShare={(form: IShareForm) => {
                    safeInvoke(this.props.onShare, form);
                  }}
                />

                <Overlay isOpen={this.state.isCopyYearWheelOpen}>
                  <CopyAnnualCycle
                    initialTitle={this.props.calendar.title}
                    onSubmit={async (form: any) => {
                      this.setState({
                        isCopyYearWheelOpen: false,
                      });
                      const copyPromise = new Promise(
                        async (resolve, reject) => {
                          try {
                            const resultCopy = await myApolloClient.mutate({
                              mutation: COPY_CALENDAR,
                              variables: {
                                input: {
                                  id: this.props.calendar.id,
                                  title: form.title,
                                },
                              },
                            });
                            // myApolloClient
                            //   .mutate({
                            //     mutation: CREATE_CALENDAR_RING,
                            //     variables: {input: {
                            //       id: resultCopy.data.copyCalendar.id,
                            //       order: 1,
                            //       color:data.calendar &&
                            //         data.calendar &&
                            //         data.calendar.color
                            //
                            //       }},
                            //     refetchQueries: [
                            //       {
                            //         query: YEAR_WHEEL,
                            //         variables: {
                            //           year: this.state.filter.selectedYear,
                            //           person: data.me && data.me.id,
                            //         },
                            //       },
                            //     ],
                            //   })
                            // TODO: mutate add calendar to my rings

                            // TODO:_ remove this =)
                            await new Promise(resolve1 =>
                              setTimeout(() => resolve(), 7000)
                            );
                            resolve();
                          } catch (e) {
                            console.log(e, 'Error');

                            // FAIL
                            reject(e);
                          }
                        }
                      );
                      if (this.toastRef.current) {
                        const that = this;
                        const myId = this.toastRef.current.addToast({
                          message: (
                            <CopyToast
                              task={copyPromise}
                              onDone={() => {
                                // console.log('DONE, remove soon?');
                                setTimeout(() => {
                                  if (this.toastRef.current) {
                                    that.toastRef.current.removeToast(myId);
                                  }
                                }, 1000 * 45); // TODO: kor lang tiid treng vi her? =)
                              }}
                            />
                          ),
                          theme: ToastType.LOADING,
                          leftIcon: MunikumIcons.Info,
                          rightIcon: MunikumIcons.Cancel,
                          onClickRightIcon: e => {
                            this.toastRef.current.removeToast(myId);
                          },
                        });
                      }
                    }}
                    onClickCancel={() => {
                      this.setState({
                        isCopyYearWheelOpen: false,
                      });
                    }}
                  />
                </Overlay>
              </div>
            )}

            {(isMyYearWheel || this.props.canWrite) && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                }}
              >
                {!this.props.isCalendarPage && (
                  <Button
                    text={intl.formatMessage(commonMessages.myAssignedTasks)}
                    theme={ColorTheme.Blue}
                    innerButtonStyle={{
                      boxShadow: this.state.showAssignedTasks
                        ? 'inset 0 0 10px rgba(0,0,0, .6)'
                        : 'none',
                    }}
                    style={{
                      color: this.state.showAssignedTasks ? Colors.RED : '#fff',
                    }}
                    onClick={() => {
                      this.setState(
                        prevState => {
                          return {
                            showAssignedTasks: !prevState.showAssignedTasks,
                          };
                        },
                        () => {
                          safeInvoke(
                            this.props.onMagicRingClick,
                            this.state.showAssignedTasks
                          );
                        }
                      );
                    }}
                  />
                )}

                <Button
                  style={{ marginLeft: '1em' }}
                  text={intl.formatMessage(commonLabels.newTask)}
                  theme={ColorTheme.Red}
                  onClick={() => {
                    if (!this.props.doIhaveCalendars) {
                      alert(
                        intl.formatMessage(messages.youHaveToCreateAYearWheel)
                      );
                      safeInvoke(this.props.openCreateYearWheel);
                    } else {
                      safeInvokeDeprecated(this.props.onClickNewTask);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </TopDiv>
        <TopDiv2 style={{ justifyContent: 'space-between' }}>
          <Checkbox
            style={{
              flex: '0 0 auto',
              marginBottom: '2em',
            }}
            checked={this.state.hideFinnishedTasks}
            onClick={() => {
              this.setState(
                {
                  hideFinnishedTasks: !this.state.hideFinnishedTasks,
                },
                () => {
                  myApolloClient.mutate({
                    mutation: UPDATE_SETTINGS,
                    variables: {
                      input: {
                        calendarHideCompleted: this.state.hideFinnishedTasks,
                      },
                    },
                  });
                }
              );
            }}
            label={intl.formatMessage(messages.hideFinnishedTasks)}
          />
        </TopDiv2>

        <MediaQuery query={'(max-width: ' + (sizes.large - 1) + 'px)'}>
          <MyListContainer>
            {/* Old style render all of it (slow) everything in DOM... */}
            {!loadingInstances && myList.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  minHeight: '10em',
                  margin: 'auto',
                }}
              >
                Ingen aktivitetar
              </div>
            )}
            {myList.map((item, index) => {
              if (item.isMonthItem) {
                return (
                  <MonthItem
                    key={'real_' + index}
                    style={{
                      height: '36px',
                      marginBottom: '8px',
                      marginLeft: '10px',
                    }}
                  >
                    {item.monthName}
                  </MonthItem>
                );
              } else {
                const g = item.item;

                return (
                  <TaskComp
                    canWrite={item.doIHaveAccess}
                    eventInstance={g}
                    taskId={g.taskId}
                    year={this.props.year}
                    calendarCode={
                      (this.props.me && this.props.me.calendarCode) || 'NA'
                    }
                    onClick={() => {
                      // console.log('onclick task comp');
                      this.setState({
                        ringId: g.ringid,
                        editEventInstanceData: {
                          taskId: g.taskId,
                          eventInstanceId: g.id || '',
                        },
                      });
                    }}
                    onCompleted={() => {
                      this.playFancySound();
                      safeInvokeDeprecated(this.props.onTempHack);
                    }}
                    onRestored={() => {
                      // console.log('restored..');
                      safeInvokeDeprecated(this.props.onTempHack);
                    }}
                    checkBoxColor={g.color}
                    key={'real_' + index}
                    style={{
                      // width: '100%',
                      // height: '36px',
                      marginLeft: '10px',
                      marginRight: '10px',
                    }}
                  />
                );
              }
            })}
          </MyListContainer>
        </MediaQuery>

        <MediaQuery query={'(min-width: ' + sizes.large + 'px)'}>
          <MyListContainer>
            {!loadingInstances && myList.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  minHeight: '10em',
                  margin: 'auto',
                }}
              >
                Ingen aktivitetar
              </div>
            )}
            <AutoSizer>
              {props => {
                return (
                  <List
                    style={{
                      outline: 'none',
                    }}
                    width={props.width}
                    height={props.height}
                    rowCount={myList.length}
                    rowHeight={36 + 8} // NOTE: height of TaskComp + padding! Month items must have same height.. (or use other stuff)
                    rowRenderer={props2 => this.rowRenderer(myList, props2)}
                  />
                );
              }}
            </AutoSizer>
          </MyListContainer>
        </MediaQuery>

        {/* EDIT EVENT INSTANCE FORM */}
        <CleanDialog
          canEscapeKeyClose={false}
          canOutsideClickClose={true}
          showCloseWarning={CloseWarningOption.SIMPLE}
          isOpen={this.state.editEventInstanceData !== undefined}
          // isOpen={this.state.isEditEventInstanceOpen}
          onClose={() =>
            this.setState({
              editEventInstanceData: undefined,
              isEditEventInstanceOpen: false,
            })
          }
        >
          <EditEventInstanceForm
            ringId={this.props.isCalendarPage ? '3' : this.state.ringId}
            isCalendarPage={this.props.isCalendarPage}
            activeYearwheels={this.props.activeYearwheels}
            me={this.props.me}
            userHash={this.props.userHash}
            taskId={
              (this.state.editEventInstanceData &&
                this.state.editEventInstanceData.taskId) ||
              ''
            }
            eventInstanceId={
              (this.state.editEventInstanceData &&
                this.state.editEventInstanceData.eventInstanceId) ||
              ''
            }
            year={this.props.year}
            onClose={(forceReloadStuff: boolean) => {
              this.setState({ editEventInstanceData: undefined }, () => {
                // console.log('');
                safeInvokeDeprecated(this.props.onTempHack, forceReloadStuff);
              });
            }}
          />
        </CleanDialog>
      </OuterContainer>
    );
  }
}

export const AnnualCycleRightMenu = injectIntl(AnnualCycleRightMenuComp);
