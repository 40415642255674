import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import {
  Colors,
  ColorTheme,
  DEFAULT_COLOR_THEME,
  getThemeProps,
  safeInvokeDeprecated,
} from '../../common';
import { Placement, Popover } from '../Popover/Popover';
import { MunikumIcons } from '../../common/icons';

export interface ITooltipProps {
  /**
   * content to render inside tooltip
   */
  content: JSX.Element | string;

  /**
   * where to put tooltip, default is top
   */
  position?: Placement;

  /**
   * put tooltip in controlled mode setting isOpen explicitly
   */
  isOpen?: boolean;

  /**
   * set tooltip as open initially, behaves like a normal tooltip after this
   */
  isDefaultOpen?: boolean;

  /**
   * color theme
   */
  theme?: ColorTheme;

  /**
   * enable this to force update on popper-js position on EACH RENDER
   * don't use this unless you really have to, rendering will be twice as slow...
   */
  isAggressiveUpdateModeEnabled?: boolean;

  style?: CSSProperties;

  /**
   * delay in ms before showing tooltip, default 500ms
   */
  delayIn?: number;

  /**
   * delay in ms before hiding tooltip, default 200ms
   */
  delayOut?: number;
  onClick?: () => void;
}

interface ITooltipState {
  isHoveringTarget: boolean;
  isOpen: boolean;
}

const TooltipText = styled.div`
  font-family: Lato, sans-serif;
  font-size: 0.875em;
  line-height: 1.375em;
  position: relative;
`;

/**
 * A Tooltip component.
 * Wrap your target component with Tooltip and set content prop, example:
 * <Tooltip content={'tooltip text'}><div>hover me</div></Tooltip>
 */
export class Tooltip extends React.PureComponent<ITooltipProps, ITooltipState> {
  public static defaultProps: ITooltipProps = {
    position: 'top',
    content: '',
    theme: DEFAULT_COLOR_THEME,
  };

  // tslint:disable-next-line
  private hoverDelayTimer: any;

  constructor(props: ITooltipProps) {
    super(props);

    this.state = {
      isOpen: this.props.isDefaultOpen || this.props.isOpen || false,
      isHoveringTarget: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITooltipProps) {
    if (
      nextProps.isOpen !== undefined &&
      nextProps.isOpen !== this.state.isOpen
    ) {
      this.setState({
        ...this.state,
        isOpen: nextProps.isOpen,
      });
    }
  }

  handleTargetMouseEnter = (e: React.SyntheticEvent<HTMLElement>) => {
    // console.log('tooltip ENTER');

    this.setState(
      {
        isHoveringTarget: true,
      },
      () => {
        if (this.hoverDelayTimer !== undefined) {
          window.clearTimeout(this.hoverDelayTimer);
        }

        if (this.props.isOpen === undefined) {
          this.hoverDelayTimer = setTimeout(() => {
            // console.log ('SHOW if hovering still: ' + this.state.isHoveringTarget);
            if (this.state.isHoveringTarget) {
              this.setState({
                isOpen: true,
              });
            }
          }, this.props.delayIn || 500); // 300
        }
      }
    );
  };

  handleTargetMouseLeave = (e: React.SyntheticEvent<HTMLElement>) => {
    // console.log('tooltip LEAVE');

    this.setState(
      {
        isHoveringTarget: false,
      },
      () => {
        if (this.hoverDelayTimer !== undefined) {
          window.clearTimeout(this.hoverDelayTimer);
        }

        if (this.props.isOpen === undefined) {
          this.hoverDelayTimer = setTimeout(() => {
            // console.log (
            //   'HIDE if not hovering, is hovering: ' + this.state.isHoveringTarget
            // );
            if (!this.state.isHoveringTarget) {
              this.setState({
                isOpen: false,
              });
            }
          }, this.props.delayOut || 200); // 150
        }
      }
    );
  };

  componentWillUnmount() {
    if (this.hoverDelayTimer !== undefined) {
      window.clearTimeout(this.hoverDelayTimer);
    }
  }

  render() {
    const theme = getThemeProps(this.props.theme);
    const backgroundColor = theme.backgroundColor;
    const foregroundColor = theme.textColor;

    const {
      position,
      content,
      children,
      isAggressiveUpdateModeEnabled,
      style,
    } = this.props;
    const { isOpen } = this.state;

    const count = React.Children.count(children);
    if (count !== 1) {
      console.error(
        'Tooltip can only have ONE child and that should be the TARGET element! You passed me ' +
          count +
          ' children :-S'
      );
    }

    let targetElementOrText: any | undefined;

    if (React.isValidElement(children)) {
      const singleChild: any = React.Children.only(children);
      if (singleChild) {
        targetElementOrText = React.cloneElement(singleChild, {
          onMouseEnter: this.handleTargetMouseEnter,
          onMouseLeave: this.handleTargetMouseLeave,
        });
      }
    } else if (typeof children === 'string') {
      targetElementOrText = (
        <span
          onMouseEnter={this.handleTargetMouseEnter}
          onMouseLeave={this.handleTargetMouseLeave}
          style={{ position: 'relative' }}
        >
          {children}
        </span>
      );
    } else {
      throw Error(
        'unsupported children please supply a valid target element or text'
      );
    }

    // if content is string we set font size, if content is JSX it's up to YOU to set correct size ;)
    const isContentString = typeof content === 'string';
    const defaultStyle = {
      padding: '.5em 1em',
      maxWidth: '21.875em',
      textAlign: 'center',
      position: 'relative',
    };

    // BUG: style is not set correctly... why?
    const myStyle: CSSProperties = Object.assign({}, defaultStyle, style);
    // console.log('myStyle', style);

    return (
      <Popover
        target={targetElementOrText}
        isOpen={isOpen}
        position={position}
        style={myStyle}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        isDefaultOpen={false}
        isAggressiveUpdateModeEnabled={isAggressiveUpdateModeEnabled}
      >
        {isContentString && <TooltipText>{content}</TooltipText>}
        {!isContentString && content}
      </Popover>
    );
  }
}

interface IInfoTooltipProps {}

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  //background-color: pink;
  cursor: help;

  position: relative;
`;

const IconContainer = styled.div`
  width: 1em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  //background-color: deeppink;

  margin-top: 0.5em;

  position: relative;
`;

export class InfoTooltip extends React.PureComponent<
  ITooltipProps & IInfoTooltipProps,
  {}
> {
  renderIcon(iconComponent: IconType) {
    if (iconComponent === undefined || iconComponent === null) {
      return null;
    }

    const Icon = iconComponent;
    const x = '1em';

    return (
      <Icon
        fill={Colors.RED}
        width={x}
        height={x}
        style={{
          transition: 'fill .25s ease-in-out, color .25s ease-in-out',
        }}
      />
    );
  }

  render() {
    const { content, children, ...rest } = this.props;

    return (
      <Tooltip
        content={content}
        {...rest}
        onClick={() => {
          safeInvokeDeprecated(this.props.onClick);
        }}
      >
        <div style={{ position: 'relative' }}>
          <OuterContainer>
            <div style={{ position: 'relative' }}>{children}</div>
            <IconContainer>{this.renderIcon(MunikumIcons.Info)}</IconContainer>
          </OuterContainer>
        </div>
      </Tooltip>
    );
  }
}
