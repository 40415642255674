import moment from 'moment';
import { LocaleUtils } from 'react-day-picker/types/utils';

/**
 * custom locale implementation for react-day-picker using moment.js i18n
 */
export const localeUtils: LocaleUtils = {
  formatDay: (day: Date, locale: string) =>
    moment(day)
      .locale(locale)
      .format('ddd ll'),
  formatMonthTitle: (month, locale) =>
    moment(month)
      .locale(locale)
      .format('MMMM YYYY'),
  formatWeekdayShort: weekday => moment.weekdaysShort()[weekday],
  formatWeekdayLong: weekday => moment.weekdays()[weekday],
  getFirstDayOfWeek: locale => moment.localeData(locale).firstDayOfWeek(),
  getMonths: locale => {
    const months: Array<string> = [];
    let i = 0;
    while (i < 12) {
      months.push(
        moment()
          .locale(locale)
          .month(i)
          .format('MMMM')
      );
      i += 1;
    }

    const temp: [
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string,
      string
    ] = [
      months[0],
      months[1],
      months[2],
      months[3],
      months[4],
      months[5],
      months[6],
      months[7],
      months[8],
      months[9],
      months[10],
      months[11],
    ];

    return temp;
  },
};
