import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import Marked from 'marked';
import {
  Colors,
  getValidationBorderColor,
  IControlledProps,
  IDisabledProps,
  ILabelProps,
  IMessageProps,
  removeNonHTMLProps,
  transitionBorder,
} from '../../common';
import { ValidationSummary } from '..';
import { FieldGroup } from '../FieldGroup/FieldGroup';
import { FormLabel } from '../FormLabel/FormLabel';

const StyledTextArea = styled.div`

  box-sizing: border-box;
  
  // TODO: allow to override
  resize: none;
  
  min-width: 15em;
  min-height: 7.5em;
 
  border-radius: 0.25em;
  background-color: ${Colors.WHITE};
  font-size: 0.875em;
  line-height: 1.1875em;
  font-weight: 500;
  padding: 0.55em 0.75em 0.55em 0.9375em;
  
  overflow: scroll;
  
  font-family: Lato, sans-serif;
  
  color: ${Colors.BLACK} 
  transition: border-color 0.25s ease-in-out;
  border: 1px solid ${(props: IMessageProps) =>
    getValidationBorderColor(props)};
  &::placeholder {
    color: ${Colors.GREYPLACEHOLDER};
  }
  // TODO: add CSS to hide blue outline. div is active on mousedown, but on mouseup child element gets active. And focus/focus-within doesn't work...
  &:active {
    border-color: ${Colors.BLACK};
    outline: none;
  }
  
  &:disabled {
    border: 1px solid ${Colors.GREY};
    color: ${Colors.GREYDISABLEDTEXT};
    background-color: ${Colors.GREY};
  }
  
  ${transitionBorder()};
`;

export interface ITextAreaMarkedProps {
  // name: string;
  // placeholder?: string;
  // todo: implement:
  // resize?: string;
}

export type ITextAreaState = {
  id?: string;
};

export class TextAreaMarked extends React.PureComponent<
  ITextAreaMarkedProps &
    IControlledProps &
    ILabelProps &
    IMessageProps &
    IDisabledProps &
    React.HTMLProps<HTMLInputElement>,
  ITextAreaState
> {
  UNSAFE_componentWillMount() {
    const id = _.uniqueId('txtField-');
    this.setState({
      id: id,
    });
  }

  render() {
    const {
      name,
      label,
      onChange,
      onBlur,
      value,
      info,
      success,
      warning,
      error,
      disabled,
      readOnly,
      ...rest
    } = this.props;
    const { id } = this.state;

    let rawMarkdown = '';
    if (value !== undefined) {
      rawMarkdown = value as string;
    }

    const isEditable = !disabled && !readOnly;

    return (
      <FieldGroup>
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <StyledTextArea
          contentEditable={isEditable}
          id={id}
          // name={name}
          onChange={onChange}
          onBlur={onBlur}
          // value={value}
          // disabled={disabled}
          error={error}
          warning={warning}
          success={success}
          info={info}
          {...removeNonHTMLProps(rest)}
          dangerouslySetInnerHTML={{
            __html: Marked(rawMarkdown),
          }}
        />
        <ValidationSummary
          info={info}
          success={success}
          warning={warning}
          error={error}
        />
      </FieldGroup>
    );
  }
}
