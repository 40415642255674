import { MunikumIcons } from '../common/icons';
import * as React from 'react';
import { CSSProperties } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import styled from 'styled-components';

import {
  AccessEnum,
  AllSectorsQuery,
  AllSectorsVariables,
  ContentStatus,
  ContentVisibilityEnum,
  TopicCategoryEnum,
} from '../../models/types';
import { commonLabels } from '../language/commonLabels';
import { IHttpLink } from '../../services/models/actionValue';
import { EditLinksView } from '../actionValue/EditLinksView';
import { Button, ButtonSize, TextField, ValidationMessage } from '../basic';
import { DropDownSelect } from '../basic/DropDownSelect/DropDownSelect';
import { commonMessages } from '../language/commonMessages';
import { metaMessages } from '../../services/models/contentMeta';
import {
  Colors,
  ColorTheme,
  getNamesAndValues,
  hideOutline,
  insetShadow,
  isReallyEmpty,
  MessageType,
  safeInvoke,
  transitionBorder,
} from '../common';
import { ITopicCreateOrUpdateForm } from '../../services/topicService';
import {
  AnnualCycleAddMembersCard,
  SmallText,
} from '../annualCycle/AnnualCycleAddMembersCard';
import {
  MembersOfAnnualCycleCard,
  MembersOfAnnualCycleCardEveryoneComp,
  typeToList,
} from '../annualCycle/AnnualCycleMembers';
import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';
import ToggleButton from '../basic/ToggleButton/ToggleButton';
import * as _ from 'lodash';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { ExternalLink } from '../basic/Structural/links';
import { MuTextField } from '../basic/MuEditor/MuTextField';
import { convertFromRaw } from 'draft-js';

interface ITopicFormProps {
  onSave?: (form: ITopicCreateOrUpdateForm) => void;
  onCancel?: () => void;
  initialData?: ITopicCreateOrUpdateForm;
  errorMessage?: string;
  disabled?: boolean;
  style?: CSSProperties;
  isTopicEdit: boolean;
  onDelete?: () => void;
}

const ButtsCont = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const RightContainer = styled.div`
  flex: 1 1 auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  padding: 1.875em;
`;
const LeftContainer = styled.div`
  flex: 1 1 auto;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;

  max-width: 700px;
  padding: 1.875em;
`;
const Container = styled.div`
  display: block;
  @media (min-width: ${1280}px) {
    display: flex;
  }
`;
export const DisabledTextField = styled.textarea`
  resize: none;

  box-sizing: border-box;

  //min-width: 15em;
  //max-width: 100%;
  width: 100%;
  border-radius: 0.25em;
  background-color: ${Colors.WHITE};
  font-size: 14px;
  line-height: 1.1875em;

  height: 100%;

  font-weight: 500;
  padding: 0.5em;

  // 1,3125
  // 1,3125 + .75 = 2,0625

  font-family: Lato, sans-serif;

  color: ${Colors.BLACK};

  &::placeholder {
    color: ${Colors.GREYPLACEHOLDER};
  }
  &:focus:enabled {
    // TODO: what todo when focus + error/success? let focus-border override?
    border-color: ${Colors.BLACK};
  }
  &:disabled {
    border: 1px solid ${Colors.GREY};
    color: ${Colors.GREYDISABLEDTEXT};
    background-color: ${Colors.GREY};
  }

  ${insetShadow()};
  ${hideOutline()};
  ${transitionBorder()};
`;

const Error = styled.div`
  color: #ff5c39;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Lato, sans-serif;
`;
interface ITopicFormState {
  //   title: string;
  //   description: string;
  //   body?: string;
  //   status?: ContentStatus;
}
const ALL_SECTORS = gql`
  query AllSectors {
    allSectors {
      id
      title
    }
  }
`;

let permissionOptions: string = '';

class AllSectorsQueryCom extends Query<AllSectorsQuery, AllSectorsVariables> {}

class TopicFormComp extends React.PureComponent<
  InjectedIntlProps & ITopicFormProps,
  ITopicFormState
> {
  statusList: { [id: string]: string };
  categories: { [id: string]: string };

  constructor(props: InjectedIntlProps & ITopicFormProps) {
    super(props);

    // if (props.initialData === undefined) {
    //   this.state = { title: '', body: '', description: '', status: undefined };
    // } else {
    //   this.state = {
    //     title: props.initialData.meta.title,
    //     description: props.initialData.meta.description,
    //     body: props.initialData.content.body,
    //     status: props.initialData.meta.status,
    //   };
    // }

    const contentStatuses: { [id: string]: string } = {};
    getNamesAndValues(ContentStatus).map(x => {
      contentStatuses[x.value] = props.intl.formatMessage(metaMessages[x.name]);
    });

    this.statusList = contentStatuses;

    let categoryKeys = Object.keys(TopicCategoryEnum);
    let categories: { [id: string]: string } = {};

    for (let i = 0; i < categoryKeys.length; i++) {
      categories[categoryKeys[i]] = categoryKeys[i];
    }
    delete contentStatuses.TO_APPROVAL;
    delete contentStatuses.OBSOLETE;

    this.categories = categories;
  }

  // handleStatusChanged = (id: string) => {
  //   this.setState({ status: ContentStatus[id] });
  // };
  //
  // handleChangeTitle = (e: any) => {
  //   this.setState({ title: e.target.value });
  //   console.log('setting state title to' + '' + e.target.value);
  // };
  //
  // handleChangeDescription = (e: any) => {
  //   this.setState({ description: e.target.value });
  //   console.log('setting state descritption to' + '' + e.target.value);
  // };
  //
  // handleChangeBody = (e: any) => {
  //   this.setState({ body: e.target.value });
  //   console.log('setting state body to' + '' + e.target.value);
  // };
  checkIfArrayHasReadMembers = formik => {
    if (formik.access.persons) {
      for (let i = 0; i < formik.access.persons.length; i++) {
        if (formik.access.persons[i].access === AccessEnum.READ) {
          return true;
        }
      }
    }
    if (formik.access.groups) {
      for (let i = 0; i < formik.access.groups.length; i++) {
        if (formik.access.groups[i].access === AccessEnum.READ) {
          return true;
        }
      }
    }

    if (formik.access.organizationGroups) {
      for (let i = 0; i < formik.access.organizationGroups.length; i++) {
        if (formik.access.organizationGroups[i].access === AccessEnum.READ) {
          return true;
        }
      }
    }

    if (formik.access.organizations) {
      for (let i = 0; i < formik.access.organizations.length; i++) {
        if (formik.access.organizations[i].access === AccessEnum.READ) {
          return true;
        }
      }
    }
  };
  deleteFromList = obj => {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].access === AccessEnum.READ) {
        obj.splice(i, 1);
        i--;
      }
    }
  };

  handleCancel = () => {
    safeInvoke(this.props.onCancel);
  };

  handleClick = () => {
    // alert('Topic saved');
  };

  // handleSave = () => {
  //   safeInvoke(this.props.onSave);
  //   console.log(this.state.title);
  // };

  render() {
    const { intl, initialData } = this.props;
    const categoryList = {
      TOPIC: 'Temagrupper',
      SUBJECT_GROUP: 'Faggrupper',
    };

    return (
      <Formik
        initialValues={initialData}
        validateOnBlur={true}
        onSubmit={(values: ITopicCreateOrUpdateForm) => {
          safeInvoke(this.props.onSave, values);
        }}
        validationSchema={yup.object().shape({
          title: yup
            .string()
            .required()
            .label(intl.formatMessage(commonLabels.title)),
          description: yup
            .string()
            .required()
            .label(intl.formatMessage(commonLabels.description)),
          category: yup
            .string()
            .required()
            .label(intl.formatMessage(commonLabels.category)),
          // status: yup.string().required(),
          links: yup.array().label(intl.formatMessage(commonLabels.links)),
        })}
        render={(formikProps: FormikProps<ITopicCreateOrUpdateForm>) => {
          // console.log(formikProps.values.access, 'sector100');

          return (
            <form
              onSubmit={formikProps.handleSubmit}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Container>
                <LeftContainer>
                  {this.props.disabled && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '.5em',
                        marginTop: '-1em',
                      }}
                    >
                      <MunikumIcons.warning
                        style={{
                          color: Colors.RED,
                          marginRight: '.2em',
                        }}
                      />
                      <SmallText
                        style={{
                          fontSize: '12px',
                          color: Colors.YELLOW,
                          marginLeft: '0em',
                        }}
                      >
                        {intl.formatMessage(commonMessages.noWriteAccessTopic)}
                      </SmallText>
                    </div>
                  )}
                  <TextField
                    label={intl.formatMessage(commonLabels.title)}
                    name={'title'}
                    value={formikProps.values.title}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    disabled={this.props.disabled}
                    style={{ width: '385px' }}
                    placeholder={intl.formatMessage(commonLabels.title)}
                    error={
                      formikProps.touched.title && formikProps.errors.title
                    }
                  />
                  <MuTextField
                    shouldEditorHaveFiles={false}
                    style={{ width: '385px' }}
                    doIHaveAccessToAddFiles={true}
                    label={this.props.intl.formatMessage(commonLabels.text)}
                    placeholder={this.props.intl.formatMessage(
                      commonLabels.descriptionPlaceholder
                    )}
                    files={[]}
                    onSubmit={(value, files) => {
                      if (
                        !isReallyEmpty(
                          convertFromRaw(JSON.parse(value)).getPlainText()
                        )
                      ) {
                        formikProps.setFieldValue('description', value);
                        formikProps.setFieldValue(
                          'descriptionText',
                          convertFromRaw(JSON.parse(value)).getPlainText()
                        );
                      } else {
                        formikProps.setFieldValue('description', '');
                        formikProps.setFieldValue('descriptionText', '');
                      }
                    }}
                    value={formikProps.values.description}
                  />
                  {formikProps.touched.description &&
                    formikProps.errors.description && (
                      <Error>
                        <MunikumIcons.Cancel
                          style={{ marginRight: '.25em', marginBottom: '.5em' }}
                        />
                        {this.props.intl.formatMessage(
                          commonMessages.descriptionRequired
                        )}
                      </Error>
                    )}
                  {/*<TextArea*/}
                  {/*label={intl.formatMessage(commonLabels.description)}*/}
                  {/*name={'description'}*/}
                  {/*disabled={this.props.disabled}*/}
                  {/*value={formikProps.values.description}*/}
                  {/*onChange={formikProps.handleChange}*/}
                  {/*onBlur={formikProps.handleBlur}*/}
                  {/*style={{ width: '385px' }}*/}
                  {/*placeholder={intl.formatMessage(commonLabels.description)}*/}
                  {/*error={*/}
                  {/**/}
                  {/*}*/}
                  {/*/>*/}

                  {/*<TextArea*/}
                  {/*label={intl.formatMessage(commonLabels.body)}*/}
                  {/*name={'body'}*/}
                  {/*disabled={this.props.disabled}*/}
                  {/*value={formikProps.values.body}*/}
                  {/*onChange={formikProps.handleChange}*/}
                  {/*onBlur={formikProps.handleBlur}*/}
                  {/*style={{ width: '385px' }}*/}
                  {/*placeholder={intl.formatMessage(commonLabels.body)}*/}
                  {/*error={*/}
                  {/*formikProps.errors.body && formikProps.touched.body*/}
                  {/*}*/}
                  {/*/>*/}

                  <EditLinksView
                    style={{ width: '385px', marginTop: '1em' }}
                    title={intl.formatMessage(commonLabels.links)}
                    links={formikProps.values.links}
                    // disabled={this.props.disabled}
                    onLinksChanged={(newLinks: ReadonlyArray<IHttpLink>) => {
                      formikProps.setFieldValue('links', newLinks);
                    }}
                  />

                  {/* NOTE: Janne said that we should remove sector 4.4-19 =) */}
                  {/*<AllSectorsQueryCom query={ALL_SECTORS}>*/}
                  {/*{({ loading, error, data }) => {*/}
                  {/*if (data && data.allSectors) {*/}
                  {/*// convert data to dropdown format*/}
                  {/*let sectors: { [id: string]: string } = {};*/}
                  {/*data.allSectors.map(sector => {*/}
                  {/*sectors[sector.id] = sector.title;*/}
                  {/*});*/}

                  {/*return (*/}
                  {/*<DropDownSelect*/}
                  {/*fieldGroupStyle={{ width: '385px' }}*/}
                  {/*name={'sector'}*/}
                  {/*label={intl.formatMessage(commonLabels.sector)}*/}
                  {/*items={sectors}*/}
                  {/*value={formikProps.values.sector}*/}
                  {/*onChange={formikProps.handleChange}*/}
                  {/*error={*/}
                  {/*formikProps.errors.status &&*/}
                  {/*formikProps.touched.status*/}
                  {/*}*/}
                  {/*/>*/}
                  {/*);*/}
                  {/*} else {*/}
                  {/*return <div />;*/}
                  {/*}*/}
                  {/*}}*/}
                  {/*</AllSectorsQueryCom>*/}
                  {/*<KostraFunctionDropDown*/}
                  {/*disabled={this.props.disabled}*/}
                  {/*style={{*/}
                  {/*width: '385px',*/}
                  {/*}}*/}
                  {/*selectedItems={formikProps.values.kostraFunctions}*/}
                  {/*onSelectedItemsChanged={selectedItems =>*/}
                  {/*formikProps.setFieldValue(*/}
                  {/*'kostraFunctions',*/}
                  {/*selectedItems*/}
                  {/*)*/}
                  {/*}*/}
                  {/*/>*/}
                  <DropDownSelect
                    disabled={this.props.disabled}
                    showEmptyOption={false}
                    fieldGroupStyle={{ width: '385px' }}
                    name={'category'}
                    label={intl.formatMessage(commonLabels.category)}
                    items={categoryList}
                    value={formikProps.values.category}
                    onChange={formikProps.handleChange}
                    error={
                      formikProps.errors.category &&
                      formikProps.touched.category
                    }
                  />
                  {/*<DropDownSelect*/}
                  {/*disabled={this.props.disabled}*/}
                  {/*fieldGroupStyle={{ width: '385px' }}*/}
                  {/*name={'status'}*/}
                  {/*label={'Status'}*/}
                  {/*items={this.statusList}*/}
                  {/*value={(formikProps.values.status || 0).toString()}*/}
                  {/*onChange={formikProps.handleChange}*/}
                  {/*onBlur={formikProps.handleBlur}*/}
                  {/*error={formikProps.errors.status}*/}
                  {/*/>*/}

                  {this.props.errorMessage && (
                    <ValidationMessage
                      type={MessageType.ERROR}
                      msg={'Update failed' + this.props.errorMessage}
                    />
                  )}
                </LeftContainer>
                <RightContainer>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom:
                          formikProps.values.access.isPrivate === true
                            ? '0em'
                            : '1.5em',
                      }}
                    >
                      <div
                        style={{
                          fontSize: '22px',
                          fontWeight: 'bold',
                          fontFamily: 'Lato',
                          color: '#333',
                          marginRight: '1em',
                        }}
                      >
                        {intl.formatMessage(commonMessages.Access)}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <MunikumIcons.Globe
                          style={{
                            height: '1.2em',
                            width: '1.2em',
                            color: !formikProps.values.access.isPrivate
                              ? '#FF5C39'
                              : '#333',
                          }}
                        />
                        <Tooltip
                          position={'top-start'}
                          content={
                            formikProps.values.access.isPrivate
                              ? intl.formatMessage(
                                  commonMessages.makeYearWheelPublic
                                )
                              : intl.formatMessage(
                                  commonMessages.makeYearWheelPrivate
                                )
                          }
                        >
                          <div>
                            <ToggleButton
                              style={{
                                marginLeft: '.5em',
                                marginRight: '.3em',
                                marginTop: '.5em',
                              }}
                              checked={formikProps.values.access.isPrivate}
                              onClick={() => {
                                if (!this.props.disabled) {
                                  const hasReadAccess = this.checkIfArrayHasReadMembers(
                                    formikProps.values
                                  );
                                  if (formikProps.values.access.isPrivate) {
                                    if (hasReadAccess) {
                                      if (
                                        confirm(
                                          intl.formatMessage(
                                            commonMessages.MakeYearWheelPublicWarning
                                          )
                                        )
                                      ) {
                                        formikProps.setFieldValue(
                                          'access.isPrivate',
                                          !formikProps.values.access.isPrivate
                                        );
                                        const personCopy = _.cloneDeep(
                                          formikProps.values.access.persons
                                        );
                                        const personGroupCopy = _.cloneDeep(
                                          formikProps.values.access.groups
                                        );
                                        const orgCopy = _.cloneDeep(
                                          formikProps.values.access
                                            .organizations
                                        );
                                        const orgGroupCopy = _.cloneDeep(
                                          formikProps.values.access
                                            .organizationGroups
                                        );
                                        this.deleteFromList(personCopy);
                                        this.deleteFromList(personGroupCopy);
                                        this.deleteFromList(orgCopy);
                                        this.deleteFromList(orgGroupCopy);
                                        formikProps.setFieldValue(
                                          'access.persons',
                                          personCopy
                                        );
                                        formikProps.setFieldValue(
                                          'access.groups',
                                          personGroupCopy
                                        );
                                        formikProps.setFieldValue(
                                          'access.organizations',
                                          orgCopy
                                        );
                                        formikProps.setFieldValue(
                                          'access.organizationGroups',
                                          orgGroupCopy
                                        );
                                      }
                                    } else {
                                      formikProps.setFieldValue(
                                        'access.isPrivate',
                                        !formikProps.values.access.isPrivate
                                      );
                                    }
                                  } else if (
                                    !formikProps.values.access.isPrivate
                                  ) {
                                    formikProps.setFieldValue(
                                      'access.isPrivate',
                                      !formikProps.values.access.isPrivate
                                    );
                                  }
                                }
                              }}
                            />
                          </div>
                        </Tooltip>
                        <MunikumIcons.Padlock
                          style={{
                            height: '1.2em',
                            width: '1.2em',
                            color: !formikProps.values.access.isPrivate
                              ? '#333'
                              : '#FF5C39',
                          }}
                        />
                      </div>
                    </div>
                    {formikProps.values.access.isPrivate && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          marginBottom:
                            formikProps.values.access.isPrivate === true
                              ? '.5em'
                              : '0',
                        }}
                      >
                        <MunikumIcons.eye
                          style={{
                            height: '1.2em',
                            width: '1.2em',
                            color:
                              formikProps.values.access.visibility ===
                              ContentVisibilityEnum.PRIVATE
                                ? '#333'
                                : '#FF5C39',
                          }}
                        />
                        <Tooltip
                          position={'top-start'}
                          content={
                            formikProps.values.access.visibility ===
                            ContentVisibilityEnum.PRIVATE
                              ? intl.formatMessage(
                                  commonMessages.MakeYearWheelVisible
                                )
                              : intl.formatMessage(
                                  commonMessages.makeYearWheelNotVisible
                                )
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <ToggleButton
                              onClick={() => {
                                if (!this.props.disabled) {
                                  formikProps.values.access.visibility ===
                                  ContentVisibilityEnum.PRIVATE
                                    ? formikProps.setFieldValue(
                                        'access.visibility',
                                        ContentVisibilityEnum.PUBLIC
                                      )
                                    : formikProps.setFieldValue(
                                        'access.visibility',
                                        ContentVisibilityEnum.PRIVATE
                                      );
                                }
                              }}
                              style={{
                                marginLeft: '.5em',
                                marginRight: '.3em',
                                marginTop: '.5em',
                              }}
                              checked={
                                formikProps.values.access.visibility ===
                                ContentVisibilityEnum.PRIVATE
                              }
                              name={'da'}
                            />
                          </div>
                        </Tooltip>
                        <MunikumIcons.eyeSlash
                          style={{
                            height: '1.2em',
                            width: '1.2em',
                            color:
                              formikProps.values.access.visibility ===
                              ContentVisibilityEnum.PRIVATE
                                ? '#FF5C39'
                                : '#333',
                          }}
                        />
                      </div>
                    )}
                    <div style={{ marginBottom: '.5em' }}>
                      <ExternalLink
                        style={{
                          color: '#333',
                          fontSize: '12px',
                          borderBottom: '1px solid #333',
                        }}
                        href={
                          'https://support.munikum.no/hc/no/articles/360027728352-Gi-lese-og-skrivetilgang-til-%C3%A5rshjulet'
                        }
                      >
                        {intl.formatMessage(
                          commonMessages.doYouNotFindThePerson
                        )}
                      </ExternalLink>
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                      <AnnualCycleAddMembersCard
                        disabled={this.props.disabled}
                        isPrivate={formikProps.values.access.isPrivate}
                        style={{ marginBottom: '1em', width: '460px' }}
                        showOnlyPerson={false}
                        addGroup={(group, permission) => {
                          let isAlreadyInArray = false;
                          for (
                            let i = 0;
                            i < formikProps.values.access.groups.length;
                            i++
                          ) {
                            if (
                              formikProps.values.access.groups[i].group.id ===
                              group.id
                            ) {
                              isAlreadyInArray = true;
                              break;
                            }
                          }
                          if (isAlreadyInArray) {
                            return '';
                          } else {
                            const toSend = formikProps.values.access.groups.slice();
                            toSend.push({
                              access: permission,
                              group: group,
                            });
                            formikProps.setFieldValue('access.groups', toSend);
                          }
                        }}
                        addOrg={(org, permission) => {
                          let isAlreadyInArray = false;
                          for (
                            let i = 0;
                            i < formikProps.values.access.organizations.length;
                            i++
                          ) {
                            if (
                              formikProps.values.access.organizations[i]
                                .organization.id === org.id
                            ) {
                              isAlreadyInArray = true;
                              break;
                            }
                          }
                          if (isAlreadyInArray) {
                            return '';
                          } else {
                            const toSend = formikProps.values.access.organizations.slice();

                            toSend.push({
                              access: permission,
                              organization: org,
                            });
                            formikProps.setFieldValue(
                              'access.organizations',
                              toSend
                            );
                          }
                        }}
                        addOrgGroup={(orgGroup, permission) => {
                          let isAlreadyInArray = false;
                          if (formikProps.values.access.organizationGroups) {
                            for (
                              let i = 0;
                              i <
                              formikProps.values.access.organizationGroups
                                .length;
                              i++
                            ) {
                              if (
                                formikProps.values.access.organizationGroups[i]
                                  .organizationGroup.id === orgGroup.id
                              ) {
                                isAlreadyInArray = true;
                                break;
                              }
                            }
                          }
                          if (isAlreadyInArray) {
                            return '';
                          } else {
                            if (formikProps.values.access.organizationGroups) {
                              const toSend = formikProps.values.access.organizationGroups.slice();
                              toSend.push({
                                access: permission,
                                organizationGroup: orgGroup,
                              });

                              formikProps.setFieldValue(
                                'access.organizationGroups',
                                toSend
                              );
                            }
                          }
                        }}
                        showTag={false}
                        canChangePermission={
                          formikProps.values.access.isPrivate
                        }
                        isPermissionActive={true}
                        title={intl.formatMessage(commonMessages.to)}
                        permissionLevel={permissionLevel => {
                          permissionOptions = permissionLevel.valueOf();
                        }}
                        addMember={(person, permission: AccessEnum) => {
                          let isAlreadyInArray = false;
                          for (
                            let i = 0;
                            i < formikProps.values.access.persons.length;
                            i++
                          ) {
                            if (
                              formikProps.values.access.persons[i].person.id ===
                              person.id
                            ) {
                              isAlreadyInArray = true;
                              break;
                            }
                          }
                          if (isAlreadyInArray) {
                            return '';
                          } else {
                            // if (person.id !== me.id) {

                            const toSend = formikProps.values.access.persons.slice();
                            toSend.push({
                              access: permission,
                              person: person,
                            });

                            formikProps.setFieldValue('access.persons', toSend);
                            // }
                          }
                        }}
                      />

                      <MembersOfAnnualCycleCard
                        type={typeToList.PERSONS}
                        disabled={this.props.disabled}
                        canChangePermission={false}
                        canRemove={false}
                        key={formikProps.values.createdBy.id}
                        profilePic={formikProps.values.createdBy.picture}
                        name={formikProps.values.createdBy.name}
                        organization={
                          formikProps.values.createdBy.organization &&
                          formikProps.values.createdBy.organization.name
                        }
                        department={formikProps.values.createdBy.department}
                        role={formikProps.values.createdBy.role}
                        handleRemoveMember={() => ''}
                        img={
                          <StaticImage
                            width={21}
                            height={21}
                            type={IStaticImageType.ORGANIZATION}
                            filename={
                              (formikProps.values.createdBy.organization &&
                                formikProps.values.createdBy.organization
                                  .organizationImage) ||
                              ''
                            }
                          />
                        }
                        permissionLevel={{
                          value: 'OWNER',
                          name: intl.formatMessage(commonMessages.owner),
                        }}
                      />
                      {formikProps.values.access &&
                        formikProps.values.access.isPrivate && (
                          <DisabledTextField
                            style={{ width: '460px' }}
                            name={'t'}
                            disabled={true}
                            value={intl.formatMessage(
                              commonMessages.infoBoxGroup
                            )}
                          />
                        )}
                      <div>
                        {formikProps.values.access.persons &&
                          formikProps.values.access.persons.map(
                            (person: any, i) => {
                              return (
                                <div key={'bla' + i}>
                                  <MembersOfAnnualCycleCard
                                    disabled={this.props.disabled}
                                    type={typeToList.PERSONS}
                                    canChangePermission={
                                      formikProps.values.access.isPrivate
                                    }
                                    canRemove={true}
                                    onChangeSelected={selectedKey => {
                                      // const personGroupCopyState = this.state.currentPersons.slice();
                                      const personGroupCopy =
                                        formikProps.values.access.persons;
                                      const getEnum =
                                        selectedKey === 'WRITE'
                                          ? AccessEnum.WRITE
                                          : AccessEnum.READ;
                                      // personGroupCopyState[
                                      //   i
                                      //   ].access = getEnum;
                                      personGroupCopy[i].access = getEnum;
                                      formikProps.setFieldValue(
                                        'access.persons',
                                        personGroupCopy
                                      );
                                    }}
                                    key={i}
                                    profilePic={person.picture}
                                    name={person.person.name}
                                    organization={
                                      person.person.organization &&
                                      person.person.organization.name
                                    }
                                    department={person.person.department}
                                    role={person.person.role}
                                    handleRemoveMember={() => {
                                      const personGroupCopyFormik = formikProps.values.access.persons.slice();
                                      personGroupCopyFormik.splice(i, 1);

                                      formikProps.setFieldValue(
                                        'access.persons',
                                        personGroupCopyFormik
                                      );
                                    }}
                                    img={
                                      <StaticImage
                                        width={21}
                                        height={21}
                                        type={IStaticImageType.ORGANIZATION}
                                        filename={
                                          (person.person.organization &&
                                            person.person.organization
                                              .organizationImage) ||
                                          ''
                                        }
                                      />
                                    }
                                    permissionLevel={{
                                      value: 'WRITE',
                                      name:
                                        person.access === 'WRITE'
                                          ? 'skrivetilgang'
                                          : 'Lesetilgang',
                                    }}
                                  />
                                </div>
                              );
                            }
                          )}
                      </div>
                      {/*Groups*/}
                      {formikProps.values.access.groups &&
                        formikProps.values.access.groups.map(
                          (group: any, i) => {
                            return (
                              <div key={'bla' + i}>
                                <MembersOfAnnualCycleCard
                                  disabled={this.props.disabled}
                                  type={typeToList.PERSONGROUPS}
                                  canChangePermission={
                                    formikProps.values.access.isPrivate
                                  }
                                  canRemove={true}
                                  onChangeSelected={selectedKey => {
                                    const groupCopy =
                                      formikProps.values.access.groups;
                                    const getEnum =
                                      selectedKey === 'WRITE'
                                        ? AccessEnum.WRITE
                                        : AccessEnum.READ;
                                    groupCopy[i].access = getEnum;

                                    formikProps.setFieldValue(
                                      'access.groups',
                                      groupCopy
                                    );
                                  }}
                                  key={i}
                                  name={group.group.title}
                                  handleRemoveMember={() => {
                                    const groupCopyFormik = formikProps.values.access.groups.slice();
                                    groupCopyFormik.splice(i, 1);

                                    formikProps.setFieldValue(
                                      'access.groups',
                                      groupCopyFormik
                                    );
                                  }}
                                  img={
                                    <StaticImage
                                      width={21}
                                      height={21}
                                      type={IStaticImageType.ORGANIZATION}
                                    />
                                  }
                                  permissionLevel={{
                                    value: 'WRITE',
                                    name:
                                      group.access === 'WRITE'
                                        ? 'skrivetilgang'
                                        : 'Lesetilgang',
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      {/*organizations*/}
                      {formikProps.values.access.organizations &&
                        formikProps.values.access.organizations.map(
                          (org: any, index) => {
                            return (
                              <div key={'bla' + index}>
                                <MembersOfAnnualCycleCard
                                  disabled={this.props.disabled}
                                  type={typeToList.ORGANIZATIONS}
                                  canChangePermission={
                                    formikProps.values.access.isPrivate
                                  }
                                  canRemove={true}
                                  onChangeSelected={selectedKey => {
                                    const orgCopy =
                                      formikProps.values.access.organizations;
                                    const getEnum =
                                      selectedKey === 'WRITE'
                                        ? AccessEnum.WRITE
                                        : AccessEnum.READ;

                                    orgCopy[index].access = getEnum;

                                    formikProps.setFieldValue(
                                      'access.organizations',
                                      orgCopy
                                    );
                                  }}
                                  key={index}
                                  name={org.organization.name}
                                  handleRemoveMember={() => {
                                    const orgCopyFormik = formikProps.values.access.organizations.slice();

                                    orgCopyFormik.splice(index, 1);

                                    formikProps.setFieldValue(
                                      'access.organizations',
                                      orgCopyFormik
                                    );
                                  }}
                                  img={
                                    <StaticImage
                                      width={21}
                                      height={21}
                                      type={IStaticImageType.ORGANIZATION}
                                      filename={
                                        (org.organization &&
                                          org.organization.organizationImage) ||
                                        ''
                                      }
                                    />
                                  }
                                  permissionLevel={{
                                    value: 'WRITE',
                                    name:
                                      org.access === 'WRITE'
                                        ? 'skrivetilgang'
                                        : 'Lesetilgang',
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      {/*OrganizationGroups*/}
                      {formikProps.values.access.organizationGroups &&
                        formikProps.values.access.organizationGroups.map(
                          (orgGroup: any, indexPosition) => {
                            return (
                              <div key={'bla' + indexPosition}>
                                <MembersOfAnnualCycleCard
                                  disabled={this.props.disabled}
                                  type={typeToList.ORGANIZATIONGROUPS}
                                  canChangePermission={
                                    formikProps.values.access.isPrivate
                                  }
                                  canRemove={true}
                                  key={indexPosition}
                                  name={orgGroup.organizationGroup.title}
                                  handleRemoveMember={() => {
                                    const orgGroupCopyFormik = formikProps.values.access.organizationGroups.slice();
                                    orgGroupCopyFormik.splice(indexPosition, 1);
                                    formikProps.setFieldValue(
                                      'access.organizationGroups',
                                      orgGroupCopyFormik
                                    );
                                  }}
                                  onChangeSelected={selectedKey => {
                                    const orgGroupCopy =
                                      formikProps.values.access
                                        .organizationGroups;
                                    const getEnum =
                                      selectedKey === 'WRITE'
                                        ? AccessEnum.WRITE
                                        : AccessEnum.READ;
                                    orgGroupCopy[
                                      indexPosition
                                    ].access = getEnum;
                                    formikProps.setFieldValue(
                                      'access.organizationGroups',
                                      orgGroupCopy
                                    );
                                  }}
                                  img={
                                    <StaticImage
                                      width={21}
                                      height={21}
                                      type={IStaticImageType.ORGANIZATION}
                                    />
                                  }
                                  permissionLevel={{
                                    value: 'WRITE',
                                    name:
                                      orgGroup.access === 'WRITE'
                                        ? 'skrivetilgang'
                                        : 'Lesetilgang',
                                  }}
                                />
                              </div>
                            );
                          }
                        )}
                      {!formikProps.values.access.isPrivate && (
                        <MembersOfAnnualCycleCardEveryoneComp
                          // style={{marginTop: '-1.2em'}}
                          onCancelClick={() => {
                            formikProps.setFieldValue(
                              'access.isPrivate',
                              !formikProps.values.access.isPrivate
                            );
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <ButtsCont>
                    {this.props.isTopicEdit && (
                      <Button
                        text={intl.formatMessage(commonMessages.delete)}
                        size={ButtonSize.Medium}
                        theme={ColorTheme.Yellow}
                        onClick={() => {
                          safeInvoke(this.props.onDelete);
                        }}
                        leftIcon={MunikumIcons.Cancel}
                        style={{ marginRight: '8px' }}
                      />
                    )}
                    <Button
                      text={intl.formatMessage(commonMessages.cancel)}
                      size={ButtonSize.Medium}
                      theme={ColorTheme.White}
                      onClick={() => this.handleCancel()}
                      leftIcon={MunikumIcons.Cancel}
                    />
                    <Button
                      text={intl.formatMessage(commonMessages.save)}
                      size={ButtonSize.Medium}
                      theme={ColorTheme.Red}
                      type={'submit'}
                      disabled={this.props.disabled}
                      leftIcon={MunikumIcons.Save}
                      onClick={this.handleClick}
                      style={{ marginLeft: '.5em' }}
                    />
                  </ButtsCont>
                </RightContainer>
              </Container>
            </form>
          );
        }}
      />
    );
  }
}

export const TopicForm = injectIntl(TopicFormComp);

TopicForm.defaultProps = {
  disabled: false,
};
