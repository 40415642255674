import * as React from 'react';
import styled from 'styled-components';
import { isFunction } from '../../common';

type IFilterFunc = (phrase: string) => void;

export interface IUltimateFilterBoxProps {
  filterFunc: IFilterFunc;
}

interface IUltimateFilterBoxState {
  currentFilter: string;
}

const FilterBox = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 5px;
`;

const FilterInput = styled.input`
  flex: 1;
  margin-bottom: 5px;
`;

export class UltimateFilterBox extends React.Component<
  IUltimateFilterBoxProps,
  IUltimateFilterBoxState
> {
  constructor(props: IUltimateFilterBoxProps) {
    super(props);

    this.state = {
      currentFilter: '',
    };
  }

  handleChange = (e: any) => {
    this.setState(
      {
        currentFilter: e.target.value,
      },
      () => {
        if (
          this.props.filterFunc !== undefined &&
          isFunction(this.props.filterFunc)
        ) {
          this.props.filterFunc(this.state.currentFilter);
        }
      }
    );
  };

  render() {
    const { currentFilter } = this.state;

    return (
      <FilterBox>
        <FilterInput
          type="text"
          placeholder="Filtrer liste..."
          value={currentFilter}
          onChange={this.handleChange}
        />
      </FilterBox>
    );
  }
}
