import React, { CSSProperties, useState } from 'react';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import { LIKE, UN_LIKE } from '../../../graphql/QueriesAndMutations';
import { DISCUSSION } from '../../../containers/Discussion/DiscussionItemPage/DiscussionItemPage';
import { GET_CALENDAR_ISFOLLOWING } from '../../../containers/Dashboard/AnnualCycleDashboard';
import { MunikumIcons } from '../../common/icons';
import { Colors, safeInvokeDeprecated } from '../../common';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { ACTION_VALUE } from '../../../containers/ActionValue/ActionValueItemPage/ActionValueItemPage';
import {
  ActionValueQuery,
  ActionValueVariables,
  DiscussionComments,
  DiscussionQuery,
  DiscussionVariables,
} from '../../../models/types';
import * as _ from 'lodash';
import { ICalendarInputQuery } from '../../annualCycle/AnnualCycleCard';

const ClapDiv = styled.div`
  transition: 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const TOPIC_CLAP = gql`
  query TopicClap($id: ID!) {
    topic(id: $id) {
      id
      likes
      isLiked
      isFollowing
    }
  }
`;
export enum ObjectType {
  ACTION_VALUE,
  TOPIC,
  DISCUSSION,
  CALENDAR,
  COMMENTDISCUSSION,
  SUBCOMMENTDISCUSSION,
  COMMENTACTIONVALUE,
  SUBCOMMENTACTIONVALUE,
}
interface IClapProps {
  /*
    Id for the that should be liked
   */
  idToClap: string;

  idToRefetch: string;
  /***
    Boolean to check if the object is already clapped
   ***/
  isClapped: boolean;
  /*
    Object type that should be clapped, is used to determine what refetch query that should be used
   */
  ObjectToClap: ObjectType;
  /*
    border color for the clap icon
   */
  /**
   * comment id if the object that you should mutate is a subcomment
   */
  commentId?: string;

  /***
   *
   */
  borderClapColor?: string;

  style?: CSSProperties;

  onClickClap?: () => void;

  inputCalendarQuery?: ICalendarInputQuery;
}
export function Clap(props: IClapProps) {
  const [isClapped, setClapped] = useState(props.isClapped);
  const findRefetchQuery = (type: ObjectType) => {
    if (type === ObjectType.ACTION_VALUE) {
      return ACTION_VALUE;
    } else if (type === ObjectType.TOPIC) {
      return TOPIC_CLAP;
    } else if (type === ObjectType.CALENDAR) {
      return GET_CALENDAR_ISFOLLOWING;
    } else if (type === ObjectType.DISCUSSION) {
      return DISCUSSION;
    }
  };
  const handleClick = () => {
    if (isClapped) {
      if (props.ObjectToClap === ObjectType.CALENDAR) {
        myApolloClient
          .mutate({
            mutation: UN_LIKE,
            variables: { id: props.idToClap },
          })
          .then(c => {
            myApolloClient
              .query({
                query: findRefetchQuery(props.ObjectToClap),
                variables: { id: props.idToRefetch },
                fetchPolicy: 'network-only',
              })
              .then(g => {});
          });
      } else if (props.ObjectToClap === ObjectType.TOPIC) {
        myApolloClient
          .mutate({
            mutation: UN_LIKE,
            variables: { id: props.idToClap },
          })
          .then(c => {
            myApolloClient
              .query({
                query: findRefetchQuery(props.ObjectToClap),
                variables: { id: props.idToRefetch },
                fetchPolicy: 'network-only',
              })
              .then(g => {});
          });
      } else if (props.ObjectToClap === ObjectType.ACTION_VALUE) {
        myApolloClient
          .mutate({
            mutation: UN_LIKE,
            variables: { id: props.idToClap },
          })
          .then(c => {
            myApolloClient
              .query({
                query: findRefetchQuery(props.ObjectToClap),
                variables: { id: props.idToRefetch },
                fetchPolicy: 'network-only',
              })
              .then(g => {});
          });
      } else if (props.ObjectToClap === ObjectType.DISCUSSION) {
        myApolloClient
          .mutate({
            mutation: UN_LIKE,
            variables: { id: props.idToClap },
          })
          .then(c => {
            myApolloClient
              .query({
                query: findRefetchQuery(props.ObjectToClap),
                variables: { id: props.idToRefetch },
                fetchPolicy: 'network-only',
              })
              .then(g => {});
          });
      } else {
        myApolloClient.mutate({
          mutation: UN_LIKE,
          variables: { id: props.idToClap },
          update: (proxy, mutationResult) => {
            // if (props.ObjectToClap === ObjectType.ACTION_VALUE) {
            //   try {
            //     const data = proxy.readQuery<
            //       ActionValueQuery,
            //       ActionValueVariables
            //       >({ query: ACTION_VALUE, variables: { id: props.idToClap } });
            //     data.actionValue.likes -= 1;
            //     data.actionValue.isLiked = false;
            //     proxy.writeQuery({
            //       query: ACTION_VALUE,
            //       variables: { id: props.idToClap },
            //       data: data,
            //     });
            //   } catch (e) {
            //     console.log(e);
            //   }
            //   // return ACTION_VALUE;
            // } else if (props.ObjectToClap === ObjectType.TOPIC) {
            //   try {
            //     const data = proxy.readQuery<TopicClapQuery, TopicClapVariables>({
            //       query: TOPIC_CLAP,
            //       variables: { id: props.idToClap },
            //     });
            //     data.topic.likes -= 1;
            //     data.topic.isLiked = false;
            //     proxy.writeQuery({
            //       query: TOPIC_CLAP,
            //       variables: { id: props.idToClap },
            //       data: data,
            //     });
            //   } catch (e) {
            //     console.log(e);
            //   }
            // } else if (props.ObjectToClap === ObjectType.CALENDAR) {
            //   try {
            //     const data = proxy.readQuery<
            //       AllCalendarsQuery,
            //       AllCalendarsVariables
            //       >({
            //       query: ALL_CALENDARS,
            //       variables: {
            //         limit: props.inputCalendarQuery.limit,
            //         offset: props.inputCalendarQuery.offset,
            //         order: props.inputCalendarQuery.order,
            //         sort: props.inputCalendarQuery.sort,
            //       },
            //     });
            //     const myCalendar = _.find(data.allCalendars.nodes, {'id': props.idToClap});
            //     myCalendar.likes -= 1;
            //     myCalendar.isLiked = false;
            //     proxy.writeQuery({
            //       query: ALL_CALENDARS,
            //       variables: {
            //         limit: props.inputCalendarQuery.limit,
            //         offset: props.inputCalendarQuery.offset,
            //         order: props.inputCalendarQuery.order,
            //         sort: props.inputCalendarQuery.sort,
            //       },
            //       data: data,
            //     });
            //   } catch (e) {
            //     console.log(e);
            //   }
            //
            //   return GET_CALENDAR_ISFOLLOWING;
            // } else if (props.ObjectToClap === ObjectType.DISCUSSION) {
            //   try {
            //     const data = proxy.readQuery<
            //       DiscussionQuery,
            //       DiscussionVariables
            //       >({ query: DISCUSSION, variables: { id: props.idToClap } });
            //     data.discussion.likes -= 1;
            //     data.discussion.isLiked = false;
            //     proxy.writeQuery({
            //       query: DISCUSSION,
            //       variables: { id: props.idToClap },
            //       data: data,
            //     });
            //   } catch (e) {
            //     console.log(e);
            //   }
            // } else
            if (props.ObjectToClap === ObjectType.COMMENTDISCUSSION) {
              try {
                const data = proxy.readQuery<
                  DiscussionQuery,
                  DiscussionVariables
                >({ query: DISCUSSION, variables: { id: props.idToRefetch } });
                const myID: DiscussionComments = _.find(
                  data.discussion.comments,
                  { id: props.idToClap }
                );
                myID.likes -= 1;
                myID.isLiked = false;
                proxy.writeQuery({
                  query: DISCUSSION,
                  variables: { id: props.idToRefetch },
                  data: data,
                });
              } catch (e) {
                console.log(e);
              }
            } else if (props.ObjectToClap === ObjectType.SUBCOMMENTDISCUSSION) {
              try {
                const data = proxy.readQuery<
                  DiscussionQuery,
                  DiscussionVariables
                >({ query: DISCUSSION, variables: { id: props.idToRefetch } });
                const comment = _.find(data.discussion.comments, {
                  id: props.commentId,
                });
                const subComment = _.find(comment.replies, {
                  id: props.idToClap,
                });
                subComment.likes -= 1;
                subComment.isLiked = false;
                proxy.writeQuery({
                  query: DISCUSSION,
                  variables: { id: props.idToRefetch },
                  data: data,
                });
              } catch (e) {
                console.log(e);
              }
            } else if (props.ObjectToClap === ObjectType.COMMENTACTIONVALUE) {
              try {
                const data = proxy.readQuery<
                  ActionValueQuery,
                  ActionValueVariables
                >({
                  query: ACTION_VALUE,
                  variables: { id: props.idToRefetch },
                });
                const comment = _.find(data.actionValue.comments, {
                  id: props.idToClap,
                });
                comment.likes -= 1;
                comment.isLiked = true;
                proxy.writeQuery({
                  query: ACTION_VALUE,
                  variables: { id: props.idToRefetch },
                  data: data,
                });
              } catch (e) {
                console.log(e);
              }
            } else if (
              props.ObjectToClap === ObjectType.SUBCOMMENTACTIONVALUE
            ) {
              try {
                const data = proxy.readQuery<
                  ActionValueQuery,
                  ActionValueVariables
                >({
                  query: ACTION_VALUE,
                  variables: { id: props.idToRefetch },
                });
                const comment = _.find(data.actionValue.comments, {
                  id: props.commentId,
                });
                const subComment = _.find(comment.replies, {
                  id: props.idToClap,
                });
                subComment.likes -= 1;
                subComment.isLiked = true;
                proxy.writeQuery({
                  query: ACTION_VALUE,
                  variables: { id: props.idToRefetch },
                  data: data,
                });
              } catch (e) {
                console.log(e);
              }
            }
          },
        });
      }

      //   .then(c => {
      //   myApolloClient.query({
      //     query: findRefetchQuery(props.ObjectToClap),
      //     variables: { id: props.commentId },
      //     fetchPolicy: 'network-only',
      //   });
      // });
    } else {
      if (props.ObjectToClap === ObjectType.CALENDAR) {
        myApolloClient
          .mutate({
            mutation: LIKE,
            variables: { id: props.idToClap },
          })
          .then(c => {
            myApolloClient
              .query({
                query: findRefetchQuery(props.ObjectToClap),
                variables: { id: props.idToRefetch },
                fetchPolicy: 'network-only',
              })
              .then(g => {});
          });
      } else if (props.ObjectToClap === ObjectType.TOPIC) {
        myApolloClient
          .mutate({
            mutation: LIKE,
            variables: { id: props.idToClap },
          })
          .then(c => {
            myApolloClient
              .query({
                query: findRefetchQuery(props.ObjectToClap),
                variables: { id: props.idToRefetch },
                fetchPolicy: 'network-only',
              })
              .then(g => {});
          });
      } else if (props.ObjectToClap === ObjectType.ACTION_VALUE) {
        myApolloClient
          .mutate({
            mutation: LIKE,
            variables: { id: props.idToClap },
          })
          .then(c => {
            myApolloClient
              .query({
                query: findRefetchQuery(props.ObjectToClap),
                variables: { id: props.idToRefetch },
                fetchPolicy: 'network-only',
              })
              .then(g => {});
          });
      } else if (props.ObjectToClap === ObjectType.DISCUSSION) {
        myApolloClient
          .mutate({
            mutation: LIKE,
            variables: { id: props.idToClap },
          })
          .then(c => {
            myApolloClient
              .query({
                query: findRefetchQuery(props.ObjectToClap),
                variables: { id: props.idToRefetch },
                fetchPolicy: 'network-only',
              })
              .then(g => {});
          });
      } else {
        myApolloClient.mutate({
          mutation: LIKE,
          variables: { id: props.idToClap },
          update: (proxy, mutationResult) => {
            // if (props.ObjectToClap === ObjectType.ACTION_VALUE) {
            //   try {
            //     const data = proxy.readQuery<
            //       ActionValueQuery,
            //       ActionValueVariables
            //       >({ query: ACTION_VALUE, variables: { id: props.idToClap } });
            //     data.actionValue.likes += 1;
            //     data.actionValue.isLiked = true;
            //     proxy.writeQuery({
            //       query: ACTION_VALUE,
            //       variables: { id: props.idToClap },
            //       data: data,
            //     });
            //   } catch (e) {
            //     console.log(e);
            //   }
            // } else if (props.ObjectToClap === ObjectType.TOPIC) {
            //   try {
            //     const data = proxy.readQuery<TopicClapQuery, TopicClapVariables>({
            //       query: TOPIC_CLAP,
            //       variables: { id: props.idToClap },
            //     });
            //     data.topic.likes += 1;
            //     data.topic.isLiked = true;
            //     proxy.writeQuery({
            //       query: TOPIC_CLAP,
            //       variables: { id: props.idToClap },
            //       data: data,
            //     });
            //   } catch (e) {
            //     console.log(e);
            //   }
            // } else if (props.ObjectToClap === ObjectType.CALENDAR) {
            //   try {
            //     const data = proxy.readQuery<
            //       AllCalendarsQuery,
            //       AllCalendarsVariables
            //       >({
            //       query: ALL_CALENDARS,
            //       variables: {
            //         limit: props.inputCalendarQuery.limit,
            //         offset: props.inputCalendarQuery.offset,
            //         order: props.inputCalendarQuery.order,
            //         sort: props.inputCalendarQuery.sort,
            //       },
            //     });
            //     const myCalendar = _.find(data.allCalendars.nodes, {'id': props.idToClap});
            //     myCalendar.likes += 1;
            //     myCalendar.isLiked = true;
            //     proxy.writeQuery({
            //       query: ALL_CALENDARS,
            //       variables: {
            //         limit: props.inputCalendarQuery.limit,
            //         offset: props.inputCalendarQuery.offset,
            //         order: props.inputCalendarQuery.order,
            //         sort: props.inputCalendarQuery.sort,
            //       },
            //       data: data,
            //     });
            //   } catch (e) {
            //     console.log(props.idToClap, 'calendarid');
            //     console.log(e);
            //   }
            // } else if (props.ObjectToClap === ObjectType.DISCUSSION) {
            //   try {
            //     const data = proxy.readQuery<
            //       DiscussionQuery,
            //       DiscussionVariables
            //       >({ query: DISCUSSION, variables: { id: props.idToClap } });
            //     data.discussion.likes += 1;
            //     data.discussion.isLiked = true;
            //     proxy.writeQuery({
            //       query: DISCUSSION,
            //       variables: { id: props.idToClap },
            //       data: data,
            //     });
            //   } catch (e) {
            //     console.log(e);
            //   }
            // } else
            if (props.ObjectToClap === ObjectType.COMMENTDISCUSSION) {
              try {
                const data = proxy.readQuery<
                  DiscussionQuery,
                  DiscussionVariables
                >({ query: DISCUSSION, variables: { id: props.idToRefetch } });
                const myID: DiscussionComments = _.find(
                  data.discussion.comments,
                  { id: props.idToClap }
                );
                myID.likes += 1;
                myID.isLiked = true;
                proxy.writeQuery({
                  query: DISCUSSION,
                  variables: { id: props.idToRefetch },
                  data: data,
                });
              } catch (e) {
                console.log(e);
              }
            } else if (props.ObjectToClap === ObjectType.SUBCOMMENTDISCUSSION) {
              try {
                const data = proxy.readQuery<
                  DiscussionQuery,
                  DiscussionVariables
                >({ query: DISCUSSION, variables: { id: props.idToRefetch } });
                const comment = _.find(data.discussion.comments, {
                  id: props.commentId,
                });
                const subComment = _.find(comment.replies, {
                  id: props.idToClap,
                });
                subComment.likes += 1;
                subComment.isLiked = true;
                proxy.writeQuery({
                  query: DISCUSSION,
                  variables: { id: props.idToRefetch },
                  data: data,
                });
              } catch (e) {
                console.log(e);
              }
            } else if (props.ObjectToClap === ObjectType.COMMENTACTIONVALUE) {
              try {
                const data = proxy.readQuery<
                  ActionValueQuery,
                  ActionValueVariables
                >({
                  query: ACTION_VALUE,
                  variables: { id: props.idToRefetch },
                });
                const comment = _.find(data.actionValue.comments, {
                  id: props.idToClap,
                });
                comment.likes += 1;
                comment.isLiked = true;
                proxy.writeQuery({
                  query: ACTION_VALUE,
                  variables: { id: props.idToRefetch },
                  data: data,
                });
              } catch (e) {
                console.log(e);
              }
            } else if (
              props.ObjectToClap === ObjectType.SUBCOMMENTACTIONVALUE
            ) {
              try {
                const data = proxy.readQuery<
                  ActionValueQuery,
                  ActionValueVariables
                >({
                  query: ACTION_VALUE,
                  variables: { id: props.idToRefetch },
                });
                const comment = _.find(data.actionValue.comments, {
                  id: props.commentId,
                });
                const subComment = _.find(comment.replies, {
                  id: props.idToClap,
                });
                subComment.likes += 1;
                subComment.isLiked = true;
                proxy.writeQuery({
                  query: ACTION_VALUE,
                  variables: { id: props.idToRefetch },
                  data: data,
                });
              } catch (e) {
                console.log(e);
              }
            }
          },
        });
      }

      // .then(c => {
      //   myApolloClient.query({
      //     query: findRefetchQuery(props.ObjectToClap),
      //     variables: { id: props.commentId},
      //     fetchPolicy: 'network-only',
      //   });
      // });
    }
    setClapped(!isClapped);
  };

  return (
    <ClapDiv
      style={props.style}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        handleClick();
        safeInvokeDeprecated(props.onClickClap);
      }}
    >
      <MunikumIcons.Clap
        fill={isClapped ? Colors.RED : props.borderClapColor || '#333'}
      />
    </ClapDiv>
  );
}
