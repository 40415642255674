import * as React from 'react';
import Helmet from 'react-helmet';

export interface IMyHelmetProps {
  title?: string;
  username?: string;
  email?: string;
}

const isProd = process.env.NODE_ENV === 'production';

/**
 * MyHelmet
 * if you want to override <head><title>some title</title></head>,
 * just insert <Helmet><title>some title</title></Helmet> in sub components!! that will override title
 * but not piwik or other scripts
 */
export class MyHelmet extends React.PureComponent<IMyHelmetProps> {
  onChangeClientState = () => {};

  render() {
    const { username, email, title } = this.props;

    return (
      <Helmet
        onChangeClientState={this.onChangeClientState}
        titleTemplate={'Årshjul.no - %s'}
        defaultTitle={'Årshjul.no'}
      >
        <meta charSet="utf-8" />
        <title>{title}</title>

        {isProd && (
          <script type="script/javascript">
            {username &&
              email &&
              window.$zopim &&
              window.$zopim(function() {
                // comment thomas: what if username and email is undefined? ok?
                window.$zopim.livechat.setName(username);
                window.$zopim.livechat.setEmail(email);
                window.$zopim.livechat.addTags('Munikum');
              })}
          </script>
        )}
      </Helmet>
    );
  }
}
