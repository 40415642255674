export enum LoginType {
  Refresh,
  Username,
  Pin,
  RefreshOnLoad,
}

/***
 * result returned on login and refreshToken
 */
export interface IAuthResult {
  username: string;
  roles?: string[];
  token_type: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  loginType?: LoginType;
}
