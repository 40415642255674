import * as React from 'react';
import { CSSProperties, useEffect } from 'react';
import { Colors, safeInvokeDeprecated } from '../../common';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import { MunikumIcons } from '../../common/icons';

export enum ToastType {
  SUCCESS_BLUE = 'SUCCESS_BLUE',
  ERROR_RED = 'ERROR_RED',
  ERROR_BLACK = 'ERROR_BLACK',
  LOADING = 'LOADING',
  WHITE = 'WHITE',
}

const ToastCont = styled.div`
  display: flex;
  position: relative;
  border-radius: 4px;
  color: #fff;
  padding: 1em;
  font-weight: bold;
  font-family: Lato, sans-serif;
  font-size: 14px;
  z-index: 200;
  // user-select: text;
  min-width: 100px;
  margin-top: 0.5em;
  overflow: hidden;

  pointer-events: auto;

  background-color: ${(props: IToastProps) => myFunc(props.theme).background};
`;

const CancelDiv = styled.div`
  transition: ease-in-out 0.2s;
  display: flex;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;
const myFunc = (color: ToastType): IStyleProps => {
  switch (color) {
    case ToastType.ERROR_BLACK:
      return {
        background: Colors.BLACK,
        icon: MunikumIcons.Cancel,
      };
    case ToastType.ERROR_RED:
      return {
        background: Colors.RED,
        icon: MunikumIcons.Cancel,
      };
    case ToastType.WHITE:
      return {
        background: Colors.WHITE,
        icon: MunikumIcons.Cancel,
      };
    case ToastType.SUCCESS_BLUE:
      return {
        background: Colors.BLUE,
        icon: MunikumIcons.Cancel,
      };
    case ToastType.LOADING:
      return {
        background: Colors.YELLOW,
        icon: MunikumIcons.Cancel,
      };

    default:
      return {
        background: Colors.BLUE,
        icon: MunikumIcons.Cancel,
      };
  }
};

interface IStyleProps {
  background?: string;
  icon?: IconType;
}
export interface IToastProps {
  theme?: ToastType;
  message?: React.ReactNode;
  onDismiss?: (didTimeoutExpire: boolean) => void;
  timeout?: number;
  leftIcon?: IconType;
  noBackground?: boolean;
  rightIcon?: IconType;
  onClickRightIcon?: (e: any) => void;
  styleRightIcon?: CSSProperties;
}

export function Toast(props: IToastProps) {
  const myTheme = props.theme || ToastType.SUCCESS_BLUE;
  const myStyle = myFunc(myTheme);
  if (props.timeout) {
    useEffect(() => {
      let timer = setTimeout(() => {
        safeInvokeDeprecated(props.onDismiss, true);
      }, props.timeout || 5000);
      return () => {
        clearTimeout(timer);
      };
    });
  }
  return (
    <>
      {props.noBackground && (
        <div style={{ marginLeft: '1em' }}>{props.message || 'Kopier'}</div>
      )}
      {!props.noBackground && (
        <ToastCont theme={props.theme} id="test">
          <div
            style={{
              marginRight: '.5em',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            {' '}
            {props.leftIcon
              ? props.leftIcon({ size: '1.5em', color: '#fff' })
              : null}{' '}
          </div>
          <div style={{ marginLeft: '1em' }}>{props.message || 'Kopier'}</div>
          <CancelDiv
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              safeInvokeDeprecated(props.onClickRightIcon);
            }}
          >
            {props.rightIcon
              ? props.rightIcon({ size: '1.5em', color: '#fff' })
              : null}
          </CancelDiv>
        </ToastCont>
      )}
    </>
  );
}
