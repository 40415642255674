export default `
### Munikum® - Brukarvilkår

Munikum AS, ved dagleg leiar, er ansvarleg for behandling av personopplysningar som blir registrert 
om deg. Brukarvilkåra inneheld opplysningar du har krav på når det samlast inn opplysningar frå 
nettstaden vår (personopplysningsloven § 19), generell informasjon om korleis vi behandlar 
personopplysningar (personopplysningsloven § 18 1. ledd) samt ansvarsavgrensingar knytt til innhaldet i Munikum®. 

#### Personvern

**Om personopplysningar**                                                                                          
Personopplysningar er alle opplysningar og vurderingar som er knytt til deg som enkeltperson. 
Personopplysningane vi registrerar ved bruk av tenesta Munikum® er opplysningar som du sjølv 
registrera i din brukarprofil, som namn, kommunenamn og funksjon i kommunen, samt opplysningar 
om din aktivitet på nettsida, kva nettlesar du nyttar og adresseområde for datanettverket du jobbar ifrå (IP-adresse).  

**Behandling av personopplysningar på munikum.no**                                                                                   
Dagleg leiar har ansvaret for Munikum AS si behandling av personopplysningar på munikum.no.
Behandlingsgrunnlaget er samtykke frå den enkelte. 

Vi nyttar Amazon Web Services og analyseverktøyet Matomo på vår nettstad, og har databehandlaravtalar 
med desse. Databehandlaravtalane sikrar at desse verktøya kunn behandlar personopplysningar som avtalt 
med Munikum AS. Informasjon frå desse verktøya blir ikkje utlevert frå Munikum AS til andre aktørar. Ta 
kontakt med oss dersom du ønsker meir informasjon om, eller innsyn i, databehandlaravtalane.

**Nettstatistikk**                                                                                 
Munikum AS samlar inn opplysningar om besøkande på munikum.no. Formålet med dette er å utarbeide 
statistikk som vi brukar til å forbetre og vidareutvikle tenesta. Eksempel på kva statistikken gir svar 
på er kor mange som besøker ulike sider, kor lenge besøket varar, kva nettstad brukarane kjem frå og kva 
nettlesar som nyttast. 

**Bruk av informasjonskapslar**                                                                                
Informasjonskapslar (cookies) er små tekstfiler som plasserast på di datamaskin når du lastar ned ei 
nettside. Analyseverktøyet Matomo plassera informasjonskapslar på di datamaskin når du besøker munikum.no. 
Dette gjerast for at vi skal kunne samle inn statistikk, og slettast etter 90 dagar dersom du ikkje har 
vore aktiv på sida. 

**Brukarprofil i Munikum®**                                                                                      
Din brukarprofil med namn, kommunenamn og kva funksjon du har i kommunen vil være synleg for andre brukarar 
av nettsida. Du kan når som helst endre dine registrerte personopplysningar på munikum.no.

**Rettar**                                                                                                                                
Alle som spør har rett på grunnleggande informasjon om behandling av personopplysningar i ei bedrift eller 
ein organisasjon etter personopplysningslova sin § 18, 1.ledd. Munikum AS har gitt denne informasjonen i 
desse brukarvilkåra, og vil vise til dette ved eventuell førespurnad. Brukarar som er registrert på munikum.no 
har rett på innsyn i eigne opplysningar. Vedkomande har også rett til å be om at uriktige eller mangelfulle 
opplysningar blir retta, supplert eller sletta. 

Du kan når som helst trekke ditt samtykke heilt eller delvis til behandling av dine personopplysningar. 
Registrerte personopplysningar om deg hos Munikum AS vil då bli sletta. Dine personopplysningar vil fjernast 
frå alle innlegg, dokument og andre filer som du sjølv eller i samarbeid med andre har produsert i Munikum®.n

Dersom du går over i ny stilling i kommunen eller av annan årsak ikkje lenger fortsette i din funksjon i 
kommunen, vil data (innlegg, dokument og anna) som du har produsert og delt i Munikum® bli vidareført til 
personen som overtar din funksjon. På den måten vil kunnskap og arbeid som er delt og innhenta gjennom Munikum® 
for eit funksjons/tenesteområde vere tilgjengeleg for kommunen sjølv om brukarar endrar eller avsluttar sine 
stillingsforhold.

### Munikum® - Ansvarsavgrensing

Samtlege brukarar av Munikum® skal ha lest og akseptert følgande informasjon:
Det er brukarar av Munikum® som er ansvarlege for innhaldet i innlegg og anna som blir produsert og delt av 
brukarane. Det er også brukarane som er ansvarlege for at det som delast overheld relevante lover, forskrifter 
m.m. Munikum AS er såleis ikkje ansvarleg for innhaldet i Munikum® som blir produsert og delt av brukarane.

Enkelte lenker på nettsida går til nettstadar administrert av bedrifter eller organisasjonar som ikkje kan bli 
kontrollert av Munikum AS. Munikum AS er ikkje ansvarleg for desse lenkene, eller eksterne nettstadar sitt 
innhald eller funksjonalitet.

All bruk av informasjon i Munikum®, for eksempel som beslutningsgrunnlag for investeringar eller anna, skjer 
på eigen risiko. Munikum AS fråskriv seg eitkvart ansvar for resultatet av at ein brukar, på ulike måtar, nyttar 
informasjon delt i Munikum®. Såleis kan Munikum AS ikkje haldast ansvarleg dersom informasjonen delt i Munikum® 
fører til direkte eller indirekte skade eller tap, uteblitt gevinst eller inntekt for brukaren/kommunen eller 
andre som har tatt del i innhaldet på Munikum®.

Munikum AS kan overvåke og eventuelt fjerne innhald på munikum.no etter eige skjønn. Munikum AS har også rett 
til å avbryte eller på annan måte avgrense bruken av Munikum®. Brukaren skal vere merksam på at Munikum AS ikkje 
granskar innlegg og anna på munikum.no før det publiserast, eller elles er ansvarleg for innhaldet.

#### Kontaktinformasjon

Kontakt oss gjerne om du har spørsmål.

E-post: support@munikum.no

Telefon: 919 04 691

Postadresse: Bankgata 1, 6770 Nordfjordeid
`;