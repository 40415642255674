import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Elastic, Power2, TweenLite } from 'gsap';
import { Colors } from '../../common';

const ProgressBackground = styled.div`
  height: 0.625em;
  width: 15.25em; // default. override with style

  background-color: rgba(208, 211, 212, 0.4);
  border-radius: 0.625em;
`;

interface IProgProps {
  // progress: number;
  color?: string;
}

// width: ${(props: IProgProps) => props.progress + '%'};
const ProgressValue = styled.div`
  height: 0.625em;
  border-radius: 0.625em;

  background-color: ${(props: IProgProps) => props.color || Colors.RED};
`;

export interface IProgressBarProps {
  color?: string;
  value: number;
  max: number;
  min?: number;
  style?: CSSProperties;
}

/**
 * Progressbar
 */
export class ProgressBar extends React.Component<IProgressBarProps> {
  public static defaultProps: IProgressBarProps = {
    color: Colors.RED,
    min: 0,
    max: 10,
    value: 0,
  };

  private myRef: any;

  constructor(props: IProgressBarProps) {
    super(props);
    this.myRef = null;

    if (props.min > props.max) {
      throw Error('min cannot be greater than max');
    }
    if (props.max < props.min) {
      throw Error('max canot be lower than min');
    }
  }

  componentDidMount() {
    if (this.myRef !== undefined && this.myRef !== null) {
      const p = this.getPercentage();
      TweenLite.fromTo(
        this.myRef,
        2,
        {
          width: '0%',
          ease: Elastic.easeOut,
        },
        {
          width: p + '%',
        }
      );
    }
  }

  componentDidUpdate() {
    if (this.myRef !== undefined && this.myRef !== null) {
      // tween to percentage
      const p = this.getPercentage();
      TweenLite.to(this.myRef, 1, {
        width: p + '%',
        ease: Power2.easeOut,
      });
    }
  }

  getPercentage = () => {
    const { max, min, value } = this.props;

    if (value > max) {
      return 100;
    }
    if (value < min) {
      return 0;
    }

    const distance = max - min; // 20
    const travel = value - min; // 10

    return (travel / distance) * 100.0;
  };

  render() {
    return (
      <ProgressBackground style={this.props.style}>
        <ProgressValue
          color={this.props.color}
          ref={ref => (this.myRef = ref)}
          data-testid={'p'}
          data-progress={this.getPercentage()}
        />
      </ProgressBackground>
    );
  }
}
