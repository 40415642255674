import {
  initNetworkDetectionSaga,
  watchNetworkStatusSaga,
} from './network/networkStatus';
import { SagaMiddleware } from 'redux-saga';
import { authLoopSaga, checkMailSaga, initAuthSaga } from './auth/auth';
import {
  entitiesLoadSaga,
  entitiesLoopSaga,
  initEntitiesSaga,
} from './entities/entities';
import { invitePersonSaga } from './person/person';
import { onboardingSaga } from './onboarding/onboarding';
import { searchSaga, searchV2Saga, shareSaga } from './meta/meta';
import {
  checkForUpdateSaga,
  checkNotificationsSaga,
  getNotificationsInfoSaga,
  readNotificationSaga,
  sendNotificationSaga,
  websocketSaga,
  zendeskLoginSaga,
} from './notification/notification';
import { revokeRefreshTokenSaga } from './refreshToken/refreshToken';
import { uploadRequestWatcherSaga } from './file/file';
import { uploadFilesToTopicSaga } from './topic/topic';
// import { loadNotificationListSaga } from 'modules/notification/notification';

/***
 * initializer, called on startup to start sagas
 * @param {SagaMiddleware<{}>} sagaMiddleware
 */
export const initSagas = (sagaMiddleware: SagaMiddleware<{}>) => {
  // 1. INITIALIZE all watching sagas (they don't do anything until they receive an action)

  // watch network:
  sagaMiddleware.run(watchNetworkStatusSaga);

  // sagaMiddleware.run(setLanguageSaga);

  // auth:
  sagaMiddleware.run(authLoopSaga); // listens for login actions (username/pass or refresh_token)
  sagaMiddleware.run(checkMailSaga); // listens for check email actions

  // entities:
  sagaMiddleware.run(entitiesLoopSaga); // listens for getEntities actions
  sagaMiddleware.run(entitiesLoadSaga); // listens for loadData actions

  // domain:
  // sagaMiddleware.run(editPersonSaga);
  // sagaMiddleware.run(editProfileSaga);
  // sagaMiddleware.run(syncElasticSaga);
  sagaMiddleware.run(invitePersonSaga);

  // meta
  // sagaMiddleware.run(loadMetaListSaga);
  sagaMiddleware.run(searchSaga);
  sagaMiddleware.run(searchV2Saga);
  sagaMiddleware.run(shareSaga);

  // notification
  // sagaMiddleware.run(loadNotificationListSaga);
  // sagaMiddleware.run(pollNotificationsSaga);
  sagaMiddleware.run(sendNotificationSaga);
  sagaMiddleware.run(getNotificationsInfoSaga);
  sagaMiddleware.run(checkNotificationsSaga);
  sagaMiddleware.run(readNotificationSaga);
  sagaMiddleware.run(websocketSaga);
  sagaMiddleware.run(checkForUpdateSaga);
  sagaMiddleware.run(zendeskLoginSaga);

  // refresh token
  sagaMiddleware.run(revokeRefreshTokenSaga);

  // action value
  // sagaMiddleware.run(loadActionValueListSaga);
  // sagaMiddleware.run(editActionValueListenSaga);
  // sagaMiddleware.run(updateActionValueSaga);
  // sagaMiddleware.run(saveActionValueSaga);
  // sagaMiddleware.run(getActionValueParametersSaga);
  // sagaMiddleware.run(loadUsedOrganizationsSaga);

  // comment
  // sagaMiddleware.run(saveCommentSaga);
  // sagaMiddleware.run(updateCommentSaga);

  // discussion
  // sagaMiddleware.run(loadDiscussionListSaga);
  // sagaMiddleware.run(editDiscussionListenSaga);
  // sagaMiddleware.run(updateDiscussionSaga);
  // sagaMiddleware.run(saveDiscussionSaga);

  // onboarding
  sagaMiddleware.run(onboardingSaga);

  // topics
  // sagaMiddleware.run(updateTopicSaga);
  sagaMiddleware.run(uploadFilesToTopicSaga);
  // sagaMiddleware.run(saveTopicSaga);

  // 2. INITIALIZE sagas that kick off stuff:
  sagaMiddleware.run(initAuthSaga); // read refresh_token from ls, wait for REHYDRATE, log in if refresh token / log out if not
  sagaMiddleware.run(initEntitiesSaga); // load static data (if not loaded already)
  sagaMiddleware.run(initNetworkDetectionSaga);
  // sagaMiddleware.run(initPollNotificationsSaga);

  sagaMiddleware.run(uploadRequestWatcherSaga);
};
