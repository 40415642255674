import * as React from 'react';
import { CSSProperties } from 'react';

/***
 * props! defines interfaces and types used by our components
 * should we import HTML props and spread out? ==> yes.. nice to have :)
 */

export interface IDisabledProps {
  disabled?: boolean;
}

/*** Interface for a clickable action (button, menu item, etc) ***/
export interface IActionProps extends IDisabledProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;

  text: string;
}

/*** Interface for a link ***/
export interface ILinkProps {
  href?: string;
  target?: string;
}

/*** Interface for controlled input (where value is text) ***/
export interface IControlledProps {
  defaultValue?: string;
  onChange?: React.FormEventHandler<HTMLElement>;
  onBlur?: React.FormEventHandler<HTMLElement>;
  value?: string;
}

/*** Interface for an option in a list ***/
export interface IOptionProps {
  disabled?: boolean;
  text: string;
  value: string;
}

export interface ISelectableOptionProps extends IOptionProps {
  selected: boolean;
}

/*** components that have a label prop ***/
export interface ILabelProps {
  label?: string;
}
//
// export interface ICanDisablePadding {
//   noPadding?: boolean;
// }

export interface IStylable {
  style?: CSSProperties;
}

//
// /*** metadata. error, warnings, etc. **/
// export interface IFieldMetadataProps {
//   meta?: {
//     dirty: boolean;
//     error?: string;
//     warning?: string;
//     pristine: boolean;
//     touched: boolean;
//     valid: boolean;
//     visited: boolean;
//   };
// }
//

// TODO: remove {} when Formik merges bug #775. replace with any..

export type MultiMessageType =
  | string
  | boolean
  | Array<string>
  | undefined
  | {};

/*** Potential validation messages on input field ***/
export interface IMessageProps {
  error?: MultiMessageType;
  warning?: MultiMessageType;
  success?: MultiMessageType;
  info?: MultiMessageType;
}

/**
 * Message type, also used as intent?
 */
export enum MessageType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}

/** A collection of curated prop keys used across our Components which are not valid HTMLElement props. */
const INVALID_PROPS = [
  'active',
  'containerRef',
  'elementRef',
  'iconName',
  'inputRef',
  'intent',
  'inline',
  'loading',
  'leftIconName',
  'onChildrenMount',
  'onRemove',
  'popoverProps',
  'rightElement',
  'rightIconName',
  'text',
  'noPadding',
  'intl',
];

/**
 * Typically applied to HTMLElements to filter out blacklisted props. When applied to a Component,
 * can filter props from being passed down to the children. Can also filter by a combined list of
 * supplied prop keys and the blacklist (only appropriate for HTMLElements).
 * @param props The original props object to filter down.
 * @param {string[]} invalidProps If supplied, overwrites the default blacklist.
 * @param {boolean} shouldMerge If true, will merge supplied invalidProps and blacklist together.
 */
export function removeNonHTMLProps(
  // tslint:disable-next-line
  props: { [key: string]: any },
  invalidProps: Array<string> = INVALID_PROPS,
  shouldMerge: boolean = false
  // tslint:disable-next-line
): { [key: string]: any } {
  if (shouldMerge) {
    invalidProps = invalidProps.concat(INVALID_PROPS);
  }

  return invalidProps.reduce(
    (prev, curr) => {
      if (prev.hasOwnProperty(curr)) {
        // tslint:disable-next-line
        delete (prev as any)[curr];
      }
      return prev;
    },
    { ...props }
  );
}
