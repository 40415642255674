import * as React from 'react';
import { IconType } from 'react-icons/lib';
import styled from 'styled-components';
import { CSSProperties } from 'react';
import { Colors, IDisabledProps, safeInvokeDeprecated } from '../../common';

export interface IInlineButtonProps extends IDisabledProps {
  icon: IconType;
  // tooltip?: JSX.Element | string;

  text?: string;

  style?: CSSProperties;

  onClick?: () => void;
}

interface IInlineButtonState {
  isHovering: boolean;
}

type IIconContainer = IDisabledProps & {
  size?: string;
  hover: boolean;
};

const OuterContainer = styled.div`
  background-color: #FFFFFF;
  border-radius: 3px;
  
  width: 20px;
  height: 20px;
  box-sizing: border-box;

  
  display: flex;
  justify-content: center;
  align-items: center;
  
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  
    &:hover {
      box-shadow: ${(props: IIconContainer) =>
        props.disabled
          ? '0 2px 7px 0 rgba(0, 0, 0, 0.1)'
          : '0 2px 7px 0 rgba(0, 0, 0, 0.3)'};
    }

  
  transition: background-color .25s ease-in-out, shadow .25s ease-in-out;

  cursor: ${(props: IIconContainer) =>
    props.disabled ? 'not-allowed' : 'pointer'}
  
  position: relative;
`;

const InnerIconContainer = styled.div`
  //background-color: paleturquoise;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * InlineButton
 * white rounded button used for inline editing stuff
 * - inactive: rounded, white background. black icon
 * - hover: +shadow, red icon
 * - click: translatexy
 *
 * TODO: tooltip? text? expand button show text when hovering?
 */
export class InlineButton extends React.PureComponent<
  IInlineButtonProps,
  IInlineButtonState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isHovering: false,
    };
  }

  handleClick = (e: any) => {
    e.stopPropagation();
    safeInvokeDeprecated(this.props.onClick);
  };

  render() {
    const Icon = this.props.icon;

    const { disabled } = this.props;
    const { isHovering } = this.state;

    const butt = (
      <OuterContainer
        disabled={disabled}
        onMouseEnter={() => this.setState({ isHovering: true })}
        onMouseLeave={() => this.setState({ isHovering: false })}
        onClick={this.handleClick}
        hover={isHovering}
        style={this.props.style}
      >
        <InnerIconContainer>
          <Icon
            fill={
              disabled
                ? Colors.GREYDISABLEDTEXT
                : isHovering
                ? Colors.RED
                : Colors.BLACK
            }
            onClick={this.handleClick}
            width={'100%'}
            height={'100%'}
            style={{
              transition: 'fill .25s ease-in-out',
            }}
          />
        </InnerIconContainer>
      </OuterContainer>
    );

    // if (this.props.tooltip !== undefined) {
    //   return (
    //     <Tooltip content={this.props.tooltip}>
    //       <div
    //         style={{
    //           display: 'inline-block',
    //         }}
    //       >
    //         {butt}
    //       </div>
    //     </Tooltip>
    //   );
    // } else {
    //   return butt;
    // }

    return butt;
  }
}
