import * as React from 'react';

export interface MunikumScrollHelpers {
  scrollToTop: () => void;
  scrollToPosition: (x: number, y: number) => void;
}

/**
 * scroll handler using context in stead of redux
 */
export const MunikumScrollContext = React.createContext<MunikumScrollHelpers>({
  scrollToPosition: (x: number, y: number) => {
    // console.log('scrollToPos ' + x + ' ' + y);
    window.scrollTo({ behavior: 'smooth', left: x, top: y });
  },
  scrollToTop: () => {
    // console.log('scrollToTop');
    window.scrollTo(0, 0);
  },
});
