import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import { put, select, take } from 'redux-saga/effects';
import { myHistory } from '../../index';
import { IPersonMeForm } from '../../services/personService';
import { userProfileSelector } from '../auth/auth';
import { IApiError, wrapApiError } from '../../services/api';
import { updateMeActionCreator } from '../person/person';
import { actionCreatorFactory } from '../actionCreatorFactory';
const actionCreator = actionCreatorFactory('ONBOARDING');
export const showWelcomeUser = actionCreator<{}>('SHOW_WELCOME_SCREEN');

export interface IConfirmUserSetupData {
  profile: Pick<IPersonMeForm, 'name' | 'jobTitle'>;
}

export const confirmUserSetup = actionCreator.async<
  IConfirmUserSetupData,
  {},
  IApiError
>('CONFIRM_USER_SETUP');

export interface IOnboardingState {
  isWelcomeUserVisible: boolean;
  isTermsAccepted: boolean;

  isConfirmingUserSetup: boolean;
  confirmUserError?: string;
}

export const reducer = (
  state: IOnboardingState = {
    isWelcomeUserVisible: false,
    isTermsAccepted: false,
    isConfirmingUserSetup: false,
  },
  action: Action
): IOnboardingState => {
  if (isType(action, showWelcomeUser)) {
    return {
      ...state,
      isWelcomeUserVisible: true,
      isTermsAccepted: false,
    };
  }

  if (isType(action, confirmUserSetup.started)) {
    return {
      ...state,
      isConfirmingUserSetup: true,
    };
  }
  if (isType(action, confirmUserSetup.failed)) {
    return {
      ...state,
      isConfirmingUserSetup: false,
      confirmUserError: action.payload.error.message,
    };
  }
  if (isType(action, confirmUserSetup.success)) {
    return {
      ...state,
      isConfirmingUserSetup: false,
      confirmUserError: undefined,
      isTermsAccepted: true,
      isWelcomeUserVisible: false,
    };
  }

  return state;
};

export function* onboardingSaga() {
  while (true) {
    const action = yield take(confirmUserSetup.started);

    try {
      // this is me from auth.user.profile
      const me = yield select(userProfileSelector);

      if (me === undefined) {
        throw Error('cannot setup user, no profile in state..');
      }

      // merge data from onboarding wizard:
      const me2: IPersonMeForm = Object.assign({}, me, action.payload.profile); // {language: action.payload.language}

      // dispatch updateMe action (this will update users name in top right corner (?))
      // TODO: we don't need meId.. remove it if you want ;)
      // yield put(updateMeActionCreator.started({ meForm: me2, meId: me.id }));
      yield put(updateMeActionCreator.started(me2));

      // this will hide welcome user screen:
      yield put(
        confirmUserSetup.success({ params: action.payload, result: {} })
      );

      // yield put(push('/'));
      myHistory.push('/');
    } catch (e) {
      yield put(
        confirmUserSetup.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}
