import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Colors,
  getValidationBorderColor,
  hideOutline,
  IControlledProps,
  IDisabledProps,
  ILabelProps,
  IMessageProps,
  insetShadow,
  removeNonHTMLProps,
  transitionBorder,
} from '../../common';
import { ValidationSummary } from '..';
import { FieldGroup } from '../FieldGroup/FieldGroup';
import { FormLabel } from '../FormLabel/FormLabel';

const AreaContainer = styled.div`
  width: 15em;
  min-height: 7.5em;
  display: block;
`;

const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  
  // TODO: allow to override
  resize: none;
  
  width: 100%;
  min-height: 7.5em;
  
  border-radius: 0.25em;
  background-color: ${Colors.WHITE};
  font-size: 0.875em;
  line-height: 1.1875em;
  font-weight: 500;
  padding: 0.55em 0.75em 0.55em 0.9375em;
  
  font-family: Lato, sans-serif;
  
  color: ${Colors.BLACK} 
  transition: border-color 0.25s ease-in-out;
  border: 1px solid ${(props: IMessageProps) =>
    getValidationBorderColor(props)};
  &::placeholder {
    color: ${Colors.GREYPLACEHOLDER};
  }
  &:focus:enabled {
    // TODO: what todo when focus + error/success? let focus-border override?
    border-color: ${Colors.BLACK};
  }
  &:disabled {
    border: 1px solid ${Colors.GREY};
    color: ${Colors.GREYDISABLEDTEXT};
    background-color: ${Colors.GREY};
  }

  ${insetShadow()};
  ${hideOutline()};
  ${transitionBorder()};
`;

export interface ITextAreaProps {
  name: string;
  placeholder?: string;
  style?: CSSProperties;
  fieldGroupStyle?: CSSProperties;
}

export type ITextAreaState = {
  id?: string;
};

export class TextArea extends React.PureComponent<
  ITextAreaProps &
    IControlledProps &
    ILabelProps &
    IMessageProps &
    IDisabledProps &
    React.HTMLProps<HTMLInputElement>,
  ITextAreaState
> {
  UNSAFE_componentWillMount() {
    const id = _.uniqueId('txtField-');
    this.setState({
      id: id,
    });
  }

  render() {
    const {
      name,
      label,
      onChange,
      onBlur,
      value,
      info,
      success,
      warning,
      error,
      disabled,
      fieldGroupStyle,
      style,
      ...rest
    } = this.props;
    const { id } = this.state;

    return (
      <FieldGroup style={fieldGroupStyle}>
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <AreaContainer style={style}>
          <StyledTextArea
            id={id}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            error={error}
            warning={warning}
            success={success}
            info={info}
            {...removeNonHTMLProps(rest)}
          />
        </AreaContainer>
        <ValidationSummary
          info={info}
          success={success}
          warning={warning}
          error={error}
        />
      </FieldGroup>
    );
  }
}
