import { ColorTheme, safeInvokeDeprecated } from '../common';
import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';
import { InjectedIntlProps } from 'react-intl';
import { commonMessages } from '../language/commonMessages';
import { Button, TextField } from '../basic';
import { CircleSpinner } from '../basic/Loader/CircleSpinner';
import {
  GET_USER_WIZARD_DATA,
  GetUserWizardData,
  UPDATE_ME,
  UpdateMe,
} from './personQueries';
import { UpdateMeInput } from '../../models/types';
import MiniErrorMessage from '../basic/StatusMessage/MiniErrorMessage';
import { StatusMessageDialog } from '../basic/StatusMessage/StatusMessage';
import { commonLabels } from '../language/commonLabels';
import { MunikumIcons } from '../common/icons';

const CenterDiv = styled.div`
  //background-color: orange;
  align-self: center;
  justify-self: center;
  flex: 1 1 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export class EditMyProfileViewComp extends React.Component<
  {
    onSaveComplete: () => void;
    onCancel?: () => void;
    showButtons: boolean;
  } & InjectedIntlProps,
  { direction: string }
> {
  private myRef: React.RefObject<any>;

  constructor(props: any) {
    super(props);

    this.myRef = React.createRef<any>();
  }

  public doSubmit() {
    // console.log('edit do it');
    if (this.myRef.current) {
      this.myRef.current.submitForm();
    }
  }

  render() {
    const { intl } = this.props;

    return (
      <GetUserWizardData query={GET_USER_WIZARD_DATA}>
        {({ data, loading, error }) => {
          if (loading) {
            return (
              <CenterDiv>
                <CircleSpinner />
              </CenterDiv>
            );
          }

          if (error || !data) {
            return (
              <CenterDiv>
                <MiniErrorMessage
                  message={intl.formatMessage(commonMessages.errorLoadData)}
                  intl={intl}
                />
              </CenterDiv>
            );
          }

          const form2InitialValues: UpdateMeInput = {
            name: data.me.name || '',
            language: data.me.language || '',
            kostraFunctions: data.me.kostraFunctions.map(f => f.id),
            jobTitle: data.me.jobTitle || '',
            description: data.me.description || '',
            department: data.me.department || '',
            phoneNumber: data.me.phoneNumber || '',
          };

          return (
            <UpdateMe mutation={UPDATE_ME}>
              {(
                updateMe,
                { data: uData, loading: uLoading, called, error: uError }
              ) => {
                if (uError) {
                  return (
                    <StatusMessageDialog
                      title={'Feil ved lagring av profil'}
                      text={'Feilmelding: ' + uError.message}
                      buttonText={'Lukk'}
                    />
                  );
                }

                // const { direction } = this.state;

                return (
                  <Formik
                    ref={this.myRef}
                    initialValues={form2InitialValues}
                    // tslint:disable-next-line
                    onSubmit={(values: UpdateMeInput) => {
                      updateMe({
                        variables: {
                          input: values,
                        },
                      })
                        .then(res => {
                          // console.log('great success save', res);
                          safeInvokeDeprecated(this.props.onSaveComplete);
                        })
                        .catch(err => {
                          console.error(err);
                        });
                    }}
                    validateOnBlur={true}
                    render={(formikProps: FormikProps<UpdateMeInput>) => {
                      return (
                        <form onSubmit={formikProps.handleSubmit}>
                          <TextField
                            label={intl.formatMessage(commonLabels.name)}
                            name={'name'}
                            value={formikProps.values.name}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={formikProps.errors.name}
                            style={{ width: '100%' }}
                          />
                          <TextField
                            label={intl.formatMessage(commonLabels.jobTitle)}
                            name={'jobTitle'}
                            value={formikProps.values.jobTitle}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={formikProps.errors.jobTitle}
                            style={{ width: '100%' }}
                          />
                          <TextField
                            label={intl.formatMessage(commonLabels.department)}
                            name={'department'}
                            value={formikProps.values.department}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={formikProps.errors.jobTitle}
                            style={{ width: '100%' }}
                          />
                          <TextField
                            label={intl.formatMessage(commonLabels.phone)}
                            name={'phoneNumber'}
                            value={formikProps.values.phoneNumber}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={formikProps.errors.phoneNumber}
                            style={{ width: '100%' }}
                          />

                          {/*<KostraFunctionDropDown */}
                          {/*selectedItems={formikProps}*/}
                          {/*/>*/}

                          {this.props.showButtons && (
                            <div style={{ marginTop: '2em' }}>
                              <Button
                                theme={ColorTheme.White}
                                text={intl.formatMessage(commonMessages.cancel)}
                                leftIcon={MunikumIcons.Cancel}
                                onClick={() =>
                                  safeInvokeDeprecated(this.props.onCancel)
                                }
                              />
                              <Button
                                theme={ColorTheme.Red}
                                style={{ marginLeft: '.5em' }}
                                leftIcon={MunikumIcons.Save}
                                text={intl.formatMessage(commonMessages.save)}
                                onClick={() => {
                                  // doSubmit();
                                  // myHistory.push('/profile');
                                  formikProps.submitForm();
                                }}
                              />
                            </div>
                          )}
                        </form>
                      );
                    }}
                  />
                );
              }}
            </UpdateMe>
          );
        }}
      </GetUserWizardData>
    );
  }
}

// const EditMyProfileView = injectIntl(EditMyProfileViewComp);
// export default EditMyProfileView;
