import { IHttpLink } from './models/actionValue';
import { getApiRequest, parsedEmptyResponse, parsedResponse } from './api';
import { ITopic } from './models/topic';
import {
  FieldsOnKostraFunctionFragment,
  OrderEnum,
  Organization,
  Person,
  SortEnum,
  TopicCategoryEnum,
  TopicCategorySortEnum,
} from '../models/types';
import { ContentStatus, IContentMeta } from './models/contentMeta';
import { actionCreatorFactory } from '../modules/actionCreatorFactory';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('TOPIC');

export interface ITopicCreateOrUpdateForm {
  id?: string;
  title?: string;
  description?: string;
  body?: string;
  status?: ContentStatus;
  category?: TopicCategoryEnum | string;
  links?: ReadonlyArray<IHttpLink>;
  sector?: string;
  kostraFunctions?: ReadonlyArray<FieldsOnKostraFunctionFragment>;
  access: any;
  createdBy: Person;
}

export interface ITopicFilter {
  sort: SortEnum;
  order: OrderEnum;
  predefinedOrg: boolean;
  limit: number;
  organizations: Array<Organization>;
  category: TopicCategorySortEnum;
  offset: number;
  showOnlyMine: boolean;
  // organizations?: Array<Organization>;
  sortValue: string;
  kostraGroup: number | null;
  kostraGroupValue: string | undefined;
  categoryKey: string | undefined;
}

export interface ITopicFilterState {
  filter: ITopicFilter;
  currentPage: number;
}

const initialState: ITopicFilterState = {
  filter: {
    organizations: [],
    showOnlyMine: false,
    predefinedOrg: false,
    categoryKey: undefined,
    kostraGroupValue: undefined,
    category: TopicCategorySortEnum.ALL,
    sort: SortEnum.LAST_UPDATED,
    order: OrderEnum.DESCENDING,
    limit: 12,
    sortValue: '1',
    offset: 0,
    // organizations: [],
    kostraGroup: null,
  },
  currentPage: 0,
};

export const resetTopicsFilterActionCreator = actionCreator<{}>(
  'CLIENT_RESET_FILTER_TOPIC'
);

export const topicChangePageActionCreator = actionCreator<{
  page: number;
}>('CHANGE_PAGE_TOPIC');

// export const setPreDefinedOrgTopic = actionCreator<{
//   org: Organization;
// }>('SET_PREDEFINED_ORG_TOPIC');

export const filterTopicActionCreator = actionCreator<{
  filter: ITopicFilter;
}>('FILTER_TOPIC');

export const topicFilterReducer = (
  state: ITopicFilterState = initialState,
  action: Action
): ITopicFilterState => {
  // if (isType(action, setPreDefinedOrg)) {
  //   return {
  //     filter: {
  //       ...state.filter,
  //       organizations: [action.payload.org],
  //     },
  //     currentPage: state.currentPage,
  //   };
  // }

  if (isType(action, resetTopicsFilterActionCreator)) {
    return {
      filter: {
        organizations: [],
        showOnlyMine: false,
        predefinedOrg: false,
        categoryKey: undefined,
        kostraGroupValue: undefined,
        category: TopicCategorySortEnum.ALL,
        sort: SortEnum.LAST_UPDATED,
        order: OrderEnum.DESCENDING,
        limit: 12,
        sortValue: '1',
        offset: 0,
        // organizations: [],
        kostraGroup: null,
      },
      currentPage: 0,
    };
  }

  if (isType(action, filterTopicActionCreator)) {
    return {
      filter: {
        ...action.payload.filter,
      },
      currentPage: 0,
    };
  }
  if (isType(action, topicChangePageActionCreator)) {
    return {
      filter: state.filter,
      currentPage: action.payload.page,
    };
  }

  return state;
};

export async function getTopicMetaList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<IContentMeta>> {
  if (params) {
    return await parsedResponse<ReadonlyArray<IContentMeta>>(
      await getApiRequest('/topic/list-meta', 'POST', params)
    );
  }
  const response = await getApiRequest('/topic/list-meta', 'GET');
  return await parsedResponse<ReadonlyArray<IContentMeta>>(response);
}

export async function getTopicMeta(id: string): Promise<IContentMeta> {
  const response = await getApiRequest('/topic/get/' + id, 'GET');
  return await parsedResponse<IContentMeta>(response);
}

export async function getTopicList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<ITopic>> {
  if (params) {
    return await parsedResponse<ReadonlyArray<ITopic>>(
      await getApiRequest('/topic/list', 'POST', params)
    );
  }
  const response = await getApiRequest('/topic/list', 'GET');
  return await parsedResponse<ReadonlyArray<ITopic>>(response);
}

export async function updateTopic(
  topic: ITopicCreateOrUpdateForm
): Promise<void> {
  const response = await getApiRequest('/topic/update', 'POST', {
    ...topic,
  });

  await parsedEmptyResponse(response);
  console.log('update ok');

  // return await parsedResponse<{}>(response);
}

export async function createTopic(
  topic: ITopicCreateOrUpdateForm
): Promise<ITopic> {
  const response = await getApiRequest('/topic/save', 'POST', {
    ...topic,
  });
  return await parsedResponse<ITopic>(response);
}
