import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { ContentState, convertToRaw } from 'draft-js';
import { Button } from '../basic';
import { MunikumIcons } from '../common/icons';
import { IFile } from '../../services/fileservice';
import {
  FileUploader,
  IFileUploaderRenderProps,
} from '../basic/UserFile/FileUploader';
import {
  CardContainer,
  CardContentContainer,
  CardLastUpdated,
  CardLeftContainerResponsive,
  ChatBubble,
} from '../basic/Card/Card';
import { UserSummary } from './UserSummary';
import { MuToolbar } from '../basic/MuEditor/MuToolbar';
import {
  ColorTheme,
  isReallyEmpty,
  safeInvoke,
  safeInvokeDeprecated,
} from '../common';
import {
  FileUploaderPicker,
  IFileItem,
} from '../basic/UserFile/FileUploaderPicker';
import { MuInternalEditor } from '../basic/MuEditor/MuInternalEditor';
import {
  IMuBag,
  IMuEditorRenderProps,
  MuEditor,
  MuEditorMode,
} from '../basic/MuEditor/MuEditor';
import { FilesContainer } from '../comment/CommentView2';
import {
  MuCancelButton,
  MuEditButton,
  MuSaveButton,
} from '../basic/MuEditor/MuButtons';
import { commonMessages } from '../language/commonMessages';
import { FileUploaderList } from '../basic/UserFile/FileUploaderList';
import { Person } from '../../models/types';
import { sizes } from '../common/media';
import { FileInfoCard } from '../basic/UserFile/FileInfoCard';
import { Overlay } from '../basic/Overlay/Overlay';

export interface IQuestionViewProps {
  createdBy: Person;

  canEdit: boolean;

  lastUpdated?: Date;
  onFileDelete?: (fileId: string) => void;
  onDeleteClick: () => void;
  /**
   * set this to put component in controlled mode
   */
  rawMarkdown: string;

  files?: ReadonlyArray<IFile>;
}

export interface IQuestionViewDispatch {
  onSave?: (markdown: string, files: IFileItem[]) => void;
  onComment?: () => void;
}

interface IQuestionViewState {
  isEditing: boolean;
  rawMarkdown: string;
  uploadableFiles: IFileItem[];
  isFileInfoCardVisible: boolean;
  fileInfoId: string;
}

const messages = defineMessages({
  deleteDiscussionWarning: {
    id: 'QuestionWiew.deleteDiscussionWarning',
    defaultMessage: 'Are you sure you want to delete the discussion?',
  },
});

const EditorAndLastUpdatedContainer = styled.div`
  display: block;

  //background-color: lightgreen;

  @media (min-width: ${sizes.large}px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const FooterContainer = styled.div`
  display: block;

  //background-color: lightgreen;

  @media (min-width: ${sizes.large}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const ToolbarAndFilesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TextDiv = styled.div`
  font-size: 16px;
  line-height: 24px;
  @media (min-width: ${sizes.large}px) {
    flex: 1 1 0px;
  }
  min-width: 0; /* Firefox word wrap fix */
`;

const LastUpdatedDiv = styled.div`
  margin-left: 0.5em;
  opacity: 0.7;
  @media (min-width: ${sizes.large}px) {
    flex: 0 0 auto;
    align-items: flex-start;
  }
`;

const ButtsContainer = styled.div`
  //background-color: pink;
  display: flex;
  justify-content: flex-start;
  align-content: center;
`;

/**
 * QuestionView. only cares about rendering of a question. We don't need metaid etc.
 */
class QuestionViewComp extends React.PureComponent<
  IQuestionViewProps & InjectedIntlProps & IQuestionViewDispatch,
  IQuestionViewState
> {
  constructor(
    props: IQuestionViewProps & IQuestionViewDispatch & InjectedIntlProps
  ) {
    super(props);

    this.state = {
      isEditing: false,
      rawMarkdown: props.rawMarkdown,
      uploadableFiles: [],
      isFileInfoCardVisible: false,
      fileInfoId: undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: IQuestionViewProps & IQuestionViewDispatch & InjectedIntlProps
  ) {
    if (
      nextProps.rawMarkdown !== undefined &&
      nextProps.rawMarkdown !== this.state.rawMarkdown
    ) {
      if (this.state.isEditing) {
        console.warn(
          'We received updated markdown from redux, but were still editing!'
        );
      } else {
        this.setState({
          rawMarkdown: nextProps.rawMarkdown,
        });
      }
    }
  }

  handleEdit = () => {
    this.setState({
      isEditing: !this.state.isEditing,
    });
  };

  handleComment = () => {
    safeInvokeDeprecated(this.props.onComment);
  };

  handleSubmit = (markdown: string) => {
    // console.log('Saving discussion');
    console.log(markdown);
    this.setState(
      {
        rawMarkdown: markdown,
        isEditing: false,
      },
      () => {
        safeInvoke(
          this.props.onSave,
          this.state.rawMarkdown,
          this.state.uploadableFiles
        );
      }
    );
  };

  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  };

  handleUploadableFiles = (recentFiles: IFileItem[]) => {
    this.setState({
      uploadableFiles: recentFiles,
    });
  };

  render() {
    const { createdBy, intl, lastUpdated, canEdit, files } = this.props;
    const { isEditing, rawMarkdown } = this.state;
    // console.log('Files: ', files);

    return (
      <CardContainer canHover={false}>
        <CardLeftContainerResponsive style={{ position: 'relative' }}>
          <ChatBubble />
          {createdBy && (
            <UserSummary
              name={createdBy.name}
              role={createdBy.role || ''}
              userImage={createdBy.picture}
              organizationImage={
                (createdBy.organization &&
                  createdBy.organization.organizationImage) ||
                ''
              }
              organizationName={
                (createdBy.organization && createdBy.organization.name) || ''
              }
            />
          )}
        </CardLeftContainerResponsive>
        <CardContentContainer
          style={{
            minHeight: '8em',
            padding: '1.25em 2em',
          }}
        >
          <MuEditor
            rawMarkdown={rawMarkdown}
            onSubmit={(
              markdown: string,
              files2: IFileItem[],
              contentState: ContentState,
              muEditorBag: IMuBag
            ) => {
              if (!isReallyEmpty(markdown)) {
                this.handleSubmit(JSON.stringify(convertToRaw(contentState)));
              }
              // muEditorBag.resetEditor();
            }}
            editorRenderFunc={(propsEditor: IMuEditorRenderProps) => {
              return (
                <React.Fragment>
                  <EditorAndLastUpdatedContainer>
                    <TextDiv>
                      <MuInternalEditor
                        editorMode={
                          isEditing ? MuEditorMode.Edit : MuEditorMode.Preview
                        }
                        onChange={propsEditor.handleDraftChange}
                        draftState={propsEditor.draftState}
                      />
                    </TextDiv>
                    {!isEditing && lastUpdated && (
                      <LastUpdatedDiv>
                        <CardLastUpdated style={{ marginLeft: '.5em' }}>
                          {intl.formatMessage(commonMessages.lastUpdated)}
                          {': '}
                          {moment
                            .utc(lastUpdated)
                            .local()
                            .format('YYYY-MM-DD')}
                        </CardLastUpdated>
                      </LastUpdatedDiv>
                    )}
                  </EditorAndLastUpdatedContainer>

                  <FileUploader
                    files={files}
                    onUploadableFiles={this.handleUploadableFiles}
                    onDeleteFile={(id: string) => {
                      safeInvokeDeprecated(this.props.onFileDelete, id);
                    }}
                    fileUploaderRenderFunc={(
                      propsFiles: IFileUploaderRenderProps
                    ) => {
                      return (
                        <div>
                          <FilesContainer>
                            <FileUploaderList
                              persistedFiles={propsFiles.persistedFiles}
                              recentFiles={propsFiles.recentFiles}
                              onRemoveFile={propsFiles.handleRemoveFile}
                              onFileDelete={propsFiles.handleDeleteFile}
                              canEdit={canEdit}
                              isEditMode={this.state.isEditing}
                              onClick={(id: string) => {
                                this.setState({
                                  isFileInfoCardVisible: true,
                                  fileInfoId: id,
                                });
                              }}
                            />
                          </FilesContainer>
                          <FooterContainer>
                            <ToolbarAndFilesContainer>
                              {isEditing && (
                                <MuToolbar
                                  toolbarMode={propsEditor.toolbarMode}
                                  onClickCommand={propsEditor.handleCommand}
                                  isItalicChecked={propsEditor.isItalicChecked}
                                  isBoldChecked={propsEditor.isBoldChecked}
                                  style={{
                                    marginRight: '.5em',
                                  }}
                                />
                              )}
                            </ToolbarAndFilesContainer>
                            {!isEditing && (
                              <ButtsContainer>
                                {canEdit && (
                                  <MuEditButton onClick={this.handleEdit} />
                                )}

                                {/*note: data model doesnt support comments on thread*/}
                                {/*<Button*/}
                                {/*text={intl.formatMessage(commonMessages.comment)}*/}
                                {/*onClick={this.handleComment}*/}
                                {/*style={{ marginLeft: '.5em' }}*/}
                                {/*/>*/}
                              </ButtsContainer>
                            )}
                            {isEditing && (
                              <ButtsContainer>
                                <Button
                                  leftIcon={MunikumIcons.Delete}
                                  theme={ColorTheme.Yellow}
                                  text={intl.formatMessage(
                                    commonMessages.delete
                                  )}
                                  style={{
                                    marginLeft: '.5em',
                                    width: '102px',
                                    height: '36px',
                                  }}
                                  onClick={() => {
                                    if (
                                      confirm(
                                        intl.formatMessage(
                                          messages.deleteDiscussionWarning
                                        )
                                      )
                                    ) {
                                      safeInvokeDeprecated(
                                        this.props.onDeleteClick
                                      );
                                      this.setState({
                                        isEditing: false,
                                      });
                                    }
                                  }}
                                />
                                <MuCancelButton
                                  style={{ marginLeft: '.5em' }}
                                  onClick={() => {
                                    propsEditor.handleCancel();
                                    this.handleCancel();
                                    propsFiles.handleClearAllFiles();
                                  }}
                                />
                                <FileUploaderPicker
                                  onNewFiles={propsFiles.handleNewFiles}
                                  style={{ marginLeft: '.5em' }}
                                />
                                <MuSaveButton
                                  onClick={() => {
                                    propsEditor.handleSubmit();
                                    propsFiles.handleClearAllFiles();
                                  }}
                                  style={{ marginLeft: '.5em' }}
                                />
                              </ButtsContainer>
                            )}
                          </FooterContainer>

                          <Overlay isOpen={this.state.isFileInfoCardVisible}>
                            <FileInfoCard
                              fileid={this.state.fileInfoId}
                              canEdit={canEdit}
                              canDelete={canEdit}
                              onDelete={() => {
                                this.setState(
                                  {
                                    isFileInfoCardVisible: false,
                                  },
                                  () => {
                                    safeInvoke(
                                      this.props.onFileDelete,
                                      this.state.fileInfoId
                                    );
                                  }
                                );
                              }}
                              onCancel={() => {
                                this.setState({
                                  isFileInfoCardVisible: false,
                                });
                              }}
                            />
                          </Overlay>
                        </div>
                      );
                    }}
                  />
                </React.Fragment>
              );
            }}
          />
        </CardContentContainer>
      </CardContainer>
    );
  }
}

export const QuestionView = injectIntl(QuestionViewComp);
