import { AutocompleteResult, Option } from 'react-select';
import {
  IElasticSearchResponse,
  IOrganization,
  IOrganizationType,
} from './models/organization';
import { getApiRequest, parsedResponse } from './api';

export async function getOrganization(id: String): Promise<IOrganization> {
  const response = await getApiRequest('/organization/get/' + id, 'GET');
  return await parsedResponse<IOrganization>(response);
}

export async function getOrganizationByLocaleAndNumber(
  orgLocale: string,
  orgNumber: string
): Promise<IOrganization> {
  const response = await getApiRequest('/organization/show/', 'POST', {
    locale: orgLocale,
    number: orgNumber,
  });
  return await parsedResponse<IOrganization>(response);
}

export async function getOrganizationTypes(): Promise<
  ReadonlyArray<IOrganizationType>
> {
  const response = await getApiRequest('/organization/list-types', 'GET');
  return await parsedResponse<ReadonlyArray<IOrganizationType>>(response);
}

/**
 * Call this to resync entire elasticsearch index? (fix search for organization)
 * @returns {Promise<void>}
 */
export async function syncElasticSearch() {
  const result = await getApiRequest('/search/sync', 'GET');
}

/**
 * search for organizatinons
 *
 * @param {string} input string. minimum 2 characters
 * @param preloadedItems - specify preloaded items (can be selected values)
 * @returns {Promise<AutocompleteResult>}
 */
export async function searchOrganization(
  input?: string,
  preloadedItems?: Array<Option<string>>
): Promise<Array<Option<string>>> {
  console.log('searchOrg', input, preloadedItems);

  if (!input || input.length < 2) {
    // return {
    //   options: preloadedItems || [{}],
    //   complete: false,
    // };
    return preloadedItems || [];
  }

  const response = await getApiRequest(
    '/search/organization?query=' + input + '&onlyMunicipalities=false',
    'GET'
  );

  // 204 = no content / body
  if (response.status === 204) {
    return preloadedItems || [];
  }

  const tempResult = await parsedResponse<IElasticSearchResponse>(response);

  const ids: Array<string> = [];
  // We're mapping to Option. we could also use labelKey and valueKey..
  const autoCompleteItems = tempResult.hits.map(hit => {
    const temp: Option<string> = {
      label: hit.title,
      value: hit.id,
    };
    ids.push(hit.id);
    return temp;
  });

  if (preloadedItems !== undefined) {
    preloadedItems.forEach(item => {
      if (item.value && ids.indexOf(item.value) < 0) {
        autoCompleteItems.unshift(item);
      }
    });
  }

  const autoCompleteRes: AutocompleteResult = {
    options: autoCompleteItems,
    complete: false,
  };
  console.log('great success');
  return autoCompleteRes.options;
}

export async function getUsedSources(): Promise<ReadonlyArray<IOrganization>> {
  const response = await getApiRequest('/meta/sources', 'GET');
  const tempResult = await parsedResponse<ReadonlyArray<IOrganization>>(
    response
  );
  return tempResult;
}
