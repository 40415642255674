import * as React from 'react';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { detect } from 'detect-browser';
import {
  checkEmail,
  IAuthCheckEmail,
  IAuthPin,
  signin,
} from '../../../modules/auth/auth';
import { myHistory } from '../../../index';
import { IVerifyPinForm } from '../../../components/login/VerifyPinForm';
import { ServiceWorkerUpdate } from '../../../components/layout/ServiceWorkerUpdate/ServiceWorkerUpdate';
import { isOnlineSelector } from '../../../modules/network/networkStatus';
import { Colors, safeInvokeDeprecated } from '../../../components/common';
import {
  ILoginFormEmail,
  LoginFormEmail,
} from '../../../components/login/LoginFormEmail';
import { InternalLink } from '../../../components/basic/Structural/links';
import { LoginType } from '../../../modules/auth/IAuthResult';
import { sizes } from '../../../components/common/media';
import { RootState } from '../../../modules/rootReducer';

const logo = require('./munikum-logo-black.svg') as string;
const background = require('./background.svg') as string;

const firefox = require('./Firefox_Logo.svg') as string;
const chrome = require('./Google_Chrome.svg') as string;
const edge = require('./Microsoft_Edge.svg') as string;
const brave = require('./brave.svg') as string;
const vivaldi = require('./vivaldi_logo.svg') as string;
const opera = require('./opera-logo.svg') as string;

export interface ILoginEmailPageProps {
  onCheckEmail?: (values: ILoginFormEmail) => void;
  onVerifyPin?: (values: IVerifyPinForm) => void;
  onNavigateToLogin?: () => void;

  isOnline: boolean;
  auth_email: IAuthCheckEmail;
  auth_pin: IAuthPin;
  isAuthenticated: boolean;
  newUser?: boolean;

  serviceWorkerUpdated: boolean;
}

const LoginBox = styled.div`
  background-color: #fff;
  border-radius: 0.25em;
  padding: 1.5em 1.875em;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  //display: flex;
  //flex-direction: column;
  margin-top: 0.5em;

  //min-height: 18.125em;
  //align-items: stretch;
  //align-content: stretch;
`;

const LoginFooterFooter = styled.div`
  align-self: stretch;
  text-align: center;
  font-size: 0.875em;
  color: ${Colors.GREYLABEL};
  margin-top: 2em;
`;

const LoginWrapper = styled.div`
  width: 100%;

  @media (min-width: ${sizes.mobile}px) {
    background-image: url(${background});
    background-size: cover;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: center;
    height: 100vh;
  }
`;

const LoginContainer = styled.div`
  @media (min-width: ${sizes.mobile}px) {
    min-height: 18.125em;

    display: flex;
    flex-direction: column;
    width: 21.875em;
    align-items: center;
    justify-content: center;
  }
`;

const BrowserWarningDiv = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: ${Colors.RED};
  color: #fff;
  z-index: 999;
  display: table;
`;

const BetterBrowsersDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
`;

const BetterBrowsersSubDiv = styled.div`
  padding: 2em;
  text-align: center;
  align-self: center;
  font-size: 1.5em;
`;

const BrowserSelectorLink = styled.a`
  color: #fff;
`;

const Back = styled.div`
  background-color: ${Colors.WHITEBACKGROUND};
`;

class LoginEmailPageComponent extends React.PureComponent<
  ILoginEmailPageProps & InjectedIntlProps,
  {}
> {
  constructor(props: ILoginEmailPageProps & InjectedIntlProps) {
    super(props);
  }

  handleRequestEmailPin = (values: ILoginFormEmail) => {
    safeInvokeDeprecated(this.props.onCheckEmail, values);
  };

  handleVerifyPin = (values: IVerifyPinForm) => {
    values.newUser = this.props.newUser;
    safeInvokeDeprecated(this.props.onVerifyPin, values);
  };

  handleGoBack = () => {
    safeInvokeDeprecated(this.props.onNavigateToLogin);
  };

  render() {
    const isIeWarningEnabled = true;

    const browser = detect();
    const BrowserHandler = () => {
      const posOs: boolean =
        browser.os === 'Windows 10' ||
        browser.os === 'Windows 7' ||
        browser.os === 'Windows XP' ||
        browser.os === 'Windows Server 2003';
      switch (browser && browser.name) {
        case 'ie':
        case 'safari':
          return (
            <BrowserWarningDiv>
              <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                <div
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '1000px',
                  }}
                >
                  <div style={{ padding: '10px', fontSize: '2em' }}>
                    <FormattedMessage
                      id={'ie.warning'}
                      defaultMessage={
                        'Munikum does not support your browser. Use one of these:'
                      }
                    />
                  </div>
                  <BetterBrowsersDiv>
                    <BetterBrowsersSubDiv>
                      <img src={firefox} width="120" height="120" />
                      <br />
                      <BrowserSelectorLink
                        href="https://www.mozilla.org/firefox/"
                        style={{ fontSize: '1.1em' }}
                      >
                        Firefox
                      </BrowserSelectorLink>
                    </BetterBrowsersSubDiv>
                    <BetterBrowsersSubDiv>
                      <img src={vivaldi} width="100" height="100" />
                      <br />
                      <BrowserSelectorLink href="https://vivaldi.com/">
                        Vivaldi
                      </BrowserSelectorLink>
                    </BetterBrowsersSubDiv>
                    <BetterBrowsersSubDiv>
                      <img src={brave} width="100" height="100" />
                      <br />
                      <BrowserSelectorLink href="https://brave.com">
                        Brave
                      </BrowserSelectorLink>
                    </BetterBrowsersSubDiv>
                    <BetterBrowsersSubDiv>
                      <img src={opera} width="100" height="100" />
                      <br />
                      <BrowserSelectorLink href="https://www.opera.com/">
                        Opera
                      </BrowserSelectorLink>
                    </BetterBrowsersSubDiv>
                    <BetterBrowsersSubDiv>
                      <img src={chrome} width="100" height="100" />
                      <br />
                      <BrowserSelectorLink href="https://www.google.com/chrome/">
                        Chrome
                      </BrowserSelectorLink>
                    </BetterBrowsersSubDiv>
                    {posOs && (
                      <BetterBrowsersSubDiv>
                        <img src={edge} width="100" height="100" />
                        <br />
                        <BrowserSelectorLink href="https://www.microsoft.com/en-us/windows/microsoft-edge">
                          Edge
                        </BrowserSelectorLink>
                      </BetterBrowsersSubDiv>
                    )}
                  </BetterBrowsersDiv>
                </div>
              </div>
            </BrowserWarningDiv>
          );
        default:
          return <div />;
      }
    };

    // console.log('render LoginEmailPage');
    const { isOnline, auth_email, auth_pin, serviceWorkerUpdated } = this.props;

    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      // console.log('you are authenticated, dont have to be here!');
      return <Redirect to={'/'} />;
    }

    return (
      <Back>
        {serviceWorkerUpdated && (
          <ServiceWorkerUpdate
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
        <LoginWrapper>
          {isIeWarningEnabled && <BrowserHandler />}

          <LoginContainer>
            <div>
              <Link to={'/'}>
                <img src={logo} width={241} height={86.8667} alt={'logo'} />
              </Link>
            </div>
            <LoginBox>
              <LoginFormEmail
                onCheckEmail={this.handleRequestEmailPin}
                onVerifyPin={this.handleVerifyPin}
                authEmail={auth_email}
                authPin={auth_pin}
                isOnline={isOnline}
              />
            </LoginBox>
            <LoginFooterFooter>
              <FormattedMessage
                id={'loginEmailPage.noUser'}
                defaultMessage={'No user?'}
              />{' '}
              <InternalLink to={'/signup'}>
                <FormattedMessage
                  id={'loginEmailPage.signupLink'}
                  defaultMessage={'Sign up'}
                />
              </InternalLink>
            </LoginFooterFooter>
          </LoginContainer>
        </LoginWrapper>
      </Back>
    );
  }
}

// mapping from global state to container props:
const mapStateToProps = (state: RootState): ILoginEmailPageProps => ({
  isOnline: isOnlineSelector(state),
  auth_email: state.auth && state.auth.auth_email,
  auth_pin: state.auth && state.auth.auth_pin,
  isAuthenticated: state.auth && state.auth.isAuthenticated,
  serviceWorkerUpdated:
    state.serviceWorker && state.serviceWorker.serviceWorkerUpdated,
  newUser:
    state.auth &&
    state.auth.auth_email &&
    state.auth.auth_email.validatedEmail &&
    state.auth.auth_email.validatedEmail.newUser,
});

// map dispatch to props on container :
const mapDispatchToPropsSimple = (
  dispatch: Dispatch<{}>
): Pick<
  ILoginEmailPageProps,
  'onCheckEmail' | 'onVerifyPin' | 'onNavigateToLogin'
> => {
  return {
    onCheckEmail: (values: ILoginFormEmail) => {
      if (values && values.email) {
        dispatch(checkEmail({ email: values.email }));
      }
    },
    onVerifyPin: (values: IVerifyPinForm) => {
      // console.log('login using PIN!:', values);
      dispatch(
        signin({
          email: values.email,
          pinCode: values.pin,
          loginType: LoginType.Pin,
          newUser: values.newUser,
        })
      );
    },
    onNavigateToLogin: () => {
      myHistory.push('/login');
    },
  };
};

// export connected container:
const LoginEmailPage = connect<ILoginEmailPageProps, {}, {}>(
  mapStateToProps,
  mapDispatchToPropsSimple
)(injectIntl(LoginEmailPageComponent));

export default LoginEmailPage;
