import { defineMessages } from 'react-intl';

/**
 * A set of reusable labels
 * @type {{next: {id: string; defaultMessage: string}}}
 */
export const commonLabels = defineMessages({
  name: {
    id: 'labels.name',
    defaultMessage: 'Name',
  },
  jobTitle: {
    id: 'labels.jobTitle',
    defaultMessage: 'Job title',
  },
  email: {
    id: 'labels.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'labels.password',
    defaultMessage: 'Password',
  },

  status: {
    id: 'labels.status',
    defaultMessage: 'Status',
  },
  myYearWheels: {
    id: 'labels.myYearWheels',
    defaultMessage: 'My year wheels',
  },
  notes: {
    id: 'labels.notes',
    defaultMessage: 'Notes',
  },
  searchHere: {
    id: 'labels.searchHere',
    defaultMessage: 'Search here...',
  },
  text: {
    id: 'labels.text',
    defaultMessage: 'Text',
  },
  Color: {
    id: 'labels.Color',
    defaultMessage: 'Color',
  },
  read: {
    id: 'labels.read',
    defaultMessage: 'Read',
  },
  municipality: {
    id: 'labels.municipality',
    defaultMessage: 'Municipality',
  },
  myFollowed: {
    id: 'labels.myFollowed',
    defaultMessage: 'My followed',
  },
  write: {
    id: 'labels.write',
    defaultMessage: 'Write',
  },
  chooseKostra: {
    id: 'labels.chooseKostra',
    defaultMessage: 'Choose KOSTRA-functions',
  },
  access: {
    id: 'labels.access',
    defaultMessage: 'Access',
  },
  uploadImage: {
    id: 'labels.uploadImage',
    defaultMessage: 'Upload Image',
  },
  notesPlaceholder: {
    id: 'labels.notesPlaceholder',
    defaultMessage: 'Write notes here...',
  },
  date: {
    id: 'labels.date',
    defaultMessage: 'Date',
  },
  time: {
    id: 'labels.time',
    defaultMessage: 'Time',
  },
  sector: {
    id: 'labels.sector',
    defaultMessage: 'Sector',
  },
  hours: {
    id: 'labels.hours',
    defaultMessage: 'Hours',
  },
  repetition: {
    id: 'labels.repetition',
    defaultMessage: 'Repetition',
  },
  reminder: {
    id: 'labels.reminder',
    defaultMessage: 'Reminder',
  },

  minutes: {
    id: 'labels.minutes',
    defaultMessage: 'Minutes',
  },
  created: {
    id: 'labels.created',
    defaultMessage: 'Created',
  },
  lastUpdated: {
    id: 'labels.lastUpdated',
    defaultMessage: 'Last updated',
  },
  newTask: {
    id: 'labels.newTask',
    defaultMessage: 'New activity',
  },
  description: {
    id: 'labels.description',
    defaultMessage: 'Description',
  },
  body: {
    id: 'labels.body',
    defaultMessage: 'Body',
  },
  year: {
    id: 'labels.year',
    defaultMessage: 'Year',
  },
  title: {
    id: 'labels.title',
    defaultMessage: 'Title',
  },
  success: {
    id: 'labels.success',
    defaultMessage: 'Success',
  },
  remove: {
    id: 'labels.remove',
    defaultMessage: 'Remove',
  },
  category: {
    id: 'labels.category',
    defaultMessage: 'Category',
  },
  sortBy: {
    id: 'labels.sortBy',
    defaultMessage: 'Sort by',
  },
  Cancel: {
    id: 'labels.Cancel',
    defaultMessage: 'Cancel',
  },
  links: {
    id: 'labels.links',
    defaultMessage: 'Links',
  },
  Exit: {
    id: 'labels.exit',
    defaultMessage: 'exit',
  },
  captcha: {
    id: 'labels.captcha',
    defaultMessage: 'Captcha',
  },
  startDate: {
    id: 'labels.startDate',
    defaultMessage: 'Start',
  },
  endDate: {
    id: 'labels.endDate',
    defaultMessage: 'End',
  },
  titlePlaceholder: {
    id: 'labels.titlePlaceholder',
    defaultMessage: 'Write title here...',
  },
  descriptionPlaceholder: {
    id: 'labels.descriptionPlaceholder',
    defaultMessage: 'Write description here...',
  },
  serie: {
    id: 'labels.serie',
    defaultMessage: 'Serie',
  },
  department: {
    id: 'labels.department',
    defaultMessage: 'Department',
  },
  phone: {
    id: 'labels.phone',
    defaultMessage: 'Telefon',
  },
  kostra: {
    id: 'labels.kostra',
    defaultMessage: 'Kostra functions',
  },
  yourKostra: {
    id: 'labels.kostra2',
    defaultMessage: 'Your KOSTRA functions',
  },
  myKostra: {
    id: 'labels.myKostra',
    defaultMessage: 'My KOSTRA functions',
  },
  monthWheel: {
    id: 'labels.monthWheel',
    defaultMessage: 'Start month',
  },
  other: {
    id: 'labels.other',
    defaultMessage: 'Other',
  },
});
