import { FormattedMessage, InjectedIntlProps } from 'react-intl';
import {
  AccessEnum,
  ContentStatus,
  CreateTodoMutation,
  CreateTodoVariables,
  FieldsOnEventExtendedInstances,
  FieldsOnEventExtendedTodos,
  GetInstanceAndTaskCalendars,
  Person,
  PersonAccess,
  TaskTypeEnum,
  UpdateEventInstanceInput,
  UserFile,
} from '../../../models/types';
import * as React from 'react';
import Mutation from 'react-apollo/Mutation';
import moment from 'moment';
import { Formik, FormikProps } from 'formik';
import {
  Colors,
  ColorTheme,
  MessageType,
  safeInvokeDeprecated,
} from '../../common';
import {
  StatusMessageDialog,
  ThemeColor,
} from '../../basic/StatusMessage/StatusMessage';
import {
  getReminderDate,
  getReminderIntervalDropdown,
  ReminderDropdown,
  ReminderOptions,
} from '../ReminderDropdown';
import { BreadCrumb, BreadCrumbs } from '../BreadCrumbs';
import { MunikumIcons } from '../../common/icons';
import { commonLabels } from '../../language/commonLabels';
import { FieldGroup } from '../../basic/FieldGroup/FieldGroup';
import { FormLabel } from '../../basic/FormLabel/FormLabel';
import { PageTitle } from '../../layout/Page/Page';
import { MuContentEditable } from '../../basic/MuEditor/MuContentEditable';
import {
  Button,
  TextField,
  ValidationMessage,
  ValidationSummary,
} from '../../basic';
import { MuField } from '../../basic/MuEditor/form/MuField';
import { ResponibleContainer } from '../CreateEventForm';
import {
  AnnualCycleAddMembersCard,
  SmallText,
} from '../AnnualCycleAddMembersCard';
import { messages } from '../messages';
import { DateTimePicker } from '../../basic/DateTimePicker/DateTimePicker';
import { Warning } from '../Warning';
import { FakeLink } from '../../basic/Structural/links';
import { commonMessages } from '../../language/commonMessages';
import { H4 } from '../../basic/Structural/typography';
import { CreateCard } from '../CreateCard';
import { TodoCard } from '../TodoCard';
import {
  CREATE_TODO,
  CreateTodoMutationComp,
  GET_INSTANCE_AND_TASK_QUERY,
  GET_REVERSE_INSTANCE_AND_TASK_QUERY,
  UPDATE_EVENT_INSTANCE,
  UPDATE_TODO,
  UpdateTodoMutationComp,
} from './eventQueries';
import styled from 'styled-components';
import * as _ from 'lodash';
import { Toaster } from '../../basic/Toaster/Toaster';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import memoizeOne from 'memoize-one';
import { guid } from '../../basic/UserFile/FileCommon';
import { Tooltip } from '../../basic/Tooltip/Tooltip';
import { SelectMode } from '../../basic/DropDownSelectUltimate/UltimateDropdown';
import { CalendarsDropdown } from '../CalendarsDropdown';
import { myHistory } from '../../../index';
import { uploadFile } from '../../../services/fileservice';
import gql from 'graphql-tag';
import { FileInfoCard } from '../../basic/UserFile/FileInfoCard';
import { Overlay } from '../../basic/Overlay/Overlay';
import { MuTextField } from '../../basic/MuEditor/MuTextField';
import { convertFromRaw, convertToRaw } from 'draft-js';
import { Checkbox } from '../../basic/Checkbox/Checkbox';

const uuidv1 = require('uuid/v1');

const Left = styled.div`
  flex: 0 0 auto; // this does'nt stop it from growing??
  max-width: 490px;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;

  background-color: #ffffff;
  border-top: 1px solid rgba(208, 211, 212, 0.7);
  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  padding: 1.875em 1.5em 1.875em 1.875em;
`;

const Right = styled.div`
  flex: 1 1 auto;
  background-color: #efefef;
  border-top: 1px solid rgba(208, 211, 212, 0.7);
  border-right: 1px solid rgba(208, 211, 212, 0.7);
  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  padding: 3.5em 1.875em 1.875em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Butts = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1em;
`;

const CalendarLink = styled.div`
  transition: ease-in-out 0.2s;
  :hover {
    transform: scale(1.02);
    border-bottom: 1px solid ${Colors.RED};
  }
`;

export const SET_STATUS_FILES = gql`
  mutation SetStatusFiles($input: SetStatusInput!) {
    setStatus(input: $input)
  }
`;

interface IInstanceFormProps extends InjectedIntlProps {
  /**
   * need this for refetch query
   */
  year: number;

  me: any;

  isAssignedInstance: boolean;
  /**
   * need this for refetch query
   */
  taskId: string;

  eventFiles: Array<any>;

  taskSelectedCalendar: any;

  /**
   * item to edit, initial values
   */
  item: FieldsOnEventExtendedInstances;

  mySelectedCalendar: any;
  /**
   * to display breadcrumbs
   */
  eventTitle: string;

  /**
   * to display breadcrumbs
   */
  taskTitle: string;

  taskType: TaskTypeEnum;

  // if the instance belongs to an repetition
  isRepetitionOn: boolean;

  userHash: string;

  /**
   * selected calendars on Task
   */
  selectedCalendars: GetInstanceAndTaskCalendars[];

  onClose?: () => void;

  onDelete?: () => void;

  onEventBreadcrumbClicked?: () => void;
}

interface IInstanceViewModel extends UpdateEventInstanceInput {
  /**
   * array of selected calendars
   */
  selectedCalendars: GetInstanceAndTaskCalendars[];
  /***
   * Todos that are being added from the createCard component gets pushed in this array
   */
  todosToBeCreated: Array<FieldsOnEventExtendedTodos>;
  /***
   * Todos that is not inside the todosToBeCreatedArray, and has been edited  gets pushed in here
   */
  todosToBeEdited: Array<FieldsOnEventExtendedTodos>;

  /***
   * Todos that should be deleted after the user presses save.
   */
  todosToBeDeleted: Array<FieldsOnEventExtendedTodos>;
  originalTodos: Array<FieldsOnEventExtendedTodos>;
}

interface InstanceFormState {
  reminderItem: ReminderOptions;
  filesToDelete: Array<any>;
  hasSuccessfullyEdited: boolean;
  files: Array<any>;
  isFileInfoCardVisible: boolean;
  fileInfoId: string | undefined;
  fileAccess: AccessEnum;
  item: FieldsOnEventExtendedInstances;
  hasUserSelectedEdit: boolean;
}

/**
 * Edit INSTANCE form
 */
const getSortedPublishedTodos = memoizeOne(
  (original: Array<FieldsOnEventExtendedTodos>) => {
    const array = [];
    original.forEach(c => {
      array.push(c);
    });

    return array;
  }
);

class InstanceForm extends React.PureComponent<
  IInstanceFormProps,
  InstanceFormState
> {
  private toastRef: React.RefObject<Toaster> = null;

  constructor(props: any) {
    super(props);
    this.state = {
      item: this.props.item,
      isFileInfoCardVisible: false,
      hasUserSelectedEdit: false,
      hasSuccessfullyEdited: false,
      filesToDelete: [],
      files: [
        ...this.props.item.files,
        ...this.props.eventFiles.map(eventFile => {
          return {
            id: eventFile.id,
            name: eventFile.name,
            description: eventFile.description,
            lastUpdated: eventFile.lastUpdated,
            size: eventFile.size,
            contentType: eventFile.contentType,
            access: {
              myAccess: AccessEnum.READ,
            },
          };
        }),
      ],
      reminderItem: ReminderOptions.NONE,
      fileAccess: AccessEnum.READ,
      fileInfoId: undefined,
    };
    this.toastRef = React.createRef();
  }

  componentWillReceiveProps(nextProps: IInstanceFormProps) {
    if (this.props.item.files !== nextProps.item.files) {
      this.setState({
        files: [
          ...nextProps.item.files,
          ...nextProps.eventFiles.map(eventFile => {
            return {
              id: eventFile.id,
              name: eventFile.name,
              description: eventFile.description,
              lastUpdated: eventFile.lastUpdated,
              size: eventFile.size,
              contentType: eventFile.contentType,
              access: {
                myAccess: AccessEnum.READ,
              },
            };
          }),
        ],
      });
    }
    if (nextProps.item !== this.state.item) {
      this.setState({
        item: nextProps.item,
      });
    }
  }

  render() {
    let doIhaveAccess = false;
    if (this.props.me.isAdmin) {
      doIhaveAccess = true;
    } else {
      if (this.props.mySelectedCalendar.createdBy.id === this.props.me.id) {
        doIhaveAccess = true;
      } else {
        this.props.mySelectedCalendar.access.persons.forEach(
          (c: PersonAccess) => {
            if (c.person.id === this.props.me.id) {
              if (c.access === AccessEnum.WRITE) {
                doIhaveAccess = true;
              }
            }
          }
        );
      }
    }
    const { intl, item } = this.props;
    return (
      <Mutation
        mutation={UPDATE_EVENT_INSTANCE}
        refetchQueries={[
          this.props.isAssignedInstance
            ? {
                query: GET_REVERSE_INSTANCE_AND_TASK_QUERY,
                variables: {
                  instanceId: this.props.item.id,
                },
              }
            : {
                query: GET_INSTANCE_AND_TASK_QUERY,
                variables: {
                  year: this.props.year,
                  taskId: this.props.taskId,
                },
              },
        ]}
      >
        {(updateEventInstance, { data, loading, called, error }) => {
          let isDescriptionDraftJsFormat = false;
          let isNoteDraftJsFormat = false;
          try {
            convertFromRaw(JSON.parse(item.description)).getPlainText();
            isDescriptionDraftJsFormat = true;
          } catch (e) {
            isDescriptionDraftJsFormat = false;
          }
          try {
            convertFromRaw(JSON.parse(item.note)).getPlainText();
            isNoteDraftJsFormat = true;
          } catch (e) {
            isNoteDraftJsFormat = false;
          }
          const myInitialValues: any = {
            todosToBeDeleted: [],
            todosToBeCreated: [],
            todosToBeEdited: [],
            originalTodos: _.cloneDeep(this.state.item.todos),
            selectedCalendars: this.props.selectedCalendars,
            id: item.id!,
            title: item.title,
            description: item.description,
            descriptionText: item.description
              ? isDescriptionDraftJsFormat
                ? convertFromRaw(JSON.parse(item.description)).getPlainText()
                : item.description
              : '',
            isDone: item.isDone,
            reminderDate: item.reminderDate
              ? moment(item.reminderDate).toDate()
              : undefined,
            responsible: (item.responsible && item.responsible.id) || '',
            isFavourite: item.isFavourite,
            endDate: item.endDate
              ? moment(item.endDate || undefined).toDate()
              : item.startDate, // ok to use startDate Kristoffer??
            startDate: moment(item.startDate).toDate(),
            status: ContentStatus.PUBLISHED,
            note: item.note ? item.note : '',
            noteText: item.note
              ? isNoteDraftJsFormat
                ? convertFromRaw(JSON.parse(item.note)).getPlainText()
                : item.note
              : '',
            files: [],
          };

          return (
            <Formik
              initialValues={myInitialValues}
              enableReinitialize={true} // TESTing to see if this is enough to re-initialize formik when we change item prop on comp
              onReset={(values: IInstanceViewModel, formikBag: any) => {
                // console.log('RESET INSTANCE FORM!!');
              }}
              onSubmit={async (values: any, formikBag: any) => {
                const input: UpdateEventInstanceInput = {
                  id: values.id || '',
                  title: values.title,
                  description: values.description,
                  descriptionText: values.descriptionText,
                  startDate: values.endDate,
                  endDate: values.endDate,
                  status: ContentStatus.PUBLISHED,
                  isDone: values.isDone,
                  isFavourite: values.isFavourite,
                  reminderDate: values.reminderDate,
                  responsible: values.responsible,
                  note: values.note,
                  noteText: values.noteText,
                };
                this.state.filesToDelete.forEach(c => {
                  myApolloClient.mutate({
                    mutation: SET_STATUS_FILES,
                    variables: {
                      input: {
                        id: +c,
                        status: ContentStatus.OBSOLETE,
                      },
                    },
                  });
                });

                for (let i = 0; i < values.files.length; i++) {
                  if (
                    values.files[i].file !== null &&
                    values.files[i].file !== undefined
                  ) {
                    try {
                      const uploadResult = await uploadFile(
                        values.files[i].file!,
                        values.id,
                        values.files[i].description,
                        p => console.log('upload progress:' + values.id)
                      );
                      if (!uploadResult.greatSuccess) {
                        // console.warn('Oops, upload failed: ' + files2[i].filename);
                        // NOTE: we could take some action here? Now we just continue to the next file...
                      }
                    } catch (uploadErr) {
                      console.log('upload err', uploadErr);
                    }
                  } else {
                  }
                }

                if (values.todosToBeDeleted.length > 0) {
                  values.todosToBeDeleted.forEach(async c => {
                    const result = await myApolloClient.mutate({
                      mutation: UPDATE_TODO,
                      variables: {
                        input: {
                          id: c.id,
                          title: c.title,
                          status: ContentStatus.OBSOLETE,
                          isDone: c.isDone,
                          description: c.description,
                          note: c.note,
                        },
                      },
                    });
                  });
                }

                if (values.todosToBeCreated.length > 0) {
                  values.todosToBeCreated.forEach(async c => {
                    const result = await myApolloClient.mutate<
                      CreateTodoMutation,
                      CreateTodoVariables
                    >({
                      mutation: CREATE_TODO,
                      variables: {
                        input: {
                          eventInstance: item.id,
                          title: c.title,
                          status: ContentStatus.PUBLISHED,
                          isDone: c.isDone,
                          description: c.description,
                          note: c.note,
                        },
                      },
                    });
                  });
                }
                if (values.todosToBeEdited.length > 0) {
                  values.todosToBeEdited.forEach(async c => {
                    const result = await myApolloClient.mutate({
                      mutation: UPDATE_TODO,
                      variables: {
                        input: {
                          id: c.id,
                          title: c.title,
                          status: ContentStatus.PUBLISHED,
                          isDone: c.isDone,
                          description: c.description,
                          note: c.note,
                        },
                      },
                    });
                  });
                }

                updateEventInstance({ variables: { input: input } })
                  .then(c => {
                    if (!this.state.hasUserSelectedEdit) {
                      safeInvokeDeprecated(
                        this.props.onClose
                        // console.log(input.responsible)
                      );
                    } else {
                      this.setState(
                        {
                          hasSuccessfullyEdited: true,
                        },
                        () => {
                          setTimeout(() => {
                            this.setState({
                              hasSuccessfullyEdited: false,
                            });
                          }, 3000);
                        }
                      );
                    }
                  })
                  .catch(err => console.log(err));
              }}
              render={(formikProps: FormikProps<IInstanceViewModel>) => {
                const sortedPublishedTodos = getSortedPublishedTodos(
                  formikProps.values.originalTodos
                );
                sortedPublishedTodos.sort(function(a: any, b: any) {
                  const nameA = a.title.toUpperCase();
                  const nameB = b.title.toUpperCase();
                  if (a.isDone < b.isDone) {
                    return -1;
                  }
                  if (a.isDone > b.isDone) {
                    return 1;
                  }

                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }

                  return 0;
                });

                if (error) {
                  return (
                    <StatusMessageDialog
                      theme={ThemeColor.ERROR_BLACK}
                      title={'Error'}
                      text={'An error occured: ' + error.message}
                      buttonText={'Dismiss'}
                      onClickButton={() => alert('dis')}
                    />
                  );
                }

                // const calculatedDate = getReminderDate(formikProps.values.endDate, item.value as ReminderOptions);

                const myReminderDate = getReminderIntervalDropdown(
                  intl,
                  formikProps.values.endDate,
                  formikProps.values.reminderDate || undefined
                );

                let myYearWheelColor = Colors.BLACK;
                // if (this.props.selectedCalendars.length === 1) {
                //   myYearWheelColor = this.props.selectedCalendars[0].color;
                // }
                return (
                  <div style={{ display: 'flex' }}>
                    <Left>
                      {!doIhaveAccess && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '.5em',
                            marginTop: '-1em',
                          }}
                        >
                          <MunikumIcons.warning
                            style={{ color: Colors.RED, marginRight: '.2em' }}
                          />
                          <SmallText
                            style={{
                              fontSize: '12px',
                              color: Colors.YELLOW,
                              marginLeft: '0em',
                            }}
                          >
                            {intl.formatMessage(commonMessages.noWriteAccess)}
                          </SmallText>
                        </div>
                      )}

                      <BreadCrumbs style={{ whiteSpace: 'normal' }}>
                        <Tooltip
                          style={{ width: '100%' }}
                          content={this.props.taskSelectedCalendar.title}
                        >
                          <CalendarLink>
                            <BreadCrumb
                              leftIcon={MunikumIcons.YearWheel}
                              onClick={() =>
                                myHistory.replace(
                                  '/calendar/' +
                                    this.props.taskSelectedCalendar.uri
                                )
                              }
                            >
                              <div
                                style={{
                                  maxWidth: '15em',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {this.props.taskSelectedCalendar.title}
                              </div>
                            </BreadCrumb>
                          </CalendarLink>
                        </Tooltip>
                        {this.props.taskType === TaskTypeEnum.DEFAULT && (
                          <Tooltip content={this.props.taskTitle}>
                            <div>
                              <BreadCrumb leftIcon={MunikumIcons.Folder}>
                                <div
                                  style={{
                                    maxWidth: '15em',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {this.props.taskTitle}
                                </div>
                              </BreadCrumb>
                            </div>
                          </Tooltip>
                        )}

                        {this.props.isRepetitionOn && (
                          <BreadCrumb
                            leftIcon={MunikumIcons.Repeat}
                            onClick={() =>
                              safeInvokeDeprecated(
                                this.props.onEventBreadcrumbClicked
                              )
                            }
                          >
                            {intl.formatMessage(commonLabels.serie)}
                          </BreadCrumb>
                        )}
                        <Tooltip content={this.props.item.title}>
                          <div>
                            <BreadCrumb
                              leftIcon={MunikumIcons.SingleTask}
                              isDisabled={true}
                            >
                              <div
                                style={{
                                  maxWidth: '15em',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {this.props.item.title}
                              </div>
                            </BreadCrumb>
                          </div>
                        </Tooltip>
                      </BreadCrumbs>
                      <div
                        style={{
                          marginTop: '1em',
                          overflowX: 'hidden',
                        }}
                      >
                        {/*CONTENT LEFT*/}

                        {/* alternative 1, setup field group, label, contentEditableContent, and validationmessages manually */}
                        <FieldGroup>
                          <FormLabel htmlFor={'title'}>Tittel</FormLabel>
                          <PageTitle>
                            <MuContentEditable
                              id={'title'}
                              name={'title'}
                              canEdit={doIhaveAccess} // TODO: add access rights here.. disable if your user cannot edit!
                              isMultiLine={false}
                              value={formikProps.values.title}
                              onSubmit={(md: string) => {
                                let text = md.replace(/(\r\n|\n|\r)/gm, '');
                                formikProps.setFieldValue('title', text);
                              }}
                              style={{
                                // minWidth: '6em',
                                width: '100%',
                                // fixes overflow problem
                                margin: '.1px',
                              }}
                              placeholder={intl.formatMessage(
                                commonLabels.titlePlaceholder
                              )}
                            />
                          </PageTitle>
                          <ValidationSummary
                            error={
                              formikProps.errors.title &&
                              formikProps.touched.title
                            }
                          />
                        </FieldGroup>
                        {/*<TextField*/}
                        {/*label={intl.formatMessage(commonLabels.title)}*/}
                        {/*name={'title'}*/}
                        {/*value={formikProps.values.title}*/}
                        {/*onBlur={formikProps.handleBlur}*/}
                        {/*onChange={formikProps.handleChange}*/}
                        {/*error={formikProps.touched.title && formikProps.errors.title}*/}
                        {/*style={{*/}
                        {/*width: '100%'*/}
                        {/*}}*/}
                        {/*/>*/}

                        {/* alternative 2, built in label and validationmessages */}
                        <div>
                          <MuTextField
                            shouldEditorHaveFiles={true}
                            doIHaveAccessToAddFiles={doIhaveAccess}
                            onClickFile={(id, access) => {
                              this.setState({
                                isFileInfoCardVisible: true,
                                fileInfoId: id,
                                fileAccess: access || AccessEnum.READ,
                              });
                            }}
                            label={intl.formatMessage(
                              commonMessages.description
                            )}
                            placeholder={intl.formatMessage(
                              commonMessages.writeHerePlaceholder
                            )}
                            files={
                              this.state.files &&
                              this.state.files.map((file: UserFile) => {
                                const temp: any = {
                                  size: file.size,
                                  contenttype: file.contentType,
                                  lastupdated: file.lastUpdated,
                                  filename: file.name,
                                  description: file.description,
                                  id: file.id,
                                  access:
                                    (file &&
                                      file.access &&
                                      file.access.myAccess) ||
                                    AccessEnum.READ,
                                };
                                return temp;
                              })
                            }
                            onSubmit={(value, files) => {
                              formikProps.setFieldValue('description', value);
                              formikProps.setFieldValue(
                                'descriptionText',
                                convertFromRaw(JSON.parse(value)).getPlainText()
                              );
                              formikProps.setFieldValue('files', files);
                            }}
                            value={formikProps.values.description}
                          />
                        </div>
                        <Overlay isOpen={this.state.isFileInfoCardVisible}>
                          <FileInfoCard
                            isCalendarEventForm={true}
                            hasWriteAccessButIsEventFile={doIhaveAccess}
                            fileid={this.state.fileInfoId}
                            canDelete={this.state.fileAccess === 'WRITE'}
                            canEdit={this.state.fileAccess === 'WRITE'}
                            onSaveSuccess={() => {
                              this.setState({
                                isFileInfoCardVisible: false,
                              });
                            }}
                            onDelete={() => {
                              let filesToDeleteCopy = this.state.filesToDelete.slice();
                              let filesToRemoveFromUi = this.state.files.slice();
                              filesToRemoveFromUi.forEach((file, i) => {
                                if (file.id === this.state.fileInfoId) {
                                  filesToRemoveFromUi.splice(i, 1);
                                }
                              });
                              filesToDeleteCopy.push(this.state.fileInfoId);
                              this.setState({
                                filesToDelete: filesToDeleteCopy,
                                isFileInfoCardVisible: false,
                                files: filesToRemoveFromUi,
                              });
                            }}
                            onCancel={() => {
                              this.setState({
                                isFileInfoCardVisible: false,
                              });
                            }}
                          />
                        </Overlay>
                        {/*<TextArea*/}
                        {/*label={intl.formatMessage(commonLabels.description)}*/}
                        {/*name={'description'}*/}
                        {/*onBlur={formikProps.handleBlur}*/}
                        {/*onChange={formikProps.handleChange}*/}
                        {/*value={formikProps.values.description || ''}*/}
                        {/*error={formikProps.touched.description && formikProps.errors.description}*/}
                        {/*style={{*/}
                        {/*width: '100%'*/}
                        {/*}}*/}
                        {/*/>*/}
                        <ResponibleContainer style={{ display: 'flex' }}>
                          <div
                            style={{
                              width: '100%',
                              marginRight:
                                item.assignedBy.id !== this.props.userHash
                                  ? '12px'
                                  : '4px',
                            }}
                          >
                            <FormLabel>
                              {intl.formatMessage(messages.responsible)}
                            </FormLabel>
                            <AnnualCycleAddMembersCard
                              disabled={!doIhaveAccess}
                              showOnlyPerson={true}
                              tagMaxWidth={{ maxWidth: '150px' }}
                              currentPermissionOwner={
                                (item.responsible && item.responsible.name) ||
                                ''
                              }
                              onTagClose={() => {
                                formikProps.setFieldValue('responsible', '');
                              }}
                              showTag={true}
                              addMember={(person: Person) => {
                                formikProps.setFieldValue(
                                  'responsible',
                                  person.id
                                );
                              }}
                            />
                          </div>
                          {item.assignedBy.id !== this.props.userHash && (
                            <Tooltip content={item.assignedBy.name}>
                              <div>
                                <TextField
                                  style={{ width: '240px' }}
                                  disabled={true}
                                  name={'assignedBy'}
                                  value={item.assignedBy.name}
                                  label={intl.formatMessage(
                                    commonMessages.assignedBy
                                  )}
                                />
                              </div>
                            </Tooltip>
                          )}
                        </ResponibleContainer>
                        {/*<CalendarsDropdown*/}
                        {/*disabled={true}*/}
                        {/*label={intl.formatMessage(messages.calendar)}*/}
                        {/*onBlur={formikProps.handleBlur}*/}
                        {/*selectMode={SelectMode.SINGLE}*/}
                        {/*selectedItems={[this.props.taskSelectedCalendar]}*/}
                        {/*style={{*/}
                        {/*width: '100%',*/}
                        {/*}}*/}
                        {/*/>*/}

                        {/*<div style={{ display: 'flex' }}>tildelt av:</div>*/}

                        <CalendarsDropdown
                          disabled={true}
                          label={intl.formatMessage(messages.calendar)}
                          onBlur={formikProps.handleBlur}
                          selectMode={SelectMode.SINGLE}
                          selectedItems={[this.props.taskSelectedCalendar]}
                          style={{
                            width: '100%',
                            // width: '15em',
                          }}
                        />
                        {/*<DateTimePicker*/}
                        {/*disabled={!doIhaveAccess}*/}
                        {/*label={'Start'}*/}
                        {/*onChange={(selectedDate: Date) => {*/}
                        {/*formikProps.setFieldValue(*/}
                        {/*'startDate',*/}
                        {/*selectedDate*/}
                        {/*);*/}

                        {/*if (*/}
                        {/*moment(selectedDate).isAfter(*/}
                        {/*moment(formikProps.values.endDate)*/}
                        {/*)*/}
                        {/*) {*/}
                        {/*const fresh = moment(selectedDate)*/}
                        {/*.subtract(0, 'days')*/}
                        {/*.toDate();*/}
                        {/*formikProps.setFieldValue('endDate', fresh);*/}
                        {/*}*/}
                        {/*}}*/}
                        {/*onSave={(selectedDate: Date) => {*/}
                        {/*formikProps.setFieldValue(*/}
                        {/*'startDate',*/}
                        {/*selectedDate*/}
                        {/*);*/}
                        {/*}}*/}
                        {/*value={formikProps.values.startDate}*/}
                        {/*// onBlur={formikProps.handleBlur}*/}
                        {/*name={'startDate'}*/}
                        {/*pickTime={false}*/}
                        {/*pickDate={true}*/}
                        {/*closeOnSelect={true}*/}
                        {/*/>*/}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            // opacity: 0.5,
                          }}
                        >
                          <DateTimePicker
                            disabled={!doIhaveAccess}
                            label={'Frist'}
                            onChange={(selectedDate: Date) => {
                              // TODO: update reminderDate when changing endDate!
                              // const calculatedDate = getReminderDate(selectedDate, item.value as ReminderOptions);
                              // console.log('setting reminderDate tpo: ', calculatedDate, formikProps.values.endDate);
                              // formikProps.setFieldValue('reminderDate', calculatedDate);

                              // const currentReminderDate = myReminderDate;
                              // const currentInterval = getReminderInterval(formikProps.values.endDate, currentReminderDate);

                              formikProps.setFieldValue(
                                'endDate',
                                selectedDate
                              );

                              if (
                                moment(selectedDate).isBefore(
                                  moment(formikProps.values.startDate)
                                )
                              ) {
                                const fresh = moment(selectedDate)
                                  .subtract(0, 'days')
                                  .toDate();
                                // console.log('setting start to: ', fresh);

                                formikProps.setFieldValue('startDate', fresh);
                              }
                            }}
                            onSave={(selectedDate: Date) => {
                              let initialReminderOption: ReminderOptions = this
                                .state.reminderItem;
                              if (
                                this.state.reminderItem === ReminderOptions.NONE
                              ) {
                                switch (myReminderDate.id) {
                                  case 'ONE_WEEK':
                                    initialReminderOption =
                                      ReminderOptions.ONE_WEEK;
                                    break;
                                  case 'TWO_WEEK':
                                    initialReminderOption =
                                      ReminderOptions.TWO_WEEK;
                                    break;
                                  case 'FOUR_WEEKS':
                                    initialReminderOption =
                                      ReminderOptions.FOUR_WEEKS;
                                    break;
                                  default:
                                    initialReminderOption =
                                      ReminderOptions.ONE_WEEK;
                                }
                                this.setState({
                                  reminderItem: initialReminderOption,
                                });
                              }
                              if (formikProps.values.reminderDate) {
                                const calculatedDate = getReminderDate(
                                  selectedDate,
                                  initialReminderOption
                                );
                                formikProps.setFieldValue(
                                  'reminderDate',
                                  calculatedDate
                                );
                              }

                              formikProps.setFieldValue(
                                'endDate',
                                selectedDate
                              );
                            }}
                            value={formikProps.values.endDate}
                            name={'endDate'}
                            outerStyle={
                              {
                                // marginLeft: '.5em',
                              }
                            }
                            pickTime={false}
                            pickDate={true}
                            closeOnSelect={true}
                          />

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <ReminderDropdown
                              disabled={!doIhaveAccess}
                              name={'reminderDate'}
                              leftIcon={MunikumIcons.Reminder}
                              onBlur={formikProps.handleBlur}
                              label={intl.formatMessage(commonLabels.reminder)}
                              fieldGroupStyle={{
                                marginLeft: '.5em',
                              }}
                              error={
                                formikProps.touched.reminderDate &&
                                formikProps.errors.reminderDate
                              }
                              onSelected={selectedDate => {
                                this.setState(
                                  {
                                    reminderItem: selectedDate.id as ReminderOptions,
                                  },
                                  () => {
                                    const calculatedDate = getReminderDate(
                                      formikProps.values.endDate,
                                      selectedDate.id as ReminderOptions
                                    );
                                    formikProps.setFieldValue(
                                      'reminderDate',
                                      calculatedDate
                                    );
                                  }
                                );
                              }}
                              selectedItems={
                                (myReminderDate && [myReminderDate]) || []
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <FieldGroup>
                        {!this.props.isAssignedInstance && (
                          <FormLabel>
                            {intl.formatMessage(commonLabels.repetition)}
                          </FormLabel>
                        )}
                        {!this.props.isAssignedInstance &&
                          !this.props.isRepetitionOn && (
                            <Warning>
                              <FormattedMessage
                                id={'AnnualCycle.warningAddRepetition'}
                                defaultMessage={
                                  '{link} if you want to add repetition to this task'
                                }
                                values={{
                                  br: <br />,
                                  link: (
                                    <FakeLink
                                      onClick={() => {
                                        safeInvokeDeprecated(
                                          this.props.onEventBreadcrumbClicked
                                        );
                                      }}
                                    >
                                      {intl.formatMessage(
                                        commonMessages.clickHere
                                      )}
                                    </FakeLink>
                                  ),
                                }}
                              />
                            </Warning>
                          )}

                        {!this.props.isAssignedInstance &&
                          this.props.isRepetitionOn && (
                            <Warning>
                              {/* TODO: add link to edit serie? */}
                              <FormattedMessage
                                id={'AnnualCycle.warningEditInstance2'}
                                defaultMessage={
                                  'Select serie in left menu or {link} if you want to edit serie'
                                }
                                values={{
                                  br: <br />,
                                  link: (
                                    <FakeLink
                                      onClick={() => {
                                        safeInvokeDeprecated(
                                          this.props.onEventBreadcrumbClicked
                                        );
                                      }}
                                    >
                                      klikk her
                                    </FakeLink>
                                  ),
                                }}
                              />
                            </Warning>
                          )}
                      </FieldGroup>
                      <MuField
                        label={intl.formatMessage(commonLabels.notes)}
                        name={'note'}
                        canEdit={doIhaveAccess} // TODO: add access rights here.. disable if your user cannot edit!
                        isMultiLine={true}
                        onSubmit={(md: string, draftJsFormat: any) => {
                          formikProps.setFieldValue(
                            'note',
                            JSON.stringify(convertToRaw(draftJsFormat))
                          );
                          try {
                            formikProps.setFieldValue(
                              'noteText',
                              convertFromRaw(JSON.parse(md)).getPlainText()
                            );
                          } catch (e) {
                            formikProps.setFieldValue('noteText', md);
                          }
                          // formikProps.setFieldValue('note', md);
                        }}
                        value={formikProps.values.note || ''}
                        placeholder={intl.formatMessage(
                          commonLabels.notesPlaceholder
                        )}
                        style={{
                          width: '100%',
                        }}
                        error={
                          formikProps.errors.note && formikProps.touched.note
                        }
                      />
                    </Left>
                    <Right>
                      <div>
                        {/*CONTENT RIGHT*/}
                        <H4>{intl.formatMessage(messages.checklistTitle)}</H4>
                        {/* this is VERY slow... add optimistic updates? with no ID is that possible? => YEP, done :-)*/}
                        <CreateTodoMutationComp
                          mutation={CREATE_TODO}
                          refetchQueries={[
                            !this.props.isAssignedInstance
                              ? {
                                  query: GET_INSTANCE_AND_TASK_QUERY, // TODO: optimize network, we could refetch only INSTANCE, not entire task!
                                  variables: {
                                    year: this.props.year,
                                    taskId: this.props.taskId,
                                  },
                                }
                              : {
                                  query: GET_REVERSE_INSTANCE_AND_TASK_QUERY,
                                  variables: {
                                    instanceId: this.props.item.id,
                                  },
                                },
                          ]}
                        >
                          {(
                            createTodo,
                            {
                              data: createData,
                              error: createError,
                              called: createCalled,
                              loading: createLoading,
                            }
                          ) => (
                            // TODO: show error of error when creating new todo
                            // TODO: disable input when loading? <CreateCard isDisabled={createLoading}

                            <div>
                              <CreateCard
                                disabled={!doIhaveAccess}
                                placeholder={intl.formatMessage(
                                  messages.addChecklistPlaceholder
                                )}
                                style={{
                                  marginBottom: '1em',
                                  marginTop: '1em',
                                }}
                                onSubmit={title => {
                                  let fUuid: string = guid();
                                  // console.log(fUuid);
                                  const todoToPush: any = {
                                    id: fUuid,
                                    note: null,
                                    isDone: false,
                                    status: ContentStatus.PUBLISHED,
                                    description: null,
                                    title: title,
                                    fromTemplate: false,
                                  };

                                  sortedPublishedTodos.push(todoToPush);
                                  const a = formikProps.values.todosToBeCreated.slice();
                                  a.push(todoToPush);
                                  formikProps.setFieldValue(
                                    'todosToBeCreated',
                                    a
                                  );

                                  // createTodo({
                                  //   variables: {
                                  //     input: {
                                  //       eventInstance:
                                  //         formikProps.values.id || '',
                                  //       isDone: false,
                                  //       title: title,
                                  //       status: ContentStatus.PUBLISHED,
                                  //     },
                                  //   },
                                  //   optimisticResponse: {
                                  //     createTodoInstance: {
                                  //       id: 'guid-hack-temp',
                                  //       title: title,
                                  //       description: '',
                                  //       note: '',
                                  //       isDone: false,
                                  //       status: ContentStatus.PUBLISHED,
                                  //       __typename: 'TodoInstanceType',
                                  //     },
                                  //   },
                                  //   update: (
                                  //     proxy: DataProxy,
                                  //     result: FetchResult<CreateTodoMutation>
                                  //   ) => {
                                  //     // example of CUSTOM update apollo cache func!
                                  //     // console.log(
                                  //     //   'example of CUSTOM update apollo cache func!'
                                  //     // );
                                  //     // this is hit both with optimistic response and real respones I think
                                  //
                                  //     // read data from cache (list of stuff). Here we have to read entire TASK and find instance
                                  //     const cachedData = proxy.readQuery<
                                  //       GetInstanceAndTaskQuery,
                                  //       GetInstanceAndTaskVariables
                                  //     >({
                                  //       query: GET_INSTANCE_AND_TASK_QUERY,
                                  //       variables: {
                                  //         year: this.props.year,
                                  //         taskId: this.props.taskId,
                                  //       },
                                  //     });
                                  //
                                  //     let cachedEventInstance:
                                  //       | FieldsOnEventExtendedInstances
                                  //       | undefined = undefined;
                                  //
                                  //     if (cachedData && cachedData.task) {
                                  //       for (
                                  //         let i = 0;
                                  //         i < cachedData.task.events.length;
                                  //         i++
                                  //       ) {
                                  //         const myEvent =
                                  //           cachedData.task.events[i];
                                  //
                                  //         for (
                                  //           let y = 0;
                                  //           y < myEvent.instances.length;
                                  //           y++
                                  //         ) {
                                  //           const myInstance =
                                  //             myEvent.instances[y];
                                  //           if (myInstance.id === item.id) {
                                  //             cachedEventInstance = myInstance;
                                  //             break;
                                  //           }
                                  //         }
                                  //       }
                                  //     }
                                  //
                                  //     // add response to cache list:
                                  //     if (cachedEventInstance) {
                                  //       const freshTodo =
                                  //         (result.data &&
                                  //           result.data.createTodoInstance) ||
                                  //         undefined;
                                  //       if (freshTodo) {
                                  //         cachedEventInstance.todos.push(
                                  //           freshTodo
                                  //         );
                                  //       }
                                  //     }
                                  //
                                  //     // custom write our data back to CACHE:
                                  //     // any should be GetInstanceAndTask.Query?
                                  //     if (cachedData) {
                                  //       proxy.writeQuery<
                                  //         GetInstanceAndTaskQuery,
                                  //         GetInstanceAndTaskVariables
                                  //       >({
                                  //         query: GET_INSTANCE_AND_TASK_QUERY,
                                  //         data: cachedData,
                                  //       });
                                  //     }
                                  //   },
                                  // });
                                }}
                              />
                              {createError && (
                                <ValidationMessage
                                  type={MessageType.ERROR}
                                  msg={createError.message}
                                />
                              )}
                            </div>
                          )}
                        </CreateTodoMutationComp>

                        <UpdateTodoMutationComp
                          mutation={UPDATE_TODO}
                          refetchQueries={[
                            !this.props.isAssignedInstance
                              ? {
                                  query: GET_INSTANCE_AND_TASK_QUERY,
                                  variables: {
                                    year: this.props.year,
                                    taskId: this.props.taskId,
                                  },
                                }
                              : {
                                  query: GET_REVERSE_INSTANCE_AND_TASK_QUERY,
                                  variables: {
                                    instanceId: this.props.item.id,
                                  },
                                },
                          ]}
                        >
                          {(
                            updateTodo,
                            {
                              data: updateData,
                              error: updateError,
                              called: updateCalled,
                              loading: updateLoading,
                            }
                          ) => {
                            return (
                              <div
                                style={{
                                  overflowY: 'auto',
                                  maxHeight: '300px',
                                }}
                              >
                                {/* TODO: show error if we fail to delete todo? */}

                                {sortedPublishedTodos.map(
                                  (t, index) =>
                                    t.status !== ContentStatus.OBSOLETE && (
                                      <TodoCard
                                        disabled={!doIhaveAccess}
                                        key={'tr45t_' + t.id}
                                        canDelete={!t.fromTemplate}
                                        todoName={t.title}
                                        isChecked={t.isDone}
                                        onChange={md => {
                                          if (
                                            formikProps.values.originalTodos.indexOf(
                                              t
                                            ) > -1
                                          ) {
                                            if (
                                              formikProps.values.todosToBeEdited.indexOf(
                                                t
                                              ) > -1
                                            ) {
                                              const index2 = _.findIndex(
                                                formikProps.values
                                                  .todosToBeEdited,
                                                (a: any) => {
                                                  return a.id === t.id;
                                                }
                                              );
                                              formikProps.values.todosToBeEdited[
                                                index2
                                              ].title = md;
                                            } else {
                                              const g = formikProps.values.todosToBeEdited.slice();
                                              t.title = md;
                                              g.push(t);
                                              formikProps.setFieldValue(
                                                'todosToBeEdited',
                                                g
                                              );
                                            }
                                          } else {
                                            const forceRender = formikProps.values.todosToBeCreated.slice();
                                            const index2 = _.findIndex(
                                              forceRender,
                                              (a: any) => {
                                                return a.id === t.id;
                                              }
                                            );
                                            forceRender[index2].title = md;
                                            formikProps.setFieldValue(
                                              'todosToBeCreated',
                                              forceRender
                                            );
                                            // formikProps.handleChange(e);
                                          }
                                        }}
                                        checkBoxColor={myYearWheelColor}
                                        isCheckBoxVisible={true}
                                        onClickTodo={() => {
                                          const copy = sortedPublishedTodos.slice();
                                          copy[index].isDone = !copy[index]
                                            .isDone;
                                          formikProps.setFieldValue(
                                            'todosToBeDeleted',
                                            formikProps.values.todosToBeDeleted
                                          );
                                          if (
                                            formikProps.values.originalTodos.indexOf(
                                              t
                                            ) > -1 &&
                                            !(
                                              formikProps.values.todosToBeEdited.indexOf(
                                                t
                                              ) > -1
                                            ) &&
                                            !(
                                              formikProps.values.todosToBeCreated.indexOf(
                                                t
                                              ) > -1
                                            )
                                          ) {
                                            const a = formikProps.values.todosToBeEdited.slice();
                                            a.push(copy[index]);
                                            formikProps.setFieldValue(
                                              'todosToBeEdited',
                                              a
                                            );
                                          } else if (
                                            formikProps.values.todosToBeCreated.indexOf(
                                              t
                                            ) > -1
                                          ) {
                                            const index2 = _.findIndex(
                                              formikProps.values
                                                .todosToBeCreated,
                                              (a: any) => {
                                                return a.title === t.title;
                                              }
                                            );
                                            formikProps.values.todosToBeCreated[
                                              index2
                                            ].isDone = t.isDone;
                                          }

                                          // if(formikProps.values.todosToBeEdited.indexOf(t) > -1) {
                                          //   const index2 = _.findIndex(formikProps.values.todosToBeEdited, (a: any)=> {
                                          //     return a.id === t.id;
                                          //   });
                                          //   // const todosToBeEditedCopy = formikProps.values.todosToBeEdited.slice();
                                          //   // todosToBeEditedCopy[index2].isDone = !t.isDone;
                                          //   // formikProps.setFieldValue('todosToBeEdited', todosToBeEditedCopy);
                                          // }
                                          // updateTodo({
                                          //   variables: {
                                          //     input: {
                                          //       id: t.id,
                                          //       title: t.title,
                                          //       description: t.description,
                                          //       note: t.note,
                                          //       isDone: !t.isDone,
                                          //       status: ContentStatus.PUBLISHED,
                                          //     },
                                          //   },
                                          //   optimisticResponse: {
                                          //     updateTodoInstance: {
                                          //       id: t.id,
                                          //       title: t.title,
                                          //       description: t.description,
                                          //       note: t.note,
                                          //       isDone: !t.isDone,
                                          //       status: ContentStatus.PUBLISHED,
                                          //       __typename: 'TodoInstanceType',
                                          //     },
                                          //   },
                                          // });
                                        }}
                                        onDeleteClicked={() => {
                                          // /**
                                          //  * He i check if the todo is already in the existing todos that i got from props,
                                          //  *if it is, i push it into todosToBeDeleted Array. if it is a new todo that is in todos
                                          //  * to be created i remove it from todostobecreated based on its title.
                                          //  **/
                                          if (
                                            formikProps.values.originalTodos.indexOf(
                                              t
                                            ) > -1
                                          ) {
                                            sortedPublishedTodos.splice(
                                              index,
                                              1
                                            );
                                            const tempCopy = formikProps.values.todosToBeDeleted.slice();
                                            tempCopy.push(t);
                                            formikProps.setFieldValue(
                                              'todosToBeDeleted',
                                              tempCopy
                                            );
                                            if (
                                              formikProps.values.todosToBeEdited.indexOf(
                                                t
                                              ) > -1
                                            ) {
                                              const temp4 = formikProps.values.todosToBeEdited.slice();
                                              const index2 = _.findIndex(
                                                temp4,
                                                (a: any) => {
                                                  return a.id === t.id;
                                                }
                                              );
                                              temp4.splice(index2, 1);
                                              formikProps.setFieldValue(
                                                'todosToBeEdited',
                                                temp4
                                              );
                                            }
                                          } else if (
                                            formikProps.values.todosToBeCreated.indexOf(
                                              t
                                            ) > -1
                                          ) {
                                            const deleteIndex = _.findIndex(
                                              sortedPublishedTodos,
                                              ind => {
                                                return ind.id === t.id;
                                              }
                                            );
                                            sortedPublishedTodos.splice(
                                              deleteIndex,
                                              1
                                            );
                                            const temp2 = formikProps.values.todosToBeCreated.slice();
                                            const index2 = _.findIndex(
                                              temp2,
                                              (a: any) => {
                                                return a.id === t.id;
                                              }
                                            );
                                            temp2.splice(index2, 1);
                                            formikProps.setFieldValue(
                                              'todosToBeCreated',
                                              temp2
                                            );
                                          }

                                          // updateTodo({
                                          //   variables: {
                                          //     input: {
                                          //       id: t.id,
                                          //       title: t.title,
                                          //       description: t.description,
                                          //       note: t.note,
                                          //       isDone: t.isDone,
                                          //       status: ContentStatus.DRAFT,
                                          //     },
                                          //   },
                                          //   optimisticResponse: {
                                          //     updateTodoInstance: {
                                          //       id: t.id,
                                          //       title: t.title,
                                          //       description: t.description,
                                          //       note: t.note,
                                          //       isDone: t.isDone,
                                          //       status: ContentStatus.DRAFT,
                                          //       __typename: 'TodoInstanceType',
                                          //     },
                                          //   },
                                          // });
                                        }}
                                      />
                                    )
                                )}
                              </div>
                            );
                          }}
                        </UpdateTodoMutationComp>
                      </div>
                      <Butts>
                        {!this.props.isAssignedInstance &&
                          this.props.isRepetitionOn && (
                            <>
                              <Tooltip
                                content={intl.formatMessage(
                                  messages.editInstanceCheckboxText
                                )}
                              >
                                <div>
                                  <Checkbox
                                    style={{ marginBottom: 0 }}
                                    onClick={() => {
                                      this.setState(
                                        ({ hasUserSelectedEdit }) => {
                                          return {
                                            hasUserSelectedEdit: !hasUserSelectedEdit,
                                          };
                                        }
                                      );
                                    }}
                                  />
                                </div>
                              </Tooltip>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {intl.formatMessage(commonMessages.edit)}
                              </div>
                            </>
                          )}

                        <Button
                          text={intl.formatMessage(commonMessages.delete)}
                          theme={ColorTheme.White}
                          leftIcon={MunikumIcons.Delete}
                          onClick={() =>
                            safeInvokeDeprecated(this.props.onDelete)
                          }
                          style={{ marginLeft: '1em' }}
                          disabled={!doIhaveAccess}
                        />
                        <Button
                          text={intl.formatMessage(commonMessages.cancel)}
                          theme={ColorTheme.White}
                          leftIcon={MunikumIcons.Cancel}
                          onClick={() => {
                            if (
                              formikProps.values.todosToBeEdited.length > 0 ||
                              formikProps.values.todosToBeCreated.length > 0 ||
                              formikProps.values.todosToBeDeleted.length > 0
                            ) {
                              if (
                                confirm(
                                  intl.formatMessage(
                                    messages.warningCloseInstaceFormWithoutSaving
                                  )
                                )
                              ) {
                                safeInvokeDeprecated(this.props.onClose);
                              }
                            } else {
                              safeInvokeDeprecated(this.props.onClose);
                            }
                          }}
                          style={{ marginLeft: '.5em' }}
                          disabled={loading}
                        />

                        <Button
                          isSuccess={
                            this.props.isRepetitionOn
                              ? this.state.hasSuccessfullyEdited
                              : false
                          }
                          isLoading={loading}
                          disabled={!doIhaveAccess}
                          text={intl.formatMessage(commonMessages.save)}
                          theme={ColorTheme.Red}
                          innerButtonStyle={{
                            justifyContent: this.state.hasSuccessfullyEdited
                              ? 'center'
                              : 'inherit',
                          }}
                          onClick={() => {
                            // super hack to get unsaved changes from MuFields.. wait for it to loose focus, then trigger onSubmit..
                            setTimeout(() => {
                              formikProps.submitForm();
                            }, 200);
                          }}
                          style={{ marginLeft: '.5em' }}
                          leftIcon={MunikumIcons.Save}
                        />
                      </Butts>
                    </Right>
                  </div>
                );
              }}
            />
          );
        }}
      </Mutation>
    );
  }
}

export default InstanceForm;
