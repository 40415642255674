import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';

import { MunikumIcons } from '../common/icons';
import { Colors, safeInvoke } from '../common';
import {
  IconButton,
  IconButtonHoverStyle,
} from '../basic/IconButton/IconButton';

export interface IYearSelectorProps {
  onYearChanged?: (year: number, year2?: number) => void;

  year: number;
  year2?: number;

  style?: CSSProperties;
}

interface IYearSelectorState {
  // year?: number;
  // year2?: number;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

const Year = styled.div`
  font-family: Lato, sans-serif;
  font-size: 26px;
  line-height: 32px;
  color: ${Colors.BLACK};
  margin-left: 0.25em;
  margin-right: 0.25em;
`;

export class YearSelector extends React.PureComponent<
  IYearSelectorProps,
  IYearSelectorState
> {
  // constructor(props: IYearSelectorProps) {
  //   super(props);
  //
  //   this.state = {
  //     year: this.props.year,
  //     year2: this.props.year2,
  //   };
  // }

  // componentWillReceiveProps(nextProps: IYearSelectorProps) {
  //   if (nextProps.year !== undefined && nextProps.year !== this.state.year) {
  //     this.setState({
  //       year: nextProps.year,
  //     });
  //   }
  // }

  render() {
    const canGoBack = this.props.year !== undefined && this.props.year > 2017;
    const canGoForward =
      this.props.year && this.props.year < new Date().getFullYear() + 4;

    return (
      <Container style={this.props.style}>
        <IconButton
          icon={MunikumIcons.ArrowLeft}
          hoverStyle={IconButtonHoverStyle.ROUND}
          disabled={!canGoBack}
          style={{
            fontSize: '.8em',
          }}
          onClick={() => {
            if (canGoBack) {
              let y1 = this.props.year;
              let y2 = this.props.year2;

              y1 = y1 - 1;
              if (y2) {
                y2 = y2 - 1;
              }

              safeInvoke(this.props.onYearChanged, y1, y2);
            }
          }}
        />
        <Year>
          {this.props.year2 !== undefined
            ? this.props.year + '/' + this.props.year2
            : ''}
          {this.props.year2 === undefined && this.props.year}
        </Year>
        <IconButton
          icon={MunikumIcons.ArrowRight}
          hoverStyle={IconButtonHoverStyle.ROUND}
          disabled={!canGoForward}
          style={{
            fontSize: '.8em',
          }}
          onClick={() => {
            if (canGoForward) {
              let y1 = this.props.year;
              let y2 = this.props.year2;

              y1 = y1 + 1;
              if (y2) {
                y2 = y2 + 1;
              }

              safeInvoke(this.props.onYearChanged, y1, y2);
            }
          }}
        />
      </Container>
    );
  }
}
