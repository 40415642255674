import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import { safeInvoke } from '../common';

const Container = styled.div`
  height: 184px;
  width: 244px;
  opacity: ${(props: { isActive: boolean }) => (props.isActive ? '1' : '0.5')};

  background-color: rgba(208, 211, 212, 0.2);
  border-radius: 4px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-content: center;
  margin: 8px;

  &:hover {
    box-shadow: ${(props: { isActive: boolean }) =>
      props.isActive ? ' 0 2px 7px 0 rgba(0, 0, 0, 0.3)' : ''};
    cursor: ${(props: { isActive: boolean }) =>
      props.isActive ? 'pointer' : ''};
  }

  justify-content: center;
`;

const IconBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin-bottom: 0.5em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 10px;
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: bold;
`;

const Body = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 16px;
  margin-right: 12px;
  margin-left: 12px;
  text-align: center;
  width: 174px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IBoxesComp {
  title: string;
  icon: IconType;
  isActive: boolean;
  body?: string;
  style?: CSSProperties;
  onClick?: () => void;
  homeDashboardMode?: boolean;
  hasAccess?: boolean;
}
export function DashboardBox2(props: IBoxesComp) {
  const Icon = props.icon;
  return (
    <Container
      isActive={props.isActive}
      style={props.style}
      onClick={() => safeInvoke(props.onClick)}
    >
      <Content>
        {props.homeDashboardMode && props.hasAccess && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '.5em',
              }}
            >
              <IconBody style={{ alignItems: 'flex-start' }}>
                <Icon size={'1.25em'} fill={'#fff'} />
              </IconBody>
              <Top>
                <div style={{ marginLeft: '8px' }}>{props.title}</div>
              </Top>
            </div>
            <Body style={{ width: '220px' }}>{props.body}</Body>
          </>
        )}
        {!props.homeDashboardMode && (
          <>
            <div>
              <IconBody>
                <Icon size={'1.25em'} fill={'#fff'} />
              </IconBody>
              <Top>
                <div style={{ marginLeft: '6px' }}>{props.title}</div>
              </Top>
            </div>
            <Body>{props.body}</Body>
          </>
        )}
        {!props.hasAccess && props.homeDashboardMode && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '.5em',
              }}
            >
              <IconBody>
                <Icon size={'1.25em'} fill={'#333'} />
              </IconBody>
              <Top>
                <div style={{ marginLeft: '6px', color: '#333' }}>
                  {props.title}
                </div>
              </Top>
            </div>
            <Body style={{ color: '#333' }}>{props.body}</Body>
          </>
        )}
      </Content>
    </Container>
  );
}

DashboardBox2.defaultProps = {
  hasAccess: true,
};
