import * as React from 'react';
import styled from 'styled-components';
import sadMunin2 from './munin-sad2.svg';
import { Colors } from '../../common';
import { Link, NavLink } from 'react-router-dom';
import { ExternalLink, LinkComponent } from '../Structural/links';
import { InjectedIntlProps } from 'react-intl';
import { commonMessages } from '../../language/commonMessages';

const Box = styled.div`
  border-radius: 4px;
  width: 400px;
  height: 150px;
  background-color: #333333;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const Line = styled.div`
  background-color: ${Colors.RED};
  width: 100%;
  height: 6px;
  position: absolute;
  left: 0;
  bottom: 32px;
  z-index: 3;
`;

const Message = styled.div`
  font-family: Lato, sans-serif;
  font-size: 1.5em;
  line-height: 1.3em;
  color: #fff;
  margin-top: 30px;

  max-height: 2.6em;
  overflow: hidden;
  margin-right: 0.5em;
`;

const MiniErrorMessage: React.FunctionComponent<
  { message: string } & InjectedIntlProps
> = props => (
  <Box>
    <div style={{ zIndex: 4 }}>
      <img src={sadMunin2} width={'100px'} />
    </div>
    <div style={{ alignSelf: 'flex-start' }}>
      <Message>{props.message}</Message>
      <LinkComponent
        onClick={e => {
          window.location.reload();
        }}
      >
        {props.intl.formatMessage(commonMessages.retry)}
      </LinkComponent>
    </div>
    <Line />
  </Box>
);

export default MiniErrorMessage;
