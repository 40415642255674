import styled from 'styled-components';

export const ItemContainer = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  height: 2.25em; // 36px
  width: 30em; // can be overridden using style

  display: flex;

  align-items: center;
  justify-content: space-between;
  padding: 0.5em;

  overflow: hidden;
`;
