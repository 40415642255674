import * as React from 'react';
import { Option } from 'react-select';

import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import styled from 'styled-components';
import gql from 'graphql-tag';
import Query, { QueryResult } from 'react-apollo/Query';
import Helmet from 'react-helmet';
import { GraphQLPager } from '../../components/basic/Pager/GraphQLPager';
import {
  AllCalendarsNodes,
  AllCalendarsQuery,
  AllCalendarsVariables,
  MeCreateTopicQuery,
  OrderEnum,
  Organization,
  SortEnum,
} from '../../models/types';
import { fieldsOnCalendar } from './calendar-queries';
import { PageTitle, PageTop } from '../../components/layout/Page/Page';
import AnnualCycleCard from '../../components/annualCycle/AnnualCycleCard';
import { DropDownSelect } from '../../components/basic/DropDownSelect/DropDownSelect';
import { commonMessages } from '../../components/language/commonMessages';

import {
  CircleSpinnerAnimation,
  SuspenseLoader,
} from '../../components/basic/Loader/CircleSpinner';
import { myHistory } from '../../index';
import { commonLabels } from '../../components/language/commonLabels';
import { SmallText } from '../../components/annualCycle/AnnualCycleAddMembersCard';
import { AsyncSearchDropdown } from '../../components/basic/AsyncSearchDropdown/AsyncSearchDropdown';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import {
  IStaticImageType,
  StaticImage,
} from '../../components/basic/StaticImage/StaticImage';
import { FormLabel } from '../../components/basic/FormLabel/FormLabel';
import { Dispatch } from 'redux';
import {
  calendarChangePageActionCreator,
  filterCalendarsActionCreator,
  ICalendarFilter,
  ICalendarFilterState,
  resetCalendarsFilterActionCreator,
} from './calendar-service';
import { RootState } from '../../modules/rootReducer';
import { connect } from 'react-redux';
import { safeInvoke } from '../../components/common';

const ContentDiv = styled.div`
  border-radius: 3px;
  background-color: #fff;
  max-width: 84.125em;
  margin: 0 auto;
  min-height: 20em;
  box-sizing: border-box;
  padding: 2em;

  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1); // ok?
`;

const TopDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
`;

const ListDiv = styled.div`
  margin: 0.9em;
  width: 45.2%;

  @media (min-width: ${1710}px) {
    width: 31%;
  }
  @media (max-width: ${1200}px) {
    width: 100%;
  }
`;

const ListContainer = styled.div`
  display: block;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  @media (min-width: ${1200}px) {
    display: flex;
  }
`;
// usen in topiclistpage aswell
export const ListDivMunicipalities = styled.div`
  padding: 0.5em;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-family: Lato, sans-serif;
  border-bottom: 1px solid grey;
`;

const messages = defineMessages({
  title: {
    id: 'CalendarListPage.title',
    defaultMessage: 'Calendars',
  },
  yearWheelsMyMunicipality: {
    id: 'CalendarListPage.yearWheelsMyMunicipality',
    defaultMessage: 'Yearwheel my municipality',
  },
});
const ORGANIZATIONS = gql`
  query Organizations($query: String!) {
    search(query: $query) {
      organizations {
        id
        name
        organizationImage
      }
    }
  }
`;
const ME_FOR_CALENDAR_LIST_PAGE = gql`
  query MeForCalendarListPage {
    me {
      id
      name
      isAdmin
      userRoles
      permissions
      language
      organization {
        id
        name
      }
    }
  }
`;

export const FIND_ORGANIZATIONS = gql`
  query FindOrganizatins($query: String!) {
    findOrganizations(query: $query) {
      id
      name
      organizationImage
    }
  }
`;
export const ALL_CALENDARS = gql`
  query AllCalendars(
    $sort: SortEnum
    $order: OrderEnum
    $limit: Int
    $offset: Int
    $organizations: [Long!]
    $kostraGroup: Long
  ) {
    me {
      id
      name
      isAdmin
      userRoles
      permissions
      language
      organization {
        id
      }
    }
    allKostraFunctionGroups {
      id
      title
    }

    allCalendars(
      input: {
        sort: $sort
        order: $order
        limit: $limit
        offset: $offset
        organizations: $organizations
        kostraGroup: $kostraGroup
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemsPerPage
        numberOfPages
        pageNumber
      }
      totalCount
      nodes {
        ...fieldsOnCalendar
      }
    }
  }
  ${fieldsOnCalendar}
`;

class AllCalendarsQueryComp extends Query<
  AllCalendarsQuery,
  AllCalendarsVariables
> {}
interface ICalendarListPageProps {
  list: ICalendarFilterState;
}

export interface ICalendarListPageDispatch {
  onPageChanged?: (page: number) => void;
  onFilterChanged?: (filter: ICalendarFilter) => void;
  onFilterReset?: () => void;
}

export interface ICalendarListPageState {
  kostraSortValue: string | undefined;
}

class CalendarListPageComp extends React.PureComponent<
  ICalendarListPageProps &
    ICalendarListPageDispatch &
    InjectedAuthRouterProps &
    InjectedIntlProps,
  ICalendarListPageState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      kostraSortValue: undefined,
    };
  }

  // componentWillUnmount() {
  //   safeInvoke(this.props.onFilterReset);
  // }

  handleSortChange = (key: string) => {
    if (key === '1') {
      safeInvoke(this.props.onFilterChanged, {
        ...this.props.list.filter,
        sort: SortEnum.LAST_UPDATED,
        sortValue: '1',
        order: OrderEnum.DESCENDING,
      });
    } else {
      safeInvoke(this.props.onFilterChanged, {
        ...this.props.list.filter,
        sort: SortEnum.TITLE,
        order: OrderEnum.ASCENDING,
        sortValue: '0',
      });
    }
  };
  handleKostraGroupChange = (
    key: string,
    KostraGroupArray: Array<{ key: number; value: string }>
  ) => {
    if (!KostraGroupArray[key]) {
      safeInvoke(this.props.onFilterChanged, {
        ...this.props.list.filter,
        kostraGroup: null,
        kostraGroupValue: undefined,
      });
    } else {
      safeInvoke(this.props.onFilterChanged, {
        ...this.props.list.filter,
        kostraGroup: KostraGroupArray[key].id,
        kostraGroupValue: key,
      });
    }
  };

  handlePageChange = (pageNumber: number) => {
    const pageNum =
      (pageNumber === 1 && 0) ||
      (pageNumber - 1) * this.props.list.filter.limit;
    safeInvoke(this.props.onPageChanged, pageNum);
  };

  render() {
    const { intl } = this.props;
    const { sort, order, limit, offset } = this.props.list.filter;
    // Setup sort enum values
    let sortKeys = Object.keys(SortEnum);
    let sortArray: Array<{ key: number; value: string }> = [];

    for (let i = 0; i < sortKeys.length; i++) {
      if (sortKeys[i] === 'DEADLINE') {
        continue;
      }
      sortArray.push({
        key: i,
        value: intl.formatMessage({
          id: 'common.' + sortKeys[i].toLocaleLowerCase(),
          defaultMessage: sortKeys[i].toLocaleLowerCase(),
        }),
      });
    }

    // Setup order enum values
    let orderKeys = Object.keys(OrderEnum);
    let orderArray: Array<{ key: number; value: string }> = [];

    for (let i = 0; i < orderKeys.length; i++) {
      orderArray.push({
        key: i,
        value: intl.formatMessage({
          id: 'common.' + orderKeys[i].toLocaleLowerCase(),
          defaultMessage: orderKeys[i].toLocaleLowerCase(),
        }),
      });
    }

    return (
      <>
        <Helmet>
          <title>{intl.formatMessage(commonMessages.calendars)}</title>
        </Helmet>
        <div>
          <Query
            query={ME_FOR_CALENDAR_LIST_PAGE}
            children={(result2: QueryResult<MeCreateTopicQuery>) => {
              if (result2.loading) {
                return '';
              }
              if (result2.error) {
                return 'error';
              }
              const me = result2.data && result2.data.me;
              return (
                <AllCalendarsQueryComp
                  query={ALL_CALENDARS}
                  variables={{
                    order: order,
                    limit: limit,
                    sort: sort,
                    kostraGroup: this.props.list.filter.kostraGroup,
                    offset: this.props.list.currentPage,
                    organizations: this.props.list.filter.organizations.map(
                      c => c.id
                    ),
                  }}
                  fetchPolicy={'cache-and-network'}
                >
                  {({ loading, error, data }) => {
                    // const test1 = data.me.name
                    const isAdmin = data && data.me && data.me.isAdmin;
                    let temp =
                      me.organization.name &&
                      me.organization.name.toLowerCase();
                    let nameCapitalized =
                      temp && temp.charAt(0).toUpperCase() + temp.slice(1);

                    let kostraGroupArray: Array<{
                      key: number;
                      value: string;
                      id: string;
                    }> = [];

                    // if (data && data.allKostraFunctionGroups) {
                    //   data.allKostraFunctionGroups.map(
                    //     (value: KostraFunctionGroup, index) => {
                    //       kostraGroupArray.push({
                    //         key: index,
                    //         value: value.title,
                    //         id: value.id,
                    //       });
                    //     }
                    //   );
                    // }
                    // if (loading) {
                    //   return '';
                    // }

                    return (
                      <>
                        <PageTop
                          style={{ maxWidth: '84.125em', margin: '0 auto' }}
                        >
                          <PageTitle style={{ marginBottom: '.5em' }}>
                            {this.props.list.filter.predefinedOrg ? (
                              <FormattedMessage
                                id={'CalendarListPage.yearWheelsMyMunicipality'}
                                defaultMessage={
                                  'Yearwheels in {nameCapitalized}'
                                }
                                values={{
                                  municipality: nameCapitalized,
                                }}
                              />
                            ) : (
                              intl.formatMessage(messages.title)
                            )}
                          </PageTitle>
                        </PageTop>
                        <ContentDiv>
                          <TopDiv>
                            <div
                              style={{
                                fontFamily: 'Lato',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                marginLeft: '0.875em',
                                display: 'flex',
                              }}
                            >
                              {data &&
                                data.allCalendars &&
                                data.allCalendars.totalCount}{' '}
                              {intl.formatMessage(commonMessages.calendars)}
                              {data.allCalendars &&
                                data.allCalendars.totalCount !== undefined &&
                                data.allCalendars.totalCount === 0 && <>.</>}
                              {data.allCalendars &&
                                data.allCalendars.totalCount !== undefined &&
                                data.allCalendars.totalCount === 0 && (
                                  <SmallText style={{ color: '#FF5C39' }}>
                                    {intl.formatMessage(
                                      commonMessages.tryAnotherFilter
                                    )}
                                  </SmallText>
                                )}
                            </div>
                            <div style={{ position: 'relative' }}>
                              {loading && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    left: '-10em',
                                    top: '1em',
                                    width: '10em',
                                  }}
                                >
                                  <CircleSpinnerAnimation
                                    style={{
                                      width: '1em',
                                      height: '1em',
                                      position: 'absolute',
                                      left: '-1.25em',
                                      top: '0.2em',
                                    }}
                                  />
                                  Oppdaterer liste...
                                </div>
                              )}

                              <div
                                style={{
                                  display: 'flex',
                                  marginRight: '1em',
                                }}
                              >
                                <div>
                                  <FormLabel>
                                    {intl.formatMessage(
                                      commonMessages.searchMunicipalities
                                    )}
                                  </FormLabel>
                                  <AsyncSearchDropdown
                                    items={
                                      this.props.list.filter.organizations
                                        .length > 0
                                        ? this.props.list.filter.organizations.map(
                                            (c: Organization) => {
                                              return {
                                                id: c.id,
                                                label: c.name,
                                                img: c.organizationImage,
                                              };
                                            }
                                          )
                                        : []
                                    }
                                    value={
                                      this.props.list &&
                                      this.props.list.filter &&
                                      this.props.list.filter.organizations
                                        .length > 0
                                        ? this.props.list &&
                                          this.props.list.filter &&
                                          this.props.list.filter
                                            .organizations[0].name
                                        : undefined
                                    }
                                    style={{ width: '160px' }}
                                    leftIcon={true}
                                    defaultListStyling={false}
                                    canClearAll={true}
                                    onChange={values => {
                                      if (values) {
                                        let orgs: any = [];
                                        values.forEach(value => {
                                          orgs.push({
                                            name: value.label,
                                            id: value.id,
                                            organizationImage: value.img,
                                          });
                                        });

                                        safeInvoke(this.props.onFilterChanged, {
                                          ...this.props.list.filter,
                                          organizations: orgs,
                                          predefinedOrg: false,
                                        });
                                      }
                                    }}
                                    placeholder={'Søk her...'}
                                    noResultMessage={'Ingen resultat...'}
                                    getItems={input => {
                                      return new Promise(
                                        async (resolve, reject) => {
                                          try {
                                            let tempOrg: Array<any> = [];
                                            const res = await myApolloClient.query<
                                              any
                                            >({
                                              query: FIND_ORGANIZATIONS,
                                              variables: {
                                                query: input,
                                              },
                                            });
                                            tempOrg =
                                              res &&
                                              res.data &&
                                              res.data.findOrganizations &&
                                              res.data.findOrganizations.map(
                                                org => {
                                                  return {
                                                    id: org.id,
                                                    label: org.name,
                                                    img: org.organizationImage,
                                                  };
                                                }
                                              );
                                            resolve(tempOrg);
                                          } catch (e) {
                                            console.error('Fail', e);
                                            reject(e);
                                          }
                                        }
                                      );
                                    }}
                                    renderItem={(
                                      value: any,
                                      i,
                                      isActive,
                                      isSelected,
                                      img
                                    ) => (
                                      <ListDivMunicipalities
                                        key={i}
                                        style={{
                                          backgroundColor: isActive
                                            ? '#00B0B9'
                                            : '#fff',
                                          cursor: isActive
                                            ? 'pointer'
                                            : 'normal',
                                        }}
                                      >
                                        <div
                                          style={{
                                            height: '1.6em',
                                            fontSize: '18px',
                                            color: isSelected ? 'red' : 'grey',
                                            marginRight: '1em',
                                          }}
                                        >
                                          &#10003;
                                        </div>
                                        {value.label}
                                        <StaticImage
                                          key={i}
                                          width={21}
                                          height={21}
                                          style={{ marginLeft: '1em' }}
                                          type={IStaticImageType.ORGANIZATION}
                                          filename={img}
                                        />
                                      </ListDivMunicipalities>
                                    )}
                                  />
                                </div>
                                {/*<DropDownSelect*/}
                                {/*name={'kostraGroups'}*/}
                                {/*value={*/}
                                {/*this.props.list.filter.kostraGroupValue*/}
                                {/*}*/}
                                {/*label={intl.formatMessage(*/}
                                {/*commonLabels.kostra*/}
                                {/*)}*/}
                                {/*showEmptyOption={true}*/}
                                {/*defaultText={'Alle'}*/}
                                {/*onChangeSelected={e =>*/}
                                {/*this.handleKostraGroupChange(*/}
                                {/*e,*/}
                                {/*kostraGroupArray*/}
                                {/*)*/}
                                {/*}*/}
                                {/*items={kostraGroupArray}*/}
                                {/*accessor={'value'}*/}
                                {/*fieldGroupStyle={{*/}
                                {/*height: '36px',*/}
                                {/*width: '160px',*/}
                                {/*marginLeft: '1em',*/}
                                {/*}}*/}
                                {/*/>*/}
                                <DropDownSelect
                                  label={intl.formatMessage(
                                    commonLabels.sortBy
                                  )}
                                  name={'sort'}
                                  onChangeSelected={e =>
                                    this.handleSortChange(e)
                                  }
                                  value={this.props.list.filter.sortValue}
                                  showEmptyOption={false}
                                  items={sortArray}
                                  accessor={'value'}
                                  fieldGroupStyle={{
                                    height: '36px',
                                    width: '160px',
                                    marginLeft: '1em',
                                  }}
                                />
                              </div>
                            </div>
                          </TopDiv>

                          <ListContainer>
                            {!data && loading && (
                              <SuspenseLoader
                                text={intl.formatMessage(
                                  commonMessages.loading
                                )}
                              />
                            )}
                            {error && <p>Error</p>}
                            {data &&
                              data.allCalendars &&
                              data.allCalendars.nodes.map(
                                (calendar: AllCalendarsNodes) => {
                                  return (
                                    <ListDiv
                                      onClick={() => {
                                        if (!calendar.isLimited) {
                                          myHistory.push(
                                            '/calendar/' + calendar.uri
                                          );
                                        }
                                      }}
                                      key={calendar.id}
                                    >
                                      <AnnualCycleCard item={calendar} />
                                    </ListDiv>
                                  );
                                }
                              )}
                          </ListContainer>
                          {data &&
                            data.allCalendars &&
                            data &&
                            data.allCalendars.totalCount > 0 && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  justifyContent: 'center',
                                }}
                              >
                                <GraphQLPager
                                  pageInfo={
                                    data &&
                                    data.allCalendars &&
                                    data.allCalendars.pageInfo &&
                                    data.allCalendars.pageInfo
                                  }
                                  onPageChanged={this.handlePageChange}
                                />
                              </div>
                            )}
                        </ContentDiv>
                      </>
                    );
                  }}
                </AllCalendarsQueryComp>
              );
            }}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (
  state: RootState,
  ownProps: ICalendarListPageProps
): ICalendarListPageProps => ({
  list: state.calendar,
});

const mapDispatchToProps = (
  dispatch: Dispatch<{}>
): ICalendarListPageDispatch => ({
  onPageChanged: (page: number) => {
    dispatch(calendarChangePageActionCreator({ page: page }));
  },
  onFilterChanged: (filter: ICalendarFilter) => {
    dispatch(filterCalendarsActionCreator({ filter: filter }));
  },
  onFilterReset: () => {
    dispatch(resetCalendarsFilterActionCreator({}));
  },
});
export const CalendarListPage = connect<{}, {}, ICalendarListPageProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CalendarListPageComp));
export default CalendarListPage;
