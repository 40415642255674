import { MunikumIcons } from '../common/icons';
import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { ItemContainer } from './ItemContainer';
import { formatMunikumDate, safeInvokeDeprecated } from '../common';
import { Avatar } from '../person/Avatar';

const EventDiv = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  overflow: hidden;
`;

const StartDate = styled.div`
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 10px;
`;

const EventText = styled.div`
  color: #333;
  font-family: Lato, sans-serif;
  font-size: 0.75em;
  position: relative;
  line-height: 1em;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  margin-left: 0.5em;
`;

// const Border = styled.div`
//   height: 1px;
//   width: 100%;
//   z-index: 1;
//   position: absolute;
//   bottom: 6px;
// `;

const CalanderDiv = styled.div`
  height: 24px;
  width: 29px;

  background-color: #fff;
  border-radius: 4%;
  box-sizing: border-box;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  width: 220px;
  justify-content: space-between;
`;

const AvatarDiv = styled.div`
  display: flex;
  width: max-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(51, 51, 51, 0.7);
  font-family: Lato, sans-serif;
  font-size: 12px;
  align-items: center;
`;

const EventItemContainer = styled(ItemContainer)`
  cursor: pointer;
`;

// export const EventContainer = styled.div`
//   display: flex;
// `;
//
// const EventCardContentContainer = styled.div`
//   flex: 1 1 0px;
//
//   padding: 0.5em;
//   background-color: #fff;
//
//   overflow: hidden;
//   display: flex;
//
//   border-radius: 4px;
//   box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
//
//   overflow-wrap: break-word;
//   word-break: break-word; /* Chrome, Safari */
//   word-wrap: break-word; /* IE11, Firefox */
//
//   align-items: center;
//   flex-direction: row;
//   min-width: 492px;
//   justify-content: space-between;
// `;

const RemoveDiv = styled.div`
  display: ${(props: { canDelete: boolean }) =>
    props.canDelete ? 'flex' : 'none'};
  transition: all 0.2s ease-in-out;
  width: 1.6em;
  height: 1.6em;
  justify-content: center;
  align-items: center;
  color: rgb(255, 92, 57);
  :hover {
    transform: scale(1.3);
    cursor: pointer;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: lightgray;
`;

export interface IEventCardProps {
  title: string;
  personName: string;
  startDate?: Date;
  style?: CSSProperties;
  canDelete?: boolean;

  onDeleteClicked?: () => void;
  onCardClick?: () => void;
  onFavouriteClick?: () => void;
}

export class EventCard extends React.PureComponent<IEventCardProps> {
  render() {
    return (
      <EventItemContainer
        style={this.props.style}
        onClick={() => safeInvokeDeprecated(this.props.onCardClick)}
      >
        <EventDiv>
          {/*<CircleCheckBox*/}
          {/*style={{ marginRight: '1em', marginBottom: '.5em' }}*/}
          {/*unCheckedColor={this.props.checkBoxColor || 'red'}*/}
          {/*/>*/}
          <IconContainer>
            <MunikumIcons.SingleTask />
          </IconContainer>
          <EventText>
            {this.props.title}
            {/*<Border />*/}
          </EventText>
        </EventDiv>

        <div style={{ display: 'flex' }}>
          <InfoDiv>
            <AvatarDiv>
              <Avatar name={this.props.personName} />
            </AvatarDiv>
            <StartDate>
              {/* TODO: if no date, show red message no start date defined. Add DateTime selector to set date */}
              {formatMunikumDate(this.props.startDate)}
            </StartDate>
            <CalanderDiv>
              <MunikumIcons.YearWheel />
            </CalanderDiv>

            <IconContainer>
              <MunikumIcons.Flag />
            </IconContainer>
            <RemoveDiv
              canDelete={this.props.canDelete || false}
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                safeInvokeDeprecated(this.props.onDeleteClicked);
              }}
            >
              <MunikumIcons.Cancel
                style={{ height: '1.2em', width: '1.2em' }}
              />
            </RemoveDiv>
          </InfoDiv>
        </div>
      </EventItemContainer>
    );
  }
}
