import * as React from 'react';
import { BoxHill } from './BoxHill';
import { Newton } from './Newton';
import { Bird } from './Bird';
import { CircleSpinner } from './CircleSpinner';

export interface ILoaderProps {
  name?: 'boxhill' | 'newton' | 'bird' | 'random' | 'circle';
}

/***
 * Loader
 */
export class Loader extends React.PureComponent<
  ILoaderProps,
  { name?: string }
> {
  constructor(props: ILoaderProps) {
    super(props);
    this.state = {
      name:
        props.name === 'random' ? this.getRandomName() : props.name || 'bird',
    };
  }

  getRandomName = () => {
    return ['boxhill', 'newton', 'bird', 'circle'][
      Math.floor(Math.random() * 3)
    ];
  };

  handleClick = () => {
    if (this.state.name) {
      const list = ['boxhill', 'newton', 'bird', 'circle'];
      const curr = list.indexOf(this.state.name);
      let next = curr + 1;
      if (next >= list.length) {
        next = 0;
      }
      this.setState({
        name: list[next],
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: ILoaderProps) {
    this.setState({
      name: nextProps.name === 'random' ? this.getRandomName() : nextProps.name,
    });
  }

  render() {
    const { name } = this.state;
    return (
      <div onClick={this.handleClick}>
        {name === 'newton' && <Newton />}
        {name === 'boxhill' && <BoxHill />}
        {name === 'bird' && <Bird />}
        {name === 'circle' && <CircleSpinner />}
      </div>
    );
  }
}
