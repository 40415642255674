import React, { CSSProperties, useEffect } from 'react';
import { DropDownSelect } from '../DropDownSelect/DropDownSelect';
import { safeInvoke } from '../../common';

interface IMonthListDropDown {
  disabled?: boolean;
  monthListValue?: string;
  onMonthChange: (key: string) => void;
  style?: CSSProperties;
}

export function MonthListDropDown(props: IMonthListDropDown) {
  // used in repetionPicker, if you change initialValue it will not work correctly in repetitionPicker
  const [monthListValue, setMonthListValue] = React.useState(
    +props.monthListValue - 1 || '0'
  );

  useEffect(() => {
    if (monthListValue !== props.monthListValue && !props.disabled) {
      setMonthListValue(+props.monthListValue - 1);
    }
    if (props.disabled) {
      setMonthListValue(0);
    }
  });
  const handleChangeMonthValue = (key: string) => {
    setMonthListValue(key);
    safeInvoke(props.onMonthChange, key);
  };

  const monthNames = [
    {
      key: 1,
      value: 'Januar',
    },
    {
      key: 2,
      value: 'Februar',
    },
    {
      key: 3,
      value: 'Mars',
    },
    {
      key: 4,
      value: 'April',
    },
    {
      key: 5,
      value: 'Mai',
    },
    {
      key: 6,
      value: 'Juni',
    },
    {
      key: 7,
      value: 'Juli',
    },
    {
      key: 8,
      value: 'August',
    },
    {
      key: 9,
      value: 'September',
    },
    {
      key: 10,
      value: 'Oktober',
    },
    {
      key: 11,
      value: 'November',
    },
    {
      key: 12,
      value: 'Desember',
    },
  ];

  return (
    <DropDownSelect
      disabled={props.disabled}
      items={monthNames}
      value={monthListValue.toString()}
      fieldGroupStyle={{
        ...props.style,
        width: '6em',
        height: '30px',
      }}
      showEmptyOption={false}
      accessor={'value'}
      onChangeSelected={e => handleChangeMonthValue(e.toString())}
    />
  );
}
