import * as React from 'react';
import { IconType } from 'react-icons/lib';
import { IconBaseProps } from 'react-icons/src/iconBase';

export const MuClearFilter: IconType = (props: IconBaseProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 38 38"
    width={props.size || '1.5em'}
    height={props.size || '1.5em'}
    style={props.style}
  >
    <path
      d="M34.8 5.6c.3.6.2 1.1-.3 1.5l-11 11.1v16.5c0 .7-.3 1.1-.9 1.3-.2.1-.4.1-.5.1-.4 0-.7-.1-1-.4L15.4 30c-.3-.3-.5-.6-.5-1V18.2l-11-11c-.5-.5-.6-1-.3-1.6.3-.6.7-.9 1.3-.9h28.6c.6 0 1 .3 1.3.9z"
      fill={props.fill}
    />
    <path
      fill="#fff"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M8 2l21.5 21.5"
    />
    <path
      fill="#fff"
      stroke={props.fill}
      strokeMiterlimit="10"
      strokeWidth="3"
      d="M6 2l28.5 28.5"
    />
  </svg>
);
