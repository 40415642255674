import gql from 'graphql-tag';
import Mutation from 'react-apollo/Mutation';
import {
  CreateTodoMutation,
  CreateTodoTemplateMutation,
  CreateTodoTemplateVariables,
  CreateTodoVariables,
  GetInstanceAndTaskQuery,
  GetInstanceAndTaskVariables,
  GetReverseInstanceAndTaskQuery,
  GetReverseInstanceAndTaskVariables,
  GetTaskQuery,
  GetTaskVariables,
  UpdateTodoMutation,
  UpdateTodoTemplateMutation,
  UpdateTodoTemplateVariables,
  UpdateTodoVariables,
} from '../../../models/types';
import Query from 'react-apollo/Query';
import {
  fieldsOnCalendarLookup,
  fieldsOnMiniPerson,
} from '../../../containers/AnnualCycle/calendar-queries';

export const UPDATE_EVENT_INSTANCE = gql`
  mutation UpdateEventInstance($input: UpdateEventInstanceInput!) {
    updateEventInstance(input: $input) {
      id
      title
      description
      startDate
      endDate
      note
      status
      isDone
      isFavourite
      reminderDate
      responsible {
        ...fieldsOnMiniPerson
      }
    }
  }
  ${fieldsOnMiniPerson}
`;

export const CREATE_TODO = gql`
  mutation CreateTodo($input: CreateTodoInstanceInput!) {
    createTodoInstance(input: $input) {
      id
      title
      description
      isDone
      status
      note
    }
  }
`;

export const UPDATE_TODO = gql`
  mutation UpdateTodo($input: UpdateTodoInstanceInput!) {
    updateTodoInstance(input: $input) {
      id
      title
      description
      isDone
      status
      note
    }
  }
`;
export const UPDATE_TODO_TEMPLATE = gql`
  mutation UpdateTodoTemplate($input: UpdateTodoTemplateInput!) {
    updateTodoTemplate(input: $input) {
      id
      title
      description
      status
    }
  }
`;
export const CREATE_TODO_TEMPLATE = gql`
  mutation CreateTodoTemplate($input: CreateTodoTemplateInput!) {
    createTodoTemplate(input: $input) {
      id
      title
      description
      status
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      title
      description
      status
      endRepetitionDate
      repetitionDefinition
      responsible {
        ...fieldsOnMiniPerson
      }
      startDate
      startRepetitionDate
    }
  }
  ${fieldsOnMiniPerson}
`;

export const GET_TASK_QUERY = gql`
  query GetTask($taskId: ID!) {
    task(id: $taskId) {
      id
      title
      description
      type
      status
      calendars {
        ...fieldsOnCalendarLookup
      }
    }
    me {
      id
      name
    }
  }
  ${fieldsOnCalendarLookup}
`;

const fieldsOnEventExtended = gql`
  fragment fieldsOnEventExtended on Event {
    id
    title
    description
    startDate
    reminderDate
    files {
      id
      name
      description
      lastUpdated
      size
      contentType
      access {
        myAccess
      }
    }

    assignedBy {
      department
      ...fieldsOnMiniPerson
    }
    endDate
    responsible {
      ...fieldsOnMiniPerson
    }

    repetitionDefinition
    endRepetitionDate
    startRepetitionDate
    status
    todoTemplate {
      id
      title
      description
      status
    }
    instances {
      id
      title
      files {
        id
        name
        description
        lastUpdated
        size
        contentType
        access {
          myAccess
        }
      }

      description
      isDone
      assignedBy {
        department
        ...fieldsOnMiniPerson
      }
      isFavourite
      status
      note
      startDate
      endDate
      responsible {
        ...fieldsOnMiniPerson
      }
      reminderDate
      createdBy {
        ...fieldsOnMiniPerson
      }
      todos {
        id
        title
        description
        isDone
        status
        parent
        fromTemplate
        note
      }
    }
  }
  ${fieldsOnMiniPerson}
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      id
      title
      description
      type
      status
      calendars {
        ...fieldsOnCalendarLookup
      }
    }
  }
  ${fieldsOnCalendarLookup}
`;

export const GET_INSTANCE_AND_TASK_QUERY = gql`
  query GetInstanceAndTask($taskId: ID!, $year: Int) {
    task(id: $taskId) {
      id
      title
      type
      status
      calendars {
        id
        title
        description
        uri
        color
        createdBy {
          id
          name
        }
        access {
          persons {
            access
            person {
              id
              name
            }
          }
        }
      }
      events(year: $year) {
        ...fieldsOnEventExtended
      }
    }
  }
  ${fieldsOnEventExtended}
`;

export const GET_REVERSE_INSTANCE_AND_TASK_QUERY = gql`
  query GetReverseInstanceAndTask($instanceId: ID!) {
    eventInstance(id: $instanceId) {
      id
      title
      files {
        id
        name
        description
        lastUpdated
        size
        contentType
        access {
          myAccess
        }
      }
      description
      isDone
      assignedBy {
        department
        ...fieldsOnMiniPerson
      }
      isFavourite
      status
      note
      startDate
      endDate
      responsible {
        ...fieldsOnMiniPerson
      }
      reminderDate
      createdBy {
        ...fieldsOnMiniPerson
      }
      todos {
        id
        title
        description
        isDone
        status
        fromTemplate
        note
      }
      event {
        id
        title
        description
        startDate
        files {
          id
          name
          description
          lastUpdated
          size
          contentType
          access {
            myAccess
          }
        }

        assignedBy {
          department
          ...fieldsOnMiniPerson
        }
        endDate
        responsible {
          ...fieldsOnMiniPerson
        }

        repetitionDefinition
        endRepetitionDate
        startRepetitionDate
        status
        todoTemplate {
          id
          title
          description
          status
        }
        task {
          id
          title
          type
          status
          calendars {
            id
            title
            description
            uri
            color
            createdBy {
              id
              name
            }
            access {
              persons {
                access
                person {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  ${fieldsOnMiniPerson}
`;

export const GET_TASK_FOR_INVALID = gql`
  query GetTaskForInvalid($taskId: ID!) {
    task(id: $taskId) {
      id
      title
      type
      calendars {
        ...fieldsOnCalendarLookup
      }
      events {
        ...fieldsOnEvent
      }
    }
  }
`;

export class GetInstanceAndTaskQueryComp extends Query<
  GetInstanceAndTaskQuery,
  GetInstanceAndTaskVariables
> {}
export class GetReverseInstanceAndTaskQueryComp extends Query<
  GetReverseInstanceAndTaskQuery,
  GetReverseInstanceAndTaskVariables
> {}

export class CreateTodoMutationComp extends Mutation<
  CreateTodoMutation,
  CreateTodoVariables
> {}
export class UpdateTodoMutationComp extends Mutation<
  UpdateTodoMutation,
  UpdateTodoVariables
> {}
export class UpdateTodoTemplateMutationComp extends Mutation<
  UpdateTodoTemplateMutation,
  UpdateTodoTemplateVariables
> {}
export class CreateTodoTemplateMutationComp extends Mutation<
  CreateTodoTemplateMutation,
  CreateTodoTemplateVariables
> {}

export class GetTaskQueryComp extends Query<GetTaskQuery, GetTaskVariables> {}
