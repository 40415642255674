import moment from 'moment';

export const WheelConstants = {
  cx: 500, // origin x
  cy: 500, // origin y
  midRadius: 214.41, // radius of middle circle
  maxRadius: 421.4, // radius of outer (colored) circle
  totalRingDepth: 421.4 - 214.41,
  degreesGutterHalf: 2, // gutter on each side, 4 degrees total space between pies
  degreesPerMonth: 26, // 360 / 12 - degreesGutter * 2, // 26 deg
  monthNames: moment.months(),
  monthNamesShort: moment.monthsShort(),
  outerRingId: 'month',
};

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

/***
 * get data needed to create arc
 * @param {number} cx
 * @param {number} cy
 * @param {number} radius
 * @param {number} startAngle
 * @param {number} endAngle
 * @returns {{start: {x: number; y: number}; end: {x: number; y: number}; radius: number; largeArcFlag: string}}
 */
const makeArc = (
  cx: number,
  cy: number,
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  const start = polarToCartesian(cx, cy, radius, endAngle);
  const end = polarToCartesian(cx, cy, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const points = {
    start: start,
    end: end,
    radius: radius,
    largeArcFlag: largeArcFlag,
  };

  return points;
};

/**
 * function to draw arc from startAngel to endAngle
 * @param {number} centerX
 * @param {number} centerY
 * @param {number} radius - distane from center to circle
 * @param {number} startAngle - 0 - 360
 * @param {number} endAngle
 * @returns {string}
 */
const describeArc = (
  centerX: number,
  centerY: number,
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  // const start = polarToCartesian(centerX, centerY, radius, endAngle);
  // const end = polarToCartesian(centerX, centerY, radius, startAngle);
  //
  // const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const arcData = makeArc(centerX, centerY, radius, startAngle, endAngle);

  const d = [
    'M',
    arcData.start.x,
    arcData.start.y,
    'A',
    radius,
    radius,
    0,
    arcData.largeArcFlag,
    0,
    arcData.end.x,
    arcData.end.y,
  ].join(' ');

  return d;
};

// https://www.mathsisfun.com/polar-cartesian-coordinates.html

const makePie = (
  cx: number,
  cy: number,
  innerRadius: number,
  depth: number,
  startAngle: number,
  endAngle: number
) => {
  const innerArc = makeArc(cx, cy, innerRadius, startAngle, endAngle);
  const outerArc = makeArc(cx, cy, innerRadius + depth, endAngle, startAngle);

  return {
    innerArc: innerArc,
    outerArc: outerArc,
  };
};

/**
 * draw a pie
 * @param {number} cx
 * @param {number} cy
 * @param {number} innerRadius
 * @param {number} depth
 * @param {number} startAngle
 * @param {number} endAngle
 * @returns {string}
 */
const describePie = (
  cx: number,
  cy: number,
  innerRadius: number,
  depth: number,
  startAngle: number,
  endAngle: number
) => {
  const innerArc = makeArc(cx, cy, innerRadius, startAngle, endAngle);
  const outerArc = makeArc(cx, cy, innerRadius + depth, endAngle, startAngle);

  const d = [
    'M',
    innerArc.start.x,
    innerArc.start.y,
    'A',
    innerArc.radius,
    innerArc.radius,
    0,
    innerArc.largeArcFlag,
    0,
    innerArc.end.x,
    innerArc.end.y,
    'L',
    outerArc.start.x,
    outerArc.start.y,
    'A',
    outerArc.radius,
    outerArc.radius,
    0,
    outerArc.largeArcFlag,
    1,
    outerArc.end.x,
    outerArc.end.y,
    'Z',
  ].join(' ');

  return d;
};

export const WheelUtils = {
  polarToCartesian,
  makeArc,
  describeArc,
  makePie,
  describePie,
};
