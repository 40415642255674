import { actionCreatorFactory, isType } from 'typescript-fsa';
import { Action } from 'redux';
import { RootState } from '../rootReducer';
import { take } from 'redux-saga/effects';
import moment from 'moment';

const actionCreator = actionCreatorFactory('UI/SETTINGS');

export type SupportedUserLocale = 'en' | 'nn' | 'nb';

export const SupportedUserLocaleNames: {
  [K in SupportedUserLocale]: string
} = {
  en: 'English',
  nn: 'Nynorsk',
  nb: 'Bokmål',
};

export const BackendLocaleMap: { [K in SupportedUserLocale]: string } = {
  en: 'gb_EN', // TODO: check if OK on backend?
  nn: 'nn_NO',
  nb: 'nb_NO',
};

export const FrontendLocaleMap: {
  [backendKey: string]: string;
} = {
  gb_EN: 'en',
  nn_NO: 'nn',
  nb_NO: 'nb',
};

export const setLocaleActionCreator = actionCreator<{
  locale: SupportedUserLocale;
}>('SET_LOCALE');

/*
  Settings are stored locally in client (persisted).
 */
export interface ISettingsState {
  locale: SupportedUserLocale;
}

export const reducer = (
  state: ISettingsState = { locale: 'nn' },
  action: Action
): ISettingsState => {
  if (isType(action, setLocaleActionCreator)) {
    return {
      ...state,
      locale: action.payload.locale,
    };
  }

  return state;
};

export const localeSelector = (state: RootState): SupportedUserLocale => {
  return state.settings.locale;
};

export function* setLanguageSaga() {
  while (true) {
    const action = yield take(setLocaleActionCreator.type);

    console.log(action.payload.locale);

    // TODO: enable this saga,
    // TODO: add mutate person set language!

    // or just add that to APP.TSX?

    if (action.payload.locale === 'en') {
      moment.locale('en-gb');
    } else {
      moment.locale(action.payload.locale);
    }
  }
}
