import * as React from 'react';
import { AuthContext, AuthInfo } from './AuthContext';
import { PropsWithChildren, useContext } from 'react';
import { invariant } from 'ts-invariant';
import { isFunction } from '../components/common';

type CustomAuthFunc = (auth: AuthInfo) => boolean;

interface AuthQueryProps {
  authFunc?: CustomAuthFunc;
}

/***
 * Use this to show content only if user is authenticated, has certain permissions, etc.
 * @param props
 * @constructor
 */
export const AuthQuery: React.FunctionComponent<
  PropsWithChildren<AuthQueryProps>
> = (props: PropsWithChildren<AuthQueryProps>) => {
  const value = useContext(AuthContext);
  invariant(value, 'You should not use AuthQuery outside a AuthContext..');

  let isAuthorized = value.isAuthenticated;

  if (isFunction(props.authFunc)) {
    isAuthorized = props.authFunc(value);
  }

  return <> {isAuthorized && props.children} </>;
};

/**
 * usage example..
 * @param props
 * @constructor
 */
const Test: React.FunctionComponent<{}> = props => (
  <div>
    <h3>hello</h3>
    <AuthQuery>only if authenticated</AuthQuery>

    <h3>test2:</h3>
    <AuthQuery authFunc={auth => auth.isAdmin}>Hello ADMIN</AuthQuery>
    <AuthQuery authFunc={auth => !auth.isAdmin}>You are NOT admin</AuthQuery>
  </div>
);
