import * as React from 'react';
import styled from 'styled-components';
import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import { errorMessages } from '../../components/language/errorMessages';
import { clearApolloCache } from '../../graphql/apolloClientFactory';
import { StatusMessage } from '../../components/basic/StatusMessage/StatusMessage';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 3.125em);
`;

class ErrorBoundaryComp extends React.Component<
  InjectedIntlProps,
  { hasError: boolean; error?: Error }
> {
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error: error,
    };
  }

  constructor(props: InjectedIntlProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: any) {
    console.error(error, info);

    // TODO: we can log to a service or something here..
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <StatusMessage
            title={this.props.intl.formatMessage(
              errorMessages.unhandledErrorTitle
            )}
            text={
              <FormattedMessage
                id={'error.unhandledErrorMessage'}
                defaultMessage={
                  'Oops! An unhandled error occurred. {br} Please contact support if you need help. You can try to reload the page by pressing the button below, or click more info to see more technical details'
                }
                values={{
                  br: <br />,
                }}
              />
            }
            buttonText={this.props.intl.formatMessage(errorMessages.reload)}
            buttonText2={this.props.intl.formatMessage(errorMessages.moreinfo)}
            onClickButton={async () => {
              await clearApolloCache();
              window.location.reload();
            }}
            onClickButton2={() => {
              console.log(this.state.error);
              alert(this.state.error);
            }}
          />
        </Container>
      );
    }

    return this.props.children;
  }
}

const ErrorBoundary = injectIntl(ErrorBoundaryComp);
export default ErrorBoundary;
