import styled, { keyframes } from 'styled-components';

const degrees12 = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330];
const degrees8 = [0, 45, 90, 135, 180, 225, 270, 315];

export const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(360deg);
  }
`;

export const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
  }
`;

export const punch = keyframes`
  0% {
    transform: scale(1);
    transform-origin: center;
  }
  30% {
    transform: scale(1.2);
    transform-origin: center;
  }
  65% {
    transform: scale(.93);
    transform-origin: center;
  }
  100% {
    transform: scale(1);  
    transform-origin: center;
  }
`;
