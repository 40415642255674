export default `
### Munikum® - Brukervilkår

Munikum AS, ved daglig leder, er ansvarlig for behandlingen av personopplysninger som blir registrert om deg.
Brukervilkårene inneholder opplysninger du har krav på når det samles inn opplysninger fra nettstedet vårt (personopplysningsloven § 19),
generell informasjon om hvordan vi behandler personopplysninger (personopplysningsloven § 18 1. ledd) samt ansvarsbegresninger knyttet til innholdet i Munikum®.

#### Personvern

**Om personopplysninger**                                                                                       
Personopplysninger er alle opplysninger og vurderinger som er knyttet til deg som enkeltperson.
Personopplysningene vi registrerer ved bruk av tjenesten Munikum® er opplysninger som du selv registrerer på munikum.no,
som navn, kommunenavn og funksjon i kommunen, opplysninger om din aktivitet på nettsiden, hvilken nettleser du benytter
og adresseområde for datanettverket du jobber ifra (IP-adresse).  

**Behandling av personopplysninger på munikum.no**                                                                             
Daglig leder har ansvaret for Munikum AS sin behandling av personopplysninger på munikum.no.
Behandlingsgrunnlaget er samtykke fra den enkelte. 

Vi benytter Amazon Web Services og analyseverktøyet Matomo på vårt nettsted, og har databehandleravtaler med disse.
Databehandleravtalene sikrer at disse verktøyene kun behandler personopplysninger som avtalt med behandlingsansvarlig, Munikum AS.
Informasjon fra disse verktøyene blir ikke utlevert frå Munikum AS til andre aktørar. 
Ta kontakt med oss dersom du ønsker mer informasjon om eller innsyn i databehandleravtalene.

**Nettstatistikk**                                                                                                    
Munikum AS samler inn opplysninger om besøkende på munikum.no.
Formålet med dette er å utarbeide statistikk som vi bruker til å forbedre og videreutvikle tjenesten.
Eksempler på hva statistikken gir svar på er hvor mange som besøker ulike sider, hvor lenge besøket varer, hvilke nettsteder brukerne kommer fra
og hvilke nettlesere som benyttes. 

**Bruk av informasjonskapsler**                                                      
Informasjonskapsler (cookies) er små tekstfiler som plasseres på din datamaskin når du laster ned en nettside. 
Lagring av opplysninger og behandling av disse er ikke tillatt med mindre bruker både har blitt informert om og har gitt sitt samtykke til behandlingen.
Analyseverktøyet Matomo plasserer informasjonskapsler på din datamaskin. Dette gjøres for at vi skal kunne samle inn statistikk,
og slettes etter 90 dager dersom du ikke har vært aktiv på siden. 

**Brukerprofil i Munikum®**                                                                 
Din brukerprofil med navn, kommunenavn og hvilken funksjon du har i kommunen vil være synlig for andre brukere av nettsiden. 
Du kan når som helst endre eller slette alle dine registrerte personopplysninger på munikum.no.

**Rettigheter**                                                                                    
Alle som spør har rett på grunnleggende informasjon om behandling av personopplysninger i en virksomhet etter personopplysningslovens § 18, 1.ledd.
Munikum AS har gitt denne informasjonen i desse brukervilkårene, og vil henvise til denne ved eventuelle forespørsler.
De som er registrert på munikum.no har rett på innsyn i egne opplysninger. 
Vedkommende har også rett til å be om at uriktige eller mangelfulle opplysninger vil bli rettet, slettet eller supplert. 

Du kan når som helst trekke ditt samtykke helt eller delvis til behandling av dine personopplysninger.
Registrerte personopplysninger om deg hos Munikum AS vil da bli slettet. 
Dine personopplysninger vil fjernes fra alle innlegg, dokumenter og øvrige filer som du selv eller i samarbeid med andre har produsert i Munikum®.

Hvis du går over i en ny stilling eller av annen årsak ikke lenger skal fortsette i din funksjon i kommunen, vil data (innlegg, dokument og annet)
som du har produsert og delt i Munikum® videreføres til personen som overtar din funksjon. 
På den måten vil kunnskap og arbeid som er delt og innhentet gjennom Munikum® for et funksjons- og tjenesteområde være tilgjengelig for kommunen
selv om brukere endrer eller avslutter sine stillingsforhold.

### Munikum® - Ansvarsbegrensning

Samtlige brukere av Munikum® forutsettes å ha lest og akseptert følgende informasjon:

Det er brukerne av Munikum® som er ansvarlige for innholdet i innlegg og annet som blir produsert og delt av brukerne.
Det er også brukerne som er ansvarlige for at det som deles overholder relevante lover, forskrifter m.m. 
Munikum AS er således ikke ansvarlig for innholdet i Munikum® som blir produsert og delt av brukerne.

Enkelte lenker på nettsiden går til nettsteder administrert av bedrifter eller organisasjoner som ikke kan kontrolleres av Munikum AS.
Munikum AS er ikke ansvarlig for slike lenker, eller eksterne nettsteders innhold eller funksjonalitet.

All bruk av informasjonen i Munikum®, for eksempel som beslutningsgrunnlag for investeringer eller annet, skjer på egen risiko.
Munikum AS fraskriver seg ethvert ansvar for resultatet av at en bruker/kommune på ulike måter benytter informasjon delt i Munikum®. 
Således kan Munikum AS ikke holdes ansvarlig dersom informasjonen delt i Munikum® fører til direkte eller indirekte skade eller tap,
uteblitt gevinst eller inntekt for brukeren/kommunen eller andre som har tatt del i innholdet på Munikum®.

Munikum AS forbeholder seg retten til å overvåke og eventuelt fjerne innhold på Munikum® etter eget skjønn.
Munikum AS har også rett til å avbryte eller på annen måte begrense bruken av Munikum®.
Brukeren skal dog være oppmerksom på at Munikum AS ikke gransker innlegg og annet i Munikum® før det publiseres, eller for øvrig er ansvarlig for innholdet.

#### Kontaktinformasjon

Kontakt oss gjerne om du har spørsmål.

E-post: support@munikum.no

Telefon: 919 04 691

Postadresse: Bankgata 1, 6770 Nordfjordeid
`;