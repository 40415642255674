import { createSelector } from 'reselect';
import moment from 'moment';
import * as _ from 'lodash';
import { IEventInstanceViewModel } from '../../components/annualCycle/AnnualCycleRightMenu';
import {
  FieldsOnCalendarRingFragment,
  YearWheelRingCalendar,
  YearWheelYearWheel,
} from '../../models/types';
import { SelectedMonthsDict } from '../../components/annualCycle/Wheel/Wheel';

export interface IAnnualCycleFilter {
  selectedYear?: number;

  selectedYearFrom?: Date;

  selectedYearTo?: Date;

  selectedMonths: SelectedMonthsDict;
}

export interface ILocalRootState {
  filter: IAnnualCycleFilter;
  rings?: Array<FieldsOnCalendarRingFragment>;
  magicRing?: FieldsOnCalendarRingFragment;
  calendars?: { [calendarId: string]: YearWheelRingCalendar };
  startMonth: string;
}

export const getIsNothingSelected = (filter: IAnnualCycleFilter) => {
  let isNothingSelected = true;
  for (let i = 0; i < 12; i++) {
    const mm = filter.selectedMonths[i];
    if (mm.isEntireMonthSelected) {
      isNothingSelected = false;
      break;
    } else if (mm.selectedRings && mm.selectedRings.length > 0) {
      isNothingSelected = false;
      break;
    }
  }

  return isNothingSelected;
};

export const getIsEverythingSelected = (filter: IAnnualCycleFilter) => {
  let isEverythingSelected = true;

  for (let i = 0; i < 12; i++) {
    const mm = filter.selectedMonths[i];
    if (!mm.isEntireMonthSelected) {
      isEverythingSelected = false;
      break;
    }
  }

  return isEverythingSelected;
};

type DataCalendars = { [calendarId: string]: YearWheelRingCalendar };

const getRingsDataAsync = (): DataCalendars => {
  // dataCalendars?: { [calendarId: string]: YearWheelRingCalendar };
  // idea:
  // - we ask for a list of calendar ID's.
  // - we check apollo cache, return data that is ready,
  // - we need state for loadingData:

  // - can we START async loading here? ok? => we need to store this state locally, lets store it in apollo local state =)

  return {};
};

/**
 * selector factory function, need this if we want to use selector in multiple pages / components!
 * (reselect cache size = 1!)
 * @param state state to read from
 */

export const filteredInstancesSelectorFactory = () => {
  return createSelector<
    ILocalRootState, // 'root' state
    IAnnualCycleFilter, // S1
    Array<YearWheelYearWheel>, // S2
    { [calendarId: string]: YearWheelRingCalendar } | undefined, // S3
    FieldsOnCalendarRingFragment | undefined, // S4
    string, // S5, startMonth
    IEventInstanceViewModel[] // R
  >(
    state => state.filter,
    state => state.rings || [],
    state => state.calendars || undefined,
    state => state.magicRing,
    state => state.startMonth,
    (res1, res2, res3, res4, res5) => {
      if (res1 === undefined || res2 === undefined || res3 === undefined) {
        // console.log('not ready yet');
        return [];
      }
      // NOTE: this transform function will only run when FILTer or DATA changes!
      // console.log(
      //   '<=========== FILTER INSTANCES, reselect memoize transform func!',
      //   res1,
      //   res2,
      //   res3,
      //   res4,
      //   res5
      // );

      const temp: Array<IEventInstanceViewModel> = [];

      const isNothingSelected = getIsNothingSelected(res1);

      let startC = moment();
      let endC = moment();

      if (res1.selectedYear !== undefined) {
        startC = moment()
          .year(res1.selectedYear)
          .startOf('year');

        endC = moment()
          .year(res1.selectedYear)
          .endOf('year');
      } else if (
        res1.selectedYearFrom !== undefined &&
        res1.selectedYearTo !== undefined
      ) {
        startC = moment(res1.selectedYearFrom);
        endC = moment(res1.selectedYearTo);
      } else {
        console.warn('you need to filter on year or dates');
        return [];
      }

      let resAndMagic = res2;

      if (res4) {
        // ADD MAGIC DATA!
        resAndMagic = res2.slice();
        resAndMagic.push(res4);
      }

      resAndMagic.forEach(ring => {
        const calendarData =
          res3 !== undefined && res3.hasOwnProperty(ring.calendar.id)
            ? res3[ring.calendar.id]
            : undefined;
        if (calendarData) {
          if (!calendarData.tasks) {
            console.warn('no tasks? what did I do..');
          }
          calendarData.tasks.forEach(task =>
            task.events.forEach(event =>
              event.instances.forEach(instance => {
                const vm: any = Object.assign({}, instance, {
                  color: ring.color,
                  taskTitle: task.title,
                  taskId: task.id,
                  taskCalendarId: calendarData.id,
                  ringid: ring.id,
                  access: calendarData.access,
                });

                let isInRange = false;

                // https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
                const startA = moment(vm.startDate);
                const endA = moment(vm.endDate || undefined);

                // console.log('filter month test');

                if (isNothingSelected) {
                  if (
                    endC.isSameOrAfter(startA) &&
                    endA.isSameOrAfter(startC)
                  ) {
                    isInRange = true;
                  }
                } else {
                  const startMonth =
                    res5 !== undefined ? parseInt(res5, 10) : 0;
                  let checkYear = -1;
                  const isTwoMode = startMonth > 0;
                  const isRangeSupplied =
                    res1.selectedYearFrom !== undefined &&
                    res1.selectedYearTo !== undefined;
                  const isYearSupplied = res1.selectedYear !== undefined;

                  if (isRangeSupplied) {
                    checkYear = res1.selectedYearFrom.getFullYear();
                  } else if (isYearSupplied) {
                    checkYear = res1.selectedYear;
                  } else {
                    console.warn('something bad.. "#$');
                  }

                  for (
                    let count = 0, m = startMonth;
                    count < 12;
                    count++, m++
                  ) {
                    const monthIndex = m % 12;
                    const currentMonth = res1.selectedMonths[monthIndex];

                    if (m === 12) {
                      checkYear++;
                    }

                    const startB = moment()
                      .year(checkYear)
                      .month(monthIndex)
                      .startOf('month');

                    const endB = moment()
                      .year(checkYear)
                      .month(monthIndex)
                      .endOf('month');

                    if (
                      currentMonth !== undefined &&
                      currentMonth.isEntireMonthSelected
                    ) {
                      if (
                        endB.isSameOrAfter(startA) &&
                        endA.isSameOrAfter(startB)
                      ) {
                        isInRange = true;
                        break;
                      }
                    } else if (
                      currentMonth !== undefined &&
                      currentMonth.selectedRings &&
                      currentMonth.selectedRings.length > 0
                    ) {
                      if (
                        endB.isSameOrAfter(startA) &&
                        endA.isSameOrAfter(startB)
                      ) {
                        if (currentMonth.selectedRings.indexOf(ring.id) > -1) {
                          isInRange = true;
                        }
                      }
                    }
                    // console.log('dates: ' + startB.format('YYYY-MM-DD hh:mm') + endB.format('YYYY-MM-DD hh:mm'));
                  }
                }

                if (isInRange) {
                  temp.push(vm);
                }
              })
            )
          );
        }
      });

      const sortedInstancesByDate = _.sortBy(temp, 'endDate').reverse();
      return _.sortBy(sortedInstancesByDate, 'isFavourite').reverse();
    }
  );
};
