/**
 *      ** Thomas var her ;-) **
 *
 *      https://gist.github.com/IAmJulianAcosta/fb1813926c2fa3adefc0
 */
function printLogo() {
  try {
    const params = [];
    let message = '';

    const request = new XMLHttpRequest();
    request.overrideMimeType('application/json');

    request.open(
      'GET',
      process.env.NODE_ENV === 'production' ? '/app/logo.json' : '/logo.json',
      true
    );

    request.onreadystatechange = function onJSONFileRead() {
      if (request.readyState === 4 && request.status === 200) {
        const json = JSON.parse(request.responseText);
        for (let i = 0; i < json.length; i++) {
          message += '%c' + json[i].text;
          params.push(json[i].style);
        }

        params.unshift(message);
        console.log.apply(console, params);

        console.log(
          '%cMade with %clove%c by the %cMunikum %cteam 🍊🍊🍊 %c(krisberg, thesab, thomastvedt, scouns, JanneOien, gtomasgard, anderslo, +++)',
          'background-color: #FFFFFF; color: #333',
          'background-color: #FFFFFF; color: #FF5C39',
          'background-color: #FFFFFF; color: #333',
          'background-color: #FFFFFF; color: #00778B',
          'background-color: #FFFFFF; color: #333',
          'background-color: #FFFFFF; color: #D0D3D4'
        );
      }
    };
    request.send();
  } catch (err) {
    console.warn('logo printing failed :-( ', err);
  }
}

export default printLogo;
