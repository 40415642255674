import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Colors,
  IDisabledProps,
  ILabelProps,
  IMessageProps,
  removeNonHTMLProps,
  safeInvoke,
} from '../../common';
import { ValidationSummary } from '..';
import { FieldGroup } from '../FieldGroup/FieldGroup';

// testing top wrapper to get adjacent selector css styling in styled components. . .
const WrapHack = styled.div`
  > input:checked ~ span {
    background-color: rebeccapurple;
  }
`;

const CheckboxInput = styled.input`
  // hide actual checkbox:
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
`;

const CheckboxLabel = styled.label`
  display: block;
  position: relative;

  //background-color: beige;
  font-family: 'Lato', sans-serif;
  color: ${Colors.BLACK};
  font-size: 0.875em;
  line-height: 1.063em;
  cursor: pointer;

  padding-left: 30px;
  ${(props: { disabled: boolean }) =>
    props.disabled ? 'opacity: 0.5;' : 'opacity: 1.0;'};
`;

const CheckHack = styled.span`
  background-color: ${(props: ICheckboxProps) =>
    props.checked ? props.color : props.unCheckedColor};

  //todo: remove, fix CSS pseudo selectors
  //display: none;

  line-height: 1;
  font-family: Lato, sans-serif;
  font-size: 1em;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  width: 18px;
  user-select: none;

  border: 1px solid #ced0da;
  ${(props: ICheckboxProps) =>
    props.disabled ? 'opacity: 0.5;' : 'opacity: 1.0;'} &:before {
    color: #fff;

    position: relative;
    font-size: 1em;
    top: 0.21em;
    left: 0.3em;
  }
`;

const RedCircle = styled.div`
  background-color: ${Colors.RED};
  border-radius: 50%;
  height: 10px;
  width: 10px;
  position: absolute;
  left: 4.5px;
  z-index: 2;
  top: 4.5px;
  ${(props: ICheckboxProps) =>
    props.checked ? 'display: block;' : 'display: none'}
`;

export interface ICheckboxProps {
  name?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  defaultColor?: boolean;
  onChange?: () => void;
  style?: CSSProperties;
  fieldGroupStyle?: CSSProperties;
  color?: string;
  unCheckedColor?: string;
  disabled?: boolean;
}

export interface ICircleCheckboxState {
  id?: string;
  checked?: boolean;
}

/**
 * Checkbox
 */
export class CircleCheckBox extends React.Component<
  ICheckboxProps & ILabelProps & IDisabledProps & IMessageProps,
  ICircleCheckboxState
> {
  constructor(props: ICheckboxProps) {
    super(props);
    this.state = {
      checked: this.props.checked,
    };
  }
  UNSAFE_componentWillMount() {
    const id = _.uniqueId('checkBox-');
    this.setState({
      id: id,
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps: ICheckboxProps) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({
        checked: nextProps.checked,
      });
    }
  }

  handleCheck = e => {
    safeInvoke(this.props.onChange);
  };

  render() {
    const {
      label,
      checked,
      onChange,
      info,
      success,
      warning,
      error,
      style,
      fieldGroupStyle,
      disabled,
      ...rest
    } = this.props;

    const { id } = this.state;

    return (
      <FieldGroup style={fieldGroupStyle}>
        <WrapHack style={style}>
          <CheckboxLabel htmlFor={id} disabled={disabled ? disabled : false}>
            <CheckboxInput
              id={id}
              type="checkbox"
              {...removeNonHTMLProps(rest)}
              // onChange={onChange}
              disabled={disabled}
              // label={label}
            />
            <RedCircle checked={this.state.checked} />
            <CheckHack
              name={this.props.name}
              unCheckedColor={this.props.unCheckedColor || Colors.WHITE}
              color={this.props.color || Colors.WHITE}
              checked={this.state.checked || false}
              disabled={disabled ? disabled : false}
              onClick={this.handleCheck}
            />
            {label}
          </CheckboxLabel>
          <ValidationSummary
            info={info}
            success={success}
            warning={warning}
            error={error}
          />
        </WrapHack>
      </FieldGroup>
    );
  }
}
