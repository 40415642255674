import * as React from 'react';
import { CSSProperties } from 'react';
import gql from 'graphql-tag';
import Query, { QueryResult } from 'react-apollo/Query';
import * as _ from 'lodash';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import Helmet from 'react-helmet';
import { myHistory } from '../../index';
import { LinkWrapper } from '../../components/basic/Structural/links';
import { MunikumIcons } from '../../components/common/icons';
import Tour from 'reactour';

import {
  AllCalendarsInput,
  MeForDashboardQuery,
  MeForDashboardVariables,
  OrderEnum,
  SortEnum,
} from '../../models/types';
import { PageTitle } from '../../components/layout/Page/Page';
import { breakPoint } from './TopicDashboard';
import { INewsItem } from './DashboardPage';
import { commonMessages } from '../../components/language/commonMessages';
import { safeInvoke, safeInvokeDeprecated } from '../../components/common';
import { News } from '../../components/dashboard/News';
import { LastUpdated } from './lastUpdated';
import styled from 'styled-components';
import { DashboardBox2 } from '../../components/dashboard/DashboardBox2';
import { Button } from '../../components/basic';
import { Dispatch } from 'redux';

import { connect } from 'react-redux';
import {
  filterCalendarsActionCreator,
  ICalendarFilter,
  resetCalendarsFilterActionCreator,
} from '../AnnualCycle/calendar-service';
import { RootState } from '../../modules/rootReducer';
import { SuspenseLoader } from '../../components/basic/Loader/CircleSpinner';

const messages = defineMessages({
  whatDoYouWantToDo: {
    id: 'AnnualCycleDashboard.whatDoYouWantToDo',
    defaultMessage: 'What do you want To do?',
  },
  createYearWheel: {
    id: 'AnnualCycleDashboard.createYearWheel',
    defaultMessage: 'Create new yearwheel',
  },
  myOrganization: {
    id: 'AnnualCycleDashboard.myOrganization',
    defaultMessage: 'My organization',
  },
  createYearWheelText: {
    id: 'AnnualCycleDashboard.createYearWheelText',
    defaultMessage: 'Create new yearwheel',
  },
  explore: {
    id: 'AnnualCycleDashboard.explore',
    defaultMessage: 'Explore',
  },
  exploreText: {
    id: 'AnnualCycleDashboard.exploreText',
    defaultMessage: 'A tool for overview and planning.',
  },
  myYearWheel: {
    id: 'AnnualCycleDashboard.myYearWheel',
    defaultMessage: 'My Year wheel',
  },
  myYearWheelText: {
    id: 'AnnualCycleDashboard.myYearWheelText',
    defaultMessage: 'Go to my year wheel',
  },
  news: {
    id: 'AnnualCycleDashboard.news',
    defaultMessage: 'News',
  },
  recomended: {
    id: 'AnnualCycleDashboard.recomended',
    defaultMessage: 'Recomended',
  },
  yourFollowed: {
    id: 'AnnualCycleDashboard.yourFollowed',
    defaultMessage: 'Your followed',
  },
  AnnualCycleDashboardTourStep1: {
    id: 'AnnualCycleDashboard.AnnualCycleDashboardTourStep1',
    defaultMessage: 'HEre is your calendars',
  },
  AnnualCycleDashboardTourStep2: {
    id: 'AnnualCycleDashboard.AnnualCycleDashboardTourStep2',
    defaultMessage: 'Explore calendars',
  },
  AnnualCycleDashboardTourStep3: {
    id: 'AnnualCycleDashboard.AnnualCycleDashboardTourStep3',
    defaultMessage: 'Calendars in your municipality',
  },
  AnnualCycleDashboardTourStep4: {
    id: 'AnnualCycleDashboard.AnnualCycleDashboardTourStep4',
    defaultMessage: 'Here you can create a new yearwheel',
  },
  AnnualCycleDashboardTourStep5: {
    id: 'AnnualCycleDashboard.AnnualCycleDashboardTourStep5',
    defaultMessage: 'Her is recomended yearwheels',
  },
  AnnualCycleDashboardTourStep6: {
    id: 'AnnualCycleDashboard.AnnualCycleDashboardTourStep6',
    defaultMessage: 'Here is data for the last updated yearwheels',
  },
  AnnualCycleDashboardTourStep7: {
    id: 'AnnualCycleDashboard.AnnualCycleDashboardTourStep7',
    defaultMessage: 'Here is news from the munikum team',
  },
  AnnualCycleDashboardTourStep8: {
    id: 'AnnualCycleDashboard.AnnualCycleDashboardTourStep8',
    defaultMessage: 'Do you want to repeat the steps?',
  },
  myMunicipalityBody: {
    id: 'AnnualCycleDashboard.myMunicipalityBody',
    defaultMessage: 'My organization',
  },
});
export const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 70.5em;
  align-items: center;
  margin: 0 auto;
   @media (min-width: ${1279}px) {
      flex-direction: row;
  }
   @media (min-width: ${1450}px) {
      flex-direction: row;
+  }
   @media (max-width: ${1450}px) {
    

  }
  
  // @media (max-width: ${breakPoint}) {
  //   flex-direction: column;
  //   align-items: center;
  // }
`;

export const MY_FOLLOWED_CALENDARS = gql`
  query getMyFollowedCalendars {
    myFollowed {
      calendars {
        id
        title
        description
        color
        isFollowing
        lastUpdated
      }
    }
  }
`;
export const GET_CALENDAR_ISFOLLOWING = gql`
  query GetOneCalendar($id: ID!) {
    calendar(id: $id) {
      id
      isFollowing
      likes
      isLiked
      followers {
        id
      }
    }
  }
`;
export const GET_ALL_CALENDARS_FOR_ANNUAL_CYCLE = gql`
  query AllCalendarsForDashboard($input: AllCalendarsInput!) {
    allCalendars(input: $input) {
      nodes {
        id
        title
        uri
        lastUpdated
        isLiked
        status
        isFollowing
      }
    }
    myFollowed {
      calendars {
        id
        isFollowing
        title
        uri
        lastUpdated
        status
      }
    }
    me {
      id
      organization {
        id
        name
        organizationImage
      }
    }
  }
`;
export const MainBox = styled.div`
  flex: 1 1 100%;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background-color: #ffffff;
  box-sizing: border-box;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //margin: 8px;
  padding: 2em;
`;
export const ContainerDashboard = styled.div``;
export const LastUpdatedComp = styled.div`
  flex-wrap: wrap;
  @media (min-width: ${1279}px) {
    flex-wrap: nowrap;
  }
`;
export const LastUpdatedDiv = styled.div`
  width: 100%;
  margin: 8px 0;

  @media (min-width: ${1279}px) {
    margin: 0 8px;
  }
`;
export const IconDiv = styled.div`
  transition: ease-in-out 0.2s;
  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

const ContentBoxesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
export const GET_ME_FOR_DASHBOARD = gql`
  query MeForDashboard {
    me {
      id
      picture
    }
  }
`;

export const FOLLOW_CALENDAR_DASHBOARD = gql`
  mutation FollowCalendarDashboard($id: ID!) {
    follow(id: $id)
  }
`;
export const UNFOLLOW_CALENDAR_DASHBOARD = gql`
  mutation UnfollowCalendarDashboard($id: ID!) {
    unFollow(id: $id)
  }
`;
class MeQuery extends Query<MeForDashboardQuery, MeForDashboardVariables> {}

interface IAnnaulCycleDashboardProps {
  style?: CSSProperties;
  list?: Array<any>;
  onClick?: (uri: string) => void;
  onFollow?: () => void;
  filter: ICalendarFilter;
}

interface IAnnualCycleDispatchProps {
  onClickMyMunicipality?: (filter: ICalendarFilter) => void;
  resetFilter?: () => void;
}

interface IAnnualCycleState {
  items: Array<INewsItem>;
  isTourOpen: boolean;
}

export class AnnualCycleDashboardComp extends React.PureComponent<
  IAnnaulCycleDashboardProps & InjectedIntlProps & IAnnualCycleDispatchProps,
  IAnnualCycleState
> {
  constructor(
    props: IAnnaulCycleDashboardProps &
      InjectedIntlProps &
      IAnnualCycleDispatchProps
  ) {
    super(props);
    this.state = {
      items: [],
      isTourOpen: false,
    };
  }

  handleData = (items: Array<INewsItem>) => {
    this.setState({
      items: items,
    });
  };
  // UNSAFE_componentWillMount() {
  //   // console.log('DASH_ didmount');
  //   let fThis = this;
  //   fetch('https://www.munikum.no/category/nyheter+arshjul/feed/json', {
  //     method: 'GET',
  //   }).then(
  //     function(response: Response) {
  //       response.text().then(value => {
  //         let result: IWordpressResponse = JSON.parse(value);
  //
  //         // BUG: here we call setState on fThis even if component is unmounted.. we should refactor to avoid memory leaks
  //         fThis.setState({
  //           items: result.items,
  //         });
  //       });
  //     },
  //     function(error: Error) {
  //       console.log(error);
  //     }
  //   );
  // }

  handleClickFollow = (id: string, follow: boolean) => {
    safeInvokeDeprecated(this.props.onFollow, id, follow);
  };

  render() {
    const { intl } = this.props;

    const steps = [
      {
        position: 'right',
        selector: '[data-tut="reactour__step1"]',
        content: (
          <FormattedMessage
            id={'AnnualCycleDashboard.AnnualCycleDashboardTourStep1'}
            defaultMessage={
              'Here you will find an overview of the annual wheels you have created or have been given access to from others. Annual wheels that you choose to follow will also be included in the list of your annual wheels'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        position: 'right',

        selector: '[data-tut="reactour__step2"]',
        content: (
          <FormattedMessage
            id={'AnnualCycleDashboard.AnnualCycleDashboardTourStep2'}
            defaultMessage={
              'Her kan se årshjul som andre har opprettet og gjort tilgjengelig for deg. {br} Du kan filtrere etter tjenesteområde, og kopiere eller dele årshjul med andre. Du kan også følge årshjul for å få varslinger om oppdateringer.'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step3"]',
        content: (
          <FormattedMessage
            id={'AnnualCycleDashboard.AnnualCycleDashboardTourStep3'}
            defaultMessage={
              'Here you can see which annual wheels have been created and published in your municipality. Are there any  annual wheels for your municipality that you should follow?'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step4"]',
        content: (
          <FormattedMessage
            id={'AnnualCycleDashboard.AnnualCycleDashboardTourStep4'}
            defaultMessage={
              'Here you can create annual wheels, and add others that may or may not have read or write access to your annual wheel.'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      // {
      //   position: 'right',
      //
      //   selector: '[data-tut="reactour__step5"]',
      //   content: (
      //     <FormattedMessage
      //       id={'AnnualCycleDashboard.AnnualCycleDashboardTourStep5'}
      //       defaultMessage={
      //         'Anbefalte årshjul for deg er basert på de tjenesteområdene du har registrert i din profil (KOSTRA-funksjoner).'
      //       }
      //       values={{
      //         br: <br />,
      //       }}
      //     />
      //   ),
      // },
      {
        position: 'right',
        selector: '[data-tut="reactour__step5"]',
        content: (
          <FormattedMessage
            id={'AnnualCycleDashboard.AnnualCycleDashboardTourStep6'}
            defaultMessage={
              'Aktivitetsloggen gir en oversikt over siste oppdateringer i årshjul som du følger eller har skrivetilgang til.'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step7"]',
        content: (
          <FormattedMessage
            id={'AnnualCycleDashboard.AnnualCycleDashboardTourStep7'}
            defaultMessage={
              'We at the Munikum team will make you aware of news related to the annual wheel. There may be new features or user stories that show different ways of using annual wheels as tools.'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        selector: '[data-tut="reactour__goTo"]',
        content: ({ goTo }) => (
          <div>
            {intl.formatMessage(commonMessages.doYouWantToRepeatTheSteps)}
            <div />
            <Button
              style={{ marginTop: '1em' }}
              onClick={() => goTo(0)}
              text={intl.formatMessage(commonMessages.repeat)}
            />
          </div>
        ),
      },
    ];
    const inputVariables: AllCalendarsInput = {
      limit: 4,
      sort: SortEnum.LAST_UPDATED,
      order: OrderEnum.DESCENDING,
    };
    // const newsItems = _.sortBy(this.state.items, 'date_modified').reverse();
    // newsItems.splice(5);
    let getCurrentYear = new Date().getFullYear();
    return (
      <Query
        variables={{ input: inputVariables }}
        fetchPolicy={'network-only'}
        query={GET_ALL_CALENDARS_FOR_ANNUAL_CYCLE}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <SuspenseLoader />;
          }
          if (error) {
            return `Error! ${error.message}`;
          }
          const calendars =
            data && data.allCalendars && data.allCalendars.nodes;
          const followedCalendars =
            data && data.myFollowed && data.myFollowed.calendars;
          let followedCalendarsSorted = _.sortBy(
            followedCalendars,
            'lastUpdated'
          ).reverse();
          let myOrg =
            data && data.me && data.me.organization && data.me.organization;
          return (
            <>
              <Helmet>
                <title>{intl.formatMessage(commonMessages.calendars)}</title>
              </Helmet>
              <OuterContainer>
                <div>
                  <MainBox style={{ margin: '4px' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1em',
                      }}
                    >
                      {/*<IconDiv>*/}
                      {/*<MunikumIcons.Info*/}
                      {/*style={{*/}
                      {/*color: '#FF5C39',*/}
                      {/*width: '1.4em',*/}
                      {/*height: '1.4em',*/}
                      {/*marginRight: '.5em',*/}
                      {/*}}*/}
                      {/*onClick={() => {*/}
                      {/*this.setState({*/}
                      {/*isTourOpen: true,*/}
                      {/*});*/}
                      {/*}}*/}
                      {/*/>*/}
                      {/*</IconDiv>*/}

                      <PageTitle style={{}}>
                        {intl.formatMessage(commonMessages.yearWheel)}
                      </PageTitle>
                    </div>

                    <MeQuery
                      query={GET_ME_FOR_DASHBOARD}
                      children={(result: QueryResult<MeForDashboardQuery>) => {
                        if (result.loading) {
                          return <div>loading</div>;
                        }
                        if (result.error) {
                          return 'error';
                        }
                        const me = result.data && result.data.me.id;
                        return (
                          <ContentBoxesContainer>
                            <LinkWrapper
                              data-tut="reactour__step1"
                              to={'/person/' + me + '/annual-cycle/'}
                            >
                              <DashboardBox2
                                title={intl.formatMessage(messages.myYearWheel)}
                                body={intl.formatMessage(
                                  messages.myYearWheelText
                                )}
                                icon={MunikumIcons.YearWheel}
                                isActive={true}
                                style={{
                                  backgroundColor: '#00778B',
                                }}
                              />
                            </LinkWrapper>
                            <LinkWrapper
                              to={'/calendar/'}
                              data-tut="reactour__step2"
                            >
                              <DashboardBox2
                                onClick={() => {
                                  safeInvoke(this.props.resetFilter);
                                }}
                                title={intl.formatMessage(messages.explore)}
                                body={intl.formatMessage(messages.exploreText)}
                                icon={MunikumIcons.Search}
                                isActive={true}
                                style={{
                                  backgroundColor: '#00778B',
                                }}
                              />
                            </LinkWrapper>
                            <LinkWrapper
                              to={'/calendar/'}
                              data-tut="reactour__step3"
                            >
                              <DashboardBox2
                                onClick={() => {
                                  safeInvoke(this.props.onClickMyMunicipality, {
                                    ...this.props.filter,
                                    organizations: [myOrg],
                                    predefinedOrg: true,
                                  });
                                }}
                                title={intl.formatMessage(
                                  messages.myOrganization
                                )}
                                body={intl.formatMessage(
                                  messages.myMunicipalityBody
                                )}
                                icon={MunikumIcons.KostraEducation}
                                isActive={true}
                                style={{
                                  backgroundColor: '#00778B',
                                }}
                              />
                            </LinkWrapper>
                            <LinkWrapper
                              data-tut="reactour__step4"
                              style={{ alignSelf: 'flex-start' }}
                              to={
                                '/person/' +
                                me +
                                '/annual-cycle/create/' +
                                getCurrentYear
                              }
                            >
                              <DashboardBox2
                                title={intl.formatMessage(
                                  messages.createYearWheel
                                )}
                                body={intl.formatMessage(
                                  messages.createYearWheelText
                                )}
                                icon={MunikumIcons.Add}
                                isActive={true}
                                style={{
                                  backgroundColor: ' #00B0B9',
                                }}
                              />
                            </LinkWrapper>
                          </ContentBoxesContainer>
                        );
                      }}
                    />
                  </MainBox>
                  <LastUpdatedComp style={{ width: '100%', display: 'flex' }}>
                    <div
                      style={{ width: '100%', margin: '4px' }}
                      data-tut="reactour__step5"
                    >
                      <LastUpdated
                        title={'Aktivitetslogg'}
                        list={calendars}
                        icon={MunikumIcons.ArrowRight}
                        onClick={uri => myHistory.push('/calendar/' + uri)}
                        onSeeAllClick={() => myHistory.push('/calendar/')}
                      />
                    </div>
                    {/*<LastUpdatedDiv data-tut="reactour__step6">*/}
                    {/*<LastUpdated*/}
                    {/*title={'Aktivitetslogg'}*/}
                    {/*list={calendars}*/}
                    {/*icon={MunikumIcons.ArrowRight}*/}
                    {/*onClick={uri => myHistory.push('/calendar/' + uri)}*/}
                    {/*onSeeAllClick={() => myHistory.push('/calendar/')}*/}
                    {/*/>*/}
                    {/*</LastUpdatedDiv>*/}
                    {/*<HelpBox*/}
                    {/*style={{margin: '4px'}}*/}
                    {/*onClickHelp={() => {*/}
                    {/*this.setState({*/}
                    {/*isTourOpen: true,*/}
                    {/*});*/}
                    {/*}}*/}
                    {/*/>*/}
                  </LastUpdatedComp>
                  {/*<div*/}
                  {/*style={{ margin: '4px', maxWidth: '69em' }}*/}
                  {/*data-tut="reactour__step6"*/}
                  {/*>*/}
                  {/*<NewsSectionComp news={this.state.items} />*/}
                  {/*</div>*/}
                  {/*<InfoBoxes*/}
                  {/*title={intl.formatMessage(messages.recomended)}*/}
                  {/*list={sortedDiscussions}*/}
                  {/*style={{ flex: '1 1 50%' }}*/}
                  {/*icon={MunikumIcons.ArrowRight}*/}
                  {/*onClick={(uri) => myHistory.push('/forum/public/' + uri)}*/}
                  {/*onSeeAllClick={() => myHistory.push('/forum/public/')}*/}
                  {/*/>*/}
                </div>
                {/*<NewsBox>*/}
                {/*<MyFollowed*/}
                {/*followType={MyType.CALENDAR}*/}
                {/*currentType={*/}
                {/*intl.formatMessage(commonMessages.yearWheel) + '...'*/}
                {/*}*/}
                {/*tooltip={intl.formatMessage(*/}
                {/*commonMessages.unfollowHelpCalendar*/}
                {/*)}*/}
                {/*onClickText={uri => {*/}
                {/*myHistory.push('/calendar/' + uri);*/}
                {/*}}*/}
                {/*list={followedCalendarsSorted}*/}
                {/*onClickFollow={(id, isFollowing) => {*/}
                {/*if (isFollowing) {*/}
                {/*myApolloClient*/}
                {/*.mutate({*/}
                {/*mutation: UNFOLLOW_CALENDAR_DASHBOARD,*/}
                {/*variables: {*/}
                {/*id: id,*/}
                {/*},*/}
                {/*})*/}
                {/*.then(() => {*/}
                {/*myApolloClient*/}
                {/*.query({*/}
                {/*query: GET_ALL_CALENDARS_FOR_ANNUAL_CYCLE,*/}
                {/*variables: { input: inputVariables },*/}
                {/*fetchPolicy: 'network-only',*/}
                {/*})*/}
                {/*.then(() => console.log('refetch ok'));*/}
                {/*});*/}
                {/*} else {*/}
                {/*myApolloClient.mutate({*/}
                {/*mutation: FOLLOW_CALENDAR_DASHBOARD,*/}
                {/*variables: {*/}
                {/*id: id,*/}
                {/*},*/}
                {/*});*/}
                {/*}*/}
                {/*}}*/}
                {/*showIcon={true}*/}
                {/*title={intl.formatMessage(messages.yourFollowed)}*/}
                {/*/>*/}
                {/*</NewsBox>*/}
                <Tour
                  steps={steps}
                  isOpen={this.state.isTourOpen}
                  onRequestClose={() => {
                    this.setState({ isTourOpen: false });
                  }}
                  maskClassName="mask"
                  className="helper"
                  rounded={5}
                  accentColor={'#5cb7b7'}
                />
              </OuterContainer>
            </>
          );
        }}
      </Query>
    );
  }
}
const mapStateToProps = (
  state: RootState,
  ownProps: IAnnaulCycleDashboardProps
): IAnnaulCycleDashboardProps => ({
  filter: state.calendar.filter,
});
const mapDispatchToProps = (
  dispatch: Dispatch<{}>
): IAnnualCycleDispatchProps => ({
  onClickMyMunicipality: (filter: ICalendarFilter) => {
    dispatch(filterCalendarsActionCreator({ filter: filter }));
  },
  resetFilter: () => {
    dispatch(resetCalendarsFilterActionCreator({}));
  },
});

export const AnnualCycleDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AnnualCycleDashboardComp));

export default AnnualCycleDashboard;
