import * as React from 'react';
import { IconType } from 'react-icons/lib';
import { IconBaseProps } from 'react-icons/src/iconBase';

export const MuClap: IconType = (props: IconBaseProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    width={props.size || '1.5em'}
    height={props.size || '1.5em'}
    viewBox="0 0 600 600"
    style={props.style}
  >
    <g id="g12" transform="translate(-605.542 -403.896) scale(2.28753)">
      <path
        className="st0"
        d="M504 213.8c2.2-1.5 2.7-4.5 1.1-6.7-1.5-2.2-4.5-2.7-6.7-1.1l-12 8.5c-2.2 1.5-2.7 4.5-1.1 6.7.9 1.3 2.4 2 3.9 2 1 0 1.9-.3 2.8-.9zm0 0"
        id="path4"
        fill={props.fill}
      />
      <path
        className="st0"
        d="M472.2 211c.6.2 1.1.3 1.7.3 1.9 0 3.8-1.2 4.5-3.1l5.2-13.7c.9-2.5-.3-5.2-2.8-6.2-2.5-.9-5.2.3-6.2 2.8l-5.2 13.7c-.9 2.5.4 5.3 2.8 6.2zm0 0"
        id="path6"
        fill={props.fill}
      />
      <path
        className="st0"
        d="M510 232h-.1l-14.7.1c-2.7.1-4.8 2.1-4.7 4.7.1 2.6 2.2 4.7 4.8 4.7h.1l14.7-.2c2.7-.1 4.8-2.2 4.7-4.8-.1-2.6-2.2-4.5-4.8-4.5zm0 0"
        id="path8"
        fill={props.fill}
      />
      <path
        className="st0"
        d="M480.4 305.3c-2.5-1.9-5.4-3.3-8.4-3.9.2-.4.4-.7.6-1.1l1.9-1.9c7.4-7.4 8.2-19.1 1.8-27.2-3-3.8-7.2-6.3-11.8-7.3.3-.6.6-1.2.9-1.9 5.1-7.3 5-17.2-.6-24.2-3-3.8-7.2-6.4-11.9-7.3 3.8-7.1 3.3-15.9-1.8-22.3-3.9-4.9-9.7-7.7-15.9-7.7-5 0-9.7 1.8-13.4 5.1-.6-3.2-1.9-6.3-4-8.9-3.9-4.9-9.7-7.7-15.9-7.7-5.4 0-10.5 2.1-14.4 5.9l-48.7 48.7-.2-6.5c-.1-11.1-9.1-20-20.2-20-10.9 0-19.8 8.6-20.2 19.4l-9.7 70.6-8 9.3c-4.8 5.6-4.5 14 .7 19.2l11.9 11.8c-3.7 5.5-3 13 1.8 17.8l55.7 55.6c2.7 2.7 6.2 4.1 10 4.1 3.1 0 6.1-1 8.6-2.9l11.6-8.9c11.2-2.6 23.5-8.7 36.7-17.9 11.5-8 24-18.8 37.1-31.9l27.7-27.7c4.1-4.1 6.2-9.8 5.9-15.5-.5-5.9-3.3-11.3-7.8-14.8zm-52.9-92.2c2.1-2.1 4.8-3.1 7.6-3.1 3.1 0 6.3 1.4 8.4 4.1 3.4 4.3 2.8 10.5-1.1 14.4l-4.9 4.9c-.8.5-1.5 1.1-2.2 1.7-.6-3.2-1.9-6.3-4-8.9-2.6-3.3-6.1-5.6-10-6.8zm-33.4-11.5c2.1-2.1 4.8-3.1 7.6-3.1 3.1 0 6.3 1.4 8.4 4.1 3.4 4.3 2.8 10.5-1.1 14.4l-56.7 56.1-.2-6.5c0-6.3-3-11.8-7.5-15.5zm-106.2 127c-1.7-1.7-1.8-4.3-.2-6.1l9.8-11.4 10.2-74c0-5.8 4.8-10.6 10.6-10.6s10.6 4.8 10.6 10.6l.3 9.6c-9.6 1.3-17.1 9.4-17.4 19.2l-9.7 70.6-2.8 3.3zm187.4.2l-27.7 27.7c-23.7 23.7-48.4 42.7-70.1 47.4-.7.1-1.3.4-1.8.8l-12.5 9.6c-.8.6-1.8.9-2.7.9-1.2 0-2.3-.4-3.2-1.3l-55.7-55.6c-1.7-1.7-1.8-4.3-.2-6.1l9.8-11.4 10.2-74c0-5.8 4.8-10.6 10.6-10.6s10.6 4.8 10.6 10.6l.5 17.5c.1 2.9 2.5 4.8 5 4.8 1.2 0 2.4-.4 3.4-1.4l56.3-56.3c2.1-2.1 4.8-3.1 7.6-3.1 3.1 0 6.3 1.4 8.4 4.1 3.4 4.3 2.8 10.5-1.1 14.4l-38.8 38.8c-2 2-2 5.3 0 7.3 1 1 2.4 1.5 3.7 1.5 1.3 0 2.7-.5 3.7-1.5l49.9-49.9c2.1-2.1 4.8-3.1 7.6-3.1 3.1 0 6.3 1.4 8.4 4.1 3.4 4.3 2.8 10.5-1.1 14.4l-49.7 49.7c-2 2-2 5.4 0 7.4 1 1 2.3 1.5 3.6 1.5 1.3 0 2.6-.5 3.6-1.5l39.1-39.1c2.1-2.1 4.8-3.1 7.6-3.1 3.1 0 6.3 1.4 8.4 4.1 3.4 4.3 2.8 10.5-1.1 14.4l-38.7 38.7c-2.1 2.1-2.1 5.5 0 7.6l.1.1c1 1 2.2 1.4 3.5 1.4 1.3 0 2.5-.5 3.5-1.4l24-24c2.2-2.2 5.1-3.3 7.9-3.3 2.3 0 4.6.7 6.5 2.2 5.1 3.7 5.4 11.2.9 15.7zm0 0"
        id="path10"
        fill={props.fill}
      />
    </g>
  </svg>
);
