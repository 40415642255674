import * as React from 'react';
import styled from 'styled-components';
import { fadeIn, fadeOut } from './animations';

export interface IFadeProps {
  transitionSeconds: number;
  isVisible: boolean;
}

const FadeWrapper = styled.div`
  display: inline-block;
  visibility: ${(props: { isVisible: boolean; trans: number }) =>
    props.isVisible ? 'visible' : 'hidden'};
  animation: ${(props: { isVisible: boolean; trans: number }) =>
      props.isVisible ? fadeIn : fadeOut}
    ${(props: { isVisible: boolean; trans: number }) => props.trans}s linear;
  transition: visibility
    ${(props: { isVisible: boolean; trans: number }) => props.trans}s linear;
`;

export const Fade: React.SFC<IFadeProps> = props => {
  return (
    <FadeWrapper isVisible={props.isVisible} trans={props.transitionSeconds} />
  );
};
