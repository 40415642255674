import * as React from 'react';
import { CSSProperties } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Colors, ColorTheme } from '../common';
import { MunikumIcons } from '../common/icons';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { RefHandler } from 'react-popper';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { commonMessages } from '../language/commonMessages';
import { commonLabels } from '../language/commonLabels';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import { Button, ButtonSize, TextField } from '../basic';
import { TogglePopover } from '../basic/Popover/TogglePopover';
import { EmailSuccess } from './EmailSuccess';
import { TextArea } from '../basic/TextArea/TextArea';
import { H2 } from '../basic/Structural/typography';
import { IShareForm } from '../../services/metaService';
import { ShareInput } from '../../models/types';

const EmailSuccessCont = styled.div`
  display: ${(props: { isSuccess: boolean }) =>
    props.isSuccess ? 'flex' : 'none'};
  min-height: 246px;
  min-width: 240px;

  align-items: center;
  justify-content: center;
`;

const SHARE_ANYTHING = gql`
  mutation Share($input: ShareInput!) {
    share(input: $input)
  }
`;
const IsOpenCont = styled.form`
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'block' : 'none')};
`;
export interface IShareButtonProps {
  meta: string;
  onShare?: (form: IShareForm) => void;
  isSharing: boolean;
  style?: CSSProperties;
  renderTarget?: (ref: RefHandler) => JSX.Element;
}

export interface IShareState {
  isOpen: boolean;
  message: string;
  isSuccess: boolean;
  isFormOpen: boolean;
}

class ShareButtonComp extends React.PureComponent<
  IShareButtonProps & InjectedIntlProps,
  IShareState
> {
  private animation: React.RefObject<HTMLElement>;
  constructor(props: IShareButtonProps & InjectedIntlProps) {
    super(props);
    this.animation = React.createRef();
    this.state = {
      isOpen: false,
      message: '',
      isSuccess: false,
      isFormOpen: true,
    };
  }

  // tslint:disable-next-line
  private formikRef: any;

  handleButtonClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleShare = (temp: IShareForm) => {
    const shareInput: ShareInput = {
      description: temp.description,
      email: temp.email,
      id: this.props.meta,
    };
    myApolloClient
      .mutate({
        mutation: SHARE_ANYTHING,
        variables: {
          input: shareInput,
        },
      })
      .then(() => {
        this.setState({
          isSuccess: true,
          isFormOpen: false,
        });
        setTimeout(() => {
          this.setState({
            isOpen: false,
            isSuccess: false,
            isFormOpen: true,
          });
        }, 1000 * 3);
      });
  };

  handleCancel = () => {
    this.setState({
      isOpen: false,
    });
  };

  render(): JSX.Element | null | false {
    const { meta, intl, isSharing, style } = this.props;
    const { isOpen } = this.state;

    const initialValues: IShareForm = {
      meta: meta,
      email: '',
      description: '',
    };

    return (
      <div>
        <TogglePopover
          isOpen={isOpen}
          position={'bottom-start'}
          renderTarget={ref => {
            return (
              <Button
                theme={ColorTheme.White}
                innerRef={ref}
                leftIcon={MunikumIcons.Share}
                size={ButtonSize.Small}
                text={intl.formatMessage(commonMessages.share)}
                onClick={this.handleButtonClick}
                style={style}
              />
            );
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={(temp: IShareForm) => {
              this.handleShare(temp);
            }}
            // tslint:disable-next-line
            ref={(node: any) => (this.formikRef = node)}
            validateOnBlur={true}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .label(intl.formatMessage(commonLabels.email))
                .required()
                .email(),
              description: Yup.string().label(
                intl.formatMessage(commonLabels.description)
              ),
            })}
            render={(props: FormikProps<IShareForm>) => {
              return (
                <div>
                  <EmailSuccessCont isSuccess={this.state.isSuccess}>
                    <div>
                      <H2>{intl.formatMessage(commonLabels.success)}</H2>
                      <EmailSuccess color={Colors.RED} />
                    </div>
                  </EmailSuccessCont>
                  <div>
                    {this.state.message !== '' && (
                      <div>{this.state.message}</div>
                    )}
                    {/*<div ref={this.animation}/>*/}

                    <IsOpenCont isOpen={this.state.isFormOpen}>
                      <TextField
                        name={'email'}
                        type={'email'}
                        label={intl.formatMessage(commonMessages.eMail)}
                        value={props.values.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.email}
                      />

                      <TextArea
                        name={'description'}
                        label={intl.formatMessage(commonMessages.message)}
                        value={props.values.description}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.errors.description}
                        style={{
                          width: '100%',
                        }}
                      />

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          text={intl.formatMessage(commonMessages.cancel)}
                          theme={ColorTheme.White}
                          onClick={this.handleCancel}
                          style={{
                            flex: '1 1 auto',
                          }}
                        />

                        <Button
                          text={intl.formatMessage(commonMessages.share)}
                          theme={ColorTheme.Red}
                          isLoading={isSharing}
                          disabled={!props.isValid}
                          onClick={() => {
                            const temp: IShareForm = Object.assign(
                              {},
                              props.values
                            );
                            this.handleShare(temp);
                          }}
                          style={{
                            flex: '1 1 auto',
                            marginLeft: '1em',
                          }}
                        />
                      </div>
                    </IsOpenCont>
                  </div>
                </div>
              );
            }}
          />
        </TogglePopover>
      </div>
    );
  }
}

export const ShareButton = injectIntl(ShareButtonComp);
