import { defineMessages } from 'react-intl';

export const loginMessages = defineMessages({
  title: {
    id: 'loginFormEmail.title',
    defaultMessage: 'Welcome',
  },
  email: {
    id: 'loginFormEmail.email',
    defaultMessage: 'E-mail',
  },
  emailPlaceholder: {
    id: 'loginFormEmail.emailPlaceholder',
    defaultMessage: 'email@your.municipality.no',
  },
  intro: {
    id: 'loginFormEmail.intro',
    defaultMessage: 'Enter your email address to log in',
    description: 'Helpful text when logging in',
  },
  pinlabel: {
    id: 'loginFormEmail.pinlabel',
    defaultMessage: 'PIN-code',
  },
  pinInfo: {
    id: 'loginFormEmail.pinInfo',
    defaultMessage: 'Check your e-mail for PIN-code',
  },
  errorEmailRequired: {
    id: 'loginFormEmail.errorEmailRequired',
    defaultMessage: 'Email is required',
  },
  errorEmailInvalid: {
    id: 'loginFormEmail.errorEmailInvalid',
    defaultMessage: 'Email is invalid',
  },
  errorEmailInvalidOrganization: {
    id: 'loginFormEmail.errorEmailInvalidOrganization',
    defaultMessage:
      '{email} is not a valid municipality email, please use your municipality email ending with @your.municipality.no',
  },
  errorEmailBadRequest: {
    id: 'loginFormEmail.errorEmailBadRequest',
    defaultMessage: 'Could not verify email, invalid email?',
  },
  errorEmailInternalServer: {
    id: 'loginFormEmail.errorEmailInternalServer',
    defaultMessage: 'Could not verify email, internal server error',
  },
  errorEmailTooManyAttempts: {
    id: 'loginFormEmail.errorEmailTooManyAttempts',
    defaultMessage: 'Too many login attempts, please try again in 1 hour',
  },
  errorEmailFailedToFetch: {
    id: 'loginFormEmail.errorEmailFailedToFetch',
    defaultMessage: 'Could not verify email, no response from server',
  },
  errorEmailNoUser: {
    id: 'loginFormEmail.noUser',
    defaultMessage: 'No user with that email, please sign up :-)',
  },
  errorPin: {
    id: 'loginFormEmail.errorPin',
    defaultMessage: 'Could not log in, incorrect PIN',
  },
  errorOffline: {
    id: 'loginFormEmail.errorOffline',
    defaultMessage: 'There is no internet connection',
  },
  login: {
    id: 'loginFormEmail.login',
    defaultMessage: 'Log in',
  },
  sendPin: {
    id: 'loginFormEmail.sendPin',
    defaultMessage: 'Send PIN-code',
  },
  errorCaptcha: {
    id: 'loginFormEmail.errorCaptcha',
    defaultMessage: 'Are you a robot?',
  },
  errorExistingEmail: {
    id: 'loginFormEmail.errorExistingEmail',
    defaultMessage: 'A user with the same email already exists',
  },
  errorPinFormat: {
    id: 'loginFoerrorPinFormat',
    defaultMessage: 'Wrong format in PIN code, 6 digits',
  },
});
