import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect';
import styled from 'styled-components';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';

import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import Helmet from 'react-helmet';
import {
  AllDiscussionsVariables,
  ContentStatus,
  OrderEnum,
  Organization,
  SortEnum,
} from '../../../models/types';
import { myHistory } from '../../../index';
import { Button } from '../../../components/basic';
import { GraphQLPager } from '../../../components/basic/Pager/GraphQLPager';
import { Page, PageTitle, PageTop } from '../../../components/layout/Page/Page';
import { FormLabel } from '../../../components/basic/FormLabel/FormLabel';
import { isAdminSelector, usernameSelector } from '../../../modules/auth/auth';
import {
  FlexGrid,
  FlexGridItem,
  FlexGridRow,
} from '../../../components/layout/FlexGrid/FlexGrid';
import { safeInvoke, safeInvokeDeprecated } from '../../../components/common';
import DiscussionCard from '../../../components/discussion/DiscussionCard';
import { InfoTooltip } from '../../../components/basic/Tooltip/Tooltip';
import {
  editDiscussionActionCreator,
  filterDiscussionsActionCreator,
  IDiscussionsFilter,
} from '../../../modules/discussion/discussion';
import { DropDownSelect } from '../../../components/basic/DropDownSelect/DropDownSelect';
import {
  CircleSpinnerAnimation,
  SuspenseLoader,
} from '../../../components/basic/Loader/CircleSpinner';
import { commonMessages } from '../../../components/language/commonMessages';
import { RootState } from '../../../modules/rootReducer';
import { Dispatch } from 'redux';
import { Checkbox } from '../../../components/basic/Checkbox/Checkbox';
import { CreateDiscussionCard } from '../../../components/discussion/CreateDiscussionCard';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import { uploadFile } from '../../../services/fileservice';
import { Overlay } from '../../../components/basic/Overlay/Overlay';
import {
  IStaticImageType,
  StaticImage,
} from '../../../components/basic/StaticImage/StaticImage';
import { AsyncSearchDropdown } from '../../../components/basic/AsyncSearchDropdown/AsyncSearchDropdown';
import {
  FIND_ORGANIZATIONS,
  ListDivMunicipalities,
} from '../../AnnualCycle/CalendarListPage';

const messages = defineMessages({
  title: {
    id: 'DiscussionListPage.title',
    defaultMessage: 'Discussions',
  },
  newQuestion: {
    id: 'DiscussionListPage.newQuestion',
    defaultMessage: 'New question',
  },
  searchPlaceholder: {
    id: 'DiscussionListPage.searchPlaceholder',
    defaultMessage: 'Filter discussions',
  },
  showOnlyMine: {
    id: 'DiscussionListPage.showOnlyMine',
    defaultMessage: 'Show only my discussions',
  },
});

const TopDiv = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export interface IDiscussionListPageProps {
  isAdmin: boolean;
  username?: string;
  list: IDiscussionsFilter;
}

export interface IDiscussionListPageState {
  isCreateDiscussionCardVisible: boolean;
  onlyMine: boolean;
  currentSortValue: string;
  limit: number;
}

export interface IDiscussionListPageDispatch {
  onEdit?: (id: string) => void;
  onLoadData?: () => void;
  onFollowDiscussion?: (id: string, follow: boolean) => void;
  onPageChanged?: (page: number) => void;
  onFilterChanged?: (filter: IDiscussionsFilter) => void;
  onClickDiscussion: (metaUri: string, forumUri: string) => void;
  onTopicClick?: (uri: string) => void;
}

const SearchAndFilterContainer = styled.div`
  display: flex;
`;

const fieldsOnDiscussion = gql`
  fragment fieldsOnDiscussion on Discussion {
    id
    title
    isLiked
    likes
    views
    createdBy {
      department
      id
      name
      picture
      role
      organization {
        id
        name
        organizationImage
      }
    }
    dateCreated
    lastUpdated
    uri
    description
    status
    isFollowing
    currentRevision {
      id
      body
    }
    comments {
      id
      dateCreated
    }
    topics {
      id
      title
      uri
      createdBy {
        department
        id
        name
        picture
        role
        organization {
          id
          name
          organizationImage
        }
      }
    }
    followers {
      id
      name
    }
    files {
      contentType
      createdBy {
        department
        id
        name
        picture
        role
        organization {
          id
          name
          organizationImage
        }
      }
      id
      isLiked
      lastUpdated
      likes
      name
      description
      size
      access {
        myAccess
      }
    }
  }
`;

export const ALL_DISCUSSIONS = gql`
  query AllDiscussions(
    $sort: SortEnum
    $order: OrderEnum
    $onlyMine: Boolean
    $limit: Int
    $offset: Int
    $organizations: [Long!]
  ) {
    me {
      id
      organization {
        id
        name
      }
    }
    allDiscussions(
      input: {
        organizations: $organizations
        sort: $sort
        order: $order
        onlyMine: $onlyMine
        limit: $limit
        offset: $offset
      }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        itemsPerPage
        numberOfPages
        pageNumber
      }

      totalCount
      nodes {
        ...fieldsOnDiscussion
      }
    }
  }
  ${fieldsOnDiscussion}
`;
export const CREATE_DISCUSSION_2 = gql`
  mutation CreateDiscussion2($input: CreateDiscussionInput!) {
    createDiscussion(input: $input) {
      ...fieldsOnDiscussion
    }
  }
  ${fieldsOnDiscussion}
`;

class AllDiscussionsQueryComp extends Query<any, AllDiscussionsVariables> {}

class DiscussionListPageComponent extends React.PureComponent<
  IDiscussionListPageProps &
    InjectedAuthRouterProps &
    RouteComponentProps<{ forum: string }> &
    InjectedIntlProps &
    IDiscussionListPageDispatch,
  IDiscussionListPageState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isCreateDiscussionCardVisible:
        window.location.href.indexOf('create') !== -1,
      onlyMine: this.props.list.showOnlyMine,
      limit: 10,
      currentSortValue: this.props.list.sortValue,
    };
  }

  componentDidMount() {
    safeInvokeDeprecated(this.props.onLoadData);
  }

  handleFollowDiscussion = (id: string, follow: boolean) => {
    safeInvokeDeprecated(this.props.onFollowDiscussion, id, follow);
  };

  handlePageChanged = (page: number) => {
    safeInvokeDeprecated(this.props.onPageChanged, page);
  };

  // handleSearch = (value: string) => {
  //   const temp: IDiscussionsFilter = Object.assign({}, this.props.filter, {
  //     search: value,
  //   });
  //   safeInvoke(this.props.onFilterChanged, temp);
  // };

  handleClick = (metaUri: string) => {
    // const completeUri = '/forum/' + forum + '/' + metaUri;
    const forumUri = this.props.match.params.forum;
    safeInvoke(this.props.onClickDiscussion, metaUri, forumUri);
  };

  handleSortChange = (key: string) => {
    if (key === '0') {
      safeInvoke(this.props.onFilterChanged, {
        ...this.props.list,
        sortValue: '0',
        sort: SortEnum.TITLE,
        order: OrderEnum.ASCENDING,
      });
    } else {
      safeInvoke(this.props.onFilterChanged, {
        ...this.props.list,
        sortValue: '1',
        sort: SortEnum.LAST_UPDATED,
        order: OrderEnum.DESCENDING,
      });
    }
  };

  handleCreateDiscussion = async (
    title,
    description,
    descriptionText,
    files2,
    data
  ) => {
    let result: any = undefined;
    result = await myApolloClient.mutate({
      mutation: CREATE_DISCUSSION_2,
      variables: {
        input: {
          title: title,
          body: description,
          bodyText: descriptionText,
          status: ContentStatus.PUBLISHED,
        },
      },
      update: (proxy, { data: { createDiscussion } }) => {
        const cachedata = proxy.readQuery({
          query: ALL_DISCUSSIONS,
          variables: {
            sort: this.props.list.sort,
            order: this.props.list.order,
            onlyMine: this.state.onlyMine,
            limit: this.state.limit,
            offset: this.props.list.offset,
            organizations: this.props.list.organizations.map(c => +c.id),
          },
        });
        data.allDiscussions.nodes.unshift(createDiscussion);
        proxy.writeQuery({
          query: ALL_DISCUSSIONS,
          variables: {
            variables: {
              sort: this.props.list.sort,
              order: this.props.list.order,
              onlyMine: this.state.onlyMine,
              limit: this.state.limit,
              offset: this.props.list.offset,
            },
          },
          data,
        });
      },
    });
    const freshId =
      (result.data &&
        result.data.createDiscussion &&
        result.data.createDiscussion.id) ||
      undefined;

    for (let i = 0; i < files2.length; i++) {
      if (files2[i].file !== null && files2[i].file !== null) {
        try {
          const uploadResult = await uploadFile(
            files2[i].file!,
            freshId,
            files2[i].description,
            p => console.log('upload progress: ' + p)
          );
          if (!uploadResult.greatSuccess) {
            // console.warn('Oops, upload failed: ' + files2[i].filename);
            // NOTE: we could take some action here? Now we just continue to the next file...
          }
        } catch (uploadErr) {
          console.log('upload err', uploadErr);
        }
      }
    }
    if (window.location.href.indexOf('create') !== -1) {
      myHistory.replace({
        pathname: '/forum' + '/public/',
      });
    }

    this.setState({
      isCreateDiscussionCardVisible: false,
    });
  };

  handleOnlyMineChecked = () => {
    this.setState(
      {
        onlyMine: !this.state.onlyMine,
      },
      () => {
        safeInvoke(this.props.onFilterChanged, {
          ...this.props.list,
          showOnlyMine: this.state.onlyMine,
        });
      }
    );
  };

  handlePageChange = (pageNumber: number) => {
    safeInvoke(this.props.onFilterChanged, {
      ...this.props.list,
      offset: (pageNumber === 1 && 0) || (pageNumber - 1) * this.state.limit,
    });
  };

  render() {
    const { match, intl } = this.props;
    const { onlyMine, limit } = this.state;

    // Setup sort enum values
    let sortKeys = Object.keys(SortEnum);
    let sortArray: Array<{ key: number; value: string }> = [];

    for (let i = 0; i < sortKeys.length; i++) {
      if (sortKeys[i] === 'DEADLINE') {
        continue;
      }
      sortArray.push({
        key: i,
        value: intl.formatMessage({
          id: 'common.' + sortKeys[i].toLocaleLowerCase(),
          defaultMessage: sortKeys[i].toLocaleLowerCase(),
        }),
      });
    }

    return (
      <Page>
        <Helmet>
          <title>{intl.formatMessage(commonMessages.forum)}</title>
        </Helmet>
        <AllDiscussionsQueryComp
          query={ALL_DISCUSSIONS}
          variables={{
            sort: this.props.list.sort,
            order: this.props.list.order,
            onlyMine: onlyMine,
            limit: limit,
            offset: this.props.list.offset,
            organizations: this.props.list.organizations.map(c => +c.id),
          }}
          fetchPolicy={'cache-and-network'}
        >
          {({ loading, error, data }) => {
            let temp =
              data.me &&
              data.me.organization.name &&
              data.me.organization.name.toLowerCase();
            let nameCapitalized =
              temp && temp.charAt(0).toUpperCase() + temp.slice(1);
            return (
              <div>
                <PageTop>
                  <InfoTooltip
                    content={
                      <div>
                        <FormattedMessage
                          id={'DiscussionListPage.intro.text'}
                          defaultMessage={
                            'This is an arena for sharing knowledge and skills between employees of municipalities across the nation. The goal is to elicit and share good solutions by supporting cooperation through this forum on tasks and challenges which are common to all municipialities and their employees.'
                          }
                          values={{
                            br: <br />,
                          }}
                        />
                      </div>
                    }
                  >
                    <PageTitle>
                      {this.props.list.predefinedMunicipality ? (
                        <FormattedMessage
                          id={'DiscissionListPage.discussionMyMunicipality'}
                          defaultMessage={'Discussions in {municipality}'}
                          values={{
                            municipality: nameCapitalized,
                          }}
                        />
                      ) : (
                        <FormattedMessage
                          id={'DiscussionListPage.intro.title'}
                          defaultMessage={'Discussions'}
                        />
                      )}
                    </PageTitle>
                  </InfoTooltip>

                  <SearchAndFilterContainer>
                    {/*<SearchField*/}
                    {/*searchPlaceholder={intl.formatMessage(*/}
                    {/*messages.searchPlaceholder*/}
                    {/*)}*/}
                    {/*onChange={this.handleSearch}*/}
                    {/*value={filter.search}*/}
                    {/*/>*/}
                    <Button
                      text={intl.formatMessage(messages.newQuestion)}
                      onClick={() => {
                        this.setState({
                          isCreateDiscussionCardVisible: true,
                        });
                      }}
                      style={{
                        width: '10em',
                        marginLeft: '.5em',
                      }}
                    />
                  </SearchAndFilterContainer>
                </PageTop>

                <FlexGrid
                  style={{
                    marginTop: '1.25em',
                  }}
                >
                  <TopDiv>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '.5em',
                      }}
                    >
                      <Checkbox
                        name={'onlyMine'}
                        checked={onlyMine}
                        label={intl.formatMessage(messages.showOnlyMine)}
                        onClick={this.handleOnlyMineChecked}
                      />
                    </div>
                    <div style={{ position: 'relative' }}>
                      {loading && (
                        <div
                          style={{
                            position: 'absolute',
                            left: '-10em',
                            top: '1em',
                            width: '10em',
                          }}
                        >
                          <CircleSpinnerAnimation
                            style={{
                              width: '1em',
                              height: '1em',
                              position: 'absolute',
                              left: '-1.25em',
                              top: '0.2em',
                            }}
                          />
                          Oppdaterer liste...
                        </div>
                      )}
                      <div style={{ display: 'flex' }}>
                        <div>
                          <FormLabel>
                            {intl.formatMessage(
                              commonMessages.searchMunicipalities
                            )}
                          </FormLabel>
                          <AsyncSearchDropdown
                            items={
                              this.props.list.organizations.length > 0
                                ? this.props.list.organizations.map(
                                    (c: Organization) => {
                                      return {
                                        id: c.id,
                                        label: c.name,
                                        img: c.organizationImage,
                                      };
                                    }
                                  )
                                : []
                            }
                            value={
                              this.props.list &&
                              this.props.list.organizations.length > 0
                                ? this.props.list &&
                                  this.props.list.organizations[0].name
                                : undefined
                            }
                            leftIcon={true}
                            defaultListStyling={false}
                            canClearAll={true}
                            onChange={values => {
                              if (values) {
                                let orgs: any = [];
                                values.forEach(value => {
                                  orgs.push({
                                    name: value.label,
                                    id: value.id,
                                    organizationImage: value.img,
                                  });
                                });
                                safeInvoke(this.props.onFilterChanged, {
                                  ...this.props.list,
                                  predefinedMunicipality: false,
                                  organizations: orgs,
                                });
                              }
                            }}
                            style={{ width: '160px' }}
                            placeholder={'Søk her...'}
                            noResultMessage={'Ingen resultat...'}
                            getItems={input => {
                              return new Promise(async (resolve, reject) => {
                                try {
                                  let tempOrg: Array<any> = [];
                                  const res = await myApolloClient.query<any>({
                                    query: FIND_ORGANIZATIONS,
                                    variables: {
                                      query: input,
                                    },
                                  });
                                  tempOrg =
                                    res &&
                                    res.data &&
                                    res.data.findOrganizations &&
                                    res.data.findOrganizations.map(org => {
                                      return {
                                        id: org.id,
                                        label: org.name,
                                        img: org.organizationImage,
                                      };
                                    });
                                  resolve(tempOrg);
                                } catch (e) {
                                  console.error('Fail', e);
                                  reject(e);
                                }
                              });
                            }}
                            renderItem={(
                              value: any,
                              i,
                              isActive,
                              isSelected,
                              img
                            ) => (
                              <ListDivMunicipalities
                                key={i}
                                style={{
                                  backgroundColor: isActive
                                    ? '#00B0B9'
                                    : '#fff',
                                  cursor: isActive ? 'pointer' : 'normal',
                                }}
                              >
                                <div
                                  style={{
                                    height: '1.6em',
                                    fontSize: '18px',
                                    color: isSelected ? 'red' : 'grey',
                                    marginRight: '1em',
                                  }}
                                >
                                  &#10003;
                                </div>
                                {value.label}
                                <StaticImage
                                  key={i}
                                  width={21}
                                  height={21}
                                  style={{ marginLeft: '1em' }}
                                  type={IStaticImageType.ORGANIZATION}
                                  filename={img}
                                />
                              </ListDivMunicipalities>
                            )}
                          />
                        </div>
                        <DropDownSelect
                          label={intl.formatMessage(commonMessages.Sort)}
                          name={'sort'}
                          onChangeSelected={e => this.handleSortChange(e)}
                          showEmptyOption={false}
                          items={sortArray}
                          accessor={'value'}
                          value={this.props.list.sortValue}
                          fieldGroupStyle={{
                            height: '36px',
                            width: '160px',
                            marginLeft: '1em',
                            marginRight: '.5em',
                          }}
                        />
                      </div>
                    </div>
                  </TopDiv>
                  <Overlay isOpen={this.state.isCreateDiscussionCardVisible}>
                    <CreateDiscussionCard
                      breadCrumbTitle={intl.formatMessage(
                        commonMessages.newDiscussion
                      )}
                      onCancel={isEmpty => {
                        if (window.location.href.indexOf('create') !== -1) {
                          myHistory.replace({
                            pathname: '/forum' + '/public/',
                          });
                        }
                        this.setState({
                          isCreateDiscussionCardVisible: false,
                        });
                      }}
                      onSubmit={(
                        title: string,
                        description: string,
                        descriptionText: string,
                        files: any
                      ) => {
                        this.handleCreateDiscussion(
                          title,
                          description,
                          descriptionText,
                          files,
                          data && data
                        );
                      }}
                    />
                  </Overlay>
                  {!data && loading && (
                    <SuspenseLoader
                      text={intl.formatMessage(commonMessages.loading)}
                    />
                  )}
                  {error && <p>Error</p>}
                  {data && data.allDiscussions && data.allDiscussions.nodes && (
                    <FlexGridRow topBorder={false}>
                      <FlexGridItem>
                        {data.allDiscussions.nodes.map((value, i) => {
                          const canEdit: boolean =
                            this.props.isAdmin ||
                            (this.props.username &&
                              value.createdBy.name === this.props.username) ||
                            false;

                          if (this.props.username !== undefined) {
                            // console.log(value);
                            return (
                              <DiscussionCard
                                topicItemPageMode={false}
                                item={value}
                                key={value.id}
                                canEdit={canEdit}
                                isYou={true}
                                onClick={this.handleClick}
                                onFollow={this.handleFollowDiscussion}
                              />
                            );
                          }
                          return <div key={i}>butt</div>;
                        })}
                      </FlexGridItem>
                      <GraphQLPager
                        pageInfo={data.allDiscussions.pageInfo}
                        onPageChanged={this.handlePageChange}
                      />
                    </FlexGridRow>
                  )}
                </FlexGrid>
              </div>
            );
          }}
        </AllDiscussionsQueryComp>
      </Page>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: IDiscussionListPageProps & RouteComponentProps<{ forum: string }>
): IDiscussionListPageProps => ({
  isAdmin: isAdminSelector(state),
  username: usernameSelector(state),
  list: state.discussion.list.filter,
});

const mapDispatchToProps = (
  dispatch: Dispatch<{}>
): IDiscussionListPageDispatch => ({
  onEdit: (id: string) => {
    // console.log('redux edit av');
    dispatch(editDiscussionActionCreator.started({ id: id }));
  },
  onFilterChanged: (filter: IDiscussionsFilter) => {
    dispatch(filterDiscussionsActionCreator({ filter: filter }));
  },
  onClickDiscussion: (metaUri: string, forumUri: string) => {
    const completeForumUri = '/forum/' + forumUri + '/' + metaUri;
    myHistory.push(completeForumUri);
  },
  onTopicClick: (uri: string) => {
    myHistory.push(uri);
  },
});

export const DiscussionListPage = connect<IDiscussionListPageProps, {}, {}>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DiscussionListPageComponent));
export default DiscussionListPage;
