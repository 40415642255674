import * as React from 'react';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { IAuthUsername, signin } from '../../../modules/auth/auth';
import { LoginFormUsernamePass } from '../../../components/login/LoginFormUsernamePass';
import { Colors, safeInvokeDeprecated } from '../../../components/common';
import { LoginType } from '../../../modules/auth/IAuthResult';
import { mergeProps } from '../../../util/mergePropsHack';
import { ExternalLink } from '../../../components/basic/Structural/links';
import { RootState } from '../../../modules/rootReducer';

const logo3 = require('./munikum-logo.svg') as string;
const background = require('./background.svg') as string;

export interface ILoginFormUsername {
  email: string;
  password: string;
}

export interface ILoginUsernamePageProps {
  login?: (values: ILoginFormUsername) => void;
  auth_username: IAuthUsername;
}

const LoginMain = styled.div`
  background-color: ${Colors.WHITE};
  border: 1px solid ${Colors.GREY};
  border-radius: 4px 4px 0 0;
  min-height: 150px;
  //padding: 30px 30px 20px 30px;
  padding: 20px 30px;
  align-self: stretch;
`;

const LoginFooter = styled.div`
  background-color: ${Colors.GREY};
  border: 1px solid ${Colors.GREY};
  border-radius: 0 0 4px 4px;
  font-size: 0.875em;
  align-self: stretch;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  color: ${Colors.GREYLABEL};
`;

const LoginFooterFooter = styled.div`
  align-self: stretch;
  text-align: center;
  font-size: 0.875em;
  color: ${Colors.GREYLABEL};
  margin-top: 2em;
`;

const LoginWrapper = styled.div`
  //background-color: pink;
  background-image: url(${background});
  background-size: cover;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
`;
const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  align-items: center;
  justify-content: center;
`;

class LoginUsernamePageComponent extends React.PureComponent<
  ILoginUsernamePageProps,
  {}
> {
  constructor(props: ILoginUsernamePageProps) {
    super(props);
  }

  handleSubmit = (values: ILoginFormUsername) => {
    safeInvokeDeprecated(this.props.login, values);
  };

  render() {
    // console.log('render LoginUsernamePage');
    const { auth_username } = this.props;

    const alt1 = (
      <LoginWrapper>
        <LoginContainer>
          <div>
            <Link to={'/'}>
              <img src={logo3} width={241} alt={'logo'} />
            </Link>
          </div>
          <LoginMain>
            <Switch>
              <Route path="/login-classic" exact={true}>
                <LoginFormUsernamePass
                  authUsername={auth_username}
                  onSubmit={this.handleSubmit}
                />
              </Route>
              <Route path="*">
                <h3>wrong route..</h3>
              </Route>
            </Switch>
          </LoginMain>
          <LoginFooter>Glemt passordet?</LoginFooter>
          <LoginFooterFooter>
            Har du ikke konto ennå?{' '}
            <ExternalLink href={'http://sodermann.no'} target={'_blank'}>
              Meld deg på
            </ExternalLink>
          </LoginFooterFooter>
        </LoginContainer>
      </LoginWrapper>
    );

    return alt1;
  }
}

// mapping from global state to container props:
const mapStateToProps = (state: RootState): ILoginUsernamePageProps => ({
  auth_username: state.auth && state.auth.auth_username,
});

// map dispatch to props on container :
const mapDispatchToPropsSimple = (dispatch: Dispatch<{}>) => {
  return {
    login: (values: ILoginFormUsername) => {
      if (values && values.email && values.password) {
        dispatch(
          signin({
            email: values.email,
            password: values.password,
            loginType: LoginType.Username,
          })
        );
      }
    },
  };
};

// export connected container:
const LoginUsernamePage: any = connect<ILoginUsernamePageProps, {}, {}>(
  mapStateToProps,
  mapDispatchToPropsSimple,
  mergeProps
)(LoginUsernamePageComponent);

export default LoginUsernamePage;
