/***
 * @deprecated don't use this, it's not necessary anymore..
 * stupid hack function needed to make TypeScript happy..
 * @param {Object} stateProps
 * @param {Object} dispatchProps
 * @param {Object} ownProps
 * @returns {{} & Object & Object & Object}
 */
export function mergeProps(
  stateProps: Object,
  dispatchProps: Object,
  ownProps: Object
) {
  return Object.assign({}, ownProps, stateProps, dispatchProps);
}
