import * as React from 'react';
import { useContext } from 'react';
import { isFunction } from '../components/common';
import { Route, RouteProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import { invariant } from 'ts-invariant';
import { AuthContext, AuthInfo } from './AuthContext';

type CustomAuthorizeFunc = (auth: AuthInfo) => boolean;

function getFnName(fn) {
  if (fn === undefined) {
    return 'default';
  }
  const f = typeof fn == 'function';
  const s =
    f &&
    ((fn.name && ['', fn.name]) || fn.toString().match(/function ([^\(]+)/));
  return (!f && 'not a function') || ((s && s[1]) || 'anonymous');
}

export const AuthRoute: React.FunctionComponent<
  RouteProps & { authorizeFunc?: CustomAuthorizeFunc } // the routes props // my stuff // from withRouter
> = props => {
  const { authorizeFunc, render, component: Component, ...rest } = props;

  const value = useContext(AuthContext);
  invariant(value, 'You should not use AuthRoute outside a AuthContext..');

  // isLoggedIn === user is logged in with correct username / pass etc.
  let isLoggedIn = value.isAuthenticated;

  // isAuthenticated === user is logged in + any required roles, permissions, etc. are satisfied
  let isAuthorized = value.isAuthenticated;

  if (isFunction(authorizeFunc)) {
    isAuthorized = authorizeFunc(value) || false;
    // console.log('custom auth func: ' + isAuthenticated.toString());
  }

  const navigatingToUrl: string = location.pathname;
  const redirect =
    navigatingToUrl.length > 0
      ? '/?redirect=' + encodeURIComponent(navigatingToUrl)
      : '';
  const noAccess =
    navigatingToUrl.length > 0 ? '/' + encodeURIComponent(navigatingToUrl) : '';

  const redirectToLogin = (
    <Redirect
      to={{
        pathname: '/login' + redirect,
        state: { from: props.location },
      }}
    />
  );

  const redirectToNoAccessPage = (
    <Redirect
      to={{
        pathname: '/no-access' + noAccess,
        state: { from: props.location },
      }}
    />
  );

  const fnName = getFnName(authorizeFunc);
  // console.log(
  //   'AUTH => authenticated: ' +
  //     (isLoggedIn ? 'Y' : 'N') +
  //     ' authorized(' +
  //     fnName +
  //     '): ' +
  //     (isAuthorized ? 'Y' : 'N')
  // );

  if (!isLoggedIn) {
    console.warn('AUTH => redirect to: ' + redirect);
  }

  if (!isAuthorized) {
    console.warn('AUTH => redirect to: ' + noAccess);
  }

  if (render) {
    // console.log('render style');
    return (
      <Route
        {...rest}
        render={routeRenderProps =>
          isLoggedIn
            ? isAuthorized
              ? render(routeRenderProps)
              : redirectToNoAccessPage
            : redirectToLogin
        }
      />
    );
  } else if (Component) {
    // NOTE: we had to use render here, not component to avoid remounts..
    return (
      <Route
        render={routeRenderProps =>
          isLoggedIn ? (
            isAuthorized ? (
              <Component {...routeRenderProps} />
            ) : (
              redirectToNoAccessPage
            )
          ) : (
            redirectToLogin
          )
        }
        {...rest}
      />
    );
  } else {
    // NOTE: react router also supports children.. we don't yet...  :-p
    throw Error('Auth route can only be used with render or component..');
  }
};
