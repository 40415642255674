import { ColorTheme } from '../common';
import * as React from 'react';
import { safeInvokeDeprecated, validateEmail } from '../common';
import styled from 'styled-components';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { commonLabels } from '../language/commonLabels';
import { commonMessages } from '../language/commonMessages';
import { IAuthUsername } from '../../modules/auth/auth';
import { Button, TextField, ValidationSummary } from '../basic';

export interface ILoginForm {
  email: string;
  password: string;
}

export interface ILoginFormUsernamePassProps {
  onSubmit?: (values: ILoginForm) => void;
  authUsername: IAuthUsername;
  initialEmail?: string;
}

export type LoginFormUsernamePassState = ILoginForm & {
  isFresh: boolean;
  emailError?: string;
};

const FixedValidationSummary = styled(ValidationSummary)`
  height: 1.5em;
`;

/***
 * LoginFormUsernamePass
 * - a 'normal' react loginform with controlled inputs, simple validation, and props that connects with redux store
 * TODO: if we want to support this, we should rewrite to Formik, internationalize errormessages, ++
 */
class LoginFormUsernamePassComp extends React.PureComponent<
  ILoginFormUsernamePassProps & InjectedIntlProps,
  LoginFormUsernamePassState
> {
  constructor(props: ILoginFormUsernamePassProps & InjectedIntlProps) {
    super(props);
    this.state = {
      email: props.initialEmail || '',
      password: '',
      isFresh: true,
    };
    // console.log('ctor username pass form');
  }

  // tslint:disable-next-line
  handleSubmit = (e: any) => {
    // console.log('submit!');
    e.preventDefault();
    safeInvokeDeprecated(this.props.onSubmit, this.state);
  };

  // tslint:disable-next-line
  handleChangeEmail = (e: any) => {
    this.setState({
      ...this.state,
      email: e.target.value,
      emailError: !this.state.isFresh
        ? validateEmail(this.state.email)
        : undefined,
    });
  };

  handleBlurEmail = () => {
    this.setState({
      ...this.state,
      emailError: validateEmail(this.state.email),
      isFresh: false,
    });
  };

  // tslint:disable-next-line
  handleChangePassword = (e: any) => {
    this.setState({
      ...this.state,
      password: e.target.value,
    });
  };

  render() {
    const { email, password, emailError } = this.state;
    const { authUsername, intl } = this.props;

    const errorMessages: Array<string> = [];
    if (emailError) {
      errorMessages.push(emailError);
    }
    if (authUsername.errorLoginUsername) {
      errorMessages.push(authUsername.errorLoginUsername);
    }

    return (
      <form onSubmit={this.handleSubmit} style={{ marginTop: '1em' }}>
        <TextField
          name={'email'}
          label={intl.formatMessage(commonLabels.email)}
          value={email}
          onChange={this.handleChangeEmail}
          onBlur={this.handleBlurEmail}
          style={{ width: '100%' }}
        />
        <br />
        <TextField
          name={'password'}
          label={intl.formatMessage(commonLabels.password)}
          type={'password'}
          value={password}
          onChange={this.handleChangePassword}
          style={{ width: '100%' }}
        />
        <br />
        <Button
          text={intl.formatMessage(commonMessages.login)}
          theme={ColorTheme.Red}
          type={'submit'}
          onClick={this.handleSubmit}
          style={{ width: '100%' }}
        />
        <div style={{ marginTop: '.5em' }}>
          <FixedValidationSummary error={errorMessages} />
        </div>
      </form>
    );
  }
}

export const LoginFormUsernamePass = injectIntl(LoginFormUsernamePassComp);
