import * as React from 'react';
import { CSSProperties, RefObject } from 'react';

import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import styled from 'styled-components';
import { MuPreview } from '../basic/MuEditor/MuPreview';
import clamp from 'clamp-js';
import {
  CardContainer,
  CardContentContainer,
  CardLastUpdated,
  CardLeftContainerNormal,
  CardTitle,
} from '../basic/Card/Card';
import { myHistory } from '../../index';
import { LinkWrapper } from '../basic/Structural/links';
import { UserSummary } from './UserSummary';
import {
  ButtType,
  FollowButton2,
  MyType,
} from '../basic/FollowButton/FollowButton2';
import { AllDiscussionsNodes, Topic } from '../../models/types';
import { Colors, safeInvokeDeprecated } from '../common';
import { Tag } from '../basic/Tag/Tag';
import { sizes } from '../common/media';
import moment from 'moment';
import { Clap, ObjectType } from '../basic/Clap/Clap';
import { MunikumIcons } from '../common/icons';
import { commonMessages } from '../language/commonMessages';
import MediaQuery from 'react-responsive';

interface IDiscussionCardProps {
  item: AllDiscussionsNodes;
  canEdit: boolean;
  isYou: boolean;
  style?: CSSProperties;
}
interface IDiscussionCardRenderProps {
  topicItemPageMode: boolean;
}
interface IDiscussionCardDispatch {
  onClick?: (uri: string) => void;
  onFollow?: (id: string, follow: boolean) => void;
  onTopicClick?: (uri: string) => void;
}

interface IDiscussionCardState {}

const messages = defineMessages({
  answers: {
    id: 'DiscussionCard.answers',
    defaultMessage: 'answers',
  },
  views: {
    id: 'DiscussionCard.views',
    defaultMessage: 'views',
  },
  followers: {
    id: 'DiscussionCard.followers',
    defaultMessage: 'followers',
  },
  files: {
    id: 'DiscussionCard.files',
    defaultMessage: 'files',
  },
});

const HeaderRowContainer = styled.div`
  display: block;

  @media (min-width: ${sizes.large}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  //background-color: pink;
`;

const FooterRowContainer = styled.div`
  display: block;

  @media (min-width: ${sizes.large}px) {
    display: flex;
    justify-content: space-between;
  }

  //background-color: pink;
`;

const TagsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
`;

const TagDiv = styled.div`
  margin-bottom: 4px;
  margin-right: 4px;
  display: flex;
  align-items: flex-end;
`;

const SummaryContainer = styled.div`
  display: flex;
  //background-color: lightgreen;
  align-items: center;
  margin-bottom: 4px;
`;

const SummaryText = styled.div`
  display: inline-block;

  font-size: 0.625em;
  line-height: 1em;
  font-weight: bold;
  font-family: Lato, sans-serif;
  color: ${Colors.BLACK};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::after {
    content: '|';
    width: 1.25em;
    height: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    color: ${Colors.BLUE};
    font-weight: normal;
  }

  &:last-child:after {
    content: '';
    margin-left: 0;
    margin-right: 0;
  }
`;

const TitleDiv = styled.div`
  flex: 2 2 0px;

  //background-color: aquamarine;
  overflow: hidden;
`;

const CardDescription = styled.div`
  color: ${props => (props.color ? props.color : Colors.BLACK)};
  font-family: 'Lato', sans-serif;
  font-size: 0.875em; // 14px
  line-height: 1.375em; // 22px
  height: 6em;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const FollowContainer = styled.div`
  //flex: 1 1 0px;
  flex: 0 0 auto; // no shrink or expand, size = initial

  margin-left: 0.5em;

  display: flex;
  @media (max-width: ${sizes.large}px) {
    max-width: ${(props: IDiscussionCardRenderProps) =>
      props.topicItemPageMode ? '1em' : undefined};
    float: right;
  }
  justify-content: flex-end;
  align-items: center;

  //background-color: yellow;
`;
const ClapDiv = styled.div`
  display: flex;
  margin-top: 0.5em;
  margin-left: 0.25em;
  margin-right: 0.5em;
  align-items: center;
`;
/**
 * Card, used in DiscussionList
 */
class DiscussionCardComponent extends React.PureComponent<
  IDiscussionCardProps &
    IDiscussionCardRenderProps &
    IDiscussionCardDispatch &
    InjectedIntlProps,
  IDiscussionCardState
> {
  private discussionRef: RefObject<any> = null;

  constructor(props: any) {
    super(props);
    this.discussionRef = React.createRef();
  }

  componentDidMount() {
    if (this.discussionRef.current) {
      let module = this.discussionRef.current;
      clamp(module, { clamp: 4 });
    }
  }

  handleClickFollow = (id: string, follow: boolean) => {
    safeInvokeDeprecated(this.props.onFollow, id, follow);
  };

  // componentWillReceiveProps(nextProps: IDiscussionCardProps) {
  //   console.log(nextProps);
  // }

  render() {
    const { item, isYou, intl, style } = this.props;

    let topics: any[] = [];

    if (item && item.topics) {
      item.topics.map(topic => {
        topics.push(topic);
      });
    }

    topics.sort(function(a: Topic, b: Topic) {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return (
      <LinkWrapper to={'/forum/public/' + item.uri}>
        <CardContainer style={style} canHover={true}>
          <CardLeftContainerNormal
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <UserSummary
                userImage={item.createdBy.picture}
                name={item.createdBy.name}
                role={item.createdBy.role || ''}
                organizationImage={
                  (item.createdBy.organization &&
                    item.createdBy.organization.organizationImage) ||
                  ''
                }
                organizationName={
                  (item.createdBy.organization &&
                    item.createdBy.organization.name) ||
                  ''
                }
              />
              {!this.props.topicItemPageMode && (
                <ClapDiv>
                  <MunikumIcons.Clap
                    style={{ height: '1.3em', width: '1.3em' }}
                    fill={item.isLiked ? Colors.RED : '#333'}
                  />
                  <div
                    style={{
                      color: '#333333',
                      fontFamily: 'Lato, sans-serif',
                      fontSize: '12px',
                      lineHeight: '15px',
                      marginLeft: '1.4em',
                    }}
                  >
                    {item.likes} {intl.formatMessage(commonMessages.claps)}
                  </div>
                </ClapDiv>
              )}
            </div>
            {!this.props.topicItemPageMode && (
              <CardLastUpdated style={{}}>
                <FormattedMessage
                  id={'DiscussionCard.lastActivity'}
                  defaultMessage={'Last activity'}
                />
                :{' '}
                {moment
                  .utc(item.lastUpdated)
                  .local()
                  .format('YYYY-MM-DD')}
              </CardLastUpdated>
            )}
          </CardLeftContainerNormal>
          <CardContentContainer style={{ padding: '1.25em 2em' }}>
            <div>
              <HeaderRowContainer>
                <TitleDiv>
                  <CardTitle>{item.title}</CardTitle>
                </TitleDiv>
                <FollowContainer
                  topicItemPageMode={this.props.topicItemPageMode}
                >
                  <Clap
                    idToRefetch={item.id}
                    idToClap={item.id}
                    isClapped={item.isLiked}
                    ObjectToClap={ObjectType.DISCUSSION}
                  />
                  <FollowButton2
                    style={{
                      alignSelf: 'flex-start',
                      marginLeft: '.5em',
                    }}
                    followId={item.id}
                    isFollowing={item.isFollowing}
                    buttonType={ButtType.STAR}
                    followType={MyType.DISCUSSION}
                  />
                </FollowContainer>
              </HeaderRowContainer>
              {!this.props.topicItemPageMode && (
                <CardDescription
                  ref={this.discussionRef}
                  style={{ fontSize: '16px', lineHeight: '24px' }}
                >
                  {item.currentRevision.body && (
                    <MuPreview rawMarkdown={item.currentRevision.body} />
                  )}
                </CardDescription>
              )}
              <FooterRowContainer
                style={{
                  marginTop: this.props.topicItemPageMode ? '1em' : 0,
                }}
              >
                {!this.props.topicItemPageMode && (
                  <TagsDiv>
                    {topics &&
                      topics.map(topic => {
                        return (
                          <TagDiv key={topic.id}>
                            <Tag
                              text={topic.title}
                              hasShadow={true}
                              color={Colors.DARKBLUE}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                myHistory.push('/topic/' + topic.id);
                              }}
                              uppercase={false}
                            />
                          </TagDiv>
                        );
                      })}
                  </TagsDiv>
                )}

                {this.props.topicItemPageMode && (
                  <MediaQuery query={'(min-width: ' + 1200 + 'px)'}>
                    <CardLastUpdated style={{}}>
                      <FormattedMessage
                        id={'DiscussionCard.lastActivity'}
                        defaultMessage={'Last activity'}
                      />
                      :{' '}
                      {moment
                        .utc(item.lastUpdated)
                        .local()
                        .format('YYYY-MM-DD')}
                    </CardLastUpdated>
                  </MediaQuery>
                )}

                <SummaryContainer>
                  <SummaryText>
                    {item.comments.length}{' '}
                    {intl.formatMessage(messages.answers)}
                  </SummaryText>
                  {!this.props.topicItemPageMode && (
                    <SummaryText>{item.views} visninger</SummaryText>
                  )}
                  {this.props.topicItemPageMode && (
                    <MediaQuery query={'(min-width: ' + 1420 + 'px)'}>
                      <SummaryText>{item.views} visninger</SummaryText>
                    </MediaQuery>
                  )}
                  <SummaryText>
                    {item.followers.length}{' '}
                    {intl.formatMessage(messages.followers)}
                  </SummaryText>
                  {this.props.topicItemPageMode && (
                    <SummaryText>
                      {item.likes} {intl.formatMessage(commonMessages.claps)}
                    </SummaryText>
                  )}

                  {/*<SummaryText>*/}
                  {/*5 filer*/}
                  {/*</SummaryText>*/}
                </SummaryContainer>
              </FooterRowContainer>
            </div>
          </CardContentContainer>
        </CardContainer>
      </LinkWrapper>
    );
  }
}

const DiscussionCard = injectIntl(DiscussionCardComponent);

export default DiscussionCard;
