import * as React from 'react';
import styled from 'styled-components';
import { FieldArray, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import * as _ from 'lodash';
import moment from 'moment';
import gql from 'graphql-tag';
import { TodoCard } from './TodoCard';
import { BreadCrumb, BreadCrumbs } from './BreadCrumbs';
import { MunikumIcons } from '../common/icons';
import {
  ContentStatus,
  CreateCalendarRingMutationVariables,
  CreateEventInput,
  CreateTaskInput,
  CreateTodoTemplateInput,
  Person,
  TaskTypeEnum,
  YearWheelMyCalendars,
} from '../../models/types';
import { DateTimePicker } from '../basic/DateTimePicker/DateTimePicker';
import { Button } from '../basic';
import { DialogBox } from '../layout/Box/Box';
import { FieldGroup } from '../basic/FieldGroup/FieldGroup';
import {
  getReminderDate,
  getReminderIntervalDropdown,
  ReminderDropdown,
  ReminderOptions,
} from './ReminderDropdown';
import { CreateCard } from './CreateCard';
import {
  IRepetitionData,
  RepetitionPicker,
} from '../basic/RepetitionPicker/RepetitionPicker';
import {
  ColorTheme,
  isReallyEmpty,
  safeInvoke,
  safeInvokeDeprecated,
} from '../common';
import { CalendarsDropdown } from './CalendarsDropdown';
import { ADD_TASK, CreateTaskMutation } from './CreateTaskCollectionForm';
import {
  StatusMessageDialog,
  ThemeColor,
} from '../basic/StatusMessage/StatusMessage';
import {
  CREATE_CALENDAR_RING,
  YEAR_WHEEL,
} from '../../containers/AnnualCycle/calendar-queries';
import { commonLabels } from '../language/commonLabels';
import { SelectMode } from '../basic/DropDownSelectUltimate/UltimateDropdown';
import { FormLabel } from '../basic/FormLabel/FormLabel';
import { AnnualCycleAddMembersCard } from './AnnualCycleAddMembersCard';
import { MuContentEditable } from '../basic/MuEditor/MuContentEditable';
import { PageTitle } from '../layout/Page/Page';
import { H4 } from '../basic/Structural/typography';
import { commonMessages } from '../language/commonMessages';
import { sizes } from '../common/media';
import { Checkbox } from '../basic/Checkbox/Checkbox';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import { uploadFile } from '../../services/fileservice';
import { MuTextField } from '../basic/MuEditor/MuTextField';
import { convertFromRaw } from 'draft-js';

const uuidv1 = require('uuid/v1');

const messages = defineMessages({
  addChecklistPlaceholder: {
    id: 'CreateEventForm.addChecklistPlaceholder',
    defaultMessage: 'Add new point to checklist',
  },
  writeTitleHere: {
    id: 'CreateEventForm.writeTitleHere',
    defaultMessage: 'Write title here...',
  },
  yearWheelisRequired: {
    id: 'CreateEventForm.yearWheelisRequired',
    defaultMessage: 'Calendar is required',
  },
  checklistTitle: {
    id: 'CreateEventForm.checklistTitle',
    defaultMessage: 'Checkpoints',
  },
  selectAnnualCycle: {
    id: 'CreateEventForm.selectAnnualCycle',
    defaultMessage: 'Select annual cycle',
  },
  CreateAnotherActivity: {
    id: 'CreateEventForm.CreateAnotherActivity',
    defaultMessage: 'Create another',
  },
  Responsible: {
    id: 'CreateEventForm.Responsible',
    defaultMessage: 'Responsible:',
  },
});
const EmailSuccessCont = styled.div`
  display: ${(props: { isSuccess: boolean }) =>
    props.isSuccess ? 'flex' : 'none'};
  position: absolute;
  top: 50%;
  right: 20%;
`;
const CustomDialogBox = styled(DialogBox)`
  padding: 0px;
  position: relative;
`;

const Form = styled.div`
  // NOTE: changed from form to div so we can use this as subform...
  display: flex;

  @media (max-width: ${1100}px) {
    display: block;
    overflow-y: auto;
    max-height: 90vh;
  }
  justify-content: space-between;
`;
const EndDateDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${1100}px) {
    display: block;
  }
`;
const LeftContainer = styled.div`
  box-sizing: border-box;
  flex: 0 0 auto;
  max-width: 555px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
  background-color: #fff;
  //padding: 1.5em 1.875em;
  padding: 1.875em 1.5em 1.875em 1.875em;
`;

const RightContainer = styled.div`
  flex: 0 0 auto;
  box-sizing: border-box;
  padding: 3.5em 1.875em 1.875em;
  background-color: #efefef;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const Butts = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const CheckBoxDiv = styled.div`
  display: flex;
  align-items: center;
`;
/* component ResponibleContainer is also used on editeventinstance */
export const ResponibleContainer = styled.div`
  display: block;
  margin-bottom: 1em;
  margin-top: 0.5em;
`;
const SmallTitle = styled.div`
  color: #333333;
  font-size: 0.875em;
  font-weight: bold;
  padding-bottom: 0.5em;
  font-family: 'Lato', sans-serif;
`;

const CalendarDiv = styled.div`
     width: 100%;
  //  display: flex;
  //  align-items: center;
  //
  // @media (min-width: ${sizes.large}px) {
  // margin-left: 2em;
  //  width: 100%;
  // }
  // @media (min-width: ${sizes.larger}px) {
  //    width: 100%;
  //      margin-left: 0;
  // }
`;

const Error = styled.div`
  color: #ff5c39;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Lato, sans-serif;
`;

interface IEventFormProps {
  /**
   * lookup data for calendarDropdown
   */
  myCalendars?: Array<YearWheelMyCalendars>;

  /**
   * this is set when opening this component as a subcomponent of CreateTaskCollectionForm..
   */
  defaultValues?: CreateEventInput;

  /**
   * this is set when opening this component as a subcomponent of CreateTaskCollectionForm..
   */
  attachedTaskValues?: CreateTaskInput;

  /**
   * need this for refetch variables . ..
   */
  year: number;
  userHash?: string;

  /**
   * User clicks cancel
   */
  onCancel?: () => void;

  // error?: () => void;

  /**
   * if this is set, CreateEventInput is dispatched here.
   * if NOT, then mutation is run with create 'simple task'...
   */
  onSubmit?: (form: ICreateEventFormViewModel) => void;

  /**Id gotten from create annual cycle if user chooses to create new task after creating annulalcycle
   * on the successmessagecomp
   */
  newCalendarId?: string;
  disabledCalendarDropDown?: boolean;
  onSubmitCalendarPage?: (input: any, files) => void;

  /**
   * if were creating a simple task, we dispatch this when mutation is successful
   */
  onSaveSuccessful?: () => void;

  activeYearwheels?: Array<any>;

  calendarPage: boolean;
}

interface IEventFormState {
  showError: boolean;
  debug: boolean;
  calendar: string;
  calendarDirty: boolean;
  hasUserSelectedCreateANew: boolean;
  // freq: Frequency | 'none';
  repData: IRepetitionData;
  isSuccess: boolean;
  year: number;
  showFieldErrorCalendar: boolean;
  orgEndDate: Date;
  reminderItem: ReminderOptions;
  files: Array<any>;
}

/**
 * Extending interface with extra data that we need..
 *
 * TODO:
 * - use @client directive?
 * - remove interface, add local state/prop?
 *
 */
// interface ICreateEventForm extends CreateEventInput {
//   calendar: string;
// }

export interface ICreateEventFormViewModel {
  form: CreateEventInput;
  calendar: string;
}

const GET_ACTIVE_YEAR_WHEELS = gql`
  query GetActiveYearWheels($person: ID!) {
    yearWheel(person: $person) {
      id
    }
  }
`;

/**
 * CreateEvent form
 * this is used both standalone + as part of createtaskcollection form . . . . .
 *
 */
class CreateEventFormComp extends React.PureComponent<
  IEventFormProps & InjectedIntlProps,
  IEventFormState
> {
  constructor(props: IEventFormProps & InjectedIntlProps) {
    super(props);
    this.state = {
      showError: false,
      debug: false,
      isSuccess: false,
      calendar: this.props.newCalendarId || '',
      calendarDirty: false,
      year: props.year || new Date().getFullYear(),
      hasUserSelectedCreateANew: false,
      orgEndDate: new Date(),
      showFieldErrorCalendar: false,
      files: [],
      reminderItem: ReminderOptions.NONE,
      repData: {
        freq: 'none',
      },
    };
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: IEventFormProps & InjectedIntlProps
  ) {
    if (
      nextProps.attachedTaskValues &&
      nextProps.attachedTaskValues.calendar !== this.state.calendar
    ) {
      this.setState({
        calendar: nextProps.attachedTaskValues.calendar,
      });
    }

    // console.log('willSMithWillReceive');
    // if (nextProps.attachedTaskValues !== undefined && this.props.attachedTaskValues !== undefined && nextProps.attachedTaskValues.calendar !== this.props.attachedTaskValues.calendar) {
    //   console.log('received UPDATED attachedTaskValues, but does FORMIK recognize this?');
    //
    //   // TODO: either force formik re-render here, or just remount in coillectionform?
    //
    // }
  }

  render() {
    const { intl } = this.props;
    const me = this.props.userHash;

    return (
      <CreateTaskMutation
        mutation={ADD_TASK}
        refetchQueries={[
          {
            query: YEAR_WHEEL,
            variables: {
              year: this.props.year,
              person: this.props.userHash,
            },
          },
        ]}
        onError={() =>
          this.setState({
            showError: true,
          })
        }
      >
        {(addTask, { data, loading, called, error }) => {
          const myDefault: CreateEventInput = {
            title: '',

            description: '',
            startDate: new Date(),
            endDate: new Date(),
            // reminder: ReminderOptions.NONE,
            responsible: me || undefined,
            status: ContentStatus.PUBLISHED,
            // calendar: ''
            reminderDate: null,
          };

          const mergedDefaults = Object.assign(
            {},
            myDefault,
            this.props.defaultValues
          );

          const caldal = this.state.calendar;

          return (
            <CustomDialogBox>
              <Formik
                validateOnBlur={true}
                validationSchema={Yup.object().shape({
                  title: Yup.string()
                    .label(intl.formatMessage(commonMessages.title))
                    .required(),
                  responsible: Yup.string()
                    .label(intl.formatMessage(messages.Responsible))
                    .required(),
                  // calendar: Yup.string()
                  //   .label(intl.formatMessage(commonMessages.Ca))
                  //   .required(),
                })}
                // validate={(values) => {
                //   console.log('custom validation too?');
                //   if (caldal === '' || caldal === undefined) {
                //     return {
                //       calendar: 'calendar is mandatory'
                //     };
                //   }
                //   return {
                //   };
                // }}
                onSubmit={async (values: CreateEventInput, formikBag: any) => {
                  const taskInput: CreateTaskInput = {
                    calendar: this.state.calendar,
                    title: values.title, // task.title = same as event.title
                    description: values.description, // task.description = same as event.tite
                    descriptionText: values.descriptionText, // task.description = same as event.tite
                    status: ContentStatus.PUBLISHED,
                    events: [values],
                    type: TaskTypeEnum.SINGLE,
                  };

                  // if Coming from collection form, just dispatch save event with CreateEventInput data
                  if (!this.state.calendar) {
                  } else {
                    if (this.props.defaultValues !== undefined) {
                      // console.log(
                      //   'sending data back to CreateTaskCollecitonForm..',
                      //   values
                      // );
                      safeInvokeDeprecated(this.props.onSubmit, {
                        calendar: this.state.calendar,
                        form: values,
                      });
                    } else {
                      if (!this.props.calendarPage) {
                        let isCalendarAlreadyActive = false;

                        this.props.activeYearwheels.forEach(calendar => {
                          if (calendar.order == this.state.calendar) {
                            isCalendarAlreadyActive = true;
                          }
                        });
                        // if calendar is not selected create a ring
                        if (!isCalendarAlreadyActive) {
                          let mutationVariables: CreateCalendarRingMutationVariables = {
                            input: {
                              calendar: this.state.calendar,
                              order: +this.state.calendar,
                            },
                          };

                          const result1 = await myApolloClient.mutate({
                            mutation: CREATE_CALENDAR_RING,
                            variables: mutationVariables,
                            refetchQueries: [
                              {
                                query: YEAR_WHEEL,
                                variables: {
                                  year: this.props.year,
                                  person: this.props.userHash,
                                },
                              },
                            ],
                            awaitRefetchQueries: true, // <== Mathias this fixed the bug with
                          });
                        }
                        const result2 = await addTask({
                          variables: {
                            input: taskInput,
                          },
                          refetchQueries: [
                            {
                              query: YEAR_WHEEL,
                              variables: {
                                year: this.props.year,
                                person: this.props.userHash,
                              },
                            },
                          ],
                          awaitRefetchQueries: true, // <== Mathias this fixed the bug with
                        });

                        const freshId =
                          result2 &&
                          result2.data.createTask &&
                          result2.data.createTask.events[0].id;

                        for (let i = 0; i < this.state.files.length; i++) {
                          if (
                            this.state.files[i].file !== null &&
                            this.state.files[i].file !== null
                          ) {
                            try {
                              const uploadResult = await uploadFile(
                                this.state.files[i].file!,
                                freshId,
                                this.state.files[i].description,
                                p => console.log('upload progress:' + freshId)
                              );
                              if (!uploadResult.greatSuccess) {
                                // console.warn('Oops, upload failed: ' + files2[i].filename);
                                // NOTE: we could take some action here? Now we just continue to the next file...
                              }
                            } catch (uploadErr) {
                              console.log('upload err', uploadErr);
                            }
                          }
                        }
                      } else {
                        safeInvoke(
                          this.props.onSubmitCalendarPage,
                          taskInput,
                          this.state.files
                        );
                      }

                      if (this.state.hasUserSelectedCreateANew) {
                        this.setState(
                          {
                            isSuccess: true,
                          },
                          () => {
                            setTimeout(() => {
                              this.setState({
                                isSuccess: false,
                              });
                            }, 1000 * 3);
                          }
                        );

                        formikBag.resetForm({
                          title: '',
                          description: '',
                          descriptionText: '',
                          startDate: values.endDate,
                          endDate: values.endDate,
                          reminderDate: values.reminderDate,
                          status: values.status,
                          repetitionDefinition: null,
                          endRepetitionDate: null,
                          responsible: values.responsible,
                        });
                        this.setState({
                          repData: {
                            freq: 'none',
                            repetitionDefinition: null,
                            startRepetitionDate: null,
                            endRepetitionDate: null,
                          },
                        });
                      } else {
                        safeInvoke(this.props.onSaveSuccessful);
                      }
                    }
                  }
                }}
                initialValues={mergedDefaults}
                render={(formikProps: FormikProps<CreateEventInput>) => {
                  // console.log('render CreateEventForm formik render prop', formikProps.values.calendar);

                  // console.log('rendrer', this.state.calendar.length, formikProps.dirty.toString(), this.state.calendarDirty.toString());

                  const selectedCalendar = _.find(
                    this.props.myCalendars,
                    cal => cal.id === this.state.calendar
                  );
                  const yearWheelColor =
                    selectedCalendar && selectedCalendar.color;

                  const isSubform = this.props.defaultValues !== undefined;

                  if (this.state.showError && error) {
                    return (
                      <StatusMessageDialog
                        theme={ThemeColor.ERROR_BLACK}
                        title={'Error'}
                        text={'An error occured: ' + error.message}
                        buttonText={'Dismiss'}
                        onClickButton={() =>
                          this.setState({
                            showError: false,
                          })
                        }
                      />
                    );
                  }

                  const myReminderDate = getReminderIntervalDropdown(
                    intl,
                    formikProps.values.endDate,
                    formikProps.values.reminderDate || undefined
                  );
                  return (
                    <div style={{ display: 'flex' }}>
                      <Form>
                        <LeftContainer>
                          <BreadCrumbs>
                            {isSubform && (
                              <BreadCrumb
                                leftIcon={MunikumIcons.Folder}
                                onClick={() => {
                                  // console.log(
                                  //   'navigate back to createcollectionview IF coming from there...'
                                  // );
                                  // TODO: add warning, are you sure you want to go back without saving your changes?
                                  safeInvokeDeprecated(this.props.onCancel);
                                }}
                              >
                                {this.props.attachedTaskValues &&
                                  this.props.attachedTaskValues.title}
                              </BreadCrumb>
                            )}

                            <BreadCrumb
                              isDisabled={true}
                              leftIcon={MunikumIcons.SingleTask}
                            >
                              {formikProps.values.title &&
                              formikProps.values.title.length > 0 &&
                              formikProps.values.title !== '\u200B' ? (
                                formikProps.values.title
                              ) : (
                                <FormattedMessage
                                  id={'CreateEventForm.activity'}
                                  defaultMessage={'Activity'}
                                />
                              )}
                            </BreadCrumb>
                          </BreadCrumbs>
                          <div
                            style={{
                              marginTop: '1em',
                            }}
                          >
                            <FieldGroup>
                              <FormLabel htmlFor={'title'}>Tittel</FormLabel>
                              <PageTitle>
                                <MuContentEditable
                                  id={'title'}
                                  name={'title'}
                                  canEdit={!loading} // TODO: add access rights here.. disable if your user cannot edit!
                                  isMultiLine={false}
                                  value={formikProps.values.title}
                                  onSubmit={(md: string) => {
                                    if (!isReallyEmpty(md)) {
                                      let text = md.replace(
                                        /(\r\n|\n|\r)/gm,
                                        ''
                                      );
                                      formikProps.setFieldValue('title', text);
                                    }

                                    // prevents hidden sign in string
                                    if (isReallyEmpty(md)) {
                                      formikProps.setFieldValue('title', '');
                                    }
                                  }}
                                  style={{
                                    width: '100%',
                                    // prevented overflow on border
                                    margin: '.1px',
                                    border:
                                      formikProps.touched.title &&
                                      formikProps.errors.title
                                        ? '1px solid #ff5c39'
                                        : 'none',
                                  }}
                                  placeholder={intl.formatMessage(
                                    commonLabels.titlePlaceholder
                                  )}
                                />
                              </PageTitle>
                              <Error style={{ marginTop: '.5em' }}>
                                {formikProps.touched.title &&
                                  formikProps.errors.title && (
                                    <MunikumIcons.Cancel
                                      style={{ marginRight: '.25em' }}
                                    />
                                  )}

                                {formikProps.touched.title &&
                                  formikProps.errors.title}
                              </Error>
                            </FieldGroup>
                            {/*<TextField*/}
                            {/*label={intl.formatMessage(commonLabels.title)}*/}
                            {/*name={'title'}*/}
                            {/*value={formikProps.values.title}*/}
                            {/*onBlur={formikProps.handleBlur}*/}
                            {/*onChange={formikProps.handleChange}*/}
                            {/*error={formikProps.touched.title && formikProps.errors.title}*/}
                            {/*style={{*/}
                            {/*width: '100%'*/}
                            {/*}}*/}
                            {/*/>*/}

                            <MuTextField
                              shouldEditorHaveFiles={true}
                              doIHaveAccessToAddFiles={true}
                              label={intl.formatMessage(
                                commonMessages.description
                              )}
                              placeholder={intl.formatMessage(
                                commonMessages.writeHerePlaceholder
                              )}
                              files={[]}
                              onSubmit={(value, files) => {
                                formikProps.setFieldValue('description', value);
                                formikProps.setFieldValue(
                                  'descriptionText',
                                  convertFromRaw(
                                    JSON.parse(value)
                                  ).getPlainText()
                                );
                                this.setState({
                                  files: files,
                                });
                              }}
                              value={formikProps.values.description}
                            />
                            <div style={{ width: '99.5%' }}>
                              {me && (
                                <ResponibleContainer>
                                  <SmallTitle>
                                    {intl.formatMessage(messages.Responsible)}
                                  </SmallTitle>
                                  <AnnualCycleAddMembersCard
                                    showOnlyPerson={true}
                                    onTagClose={() => {
                                      formikProps.setFieldValue(
                                        'responsible',
                                        ''
                                      );
                                    }}
                                    // tagMaxWidth={{width: '100%'}}
                                    showTag={true}
                                    title={intl.formatMessage(
                                      messages.Responsible
                                    )}
                                    addMember={(person: Person) => {
                                      formikProps.setFieldValue(
                                        'responsible',
                                        person.id
                                      );
                                    }}
                                  />
                                </ResponibleContainer>
                              )}
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {/*<DateTimePicker*/}
                              {/*label={'Start'}*/}
                              {/*pickDate={true}*/}
                              {/*pickTime={false}*/}
                              {/*closeOnSelect={true}*/}
                              {/*onChange={(selectedDate: Date) => {*/}
                              {/*formikProps.setFieldValue(*/}
                              {/*'startDate',*/}
                              {/*selectedDate*/}
                              {/*);*/}
                              {/*if (*/}
                              {/*moment(selectedDate).isAfter(*/}
                              {/*moment(formikProps.values.endDate)*/}
                              {/*)*/}
                              {/*) {*/}
                              {/*const fresh = moment(selectedDate)*/}
                              {/*.subtract(0, 'days')*/}
                              {/*.toDate();*/}
                              {/*formikProps.setFieldValue('endDate', fresh);*/}
                              {/*}*/}
                              {/*this.setState({*/}
                              {/*orgStartDate: _.cloneDeep(selectedDate),*/}
                              {/*});*/}
                              {/*}}*/}
                              {/*onSave={(selectedDate: Date) => {*/}
                              {/*formikProps.setFieldValue(*/}
                              {/*'startDate',*/}
                              {/*selectedDate*/}
                              {/*);*/}
                              {/*}}*/}
                              {/*value={formikProps.values.startDate}*/}
                              {/*// onBlur={formikProps.handleBlur}*/}
                              {/*name={'startDate'}*/}
                              {/*disabled={loading}*/}
                              {/*/>*/}
                              <CalendarDiv>
                                <CalendarsDropdown
                                  name={'calendar'}
                                  disabled={
                                    this.props.disabledCalendarDropDown ||
                                    isSubform ||
                                    loading
                                  } // we cant change calendar when were editing a sub-event.. we can edit in prev screen..
                                  disableAssigned={true}
                                  label={intl.formatMessage(
                                    messages.selectAnnualCycle
                                  )}
                                  onBlur={() => {
                                    this.setState({
                                      calendarDirty: true,
                                    });
                                  }}
                                  selectMode={SelectMode.SINGLE}
                                  onSelected={selectedItem => {
                                    // formikProps.setFieldValue(
                                    //   'calendar',
                                    //   selectedItem.id
                                    // );
                                    // if (isSubform)

                                    this.setState(
                                      {
                                        showFieldErrorCalendar: false,
                                        calendar: selectedItem.id,
                                        calendarDirty: true,
                                      },
                                      () => {
                                        // formikProps.validateField('calendar');
                                      }
                                    );
                                  }}
                                  style={{
                                    // width: '100%',
                                    border: this.state.showFieldErrorCalendar
                                      ? '1px solid #ff5c39'
                                      : 'none',
                                  }}
                                  selectedItems={
                                    selectedCalendar ? [selectedCalendar] : []
                                  }
                                  // error={
                                  //   this.state.calendar.length === 0 &&
                                  //   formikProps.dirty &&
                                  //   this.state.calendarDirty
                                  //     ? intl.formatMessage(
                                  //         messages.yearWheelisRequired
                                  //       )
                                  //     : undefined
                                  // }
                                  // error={formikProps.errors.calendar && }
                                />
                                {this.state.showFieldErrorCalendar && (
                                  <Error
                                    style={{
                                      marginTop: '-.2em',
                                      marginBottom: '.5em',
                                    }}
                                  >
                                    {formikProps.touched.title &&
                                      formikProps.errors.title && (
                                        <MunikumIcons.Cancel
                                          style={{ marginRight: '.25em' }}
                                        />
                                      )}
                                    {intl.formatMessage(
                                      commonMessages.fieldErrorCalendar
                                    )}
                                  </Error>
                                )}
                              </CalendarDiv>
                            </div>
                            <EndDateDiv>
                              <DateTimePicker
                                label={'Frist'}
                                disabled={loading}
                                pickDate={true}
                                pickTime={false}
                                closeOnSelect={true}
                                fieldGroupStyle={{ marginRight: '.5em' }}
                                onChange={(selectedDate: Date) => {
                                  formikProps.setFieldValue(
                                    'endDate',
                                    selectedDate
                                  );
                                  this.setState({
                                    orgEndDate: _.cloneDeep(selectedDate),
                                  });
                                  if (
                                    moment(selectedDate).isBefore(
                                      moment(formikProps.values.startDate)
                                    )
                                  ) {
                                    const fresh = moment(selectedDate)
                                      .subtract(0, 'days')
                                      .toDate();
                                    // console.log('setting start to: ', fresh);

                                    formikProps.setFieldValue(
                                      'startDate',
                                      fresh
                                    );
                                  }
                                }}
                                onSave={(selectedDate: Date) => {
                                  if (formikProps.values.reminderDate) {
                                    const calculatedDate = getReminderDate(
                                      selectedDate,
                                      this.state.reminderItem
                                    );
                                    formikProps.setFieldValue(
                                      'reminderDate',
                                      calculatedDate
                                    );
                                  }
                                  this.setState({
                                    repData: {
                                      ...this.state.repData,
                                      startRepetitionDate: selectedDate,
                                    },
                                  });
                                  formikProps.setFieldValue(
                                    'endDate',
                                    selectedDate
                                  );
                                  formikProps.setFieldValue(
                                    'startDate',
                                    selectedDate
                                  );
                                }}
                                value={formikProps.values.endDate}
                                name={'endDate'}
                                outerStyle={
                                  {
                                    // marginLeft: '.5em',
                                  }
                                }
                              />
                              <ReminderDropdown
                                disabled={loading}
                                name={'reminderDate'}
                                leftIcon={MunikumIcons.Reminder}
                                onBlur={formikProps.handleBlur}
                                label={intl.formatMessage(
                                  commonLabels.reminder
                                )}
                                fieldGroupStyle={
                                  {
                                    // marginLeft: '1em',
                                  }
                                }
                                error={
                                  formikProps.touched.reminderDate &&
                                  formikProps.errors.reminderDate
                                }
                                onSelected={item => {
                                  this.setState(
                                    {
                                      reminderItem: item.id as ReminderOptions,
                                    },
                                    () => {
                                      const calculatedDate = getReminderDate(
                                        formikProps.values.endDate,
                                        item.id as ReminderOptions
                                      );
                                      // console.log(
                                      //   'setting reminderDate to: ',
                                      //   calculatedDate,
                                      //   formikProps.values.endDate
                                      // );
                                      formikProps.setFieldValue(
                                        'reminderDate',
                                        calculatedDate
                                      );
                                    }
                                  );
                                }}
                                selectedItems={
                                  (myReminderDate && [myReminderDate]) || []
                                }
                              />
                            </EndDateDiv>
                            <RepetitionPicker
                              orgEndDate={this.state.orgEndDate}
                              disabled={loading}
                              handleCancel={startDate => {
                                formikProps.setFieldValue(
                                  'startDate',
                                  startDate
                                );
                              }}
                              name={'repetitionDefinition'}
                              label={intl.formatMessage(
                                commonLabels.repetition
                              )}
                              handleRemoveRepetition={() => {
                                formikProps.setFieldValue(
                                  'repetitionDefinition',
                                  null
                                );
                                formikProps.setFieldValue(
                                  'endDate',
                                  formikProps.values.endDate
                                );
                                formikProps.setFieldValue(
                                  'startDate',
                                  formikProps.values.endDate
                                );
                                formikProps.setFieldValue(
                                  'endRepetitionDate',
                                  null
                                );
                                formikProps.setFieldValue(
                                  'repetitionDefinition',
                                  null
                                );
                                this.setState({
                                  repData: {
                                    freq: 'none',
                                    repetitionDefinition: null,
                                    startRepetitionDate: null,
                                    endRepetitionDate: null,
                                  },
                                });
                              }}
                              onSave={(repData: IRepetitionData) => {
                                console.log(repData);
                                this.setState(
                                  {
                                    repData: repData,
                                  },
                                  () => {
                                    if (repData.freq === 'none') {
                                      formikProps.setFieldValue(
                                        'endRepetitionDate',
                                        undefined
                                      );
                                      formikProps.setFieldValue(
                                        'repetitionDefinition',
                                        undefined
                                      );
                                    } else {
                                      formikProps.setFieldValue(
                                        'endDate',
                                        repData.startRepetitionDate
                                      );
                                    }
                                    formikProps.setFieldValue(
                                      'startDate',
                                      repData.startRepetitionDate
                                    );

                                    formikProps.setFieldValue(
                                      'endRepetitionDate',
                                      repData.endRepetitionDate
                                    );
                                    formikProps.setFieldValue(
                                      'repetitionDefinition',
                                      repData.repetitionDefinition
                                    );
                                    // TODO test if works
                                  }
                                );
                              }}
                              startDate={formikProps.values.endDate}
                              // currentUntilValue={
                              //   formikProps.values.endRepetitionDate
                              // }
                              value={this.state.repData}
                            />
                          </div>
                        </LeftContainer>
                        <RightContainer>
                          <div>
                            <H4>
                              {intl.formatMessage(messages.checklistTitle)}
                            </H4>

                            <FieldArray
                              name={'todos'}
                              render={arrayHelpers => {
                                return (
                                  <div
                                    style={{
                                      overflowY: 'auto',
                                      maxHeight: '300px',
                                    }}
                                  >
                                    <CreateCard
                                      disabled={loading}
                                      placeholder={intl.formatMessage(
                                        messages.addChecklistPlaceholder
                                      )}
                                      style={{
                                        marginTop: '1em',
                                      }}
                                      onSubmit={title => {
                                        const temp: CreateTodoTemplateInput = {
                                          title: title,
                                          // event: '', // TODO: this shoul not be mandatory when creating??
                                          status: ContentStatus.PUBLISHED,
                                        };

                                        arrayHelpers.insert(0, temp);
                                      }}
                                    />
                                    <div
                                      style={{
                                        marginTop: '1em',
                                      }}
                                    >
                                      {formikProps.values.todos &&
                                        formikProps.values.todos.map(
                                          (todo, index) => {
                                            const todoKey = uuidv1();

                                            return todo ? (
                                              <TodoCard
                                                checkBoxColor={yearWheelColor}
                                                onDeleteClicked={() => {
                                                  arrayHelpers.remove(index);
                                                }}
                                                canDelete={true}
                                                key={'todocard:_' + todoKey}
                                                todoName={todo.title}
                                                isCheckBoxVisible={false}
                                                style={{
                                                  marginBottom: '.5em',
                                                }}
                                              />
                                            ) : null;
                                          }
                                        )}
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </div>
                          <Butts>
                            {/*<Button*/}
                            {/*text={'Debug'}*/}
                            {/*theme={ColorTheme.Yellow}*/}
                            {/*onClick={() =>*/}
                            {/*this.setState({
                                        debug: !this.state.debug,
                                      })*/}
                            {/*}*/}
                            {/*/>*/}
                            <CheckBoxDiv>
                              <Checkbox
                                onClick={() => {
                                  this.setState({
                                    hasUserSelectedCreateANew: !this.state
                                      .hasUserSelectedCreateANew,
                                  });
                                }}
                              />
                              <div>
                                {intl.formatMessage(
                                  messages.CreateAnotherActivity
                                )}{' '}
                              </div>
                            </CheckBoxDiv>
                            <Button
                              text={intl.formatMessage(commonMessages.cancel)}
                              leftIcon={MunikumIcons.Cancel}
                              theme={ColorTheme.White}
                              onClick={() =>
                                safeInvokeDeprecated(this.props.onCancel)
                              }
                              style={{ marginLeft: '.5em' }}
                              disabled={loading}
                            />
                            <Button
                              isSuccess={this.state.isSuccess}
                              isLoading={loading}
                              text={intl.formatMessage(commonMessages.save)}
                              theme={ColorTheme.Red}
                              innerButtonStyle={{
                                justifyContent: this.state.isSuccess
                                  ? 'center'
                                  : 'inherit',
                              }}
                              // type={'submit'}
                              onClick={() => {
                                if (!this.state.calendar) {
                                  this.setState({
                                    showFieldErrorCalendar: true,
                                  });
                                }
                                setTimeout(() => {
                                  // timeout so editors looses focus and submits
                                  formikProps.submitForm();
                                }, 200);
                              }}
                              style={{ marginLeft: '.5em' }}
                              leftIcon={MunikumIcons.Save}
                              // disabled={
                              //   !formikProps.isValid ||
                              //   this.state.calendar.length === 0
                              // }
                            />
                          </Butts>
                        </RightContainer>
                      </Form>

                      {this.state.debug && (
                        <div style={{ display: 'flex' }}>
                          <pre
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Courier new',
                              marginLeft: '.5em',
                            }}
                          >
                            values: <br />
                            {JSON.stringify(formikProps.values, null, 2)}
                          </pre>
                          <pre
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Courier new',
                              marginLeft: '.5em',
                            }}
                          >
                            touched: <br />
                            {JSON.stringify(formikProps.touched, null, 2)}
                          </pre>
                          <pre
                            style={{
                              fontSize: '12px',
                              fontFamily: 'Courier new',
                              marginLeft: '.5em',
                            }}
                          >
                            errors: <br />
                            {JSON.stringify(formikProps.errors, null, 2)}
                          </pre>
                          <pre>
                            {JSON.stringify(
                              this.props.attachedTaskValues,
                              null,
                              2
                            )}
                          </pre>
                        </div>
                      )}
                    </div>
                  );
                }}
              />
            </CustomDialogBox>
          );
        }}
      </CreateTaskMutation>
    );
  }
}

export const CreateEventForm = injectIntl(CreateEventFormComp);
export default CreateEventForm;
