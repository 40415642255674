export const mentions = [
  {
    name: 'Petter Sødermann',
    link: 'https://sodermann.no/menneskene',
    avatar: 'http://sodermann.no/wp-content/uploads/2016/05/Petter-1.jpg',
  },
  {
    name: 'Hilde Langtangen',
    link: 'https://sodermann.no/menneskene',
    avatar: 'http://sodermann.no/wp-content/uploads/2016/05/hilde1.jpg',
  },
  {
    name: 'Kristoffer Bergset',
    link: 'https://sodermann.no/menneskene',
    avatar: 'http://sodermann.no/wp-content/uploads/2016/05/kristoffer.jpg',
  },
  {
    name: 'Tor-Kristian Gulhaugen',
    link: 'https://sodermann.no/menneskene',
    avatar: 'http://sodermann.no/wp-content/uploads/2016/05/tor-kristian.jpg',
  },
  {
    name: 'Alfred Bjørlo ',
    link: 'https://sodermann.no/menneskene',
    avatar:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Alfred_Bj%C3%B8rlo_%28cropped%29.jpg/250px-Alfred_Bj%C3%B8rlo_%28cropped%29.jpg',
  },
];
