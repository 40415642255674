import * as React from 'react';
import { CSSProperties } from 'react';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import * as _ from 'lodash';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import Helmet from 'react-helmet';
import { myHistory } from '../../index';
import { AllActionValuesInput, OrderEnum, SortEnum } from '../../models/types';
import { LinkWrapper } from '../../components/basic/Structural/links';
import { MunikumIcons } from '../../components/common/icons';
import { PageTitle } from '../../components/layout/Page/Page';
import Tour from 'reactour';

import { INewsItem, IWordpressResponse } from './DashboardPage';
import { commonMessages } from '../../components/language/commonMessages';
import { News } from '../../components/dashboard/News';
import {
  ContainerDashboard,
  IconDiv,
  LastUpdatedComp,
  LastUpdatedDiv,
  MainBox,
  OuterContainer,
} from './AnnualCycleDashboard';
import { DashboardBox2 } from '../../components/dashboard/DashboardBox2';
import { NewsSectionComp } from '../../components/dashboard/NewsSection';
import { Button } from '../../components/basic';
import { LastUpdated } from './lastUpdated';
import { Dispatch } from 'redux';
import {
  changeShowOnlyMyFollowedActionCreator,
  clientResetActionValuesFilterActionCreator,
} from '../../modules/actionValue/actionValue';
import { connect } from 'react-redux';
import { RootState } from '../../modules/rootReducer';
import { safeInvoke } from '../../components/common';
import { SuspenseLoader } from '../../components/basic/Loader/CircleSpinner';

const messages = defineMessages({
  whatDoYouWantToDo: {
    id: 'grants.whatDoYouWantToDo',
    defaultMessage: 'What do you want To do?',
  },
  explore: {
    id: 'grants.explore',
    defaultMessage: 'Explore',
  },
  exploreText: {
    id: 'grants.exploreText',
    defaultMessage: 'Overview of grant and refund schemes.',
  },
  news: {
    id: 'grants.news',
    defaultMessage: 'News',
  },
  recomended: {
    id: 'grants.recomended',
    defaultMessage: 'Recomended',
  },
  yourFollowed: {
    id: 'grants.yourFollowed',
    defaultMessage: 'Your followed',
  },
  shareGrantTip: {
    id: 'grants.shareGrantTip',
    defaultMessage: 'Share a grant tip',
  },
  shareGrantTipText: {
    id: 'grants.shareGrantTipText',
    defaultMessage: 'Send us a actionvalue that you want us to add',
  },
  myFollowedActionValues: {
    id: 'grants.myFollowedActionValues',
    defaultMessage: 'My followed action values',
  },
  myFollowedActionValuesText: {
    id: 'grants.myFollowedActionValuesText',
    defaultMessage: 'Here is a list for your followed actionvalues',
  },
  actionValueDashboardTourStep6: {
    id: 'actionValueDashboard.actionValueDashboardTourStep6',
    defaultMessage: 'Do you want to repeat the steps',
  },
});
export const MY_FOLLOWED_GRANTS = gql`
  query getMyFollowedActionValues {
    myFollowed {
      actionValues {
        id
        isFollowing
        lastUpdated
      }
    }
  }
`;
export const GET_GRANTS_FOR_DASHBOARD = gql`
  query GetActionValues($input: AllActionValuesInput!) {
    allActionValues(input: $input) {
      nodes {
        id
        uri
        lastUpdated
        title
        isFollowing
        dateCreated
      }
    }
    myFollowed {
      actionValues {
        id
        uri
        lastUpdated
        title
        isFollowing
        dateCreated
      }
    }
  }
`;
export const FOLLOW_GRANT = gql`
  mutation FollowGrant($id: ID!) {
    follow(id: $id)
  }
`;
export const UNFOLLOW_GRANT = gql`
  mutation UnfollowGrant($id: ID!) {
    unFollow(id: $id)
  }
`;

interface IActionvalueDashboardProps {
  style?: CSSProperties;
  list?: Array<any>;
  onClick?: (uri: string) => void;
  showOnlyMyFollowedActionvalues: boolean;
}
interface IActionValueDashboardState {
  items: Array<INewsItem>;
  isTourOpen: boolean;
}
interface IActionValueDashboardDispatch {
  changeShowOnlyMyFollowedGrants: (showOnlyMyFollowed: boolean) => void;
  handleExploreClick: () => void;
}
export class ActionValueDashboardComp extends React.PureComponent<
  IActionvalueDashboardProps &
    InjectedIntlProps &
    IActionValueDashboardDispatch,
  IActionValueDashboardState
> {
  constructor(
    props: IActionvalueDashboardProps &
      IActionValueDashboardDispatch &
      InjectedIntlProps
  ) {
    super(props);
    this.state = {
      items: [],
      isTourOpen: false,
    };
  }
  UNSAFE_componentWillMount() {
    let fThis = this;

    fetch('https://www.munikum.no/category/tilskudd/feed/json', {
      method: 'GET',
    }).then(
      function(response: Response) {
        response.text().then(value => {
          let result: IWordpressResponse = JSON.parse(value);
          fThis.setState({
            items: result.items,
          });
        });
      },
      function(error: Error) {
        console.log(error);
      }
    );
  }

  render() {
    const { intl } = this.props;
    const newsItems = _.sortBy(this.state.items, 'date_modified').reverse();
    newsItems.splice(5);
    const inputVariables: AllActionValuesInput = {
      limit: 4,
      sort: SortEnum.LAST_UPDATED,
      order: OrderEnum.DESCENDING,
    };

    const steps = [
      {
        position: 'right',
        selector: '[data-tut="reactour__step1"]',
        content: (
          <FormattedMessage
            id={'actionValueDashboard.ActionValueDashboardTourStep1'}
            defaultMessage={
              'Here is a list over the action-values you have followed'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step2"]',
        content: (
          <FormattedMessage
            id={'actionValueDashboard.ActionValueDashboardTourStep2'}
            defaultMessage={
              'Here is a list over all the action-values avalible.'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        position: 'right',

        selector: '[data-tut="reactour__step3"]',
        content: (
          <FormattedMessage
            id={'actionValueDashboard.ActionValueDashboardTourStep3'}
            defaultMessage={
              'Here you can see share tips about action-values, and we will review it and posible add it to the actionvalue list.'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step4"]',
        content: (
          <FormattedMessage
            id={'actionValueDashboard.ActionValueDashboardTourStep4'}
            defaultMessage={
              'The activity log gives you an overview over the last updated action-values witch you follow.'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        position: 'right',
        selector: '[data-tut="reactour__step5"]',
        content: (
          <FormattedMessage
            id={'actionValueDashboard.ActionValueDashboardTourStep5'}
            defaultMessage={
              'We at the Munikum team will make you aware of news related to the action-values. There may be new features or user stories.'
            }
            values={{
              br: <br />,
            }}
          />
        ),
      },
      {
        selector: '[data-tut="reactour__goTo"]',
        content: ({ goTo }) => (
          <div>
            {intl.formatMessage(commonMessages.doYouWantToRepeatTheSteps)}
            <div />
            <Button
              style={{ marginTop: '1em' }}
              onClick={() => goTo(0)}
              text={intl.formatMessage(commonMessages.repeat)}
            />
          </div>
        ),
      },
    ];
    return (
      <Query
        query={GET_GRANTS_FOR_DASHBOARD}
        variables={{ input: inputVariables }}
        fetchPolicy={'network-only'}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <SuspenseLoader />;
          }
          if (error) {
            return `Error! ${error.message}`;
          }
          const actionValues =
            data && data.allActionValues && data.allActionValues.nodes;

          const followedGrants =
            data && data.myFollowed && data.myFollowed.actionValues;
          let followedGrantsSorted = _.sortBy(
            followedGrants,
            'lastUpdated'
          ).reverse();
          return (
            <>
              <Helmet>
                <title>{intl.formatMessage(commonMessages.actionValues)}</title>
              </Helmet>
              <OuterContainer>
                <ContainerDashboard>
                  <MainBox style={{ margin: '4px' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1em',
                      }}
                    >
                      <IconDiv>
                        <MunikumIcons.Info
                          style={{
                            color: '#FF5C39',
                            width: '1.4em',
                            height: '1.4em',
                            marginRight: '.5em',
                          }}
                          onClick={() => {
                            this.setState({
                              isTourOpen: true,
                            });
                          }}
                        />
                      </IconDiv>

                      <PageTitle style={{}}>
                        {intl.formatMessage(commonMessages.actionValues)}
                      </PageTitle>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <LinkWrapper
                        data-tut="reactour__step1"
                        to={'/action-value/list'}
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(
                              this.props.changeShowOnlyMyFollowedGrants,
                              true
                            );
                          }}
                          title={intl.formatMessage(
                            messages.myFollowedActionValues
                          )}
                          body={intl.formatMessage(
                            messages.myFollowedActionValuesText
                          )}
                          icon={MunikumIcons.ActionValue}
                          isActive={true}
                          style={{
                            backgroundColor: '#00778B',
                          }}
                        />
                      </LinkWrapper>
                      <LinkWrapper
                        to={'/action-value/list'}
                        data-tut="reactour__step2"
                      >
                        <DashboardBox2
                          onClick={() => {
                            safeInvoke(this.props.handleExploreClick);
                          }}
                          title={intl.formatMessage(messages.explore)}
                          body={intl.formatMessage(messages.exploreText)}
                          icon={MunikumIcons.Search}
                          isActive={true}
                          style={{
                            backgroundColor: '#00778B',
                          }}
                        />
                      </LinkWrapper>
                      {/*<LinkWrapper*/}
                      {/*to={'/forum/list'}*/}
                      {/*data-tut="reactour__step3"*/}
                      {/*>*/}
                      {/*<DashboardBox2*/}
                      {/*title={intl.formatMessage(*/}
                      {/*commonMessages.mymunicipality*/}
                      {/*)}*/}
                      {/*body={intl.formatMessage(*/}
                      {/*messages.myMunicipalityBody*/}
                      {/*)}*/}
                      {/*icon={MunikumIcons.KostraEducation}*/}
                      {/*isActive={true}*/}
                      {/*style={{*/}
                      {/*backgroundColor: '#00778B',*/}
                      {/*}}*/}
                      {/*/>*/}
                      {/*</LinkWrapper>*/}
                      <a
                        data-tut="reactour__step3"
                        style={{
                          alignSelf: 'flex-start',
                          textDecoration: 'none',
                        }}
                        href={'mailto:support@munikum.no?subject=Tilskudstips'}
                      >
                        <DashboardBox2
                          title={intl.formatMessage(messages.shareGrantTip)}
                          body={intl.formatMessage(messages.shareGrantTipText)}
                          icon={MunikumIcons.lightbulb}
                          isActive={true}
                          style={{
                            backgroundColor: ' #00B0B9',
                          }}
                        />
                      </a>
                    </div>
                  </MainBox>
                  <LastUpdatedComp style={{ width: '100%', display: 'flex' }}>
                    <LastUpdatedDiv
                      data-tut="reactour__step4"
                      style={{ width: '100%', margin: '4px' }}
                    >
                      <LastUpdated
                        title={'Aktivitetslogg'}
                        list={actionValues}
                        icon={MunikumIcons.ArrowRight}
                        onClick={uri => myHistory.push('/action-value/' + uri)}
                        onSeeAllClick={() =>
                          myHistory.push('/action-value/list')
                        }
                      />
                    </LastUpdatedDiv>
                    {/*<HelpBox*/}
                    {/*style={{margin: '4px'}}*/}
                    {/*onClickHelp={() => {*/}
                    {/*this.setState({*/}
                    {/*isTourOpen: true,*/}
                    {/*});*/}
                    {/*}}*/}
                    {/*/>*/}
                  </LastUpdatedComp>
                  <div
                    style={{ margin: '4px', maxWidth: '69em' }}
                    data-tut="reactour__step5"
                  >
                    <NewsSectionComp news={this.state.items} />
                  </div>
                </ContainerDashboard>
              </OuterContainer>
              <Tour
                steps={steps}
                isOpen={this.state.isTourOpen}
                onRequestClose={() => {
                  this.setState({ isTourOpen: false });
                }}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor={'#5cb7b7'}
              />
            </>
          );
        }}
      </Query>
    );
  }
}
const mapStateToProps = (
  state: RootState,
  ownProps: IActionvalueDashboardProps
): IActionvalueDashboardProps => {
  return {
    showOnlyMyFollowedActionvalues:
      state.actionValue.clientList.filter.showOnlyMyFollowed,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<{}>
): IActionValueDashboardDispatch => ({
  changeShowOnlyMyFollowedGrants: (showOnlyMyFollowed: boolean) => {
    dispatch(changeShowOnlyMyFollowedActionCreator({ showOnlyMyFollowed }));
  },
  handleExploreClick: () => {
    dispatch(clientResetActionValuesFilterActionCreator({}));
  },
  // onNavigatedToPage: () => {
  //   console.log('did you just navigate to this page?');
  //   dispatch(clientResetActionValuesFilterActionCreator({}));
  // },
});

export const ActionValueDashboard: any = connect<
  IActionvalueDashboardProps,
  {},
  {}
>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ActionValueDashboardComp));
export default ActionValueDashboard;
