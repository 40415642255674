import { ApiError, getApiRequestAdvanced, parsedResponse } from './api';

export interface IAuthResponse {
  username: string;
  roles?: Array<string>;
  token_type: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
}

export interface ICheckEmailResponse {
  email: string;
  tooManyAttempts?: boolean;
  // not returned when tooManyAttempts = true
  valid?: boolean;
  newUser: true;
  challenge?: boolean;

  /**
   * check in api, if user has organization which is a municipality
   */
  isMunicipality?: boolean;
  organization?: {
    id: number;
    name: string;
  };
}

export async function requestEmailPinLogin(
  email: string
): Promise<ICheckEmailResponse> {
  const response = await getApiRequestAdvanced('/auth/email', 'POST', null, {
    email: email,
  });
  return await parsedResponse<ICheckEmailResponse>(response);
}

export async function refreshAccessToken(
  refreshToken: string
): Promise<IAuthResponse> {
  const response = await getApiRequestAdvanced(
    '/auth/access-token',
    'POST',
    null,
    {
      token: refreshToken,
    }
  );
  return await parsedResponse<IAuthResponse>(response);
}

export async function login(
  username: string,
  password: string
): Promise<IAuthResponse> {
  const response = await getApiRequestAdvanced('/auth/login', 'POST', null, {
    email: username,
    password: password,
  });
  return await parsedResponse<IAuthResponse>(response);
}

export async function verifyPin(
  email: string,
  pinCode: string
): Promise<IAuthResponse> {
  const response = await getApiRequestAdvanced(
    '/auth/verify-pin',
    'POST',
    null,
    {
      email: email,
      pinCode: pinCode,
    }
  );
  return await parsedResponse<IAuthResponse>(response);
}

export async function verifyNewUserPin(
  email: string,
  pinCode: string
): Promise<IAuthResponse> {
  const response = await getApiRequestAdvanced(
    '/auth/confirm-user',
    'POST',
    null,
    {
      email: email,
      pinCode: pinCode,
    }
  );
  return await parsedResponse<IAuthResponse>(response);
}

export async function checkEmailValid(email: string): Promise<boolean> {
  const response = await getApiRequestAdvanced(
    '/auth/email-valid',
    'POST',
    null,
    {
      email: email,
    }
  );
  // console.log('result', response);
  return response.ok;
}

export async function signupNewUser(
  email: string,
  captcha: string
): Promise<boolean> {
  // console.log('asdf');

  const response = await getApiRequestAdvanced('/auth/register', 'POST', null, {
    email: email,
    captcha: captcha,
  });

  if (response.ok) {
    return true;
  } else if (response.status === 409) {
    throw new ApiError('A user with that email already exists', 409);
  } else {
    throw new ApiError('Error when registering user', response.status);
  }
}
