import { createHttpLink } from 'apollo-link-http';
import React from 'react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Provider } from 'react-redux';
import { Playground, store } from 'graphql-playground-react';
import Helmet from 'react-helmet';
import { Session } from 'graphql-playground-react/lib/state/sessions/reducers';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { getAccessTokenFromStore } from '../../../services/api';

const myCache = new InMemoryCache({
  addTypename: true,
});

let graphqlEndpoint = 'http://localhost:8080/graphql';
if (process && process.env && process.env.REACT_APP_API) {
  graphqlEndpoint = process.env.REACT_APP_API + '/graphql';
}

const subsEndpoint = `${process.env.REACT_APP_WS_URL}:${
  process.env.REACT_APP_WS_PORT
}`;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  const token = getAccessTokenFromStore(); // TODO: we could rewrite to local storage when redux store is gone
  console.log('we are here..');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const typeDefs = `
  type Test {
    id: Int!
    text: String!
    completed: Boolean!
  }

  type Mutation {
    addTest(text: String!): Test
    toggleTest(id: Int!): Test
  }

  type Query {
    visibilityFilter: String
    tests: [Test]
  }
`;
//
// const myStateLink = withClientState({
//   resolvers: {},
//   defaults: {
//     tests: [],
//     visibilityFilter: 'SHOW_ALL',
//   },
//   typeDefs: typeDefs,
//   cache: myCache,
// });

const httpLink = createHttpLink({
  uri: graphqlEndpoint,
  headers: {
    Pragma: 'no-cache',
  },
});

// ReactDOM.render(
//   <Provider store={store}>
//     <Playground
//       endpoint={graphqlEndpoint || ''}
//       subscriptionEndpoint={subsEndpoint}
//       workspaceName={'Munikum'}
//       createApolloLink={(session: Session, subscriptionEndpoint?: string): ApolloLink => {
//         const httpLink = createHttpLink({
//           uri: graphqlEndpoint,
//           headers: {
//             Pragma: 'no-cache',
//           },
//         });
//         const myLink = ApolloLink.from([errorLink, authLink, httpLink]);
//         console.log('LINK ' + subscriptionEndpoint);
//         return myLink;
//       }}
//     />
//   </Provider>, document.body);
//

class PlaygroundPage extends React.PureComponent<{}, {}> {
  render() {
    return (
      <>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Source+Code+Pro:400,700"
            rel="stylesheet"
          />
        </Helmet>
        <Provider store={store}>
          <Playground
            endpoint={graphqlEndpoint || ''}
            subscriptionEndpoint={subsEndpoint}
            workspaceName={'Munikum'}
            createApolloLink={(
              session: Session,
              subscriptionEndpoint?: string
            ): any => {
              const myLink = ApolloLink.from([errorLink, authLink, httpLink]);
              return { link: myLink };
            }}
          />
        </Provider>
      </>
    );
  }
}

export default PlaygroundPage;
