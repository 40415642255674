import { OrderEnum, Organization, SortEnum } from '../../models/types';
import { actionCreatorFactory } from '../../modules/actionCreatorFactory';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import {
  WheelDirection,
  WheelSelectionMode,
  WheelToggleMode,
} from '../../components/annualCycle/Wheel/Wheel';

const actionCreator = actionCreatorFactory('CALENDAR');

export interface ICalendarFilter {
  sort?: SortEnum;
  order?: OrderEnum;
  limit?: number;
  offset?: number;
  organizations?: Array<Organization>;
  sortValue?: string;
  kostraGroup?: number | null;
  kostraGroupValue: string | undefined;
  predefinedOrg: boolean;
}

export interface ICalendarSettings {
  startMonth: string;
  direction: WheelDirection;
  selectionMode: WheelSelectionMode;
  toggleMode?: WheelToggleMode;
}

export interface ICalendarFilterState {
  filter: ICalendarFilter;
  currentPage: number;
  settings?: ICalendarSettings;
}

const initialState: ICalendarFilterState = {
  filter: {
    kostraGroupValue: undefined,
    sort: SortEnum.LAST_UPDATED,
    order: OrderEnum.DESCENDING,
    limit: 12,
    predefinedOrg: false,
    sortValue: '1',
    offset: 0,
    organizations: [],
    kostraGroup: null,
  },
  settings: {
    startMonth: '0',
    selectionMode: WheelSelectionMode.MONTH,
    direction: WheelDirection.RIGHT,
  },
  currentPage: 0,
};

export const resetCalendarsFilterActionCreator = actionCreator<{}>(
  'CLIENT_RESET_FILTER'
);
export const calendarChangeStartMonth = actionCreator<{
  settings: ICalendarSettings;
}>('CALENDAR_CHANGE_START_MONTH');
export const calendarChangePageActionCreator = actionCreator<{
  page: number;
}>('CHANGE_PAGE_CALENDAR');

export const clearMyMunicipality = actionCreator<{}>('CLEAR_MY_MUNICIPALITY');

export const filterCalendarsActionCreator = actionCreator<{
  filter: ICalendarFilter;
}>('FILTER_CALENDAR');

export const calendarReducer = (
  state: ICalendarFilterState = initialState,
  action: Action
): ICalendarFilterState => {
  if (isType(action, clearMyMunicipality)) {
    return {
      filter: {
        ...state.filter,
        organizations: [],
      },
      currentPage: state.currentPage,
    };
  }

  if (isType(action, calendarChangeStartMonth)) {
    return {
      settings: {
        direction: action.payload.settings.direction,
        selectionMode: action.payload.settings.selectionMode,
        startMonth: action.payload.settings.startMonth,
        toggleMode: action.payload.settings.toggleMode,
      },
      filter: {
        ...state.filter,
      },
      currentPage: state.currentPage,
    };
  }

  if (isType(action, resetCalendarsFilterActionCreator)) {
    return {
      filter: {
        predefinedOrg: false,
        kostraGroupValue: undefined,
        sort: SortEnum.LAST_UPDATED,
        order: OrderEnum.DESCENDING,
        limit: 12,
        sortValue: '1',
        offset: 0,
        organizations: [],
        kostraGroup: null,
      },
      currentPage: 0,
    };
  }

  if (isType(action, filterCalendarsActionCreator)) {
    return {
      filter: {
        ...action.payload.filter,
      },
      currentPage: 0,
    };
  }
  if (isType(action, calendarChangePageActionCreator)) {
    return {
      filter: state.filter,
      currentPage: action.payload.page,
    };
  }

  return state;
};

// const store = createStore(calendarReducer());
