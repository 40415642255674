// import AnnualCyclePage from './containers/AnnualCycle/AnnualCyclePage';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import { RouteComponentProps, withRouter } from 'react-router';
import { addLocaleData, IntlProvider } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import nnLocaleData from 'react-intl/locale-data/nn';
import nbLocaleData from 'react-intl/locale-data/nb';
import { RootState } from './modules/rootReducer';
import { BackendLocaleMap, localeSelector } from './modules/settings/settings';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/nb';
import 'moment/locale/nn';
import { enYupLocale, nbYupLocale, nnYupLocale, } from './components/language/yupLocale';
import { MyHelmet } from './components/helmet/MyHelmet';
// NO LAZY:
import { SuspenseLoader } from './components/basic/Loader/CircleSpinner';
import { ConnectedMainLayout } from './layouts/MainLayout/MainLayout';
import LoadingPage from './containers/LoadingPage/LoadingPage';
import { DEFAULT_LOCALE, translationMessages } from './translations/translationUtils';
import TopicEditPage from './containers/Topic/TopicEditPage/TopicEditPage';
import { createGlobalStyle } from 'styled-components';
import YearPrintPage from './containers/AnnualCycle/YearPrintPage';
import PlaygroundPage from './containers/labs/Graphql/PlaygroundPage';
import { Colors } from './components/common';
import { setLocale as yupSetLocale } from 'yup'; // nice, they fixesd missing export in latest version
import { ToasterContext } from './components/basic/Toaster/ToasterContext';
import Toaster from './components/basic/Toaster/Toaster';
import { IToastProps } from './components/basic/Toaster/Toast';
import WelcomeWizardPage from './containers/Login/WelcomeWizardPage/WelcomeWizardPage';
import { myApolloClient } from './graphql/apolloClientFactory';
import { GET_LOCALE_QUERY, UPDATE_LOCALE } from './components/language/LanguageSelector/LanguageSelector';
import { GetLocaleQuery, GetLocaleVariables, UpdateLocaleMutation, UpdateLocaleVariables } from './models/types';
import { UserTermsPage } from './containers/AboutPage/UserTermsPage';
import { isAuthenticatedSelector, userAuthInfoSelector } from './modules/auth/auth';
import {
  authFuncActionValue,
  authFuncCalendar,
  authFuncDiscussion,
  authFuncIsAdmin,
  authFuncTopic,
  AuthProvider,
  AuthRoute,
  UserAuthInfo
} from './auth';
import NoAccessPage from './auth/NoAccessPage';
import { AboutPage } from './containers';

const ActionValueCreatePage = React.lazy(() => import('./containers/ActionValue/ActionValueCreatePage/CreateActionValuePage'));
const ActionValueEditPage = React.lazy(() => import('./containers/ActionValue/ActionValueEditPage/ActionValueEditPage'));

// Pages with EmptyLayout or no layout:
const LabsPage = React.lazy(() => import('./containers/labs/LabsPage'));
const AdminPage = React.lazy(() => import('./containers/admin/AdminPage')) as any;
const NotFoundPage = React.lazy(() => import('./containers/NotFoundPage/NotFoundPage'));
const LoginEmailPage = React.lazy(() => import('./containers/Login/LoginEmailPage/LoginEmailPage'));
const SignupPage = React.lazy(() => import('./containers/Login/SignupPage/SignupPage'));
const SignupVerifyPage = React.lazy(() => import('./containers/Login/SignupPage/SignupVerifyPage'));

const GraphiqlPage = React.lazy(() => import('./containers/labs/Graphql/GraphiqlPage'));

const LoginUsernamePage = React.lazy(() => import('./containers/Login/LoginUsernamePage/LoginUsernamePage'));
const HelpdeskPage = React.lazy(() => import('./containers/HelpdeskPage/HelpdeskPage'));

// Pages with lazy below MainLayout:

const DashboardPage = React.lazy(() => import('./containers/Dashboard/DashboardPage'));
const ProfilePage = React.lazy(() => import('./containers/ProfilePage/ProfilePage'));
const MyProfilePage = React.lazy(() => import('./containers/MyProfilePage/MyProfilePage'));
const ActionValueItemPage = React.lazy(() => import('./containers/ActionValue/ActionValueItemPage/ActionValueItemPage'));
const ActionValueListPage = React.lazy(() => import('./containers/ActionValue/ActionValueListPage/ActionValueListPage'));
const DiscussionItemPage = React.lazy(() => import('./containers/Discussion/DiscussionItemPage/DiscussionItemPage'));
// const DiscussionCreatePage = React.lazy(() => import('./containers/Discussion/DiscussionCreatePage/DiscussionCreatePage'));
const OrganizationPage = React.lazy(() => import('./containers/OrganizationPage/OrganizationPage'));
const DiscussionListPage = React.lazy(() => import('./containers/Discussion/DiscussionListPage/DiscussionListPage'));
const TopicListPage = React.lazy(() => import('./containers/Topic/TopicListPage/TopicListPage'));
const TopicCreatePage = React.lazy(() => import('./containers/Topic/TopicCreatePage/TopicCreatePage'));
const TopicItemPage = React.lazy(() => import('./containers/Topic/TopicItemPage/TopicItemPage'));
const AnnualCyclePage = React.lazy(() => import('./containers/AnnualCycle/AnnualCyclePage'));
const CalendarListPage = React.lazy(() => import('./containers/AnnualCycle/CalendarListPage'));
const CalendarPage = React.lazy(() => import('./containers/AnnualCycle/CalendarPage'));
const TopicDashboardComp = React.lazy(() => import('./containers/Dashboard/TopicDashboard'));
const ActionValueDashboard = React.lazy(() => import('./containers/Dashboard/ActionValueDashboard'));
const ForumDashboard = React.lazy(() => import('./containers/Dashboard/ForumDashboard'));
const AnnualCycleDashboard = React.lazy(() => import ('./containers/Dashboard/AnnualCycleDashboard'));


addLocaleData(enLocaleData);
addLocaleData(nnLocaleData);
addLocaleData(nbLocaleData);

// global CSS inject. this is the only place we should define global CSS!! and only CSS for body and fonts!
// tslint:disable-next-line
const GlobalStyle = createGlobalStyle`
* {
    -webkit-overflow-scrolling: touch;
}
html {
  margin: 0;
  min-height: 100%;
  height: 100%;
    
  width: 100%;  
  overflow-x: hidden;
  
  @media print {
    margin: 0px;
    min-height: unset;
    height: unset;
    width: 100%;
    overflow-y: visible;
    overflow-x: visible;
  }
  
  @page :left {
    margin: 0.5cm;
  }
  
  @page :right {
    margin: 0.8cm;
  }
  
}
body {
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: block;
  line-height: normal;
  font-family: 'Lato', sans-serif;
  color: ${Colors.BLACK}
  
  // fixes whitespace on mobile, but hides vertical scrollbar behind header
  overflow-x: hidden; 
  overflow-y: auto;
  
  @media print {
    margin: 0px;
    width: 100%;
    min-height: unset;
    height: unset;
    overflow-x: visible;
    overflow-y: visible;
  }
}
.src-component-launcher-WidgetLauncher-label {
  color: #fff;
  }

a { outline: none; }
`;

/***
 * One alternative to put Lazy components in routes..
 * (we placed Suspense in MainLayout!)
 * @param Component
 * @constructor
 */
// function WaitingComponent2(Component) {
//   console.log('WAIT_COMP', Component);
//   return props => (
//     <React.Suspense fallback={''}>
//       <Component {...props} />
//     </React.Suspense>
//   );
// }
//
//
// // https://github.com/ReactTraining/react-router/issues/6561
// // another alternative (not tested)
// function Loadable({ fallback = null, loader, ...props }) {
//   const lazyRef = useRef(lazy(loader));
//   useEffect(() => {
//     lazyRef.current = lazy(loader);
//   }, [loader]);
//
//   const LazyComponent = lazyRef.current;
//   return <React.Suspense fallback={fallback}>{LazyComponent && <LazyComponent {...props} />}</React.Suspense>;
// }

export interface IAppProps {
  isAppReady: boolean;
  locale: string; // 'nn' | 'nb' | 'en';
  isWelcomeUserVisible: boolean;

  isAuthenticated: boolean;
  userAuthInfo?: UserAuthInfo;
}

class AppComponent extends React.Component<
  IAppProps & RouteComponentProps<{}>,
  {}
> {

  private toastRef: React.RefObject<Toaster> = null;

  constructor(props: IAppProps & RouteComponentProps<{}>) {
    super(props);
    this.toastRef = React.createRef();
    this.setLocale(props.locale, false);
  }

  setLocale = (locale: string = DEFAULT_LOCALE, saveToDb = true) => {
    const localeHack = locale.substring(0, 2);

    // set moment locale: (might require hard refresh?)
    if (localeHack === 'en') {
      moment.locale('en-gb');
    } else {
      moment.locale(localeHack);
    }

    // set yup locale:
    if (localeHack === 'nn') {
      yupSetLocale(nnYupLocale);
    } else if (localeHack === 'nb') {
      yupSetLocale(nbYupLocale);
    } else {
      yupSetLocale(enYupLocale);
    }

    if (saveToDb) {
      myApolloClient.query<GetLocaleQuery, GetLocaleVariables>({
        query: GET_LOCALE_QUERY
      }).then(res => {
        if (res.errors) {
          console.error('error saving locale to api', res.errors);
        } else {
          // mutate / store locale:
          myApolloClient.mutate<UpdateLocaleMutation, UpdateLocaleVariables>({
            mutation: UPDATE_LOCALE,
            variables: {
              input: {
                language: BackendLocaleMap[localeHack],
                kostraFunctions: res.data.me.kostraFunctions.map(c => c.id),
                jobTitle: res.data.me.jobTitle,
                description: res.data.me.description,
                department: res.data.me.department,
                name: res.data.me.name,
              }
            }
          }).then(res2 => {
            // console.log('language saved to backend ok');
          });
        }
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: IAppProps & RouteComponentProps<{}>) {
    if (nextProps.locale !== this.props.locale) {
      // console.log(
      //   'locale changed from ' + this.props.locale + ' to ' + nextProps.locale
      // );

      this.setLocale(nextProps.locale);
    }
  }

  handleAddToast = (props: IToastProps): string => {
    if(this.toastRef.current) {
      return this.toastRef.current.addToast(props);
    }
  };
  handleRemoveToast = (id: string) => {
    // console.log('remove');
    if(this.toastRef.current) {
      this.toastRef.current.removeToast(id);
    }

  };

  render() {
    const { isAppReady, locale } = this.props;
    const localeHack = locale.substring(0, 2);

    const routes = (
      <Switch >

        {/* EmptyLayout */}
        <Route path="/loading" exact={true} component={LoadingPage} />
        <Route path="/login" exact={false} component={() => <React.Suspense fallback={<SuspenseLoader/>}><LoginEmailPage /> </React.Suspense>} />
        <Route path={'/signup'} exact={true} component={() => <React.Suspense fallback={<SuspenseLoader/>}><SignupPage /> </React.Suspense>} />
        <Route path={'/verify/:email'} exact={true} component={() => <React.Suspense fallback={<SuspenseLoader/>}><SignupVerifyPage /> </React.Suspense>} />/>
        <Route path="/login-classic" exact={false} component={() => <React.Suspense fallback={<SuspenseLoader/>}><LoginUsernamePage/> </React.Suspense>} />/>
        <Route path="/help" exact={true} component={() => <React.Suspense fallback={<SuspenseLoader/>}>
          <HelpdeskPage token={'butt'} onLoadData={() => {return 'butt';}} /> {/* FIXME @Thomas */}
        </React.Suspense>} />

        {/* CUSTOM layout */}
        <Route path="/welcome-user" exact={true} component={() => <React.Suspense fallback={<SuspenseLoader/>}><WelcomeWizardPage /></React.Suspense>} />
        <Route path="/gql" exact={true} component={() => <React.Suspense fallback={<SuspenseLoader/>}><GraphiqlPage /> </React.Suspense>} />
        <Route path="/gql2" exact={true} component={() => <PlaygroundPage />} />

        <Route path={'/'} exact={true} component={() => <Redirect to={'/home'} />} />

        <AuthRoute authorizeFunc={authFuncCalendar} path="/person/:person/annual-cycle/print/:year?/:startMonth?" component={YearPrintPage} exact={true}/>

        <AuthRoute
          path={['/home','/action-value', '/forum', '/topic', '/person', '/profile', '/my-profile', '/about', '/organization', '/annual-cycle', '/labs', '/admin', '/calendar', '/no-access']}
          exact={false}
          component={() => {
            return (
              <ConnectedMainLayout>

                <Switch>

                  {/* NOTE: lazy stuff here: */}
                  <Route path={['/', '/home']} exact={true} component={DashboardPage} />
                  <AuthRoute path={'/about'} exact={true} component={AboutPage} />

                  <Route path={'/no-access/:redirect'} exact={false} component={NoAccessPage} />

                  {/* NOTE: not lazy yet: */}
                  <AuthRoute authorizeFunc={authFuncIsAdmin} path="/admin/action-value/create" component={ActionValueCreatePage} />
                  <AuthRoute authorizeFunc={authFuncIsAdmin} path="/admin/action-value/edit/:uri" component={ActionValueEditPage} />
                  <AuthRoute authorizeFunc={authFuncActionValue} path="/action-value/" exact={true} component={ActionValueDashboard} />
                  <AuthRoute authorizeFunc={authFuncActionValue} path="/action-value/list" exact={true} component={ActionValueListPage} />
                  <AuthRoute authorizeFunc={authFuncActionValue} path="/action-value/:uri" component={ActionValueItemPage} />

                  <AuthRoute authorizeFunc={authFuncDiscussion} path="/forum" exact={true} component={ForumDashboard} />
                  <AuthRoute authorizeFunc={authFuncDiscussion} path="/forum/:forum/create" exact={true} component={DiscussionListPage}/>
                  <AuthRoute authorizeFunc={authFuncDiscussion} path="/forum/:forum/:uri" component={DiscussionItemPage} />
                  <AuthRoute
                    path="/forum/:forum"
                    component={DiscussionListPage}
                    authorizeFunc={authFuncDiscussion}
                  />
                  <Route path="/about/userTerms" exact={true} component={UserTermsPage}/>
                  <AuthRoute authorizeFunc={authFuncTopic} path="/topic" exact={true} component={TopicDashboardComp} />
                  <AuthRoute authorizeFunc={authFuncTopic} path="/topic/list" exact={true} component={TopicListPage} />
                  {/*<AuthRoute authorizeFunc={authFuncTopic} path="/topic/:forum/new-discussion" exact={true} component={DiscussionCreatePage}/>*/}
                  <AuthRoute authorizeFunc={authFuncTopic} path="/topic/create" exact={true} component={TopicCreatePage} />
                  <AuthRoute authorizeFunc={authFuncTopic} path="/topic/edit/:uri" component={TopicEditPage} />

                  <AuthRoute authorizeFunc={authFuncTopic} path="/topic/:uri/:tab?" component={TopicItemPage} />


                  <AuthRoute authorizeFunc={authFuncCalendar} path="/annual-cycle" exact={true} component={AnnualCycleDashboard} />

                  <AuthRoute authorizeFunc={authFuncCalendar} path="/person/:person/annual-cycle/create/:year?" component={AnnualCyclePage} exact={true}/>
                  <AuthRoute authorizeFunc={authFuncCalendar} path="/person/:person/annual-cycle/:year?/:startMonth?" component={AnnualCyclePage} exact={true}/>

                  <AuthRoute authorizeFunc={authFuncCalendar} path="/calendar" exact={true} component={CalendarListPage} />
                  <AuthRoute authorizeFunc={authFuncCalendar} path="/calendar/myMunicipality" exact={true} component={CalendarListPage} />

                  <AuthRoute authorizeFunc={authFuncCalendar} path="/calendar/:uri" component={CalendarPage} />

                  <Redirect from={'/my-profile/'} to={'/my-profile/info'} exact={true} />
                  <Route path="/my-profile/:tab" component={MyProfilePage} exact={false} />
                  <Route path="/profile/:uri" component={ProfilePage} />
                  <Route path="/person/:uri" component={ProfilePage} exact={true} />

                  <Route path="/organization/:orgLocale/:orgNumber" component={OrganizationPage}/>


                  {/* NOTE: subroutes in LabsPAge! */}
                  <Route path="/labs" exact={false} component={() => <React.Suspense fallback={<SuspenseLoader/>}> <LabsPage /></React.Suspense>} />

                  {/* NOTE: subroutes in AdinPage! */}
                  <AuthRoute
                    authorizeFunc={authFuncIsAdmin}
                    path="/admin"
                    exact={false}
                    component={() =><React.Suspense fallback={<SuspenseLoader/>}> <AdminPage /></React.Suspense>}
                    // component={() => <TestIt />}
                  />


                  <Route path={'*'} component={() => <NotFoundPage />} />
                </Switch>
              </ConnectedMainLayout>
            );
          }}
        />

        {/* just ignore 404s and redirect to home?  */}
        <Route path="*" component={() => <React.Suspense fallback={<SuspenseLoader/>}><NotFoundPage /></React.Suspense>} />
      </Switch>
    );

    return (
      <IntlProvider
        locale={localeHack}
        defaultLocale={DEFAULT_LOCALE}
        textComponent={'span'}
        messages={translationMessages[localeHack]}
      >
        <>
          <Toaster ref={this.toastRef}/>
        <ToasterContext.Provider value={{
          addToast: this.handleAddToast,
          removeToast: this.handleRemoveToast,
        }}>
          <MyHelmet />
          <GlobalStyle/>
          {!isAppReady && <LoadingPage />}
          <AuthProvider
            isAuthenticated={this.props.isAuthenticated}
            userAuthInfo={this.props.userAuthInfo}
          >
            {isAppReady && routes }
          </AuthProvider>
        </ToasterContext.Provider>
        </>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state: RootState): IAppProps => ({
  isAppReady: state.auth && state.auth.isAppReady,
  isWelcomeUserVisible: state.onboarding.isWelcomeUserVisible,
  locale: localeSelector(state),
  isAuthenticated: isAuthenticatedSelector(state),
  userAuthInfo: userAuthInfoSelector(state)
});

const App = withRouter(
  connect<IAppProps, {}, {}>(
    mapStateToProps
  )(AppComponent)
);
export default App;
