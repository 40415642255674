export enum MunikumKeys {
  BACKSPACE = 8,
  TAB = 9,
  ENTER = 13,
  SHIFT = 16,
  ESCAPE = 27,
  SPACE = 32,
  ARROW_LEFT = 37,
  ARROW_UP = 38,
  ARROW_RIGHT = 39,
  ARROW_DOWN = 40,
  DELETE = 46,
  CONTROL = 17,
  LEFT_COMMAND = 91,
  T = 84,
  J = 74,
}

export function isKeyboardClick(keyCode: number): boolean {
  return keyCode === MunikumKeys.ENTER || keyCode === MunikumKeys.SPACE;
}
