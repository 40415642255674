import * as React from 'react';
import styled from 'styled-components';

const TitleDiv = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 0.7em;
  font-weight: 600;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 0.5em;

  &:hover {
    cursor: default;
  }
`;

export const MenuTitle: React.SFC<{ text: string }> = props => (
  <TitleDiv>{props.text}</TitleDiv>
);
