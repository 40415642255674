import moment from 'moment';
import * as React from 'react';
import { CSSProperties, RefObject } from 'react';

import styled from 'styled-components';

import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import RRule, { ByWeekday, Frequency, Options, Weekday } from 'rrule';
import * as _ from 'lodash';
import { Button, ButtonSize, TextField, ValidationSummary } from '..';
import { FieldGroup } from '../FieldGroup/FieldGroup';
import { MunikumIcons } from '../../common/icons';
import {
  Colors,
  ColorTheme,
  ILabelProps,
  IMessageProps,
  Omit,
  safeInvoke,
  safeInvokeDeprecated,
} from '../../common';
import { FormLabel } from '../FormLabel/FormLabel';
import { DateTimePicker } from '../DateTimePicker/DateTimePicker';
import {
  DROPDOWN_EMPTY_VALUE,
  DropDownSelect,
} from '../DropDownSelect/DropDownSelect';
import { commonMessages } from '../../language/commonMessages';
import { Popover } from '../Popover/Popover';
import { RepetitionCustom } from './RepetitionCustom';
import { CircleCheckBox } from '../CircleCheckBox/circleCheckBox';
import { MonthListDropDown } from './MonthListDropDown';
import { Tooltip } from '../Tooltip/Tooltip';

const messages = defineMessages({
  Yearly: {
    id: 'RepetitionPicker.Yearly',
    defaultMessage: 'Yearly',
  },
  Monthly: {
    id: 'RepetitionPicker.Monthly',
    defaultMessage: 'Monthly',
  },
  fixedRepition: {
    id: 'RepetitionPicker.fixedRepition',
    defaultMessage: 'Fixed repitition',
  },
  Weekly: {
    id: 'RepetitionPicker.Weekly',
    defaultMessage: 'Weekly',
  },
  Daily: {
    id: 'RepetitionPicker.Daily',
    defaultMessage: 'Daily',
  },

  Years: {
    id: 'RepetitionPicker.Years',
    defaultMessage: 'Year(s)',
  },
  Months: {
    id: 'RepetitionPicker.Months',
    defaultMessage: 'Month(s)',
  },
  Weeks: {
    id: 'RepetitionPicker.Weeks',
    defaultMessage: 'Week(s)',
  },
  Days: {
    id: 'RepetitionPicker.Days',
    defaultMessage: 'Day(s)',
  },

  Every: {
    id: 'RepetitionPicker.Every',
    defaultMessage: 'Every:',
  },
  tuesday: {
    id: 'RepetitionPicker.tuesday',
    defaultMessage: 'TU',
  },
  Ends: {
    id: 'RepetitionPicker.Ends',
    defaultMessage: 'Ends:',
  },
  nothinChosen: {
    id: 'RepetitionPicker.nothinChosen',
    defaultMessage: 'Nothing chosen:',
  },
  repitionPreviewText: {
    id: 'RepetitionPicker.repitionPreviewText',
    defaultMessage: 'Nothing chosen:',
  },
});

export interface IRepetitionTimePickerProps {
  /**
   * initially selected date
   */
  defaultSelectedValue?: Date;

  /**
   * optionally set this to put component in controlled mode
   */
  value?: IRepetitionData;

  handleRemoveRepetition: () => void;

  /**
   * form name
   */
  name: string;

  // currentUntilValue: Date;

  /**
   * is datetimepicker initially open?
   */
  isDefaultOpen?: boolean;

  style?: CSSProperties;

  fieldGroupStyle?: CSSProperties;

  outerStyle?: CSSProperties;
  startDate?: Date;
  onClickDay?: () => void;
  onCancel?: () => void;
  onSave?: (values: IRepetitionData) => void;
  orgEndDate?: Date;
  count?: number;
  bymonth?: number;
  handleCancel?: (startDate: Date) => void;
  // title: string;

  disabled?: boolean;
}

export interface IRepetitionTimePickerState {
  friendlyText: string;
  /**
   * form id
   */
  showerrorEndDate: boolean;
  errorEndDateMsg: any;
  id: string;
  startDate: Date;
  specialInterval: boolean;
  // currentDtStart: Date;
  // currentUntil: Date;
  dateOfFistInstance: Date;
  isOpen: boolean;
  customMode: boolean;
  dummyDate: string;
  selectedMonths: { [key: string]: boolean };
  selectedDays: { [key: string]: boolean };
  isSelected: any;
  isNeverSelected: boolean;
  allMonths: Array<any>;
  noEndDate: boolean;

  data: Partial<IOptionsViewModel>;
}

export interface IRepetitionData {
  startRepetitionDate?: Date;
  endRepetitionDate?: Date;
  repetitionDefinition?: string;
  freq: Frequency | 'none';
}

const TempDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
`;

const WeekDaysDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const Circle = styled.div`
  height: 24px;
  width: 24px;
  border: 1px solid #d0d3d4;
  background-color: ${(props: { isSelected: any }) =>
    props.isSelected ? '#FF5C39' : '#fff'};
  color: ${(props: { isSelected: any }) =>
    props.isSelected ? '#fff' : '#333'};
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const RightSection = styled.div`
  background-color: #efefef;
  min-width: 300px;
  padding: 1.25em;
  margin-left: 0.5em;
`;

// used in many other places, should not change
export const SmallTitle = styled.div`
  margin-right: 1em;
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
`;

const BottomDiv = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3em;
`;
const ThTitles = styled.th`
  padding-right: 0.5em;
  width: 3em;
  display: flex;
  justify-content: flex-start;
`;
const Td = styled.td`
  padding-right: 0.5em;
  width: 3em;
  border-bottom: 1px solid #333;
`;

const IconDiv = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const RemoveRepetitionWaring = styled.div`
  margin-bottom: 1em;
  :hover {
    cursor: pointer;
  }
`;

function renderDays(names: Array<any>, startNr: number, endNr: number) {
  let newArray: Array<any>;
  newArray = [];
  for (let i = startNr; i < endNr; i++) {
    newArray.push({
      id: i,
      value: names[i],
    });
  }
  return newArray;
}

// NOTE: this variable is defined outside component and lives forever =)
let selectedDays: ByWeekday[];
selectedDays = [1];
let newPreviewArray: Array<any> = undefined;
let selectedMonths: Array<any>;
selectedMonths = [];

const parseData = (
  data1: IRepetitionData | undefined
): Partial<IOptionsViewModel> => {
  // TODO: parse rule string, set values below
  if (data1 && data1.freq === 'none') {
    return {
      bymonth: null,
      freq: 0,
      currentDtStart: data1.startRepetitionDate || new Date(),
      interval: 1,
      currentUntil: null,
      // data1.endRepetitionDate ||
      // moment()
      //   .add(1, 'year')
      //   .toDate(),
    };
  }
  if (data1 && data1.repetitionDefinition === undefined) {
    return {
      bymonth: null,
      freq: 0,
      currentDtStart: data1.startRepetitionDate || new Date(),
      interval: 1,
      currentUntil: null,
      // data1.endRepetitionDate ||
      // moment()
      //   .add(1, 'year')
      //   .toDate(),
    };
  }

  if (data1 === undefined) {
    return {};
  } else {
    const test1 = RRule.parseString(data1.repetitionDefinition || '');
    let RuleDay = RRule.MO;
    let nth = 0;
    if (test1.byweekday) {
      switch (new Date(data1.startRepetitionDate).getDay()) {
        case 0:
          RuleDay = RRule.SU;
          break;
        case 1:
          RuleDay = RRule.MO;
          break;
        case 2:
          RuleDay = RRule.TU;
          break;
        case 3:
          RuleDay = RRule.WE;
          break;
        case 4:
          RuleDay = RRule.TH;
          break;
        case 5:
          RuleDay = RRule.FR;
          break;
        case 6:
          RuleDay = RRule.SA;
          break;
        default:
          RuleDay = RRule.MO;
      }
      let startDate = new Date(data1.startRepetitionDate).getDate();

      // find if the selected date is in the last week of the month
      let nthSouldBeLastWeekInMonth = false;
      let butt = new Date(data1.startRepetitionDate).getDate() + 7;
      let testing = new Date(
        new Date(data1.startRepetitionDate).getFullYear(),
        new Date(data1.startRepetitionDate).getMonth(),
        butt
      );
      if (
        butt <= 31 &&
        testing.getFullYear() ===
          new Date(data1.startRepetitionDate).getFullYear() &&
        testing.getMonth() === new Date(data1.startRepetitionDate).getMonth()
      ) {
      } else {
        nthSouldBeLastWeekInMonth = true;
      }
      // find what week the date is in
      if (nthSouldBeLastWeekInMonth) {
        nth = -1;
      }
      if (startDate <= 7) {
        nth = 1;
      } else {
        if (startDate <= 14) {
          nth = 2;
        } else {
          if (startDate <= 21) {
            nth = 3;
          } else {
            if (startDate <= 28) {
              nth = 4;
            }
          }
        }
      }
    }

    let temp: IOptionsViewModel = {
      freq: data1.freq,
      dtstart: null,
      until: null,
      interval: test1.interval || null,
      wkst: test1.wkst || null,
      count: test1.count || null,
      tzid: test1.tzid || null,
      bysetpos: test1.bysetpos || null,
      bymonth: test1.bymonth
        ? data1.freq === 0
          ? new Date(data1.startRepetitionDate).getMonth() + 1
          : test1.bymonth
        : null,
      bymonthday: test1.bymonthday || null,
      bynmonthday: test1.bynmonthday || null,
      byyearday: test1.byyearday || null,
      byweekno: test1.byweekno || null,
      byweekday: test1.byweekday
        ? data1.freq === 2
          ? test1.byweekday
          : [RuleDay.nth(nth)]
        : null,
      bynweekday: test1.bynweekday || null,
      byhour: test1.byhour || null,
      byminute: test1.byminute || null,
      bysecond: test1.bysecond || null,
      byeaster: test1.byeaster || null,
      currentDtStart: data1.startRepetitionDate || new Date(),
      currentUntil: data1.endRepetitionDate || null,
    };
    return temp;
  }
};

interface IOptionsViewModel extends Omit<Options, 'freq'> {
  freq: Frequency | 'none';
  currentDtStart: Date;
  currentUntil: Date;
}

/***
 * RepetitionPicker
 */

class RepetitionPickerComp extends React.PureComponent<
  IRepetitionTimePickerProps & ILabelProps & IMessageProps & InjectedIntlProps,
  IRepetitionTimePickerState
> {
  private readonly myRef: RefObject<HTMLDivElement>;
  private repetitionRef: RefObject<any>;
  private dateOfFistInstance: Date;
  constructor(
    props: IRepetitionTimePickerProps &
      IMessageProps &
      ILabelProps &
      InjectedIntlProps
  ) {
    super(props);
    this.repetitionRef = React.createRef();
    this.dateOfFistInstance = new Date();
    let data = parseData(props.value);
    let days: Number[] = [];
    selectedDays = []; // super hack
    selectedMonths = [];

    if (data.byweekday instanceof Array) {
      const a: ByWeekday[] = data.byweekday;
      a.map((i: Weekday) => {
        days.push(i.weekday);
        selectedDays.push(i.weekday);
      });
    }
    let months: number[] = [];

    if (data.bymonth instanceof Array) {
      const a: number[] = data.bymonth;
      a.map(i => {
        months.push(i);
        selectedMonths.push(i);
      });
    } else if (typeof data.bymonth === 'number') {
      months.push(data.bymonth);
      selectedMonths.push(data.bymonth);
    }

    this.state = {
      data: data,
      showerrorEndDate: false,
      errorEndDateMsg: (
        <FormattedMessage
          id={'repetitionPicker.repititionEndDateWarning'}
          defaultMessage={
            'The task will not repeat with this end date. {br} try changing it more ahead in time'
          }
          values={{
            br: <br />,
          }}
        />
      ),
      friendlyText: this.getTextFieldPlaceholder(data.freq),
      isNeverSelected: true,
      dummyDate: _.cloneDeep(this.props.startDate.getDate().toString()),
      specialInterval:
        this.props.value &&
        this.props.value &&
        this.props.value.repetitionDefinition
          ? this.props.value.repetitionDefinition.indexOf('+') > 0 ||
            this.props.value.repetitionDefinition.indexOf('-') > 0
          : false,
      startDate: this.props.startDate,
      isSelected: false,
      customMode: data.interval === null || data.interval === undefined,
      noEndDate: data.currentUntil === null,
      dateOfFistInstance: new Date(),
      selectedMonths: {
        JAN: months.indexOf(1) > -1,
        FEB: months.indexOf(2) > -1,
        MAR: months.indexOf(3) > -1,
        APR: months.indexOf(4) > -1,
        MAY: months.indexOf(5) > -1,
        JUN: months.indexOf(6) > -1,
        JUL: months.indexOf(7) > -1,
        AUG: months.indexOf(8) > -1,
        SEP: months.indexOf(9) > -1,
        OKT: months.indexOf(10) > -1,
        NOV: months.indexOf(11) > -1,
        DES: months.indexOf(12) > -1,
      },
      selectedDays: {
        MO: days.indexOf(0) > -1,
        TU: days.indexOf(1) > -1,
        WE: days.indexOf(2) > -1,
        TH: days.indexOf(3) > -1,
        FR: days.indexOf(4) > -1,
        SA: days.indexOf(5) > -1,
        SU: days.indexOf(6) > -1,
      },
      allMonths: renderDays(
        [
          '',
          'JAN',
          'FEB',
          'MAR',
          'APR',
          'MAI',
          'JUN',
          'JUL',
          'AUG',
          'SEP',
          'OKT',
          'NOV',
          'DES',
        ],
        1,
        13
      ),

      id: 'dp1',
      isOpen: props.isDefaultOpen || false,
    };

    this.myRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: IRepetitionTimePickerProps &
      ILabelProps &
      // IRepetitionTimePickerDispatch &
      InjectedIntlProps
  ) {
    if (this.state.startDate !== nextProps.startDate) {
      this.setState({
        startDate: nextProps.startDate,
        dummyDate: _.cloneDeep(nextProps.startDate.getDate().toString()),
      });
    }
    if (
      nextProps.value !== undefined &&
      nextProps.value.freq !== this.state.data.freq
    ) {
      if (nextProps.value.freq === 'none') {
        this.setState({
          friendlyText: this.props.intl.formatMessage(messages.nothinChosen),
        });
      }
      // TODO: update internal formik state.. reset form?
      this.setState({
        data: parseData(nextProps.value),
      });
    }
  }

  handleClickTextField = () => {
    this.setState({
      isOpen: true,
      dummyDate: _.cloneDeep(this.props.orgEndDate)
        .getDate()
        .toString(),
      startDate: _.cloneDeep(this.props.orgEndDate),
    });
  };

  handleCancel = startDate => {
    safeInvoke(this.props.handleCancel, startDate);
  };
  getDayName = (dateStr, locale2) => {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale2, { weekday: 'short' });
  };
  getMonthName = (dateStr, locale2) => {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale2, { month: 'short' });
  };
  findDayName = dateStr => {
    switch (dateStr) {
      case '0':
      case 0:
        return RRule.SU;
      case '1':
      case 1:
        return RRule.MO;
      case '2':
      case 2:
        return RRule.TU;
      case '3':
      case 3:
        return RRule.WE;
      case '4':
      case 4:
        return RRule.TH;
      case '5':
      case 5:
        return RRule.FR;
      case '6':
      case 6:
        return RRule.SA;

      default:
        return RRule.MO;
    }
  };

  getNth = () => {
    let startDate = this.state.startDate.getDate();
    // check if it should be the last week in the month
    let nthSouldBeLastWeekInMonth = false;
    let butt = this.state.startDate.getDate() + 7;
    let testing = new Date(
      this.state.startDate.getFullYear(),
      this.state.startDate.getMonth(),
      butt
    );
    if (
      butt <= 31 &&
      testing.getFullYear() === this.state.startDate.getFullYear() &&
      testing.getMonth() === this.state.startDate.getMonth() &&
      testing.getDay() === this.state.startDate.getDay()
    ) {
    } else {
      console.log(this.state.startDate.getMonth());
      nthSouldBeLastWeekInMonth = true;
    }
    console.log(testing.getDay(), this.state.startDate.getDay());
    console.log(testing.getMonth(), this.state.startDate.getMonth());
    // find what week the date is in
    if (nthSouldBeLastWeekInMonth) {
      return -1;
    } else {
      if (startDate <= 7) {
        return 1;
      } else {
        if (startDate <= 14) {
          return 2;
        } else {
          if (startDate <= 21) {
            return 3;
          } else {
            if (startDate <= 28) {
              return 4;
            }
          }
        }
      }
    }
  };
  makeRulePreview = formikProps => {
    if (formikProps && formikProps.freq !== 'none') {
      let frequency = formikProps.freq === 'none' ? 0 : formikProps.freq;
      switch (frequency) {
        case 0:
        case '0':
          frequency = RRule.YEARLY;
          break;
        case 1:
        case '1':
          frequency = RRule.MONTHLY;
          break;
        case 2:
        case '2':
          frequency = RRule.WEEKLY;
          break;
        default:
          frequency = RRule.YEARLY;
      }

      let dateToday = new Date();
      let year = dateToday.getFullYear();
      let month = dateToday.getMonth();
      let day = dateToday.getDate();
      let endRepetition = new Date(year + 12, month, day);

      let byMonthDay = null;
      if (!this.state.specialInterval) {
        if (formikProps.freq === '1' || formikProps.freq === '0') {
          byMonthDay = this.state.startDate.getDate();
        }
      }
      const rule = new RRule({
        freq: frequency,
        interval: formikProps.interval ? +formikProps.interval : 1,
        bymonthday: byMonthDay,
        bymonth: formikProps.bymonth,
        dtstart: this.state.startDate,
        byweekday: formikProps.byweekday,
        until:
          formikProps.currentUntil === null
            ? endRepetition
            : new Date(formikProps.currentUntil),
      });
      let test =
        rule &&
        rule.all(function(date: Date, i: number) {
          // getting all the objects from the rule
          if (!rule.options.count && i === 1000) {
            return false; // That's enough
          }
          return true;
        });
      let dateTodayRule2 = new Date();
      let dateYesterDay = dateTodayRule2.setDate(
        new Date().getDate() - 1 === 0 ? 1 : new Date().getDate()
      );
      newPreviewArray = [];
      let count: number = 0;
      test.forEach(c => {
        let dayToday = new Date(
          this.state.startDate.getFullYear(),
          this.state.startDate.getMonth(),
          this.state.startDate.getDate(),
          23,
          59,
          59,
          99
        );
        let ruleDate = new Date(
          c.getFullYear(),
          c.getMonth(),
          c.getDate(),
          23,
          59,
          59,
          99
        );
        if (dayToday <= ruleDate && count < 10) {
          newPreviewArray.push(c);
          count++;
        }
      });
      this.dateOfFistInstance = new Date(newPreviewArray[0]);

      return (
        <>
          {newPreviewArray.length > 0 && (
            <table style={{ minWidth: '10em' }}>
              <tbody style={{}}>
                <tr style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <th style={{ paddingRight: '2em' }} />
                  <ThTitles>Dag</ThTitles>
                  <ThTitles>Dato</ThTitles>
                  <ThTitles style={{ paddingRight: '1em' }}>Månad</ThTitles>
                  <ThTitles>År</ThTitles>
                </tr>
                {newPreviewArray &&
                  newPreviewArray.map((c, i) => {
                    return (
                      <tr
                        key={i}
                        style={{
                          display: 'flex',
                          paddingTop: '.5em',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <th style={{ paddingRight: '1em', width: '1em' }}>
                          {i + 1}
                        </th>
                        <Td>{this.getDayName(c.toString(), 'nb-NO')}</Td>
                        <Td>{c.getDate().toString()}</Td>
                        <Td style={{ paddingRight: '1em' }}>
                          {this.getMonthName(c.toString(), 'nb-NO').toString()}
                        </Td>
                        <Td>{c.getFullYear().toString()}</Td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
          {newPreviewArray.length === 0 && (
            <div
              style={{
                marginTop: '1em',
                fontStyle: 'italic',
                opacity: 0.7,
                fontSize: '16px',
                maxWidth: '300px',
                lineHeight: '24ox',
                display: 'flex',
                flexWrap: 'wrap',
                fontFamily: 'Lato, sans-serif',
              }}
            >
              {this.props.intl.formatMessage(commonMessages.noRepetitionsText)}
            </div>
          )}
        </>
      );
    }
  };

  handleMonthClick = (month: string) => {
    const temp = Object.assign({}, this.state.selectedMonths);
    temp[month] = !temp[month];
    this.setState(
      {
        selectedMonths: temp,
        selectedDays: {
          MO: false,
          TU: false,
          WE: false,
          TH: false,
          FR: false,
          SA: false,
          SU: false,
        },
      },
      () => {}
    );
  };

  handleDayClick = (day: string) => {
    const temp = Object.assign({}, this.state.selectedDays);
    temp[day] = !temp[day];
    this.setState({
      selectedDays: temp,
      selectedMonths: {
        JAN: false,
        FEB: false,
        MAR: false,
        APR: false,
        MAY: false,
        JUN: false,
        JUL: false,
        AUG: false,
        SEP: false,
        OKT: false,
        NOV: false,
        DES: false,
      },
    });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutSide);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutSide);
  }

  getTextFieldPlaceholder = freq => {
    let placeholderText = this.props.intl.formatMessage(messages.nothinChosen);
    if (
      !(this.props.value.freq === 'none' || this.props.value.freq === undefined)
    ) {
      switch (freq) {
        case 0:
          placeholderText = this.props.intl.formatMessage(messages.Yearly);
          break;
        case 1:
          placeholderText = this.props.intl.formatMessage(messages.Monthly);
          break;
        case 2:
          placeholderText = this.props.intl.formatMessage(messages.Weekly);
          break;
        default:
          placeholderText = this.props.intl.formatMessage(
            messages.nothinChosen
          );
      }
    } else {
      placeholderText = this.props.intl.formatMessage(messages.nothinChosen);
    }
    return placeholderText;
  };

  protected handleClickOutSide = (e: any) => {
    if (this.state.isOpen) {
      if (this.repetitionRef && this.repetitionRef.current) {
        if (!this.repetitionRef.current.contains(e.target)) {
          this.setState({
            isOpen: false,
            showerrorEndDate: false,
            startDate: moment(this.props.orgEndDate).toDate(),
          });
          this.handleCancel(moment(this.props.orgEndDate).toDate());
        }
      }
    }
  };

  render() {
    const hey = parseData(this.props.value);
    const Days = renderDays(
      [
        'MA',
        this.props.intl.formatMessage(messages.tuesday),
        'ON',
        'TO',
        'FR',
        'LØ',
        'SØ',
      ],
      0,
      7
    );

    const {
      label,
      name,
      style,
      fieldGroupStyle,
      intl,
      outerStyle,
      info,
      success,
      warning,
      error,
    } = this.props;
    const { id, isOpen } = this.state;

    const myItems = {
      '0': { name: intl.formatMessage(messages.Yearly) },
      '1': { name: intl.formatMessage(messages.Monthly) },
      '2': { name: intl.formatMessage(messages.Weekly) },
      // '3' : { name: intl.formatMessage(messages.Daily)},
    };
    return (
      <div ref={this.myRef} style={outerStyle}>
        <FieldGroup style={fieldGroupStyle}>
          {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
          <Popover
            usePortal={false}
            style={{
              boxSizing: 'border-box',
              padding: 0,
            }}
            isOpen={this.state.isOpen}
            position={'bottom-end'}
            renderTarget={ref => {
              return (
                <div>
                  <TextField
                    onClickIcon={this.handleClickTextField}
                    name={name}
                    innerRef={ref}
                    value={this.state.friendlyText}
                    onChange={() => {}}
                    onClick={this.handleClickTextField}
                    autoComplete={'off'}
                    inputStyle={{
                      cursor: 'pointer',
                      color: 'transparent',
                      textShadow: '0 0 0 ' + Colors.BLACK,
                    }}
                    rightIcon={MunikumIcons.ArrowDown}
                    fieldGroupStyle={{
                      padding: 0,
                    }}
                    style={style}
                  />
                </div>
              );
            }}
          >
            <Formik
              validateOnBlur={true}
              validationSchema={Yup.object().shape({})}
              onSubmit={(values: IOptionsViewModel) => {
                if (newPreviewArray.length === 0) {
                  this.setState({
                    showerrorEndDate: true,
                  });
                } else {
                  this.setState({
                    showerrorEndDate: false,
                  });
                  let data: IRepetitionData | undefined = undefined;
                  let friendlyText = '';
                  if (values.freq === 'none') {
                    friendlyText = 'Ingenting er valgt';
                    data = {
                      freq: values.freq,
                    };
                  } else {
                    if (myItems[this.state.data.freq] !== undefined) {
                      this.setState({
                        friendlyText: myItems[this.state.data.freq].name,
                      });
                    }

                    if (
                      !this.state.specialInterval &&
                      !this.state.customMode &&
                      values.freq.toString() === '1'
                    ) {
                      values.bymonth = null;
                      values.byweekday = null;
                    }

                    if (this.state.customMode) {
                      values.interval = null;
                    } else {
                      if (values.interval === undefined) {
                        values.interval = 1;
                      }
                    }
                    if (values.freq.toString() === '0') {
                      values.bymonth =
                        values.bymonth === null
                          ? this.state.startDate.getMonth() + 1
                          : values.bymonth;
                    }
                    if (!this.state.specialInterval) {
                      if (
                        values.freq.toString() === '1' ||
                        values.freq.toString() === '0'
                      ) {
                        values.bymonthday = this.state.startDate.getDate();
                      }
                    }
                    this.setState({
                      startDate: this.dateOfFistInstance,
                    });

                    // const eventData = new RRule({
                    //   freq: values.freq,
                    //   interval: values.interval,
                    //   byweekday: values.byweekday,
                    //   bymonthday: values.bymonthday,
                    //   wkst: 0,
                    // });

                    const temp2 = _.cloneDeep(values);

                    // ugly hacks :-)
                    delete temp2.dtstart;
                    delete temp2.until;
                    delete temp2.currentDtStart;
                    delete temp2.currentUntil;

                    const eventData = new RRule(temp2 as Options);
                    const temp = eventData.toString();
                    // friendlyText = eventData.toText();

                    if (values.freq.toString() === '0') {
                      friendlyText = intl.formatMessage(messages.Yearly);
                    } else if (values.freq.toString() === '1') {
                      friendlyText = intl.formatMessage(messages.Monthly);
                    } else if (values.freq.toString() === '2') {
                      friendlyText = intl.formatMessage(messages.Weekly);
                    }
                    data = {
                      startRepetitionDate: this.state.startDate,
                      freq: values.freq,
                      endRepetitionDate: values.currentUntil,
                      repetitionDefinition: temp.replace('RRULE:', ''),
                    };
                  }

                  this.setState({
                    isOpen: false,
                    friendlyText: friendlyText,
                  });
                  safeInvokeDeprecated(this.props.onSave, data);
                }
              }}
              initialValues={hey}
              render={(formikProps: FormikProps<IOptionsViewModel>) => {
                return (
                  <div
                    ref={this.repetitionRef}
                    style={{ minWidth: '20em', display: 'flex' }}
                  >
                    <div style={{ padding: '1.25em', minWidth: '300px' }}>
                      <TempDiv>
                        <SmallTitle>
                          {intl.formatMessage(messages.fixedRepition)}
                        </SmallTitle>
                        <DropDownSelect
                          showEmptyOption={false}
                          disabled={this.props.disabled}
                          name={'frequency'}
                          fieldGroupStyle={{
                            width: '6em',
                          }}
                          items={myItems}
                          value={
                            formikProps.values.freq === 'none'
                              ? '0'
                              : formikProps.values.freq.toString()
                          }
                          onChangeSelected={(key: string) => {
                            if (key === DROPDOWN_EMPTY_VALUE) {
                              formikProps.setFieldValue('freq', 'empty');
                            } else {
                              this.setState({
                                customMode: false,
                                showerrorEndDate: false,
                                specialInterval: false,
                              });
                              formikProps.setFieldValue('freq', key);
                              formikProps.setFieldValue('interval', 1);
                              formikProps.setFieldValue('bymonth', null);
                              formikProps.setFieldValue('byweekday', null);
                              selectedDays = [];
                              selectedMonths = [];
                              if (!(key === '0' || key === '1')) {
                                formikProps.setFieldValue('bymonthday', null);
                              }
                              this.setState({
                                selectedDays: {},
                                selectedMonths: {},
                              });
                              if (key === '0') {
                                formikProps.setFieldValue(
                                  'bymonth',
                                  new Date().getMonth() + 1
                                );
                              }
                            }
                            if (key === '2') {
                              selectedDays.push(
                                this.state.startDate.getDay() - 1
                              );
                              formikProps.setFieldValue(
                                'byweekday',
                                selectedDays
                              );
                            }
                          }}
                          accessor={'name'}
                        />
                      </TempDiv>
                      {(formikProps.values.freq.toString() === '1' ||
                        formikProps.values.freq.toString() === '0') && (
                        <div
                          style={{
                            marginTop: '.5em',
                            backgroundColor: '#efefef',
                            padding: '.5em',
                            marginBottom: '.5em',
                          }}
                        >
                          <div style={{ display: 'flex', marginTop: '.5em' }}>
                            <CircleCheckBox
                              style={{ marginTop: '.2em' }}
                              checked={!this.state.specialInterval}
                              onChange={() => {
                                if (this.state.specialInterval === true) {
                                  formikProps.setFieldValue('byweekday', null);
                                  if (
                                    formikProps.values.freq.toString() === '0'
                                  ) {
                                    selectedMonths = [];
                                    selectedDays = [];
                                    formikProps.setFieldValue(
                                      'bymonth',
                                      this.state.startDate.getMonth() + 1
                                    );
                                  }
                                  this.setState({
                                    specialInterval: !this.state
                                      .specialInterval,
                                  });
                                }
                              }}
                            />
                            <SmallTitle
                              style={{ fontSize: '12px', marginTop: '.5em' }}
                            >
                              Dato
                            </SmallTitle>
                            <div style={{ display: 'flex' }}>
                              <TextField
                                disabled={this.state.specialInterval}
                                value={this.state.dummyDate}
                                onChange={e => {
                                  if (e.target.value < 32) {
                                    if (e.target.value) {
                                      let test: Date = this.state.startDate;
                                      test.setDate(+e.target.value);

                                      this.setState({
                                        startDate: test,
                                        dummyDate: e.target.value,
                                      });
                                    } else {
                                      this.setState({
                                        dummyDate: '',
                                      });
                                    }
                                  }
                                }}
                                onBlur={formikProps.handleBlur}
                                // name={'interval'}
                                style={{ marginLeft: '.5em', width: '6em' }}
                                fieldGroupStyle={{
                                  marginTop: '-.2em',
                                }}
                              />
                            </div>
                            {formikProps.values.freq.toString() === '0' && (
                              <MonthListDropDown
                                style={{ marginLeft: '8px', marginTop: '-3px' }}
                                monthListValue={
                                  formikProps.values.bymonth === null ||
                                  formikProps.values.bymonth === undefined
                                    ? (
                                        this.state.startDate.getMonth() + 1
                                      ).toString()
                                    : formikProps.values.bymonth.toString()
                                }
                                disabled={this.state.specialInterval}
                                onMonthChange={(key: string) => {
                                  let monthNumber = +key + 1;
                                  formikProps.setFieldValue(
                                    'bymonth',
                                    monthNumber
                                  );
                                }}
                              />
                            )}
                          </div>
                          <div style={{ display: 'flex', marginBottom: '1em' }}>
                            <CircleCheckBox
                              style={{ marginTop: '.75em' }}
                              checked={this.state.specialInterval}
                              onChange={() => {
                                if (this.state.specialInterval === false) {
                                  formikProps.setFieldValue('bymonthday', null);
                                  this.setState({
                                    specialInterval: !this.state
                                      .specialInterval,
                                  });
                                  if (
                                    formikProps.values.byweekday === null ||
                                    formikProps.values.byweekday === undefined
                                  ) {
                                    formikProps.setFieldValue('byweekday', [
                                      this.findDayName(
                                        this.state.startDate.getDay()
                                      ).nth(this.getNth()),
                                    ]);

                                    if (
                                      formikProps.values.freq.toString() === '0'
                                    ) {
                                      formikProps.setFieldValue(
                                        'bymonth',
                                        this.state.startDate.getMonth() + 1
                                      );
                                    }
                                  }
                                }
                              }}
                            />
                            <RepetitionCustom
                              value={formikProps.values.byweekday}
                              disabled={!this.state.specialInterval}
                              intervalValue={'0'}
                              onWeekDayChange={(key: ByWeekday[]) => {
                                formikProps.setFieldValue('byweekday', key);
                              }}
                            />
                            {formikProps.values.freq.toString() === '0' && (
                              <>
                                <SmallTitle
                                  style={{
                                    fontSize: '12px',
                                    marginLeft: '.5em',
                                    marginTop: '1em',
                                    marginRight: '.5em',
                                  }}
                                >
                                  i
                                </SmallTitle>
                                <MonthListDropDown
                                  monthListValue={
                                    formikProps.values.bymonth === null ||
                                    formikProps.values.bymonth === undefined
                                      ? (
                                          this.state.startDate.getMonth() + 1
                                        ).toString()
                                      : formikProps.values.bymonth.toString()
                                  }
                                  disabled={!this.state.specialInterval}
                                  onMonthChange={(key: string) => {
                                    let monthNumber = +key + 1;
                                    formikProps.setFieldValue(
                                      'bymonth',
                                      monthNumber
                                    );
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      <div
                        style={{
                          backgroundColor:
                            formikProps.values.freq.toString() === '1'
                              ? '#efefef'
                              : '#fff',
                          padding:
                            formikProps.values.freq.toString() === '1'
                              ? '.5em'
                              : 0,
                          marginTop:
                            formikProps.values.freq.toString() === '1'
                              ? '1em'
                              : 0,
                          flexDirection: 'column',
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                          }}
                        >
                          {formikProps.values.freq.toString() === '1' && (
                            <CircleCheckBox
                              defaultChecked={true}
                              checked={!this.state.customMode}
                              onChange={() => {
                                if (this.state.customMode === true) {
                                  formikProps.setFieldValue('bymonth', null);
                                  selectedMonths = [];
                                  formikProps.setFieldValue('interval', 1);
                                  this.setState({
                                    customMode: !this.state.customMode,
                                    selectedDays: {},
                                    selectedMonths: {},
                                  });
                                }
                              }}
                            />
                          )}

                          <SmallTitle
                            style={{ fontSize: '12px', marginTop: '.5em' }}
                          >
                            {intl.formatMessage(messages.Every)}
                          </SmallTitle>

                          <TextField
                            disabled={
                              this.props.disabled || this.state.customMode
                            }
                            value={
                              (formikProps.values.interval &&
                                formikProps.values.interval.toString()) ||
                              ''
                            }
                            onChange={e => {
                              if (e.target.value !== 0) {
                                if (
                                  e.target.value <
                                  (formikProps.values.freq.toString() === '2'
                                    ? 53
                                    : 13)
                                ) {
                                  formikProps.handleChange(e);
                                }
                              }
                            }}
                            onBlur={formikProps.handleBlur}
                            name={'interval'}
                            style={{ width: '3em', marginRight: '.5em' }}
                            fieldGroupStyle={{
                              paddingBottom: '0px',
                              marginTop: '.5em',
                            }}
                          />

                          {formikProps.values.freq.toString() === '2' && (
                            <SmallTitle style={{ fontSize: '12px' }}>
                              {intl.formatMessage(messages.Weeks)}
                            </SmallTitle>
                          )}
                          {formikProps.values.freq.toString() === '1' && (
                            <SmallTitle
                              style={{ fontSize: '12px', marginTop: '.5em' }}
                            >
                              {intl.formatMessage(messages.Months)}
                            </SmallTitle>
                          )}
                          {formikProps.values.freq.toString() === '0' && (
                            <SmallTitle style={{ fontSize: '12px' }}>
                              {intl.formatMessage(messages.Years)}
                            </SmallTitle>
                          )}
                          {formikProps.values.freq.toString() === '1' && (
                            <>
                              <CircleCheckBox
                                style={{ marginLeft: '1em' }}
                                checked={this.state.customMode}
                                onChange={() => {
                                  if (this.state.customMode === false) {
                                    formikProps.setFieldValue('interval', null);
                                    selectedMonths.push(
                                      this.state.startDate.getMonth() + 1
                                    );
                                    formikProps.setFieldValue(
                                      'bymonth',
                                      selectedMonths
                                    );
                                    this.setState({
                                      customMode: !this.state.customMode,
                                    });
                                  }
                                }}
                              />
                              <SmallTitle
                                style={{ fontSize: '12px', marginTop: '.5em' }}
                              >
                                Egendefinert
                              </SmallTitle>
                            </>
                          )}
                        </div>
                        <div style={{ display: 'flex', marginTop: '1em' }}>
                          {formikProps.values.freq.toString() === '1' &&
                            this.state.customMode && (
                              <div>
                                <SmallTitle>Gjenta på</SmallTitle>
                                <WeekDaysDiv>
                                  {this.state.allMonths.map((v, i) => {
                                    return (
                                      <Circle
                                        key={i}
                                        onClick={() => {
                                          if (!this.props.disabled) {
                                            const isMonthThere =
                                              selectedMonths.indexOf(v.id) > -1;
                                            if (!isMonthThere) {
                                              selectedMonths.push(v.id);
                                              formikProps.setFieldValue(
                                                'bymonth',
                                                selectedMonths
                                              );
                                              this.handleMonthClick(v.value);
                                            } else {
                                              selectedMonths.splice(
                                                selectedMonths.indexOf(v.id),
                                                1
                                              );
                                              this.handleMonthClick(v.value);
                                              formikProps.setFieldValue(
                                                'bymonth',
                                                selectedMonths
                                              );
                                            }
                                          }
                                        }}
                                        isSelected={
                                          selectedMonths.indexOf(v.id) > -1
                                        }
                                      >
                                        {v.value}
                                      </Circle>
                                    );
                                  })}
                                </WeekDaysDiv>
                              </div>
                            )}
                        </div>
                      </div>

                      <div style={{ display: 'flex' }}>
                        {formikProps.values.freq.toString() === '2' && (
                          <div>
                            <SmallTitle>Gjenta på</SmallTitle>
                            <WeekDaysDiv>
                              {Days.map((v, i) => {
                                return (
                                  <Circle
                                    key={i}
                                    isSelected={selectedDays.indexOf(v.id) > -1}
                                    onClick={() => {
                                      if (!this.props.disabled) {
                                        const isdayThere =
                                          selectedDays.indexOf(v.id) > -1;

                                        // const isdayThere = this.state.selectedDays[v.id] !== undefined;
                                        if (!isdayThere) {
                                          this.handleDayClick(v.value);
                                          selectedDays.push(v.id);
                                          formikProps.setFieldValue(
                                            'byweekday',
                                            selectedDays
                                          );
                                        } else {
                                          this.handleDayClick(v.value);
                                          selectedDays.splice(
                                            selectedDays.indexOf(v.id),
                                            1
                                          );
                                          formikProps.setFieldValue(
                                            'byweekday',
                                            selectedDays
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    {v.value}
                                  </Circle>
                                );
                              })}
                            </WeekDaysDiv>
                          </div>
                        )}
                      </div>

                      {/*<div>*/}
                      {/*<SmallTitle>Starter:</SmallTitle>*/}
                      {/*</div>*/}
                      <div style={{ display: 'block', marginTop: '1em' }}>
                        <DateTimePicker
                          noEndDateMode={this.state.noEndDate}
                          disabled={this.props.disabled}
                          pickDate={true}
                          pickTime={false}
                          closeOnSelect={true}
                          label={intl.formatMessage(messages.Ends)}
                          onClickTextField={() => {
                            // let dateToday = new Date();
                            // let year = dateToday.getFullYear();
                            // let month = dateToday.getMonth();
                            // let day = dateToday.getDate();
                            // let endRepetition = new Date(year + 1, month, day);
                            // formikProps.setFieldValue('currentUntil', endRepetition);
                          }}
                          onChange={(date: Date) => {
                            if (this.state.noEndDate === true) {
                              this.setState({
                                noEndDate: false,
                              });
                            }
                            const fresh = moment(this.state.startDate)
                              .add(7, 'days')
                              .toDate();
                            if (moment(date).isAfter(moment(fresh))) {
                              this.setState({
                                showerrorEndDate: false,
                              });
                            }
                            formikProps.setFieldValue('currentUntil', date);
                          }}
                          value={formikProps.values.currentUntil}
                          name={'currentUntil'}
                          style={{ marginTop: '.5em' }}
                        />
                        {formikProps.values.currentUntil !== null && (
                          <RemoveRepetitionWaring
                            onClick={() => {
                              formikProps.setFieldValue('currentUntil', null);
                              this.setState({
                                noEndDate: true,
                              });
                            }}
                            style={{
                              width: 'max-content',
                              marginTop: '-.5em',
                              color: Colors.YELLOW,
                              fontSize: '14px',
                              fontFamily: 'Lato, sans-serif',
                            }}
                          >
                            {intl.formatMessage(commonMessages.removeEndDate)}
                          </RemoveRepetitionWaring>
                        )}
                      </div>
                      {this.state.showerrorEndDate && (
                        <div style={{ display: 'flex' }}>
                          <MunikumIcons.Star
                            style={{
                              color: Colors.RED,
                              marginRight: '4px',
                              width: '.7em',
                              height: '.7em',
                            }}
                          />
                          <div
                            style={{
                              color: Colors.RED,
                              fontSize: '14px',
                              fontFamily: 'Lato, sans-serif',
                            }}
                          >
                            <FormattedMessage
                              id={'repetitionPicker.repititionEndDateWarning'}
                              defaultMessage={
                                'The task will not repeat with this end date. {br} try changing it more ahead in time'
                              }
                              values={{
                                br: <br />,
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <BottomDiv>
                        <div>
                          <Button
                            size={ButtonSize.Small}
                            text={intl.formatMessage(
                              commonMessages.removeRepetition
                            )}
                            theme={ColorTheme.White}
                            style={{ alignSelf: 'flex-start' }}
                            onClick={() => {
                              formikProps.resetForm({
                                freq: 0,
                                dtstart: this.state.startDate,
                                until: null,
                                interval: null,
                                wkst: null,
                                count: null,
                                tzid: null,
                                bysetpos: null,
                                bymonth: null,
                                bymonthday: null,
                                bynmonthday: null,
                                byyearday: null,
                                byweekno: null,
                                byweekday: null,
                                bynweekday: null,
                                byhour: null,
                                byminute: null,
                                bysecond: null,
                                byeaster: null,
                                currentDtStart: this.state.startDate,
                                currentUntil: null,
                              });
                              this.setState({
                                specialInterval: false,
                                customMode: false,
                                showerrorEndDate: false,
                                isOpen: false,
                                noEndDate: true,
                                friendlyText: this.props.intl.formatMessage(
                                  messages.nothinChosen
                                ),
                              });
                              safeInvoke(this.props.handleRemoveRepetition);
                            }}
                          />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            size={ButtonSize.Small}
                            text={intl.formatMessage(commonMessages.cancel)}
                            theme={ColorTheme.White}
                            onClick={() => {
                              this.setState({
                                noEndDate:
                                  this.props.value.endRepetitionDate === null ||
                                  this.props.value.endRepetitionDate ===
                                    undefined,
                                isOpen: false,
                                specialInterval:
                                  this.props.value &&
                                  this.props.value &&
                                  this.props.value.repetitionDefinition
                                    ? this.props.value.repetitionDefinition.indexOf(
                                        '+'
                                      ) > 0
                                    : false,
                                showerrorEndDate: false,
                                customMode:
                                  hey.interval === null ||
                                  hey.interval === undefined,

                                startDate: moment(
                                  this.props.orgEndDate
                                ).toDate(),
                                dummyDate: this.props.orgEndDate
                                  .getDate()
                                  .toString(),
                              });
                              this.handleCancel(
                                moment(this.props.orgEndDate).toDate()
                              );
                            }}
                          />
                          <Button
                            disabled={this.props.disabled}
                            size={ButtonSize.Small}
                            text={intl.formatMessage(commonMessages.save)}
                            theme={ColorTheme.Red}
                            onClick={() => {
                              setTimeout(() => {
                                formikProps.submitForm();
                              });
                            }}
                            style={{
                              marginLeft: '.25em',
                            }}
                          />
                        </div>
                        {/*{rulePreview && f(rulePreview.all() as [])}*/}
                      </BottomDiv>
                    </div>
                    <RightSection>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '.5em',
                        }}
                      >
                        <Tooltip
                          position={'top-start'}
                          content={intl.formatMessage(
                            messages.repitionPreviewText
                          )}
                        >
                          <IconDiv>
                            <MunikumIcons.Info
                              style={{
                                color: '#FF5C39',
                                width: '1.2em',
                                height: '1.2em',
                              }}
                            />
                          </IconDiv>
                        </Tooltip>
                      </div>
                      {this.makeRulePreview(formikProps.values)}
                    </RightSection>
                  </div>
                );
              }}
            />
          </Popover>
          <ValidationSummary
            info={info}
            success={success}
            warning={warning}
            error={error}
          />
        </FieldGroup>
      </div>
    );
  }
}

export const RepetitionPicker = injectIntl(RepetitionPickerComp);
RepetitionPicker.defaultProps = {
  disabled: false,
};
