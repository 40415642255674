import { InjectedIntlProps, injectIntl, FormattedMessage } from 'react-intl';
import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import * as _ from 'lodash';
import memoizeOne from 'memoize-one';
import { commonLabels } from '../../language/commonLabels';
import { Colors, safeInvokeDeprecated } from '../../common';
import {
  IconButton,
  IconButtonHoverStyle,
} from '../../basic/IconButton/IconButton';
import { MunikumIcons } from '../../common/icons';
import { FieldsOnEventInstances } from '../../../models/types';

export interface IBlackSidebarProps {
  /**
   * array of instances used to list them . . .
   */
  instances: Array<FieldsOnEventInstances>;

  /**
   * id of selected instance, used to highlight selected item
   */
  selectedInstanceId?: string;

  /**
   * set to true if the Event / serie is selected
   */
  isEventSelected?: boolean;

  onClickSeries?: () => void;

  onClickInstance?: (item: FieldsOnEventInstances) => void;

  isRepetitionOn: boolean;
}

interface IBlackSidebarState {
  currentPage: number;
}

const EmptySidebar = styled.div`
  flex: 0 0 3em;
  background-color: #333;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;

  padding-top: 1em;
  padding-bottom: 1em;
  color: #ffffff;
`;

const EmptyRepeatDiv = styled.div`
  width: 100%;
  padding: 0.5em 1em;

  box-sizing: border-box;
`;

const Sidebar = styled.div`
  flex: 0 0 7.5em; // fixed width
  background-color: #333;
  color: #ffffff;

  border-top: 1px solid #333;
  border-left: 1px solid #333;
  border-bottom: 1px solid #333;
  border-top-left-radius: 0.25em;
  border-bottom-left-radius: 0.25em;

  padding-top: 1em;
  padding-bottom: 1em;

  overflow: hidden;
`;

interface IProps {
  isSelected: boolean;
}

const SidebarText = styled.div`
  font-family: Lato, sans-serif;
  font-size: 0.625em;
  text-transform: uppercase;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;

  color: ${(props: IProps) => (props.isSelected ? Colors.RED : Colors.WHITE)};

  //text-align: center;
`;

const SidebarItem = styled.div`
  display: flex;

  align-items: center;
  //justify-content: center;

  width: 100%;
  padding: 0.5em 1em;

  box-sizing: border-box;

  overflow: hidden;

  background-color: ${(props: IProps) =>
    props.isSelected ? 'hsla(0, 0%, 0%, .2)' : 'transparent'};

  cursor: pointer;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: hsla(0, 0%, 0%, 0.4);
  }
`;

const SidebarDivider = styled.div`
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  width: 100%;
  height: 2px;

  margin-bottom: 0.5em;
`;

const NowDivider = styled.div`
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  width: 100%;
  height: 2px;

  margin-bottom: 0.75em;
  margin-top: 0.25em;

  position: relative;
`;

const NowStuff = styled.div`
  position: absolute;
  top: -0.5em;
  left: calc(50% - 2em);

  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 0.5em;

  font-size: 0.425em;
  font-family: Lato, sans-serif;
  text-transform: uppercase;

  padding: 0.25em 0.5em;
`;

interface IStuff {
  items: Array<FieldsOnEventInstances>;
  page: number;
}

const itemsPerPage = 7;

class BlackSidebarComp extends React.PureComponent<
  IBlackSidebarProps & InjectedIntlProps,
  IBlackSidebarState
> {
  getSortedList = memoizeOne((instances: Array<FieldsOnEventInstances>) => {
    return _.sortBy(instances, 'endDate');
  });

  getSelectedIndex = memoizeOne(
    (
      sortedInstances: Array<FieldsOnEventInstances>,
      selectedInstanceId: string | undefined
    ) => {
      let selectedIndex = 0;
      sortedInstances.some((value, index) => {
        if (value.id === selectedInstanceId) {
          selectedIndex = index;
          return true;
        }
        return false;
      });

      return selectedIndex;
    }
  );

  constructor(props: IBlackSidebarProps & InjectedIntlProps) {
    super(props);

    const list = this.getSortedList(props.instances);
    const selectedIndex = this.getSelectedIndex(list, props.selectedInstanceId);
    const itemNumber = selectedIndex + 1;

    let initialPage = 0;

    if (itemNumber % itemsPerPage === 10) {
      initialPage = Math.floor(itemNumber / itemsPerPage);
    } else {
      initialPage = Math.floor(selectedIndex / itemsPerPage);
    }

    this.state = {
      currentPage: initialPage,
    };

    // console.log('asdf', initialPage, selectedIndex);
  }

  // not recommended. . . .
  // static getDerivedStateFromProps(props: IBlackSidebarProps & InjectedIntlProps, state: IBlackSidebarState) {
  //   return null;
  // }

  render() {
    const { intl, instances, selectedInstanceId, isRepetitionOn } = this.props;
    const { currentPage } = this.state;

    const items = this.getSortedList(instances);

    let maxPage = 0;
    if (items.length % 10 !== 0) {
      maxPage = Math.floor(items.length / itemsPerPage + 1);
    } else {
      maxPage = Math.floor(items.length / itemsPerPage);
    }

    const limited = items.slice(
      currentPage * itemsPerPage,
      currentPage * itemsPerPage + itemsPerPage
    );

    if (!isRepetitionOn) {
      return (
        <EmptySidebar>
          <EmptyRepeatDiv>
            <MunikumIcons.Repeat
              style={{
                color: Colors.GREY208,
                width: '1em',
                height: '1em',
                marginRight: '.5em',
              }}
            />
          </EmptyRepeatDiv>
          <SidebarDivider />
        </EmptySidebar>
      );
    }

    return (
      <Sidebar>
        <SidebarItem
          key={'event3345'}
          isSelected={this.props.isEventSelected || false}
          onClick={() => safeInvokeDeprecated(this.props.onClickSeries)}
        >
          <MunikumIcons.Repeat
            style={{
              color: '#FF5C39',
              width: '1em',
              height: '1em',
              marginRight: '.5em',
              flex: '0 0 auto',
            }}
          />
          <SidebarText isSelected={false}>
            {intl.formatMessage(commonLabels.serie)}
          </SidebarText>
        </SidebarItem>
        <SidebarDivider />

        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '.25em',
              marginLeft: '1.6em',
            }}
          >
            <IconButton
              icon={MunikumIcons.ArrowUp}
              style={{
                fontSize: '.6em',
              }}
              iconColor={Colors.WHITE}
              hoverStyle={IconButtonHoverStyle.SQUARE}
              disabled={currentPage === 0}
              onClick={() => {
                this.setState({
                  currentPage: currentPage - 1,
                });
              }}
            />
          </div>

          {limited.map((item: FieldsOnEventInstances, i: number) => {
            const isInstanceSelected = item.id === selectedInstanceId;
            // const currDate = moment(item.endDate);
            // const addDivider =
            //   prevDate.isBefore(today) &&
            //   currDate.isAfter(today) &&
            //   i !== 0 &&
            //   i < sortedInstances.length - 1;
            // prevDate = moment(item.endDate);

            return (
              <div key={'is' + item.id + '_' + i}>
                <SidebarItem
                  isSelected={isInstanceSelected}
                  onClick={() => {
                    safeInvokeDeprecated(this.props.onClickInstance, item);
                  }}
                >
                  <SidebarText isSelected={isInstanceSelected}>
                    {moment(item.endDate || undefined).format('DD-MM-YY')}
                  </SidebarText>
                </SidebarItem>
                {/*{addDivider && (*/}
                {/*<NowDivider>*/}
                {/*<NowStuff>*/}
                {/*<FormattedMessage*/}
                {/*id={'BlackSidebar.now'}*/}
                {/*defaultMessage={'Now'}*/}
                {/*/>*/}
                {/*</NowStuff>*/}
                {/*</NowDivider>*/}
                {/*)}*/}
              </div>
            );
          })}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '.25em',
              marginLeft: '1.6em',
            }}
          >
            <IconButton
              icon={MunikumIcons.ArrowDown}
              style={{
                fontSize: '.6em',
              }}
              iconColor={Colors.WHITE}
              hoverStyle={IconButtonHoverStyle.SQUARE}
              disabled={currentPage + 1 === maxPage}
              onClick={() => {
                this.setState({
                  currentPage: currentPage + 1,
                });
              }}
            />
          </div>
        </div>
      </Sidebar>
    );
  }
}

export const BlackSidebar = injectIntl(BlackSidebarComp);
