import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Colors, IDisabledProps, safeInvokeDeprecated } from '../../common';
import { formatPercentage, TooltipRenderFunc } from './Slider';
import { Tooltip } from '../Tooltip/Tooltip';

const HandleSpan = styled.span`
  height: 0.85em;
  width: 0.85em;
  border: 1px solid ${Colors.GREYBORDER};
  border-radius: 4px;
  background-color: ${Colors.GREY};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  position: absolute;
  top: 0;
  left: 0;
  cursor: ${(props: { isMoving: boolean }) =>
    props.isMoving ? 'grabbing' : 'grab'};
`;

export interface IHandleProps {
  min: number;
  max: number;
  stepSize: number;
  tickSizeInPixels: number;
  tickSizeRatio: number;
  value: number;
  trackLeftPosition: number;
  /**
   * set to false to hide track labels
   */
  showTooltip?: boolean;

  /**
   * tooltip on axis will always be visible not only when dragging
   */
  isTooltipSticky?: boolean;

  /**
   * custom tooltip render func
   */
  tooltipRenderer?: TooltipRenderFunc;
}

export interface IHandleDispatch {
  /**
   * when moving slider (only when steppung)
   * @param {number} newValue
   */
  onChange?: (newValue: number) => void;

  /**
   * releasing handle
   * @param {number} newValue
   */
  onRelease?: (newValue: number) => void;

  /**
   * start moving handle
   */
  onStartMoving?: () => void;
}

export interface IHandleState {
  isMoving: boolean;
}

/**
 * A handle that can be dragged using mouse
 * TODO:
 * - add touch support
 * - add keyboard support
 */
export class Handle extends React.PureComponent<
  IHandleProps & IDisabledProps & IHandleDispatch,
  IHandleState
> {
  public handleElement: HTMLElement;

  constructor(props: IHandleProps & IDisabledProps) {
    super(props);

    this.state = {
      isMoving: false,
    };
  }

  componentWillUnmount() {
    this.removeDocumentEventListeners();
  }

  private changeValue(newValue: number) {
    newValue = _.clamp(newValue, this.props.min, this.props.max);
    if (!isNaN(newValue) && this.props.value !== newValue) {
      safeInvokeDeprecated(this.props.onChange, newValue);
    }
  }

  /**
   * from client pixel position (relative?) to actual selected value
   * @param {number} clientPixel
   * @returns {number}
   */
  private clientToValue(clientPixel: number) {
    const { stepSize, tickSizeInPixels, value, trackLeftPosition } = this.props;

    if (this.handleElement === null) {
      return value;
    }

    // this is the CURRENT POSITION of handle div:
    const handleRect = this.handleElement.getBoundingClientRect();
    const handleOffset = handleRect.width / 2; // subtract!!

    // const pixelsPerStep = stepSize * tickSizeInPixels;
    const pixelsPerStep = tickSizeInPixels;

    // const calcStepsAccurate = (clientPixel - handleOffset) / pixelsPerStep;

    // const adjustedClientPixel = clientPixel - handleRect.left - handleOffset;

    const adjustedClientPixel = clientPixel - trackLeftPosition;

    const calcStepsAccurate = adjustedClientPixel / pixelsPerStep;
    const calcStep = Math.round(calcStepsAccurate);

    // console.log(
    //   'calculating value from pixel: ' +
    //     clientPixel +
    //     ' adjusted: ' +
    //     adjustedClientPixel +
    //     ' step: ' +
    //     calcStep +
    //     ' acc: ' +
    //     calcStepsAccurate
    // );

    return calcStep;
  }

  private handleMovedTo = (clientPixel: number) => {
    if (this.state.isMoving && !this.props.disabled) {
      const temp: number = this.clientToValue(clientPixel);
      // console.log('handleMoveTo PX:' + clientPixel + ' ==> value ===> ' + temp);
      this.changeValue(temp);
    }
  };

  private handleHandleMovement = (event: MouseEvent) => {
    this.handleMovedTo(event.clientX);
  };

  private handleMoveEndedAt = (clientPixel: number) => {
    this.removeDocumentEventListeners();
    this.setState({ isMoving: false });

    // not using changeValue because we want to invoke the handler regardless of current prop value
    const { onRelease } = this.props;
    const finalValue = _.clamp(
      this.clientToValue(clientPixel),
      this.props.min,
      this.props.max
    );

    safeInvokeDeprecated(onRelease, finalValue);
  };

  private endHandleMovement = (event: MouseEvent) => {
    this.handleMoveEndedAt(event.clientX);
  };

  beginHandleMovement = (event: MouseEvent | React.MouseEvent<HTMLElement>) => {
    document.addEventListener('mousemove', this.handleHandleMovement);
    document.addEventListener('mouseup', this.endHandleMovement);
    this.setState(
      {
        isMoving: true,
      },
      () => {
        safeInvokeDeprecated(this.props.onStartMoving);
      }
    );
  };

  private calculateHandlePosition() {
    if (this.handleElement === null || this.handleElement === undefined) {
      return '0%';
    }

    const currentValue = this.props.value;
    // tickSizeRatio == 0.01,
    // stepSize == 5
    // ticks in one step = 0.05
    // pixels per step: 20
    // percentage: .....??

    const positionRatio = this.props.tickSizeRatio * this.props.stepSize;

    // const calcPositionRatio = currentValue * positionRatio;

    const calcPositionRatio = currentValue / this.props.max;

    const calcPosPerc = formatPercentage(calcPositionRatio);

    const handleRect = this.handleElement.getBoundingClientRect();
    const handleOffset = handleRect.width / 2;

    const calcPosition = 'calc(' + calcPosPerc + ' - ' + handleOffset + 'px)';

    return calcPosition;
  }

  render() {
    const {
      disabled,
      showTooltip,
      isTooltipSticky,
      tooltipRenderer,
      min,
      max,
      value,
    } = this.props;
    const { isMoving } = this.state;

    const calculatedHandlePosition = this.calculateHandlePosition(); // 'calc(30% - 8px)';

    // console.log('handle render: ' + calculatedHandlePosition);

    const showTip =
      (showTooltip && isTooltipSticky) || (showTooltip && isMoving);
    let tooltipContent: JSX.Element | string = '';
    if (showTip) {
      if (tooltipRenderer !== undefined) {
        tooltipContent = tooltipRenderer({
          min: min,
          max: max,
          value: value,
          isHandleMoving: isMoving,
        });
      } else {
        tooltipContent = value.toString();
      }
    }
    // console.log('TIP: ' + showTip + ' sticky: ' + isTooltipSticky + ' isMoving: ' + isMoving);

    return (
      <HandleSpan
        // tslint:disable-next-line
        onMouseDown={(e: any) => {
          if (!disabled) {
            this.beginHandleMovement(e);
          }
        }}
        // tslint:disable-next-line
        ref={(ref: any) => (this.handleElement = ref)}
        style={{
          left: calculatedHandlePosition,
        }}
        isMoving={isMoving}
      >
        {showTip && (
          <Tooltip
            content={tooltipContent}
            position={'top'}
            isOpen={showTip}
            isAggressiveUpdateModeEnabled={true}
          >
            <div />
          </Tooltip>
        )}
      </HandleSpan>
    );
  }

  private removeDocumentEventListeners() {
    document.removeEventListener('mousemove', this.handleHandleMovement);
    document.removeEventListener('mouseup', this.endHandleMovement);
  }
}
