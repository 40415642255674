import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import styled from 'styled-components';
import * as Yup from 'yup';
import { IAuthCheckEmail, IAuthPin } from '../../modules/auth/auth';
import { commonLabels } from '../language/commonLabels';
import { commonMessages } from '../language/commonMessages';
import { loginMessages } from './loginMessages';
import { Button, TextField, ValidationSummary } from '../basic';
import { ColorTheme, safeInvokeDeprecated } from '../common';
import { MunikumKeys } from '../common/keys';
import { H2, TextNormal } from '../basic/Structural/typography';

export interface ILoginFormEmail {
  email?: string;
  pin?: string;
}

export interface ILoginFormEmailProps {
  onCheckEmail?: (values: Pick<ILoginFormEmail, 'email'>) => void;
  onVerifyPin?: (values: ILoginFormEmail) => void;
  authEmail: IAuthCheckEmail;
  authPin: IAuthPin;
  isOnline: boolean;
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 15em;
  //box-sizing: border-box;
`;

const Top = styled.div``;

const Pin = styled.div`
  // added ugly IE11 hack
  position: relative;
  left: -0.25em;
  overflow: hidden;
  padding-left: 0.25em;

  height: ${(props: { isVisible: boolean }) =>
    props.isVisible ? '6em' : '0em'};
  opacity: ${(props: { isVisible: boolean }) => (props.isVisible ? '1' : '0')};
  transition: all, 0.25s ease-in;
`;

class LoginFormEmailComponent extends React.PureComponent<
  ILoginFormEmailProps & InjectedIntlProps,
  {}
> {
  private pinRef: HTMLInputElement | null;
  UNSAFE_componentWillReceiveProps(nextProps: ILoginFormEmailProps) {
    const currentValidEmail =
      this.props.authEmail.validatedEmail &&
      this.props.authEmail.validatedEmail.valid;
    const incomingValidEmail =
      nextProps.authEmail.validatedEmail &&
      nextProps.authEmail.validatedEmail.valid;

    if (!currentValidEmail && incomingValidEmail) {
      // console.log('show me the stuff');
      if (this.pinRef !== null && this.pinRef !== undefined) {
        // console.log('focus');
        this.pinRef.focus();
      }
    }
  }

  render() {
    const { authEmail, authPin, intl, isOnline } = this.props;

    return (
      <Formik
        initialValues={{
          email: '',
          pin: '',
        }}
        validateOnBlur={true}
        validateOnChange={true}
        isInitialValid={false}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .label(intl.formatMessage(commonLabels.email))
            .required()
            .email(),
        })}
        onSubmit={(values: ILoginFormEmail, formikBag: any) => {
          // TODO: possible bug? we should use info from redux in stead?
          if (values.pin === '') {
            // send verify email and pin
            safeInvokeDeprecated(this.props.onCheckEmail, {
              email: values.email,
            });
          } else {
            // verify pin
            safeInvokeDeprecated(this.props.onVerifyPin, values);
          }
        }}
        render={(props: FormikProps<ILoginFormEmail>) => {
          const isPinBoxVisible =
            (authEmail.validatedEmail &&
              authEmail.validatedEmail.valid &&
              props.values.email !== undefined &&
              authEmail.validatedEmail.email.toLowerCase() ===
                props.values.email.toString().toLowerCase()) ||
            false;

          const isLoading = authEmail.isCheckingEmail || authPin.isLoggingInPin;

          const myErrors = Array<string>();

          if (authEmail.errorEmail === 'badRequest') {
            myErrors.push(
              intl.formatMessage(loginMessages.errorEmailBadRequest)
            );
          } else if (authEmail.errorEmail === 'tooManyAttempts') {
            // TODO: we could display a better message, try again in one hour => try again in 35 minutes?
            myErrors.push(
              intl.formatMessage(loginMessages.errorEmailTooManyAttempts)
            );
          } else if (authEmail.errorEmail === 'serverError') {
            myErrors.push(
              intl.formatMessage(loginMessages.errorEmailInternalServer)
            );
          } else if (authEmail.errorEmail === 'serverDown') {
            myErrors.push(
              intl.formatMessage(loginMessages.errorEmailFailedToFetch)
            );
          } else if (authEmail.errorEmail === 'nouser') {
            myErrors.push(intl.formatMessage(loginMessages.errorEmailNoUser));
          }

          // invalid email is successful authEmail result. only applies to non-partners . .:
          if (
            authEmail &&
            authEmail.validatedEmail &&
            !authEmail.validatedEmail.valid &&
            !authEmail.validatedEmail.isMunicipality
          ) {
            myErrors.push(
              intl.formatMessage(loginMessages.errorEmailInvalidOrganization, {
                email: authEmail.validatedEmail.email,
              })
            );
          }

          if (
            props.touched.email &&
            props.errors.email &&
            typeof props.errors.email === 'string'
          ) {
            myErrors.push(props.errors.email);
          }
          if (
            props.touched.pin &&
            props.errors.pin &&
            typeof props.errors.pin === 'string'
          ) {
            myErrors.push(props.errors.pin);
          }

          // verify PIN:
          if (authPin.errorPin) {
            myErrors.push(intl.formatMessage(loginMessages.errorPin));
          }

          // Offline warning:
          if (!isOnline) {
            myErrors.push(intl.formatMessage(loginMessages.errorOffline));
          }

          return (
            <Form onSubmit={props.handleSubmit}>
              <Top>
                <H2>{intl.formatMessage(loginMessages.title)}</H2>
                <TextNormal>
                  {intl.formatMessage(loginMessages.intro)} <br />
                </TextNormal>

                {/*<div>*/}
                {/*isSubmitting: {props.isSubmitting.toString()} <br/>*/}
                {/*isValid: {props.isValid.toString()} <br/>*/}
                {/*isDirty: {props.dirty.toString()}<br/>*/}
                {/*touched: <br/>{JSON.stringify(props.touched)}<br/>*/}
                {/*emailTouched: {props.touched.email ? 'YES' : 'NO'};*/}
                {/*values: {JSON.stringify(props.values)}*/}
                {/*</div>*/}

                <TextField
                  name={'email'}
                  label={intl.formatMessage(loginMessages.email)}
                  onKeyDown={(e: any) => {
                    if (e.which === MunikumKeys.ENTER) {
                      props.submitForm();
                      if (this.pinRef) {
                        this.pinRef.focus();
                      }
                    }
                  }}
                  value={props.values.email || ''}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  disabled={isLoading}
                  style={{ width: '100%' }}
                  fieldGroupStyle={{
                    marginTop: '1.5em',
                  }}
                  // disabled={isPinBoxVisible} // cant do this, what if email was wrong?? if we want this, add local state, disable, and let clicke re-enable?
                  placeholder={intl.formatMessage(
                    loginMessages.emailPlaceholder
                  )}
                  error={
                    props.touched.email !== undefined &&
                    props.errors.email !== undefined
                  }
                />
                <Pin isVisible={isPinBoxVisible}>
                  <TextField
                    name={'pin'}
                    autoComplete={'off'}
                    disabled={isLoading}
                    onKeyDown={(e: any) => {
                      if (e.which === MunikumKeys.ENTER) {
                        props.submitForm();
                      }
                    }}
                    label={intl.formatMessage(loginMessages.pinlabel)}
                    value={props.values.pin || ''}
                    onChange={(e: any) => {
                      props.setFieldValue(
                        'pin',
                        e.target.value.replace(/\s/g, '')
                      );
                    }}
                    style={{ width: '100%' }}
                    error={
                      props.errors.pin !== undefined ||
                      authPin.errorPin !== undefined
                    }
                    // tslint:disable-next-line
                    innerRef={(input: any) => (this.pinRef = input)}
                  />
                  {intl.formatMessage(loginMessages.pinInfo)}
                </Pin>

                <ValidationSummary error={myErrors} />
              </Top>

              <div style={{ marginTop: '1em' }}>
                <Button
                  text={
                    isPinBoxVisible
                      ? intl.formatMessage(loginMessages.login)
                      : intl.formatMessage(commonMessages.next)
                  }
                  theme={ColorTheme.Red}
                  style={{ width: '100%' }}
                  isLoading={
                    authEmail.isCheckingEmail || authPin.isLoggingInPin
                  }
                  onClick={() => {
                    props.submitForm();
                  }}
                />
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

export const LoginFormEmail = injectIntl(LoginFormEmailComponent);
