import enTranslationMessages from './messages-en.json';
import nnTranslationMessages from './messages-nn.json';
import nbTranslationMessages from './messages-nb.json';

export const DEFAULT_LOCALE = 'nn';

export const formatTranslationMessages = (locale: string, messages: any) => {
  const defaultFormattedMessages: any =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};

  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

// These messages are loaded by HOC and injected to app
export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  nn: formatTranslationMessages('nn', nnTranslationMessages),
  nb: formatTranslationMessages('nb', nbTranslationMessages),
};
