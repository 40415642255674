export enum DeadlineType {
  APPLICATION = 'APPLICATION',
  REPORT = 'REPORT',
}

export interface IDeadline {
  id?: string;
  date: Date;
  type?: DeadlineType;
  reminderSent?: boolean;
}

// export interface ICreateDeadline {
//   date: Date;
//   type?: DeadlineType;
// }
