import React from 'react';
import { IToastProps } from './Toast';

export interface IToastHelpers {
  addToast: (props: IToastProps) => string;
  removeToast: (id: string) => void;
}

const defaultHelpImplementation: IToastHelpers = {
  addToast: props => {
    return 'teit';
  },
  removeToast: id => {
    console.log('hei');
  },
};

export const ToasterContext = React.createContext<IToastHelpers>(
  defaultHelpImplementation
);

// function ToasterProvider () {
//   return(
//
//     {/*<ToasterContext.Provider>*/}
//   );
// }
