import styled from 'styled-components';
import * as React from 'react';
import { CSSProperties, DragEvent, SyntheticEvent } from 'react';
import { Popover } from '../Popover/Popover';
import { Colors, ColorTheme } from '../../common';
import Dropzone from 'react-dropzone';
import { FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';
import { Button, ButtonSize } from '..';
import { guid } from './FileCommon';
import { commonMessages } from '../../language/commonMessages';
import { commonLabels } from '../../language/commonLabels';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { convertToRaw, Editor, EditorState, RichUtils } from 'draft-js';
import { MunikumIcons } from '../../common/icons';
import { TextVerySmall } from '../Structural/typography';

interface IUpFileProps {
  file?: File;
  fileid: string;
  metaid: string;
  removeitem: (uuid: string) => void;
  addDescription?: (uuid: string, description: string) => void;
}

interface IUpFileState {
  editorState: EditorState;
}

const UpFileBox = styled.div`
  padding: 5px 0;
`;

const UpFileNameBox = styled.div`
  color: #222;
  font-weight: bold;
`;

const UpFileRemovalBox = styled.div`
  &:hover {
    cursor: pointer;
  }
  color: ${Colors.RED};
  text-align: right;
`;

const UpFileMetaBox = styled.div`
  padding-top: 3px;
  color: #999;
  font-size: 80%;
  display: flex;
  & > div {
    flex: 1;
  }
`;

const UpFileDescriptionBox = styled.div`
  padding: 5px 0;
  font-size: 80%;
  border: 1px solid #ddd;
  border-radius: 3px;
`;

class FileUploaderFile extends React.PureComponent<IUpFileProps, IUpFileState> {
  private editor = null;

  constructor(props: IUpFileProps) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
    };
  }

  focus = () => {
    this.editor.focus();
  };

  componentDidMount() {
    this.focus();
  }

  formatSize = (a: number, b?: number) => {
    if (0 === a) {
      return '0 Bytes';
    }
    let c = 1024,
      d = b || 2,
      e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      f = Math.floor(Math.log(a) / Math.log(c));

    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
  };

  handleRemoval = () => {
    this.props.removeitem(this.props.fileid);
  };

  onChange = editorState => {
    this.setState(
      {
        editorState,
      },
      () => {
        this.props.addDescription(
          this.props.fileid,
          JSON.stringify(
            convertToRaw(this.state.editorState.getCurrentContent())
          )
        );
      }
    );
  };

  handleKeyCommand(command: string, editorState: EditorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  render() {
    const { file } = this.props;

    return (
      <UpFileBox>
        {file && (
          <div>
            <UpFileNameBox>{file.name}</UpFileNameBox>
            <UpFileMetaBox>
              <div>{this.formatSize(file.size)}</div>
              <UpFileRemovalBox>
                <FaTimesCircle onClick={this.handleRemoval} />
              </UpFileRemovalBox>
            </UpFileMetaBox>
            <UpFileDescriptionBox onClick={this.focus}>
              <Editor
                editorState={this.state.editorState}
                handleKeyCommand={this.handleKeyCommand}
                onChange={this.onChange}
                placeholder={'Legg til beskrivelse av fila'}
                ref={element => {
                  this.editor = element;
                }}
              />
            </UpFileDescriptionBox>
          </div>
        )}
      </UpFileBox>
    );
  }
}

export interface IFileItem {
  file?: File;
  fileid?: string;
  filename: string;
  description?: string;
  persisted: boolean;
  uuid: string;
}

export interface IFileUploaderPickerProps {
  metaid?: number;
  onNewFiles?: (files: IFileItem[]) => void;
  style?: CSSProperties;
  shouldUsePaperClip?: boolean;
}

interface IFileUploaderFileType {
  fileExtension: string[];
  mimeType: string;
}

interface IFileUploaderState {
  rejected: IFileItem[];
  accepted: IFileItem[];
  maxSize: number;
  fileTypes: IFileUploaderFileType[];
  isActive: boolean;
  isRejected: boolean;
  isMaxed: boolean;
  rejectedText: string;

  isOpen: boolean;
}

const dropzoneStyle: CSSProperties = {
  border: '2px dotted lightgray',
  borderRadius: '5px',
  padding: '15px',
  textAlign: 'center',
};

const mainStyle: CSSProperties = {
  width: '300px',
  padding: 0,
};

const OuterBox = styled.div`
  display: inline-block;
`;

const HeaderBox = styled.div`
  color: #fff;
  background-color: #000;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  text-align: center;
  font-weight: bold;
`;

const MessageBox = styled.div`
  margin-top: 5px;
  padding: 5px 0px;
  color: #f00;
  font-weight: bold;
  text-align: center;
`;

const FileBox = styled.div`
  padding: 5px 0px;
`;

const MainBox = styled.div`
  color: #000;
  background-color: #fff;
  padding: 15px;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PaperClipDiv = styled.div`
  display: flex;
  color: #333;
  transition: 0.2s ease-in-out;
  :hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export class FileUploaderPickerComp extends React.PureComponent<
  InjectedIntlProps & IFileUploaderPickerProps,
  IFileUploaderState
> {
  constructor(props: IFileUploaderPickerProps & InjectedIntlProps) {
    super(props);
    this.state = {
      rejected: [],
      accepted: [],
      maxSize: 20000000,
      fileTypes: [
        { fileExtension: ['doc'], mimeType: 'application/msword' },
        {
          fileExtension: ['docx'],
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        },
        { fileExtension: ['xls'], mimeType: 'application/vnd.ms-excel' },
        {
          fileExtension: ['xlsx'],
          mimeType:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        { fileExtension: ['ppt'], mimeType: 'application/vnd.ms-powerpoint' },
        {
          fileExtension: ['pptx'],
          mimeType:
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        },
        { fileExtension: ['pdf'], mimeType: 'application/pdf' },
        { fileExtension: ['jpeg', 'jpg'], mimeType: 'image/jpeg' },
        { fileExtension: ['png'], mimeType: 'image/png' },
        { fileExtension: ['svg'], mimeType: 'image/svg+xml' },
        { fileExtension: ['txt'], mimeType: 'text/plain' },
      ],
      isActive: false,
      isRejected: false,
      isMaxed: false,
      rejectedText: '',
      isOpen: false,
    };
  }

  handleButtonClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  handleApply = () => {
    if (this.props.onNewFiles) {
      let newFiles = this.state.accepted.slice();
      // console.log(newFiles);
      this.props.onNewFiles(newFiles);
      this.setState({
        accepted: [],
        isOpen: false,
      });
    }
  };

  handleDropReject = (rejected: File[]) => {
    if (rejected[0] && rejected[0].size > this.state.maxSize) {
      this.setState({
        isRejected: true,
        isActive: false,
        rejectedText: this.props.intl.formatMessage(
          commonMessages.filepickerTooBig
        ),
      });
    } else {
      this.setState({
        isRejected: true,
        isActive: false,
        rejectedText: this.props.intl.formatMessage(
          commonMessages.filepickerNotAccepted
        ),
      });
    }
  };

  dragEnter = () => {
    // this.setState({
    //   isActive: true,
    // });
  };

  dragLeave = () => {
    // this.setState({
    //   isActive: false,
    // });
  };

  closePopup = (e: SyntheticEvent) => {
    this.setState({
      isRejected: false,
      rejectedText: '',
    });
  };

  isAcceptedFileType = (file: File) => {
    let ext = file.name.split('.').pop();

    let isAccepted = false;
    for (let testFileType of this.state.fileTypes) {
      if (file.type === testFileType.mimeType) {
        isAccepted = true;
      } else if (
        file.name &&
        ext &&
        ext.length > 0 &&
        testFileType.fileExtension.indexOf(ext) !== -1
      ) {
        isAccepted = true;
      }
    }

    return isAccepted;
  };

  handleDroppedFiles = (
    accepted: File[],
    rejected: File[],
    event: DragEvent<HTMLDivElement>
  ) => {
    let files = accepted.concat(rejected);

    let currentAccepted = this.state.accepted.slice();
    let currentRejected = this.state.rejected.slice();

    let currentRejectedText = '';

    let exists = false;
    for (let newFile in files) {
      // Continue to next file if file size is too big.
      if (files[newFile].size > this.state.maxSize) {
        currentRejectedText = this.props.intl.formatMessage(
          commonMessages.filepickerTooBig
        );
        // console.log(currentRejectedText);
        continue;
      }

      // Check if file already exists in accepted state.
      for (let curAccFile of currentAccepted) {
        if (
          curAccFile.file !== undefined &&
          curAccFile.file.name === files[newFile].name &&
          curAccFile.file.size === files[newFile].size
        ) {
          exists = true;
          continue;
        }
      }

      // Continue to next file if it already exists in accepted state.
      if (exists) {
        currentRejectedText = this.props.intl.formatMessage(
          commonMessages.filepickerIsSelected
        );
        // console.log(currentRejectedText);
        continue;
      }

      // Continue to next file if file type is not accepted.
      if (!this.isAcceptedFileType(files[newFile])) {
        currentRejectedText = this.props.intl.formatMessage(
          commonMessages.filepickerNotAccepted
        );
        // console.log(currentRejectedText);
        continue;
      }

      currentAccepted.push({
        file: files[newFile],
        filename: files[newFile].name,
        uuid: guid(),
        persisted: false,
      });
    }

    setTimeout(() => {
      this.setState(
        {
          accepted: currentAccepted,
          rejected: currentRejected,
          rejectedText: currentRejectedText,
        },
        () => {
          let self = this;
          setTimeout(function() {
            self.clearMessage();
          }, 3000);
        }
      );
    }, 100);
  };

  clearMessage = () => {
    this.setState({ rejectedText: '' });
  };

  removeItem = (uuid: string) => {
    // console.log(uuid);

    let currentAccepted = this.state.accepted.slice();

    let removeIndex = currentAccepted
      .map(function(item: IFileItem) {
        return item.uuid;
      })
      .indexOf(uuid);

    if (removeIndex !== -1) {
      currentAccepted.splice(removeIndex, 1);

      this.setState({
        accepted: currentAccepted,
      });
    }
  };

  addDescription = (uuid: string, description: string) => {
    let currentAccepted = this.state.accepted.slice();

    let idx = currentAccepted
      .map(function(item: IFileItem) {
        return item.uuid;
      })
      .indexOf(uuid);

    if (idx !== -1) {
      currentAccepted[idx].description = description;
      console.log((currentAccepted[idx].description = description));
      this.setState({
        accepted: currentAccepted,
      });
    }
  };

  hasFiles = () => {
    return this.state.accepted.length > 0;
  };

  render() {
    const { accepted, maxSize, isOpen } = this.state;
    const { intl, style } = this.props;

    return (
      <OuterBox style={style}>
        <Popover
          renderTarget={ref => {
            if (!this.props.shouldUsePaperClip) {
              return (
                <Button
                  theme={ColorTheme.White}
                  innerRef={ref}
                  text="Legg til fil"
                  onClick={this.handleButtonClick}
                />
              );
            } else {
              return (
                <PaperClipDiv ref={ref} onClick={this.handleButtonClick}>
                  <MunikumIcons.paperclip
                    style={{
                      opacity: 0.6,
                      transform: 'rotate(-42deg)',
                      marginRight: '4px',
                    }}
                  />
                  <TextVerySmall style={{ fontWeight: 'bold' }}>
                    Legg til fil
                  </TextVerySmall>
                </PaperClipDiv>
              );
            }
          }}
          isOpen={isOpen}
          position="top"
          style={mainStyle}
        >
          <HeaderBox>
            {intl.formatMessage(commonMessages.filepickerAddFiles)}
          </HeaderBox>
          <MainBox>
            <Dropzone
              onDrop={this.handleDroppedFiles}
              onDropRejected={this.handleDropReject}
              onDragEnter={this.dragEnter}
              onDragLeave={this.dragLeave}
              multiple={true}
              style={dropzoneStyle}
            >
              {ds => (
                <div>
                  <div>
                    {intl.formatMessage(commonMessages.filepickerAddMessage)}
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <Button
                      theme={ColorTheme.Red}
                      size={ButtonSize.Small}
                      text={intl.formatMessage(
                        commonMessages.filepickerChooseFileses
                      )}
                      style={{
                        width: '29%',
                      }}
                    />
                  </div>
                </div>
              )}
            </Dropzone>

            {this.state.rejectedText !== '' && (
              <MessageBox>
                <FaExclamationTriangle /> {this.state.rejectedText}
              </MessageBox>
            )}

            <FileBox>
              {accepted.map((f, index: number) => (
                <FileUploaderFile
                  metaid=""
                  file={f.file ? f.file : undefined}
                  fileid={f.uuid}
                  key={index}
                  removeitem={this.removeItem}
                  addDescription={this.addDescription}
                />
              ))}
            </FileBox>

            <ButtonBox>
              <Button
                theme={ColorTheme.White}
                size={ButtonSize.Medium}
                onClick={this.handleCancel}
                text={intl.formatMessage(commonLabels.Cancel)}
                style={{
                  width: '49%',
                }}
              />
              <Button
                theme={ColorTheme.Red}
                size={ButtonSize.Medium}
                onClick={this.handleApply}
                disabled={!this.hasFiles()}
                text={intl.formatMessage(
                  this.state.accepted.length > 1
                    ? commonMessages.filepickerChooseFiles
                    : commonMessages.filepickerChooseFile
                )}
                style={{
                  width: '49%',
                }}
              />
            </ButtonBox>
          </MainBox>
        </Popover>
      </OuterBox>
    );
  }
}

export const FileUploaderPicker = injectIntl(FileUploaderPickerComp);
