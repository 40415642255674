import * as React from 'react';

import styled from 'styled-components';
import { MunikumIcons } from '../common/icons';
import { InjectedIntlProps, injectIntl } from 'react-intl';

import { Colors, ColorTheme, formatMunikumDate, safeInvoke } from '../common';
import { Button, ButtonSize } from '../basic';
import {
  CardContainer,
  CardContentContainer,
  CardLastUpdated,
} from '../basic/Card/Card';
import { LinkWrapper } from '../basic/Structural/links';
import { commonMessages } from '../language/commonMessages';
import { Tag } from '../basic/Tag/Tag';
import { doDownloadFile } from '../basic/UserFile/FileCommon';
import { ITopicItem } from '../../services/models/topic';
import { sizes } from '../common/media';
import { Clap, ObjectType } from '../basic/Clap/Clap';
import { convertFromRaw, Editor, EditorState } from 'draft-js';

const HeaderRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -8px;
`;

const TitleAndLastUpdatedDiv = styled.div`
  flex: 2 2 0px;
  overflow: hidden;
  display: flex;
  align-items: center;

  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 14px;
`;

const FooterRowContainer = styled.div`
  display: block;

  @media (min-width: ${sizes.large}px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const CancleDiv = styled.div`
  transition: ease-in-out 0.2s;
  :hover {
    transform: scale(1.1);
  }
`;

export enum DocumentType {
  ActionValue,
  Comment,
  Topic,
  Discussion,
}
interface ITopicDocumentProps {
  readMore?: boolean;
  filename?: string;
  description?: string;
  canDelete: boolean;
  canEdit: boolean;
  lastUpdated?: Date;
  id?: string;
  likes: number;
  isLiked: boolean;
  documentType: DocumentType;
  onDeleteFile: () => void;
  uri?: string;
  topicId: string;
  onClickTag?: () => void;
  onClickDocument?: (id: string) => void;
}

class DocumentCardComp extends React.PureComponent<
  ITopicDocumentProps & InjectedIntlProps,
  {
    readMore: boolean;
    likes: number;
    isLiked: boolean;
  }
> {
  constructor(props: ITopicDocumentProps & InjectedIntlProps) {
    super(props);
    this.state = {
      readMore: false,
      likes: this.props.likes,
      isLiked: this.props.isLiked,
    };
  }

  handleReadMore = () => {
    this.setState({
      readMore: !this.state.readMore,
    });
  };

  handleClick = () => {
    if (this.props.id && this.props.onClickDocument) {
      // doDownloadFile(this.props.id);
      this.props.onClickDocument(this.props.id);
    }
  };

  render() {
    const { readMore } = this.state;
    const { intl } = this.props;

    let topics: ITopicItem[] = [];

    let fileDescContentState = null;
    if (
      this.props.description !== undefined &&
      this.props.description.length > 0
    ) {
      fileDescContentState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(this.props.description))
      );
    }

    return (
      <CardContainer canHover={true} style={{ marginBottom: '1em' }}>
        <CardContentContainer>
          <div>
            <HeaderRowContainer>
              <TitleAndLastUpdatedDiv>
                {!readMore && fileDescContentState && (
                  <MunikumIcons.ArrowRight
                    fill={'#333'}
                    width={'15px'}
                    height={'15px'}
                    style={{}}
                    onClick={this.handleReadMore}
                  />
                )}
                {readMore && fileDescContentState && (
                  <MunikumIcons.ArrowDown
                    fill={'#333'}
                    width={'15px'}
                    height={'15px'}
                    style={{}}
                    onClick={this.handleReadMore}
                  />
                )}
                <MunikumIcons.File
                  fill={'red'}
                  width={'23px'}
                  height={'26px'}
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}
                />

                <TitleAndLastUpdatedDiv onClick={this.handleClick}>
                  {this.props.filename}
                </TitleAndLastUpdatedDiv>
              </TitleAndLastUpdatedDiv>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    color: '#333333',
                    fontFamily: 'Lato, sans-serif',
                    fontSize: '12px',
                    lineHeight: '15px',
                    marginLeft: '1.4em',
                    marginRight: '.5em',
                  }}
                >
                  {this.state.likes} {intl.formatMessage(commonMessages.claps)}
                </div>
                <Clap
                  idToClap={this.props.id}
                  idToRefetch={this.props.topicId}
                  isClapped={this.props.isLiked}
                  ObjectToClap={ObjectType.TOPIC}
                  style={{ marginRight: '1em' }}
                  onClickClap={() => {
                    this.setState({
                      likes: this.state.isLiked
                        ? this.state.likes - 1
                        : this.state.likes + 1,
                      isLiked: !this.state.isLiked,
                    });
                  }}
                />
              </div>
              <CardLastUpdated
                style={{
                  marginRight:
                    this.props.documentType === DocumentType.Topic
                      ? '8em'
                      : '0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {formatMunikumDate(this.props.lastUpdated)}
              </CardLastUpdated>

              {this.props.documentType === DocumentType.ActionValue && (
                <LinkWrapper
                  to={'/action-value/' + this.props.uri}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '6em',
                  }}
                >
                  <Tag
                    hasShadow={true}
                    text={intl.formatMessage(commonMessages.actionValues)}
                    color={Colors.DARKYELLOW}
                    uppercase={false}
                    style={{ marginLeft: '10px' }}
                  />
                </LinkWrapper>
              )}
              {(this.props.documentType === DocumentType.Comment ||
                this.props.documentType === DocumentType.Discussion) && (
                <LinkWrapper
                  to={'/forum/public/' + this.props.uri}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '6em',
                  }}
                >
                  <Tag
                    hasShadow={true}
                    text={intl.formatMessage(commonMessages.forum)}
                    color={Colors.DARKRED}
                    uppercase={false}
                    style={{ marginLeft: '10px' }}
                  />
                </LinkWrapper>
              )}

              {/*{this.props.documentType === DocumentType.Topic && (*/}
              {/*<Tag*/}
              {/*hasShadow={false}*/}
              {/*text={intl.formatMessage(commonMessages.topic)}*/}
              {/*color={Colors.DARKBLUE}*/}
              {/*uppercase={false}*/}
              {/*style={{ marginLeft: '10px' }}*/}
              {/*/>*/}
              {/*)}*/}
              <div>
                {/*<ShareButton*/}
                {/*meta={''}*/}
                {/*style={{ marginLeft: '10px', marginRight: '10px' }}*/}
                {/*isSharing={true}*/}
                {/*/>*/}
              </div>
              <div
                style={{
                  marginRight: this.props.canDelete ? '0' : '2.4em',
                  marginLeft: '1em',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {this.props.canDelete && (
                  <CancleDiv>
                    <MunikumIcons.Cancel
                      style={{
                        marginLeft: '.5em',
                        fontSize: '1.6em',
                        color: Colors.YELLOW,
                      }}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        safeInvoke(this.props.onDeleteFile);
                      }}
                    />
                  </CancleDiv>
                )}
              </div>
            </HeaderRowContainer>
            {readMore && fileDescContentState && (
              <div
                style={{
                  marginLeft: '3.1em',
                  marginTop: '18px',
                  maxWidth: '36em',
                }}
              >
                <TitleAndLastUpdatedDiv>
                  <Editor
                    editorState={fileDescContentState}
                    readOnly={true}
                    onChange={null}
                  />
                </TitleAndLastUpdatedDiv>
                {/*<Button*/}
                {/*  text={'Gå til tilskudd'}*/}
                {/*  rightIcon={MunikumIcons.ArrowRight}*/}
                {/*  size={ButtonSize.Small}*/}
                {/*  theme={ColorTheme.White}*/}
                {/*  style={{ marginTop: '1em' }}*/}
                {/*/>*/}
              </div>
            )}
          </div>
        </CardContentContainer>
      </CardContainer>
    );
  }
}

export const TopicDocumentCard = injectIntl(DocumentCardComp);
