import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { Colors, safeInvoke } from '../common';
import { Avatar } from '../person/Avatar';
import { MunikumIcons } from '../common/icons';
import { Popover } from '../basic/Popover/Popover';
import { MunikumKeys } from '../common/keys';

export enum typeToList {
  ORGANIZATIONS = 'ORGANIZATIONS',
  PERSONS = 'PERSONS',
  ORGANIZATIONGROUPS = 'ORGANIZATIONGROUPS',
  PERSONGROUPS = 'PERSONGROUPS',
}
export const MemmbersContainer = styled.div`
  display: flex;
  border-radius: 3px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(208, 211, 212, 0.2);
  padding: 0.5em;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
  transition: box-shadow 0.2s ease-in-out;
  height: 40px;
  margin-bottom: 0.25em;
`;

const NameDiv = styled.div`
  width: 122px;
  color: #333333;
  font-size: 14px;
  overflow: hidden;
  margin-left: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ImgContainer = styled.div`
  display: flex;
  //background-color: #8b9898;
  flex: 0 0 32%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(51, 51, 51, 0.7);
  font-family: Lato, sans-serif;
  font-size: 12px;
  //height: 36px;
  align-items: center;
`;

const OrganizationDiv = styled.div`
  color: rgba(51, 51, 51, 0.7);
  font-family: Lato, sans-serif;
  font-size: 12px;
  max-width: 120px;
  margin-left: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PermissionCont = styled.div`
  display: flex;
  color: rgba(51, 51, 51, 0.7);
  font-family: Lato, sans-serif;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  justify-content: flex-start;

  :hover {
    cursor: ${(props: { canChangePermission: boolean }) =>
      props.canChangePermission ? 'pointer' : 'default'};
  }
`;

const RemoveDiv = styled.div`
  display: ${(props: { canRemove: boolean }) =>
    props.canRemove ? 'flex' : 'none'};
  transition: all 0.2s ease-in-out;
  width: 1.6em;
  height: 1.6em;
  justify-content: flex-end;
  align-items: center;
  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;
export const PermissionList = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  margin: 8px;
  &:hover {
    cursor: pointer;
  }
`;
interface IProps {
  name: string;
  disabled?: boolean;
  organization?: any;
  department?: any;
  role?: any;
  onChangeSelected?: (selectedKey: string) => void;
  permissionLevel: { value: string; name: string };
  handleRemoveMember: () => void;
  img: any;
  canRemove: boolean;
  leftImg?: any;
  profilePic?: string | null;
  type: typeToList;
  canChangePermission: boolean;
  onBlur?: React.FormEventHandler<HTMLElement>;
}

interface IMembersOfAnnualCycleCardCompState {
  isPermissionPopoverOpen: boolean;
  currentIndex: number;
}
let permissionList: Array<any> = [
  { value: 'READ', name: 'Lese' },
  { value: 'WRITE', name: 'Skrive' },
];
class MembersOfAnnualCycleCardComp extends React.PureComponent<
  IProps & InjectedIntlProps,
  IMembersOfAnnualCycleCardCompState
> {
  constructor(props: IProps & InjectedIntlProps) {
    super(props);
    this.state = {
      isPermissionPopoverOpen: false,
      currentIndex: -1,
    };
  }

  protected handleKeyUp = (e: any) => {
    if (e.which === MunikumKeys.ARROW_UP) {
      if (this.state.currentIndex > 0) {
        this.setState({
          currentIndex: this.state.currentIndex - 1,
        });
      }
    } else if (e.which === MunikumKeys.ARROW_DOWN) {
      if (this.state.currentIndex < permissionList.length - 1) {
        this.setState({
          currentIndex: this.state.currentIndex + 1,
        });
      }
    }

    // else if (e.which === MunikumKeys.ENTER) {
    //   if(this.props.canChangePermission) {
    //     const selectedItem = permissionList[this.state.currentIndex].value;
    //     safeInvoke(this.props.onChangeSelected, selectedItem);
    //     console.log(selectedItem, 'selecteditem');
    //     this.setState({
    //       isPermissionPopoverOpen: false,
    //       currentIndex: -1,
    //     });
    //   }
    // }
  };
  // componentDidMount() {
  //   document.addEventListener('keyup', this.handleKeyUp);
  // }
  //
  // componentWillUnmount() {
  //   document.removeEventListener('keyup', this.handleKeyUp);
  // }
  // const handleChange = (e: any) => {
  //   safeInvoke(p.onChangeSelected, e.target.value);
  // };
  render() {
    // console.log(this.props.img);
    const { onBlur } = this.props;
    let profilePic: string | undefined = undefined;
    if (this.props.profilePic) {
      profilePic = 'https://www.munikum.no/' + this.props.profilePic;
    }
    return (
      <MemmbersContainer key={''}>
        <ImgContainer>
          {profilePic && this.props.type === typeToList.PERSONS && (
            <img
              style={{
                borderRadius: '50%',
                flex: ' 0 0 25px',
              }}
              src={profilePic}
              width="25px"
              height="25px"
            />
          )}
          {this.props.type === typeToList.ORGANIZATIONS && this.props.img}
          {this.props.type === typeToList.PERSONGROUPS && (
            <MunikumIcons.Topic
              style={{
                width: '1.875em',
                height: '1.875em',
              }}
            />
          )}
          {this.props.type === typeToList.ORGANIZATIONGROUPS && (
            <MunikumIcons.KostraEducation
              style={{
                width: '1.875em',
                height: '1.875em',
              }}
            />
          )}
          {!profilePic && this.props.type === typeToList.PERSONS && (
            <Avatar style={{ flex: ' 0 0 1.875em' }} name={this.props.name} />
          )}

          <NameDiv> {this.props.name}</NameDiv>
        </ImgContainer>
        {this.props.type === typeToList.PERSONS && (
          <ImgContainer style={{ marginLeft: '1.8em' }}>
            {this.props.img}
            <OrganizationDiv>{this.props.organization}</OrganizationDiv>
          </ImgContainer>
        )}

        <div
          style={{
            display: 'flex',
            flex: '0 0 20%',
            marginRight:
              this.props.type === typeToList.PERSONS ? '0' : '-11.2em',
          }}
        >
          {this.props.canChangePermission && !this.props.disabled && (
            <Popover
              style={{ padding: '.5em' }}
              isDefaultOpen={false}
              position={'bottom-start'}
              isOpen={this.state.isPermissionPopoverOpen}
              usePortal={false}
              // target={(<div>Hello</div>)}
              renderTarget={ref => (
                <PermissionCont
                  onBlur={onBlur}
                  canChangePermission={this.props.canChangePermission}
                  ref={ref}
                  onClick={() => {
                    this.setState({
                      isPermissionPopoverOpen: !this.state
                        .isPermissionPopoverOpen,
                    });
                  }}
                >
                  {this.props.permissionLevel.name}
                  <MunikumIcons.ArrowDown
                    style={{ marginLeft: '.1em', marginBottom: '-.1em' }}
                  />
                </PermissionCont>
              )}
            >
              <div>
                {permissionList.map((c, i) => {
                  const isActive = this.state.currentIndex === i;
                  return (
                    <PermissionList
                      key={i}
                      onMouseEnter={() => {
                        this.setState({
                          currentIndex: i,
                        });
                      }}
                      onMouseLeave={() => {
                        this.setState({
                          currentIndex: -1,
                        });
                      }}
                      style={{
                        backgroundColor: isActive
                          ? 'rgba(208, 211, 212, 0.7)'
                          : '#fff',
                      }}
                      onClick={() => {
                        safeInvoke(this.props.onChangeSelected, c.value);
                        this.setState({
                          isPermissionPopoverOpen: false,
                          currentIndex: -1,
                        });
                      }}
                    >
                      {c.name}
                    </PermissionList>
                  );
                })}
              </div>
            </Popover>
          )}
          {!this.props.canChangePermission && (
            <PermissionCont
              canChangePermission={this.props.canChangePermission}
            >
              {this.props.permissionLevel.name}
            </PermissionCont>
          )}
        </div>

        <div
          style={{
            flex: '0 0 15%',
            display: 'flex',
            alignItems: 'center',
            justifyContent:
              this.props.type === typeToList.PERSONS ? 'flex-start' : 'center',
          }}
        >
          {this.props.canRemove && !this.props.disabled && (
            <RemoveDiv
              style={{
                color: 'rgb(242, 169, 0)',
                marginRight:
                  this.props.type === typeToList.PERSONS ? '0' : '.4em',
              }}
              canRemove={this.props.canRemove}
              onClick={() => {
                safeInvoke(this.props.handleRemoveMember);
              }}
            >
              <MunikumIcons.Cancel
                style={{
                  height: '1.2em',
                  width: '1.2em',
                  color: Colors.YELLOW,
                }}
              />
            </RemoveDiv>
          )}
          {!this.props.canRemove && <div style={{ marginLeft: '1.6em' }} />}
        </div>
      </MemmbersContainer>
    );
  }
}

export const MembersOfAnnualCycleCard = injectIntl(
  MembersOfAnnualCycleCardComp
);
MembersOfAnnualCycleCard.defaultProps = {
  disabled: false,
};
interface IMembersOfAnnualCycleCardEveryoneComp {
  onCancelClick?: () => void;
  style?: CSSProperties;
  disabled?: boolean;
}
export function MembersOfAnnualCycleCardEveryoneComp(
  props: IMembersOfAnnualCycleCardEveryoneComp
) {
  return (
    <MemmbersContainer style={props.style}>
      <ImgContainer>
        <MunikumIcons.Globe
          style={{ width: '1.875em', height: '1.875em', color: '#FF5C39' }}
        />
        <NameDiv>Alle</NameDiv>
      </ImgContainer>
      <div
        style={{
          color: 'rgba(51, 51, 51, 0.7)',
          fontFamily: 'Lato, sans-serif',
          fontSize: '12px',
          display: 'flex',
          alignItems: 'center',
          minWidth: '2em',
          marginRight: '-11.4em',
        }}
      >
        Lesetilgang
      </div>
      {!props.disabled && (
        <RemoveDiv
          style={{ marginRight: '1.45em' }}
          canRemove={true}
          onClick={() => {
            safeInvoke(props.onCancelClick);
          }}
        >
          <MunikumIcons.Cancel
            style={{
              height: '1.2em',
              width: '1.2em',
              color: 'rgb(242, 169, 0)',
            }}
          />
        </RemoveDiv>
      )}
    </MemmbersContainer>
  );
}
MembersOfAnnualCycleCardEveryoneComp.defaultProps = {
  disabled: false,
};
