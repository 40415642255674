import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Colors,
  IDisabledProps,
  ILabelProps,
  safeInvokeDeprecated,
} from '../../common';
import { FieldGroup } from '../FieldGroup/FieldGroup';
import { FormLabel } from '../FormLabel/FormLabel';
import { Handle } from './Handle';

export interface ITooltipRenderProps {
  min: number;
  max: number;
  value: number;
  isHandleMoving: boolean;
}

export type TooltipRenderFunc = (
  props: ITooltipRenderProps
) => JSX.Element | string;

export interface ISliderProps {
  initialValue?: number;

  min: number;

  max: number;

  /**
   * increment between values, how much does tha handle increase on move?
   */
  stepSize?: number;

  /**
   * increment between labels rendered below slider
   */
  labelStepSize?: number;

  /**
   * set to false to hide track labels
   */
  showTooltip?: boolean;

  /**
   * tooltip on axis will always be visible not only when dragging
   */
  isTooltipSticky?: boolean;

  /**
   * custom tooltip render func
   */
  tooltipRenderer?: TooltipRenderFunc;

  /**
   * fill track with RED color from initialValue to value
   */
  fillTrack?: boolean;

  /**
   * set this to put component in controlled mode
   */
  value?: number;
}

export interface ISliderDispatch {
  /**
   * fired on each change while sliding
   * @param {number} value
   */
  onChange?: (value: number) => void;

  /**
   * fired when handle is released
   * @param {number} value
   */
  onReleaseHandle?: (value: number) => void;

  /**
   * fired when we start dragging handle
   */
  onStartMovingHandle?: () => void;
}

export interface ISliderState {
  /**
   * selected value
   */
  value: number;

  /**
   * unique id used for label
   */
  id?: string;

  /**
   * one tick calculated pixels
   */
  tickSizeInPixels?: number;

  /**
   * one tick calculated percentage/ratio
   */
  tickSizeRatio: number;

  /**
   * track left start position on screen in pixels?
   */
  trackLeftPosition: number;

  /**
   * true if we're moving the handle
   */
  isHandleMoving: boolean;
}

const SliderContainer = styled.div`
  width: 100%;
  min-width: 150px;
  // height: handle is 1em
  height: ${(props: { showLabels: boolean }) =>
    props.showLabels ? '2.5em' : '1em'};
  outline: none;
  cursor: default;
  user-select: none;

  position: relative;

  //font-size: 1em;

  //background-color: lightpink;
`;

const AxisContainer = styled.div``;

const Track = styled.div`
  height: 0.25em; // 4px;
  border-radius: 10px;
  background-color: rgba(208, 211, 212, 0.4);

  left: 0;
  right: 0;
  top: 0.375em;
  position: absolute;
`;

interface ITrackFillProps {
  left: string;
  width: string;
}

const TrackFill = styled(Track)`
  background-color: ${Colors.RED};

  left: ${(props: ITrackFillProps) => props.left};
  width: ${(props: ITrackFillProps) => props.width};
`;

export class Slider extends React.PureComponent<
  ISliderProps & ISliderDispatch & IDisabledProps & ILabelProps,
  ISliderState
> {
  public static defaultProps: ISliderProps & IDisabledProps = {
    initialValue: 0,
    value: 0,
    min: 0,
    max: 10,
    stepSize: 1,
    labelStepSize: 1,
    fillTrack: true,
    showTooltip: false,
    isTooltipSticky: false,
  };

  private trackRef: HTMLDivElement | null;

  constructor(props: ISliderProps & IDisabledProps & ILabelProps) {
    super(props);

    this.state = {
      value: props.value || props.initialValue || 0,
      tickSizeRatio: 1,
      trackLeftPosition: 0,
      isHandleMoving: false,
    };
  }

  UNSAFE_componentWillMount() {
    const id = _.uniqueId('slider-');
    this.setState({
      id: id,
    });
  }

  componentDidMount() {
    // console.log('slider didmount');
    this.updateTickSize();
  }

  componentDidUpdate() {
    // console.log('slider didupdate');
    this.updateTickSize();
  }

  UNSAFE_componentWillReceiveProps(nextProps: ISliderProps) {
    if (
      nextProps.value !== undefined &&
      nextProps.value !== null &&
      nextProps.value !== this.state.value
    ) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  private updateTickSize() {
    if (this.trackRef !== null) {
      const trackSize = this.trackRef.clientWidth; // in pixels I guess
      const trackLength = this.props.max - this.props.min;
      const tickSizeRatio = 1 / trackLength;
      const tickSize = trackSize * tickSizeRatio;

      const temp = this.trackRef.getBoundingClientRect();

      this.setState(
        {
          ...this.state,
          tickSizeInPixels: tickSize,
          tickSizeRatio: tickSizeRatio,
          trackLeftPosition: temp.left,
        },
        () => {
          // console.log(
          //   'updatedTickSize: ' +
          //     this.state.tickSizeInPixels +
          //     ' ' +
          //     this.state.tickSizeRatio +
          //     ' left: ' +
          //     temp.left
          // );
        }
      );
    }
  }

  renderTrackFill() {
    if (!this.props.fillTrack) {
      return null;
    }

    const { tickSizeRatio, value } = this.state;

    // start filling slider FROM (in value):
    const fillFromValue = _.clamp(
      this.props.initialValue || 0,
      this.props.min,
      this.props.max
    );

    const offsetRatio = (fillFromValue - this.props.min) * tickSizeRatio; // ratio of 100% width
    const calculatedTrackFillLeft = formatPercentage(offsetRatio); // left: 30%

    const widthRatio = (this.state.value - fillFromValue) * tickSizeRatio;

    if (widthRatio <= 0) {
      return null;
    }

    const calculatedTrackFillWidth = formatPercentage(widthRatio); // width: 43%

    return (
      <TrackFill
        left={calculatedTrackFillLeft}
        width={calculatedTrackFillWidth}
      />
    );
  }

  handleHandleStartMoving = () => {
    this.setState(
      {
        isHandleMoving: true,
      },
      () => {
        safeInvokeDeprecated(this.props.onStartMovingHandle);
      }
    );
  };

  handleHandleRelease = (value: number) => {
    // console.log('handleHandleRelease: ' + value);
    this.setState(
      {
        isHandleMoving: false,
      },
      () => {
        safeInvokeDeprecated(this.props.onReleaseHandle, value);
      }
    );
  };

  handleHandleChange = (value: number) => {
    // console.log('handleHandleChange: ' + value);
    this.setState(
      {
        ...this.state,
        value: value,
      },
      () => {
        safeInvokeDeprecated(this.props.onChange, value);
      }
    );
  };

  render() {
    const {
      min,
      max,
      stepSize,
      disabled,
      showTooltip,
      isTooltipSticky,
      label,
      tooltipRenderer,
    } = this.props;

    const {
      value,
      id,
      trackLeftPosition,
      tickSizeRatio,
      tickSizeInPixels,
    } = this.state;

    return (
      <FieldGroup>
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <SliderContainer showLabels={false}>
          <Track ref={node => (this.trackRef = node)} />
          {this.renderTrackFill()}
          <Handle
            disabled={disabled}
            min={min}
            max={max}
            value={value}
            stepSize={stepSize || 1}
            tickSizeInPixels={tickSizeInPixels || 40}
            tickSizeRatio={tickSizeRatio}
            trackLeftPosition={trackLeftPosition}
            onRelease={this.handleHandleRelease}
            onChange={this.handleHandleChange}
            onStartMoving={this.handleHandleStartMoving}
            showTooltip={showTooltip}
            isTooltipSticky={isTooltipSticky}
            tooltipRenderer={tooltipRenderer}
          />
        </SliderContainer>
      </FieldGroup>
    );
  }
}

/** Helper function for formatting ratios as CSS percentage values. */
export function formatPercentage(ratio: number) {
  return `${(ratio * 100).toFixed(2)}%`;
}
