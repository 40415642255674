import * as React from 'react';
import { CSSProperties } from 'react';
import _ from 'lodash';
import {
  Colors,
  ILabelProps,
  IMessageProps,
  safeInvokeDeprecated,
} from '../../../common';
import { ValidationSummary } from '../..';
import { FieldGroup } from '../../FieldGroup/FieldGroup';
import { TextSmall } from '../../Structural/typography';
import { MuContentEditable } from '../MuContentEditable';
import { FormLabel } from '../../FormLabel/FormLabel';
import { EditorState } from 'draft-js';

export interface IMuFieldProps {
  name: string;
  value: string;

  // tabIndex?: number;

  placeholder?: string;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  // fieldGroupStyle?: CSSProperties;

  canEdit: boolean;
  isMultiLine: boolean;

  onChange?: (value: string) => void;
  onSubmit?: (value: string, contentState: EditorState) => void;
  onCancel?: (value: string) => void;
  onBlur?: (e: any) => void;
  // NOTE: we cant blur and let formik handle it.. its not a form field with id or name...
  // onBlur?: (e: any) => void;
}

interface IMuFieldState {
  id?: string;

  // keep internal copy of markdown to avoid translating in draft.js
  value: string;
}

/**
 * MuField is a contentEditable alternative to TextField and TextArea which displays
 * - text with SmallText component
 * - label
 * - validation messages
 *
 * WARNING: dont use onChange and value (controllable) unstable..
 * - use onSubmit and onCancel.
 * - blur / loose focus will trigger onSubmit
 *
 *
 * TODO:
 * - tabbing support. add tabIndex + onBlur stuff + css styling remove default tab active styling. . .
 *
 */
export class MuField extends React.PureComponent<
  IMuFieldProps & ILabelProps & IMessageProps,
  IMuFieldState
> {
  constructor(props: IMuFieldProps & ILabelProps & IMessageProps) {
    super(props);

    this.state = {
      id: undefined,
      value: props.value || '',
    };
  }

  UNSAFE_componentWillMount() {
    const id = _.uniqueId('muField-');
    this.setState({
      id: id,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: IMuFieldProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  render() {
    const {
      name,
      style,
      label,
      info,
      success,
      warning,
      error,
      // value,
      canEdit,
      isMultiLine,
      placeholder,
      textStyle,
    } = this.props;
    const { id, value } = this.state;

    return (
      <FieldGroup style={style}>
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <TextSmall
          color={Colors.BLACK}
          // tabIndex={this.props.tabIndex}
          style={textStyle}
        >
          <MuContentEditable
            id={id}
            // onBlur={this.props.onBlur}
            name={name}
            value={value}
            placeholder={placeholder}
            canEdit={canEdit}
            isMultiLine={isMultiLine}
            onChange={(markdown: string) => {
              if (markdown !== this.state.value) {
                safeInvokeDeprecated(this.props.onChange, markdown);
              }
            }}
            onSubmit={(markdown: string, contentState: EditorState) => {
              safeInvokeDeprecated(this.props.onSubmit, markdown, contentState);
            }}
            onCancel={(md: string) =>
              safeInvokeDeprecated(this.props.onCancel, md)
            }
            style={style}
          />
        </TextSmall>
        {/*{this.props.placeholder && (!value || value.length === 0) && }*/}

        <ValidationSummary
          info={info}
          success={success}
          warning={warning}
          error={error}
        />
      </FieldGroup>
    );
  }
}
