import { actionCreatorFactory, isType } from 'typescript-fsa';
import { Action } from 'redux';

const actionCreator = actionCreatorFactory('UI/MAIN');

export const toggleLeftMenu = actionCreator<{}>('TOGGLE_LEFT_MENU');

export const shouldScrollToTop = actionCreator.async<{}, {}, {}>(
  'SCROLL_TO_TOP'
);

export const shouldScrollToPosition = actionCreator.async<
  { x: number; y: number },
  {},
  {}
>('SCROLL_TO_POS');

export interface IMainLayoutState {
  isSidebarExpanded: boolean;
  shouldScrollToTop: boolean;
  shouldScrollToPosition?: { x: number; y: number };
}

export const reducer = (
  state: IMainLayoutState = {
    isSidebarExpanded: true,
    shouldScrollToTop: false,
    shouldScrollToPosition: undefined,
  },
  action: Action
): IMainLayoutState => {
  if (isType(action, toggleLeftMenu)) {
    return {
      ...state,
      isSidebarExpanded: !state.isSidebarExpanded,
    };
  }

  if (isType(action, shouldScrollToTop.started)) {
    return {
      ...state,
      shouldScrollToTop: true,
    };
  }
  if (isType(action, shouldScrollToTop.done)) {
    return {
      ...state,
      shouldScrollToTop: false,
    };
  }

  if (isType(action, shouldScrollToPosition.started)) {
    return {
      ...state,
      shouldScrollToPosition: { x: action.payload.x, y: action.payload.y },
    };
  }

  if (isType(action, shouldScrollToPosition.done)) {
    return {
      ...state,
      shouldScrollToPosition: undefined,
    };
  }

  return state;
};
