import * as React from 'react';
import styled from 'styled-components';
import { Colors, safeInvokeDeprecated } from '../../common';
import { Portal } from '../Portal/Portal';

export interface IOverlayProps {
  isOpen: boolean;
  // canEscapeKeyClose?: boolean;
  // canOutsideClickClose?: boolean;
  backgroundStyle?: 'shadow' | 'solidWhite';
  // fadeIn?: boolean;
  // fadeOut?: boolean;
  // tslint:disable-next-line
  didOpen?: () => void;
  onClose?: (event?: React.SyntheticEvent<HTMLElement>) => void;
  onClickShadow?: () => void;
}

export interface IOverlayState {
  hasEverOpened?: boolean;
}

const OverlayBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.BLACK50};
  overflow: auto;

  user-select: none;

  z-index: 20;
  //pointer-events: none;

  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  animation: opacity 2s ease;
`;

const OverlayShadowCoveringEverything = styled.div`
  position: absolute;
  opacity: 1;
  transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  height: 100%; // might fix IE 11 bug
  user-select: none;
  z-index: 20;
  background-color: ${Colors.BLACK50};
`;

/***
 * Overlay
 * - a overlay with shadows and transitions (using Portal)
 *
 * todo: we should set body.overflow: hidden when overlay is visible to avoid scrolling.. how??
 * TODO: remove mousedown event handlers, dont think they work...
 */
export class Overlay extends React.PureComponent<IOverlayProps, IOverlayState> {
  public static defaultProps: IOverlayProps = {
    isOpen: false,
    backgroundStyle: 'shadow',
  };
  private static openStack: Overlay[] = [];

  private containerElement: HTMLElement;
  private refHandlers = {
    container: (ref: HTMLDivElement) => (this.containerElement = ref),
  };

  public constructor(props: IOverlayProps) {
    super(props);
    this.state = { hasEverOpened: props!.isOpen };
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Portal
        containerRef={this.refHandlers.container}
        onChildrenMount={this.handleContentMount}
      >
        {isOpen && (
          <OverlayBackdrop isOpen={isOpen}>
            <OverlayShadowCoveringEverything
              onClick={() => safeInvokeDeprecated(this.props.onClickShadow)}
            >
              {this.props.children}
            </OverlayShadowCoveringEverything>
          </OverlayBackdrop>
        )}
      </Portal>
    );
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.overlayWillOpen();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IOverlayProps) {
    // console.log('overlay will receive');
    this.setState({
      hasEverOpened: this.state.hasEverOpened || nextProps.isOpen,
    });
  }

  componentDidUpdate(prevProps: IOverlayProps) {
    // console.log('overlay did update');
    if (prevProps.isOpen && !this.props.isOpen) {
      this.overlayWillClose();
    }
  }

  componentWillUnmount() {
    this.overlayWillClose();
  }

  private handleContentMount = () => {
    if (this.props.isOpen) {
      safeInvokeDeprecated(this.props.didOpen);
    }
  };

  private overlayWillClose() {
    const { openStack } = Overlay;
    const stackIndex = openStack.indexOf(this);
    if (stackIndex !== -1) {
      openStack.splice(stackIndex, 1);
      if (openStack.length === 0) {
        document.body.classList.remove('XYZ_OVERLAY_OPEN');
      }
    }
  }

  private overlayWillOpen() {
    // console.log('will open');

    const { openStack } = Overlay;
    openStack.push(this);

    // adding special classname to body, use this to disable scrolling when overlay is visible
    document.body.classList.add('XYZ_OVERLAY_OPEN');
  }
}
