import * as React from 'react';
import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import { MuEditorMode } from './MuEditor';
import { MuToolbarMode } from './MuToolbar';
import { MuInternalEditor } from './MuInternalEditor';

const draftjsImport = require('draft-js-import-markdown');

export interface IMuPreviewProps {
  rawMarkdown: string;
}

interface IMuPreviewState {
  rawMarkdown?: string;
  draftState?: EditorState;
}

/**
 * Use this to replace PreviewMarkdown component
 */
export class MuPreview extends React.PureComponent<
  IMuPreviewProps,
  IMuPreviewState
> {
  constructor(props: IMuPreviewProps) {
    super(props);

    const edison =
      props.rawMarkdown !== undefined
        ? props.rawMarkdown.substring(0, 1) === '{'
          ? EditorState.createWithContent(
              convertFromRaw(JSON.parse(props.rawMarkdown))
            )
          : EditorState.createWithContent(
              ContentState.createFromText(props.rawMarkdown)
            )
        : EditorState.createEmpty();

    this.state = {
      rawMarkdown: props.rawMarkdown || undefined,
      draftState: edison,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps: IMuPreviewProps) {
    if (
      nextProps.rawMarkdown !== undefined &&
      nextProps.rawMarkdown !== this.state.rawMarkdown
    ) {
      const edison =
        nextProps.rawMarkdown !== undefined
          ? nextProps.rawMarkdown.substring(0, 1) === '{'
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(nextProps.rawMarkdown))
              )
            : EditorState.createWithContent(
                ContentState.createFromText(nextProps.rawMarkdown)
              )
          : EditorState.createEmpty();

      this.setState({
        rawMarkdown: nextProps.rawMarkdown,
        draftState: edison,
      });
    }
  }

  render() {
    const { draftState } = this.state;
    return (
      <MuInternalEditor
        editorMode={MuEditorMode.Preview}
        toolbarMode={MuToolbarMode.None}
        draftState={draftState}
      />
    );
  }
}
