import * as _ from 'lodash';
import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import { call, put, take } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import moment from 'moment';
import { myHistory } from '../../index';
import { IFile } from '../../services/fileservice';
import {
  getDiscussionMetasActionCreator,
  getDiscussionsActionCreator,
  getFilesActionCreator,
} from '../entities/entities';
import { ContentStatus, IContentMeta } from '../../services/models/contentMeta';
import {
  createDiscussion,
  getDiscussion,
  IDiscussionCreateOrUpdateForm,
  updateDiscussion,
} from '../../services/discussionService';
import { createUploadFileChannel } from '../file/file';
import { IApiError, wrapApiError } from '../../services/api';
import { IDiscussion, IDiscussionItem } from '../../services/models/discussion';
import { IFileItem } from '../../components/basic/UserFile/FileUploaderPicker';
import { RouteComponentProps } from 'react-router';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { RootState } from '../rootReducer';
import { OrderEnum, Organization, SortEnum } from '../../models/types';

const actionCreator = actionCreatorFactory('DISCUSSION');

/**
 * Discussion redux module (actions, reducer, sagas, selectors). see also entities.ts for generic code!
 */

// actions

// action to start editing discussion. get data from api, put everything we need in state, and redirect to editpage
export const editDiscussionActionCreator = actionCreator.async<
  { id: string; isFromEditPage?: boolean },
  IDiscussionCreateOrUpdateForm,
  IApiError
>('UI_EDIT');

// close edit page
export const cancelEditDiscussionActionCreator = actionCreator<{}>(
  'UI_CANCEL_EDIT'
);

// update actions
export const updateDiscussionActionCreator = actionCreator.async<
  { form: IDiscussionCreateOrUpdateForm; files: IFileItem[] },
  IDiscussionCreateOrUpdateForm,
  IApiError
>('UPDATE');

// we'll skip keeping create in redux state.. could be a nice feature if we want to create, navigate, continue to edit..

// // action to start editing a new action value
// export const createDiscussionActionCreator = actionCreator.async<{ newItem: IDiscussionCreateOrUpdateForm }, { form: IDiscussionCreateOrUpdateForm, tempKey: string}, IApiError>('UI_CREATE');
//
// // cancel editing newly created action value
// export const cancelCreateDiscussionActionCreator = actionCreator<{tempKeyToRemove: string}>(
//   'UI_CANCEL_CREATE'
// );

// we also need one for create, since create is different than update:
export const saveDiscussionActionCreator = actionCreator.async<
  IDiscussionCreateOrUpdateForm,
  {},
  IApiError
>('SAVE');

export const filterDiscussionsActionCreator = actionCreator<{
  filter: IDiscussionsFilter;
}>('FILTER_DISCUSSION');

export const resetDiscussionFilterActionCreator = actionCreator<{}>(
  'CLIENT_RESET_FILTER'
);

export const discussionChangePage = actionCreator<{
  page: number;
}>('CHANGE_PAGE_DISCUSSION');

// special action to load data when showing list:
// export const loadDiscussionList = actionCreator<{}>('LOAD');

// special action to load single item from api when showing item?:

// TODO: we could add support for multiple open "files"/discussions? then we could edit, navigate, edit some more..
//
// interface ICreateItem {
//   isUpdating: boolean;
//   error?: string;
//   form: IDiscussionCreateOrUpdateForm;
//   opened: Date;
//   tempKey: string;
// }

export interface IDiscussionsFilter {
  search: string;
  page: 0;
  showOnlyMine: boolean;
  organizations: Array<Organization>;
  order: OrderEnum.DESCENDING;
  limit: 10;
  // if you go throug myMunicipality show diffent title
  predefinedMunicipality: boolean;
  sort: SortEnum.LAST_UPDATED;
  sortValue: '1';
  offset: 0;
}

export interface IDiscussionState {
  edit: {
    isPreparingToEdit: boolean;
    error?: string;
    form?: IDiscussionCreateOrUpdateForm;
  };
  update: {
    isUpdating: boolean;
    error?: string;
  };
  create: {
    isSavingDraft: boolean;
    isSavingPublished: boolean;
    error?: string;
  };
  list: {
    filter: IDiscussionsFilter;
  };
}

const initialState: IDiscussionState = {
  edit: {
    isPreparingToEdit: false,
  },
  update: {
    isUpdating: false,
  },
  create: {
    isSavingDraft: false,
    isSavingPublished: false,
  },
  list: {
    filter: {
      predefinedMunicipality: false,
      search: '',
      page: 0,
      organizations: [],
      showOnlyMine: false,
      order: OrderEnum.DESCENDING,
      limit: 10,
      sort: SortEnum.LAST_UPDATED,
      sortValue: '1',
      offset: 0,
    },
  },
};

export const reducer = (
  state: IDiscussionState = initialState,
  action: Action
): IDiscussionState => {
  if (isType(action, resetDiscussionFilterActionCreator)) {
    return {
      ...state,
      list: {
        ...state.list,
        filter: {
          predefinedMunicipality: false,
          organizations: [],
          search: '',
          showOnlyMine: false,
          sort: SortEnum.LAST_UPDATED,
          order: OrderEnum.DESCENDING,
          limit: 10,
          sortValue: '1',
          offset: 0,
          page: 0,
        },
      },
    };
  }

  if (isType(action, filterDiscussionsActionCreator)) {
    return {
      ...state,
      list: {
        ...state.list,
        filter: {
          ...action.payload.filter,
          offset:
            action.payload.filter.offset === state.list.filter.offset
              ? 0
              : action.payload.filter.offset,
        },
      },
    };
  }

  if (isType(action, saveDiscussionActionCreator.started)) {
    return {
      ...state,
      create: {
        error: undefined,
        isSavingPublished: action.payload.status === ContentStatus.PUBLISHED,
        isSavingDraft: action.payload.status === ContentStatus.DRAFT,
      },
    };
  }
  if (isType(action, saveDiscussionActionCreator.success)) {
    return {
      ...state,
      create: {
        isSavingPublished: false,
        isSavingDraft: false,
        error: undefined,
      },
    };
  }
  if (isType(action, saveDiscussionActionCreator.failed)) {
    return {
      ...state,
      create: {
        isSavingPublished: false,
        isSavingDraft: false,
        error: action.payload.error.message,
      },
    };
  }

  if (isType(action, updateDiscussionActionCreator.started)) {
    return {
      ...state,
      update: {
        isUpdating: true,
        error: undefined,
      },
    };
  }
  if (isType(action, updateDiscussionActionCreator.success)) {
    return {
      ...state,
      update: {
        isUpdating: false,
        error: undefined,
      },
    };
  }
  if (isType(action, updateDiscussionActionCreator.failed)) {
    return {
      ...state,
      update: {
        isUpdating: false,
        error: action.payload.error.message,
      },
    };
  }

  if (isType(action, editDiscussionActionCreator.started)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: true,
        error: undefined,
      },
    };
  }
  if (isType(action, editDiscussionActionCreator.success)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: undefined,
        form: action.payload.result,
      },
    };
  }
  if (isType(action, editDiscussionActionCreator.failed)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: action.payload.error.message,
      },
    };
  }
  if (isType(action, cancelEditDiscussionActionCreator)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: undefined,
        form: undefined,
      },
    };
  }
  return state;
};

export function* editDiscussionListenSaga() {
  while (true) {
    // console.log('waiting for edit action');
    const action = yield take(editDiscussionActionCreator.started.type);
    // console.log('picked up edit discussion aciton');

    try {
      const avId = action.payload.id;
      if (!avId) {
        throw Error('no id specified, edit aborted');
      }

      // console.log('get from api/db, discussion with id: ' + avId);
      const content = yield call(getDiscussion, avId);

      // setup edit discussion data:
      const form: IDiscussionCreateOrUpdateForm = {
        id: content.id,
        title: content.title,
        description: content.description,
        body: content.body,
        status: content.status,
        source: content.source,
        sectors: content.sectors,
        validOrganizationTypes: content.validOrganizationTypes,
        type: content.type,
        realms: content.realms,
      };

      // put item ready to edit on redux global state:
      yield put(
        editDiscussionActionCreator.success({
          params: { id: content.id },
          result: form,
        })
      );

      // redirect to edit page (if not already there. hmm.. how do we know? do we have to know?)
      if (action.payload.isFromEditPage) {
        console.log('already there, nothing to redirect to.. .');
      } else {
        // here we put (saga) a redux-router push action
        myHistory.push(`/forum/edit/${content.id}`);
      }
    } catch (e) {
      yield put(
        editDiscussionActionCreator.failed({
          params: { id: action.payload.id },
          error: wrapApiError(e),
        })
      );

      // TODO: show as toast?
      console.log('error editing', e);

      // yield put(push('/forum/'));
    }
  }
}

export function* updateDiscussionSaga() {
  while (true) {
    const action = yield take(updateDiscussionActionCreator.started.type);
    // console.log('SAGA action', action);
    try {
      if (action.payload.form.id === undefined) {
        throw Error('Cannot update discussion without id!');
      }

      const tempMetaLimit = new Date();

      console.log('update discussion...');

      // updating discussion
      const result = yield call(updateDiscussion, action.payload.form);
      console.log('SAGA updateDiscussionSaga', result);

      // Save new files.
      if (action.payload.files && action.payload.files.length > 0) {
        for (let sFile of action.payload.files) {
          const channel = yield call(createUploadFileChannel, {
            file: sFile.file,
            metaid: result.metaid,
          });

          while (true) {
            const { success, err } = yield take(channel);
            if (err) {
              throw Error('File upload failed');
            }
            if (success) {
              break;
            }
          }
        }

        yield put(
          getFilesActionCreator.started({ lastUpdated: tempMetaLimit })
        );
      }

      // get new discussion
      // yield put(getDiscussionActionCreator.started({ id: result.id }));

      yield put(
        getDiscussionsActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      // make sure meta is updated
      yield put(
        getDiscussionMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      // update success state
      yield put(
        updateDiscussionActionCreator.success({
          params: action.payload.form,
          result: result,
        })
      );

      // navigate back to list
      // yield put(push(`/forum/` + action.payload.forum));
    } catch (e) {
      yield put(
        updateDiscussionActionCreator.failed({
          params: action.payload.form,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export function* saveDiscussionSaga() {
  while (true) {
    const action = yield take(saveDiscussionActionCreator.started.type);
    try {
      const tempMetaLimit = new Date();

      // 1. save action value. if great success; discussion is returned as result
      const result = yield call(createDiscussion, action.payload);

      // Save new files.
      if (action.payload.files && action.payload.files.length > 0) {
        for (let sFile of action.payload.files) {
          const channel = yield call(createUploadFileChannel, {
            file: sFile.file,
            metaid: result.metaid,
          });

          while (true) {
            const { success, err } = yield take(channel);
            if (err) {
              throw Error('File upload failed');
            }
            if (success) {
              break;
            }
          }
        }

        yield put(
          getFilesActionCreator.started({ lastUpdated: tempMetaLimit })
        );
      }

      yield put(
        getDiscussionsActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      yield put(
        getDiscussionMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      yield put(
        saveDiscussionActionCreator.success({
          params: action.payload,
          result: result,
        })
      );

      myHistory.push(`/forum/` + action.payload.forum);
    } catch (e) {
      yield put(
        saveDiscussionActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

// DISCUSSION

const forumListTransformer = (forumDict: {
  [byId: string]: Readonly<IContentMeta>;
}) => _.values(forumDict);

const discussionMetaListTransformer = (discussionMetaDict: {
  [byId: string]: Readonly<IContentMeta>;
}) => _.values(discussionMetaDict);

const discussionListTransformer = (discussionDict: {
  [byId: string]: Readonly<IDiscussion>;
}) => {
  // console.log('discussionListTransformer');
  return _.values(discussionDict);
};

export const forumListSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IContentMeta> },
  ReadonlyArray<IContentMeta>
>(
  state => state.entities.forums.byId,
  forumListTransformer
);

export const discussionMetaListSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IContentMeta> },
  ReadonlyArray<IContentMeta>
>(
  state => state.entities.discussionMetas.byId,
  discussionMetaListTransformer
);

export const discussionMetaDictSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IContentMeta> },
  { [byId: string]: Readonly<IContentMeta> }
>(
  state => state.entities.discussionMetas.byId,
  res => res
);

export const discussionDictSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IDiscussion> },
  { [byId: string]: Readonly<IDiscussion> }
>(
  state => state.entities.discussions.byId,
  res => {
    return res;
  }
);

const mergeDiscussionMetaAndCurrentRevision = (
  metaList: ReadonlyArray<IContentMeta>,
  valueDict: { [byId: string]: Readonly<IDiscussion> }
) => {
  if (metaList === undefined || metaList.length === 0) {
    return [];
  }

  if (valueDict === undefined || Object.keys(valueDict).length === 0) {
    return [];
  }

  const list = metaList.map(metaItem => {
    const newItem: IDiscussionItem = {
      meta: metaItem,
      content: valueDict[metaItem.currentRevision],
    };
    return newItem;
  });

  return list;
};

export const discussionItemListSelector = createSelector(
  discussionMetaListSelector,
  discussionDictSelector,
  mergeDiscussionMetaAndCurrentRevision
);

/**
 * DiscussionItemListSortedselector with param from URL, filter from redux global state,
 * sorting + filtering in cached transform func.
 *
 * First three functions are selectors, S1 = unsorted list, S2 = filter, S3 = param from uri
 * Last function is the cached transform function which filters and sorts the list
 */
export const discussionItemListSortedSelector = createSelector<
  RootState, // Our global state
  RouteComponentProps<{ forum: string }>, // P = Param!
  ReadonlyArray<IDiscussionItem>, // S1 = list of discussion items unsorted
  IDiscussionsFilter, // S2 = filter for this list
  string | undefined, // S3 forum id from uri
  string, // S4 HASH
  ReadonlyArray<IDiscussionItem> // T = sorted and filtered list
>(
  discussionItemListSelector,
  (state: RootState) => {
    return state.discussion.list.filter;
  },
  (state: RootState, props: RouteComponentProps<{ forum: string }>) => {
    const forum = props.match.params.forum; // public or other
    const forumId = forum.substring(forum.lastIndexOf('-') + 1, forum.length);
    return forumId;
  },
  (state: RootState) => {
    return (
      (state.auth &&
        state.auth.user &&
        state.auth.user.profile &&
        state.auth.user.profile.hash) ||
      ''
    );
  },
  (
    r1UnsortedList: ReadonlyArray<IDiscussionItem>,
    r2Filter: IDiscussionsFilter,
    r3ForumId: string | number | undefined,
    r4userhash: string
  ) => {
    let filteredList: IDiscussionItem[] = [];

    r1UnsortedList.forEach(value => {
      if (value.meta.title) {
        filteredList.push(value);
      }
    });

    // Sort by last answer
    if (filteredList) {
      filteredList.sort(
        (a, b) =>
          moment.utc(b.meta.lastComment).unix() -
          moment.utc(a.meta.lastComment).unix()
      );
    }

    filteredList = _.filter(filteredList, (item: IDiscussionItem) => {
      if (item.content === undefined || item.meta === undefined) {
        return false;
      }

      if (r2Filter.showOnlyMine) {
        if (item.meta.createdBy.hash !== r4userhash) {
          return false;
        }
      }

      if (r2Filter.search && r2Filter.search.length > 0) {
        const test1 =
          item.content.title !== undefined &&
          item.content.title !== null &&
          item.content.title
            .toLowerCase()
            .indexOf(r2Filter.search.toLowerCase()) > -1;
        const test2 =
          item.content.body !== undefined &&
          item.content.body !== null &&
          item.content.body
            .toLowerCase()
            .indexOf(r2Filter.search.toLowerCase()) > -1;

        if (!test1 && !test2) {
          return false;
        }
      }

      return true;
    });

    // console.log('filter transform func ' + filteredList.length);

    return filteredList;
  }
);

const mergeDiscussionMetaAndCurrentRevisionToDict = (
  metaDict: { [byId: string]: Readonly<IContentMeta> },
  valueDict: { [byId: string]: Readonly<IDiscussion> }
) => {
  if (metaDict === undefined || Object.keys(metaDict).length === 0) {
    return [];
  }
  if (valueDict === undefined || Object.keys(valueDict).length === 0) {
    return [];
  }

  const itemDict: { [byId: string]: IDiscussionItem } = {};

  for (let key in metaDict) {
    itemDict[key] = {
      meta: metaDict[key],
      content: valueDict[metaDict[key].currentRevision],
    };
  }

  return itemDict;
};

export const discussionItemDictSelector = createSelector(
  discussionMetaDictSelector,
  discussionDictSelector,
  mergeDiscussionMetaAndCurrentRevisionToDict
);

export const discussionFollowedListSelector = createSelector<
  RootState,
  ReadonlyArray<IContentMeta>,
  { [byId: string]: Readonly<IDiscussion> },
  ReadonlyArray<IDiscussionItem>
>(
  discussionMetaListSelector,
  discussionDictSelector,
  (
    metaList: ReadonlyArray<IContentMeta>,
    valueDict: { [byId: string]: Readonly<IDiscussion> }
  ): ReadonlyArray<IDiscussionItem> => {
    // if metaList or valueDict is empty we return undefined
    if (metaList === undefined || metaList.length === 0) {
      return [];
    }

    if (valueDict === undefined || Object.keys(valueDict).length === 0) {
      return [];
    }

    const allItems = metaList.map(metaItem => ({
      meta: metaItem,
      content: valueDict[metaItem.currentRevision],
    }));

    return _.filter<IDiscussionItem>(allItems, (item: IDiscussionItem) => {
      if (item.content === undefined || item.meta === undefined) {
        return false;
      }

      return item.meta.watched;
    });
  }
);

export const discussionItemDictMetaSelector = createSelector(
  discussionMetaDictSelector,
  discussionDictSelector,
  (
    metaDict: { [byId: string]: Readonly<IContentMeta> },
    valueDict: { [byId: string]: Readonly<IDiscussion> }
  ) => {
    if (metaDict === undefined || Object.keys(metaDict).length === 0) {
      return undefined;
    }

    if (valueDict === undefined || Object.keys(valueDict).length === 0) {
      return undefined;
    }

    const itemDict: { [byId: string]: IDiscussionItem } = {};

    for (let key in metaDict) {
      itemDict[key] = {
        meta: metaDict[key],
        content: valueDict[metaDict[key].currentRevision],
      };
    }

    return itemDict;
  }
);

type DiscussionItemDict = { [byId: string]: IDiscussionItem };

export const discussionItemMetaSelector = createSelector<
  RootState,
  RouteComponentProps<{ uri: string }>,
  DiscussionItemDict | undefined,
  string | undefined,
  IDiscussionItem | undefined
>(
  discussionItemDictMetaSelector,
  (state: RootState, props: RouteComponentProps<{ uri: string }>) => {
    return props.match && props.match.params && props.match.params.uri
      ? props.match.params.uri.substring(
          props.match.params.uri.lastIndexOf('-') + 1,
          props.match.params.uri.length
        )
      : undefined;
  },
  (r1: DiscussionItemDict, r2: string) => {
    if (r1 !== undefined && r2 !== undefined) {
      return r1[r2];
    }
    return undefined;
  }
);

// export function createSelector<S, P, R1, R2, T>(
//   selector1: ParametricSelector<S, P, R1>,
//   selector2: ParametricSelector<S, P, R2>,
//   combiner: (res1: R1, res2: R2) => T,
// ): OutputParametricSelector<S, P, T, (res1: R1, res2: R2) => T>;
export const discussionFilesMetaSelector = createSelector<
  RootState,
  RouteComponentProps<{ uri: string }>,
  IDiscussionItem | undefined,
  {
    [byId: string]: Readonly<IFile>;
  },
  ReadonlyArray<IFile>
>(
  discussionItemMetaSelector,
  (state: RootState) => {
    return state.entities.files.byId;
  },
  (
    r1: IDiscussionItem | undefined,
    r2: {
      [byId: string]: Readonly<IFile>;
    }
  ) => {
    if (
      r1 !== undefined &&
      r2 !== undefined &&
      r1.content.userFiles !== undefined
    ) {
      // r1.content.files
      const temp: Array<IFile> = [];
      r1.content.userFiles.map((filevalue: string) => {
        temp.push(r2[filevalue]);
      });
      return temp;
    }
    return [];
  }
);
