export type Maybe<T> = T | null;

export interface AllActionValuesInput {
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field status */
  status?: Maybe<ContentStatus>;
  /** description of field limit */
  limit?: Maybe<number>;
  /** description of field offset */
  offset?: Maybe<number>;
  /** description of field sector */
  sector?: Maybe<string>;
  /** description of field kostraGroup */
  kostraGroup?: Maybe<Long>;

  onlyFollowed?: Maybe<boolean>;
}

export interface AllTopicsInput {
  /** description of field category */
  category?: Maybe<TopicCategorySortEnum>;
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field status */
  status?: Maybe<ContentStatus>;
  /** description of field limit */
  limit?: Maybe<number>;
  /** description of field offset */
  offset?: Maybe<number>;
  /** description of field sector */
  sector?: Maybe<string>;
  /** description of field kostraGroup */
  kostraGroup?: Maybe<Long>;
  /** description of field organizations */
  organizations?: Maybe<Long[]>;

  onlyMine?: Maybe<boolean>;
}

export interface AllDiscussionsInput {
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field status */
  status?: Maybe<ContentStatus>;
  /** description of field limit */
  limit?: Maybe<number>;
  /** description of field offset */
  offset?: Maybe<number>;
  /** description of field onlyMine */
  onlyMine?: Maybe<boolean>;
  /** description of field organizations */
  organizations?: Maybe<Long[]>;
}

export interface AllCalendarsInput {
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field status */
  status?: Maybe<ContentStatus>;
  /** description of field limit */
  limit?: Maybe<number>;
  /** description of field offset */
  offset?: Maybe<number>;
  /** description of field organizations */
  organizations?: Maybe<Long[]>;
  /** description of field kostraGroup */
  kostraGroup?: Maybe<Long>;
}

export interface MyCalendarsInput {
  /** description of field sort */
  sort?: Maybe<SortEnum>;
  /** description of field order */
  order?: Maybe<OrderEnum>;
  /** description of field limit */
  limit?: Maybe<number>;
  /** description of field offset */
  offset?: Maybe<number>;
}

export interface MyFollowedInput {
  excludeWrite?: Maybe<boolean>;
}

export interface EventNoteSummaryInput {
  id: string;
}

export interface UpdateMeInput {
  /** description of field name */
  name?: Maybe<string>;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field jobTitle */
  jobTitle?: Maybe<string>;
  /** description of field department */
  department?: Maybe<string>;
  /** description of field oldPassword */
  oldPassword?: Maybe<string>;
  /** description of field newPassword1 */
  newPassword1?: Maybe<string>;
  /** description of field newPassword2 */
  newPassword2?: Maybe<string>;
  /** description of field language */
  language?: Maybe<string>;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<Long[]>;
  /** description of field phoneNumber */
  phoneNumber?: Maybe<string>;
}

export interface UpdateMeSettingsInput {
  /** description of field showAssignedTasks */
  showAssignedTasks?: Maybe<boolean>;
  /** description of field sendInstantNotificationMail */
  sendInstantNotificationMail?: Maybe<boolean>;
  /** description of field sendDailyNotificationMail */
  sendDailyNotificationMail?: Maybe<boolean>;
  /** description of field sendWeeklyNotificationMail */
  sendWeeklyNotificationMail?: Maybe<boolean>;
  /** description of field acceptedUserTerms */
  acceptedUserTerms?: Maybe<boolean>;
  /** description of field allowContactViaPhone */
  allowContactViaPhone?: Maybe<boolean>;
  /** description of field allowContactViaEmail */
  allowContactViaEmail?: Maybe<boolean>;

  shouldReceiveNewsletter?: Maybe<boolean>;

  calendarReverseDirection?: Maybe<boolean>;

  calendarElementView?: Maybe<boolean>;

  calendarUseCtrl?: Maybe<boolean>;

  calendarStartMonth?: Maybe<number>;

  calendarHideCompleted?: Maybe<boolean>;
}
/** Input type for creating a new topic */
export interface CreateTopicInput {
  /** description of field title */
  title: string;
  /** description of field body */
  body: string;
  /** description of field description */
  description: string;

  descriptionText: string;
  /** description of field status */
  status: ContentStatus;
  /** description of field sector */
  sector?: Maybe<string>;
  /** description of field category */
  category: TopicCategoryEnum;
  /** description of field links */
  links?: Maybe<CreateHttpLinkInput[]>;
  /** description of field parameters */
  parameters?: Maybe<CreateActionValueParameterValueInput[]>;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<string[]>;
  /** description of field access */
  access: AccessInput;
}

export interface CreateHttpLinkInput {
  /** description of field label */
  label: string;
  /** description of field href */
  href: string;
}

export interface CreateActionValueParameterValueInput {
  /** description of field parameter */
  parameter: Long;
  /** description of field option */
  option: Long;
  /** description of field comment */
  comment?: Maybe<string>;
}

export interface AccessInput {
  /** description of field isPrivate */
  isPrivate: boolean;
  /** description of field visibility */
  visibility: ContentVisibilityEnum;
  /** description of field persons */
  persons?: Maybe<PersonAccessInput[]>;
  /** description of field groups */
  groups?: Maybe<PersonGroupAccessInput[]>;
  /** description of field organizations */
  organizations?: Maybe<OrganizationAccessInput[]>;
  /** description of field organizationGroups */
  organizationGroups?: Maybe<OrganizationGroupAccessInput[]>;
}

export interface PersonAccessInput {
  /** description of field person */
  person: string;
  /** description of field access */
  access: AccessEnum;
}

export interface PersonGroupAccessInput {
  /** description of field group */
  group: Long;
  /** description of field access */
  access: AccessEnum;
}

export interface OrganizationAccessInput {
  /** description of field organization */
  organization: Long;
  /** description of field access */
  access: AccessEnum;
}

export interface OrganizationGroupAccessInput {
  /** description of field organizationGroup */
  organizationGroup: Long;
  /** description of field access */
  access: AccessEnum;
}
/** Update type for updating a topic */
export interface UpdateTopicInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field body */
  body: string;
  /** description of field description */
  description: string;

  descriptionText: string;
  /** description of field status */
  status: ContentStatus;
  /** description of field sector */
  sector?: Maybe<string>;
  /** description of field category */
  category: TopicCategoryEnum;
  /** description of field links */
  links?: Maybe<CreateHttpLinkInput[]>;
  /** description of field parameters */
  parameters?: Maybe<CreateActionValueParameterValueInput[]>;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<string[]>;
  /** description of field access */
  access: AccessInput;
}
/** Input type for creating a new calendar */
export interface CreateCalendarInput {
  /** description of field title */
  title: string;
  /** description of field description */
  description: string;

  descriptionText: string;
  /** description of field color */
  color: string;
  /** description of field status */
  status: ContentStatus;
  /** description of field access */
  access: AccessInput;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<string[]>;
  /** description of field category */
  category: CalendarCategoryEnum;
}
/** Input type for updating a calendar */
export interface UpdateCalendarInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description: string;

  descriptionText: string;
  /** description of field color */
  color: string;
  /** description of field status */
  status: ContentStatus;
  /** description of field access */
  access: AccessInput;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<string[]>;
  /** description of field category */
  category: CalendarCategoryEnum;
}

export interface CopyCalendarInput {
  /** description of field id */
  id: Long;
  /** description of field title */
  title: string;
}
/** Input type for creating a new annual cycle ring */
export interface CreateCalendarRingInput {
  /** description of field calendar */
  calendar: string;
  /** description of field order */
  order: number;
  /** description of field color */
  color?: Maybe<string>;
}
/** Input type for creating a new calendar task */
export interface CreateTaskInput {
  /** description of field calendar */
  calendar: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field status */
  status: ContentStatus;
  /** description of field events */
  events?: Maybe<(Maybe<CreateEventInput>)[]>;
  /** description of field type */
  type: TaskTypeEnum;
}
/** Input type for creating a new task event */
export interface CreateEventInput {
  /** Specify taskId, or nothing if your creating a new task */
  task?: Maybe<string>;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field startDate */
  startDate?: Maybe<Date>;
  /** description of field endDate */
  endDate: Date;
  /** description of field endRepetitionDate */
  endRepetitionDate?: Maybe<Date>;
  /** description of field repetitionDefinition */
  repetitionDefinition?: Maybe<string>;
  /** description of field status */
  status: ContentStatus;
  /** description of field todos */
  todos?: Maybe<(Maybe<CreateTodoTemplateInput>)[]>;
  /** description of field reminderDate */
  reminderDate?: Maybe<Date>;
  /** description of field responsible */
  responsible?: Maybe<string>;
}
/** Input type for creating a new todo template */
export interface CreateTodoTemplateInput {
  /** description of field event */
  event?: Maybe<string>;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field status */
  status: ContentStatus;
}
/** Input type for updating an existing calendar task */
export interface UpdateTaskInput {
  /** description of field id */
  id: string;
  /** description of field calendar */
  calendar: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field status */
  status: ContentStatus;
  /** description of field type */
  type: TaskTypeEnum;
}
/** Input type for updating an existing task event */
export interface UpdateEventInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field startDate */
  startDate?: Maybe<Date>;
  /** description of field endDate */
  endDate: Date;
  /** description of field endRepetitionDate */
  endRepetitionDate?: Maybe<Date>;
  /** description of field repetitionDefinition */
  repetitionDefinition?: Maybe<string>;
  /** description of field status */
  status: ContentStatus;
  /** description of field reminderDate */
  reminderDate?: Maybe<Date>;
  /** description of field responsible */
  responsible?: Maybe<string>;
}
/** Input type for updating an existing task event instance */
export interface UpdateEventInstanceInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field startDate */
  startDate?: Maybe<Date>;
  /** description of field endDate */
  endDate: Date;
  /** description of field status */
  status: ContentStatus;
  /** description of field isDone */
  isDone: boolean;
  /** description of field isFavourite */
  isFavourite: boolean;
  /** description of field reminderDate */
  reminderDate?: Maybe<Date>;
  /** description of field responsible */
  responsible?: Maybe<string>;
  /** description of field note */
  note?: Maybe<string>;

  noteText?: Maybe<string>;
}
/** Input type for updating an existing todo template */
export interface UpdateTodoTemplateInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field status */
  status: ContentStatus;
}
/** Input type for creating a new todo instance */
export interface CreateTodoInstanceInput {
  /** description of field eventInstance */
  eventInstance: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field note */
  note?: Maybe<string>;
  /** description of field isDone */
  isDone: boolean;
  /** description of field status */
  status: ContentStatus;
  /** description of field responsible */
  responsible?: Maybe<string>;
}
/** Input type for updating an existing todo instance */
export interface UpdateTodoInstanceInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field note */
  note?: Maybe<string>;
  /** description of field isDone */
  isDone: boolean;
  /** description of field status */
  status: ContentStatus;
  /** description of field responsible */
  responsible?: Maybe<string>;
}

export interface CreateDiscussionInput {
  /** description of field title */
  title: string;
  /** description of field body */
  body: string;

  bodyText: string;
  /** description of field status */
  status: ContentStatus;
  /** description of field master */
  master?: Maybe<string>;
}

export interface UpdateDiscussionInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field body */
  body: string;

  bodyText: string;
  /** description of field status */
  status: ContentStatus;
}

export interface CreateCommentInput {
  /** description of field meta */
  meta: string;
  /** description of field parent */
  parent?: Maybe<string>;
  /** description of field status */
  status: ContentStatus;
  /** description of field message */
  message: string;

  messageText: string;
}

export interface UpdateCommentInput {
  /** description of field id */
  id: string;
  /** description of field meta */
  meta: string;
  /** description of field parent */
  parent?: Maybe<string>;
  /** description of field status */
  status: ContentStatus;
  /** description of field message */
  message?: Maybe<string>;

  messageText?: Maybe<string>;
}

export interface CreateActionValueInput {
  /** description of field title */
  title: string;
  /** description of field body */
  body?: Maybe<string>;

  bodyText?: Maybe<string>;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field responsible */
  responsible?: Maybe<string>;
  /** description of field source */
  source: string;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<string[]>;
  /** description of field realms */
  realms?: Maybe<string[]>;
  /** description of field links */
  links?: Maybe<CreateHttpLinkInput[]>;
  /** description of field validOrganizationTypes */
  validOrganizationTypes?: Maybe<string[]>;
  /** description of field parameters */
  parameters?: Maybe<CreateActionValueParameterValueInput[]>;
  /** description of field type */
  type: ActionValueTypeEnum;
  /** description of field status */
  status: ContentStatus;
  /** description of field category */
  category: ActionValueCategoryEnum;
  /** description of field year */
  year: number;
  /** description of field deadlines */
  deadlines?: Maybe<CreateDeadlineInput[]>;
  /** description of field reportDeadline */
  reportDeadline?: Maybe<Date>;
  /** description of field parent */
  parent?: Maybe<string>;
  /** description of field talkTo */
  talkTo?: Maybe<string>;
  /** description of field sourceUrl */
  sourceUrl?: Maybe<string>;
}

export interface CreateDeadlineInput {
  /** description of field date */
  date: Date;
  /** description of field type */
  type?: Maybe<string>;
}

export interface UpdateActionValueInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field body */
  body?: Maybe<string>;

  bodyText?: Maybe<string>;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** description of field responsible */
  responsible?: Maybe<string>;
  /** description of field source */
  source: string;
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<string[]>;
  /** description of field realms */
  realms?: Maybe<string[]>;
  /** description of field links */
  links?: Maybe<CreateHttpLinkInput[]>;
  /** description of field parameters */
  parameters?: Maybe<CreateActionValueParameterValueInput[]>;
  /** description of field validOrganizationTypes */
  validOrganizationTypes?: Maybe<string[]>;
  /** description of field type */
  type: ActionValueTypeEnum;
  /** description of field status */
  status: ContentStatus;
  /** description of field category */
  category: ActionValueCategoryEnum;
  /** description of field year */
  year: number;
  /** description of field deadlines */
  deadlines?: Maybe<CreateDeadlineInput[]>;
  /** description of field reportDeadline */
  reportDeadline?: Maybe<Date>;
  /** description of field parent */
  parent?: Maybe<string>;
  /** description of field talkTo */
  talkTo?: Maybe<string>;
  /** description of field sourceUrl */
  sourceUrl?: Maybe<string>;
}

export interface AddToRelatedInput {
  /** description of field meta */
  meta: Long;
  /** description of field metasToAdd */
  metasToAdd: Long[];
}

export interface RemoveFromRelatedInput {
  /** description of field meta */
  meta: Long;
  /** description of field metaToDelete */
  metasToDelete: Long[];
}

export interface ShareInput {
  /** description of field id */
  id: string;
  /** description of field email */
  email: string;
  /** description of field description */
  description?: Maybe<string>;
}

export interface SetStatusInput {
  /** description of field id */
  id: string;
  /** description of field status */
  status: ContentStatus;
}

export interface CreatePersonGroupInput {
  /** description of field title */
  title: string;
  /** description of field members */
  members: string[];
}

export interface UpdatePersonGroupInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field members */
  members: string[];
}

export interface CreateOrganizationGroupInput {
  /** description of field title */
  title: string;
  /** description of field members */
  members: Long[];
}

export interface UpdateOrganizationGroupInput {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field members */
  members: Long[];
}

export interface MakeSharableInput {
  id: string;

  uri?: Maybe<string>;
}
/** Update type for updating file info */
export interface UpdateFileInfoInput {
  /** description of field id */
  id: string;
  /** description of field description */
  description: string;

  descriptionText: string;
}
/** Content status enum test */
export enum ContentStatus {
  DRAFT = 'DRAFT',
  OBSOLETE = 'OBSOLETE',
  PUBLISHED = 'PUBLISHED',
  TO_APPROVAL = 'TO_APPROVAL',
}
/** ContentVisibilityEnum */
export enum ContentVisibilityEnum {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}
/** AccessEnum */
export enum AccessEnum {
  WRITE = 'WRITE',
  READ = 'READ',
  NONE = 'NONE',
}
/** TopicCategoryEnum */
export enum TopicCategoryEnum {
  TOPIC = 'TOPIC',
  SUBJECT_GROUP = 'SUBJECT_GROUP',
}
/** TaskTypeEnum */
export enum TaskTypeEnum {
  DEFAULT = 'DEFAULT',
  SINGLE = 'SINGLE',
}
/** CalendarCategoryEnum */
export enum CalendarCategoryEnum {
  TOPIC = 'TOPIC',
  ORGANIZATION = 'ORGANIZATION',
}
/** SortEnum */
export enum SortEnum {
  TITLE = 'TITLE',
  LAST_UPDATED = 'LAST_UPDATED',
  DEADLINE = 'DEADLINE',
}
/** OrderEnum */
export enum OrderEnum {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}
/** TopicCategorySortEnum */
export enum TopicCategorySortEnum {
  ALL = 'ALL',
  TOPIC = 'TOPIC',
  SUBJECT_GROUP = 'SUBJECT_GROUP',
}
/** ActionValueTypeEnum */
export enum ActionValueTypeEnum {
  SHORT_TERM = 'SHORT_TERM',
  MIDDLE_TERM = 'MIDDLE_TERM',
  LONG_TERM = 'LONG_TERM',
  YEARLY = 'YEARLY',
}
/** ActionValueCategoryEnum */
export enum ActionValueCategoryEnum {
  OPERATIONAL = 'OPERATIONAL',
  INVESTMENT = 'INVESTMENT',
}

/** Date type */

/** Long type */
export type Long = any;

// ====================================================
// Documents
// ====================================================

export type GetKostraFunctionsVariables = {};

export type GetKostraFunctionsQuery = {
  __typename?: 'Query';

  allKostraFunctions: GetKostraFunctionsAllKostraFunctions[];
};

export type GetKostraFunctionsAllKostraFunctions = FieldsOnKostraFunctionFragment;

export type MyGetKostraFunctionsVariables = {};

export type MyGetKostraFunctionsQuery = {
  __typename?: 'Query';

  me: MyGetKostraFunctionsMe;
};

export type MyGetKostraFunctionsMe = {
  __typename?: 'Me';

  id: string;

  kostraFunctions: MyGetKostraFunctionsKostraFunctions[];
};

export type MyGetKostraFunctionsKostraFunctions = {
  __typename?: 'KostraFunction';

  id: string;
};

export type FindPersonsOrganizationsAndGroupsVariables = {
  query: string;
};

export type FindPersonsOrganizationsAndGroupsQuery = {
  __typename?: 'Query';

  search: FindPersonsOrganizationsAndGroupsSearch;
};

export type FindPersonsOrganizationsAndGroupsSearch = {
  __typename?: 'Search';

  persons: FindPersonsOrganizationsAndGroupsPersons[];

  groups: FindPersonsOrganizationsAndGroupsGroups[];

  organizationGroups: FindPersonsOrganizationsAndGroupsOrganizationGroups[];

  organizations: FindPersonsOrganizationsAndGroupsOrganizations[];
};

export type FindPersonsOrganizationsAndGroupsPersons = {
  __typename?: 'Person';

  name: string;

  id: string;

  department: Maybe<string>;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<FindPersonsOrganizationsAndGroupsOrganization>;
};

export type FindPersonsOrganizationsAndGroupsOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FindPersonsOrganizationsAndGroupsGroups = {
  __typename?: 'PersonGroup';

  id: string;

  title: string;

  createdBy: FindPersonsOrganizationsAndGroupsCreatedBy;

  dateCreated: Date;

  lastUpdated: Date;

  members: FindPersonsOrganizationsAndGroupsMembers[];
};

export type FindPersonsOrganizationsAndGroupsCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type FindPersonsOrganizationsAndGroupsMembers = {
  __typename?: 'Person';

  name: string;

  id: string;
};

export type FindPersonsOrganizationsAndGroupsOrganizationGroups = {
  __typename?: 'OrganizationGroup';

  createdBy: FindPersonsOrganizationsAndGroups_CreatedBy;

  id: string;

  lastUpdated: Date;

  title: string;

  members: FindPersonsOrganizationsAndGroups_Members[];
};

export type FindPersonsOrganizationsAndGroups_CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type FindPersonsOrganizationsAndGroups_Members = {
  __typename?: 'Organization';

  name: string;

  id: string;
};

export type FindPersonsOrganizationsAndGroupsOrganizations = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FindPersonsVariables = {
  query: string;
};

export type FindPersonsQuery = {
  __typename?: 'Query';

  search: FindPersonsSearch;
};

export type FindPersonsSearch = {
  __typename?: 'Search';

  persons: FindPersonsPersons[];
};

export type FindPersonsPersons = {
  __typename?: 'Person';

  name: string;

  id: string;

  department: Maybe<string>;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<FindPersonsOrganization>;
};

export type FindPersonsOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type GetMeForTagsVariables = {};

export type GetMeForTagsQuery = {
  __typename?: 'Query';

  me: GetMeForTagsMe;
};

export type GetMeForTagsMe = {
  __typename?: 'Me';

  id: string;

  name: string;
};

export type AddCalendarMutationVariables = {
  input: CreateCalendarInput;
};

export type AddCalendarMutationMutation = {
  __typename?: 'Mutation';

  createCalendar: Maybe<AddCalendarMutationCreateCalendar>;
};

export type AddCalendarMutationCreateCalendar = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  dateCreated: Date;

  access: AddCalendarMutationAccess;

  createdBy: AddCalendarMutationCreatedBy;

  color: string;

  status: ContentStatus;
};

export type AddCalendarMutationAccess = {
  __typename?: 'Access';

  isPrivate: boolean;

  persons: AddCalendarMutationPersons[];
};

export type AddCalendarMutationPersons = {
  __typename?: 'PersonAccess';

  person: AddCalendarMutationPerson;

  access: AccessEnum;
};

export type AddCalendarMutationPerson = {
  __typename?: 'Person';

  id: string;
};

export type AddCalendarMutationCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type GetMeVariables = {};

export type GetMeQuery = {
  __typename?: 'Query';

  me: GetMeMe;
};

export type GetMeMe = {
  __typename?: 'Me';

  id: string;

  name: string;

  jobTitle: Maybe<string>;

  picture: Maybe<string>;

  isAdmin: boolean;

  department: Maybe<string>;

  organization: Maybe<GetMeOrganization>;
};

export type GetMeOrganization = {
  __typename?: 'Organization';

  id: string;

  organizationImage: Maybe<string>;

  name: string;
};

export type CreateOrganizationGroupVariables = {
  input: CreateOrganizationGroupInput;
};

export type CreateOrganizationGroupMutation = {
  __typename?: 'Mutation';

  createOrganizationGroup: Maybe<
    CreateOrganizationGroupCreateOrganizationGroup
  >;
};

export type CreateOrganizationGroupCreateOrganizationGroup = {
  __typename?: 'OrganizationGroup';

  id: string;
};

export type RefetchAnnualCycleVariables = {};

export type RefetchAnnualCycleQuery = {
  __typename?: 'Query';

  myCalendars: RefetchAnnualCycleMyCalendars[];
};

export type RefetchAnnualCycleMyCalendars = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  description: Maybe<string>;

  color: string;

  status: ContentStatus;
};

export type GetActiveYearWheelsVariables = {
  person: string;
};

export type GetActiveYearWheelsQuery = {
  __typename?: 'Query';

  yearWheel: GetActiveYearWheelsYearWheel[];
};

export type GetActiveYearWheelsYearWheel = {
  __typename?: 'CalendarRing';

  id: string;
};

export type AddTaskCollectionMutationVariables = {
  input: CreateTaskInput;
};

export type AddTaskCollectionMutationMutation = {
  __typename?: 'Mutation';

  createTask: Maybe<AddTaskCollectionMutationCreateTask>;
};

export type AddTaskCollectionMutationCreateTask = {
  __typename?: 'Task';

  id: string;

  title: string;

  events: AddTaskCollectionMutationEvents[];
};

export type AddTaskCollectionMutationEvents = {
  __typename?: 'Event';

  id: string;
};

export type GetAnnualCycleQueryVariables = {
  id: string;
};

export type GetAnnualCycleQueryQuery = {
  __typename?: 'Query';

  calendar: Maybe<GetAnnualCycleQueryCalendar>;

  me: GetAnnualCycleQueryMe;
};

export type GetAnnualCycleQueryCalendar = {
  __typename?: 'Calendar';

  id: string;

  category: CalendarCategoryEnum;

  topics: GetAnnualCycleQueryTopics[];

  kostraFunctions: GetAnnualCycleQueryKostraFunctions[];

  actionValues: GetAnnualCycleQueryActionValues[];

  discussions: GetAnnualCycleQueryDiscussions[];

  access: GetAnnualCycleQueryAccess;

  title: string;

  description: Maybe<string>;

  descriptionText: Maybe<string>;

  color: string;

  createdBy: GetAnnualCycleQuery__CreatedBy;

  status: ContentStatus;
};

export type GetAnnualCycleQueryTopics = {
  __typename?: 'Topic';

  title: string;

  id: string;
};

export type GetAnnualCycleQueryKostraFunctions = FieldsOnKostraFunctionFragment;

export type GetAnnualCycleQueryActionValues = {
  __typename?: 'ActionValue';

  title: string;

  id: string;
};

export type GetAnnualCycleQueryDiscussions = {
  __typename?: 'Discussion';

  title: string;

  id: string;
};

export type GetAnnualCycleQueryAccess = {
  __typename?: 'Access';

  isPrivate: boolean;

  myAccess: AccessEnum;

  visibility: ContentVisibilityEnum;

  groups: GetAnnualCycleQueryGroups[];

  organizationGroups: GetAnnualCycleQueryOrganizationGroups[];

  organizations: GetAnnualCycleQueryOrganizations[];

  persons: GetAnnualCycleQueryPersons[];
};

export type GetAnnualCycleQueryGroups = {
  __typename?: 'GroupAccess';

  group: GetAnnualCycleQueryGroup;

  access: AccessEnum;
};

export type GetAnnualCycleQueryGroup = {
  __typename?: 'PersonGroup';

  id: string;

  title: string;

  createdBy: GetAnnualCycleQueryCreatedBy;

  dateCreated: Date;

  lastUpdated: Date;

  members: GetAnnualCycleQueryMembers[];
};

export type GetAnnualCycleQueryCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type GetAnnualCycleQueryMembers = {
  __typename?: 'Person';

  name: string;

  id: string;
};

export type GetAnnualCycleQueryOrganizationGroups = {
  __typename?: 'OrganizationGroupAccess';

  access: AccessEnum;

  organizationGroup: GetAnnualCycleQueryOrganizationGroup;
};

export type GetAnnualCycleQueryOrganizationGroup = {
  __typename?: 'OrganizationGroup';

  createdBy: GetAnnualCycleQuery_CreatedBy;

  id: string;

  lastUpdated: Date;

  title: string;

  members: GetAnnualCycleQuery_Members[];
};

export type GetAnnualCycleQuery_CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type GetAnnualCycleQuery_Members = {
  __typename?: 'Organization';

  name: string;

  id: string;
};

export type GetAnnualCycleQueryOrganizations = {
  __typename?: 'OrganizationAccess';

  access: AccessEnum;

  organization: GetAnnualCycleQueryOrganization;
};

export type GetAnnualCycleQueryOrganization = {
  __typename?: 'Organization';

  name: string;

  id: string;
};

export type GetAnnualCycleQueryPersons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: GetAnnualCycleQueryPerson;
};

export type GetAnnualCycleQueryPerson = {
  __typename?: 'Person';

  name: string;

  id: string;

  department: Maybe<string>;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<GetAnnualCycleQuery_Organization>;
};

export type GetAnnualCycleQuery_Organization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type GetAnnualCycleQuery__CreatedBy = FieldsOnPersonFragment;

export type GetAnnualCycleQueryMe = {
  __typename?: 'Me';

  id: string;

  isAdmin: boolean;

  calendarCode: string;
};

export type SearchTopicVariables = {
  query: string;
};

export type SearchTopicQuery = {
  __typename?: 'Query';

  search: SearchTopicSearch;
};

export type SearchTopicSearch = {
  __typename?: 'Search';

  topics: SearchTopicTopics[];
};

export type SearchTopicTopics = {
  __typename?: 'TopicSearch';

  id: string;

  title: string;
};

export type UpdateCalendarVariables = {
  input: UpdateCalendarInput;
};

export type UpdateCalendarMutation = {
  __typename?: 'Mutation';

  updateCalendar: Maybe<UpdateCalendarUpdateCalendar>;
};

export type UpdateCalendarUpdateCalendar = {
  __typename?: 'Calendar';

  id: string;

  access: UpdateCalendarAccess;

  title: string;

  description: Maybe<string>;

  color: string;

  createdBy: UpdateCalendarCreatedBy;

  status: ContentStatus;
};

export type UpdateCalendarAccess = {
  __typename?: 'Access';

  isPrivate: boolean;

  persons: UpdateCalendarPersons[];
};

export type UpdateCalendarPersons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: UpdateCalendarPerson;
};

export type UpdateCalendarPerson = FieldsOnPersonFragment;

export type UpdateCalendarCreatedBy = FieldsOnPersonFragment;

export type AddToRelatedVariables = {
  input: AddToRelatedInput;
};

export type AddToRelatedMutation = {
  __typename?: 'Mutation';

  addToRelated: AddToRelatedAddToRelated[];
};

export type AddToRelatedAddToRelated = {
  __typename?: 'QueryStatus';

  id: string;

  success: boolean;
};

export type RemoveRelatedVariables = {
  input: RemoveFromRelatedInput;
};

export type RemoveRelatedMutation = {
  __typename?: 'Mutation';

  removeFromRelated: RemoveRelatedRemoveFromRelated[];
};

export type RemoveRelatedRemoveFromRelated = {
  __typename?: 'QueryStatus';

  id: string;

  success: boolean;
};

export type CopyCalendarMutationVariables = {
  input: CopyCalendarInput;
};

export type CopyCalendarMutationMutation = {
  __typename?: 'Mutation';

  copyCalendar: Maybe<boolean>;
};

export type UpdateEventInstanceVariables = {
  input: UpdateEventInstanceInput;
};

export type UpdateEventInstanceMutation = {
  __typename?: 'Mutation';

  updateEventInstance: Maybe<UpdateEventInstanceUpdateEventInstance>;
};

export type UpdateEventInstanceUpdateEventInstance = {
  __typename?: 'EventInstance';

  id: string;

  title: string;

  description: Maybe<string>;

  startDate: Date;

  endDate: Maybe<Date>;

  note: Maybe<string>;

  status: ContentStatus;

  isDone: boolean;

  isFavourite: boolean;

  reminderDate: Maybe<Date>;

  responsible: Maybe<UpdateEventInstanceResponsible>;
};

export type UpdateEventInstanceResponsible = FieldsOnMiniPersonFragment;

export type CreateTodoVariables = {
  input: CreateTodoInstanceInput;
};

export type CreateTodoMutation = {
  __typename?: 'Mutation';

  createTodoInstance: Maybe<CreateTodoCreateTodoInstance>;
};

export type CreateTodoCreateTodoInstance = {
  __typename?: 'TodoInstanceType';

  id: string;

  title: string;

  description: Maybe<string>;

  isDone: boolean;

  status: ContentStatus;

  note: Maybe<string>;
};

export type UpdateTodoVariables = {
  input: UpdateTodoInstanceInput;
};

export type UpdateTodoMutation = {
  __typename?: 'Mutation';

  updateTodoInstance: Maybe<UpdateTodoUpdateTodoInstance>;
};

export type UpdateTodoUpdateTodoInstance = {
  __typename?: 'TodoInstanceType';

  id: string;

  title: string;

  description: Maybe<string>;

  isDone: boolean;

  status: ContentStatus;

  note: Maybe<string>;
};

export type UpdateTodoTemplateVariables = {
  input: UpdateTodoTemplateInput;
};

export type UpdateTodoTemplateMutation = {
  __typename?: 'Mutation';

  updateTodoTemplate: Maybe<UpdateTodoTemplateUpdateTodoTemplate>;
};

export type UpdateTodoTemplateUpdateTodoTemplate = {
  __typename?: 'TodoTemplate';

  id: string;

  title: string;

  description: Maybe<string>;

  status: ContentStatus;
};

export type CreateTodoTemplateVariables = {
  input: CreateTodoTemplateInput;
};

export type CreateTodoTemplateMutation = {
  __typename?: 'Mutation';

  createTodoTemplate: Maybe<CreateTodoTemplateCreateTodoTemplate>;
};

export type CreateTodoTemplateCreateTodoTemplate = {
  __typename?: 'TodoTemplate';

  id: string;

  title: string;

  description: Maybe<string>;

  status: ContentStatus;
};

export type UpdateEventVariables = {
  input: UpdateEventInput;
};

export type UpdateEventMutation = {
  __typename?: 'Mutation';

  updateEvent: Maybe<UpdateEventUpdateEvent>;
};

export type UpdateEventUpdateEvent = {
  __typename?: 'Event';

  id: string;

  title: string;

  description: Maybe<string>;

  status: ContentStatus;

  endRepetitionDate: Maybe<Date>;

  repetitionDefinition: Maybe<string>;

  responsible: Maybe<UpdateEventResponsible>;

  startDate: Date;

  startRepetitionDate: Maybe<Date>;
};

export type UpdateEventResponsible = FieldsOnMiniPersonFragment;

export type GetTaskVariables = {
  taskId: string;
};

export type GetTaskQuery = {
  __typename?: 'Query';

  task: Maybe<GetTaskTask>;

  me: GetTaskMe;
};

export type GetTaskTask = {
  __typename?: 'Task';

  id: string;

  title: string;

  description: Maybe<string>;

  type: TaskTypeEnum;

  status: ContentStatus;

  calendars: GetTaskCalendars[];
};

export type GetTaskCalendars = FieldsOnCalendarLookupFragment;

export type GetTaskMe = {
  __typename?: 'Me';

  id: string;

  name: string;
};

export type UpdateTaskVariables = {
  input: UpdateTaskInput;
};

export type UpdateTaskMutation = {
  __typename?: 'Mutation';

  updateTask: Maybe<UpdateTaskUpdateTask>;
};

export type UpdateTaskUpdateTask = {
  __typename?: 'Task';

  id: string;

  title: string;

  description: Maybe<string>;

  type: TaskTypeEnum;

  status: ContentStatus;

  calendars: UpdateTaskCalendars[];
};

export type UpdateTaskCalendars = FieldsOnCalendarLookupFragment;

export type GetInstanceAndTaskVariables = {
  taskId: string;
  year?: Maybe<number>;
};

export type GetInstanceAndTaskQuery = {
  __typename?: 'Query';

  task: Maybe<GetInstanceAndTaskTask>;
};

export type GetInstanceAndTaskTask = {
  __typename?: 'Task';

  id: string;

  title: string;

  type: TaskTypeEnum;

  status: ContentStatus;

  calendars: GetInstanceAndTaskCalendars[];

  events: GetInstanceAndTaskEvents[];
};

export type GetInstanceAndTaskCalendars = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;

  color: string;

  createdBy: GetInstanceAndTaskCreatedBy;

  access: GetInstanceAndTaskAccess;
};

export type GetInstanceAndTaskCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type GetInstanceAndTaskAccess = {
  __typename?: 'Access';

  persons: GetInstanceAndTaskPersons[];
};

export type GetInstanceAndTaskPersons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: GetInstanceAndTaskPerson;
};

export type GetInstanceAndTaskPerson = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type GetInstanceAndTaskEvents = FieldsOnEventExtendedFragment;

export type GetReverseInstanceAndTaskVariables = {
  instanceId: string;
};

export type GetReverseInstanceAndTaskQuery = {
  __typename?: 'Query';

  eventInstance: Maybe<GetReverseInstanceAndTaskEventInstance>;
};

export type GetReverseInstanceAndTaskEventInstance = {
  __typename?: 'EventInstance';

  id: string;

  title: string;

  files: GetReverseInstanceAndTaskFiles[];

  description: Maybe<string>;

  isDone: boolean;

  assignedBy: Maybe<GetReverseInstanceAndTaskAssignedBy>;

  isFavourite: boolean;

  status: ContentStatus;

  note: Maybe<string>;

  startDate: Date;

  endDate: Maybe<Date>;

  responsible: Maybe<GetReverseInstanceAndTaskResponsible>;

  reminderDate: Maybe<Date>;

  createdBy: GetReverseInstanceAndTaskCreatedBy;

  todos: GetReverseInstanceAndTaskTodos[];

  event: GetReverseInstanceAndTaskEvent;
};

export type GetReverseInstanceAndTaskFiles = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  size: number;

  contentType: string;

  access: GetReverseInstanceAndTaskAccess;
};

export type GetReverseInstanceAndTaskAccess = {
  __typename?: 'Access';

  myAccess: AccessEnum;
};

export type GetReverseInstanceAndTaskAssignedBy = {
  __typename?: 'Person';

  department: Maybe<string>;
} & FieldsOnMiniPersonFragment;

export type GetReverseInstanceAndTaskResponsible = FieldsOnMiniPersonFragment;

export type GetReverseInstanceAndTaskCreatedBy = FieldsOnMiniPersonFragment;

export type GetReverseInstanceAndTaskTodos = {
  __typename?: 'TodoInstanceType';

  id: string;

  title: string;

  description: Maybe<string>;

  isDone: boolean;

  status: ContentStatus;

  fromTemplate: boolean;

  note: Maybe<string>;

  parent: string;
};

export type GetReverseInstanceAndTaskEvent = {
  __typename?: 'Event';

  id: string;

  title: string;

  description: Maybe<string>;

  startDate: Date;

  files: GetReverseInstanceAndTask_Files[];

  assignedBy: Maybe<GetReverseInstanceAndTask_AssignedBy>;

  endDate: Maybe<Date>;

  responsible: Maybe<GetReverseInstanceAndTask_Responsible>;

  repetitionDefinition: Maybe<string>;

  endRepetitionDate: Maybe<Date>;

  startRepetitionDate: Maybe<Date>;

  status: ContentStatus;

  todoTemplate: GetReverseInstanceAndTaskTodoTemplate[];

  task: GetReverseInstanceAndTaskTask;
};

export type GetReverseInstanceAndTask_Files = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  size: number;

  contentType: string;

  access: GetReverseInstanceAndTask_Access;
};

export type GetReverseInstanceAndTask_Access = {
  __typename?: 'Access';

  myAccess: AccessEnum;
};

export type GetReverseInstanceAndTask_AssignedBy = {
  __typename?: 'Person';

  department: Maybe<string>;
} & FieldsOnMiniPersonFragment;

export type GetReverseInstanceAndTask_Responsible = FieldsOnMiniPersonFragment;

export type GetReverseInstanceAndTaskTodoTemplate = {
  __typename?: 'TodoTemplate';

  id: string;

  title: string;

  description: Maybe<string>;

  status: ContentStatus;
};

export type GetReverseInstanceAndTaskTask = {
  __typename?: 'Task';

  id: string;

  title: string;

  type: TaskTypeEnum;

  status: ContentStatus;

  calendars: GetReverseInstanceAndTaskCalendars[];
};

export type GetReverseInstanceAndTaskCalendars = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;

  color: string;

  createdBy: GetReverseInstanceAndTask_CreatedBy;

  access: GetReverseInstanceAndTask__Access;
};

export type GetReverseInstanceAndTask_CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type GetReverseInstanceAndTask__Access = {
  __typename?: 'Access';

  persons: GetReverseInstanceAndTaskPersons[];
};

export type GetReverseInstanceAndTaskPersons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: GetReverseInstanceAndTaskPerson;
};

export type GetReverseInstanceAndTaskPerson = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type GetTaskForInvalidVariables = {
  taskId: string;
};

export type GetTaskForInvalidQuery = {
  __typename?: 'Query';

  task: Maybe<GetTaskForInvalidTask>;
};

export type GetTaskForInvalidTask = {
  __typename?: 'Task';

  id: string;

  title: string;

  type: TaskTypeEnum;

  calendars: GetTaskForInvalidCalendars[];

  events: GetTaskForInvalidEvents[];
};

export type GetTaskForInvalidCalendars = FieldsOnCalendarLookupFragment;

export type GetTaskForInvalidEvents = FieldsOnEventFragment;

export type SetStatusFilesVariables = {
  input: SetStatusInput;
};

export type SetStatusFilesMutation = {
  __typename?: 'Mutation';

  setStatus: Maybe<boolean>;
};

export type UpdateInstanceStatusVariables = {
  input: UpdateEventInstanceInput;
};

export type UpdateInstanceStatusMutation = {
  __typename?: 'Mutation';

  updateEventInstance: Maybe<UpdateInstanceStatusUpdateEventInstance>;
};

export type UpdateInstanceStatusUpdateEventInstance = {
  __typename?: 'EventInstance';

  id: string;

  isDone: boolean;

  isFavourite: boolean;

  status: ContentStatus;
};

export type GetMyFollowedCalendarsDropdownVariables = {};

export type GetMyFollowedCalendarsDropdownQuery = {
  __typename?: 'Query';

  me: GetMyFollowedCalendarsDropdownMe;

  myFollowed: GetMyFollowedCalendarsDropdownMyFollowed;
};

export type GetMyFollowedCalendarsDropdownMe = {
  __typename?: 'Me';

  id: string;

  name: string;
};

export type GetMyFollowedCalendarsDropdownMyFollowed = {
  __typename?: 'MyFollowed';

  calendars: GetMyFollowedCalendarsDropdownCalendars[];
};

export type GetMyFollowedCalendarsDropdownCalendars = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  description: Maybe<string>;

  color: string;

  isFollowing: boolean;

  createdBy: GetMyFollowedCalendarsDropdownCreatedBy;

  access: GetMyFollowedCalendarsDropdownAccess;
};

export type GetMyFollowedCalendarsDropdownCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type GetMyFollowedCalendarsDropdownAccess = {
  __typename?: 'Access';

  persons: GetMyFollowedCalendarsDropdownPersons[];
};

export type GetMyFollowedCalendarsDropdownPersons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: GetMyFollowedCalendarsDropdownPerson;
};

export type GetMyFollowedCalendarsDropdownPerson = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type TopicClapVariables = {
  id: string;
};

export type TopicClapQuery = {
  __typename?: 'Query';

  topic: Maybe<TopicClapTopic>;
};

export type TopicClapTopic = {
  __typename?: 'Topic';

  id: string;

  likes: number;

  isLiked: boolean;

  isFollowing: boolean;
};

export type GetFileInfoQueryVariables = {
  id: string;
};

export type GetFileInfoQueryQuery = {
  __typename?: 'Query';

  fileInfo: GetFileInfoQueryFileInfo;
};

export type GetFileInfoQueryFileInfo = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  descriptionText: string;

  contentType: string;

  size: number;

  lastUpdated: Date;
};

export type UpdateFileDescriptionVariables = {
  input: UpdateFileInfoInput;
};

export type UpdateFileDescriptionMutation = {
  __typename?: 'Mutation';

  updateFileInfo: Maybe<UpdateFileDescriptionUpdateFileInfo>;
};

export type UpdateFileDescriptionUpdateFileInfo = {
  __typename?: 'UserFile';

  id: string;

  description: string;
};

export type DeleteFileMutationVariables = {
  id: string;
};

export type DeleteFileMutationMutation = {
  __typename?: 'Mutation';

  deleteFile: Maybe<boolean>;
};

export type RelationQueryVariables = {
  query: string;
};

export type RelationQueryQuery = {
  __typename?: 'Query';

  search: RelationQuerySearch;
};

export type RelationQuerySearch = {
  __typename?: 'Search';

  topics: RelationQueryTopics[];

  actionValues: RelationQueryActionValues[];

  discussions: RelationQueryDiscussions[];

  calendars: RelationQueryCalendars[];
};

export type RelationQueryTopics = {
  __typename?: 'TopicSearch';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;
};

export type RelationQueryActionValues = {
  __typename?: 'ActionValueSearch';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;
};

export type RelationQueryDiscussions = {
  __typename?: 'DiscussionSearch';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;
};

export type RelationQueryCalendars = {
  __typename?: 'CalendarSearch';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;
};

export type GetLocaleVariables = {};

export type GetLocaleQuery = {
  __typename?: 'Query';

  me: GetLocaleMe;
};

export type GetLocaleMe = {
  __typename?: 'Me';

  id: string;

  name: string;

  description: Maybe<string>;

  jobTitle: Maybe<string>;

  department: Maybe<string>;

  language: Maybe<string>;

  kostraFunctions: GetLocaleKostraFunctions[];
};

export type GetLocaleKostraFunctions = {
  __typename?: 'KostraFunction';

  id: string;
};

export type UpdateLocaleVariables = {
  input: UpdateMeInput;
};

export type UpdateLocaleMutation = {
  __typename?: 'Mutation';

  updateMe: Maybe<UpdateLocaleUpdateMe>;
};

export type UpdateLocaleUpdateMe = {
  __typename?: 'Me';

  id: string;

  language: Maybe<string>;
};

export type AllCalendarsForSidebarVariables = {};

export type AllCalendarsForSidebarQuery = {
  __typename?: 'Query';

  myFollowed: AllCalendarsForSidebarMyFollowed;
};

export type AllCalendarsForSidebarMyFollowed = {
  __typename?: 'MyFollowed';

  calendars: AllCalendarsForSidebarCalendars[];
};

export type AllCalendarsForSidebarCalendars = {
  __typename?: 'Calendar';

  id: string;

  isFollowing: boolean;

  title: string;

  description: Maybe<string>;

  uri: string;

  lastUpdated: Date;
};

export type AllDiscussionsForSidebarVariables = {};

export type AllDiscussionsForSidebarQuery = {
  __typename?: 'Query';

  myFollowed: AllDiscussionsForSidebarMyFollowed;
};

export type AllDiscussionsForSidebarMyFollowed = {
  __typename?: 'MyFollowed';

  discussions: AllDiscussionsForSidebarDiscussions[];
};

export type AllDiscussionsForSidebarDiscussions = {
  __typename?: 'Discussion';

  id: string;

  isFollowing: boolean;

  title: string;

  description: Maybe<string>;

  uri: string;

  lastUpdated: Date;

  currentRevision: AllDiscussionsForSidebarCurrentRevision;
};

export type AllDiscussionsForSidebarCurrentRevision = {
  __typename?: 'ActionValueRevision';

  id: string;

  body: Maybe<string>;
};

export type AllActionValueForSidebarVariables = {};

export type AllActionValueForSidebarQuery = {
  __typename?: 'Query';

  myFollowed: AllActionValueForSidebarMyFollowed;
};

export type AllActionValueForSidebarMyFollowed = {
  __typename?: 'MyFollowed';

  actionValues: AllActionValueForSidebarActionValues[];
};

export type AllActionValueForSidebarActionValues = {
  __typename?: 'ActionValue';

  id: string;

  isFollowing: boolean;

  title: string;

  description: Maybe<string>;

  uri: string;

  lastUpdated: Date;

  currentRevision: AllActionValueForSidebarCurrentRevision;
};

export type AllActionValueForSidebarCurrentRevision = {
  __typename?: 'ActionValueRevision';

  id: string;

  description: Maybe<string>;
};

export type AllTopicsForSidebarVariables = {};

export type AllTopicsForSidebarQuery = {
  __typename?: 'Query';

  myFollowed: AllTopicsForSidebarMyFollowed;
};

export type AllTopicsForSidebarMyFollowed = {
  __typename?: 'MyFollowed';

  topics: AllTopicsForSidebarTopics[];
};

export type AllTopicsForSidebarTopics = {
  __typename?: 'Topic';

  id: string;

  isFollowing: boolean;

  title: string;

  description: Maybe<string>;

  uri: string;

  lastUpdated: Date;
};

export type UnfollowCalendarSidebarVariables = {
  id: string;
};

export type UnfollowCalendarSidebarMutation = {
  __typename?: 'Mutation';

  unFollow: Maybe<boolean>;
};

export type ShareVariables = {
  input: ShareInput;
};

export type ShareMutation = {
  __typename?: 'Mutation';

  share: Maybe<boolean>;
};

export type OmniSearchQueryVariables = {
  query: string;
};

export type OmniSearchQueryQuery = {
  __typename?: 'Query';

  search: OmniSearchQuerySearch;
};

export type OmniSearchQuerySearch = {
  __typename?: 'Search';

  actionValues: OmniSearchQueryActionValues[];

  discussions: OmniSearchQueryDiscussions[];

  topics: OmniSearchQueryTopics[];

  calendars: OmniSearchQueryCalendars[];
};

export type OmniSearchQueryActionValues = {
  __typename?: 'ActionValueSearch';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;
};

export type OmniSearchQueryDiscussions = {
  __typename?: 'DiscussionSearch';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;
};

export type OmniSearchQueryTopics = {
  __typename?: 'TopicSearch';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;
};

export type OmniSearchQueryCalendars = {
  __typename?: 'CalendarSearch';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;
};

export type GetUserDataVariables = {};

export type GetUserDataQuery = {
  __typename?: 'Query';

  me: GetUserDataMe;
};

export type GetUserDataMe = FieldsOnMeFragment;

export type GetUserWizardDataVariables = {};

export type GetUserWizardDataQuery = {
  __typename?: 'Query';

  me: GetUserWizardDataMe;

  allKostraFunctionGroups: GetUserWizardDataAllKostraFunctionGroups[];
};

export type GetUserWizardDataMe = FieldsOnMeFragment;

export type GetUserWizardDataAllKostraFunctionGroups = {
  __typename?: 'KostraFunctionGroup';

  id: string;

  title: string;

  kostraFunctions: GetUserWizardDataKostraFunctions[];
};

export type GetUserWizardDataKostraFunctions = {
  __typename?: 'KostraFunction';

  id: string;

  kid: number;

  title: string;
};

export type UpdateMeVariables = {
  input: UpdateMeInput;
};

export type UpdateMeMutation = {
  __typename?: 'Mutation';

  updateMe: Maybe<UpdateMeUpdateMe>;
};

export type UpdateMeUpdateMe = FieldsOnMeFragment;

export type UpdateMeSettingsVariables = {
  input: UpdateMeSettingsInput;
};

export type UpdateMeSettingsMutation = {
  __typename?: 'Mutation';

  updateSettings: Maybe<UpdateMeSettingsUpdateSettings>;
};

export type UpdateMeSettingsUpdateSettings = FieldsOnMeFragment;

export type RelationQueryActionValueVariables = {
  query: string;
};

export type RelationQueryActionValueQuery = {
  __typename?: 'Query';

  search: RelationQueryActionValueSearch;
};

export type RelationQueryActionValueSearch = {
  __typename?: 'Search';

  actionValues: RelationQueryActionValueActionValues[];
};

export type RelationQueryActionValueActionValues = {
  __typename?: 'ActionValueSearch';

  id: string;

  title: string;

  description: Maybe<string>;

  uri: string;
};

export type AllSectorsVariables = {};

export type AllSectorsQuery = {
  __typename?: 'Query';

  allSectors: AllSectorsAllSectors[];
};

export type AllSectorsAllSectors = {
  __typename?: 'Sector';

  id: string;

  title: string;
};

export type AllKostrasAndRealmsVariables = {};

export type AllKostrasAndRealmsQuery = {
  __typename?: 'Query';

  allKostraFunctions: AllKostrasAndRealmsAllKostraFunctions[];

  allRealms: AllKostrasAndRealmsAllRealms[];
};

export type AllKostrasAndRealmsAllKostraFunctions = FieldsOnKostraFunctionFragment;

export type AllKostrasAndRealmsAllRealms = FieldsOnRealmsFragment;

export type CreateeActionValueVariables = {
  input: CreateActionValueInput;
};

export type CreateeActionValueMutation = {
  __typename?: 'Mutation';

  createActionValue: Maybe<CreateeActionValueCreateActionValue>;
};

export type CreateeActionValueCreateActionValue = {
  __typename?: 'ActionValue';

  id: string;
};

export type UpdateActionValueVariables = {
  input: UpdateActionValueInput;
};

export type UpdateActionValueMutation = {
  __typename?: 'Mutation';

  updateActionValue: Maybe<UpdateActionValueUpdateActionValue>;
};

export type UpdateActionValueUpdateActionValue = {
  __typename?: 'ActionValue';

  id: string;

  realms: UpdateActionValueRealms[];
};

export type UpdateActionValueRealms = {
  __typename?: 'Realm';

  id: string;
};

export type GetActionValueVariables = {
  id: string;
};

export type GetActionValueQuery = {
  __typename?: 'Query';

  actionValue: Maybe<GetActionValueActionValue>;

  allKostraFunctionGroups: GetActionValueAllKostraFunctionGroups[];

  allKostraFunctions: GetActionValueAllKostraFunctions[];

  me: GetActionValueMe;

  allRealms: GetActionValueAllRealms[];
};

export type GetActionValueActionValue = {
  __typename?: 'ActionValue';

  id: string;

  title: string;

  dateCreated: Date;

  lastUpdated: Date;

  uri: string;

  description: Maybe<string>;

  status: ContentStatus;

  isFollowing: boolean;

  realms: GetActionValueRealms[];

  kostraFunctions: GetActionValueKostraFunctions[];

  currentRevision: GetActionValueCurrentRevision;

  topics: GetActionValueTopics[];

  files: GetActionValueFiles[];

  comments: GetActionValueComments[];
};

export type GetActionValueRealms = {
  __typename?: 'Realm';

  id: string;

  title: string;

  i18nCode: string;

  iso3166: string;

  localId: string;

  parent: Maybe<string>;

  type: string;
};

export type GetActionValueKostraFunctions = FieldsOnKostraFunctionFragment;

export type GetActionValueCurrentRevision = {
  __typename?: 'ActionValueRevision';

  id: string;

  description: Maybe<string>;

  body: Maybe<string>;

  sourceUrl: Maybe<string>;

  year: Maybe<number>;

  category: Maybe<string>;

  type: Maybe<string>;

  links: GetActionValueLinks[];

  source: Maybe<GetActionValueSource>;

  radarGraph: Maybe<GetActionValueRadarGraph[]>;

  deadlines: Maybe<GetActionValueDeadlines[]>;
};

export type GetActionValueLinks = {
  __typename?: 'HttpLink';

  label: string;

  href: string;
};

export type GetActionValueSource = {
  __typename?: 'Organization';

  id: string;

  name: string;
};

export type GetActionValueRadarGraph = {
  __typename?: 'RadarGraphType';

  name: string;

  sum: number;

  type: number;
};

export type GetActionValueDeadlines = {
  __typename?: 'DeadlineType';

  id: string;

  date: Date;

  type: string;
};

export type GetActionValueTopics = {
  __typename?: 'Topic';

  id: string;

  title: string;

  uri: string;
};

export type GetActionValueFiles = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  size: number;

  contentType: string;
};

export type GetActionValueComments = {
  __typename?: 'Comment';

  id: string;

  author: GetActionValueAuthor;

  dateCreated: Date;

  lastUpdated: Date;

  message: string;

  files: GetActionValue_Files[];

  replies: GetActionValueReplies[];
};

export type GetActionValueAuthor = {
  __typename?: 'Person';

  picture: Maybe<string>;

  id: string;

  name: string;

  role: Maybe<string>;

  organization: Maybe<GetActionValueOrganization>;
};

export type GetActionValueOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type GetActionValue_Files = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  size: number;

  contentType: string;
};

export type GetActionValueReplies = {
  __typename?: 'Comment';

  id: string;

  author: GetActionValue_Author;

  dateCreated: Date;

  lastUpdated: Date;

  message: string;

  files: GetActionValue__Files[];
};

export type GetActionValue_Author = {
  __typename?: 'Person';

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<GetActionValue_Organization>;
};

export type GetActionValue_Organization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type GetActionValue__Files = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  size: number;

  contentType: string;
};

export type GetActionValueAllKostraFunctionGroups = FieldsOnKostraFunctionGroupFragment;

export type GetActionValueAllKostraFunctions = FieldsOnKostraFunctionFragment;

export type GetActionValueMe = {
  __typename?: 'Me';

  id: string;

  name: string;

  jobTitle: Maybe<string>;

  picture: Maybe<string>;

  isAdmin: boolean;

  organization: Maybe<GetActionValue__Organization>;
};

export type GetActionValue__Organization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type GetActionValueAllRealms = {
  __typename?: 'Realm';

  id: string;

  title: string;

  i18nCode: string;

  iso3166: string;

  localId: string;

  parent: Maybe<string>;

  type: string;
};

export type ActionValueVariables = {
  id: string;
};

export type ActionValueQuery = {
  __typename?: 'Query';

  actionValue: Maybe<ActionValueActionValue>;

  me: ActionValueMe;
};

export type ActionValueActionValue = FieldsOnActionValueFragment;

export type ActionValueMe = FieldsOnMeSimpleFragment;

export type LastActionValueVariables = {};

export type LastActionValueQuery = {
  __typename?: 'Query';

  allActionValues: LastActionValueAllActionValues;
};

export type LastActionValueAllActionValues = {
  __typename?: 'AllActionValues';

  nodes: LastActionValueNodes[];
};

export type LastActionValueNodes = {
  __typename?: 'ActionValue';

  id: string;

  lastUpdated: Date;
};

export type AllActionValuesVariables = {
  sort?: Maybe<SortEnum>;
  order?: Maybe<OrderEnum>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
  kostraGroup?: Maybe<Long>;
  onlyFollowed?: Maybe<boolean>;
};

export type AllActionValuesQuery = {
  __typename?: 'Query';

  allKostraFunctionGroups: AllActionValuesAllKostraFunctionGroups[];

  allActionValues: AllActionValuesAllActionValues;

  allSectors: AllActionValuesAllSectors[];
};

export type AllActionValuesAllKostraFunctionGroups = {
  __typename?: 'KostraFunctionGroup';

  id: string;

  title: string;
};

export type AllActionValuesAllActionValues = {
  __typename?: 'AllActionValues';

  pageInfo: AllActionValuesPageInfo;

  totalCount: number;

  nodes: AllActionValuesNodes[];
};

export type AllActionValuesPageInfo = {
  __typename?: 'PageInfo';

  hasNextPage: boolean;

  hasPreviousPage: boolean;

  itemsPerPage: number;

  numberOfPages: number;

  pageNumber: number;
};

export type AllActionValuesNodes = FieldsOnActionValueFragment;

export type AllActionValuesAllSectors = {
  __typename?: 'Sector';

  id: string;

  title: string;
};

export type YearWheelVariables = {
  person: string;
};

export type YearWheelQuery = {
  __typename?: 'Query';

  me: YearWheelMe;

  myCalendars: YearWheelMyCalendars[];

  yearWheel: YearWheelYearWheel[];

  myAssignedTasks: YearWheelMyAssignedTasks;
};

export type YearWheelMe = {
  __typename?: 'Me';

  id: string;

  calendarCode: string;

  picture: Maybe<string>;

  isAdmin: boolean;

  settings: YearWheelSettings;
};

export type YearWheelSettings = {
  __typename?: 'UserSetting';

  showAssignedTasks: boolean;

  calendarReverseDirection: boolean;

  calendarElementView: boolean;

  calendarUseCtrl: boolean;

  calendarStartMonth: number;

  calendarHideCompleted: boolean;
};

export type YearWheelMyCalendars = FieldsOnCalendarLookupFragment;

export type YearWheelYearWheel = FieldsOnCalendarRingFragment;

export type YearWheelMyAssignedTasks = FieldsOnCalendarRingFragment;

export type YearWheelRingVariables = {
  calendarId: string;
  year?: Maybe<number>;
  from?: Maybe<Date>;
  to?: Maybe<Date>;
};

export type YearWheelRingQuery = {
  __typename?: 'Query';

  calendar: Maybe<YearWheelRingCalendar>;
};

export type YearWheelRingCalendar = {
  __typename?: 'Calendar';

  tasks: YearWheelRingTasks[];
} & FieldsOnCalendarFragment;

export type YearWheelRingTasks = {
  __typename?: 'Task';

  events: YearWheelRingEvents[];
} & FieldsOnTaskFragment;

export type YearWheelRingEvents = {
  __typename?: 'Event';

  instances: YearWheelRingInstances[];
} & FieldsOnEventFragment;

export type YearWheelRingInstances = FieldsOnEventInstanceFragment;

export type YearWheelMagicRingVariables = {
  year?: Maybe<number>;
  from?: Maybe<Date>;
  to?: Maybe<Date>;
};

export type YearWheelMagicRingQuery = {
  __typename?: 'Query';

  myAssignedTasks: YearWheelMagicRingMyAssignedTasks;
};

export type YearWheelMagicRingMyAssignedTasks = {
  __typename?: 'CalendarRing';

  calendar: YearWheelMagicRingCalendar;
} & FieldsOnCalendarRingFragment;

export type YearWheelMagicRingCalendar = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  uri: string;

  description: Maybe<string>;

  category: CalendarCategoryEnum;

  color: string;

  status: ContentStatus;

  lastUpdated: Date;

  dateCreated: Date;

  isFollowing: boolean;

  isRestricted: boolean;

  isLimited: boolean;

  access: YearWheelMagicRingAccess;

  topics: YearWheelMagicRingTopics[];

  createdBy: YearWheelMagicRingCreatedBy;

  tasks: YearWheelMagicRingTasks[];
};

export type YearWheelMagicRingAccess = {
  __typename?: 'Access';

  myAccess: AccessEnum;

  isPrivate: boolean;

  persons: YearWheelMagicRingPersons[];
};

export type YearWheelMagicRingPersons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: YearWheelMagicRingPerson;
};

export type YearWheelMagicRingPerson = {
  __typename?: 'Person';

  id: string;
};

export type YearWheelMagicRingTopics = {
  __typename?: 'Topic';

  id: string;

  title: string;

  uri: string;

  dateCreated: Date;

  isFollowing: boolean;

  isLimited: boolean;

  isRestricted: boolean;
};

export type YearWheelMagicRingCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<YearWheelMagicRingOrganization>;
};

export type YearWheelMagicRingOrganization = {
  __typename?: 'Organization';

  id: string;

  organizationImage: Maybe<string>;

  name: string;
};

export type YearWheelMagicRingTasks = {
  __typename?: 'Task';

  events: YearWheelMagicRingEvents[];
} & FieldsOnTaskFragment;

export type YearWheelMagicRingEvents = {
  __typename?: 'Event';

  instances: YearWheelMagicRingInstances[];
} & FieldsOnEventFragment;

export type YearWheelMagicRingInstances = FieldsOnEventInstanceFragment;

export type UpdateSettingsVariables = {
  input: UpdateMeSettingsInput;
};

export type UpdateSettingsMutation = {
  __typename?: 'Mutation';

  updateSettings: Maybe<UpdateSettingsUpdateSettings>;
};

export type UpdateSettingsUpdateSettings = {
  __typename?: 'Me';

  id: string;

  calendarCode: string;

  picture: Maybe<string>;

  isAdmin: boolean;

  settings: UpdateSettingsSettings;
};

export type UpdateSettingsSettings = {
  __typename?: 'UserSetting';

  showAssignedTasks: boolean;

  calendarReverseDirection: boolean;

  calendarElementView: boolean;

  calendarUseCtrl: boolean;

  calendarStartMonth: number;

  calendarHideCompleted: boolean;
};

export type CreateCalendarRingMutationVariables = {
  input: CreateCalendarRingInput;
};

export type CreateCalendarRingMutationMutation = {
  __typename?: 'Mutation';

  createCalendarRing: Maybe<CreateCalendarRingMutationCreateCalendarRing>;
};

export type CreateCalendarRingMutationCreateCalendarRing = FieldsOnCalendarRingFragment;

export type DeleteCalendarRingMutationVariables = {
  id: string;
};

export type DeleteCalendarRingMutationMutation = {
  __typename?: 'Mutation';

  deleteCalendarRing: Maybe<boolean>;
};

export type MyCalendarsVariables = {};

export type MyCalendarsQuery = {
  __typename?: 'Query';

  myCalendars: MyCalendarsMyCalendars[];
};

export type MyCalendarsMyCalendars = FieldsOnCalendarLookupFragment;

export type CalendarVariables = {
  id: string;
  year?: Maybe<number>;
};

export type CalendarQuery = {
  __typename?: 'Query';

  me: CalendarMe;

  yearWheel: CalendarYearWheel[];

  calendar: Maybe<Calendar_Calendar>;
};

export type CalendarMe = {
  __typename?: 'Me';

  id: string;

  calendarCode: string;

  picture: Maybe<string>;

  isAdmin: boolean;

  settings: CalendarSettings;
};

export type CalendarSettings = {
  __typename?: 'UserSetting';

  calendarHideCompleted: boolean;

  showAssignedTasks: boolean;
};

export type CalendarYearWheel = {
  __typename?: 'CalendarRing';

  calendar: CalendarCalendar;
};

export type CalendarCalendar = {
  __typename?: 'Calendar';

  id: string;
};

export type Calendar_Calendar = {
  __typename?: 'Calendar';

  tasks: CalendarTasks[];
} & FieldsOnCalendarFragment;

export type CalendarTasks = {
  __typename?: 'Task';

  events: CalendarEvents[];
} & FieldsOnTaskFragment;

export type CalendarEvents = {
  __typename?: 'Event';

  instances: CalendarInstances[];
} & FieldsOnEventFragment;

export type CalendarInstances = FieldsOnEventInstanceFragment;

export type FollowCalendarVariables = {
  id: string;
};

export type FollowCalendarMutation = {
  __typename?: 'Mutation';

  follow: Maybe<boolean>;
};

export type UnfollowCalendarVariables = {
  id: string;
};

export type UnfollowCalendarMutation = {
  __typename?: 'Mutation';

  unFollow: Maybe<boolean>;
};

export type OrganizationsVariables = {
  query: string;
};

export type OrganizationsQuery = {
  __typename?: 'Query';

  search: OrganizationsSearch;
};

export type OrganizationsSearch = {
  __typename?: 'Search';

  organizations: OrganizationsOrganizations[];
};

export type OrganizationsOrganizations = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type MeForCalendarListPageVariables = {};

export type MeForCalendarListPageQuery = {
  __typename?: 'Query';

  me: MeForCalendarListPageMe;
};

export type MeForCalendarListPageMe = {
  __typename?: 'Me';

  id: string;

  name: string;

  isAdmin: boolean;

  userRoles: string[];

  permissions: string[];

  language: Maybe<string>;

  organization: Maybe<MeForCalendarListPageOrganization>;
};

export type MeForCalendarListPageOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;
};

export type FindOrganizatinsVariables = {
  query: string;
};

export type FindOrganizatinsQuery = {
  __typename?: 'Query';

  findOrganizations: FindOrganizatinsFindOrganizations[];
};

export type FindOrganizatinsFindOrganizations = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type AllCalendarsVariables = {
  sort?: Maybe<SortEnum>;
  order?: Maybe<OrderEnum>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
  organizations?: Maybe<Long[]>;
  kostraGroup?: Maybe<Long>;
};

export type AllCalendarsQuery = {
  __typename?: 'Query';

  me: AllCalendarsMe;

  allKostraFunctionGroups: AllCalendarsAllKostraFunctionGroups[];

  allCalendars: AllCalendarsAllCalendars;
};

export type AllCalendarsMe = {
  __typename?: 'Me';

  id: string;

  name: string;

  isAdmin: boolean;

  userRoles: string[];

  permissions: string[];

  language: Maybe<string>;

  organization: Maybe<AllCalendarsOrganization>;
};

export type AllCalendarsOrganization = {
  __typename?: 'Organization';

  id: string;
};

export type AllCalendarsAllKostraFunctionGroups = {
  __typename?: 'KostraFunctionGroup';

  id: string;

  title: string;
};

export type AllCalendarsAllCalendars = {
  __typename?: 'AllCalendars';

  pageInfo: AllCalendarsPageInfo;

  totalCount: number;

  nodes: AllCalendarsNodes[];
};

export type AllCalendarsPageInfo = {
  __typename?: 'PageInfo';

  hasNextPage: boolean;

  hasPreviousPage: boolean;

  itemsPerPage: number;

  numberOfPages: number;

  pageNumber: number;
};

export type AllCalendarsNodes = FieldsOnCalendarFragment;

export type GetMyFollowedActionValuesVariables = {};

export type GetMyFollowedActionValuesQuery = {
  __typename?: 'Query';

  myFollowed: GetMyFollowedActionValuesMyFollowed;
};

export type GetMyFollowedActionValuesMyFollowed = {
  __typename?: 'MyFollowed';

  actionValues: GetMyFollowedActionValuesActionValues[];
};

export type GetMyFollowedActionValuesActionValues = {
  __typename?: 'ActionValue';

  id: string;

  isFollowing: boolean;

  lastUpdated: Date;
};

export type GetActionValuesVariables = {
  input: AllActionValuesInput;
};

export type GetActionValuesQuery = {
  __typename?: 'Query';

  allActionValues: GetActionValuesAllActionValues;

  myFollowed: GetActionValuesMyFollowed;
};

export type GetActionValuesAllActionValues = {
  __typename?: 'AllActionValues';

  nodes: GetActionValuesNodes[];
};

export type GetActionValuesNodes = {
  __typename?: 'ActionValue';

  id: string;

  uri: string;

  lastUpdated: Date;

  title: string;

  isFollowing: boolean;

  dateCreated: Date;
};

export type GetActionValuesMyFollowed = {
  __typename?: 'MyFollowed';

  actionValues: GetActionValuesActionValues[];
};

export type GetActionValuesActionValues = {
  __typename?: 'ActionValue';

  id: string;

  uri: string;

  lastUpdated: Date;

  title: string;

  isFollowing: boolean;

  dateCreated: Date;
};

export type FollowGrantVariables = {
  id: string;
};

export type FollowGrantMutation = {
  __typename?: 'Mutation';

  follow: Maybe<boolean>;
};

export type UnfollowGrantVariables = {
  id: string;
};

export type UnfollowGrantMutation = {
  __typename?: 'Mutation';

  unFollow: Maybe<boolean>;
};

export type GetMyFollowedCalendarsVariables = {};

export type GetMyFollowedCalendarsQuery = {
  __typename?: 'Query';

  myFollowed: GetMyFollowedCalendarsMyFollowed;
};

export type GetMyFollowedCalendarsMyFollowed = {
  __typename?: 'MyFollowed';

  calendars: GetMyFollowedCalendarsCalendars[];
};

export type GetMyFollowedCalendarsCalendars = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  description: Maybe<string>;

  color: string;

  isFollowing: boolean;

  lastUpdated: Date;
};

export type GetOneCalendarVariables = {
  id: string;
};

export type GetOneCalendarQuery = {
  __typename?: 'Query';

  calendar: Maybe<GetOneCalendarCalendar>;
};

export type GetOneCalendarCalendar = {
  __typename?: 'Calendar';

  id: string;

  isFollowing: boolean;

  likes: number;

  isLiked: boolean;

  followers: GetOneCalendarFollowers[];
};

export type GetOneCalendarFollowers = {
  __typename?: 'Person';

  id: string;
};

export type AllCalendarsForDashboardVariables = {
  input: AllCalendarsInput;
};

export type AllCalendarsForDashboardQuery = {
  __typename?: 'Query';

  allCalendars: AllCalendarsForDashboardAllCalendars;

  myFollowed: AllCalendarsForDashboardMyFollowed;

  me: AllCalendarsForDashboardMe;
};

export type AllCalendarsForDashboardAllCalendars = {
  __typename?: 'AllCalendars';

  nodes: AllCalendarsForDashboardNodes[];
};

export type AllCalendarsForDashboardNodes = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  uri: string;

  lastUpdated: Date;

  isLiked: boolean;

  status: ContentStatus;

  isFollowing: boolean;
};

export type AllCalendarsForDashboardMyFollowed = {
  __typename?: 'MyFollowed';

  calendars: AllCalendarsForDashboardCalendars[];
};

export type AllCalendarsForDashboardCalendars = {
  __typename?: 'Calendar';

  id: string;

  isFollowing: boolean;

  title: string;

  uri: string;

  lastUpdated: Date;

  status: ContentStatus;
};

export type AllCalendarsForDashboardMe = {
  __typename?: 'Me';

  id: string;

  organization: Maybe<AllCalendarsForDashboardOrganization>;
};

export type AllCalendarsForDashboardOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type MeForDashboardVariables = {};

export type MeForDashboardQuery = {
  __typename?: 'Query';

  me: MeForDashboardMe;
};

export type MeForDashboardMe = {
  __typename?: 'Me';

  id: string;

  picture: Maybe<string>;
};

export type FollowCalendarDashboardVariables = {
  id: string;
};

export type FollowCalendarDashboardMutation = {
  __typename?: 'Mutation';

  follow: Maybe<boolean>;
};

export type UnfollowCalendarDashboardVariables = {
  id: string;
};

export type UnfollowCalendarDashboardMutation = {
  __typename?: 'Mutation';

  unFollow: Maybe<boolean>;
};

export type MyFollowedDashboardVariables = {};

export type MyFollowedDashboardQuery = {
  __typename?: 'Query';

  myFollowed: MyFollowedDashboardMyFollowed;
};

export type MyFollowedDashboardMyFollowed = {
  __typename?: 'MyFollowed';

  actionValues: MyFollowedDashboardActionValues[];

  calendars: MyFollowedDashboardCalendars[];

  topics: MyFollowedDashboardTopics[];

  discussions: MyFollowedDashboardDiscussions[];
};

export type MyFollowedDashboardActionValues = {
  __typename?: 'ActionValue';

  id: string;

  title: string;

  lastUpdated: Date;

  uri: string;
};

export type MyFollowedDashboardCalendars = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  lastUpdated: Date;

  uri: string;
};

export type MyFollowedDashboardTopics = {
  __typename?: 'Topic';

  id: string;

  title: string;

  lastUpdated: Date;

  uri: string;
};

export type MyFollowedDashboardDiscussions = {
  __typename?: 'Discussion';

  id: string;

  title: string;

  lastUpdated: Date;

  uri: string;
};

export type GetMyFollowedDiscussionsVariables = {};

export type GetMyFollowedDiscussionsQuery = {
  __typename?: 'Query';

  myFollowed: GetMyFollowedDiscussionsMyFollowed;
};

export type GetMyFollowedDiscussionsMyFollowed = {
  __typename?: 'MyFollowed';

  discussions: GetMyFollowedDiscussionsDiscussions[];
};

export type GetMyFollowedDiscussionsDiscussions = {
  __typename?: 'Discussion';

  id: string;

  isFollowing: boolean;

  lastUpdated: Date;
};

export type GetDiscussionsVariables = {
  input: AllDiscussionsInput;
};

export type GetDiscussionsQuery = {
  __typename?: 'Query';

  allDiscussions: GetDiscussionsAllDiscussions;

  me: GetDiscussionsMe;

  myFollowed: GetDiscussionsMyFollowed;
};

export type GetDiscussionsAllDiscussions = {
  __typename?: 'AllDiscussions';

  nodes: GetDiscussionsNodes[];
};

export type GetDiscussionsNodes = {
  __typename?: 'Discussion';

  id: string;

  uri: string;

  lastUpdated: Date;

  title: string;

  dateCreated: Date;

  isFollowing: boolean;
};

export type GetDiscussionsMe = {
  __typename?: 'Me';

  id: string;

  organization: Maybe<GetDiscussionsOrganization>;
};

export type GetDiscussionsOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type GetDiscussionsMyFollowed = {
  __typename?: 'MyFollowed';

  discussions: GetDiscussionsDiscussions[];
};

export type GetDiscussionsDiscussions = {
  __typename?: 'Discussion';

  id: string;

  uri: string;

  lastUpdated: Date;

  title: string;

  isFollowing: boolean;

  dateCreated: Date;
};

export type FollowDiscussionVariables = {
  id: string;
};

export type FollowDiscussionMutation = {
  __typename?: 'Mutation';

  follow: Maybe<boolean>;
};

export type UnfollowDiscussionVariables = {
  id: string;
};

export type UnfollowDiscussionMutation = {
  __typename?: 'Mutation';

  unFollow: Maybe<boolean>;
};

export type MyFollowedTopicsVariables = {};

export type MyFollowedTopicsQuery = {
  __typename?: 'Query';

  myFollowed: MyFollowedTopicsMyFollowed;
};

export type MyFollowedTopicsMyFollowed = {
  __typename?: 'MyFollowed';

  topics: MyFollowedTopicsTopics[];
};

export type MyFollowedTopicsTopics = {
  __typename?: 'Topic';

  id: string;

  isFollowing: boolean;

  lastUpdated: Date;
};

export type GetTopicsVariables = {
  input: AllTopicsInput;
};

export type GetTopicsQuery = {
  __typename?: 'Query';

  allTopics: GetTopicsAllTopics;

  me: GetTopicsMe;
};

export type GetTopicsAllTopics = {
  __typename?: 'AllTopics';

  nodes: GetTopicsNodes[];
};

export type GetTopicsNodes = {
  __typename?: 'Topic';

  id: string;

  uri: string;

  lastUpdated: Date;

  title: string;

  dateCreated: Date;

  isFollowing: boolean;
};

export type GetTopicsMe = {
  __typename?: 'Me';

  id: string;

  organization: Maybe<GetTopicsOrganization>;
};

export type GetTopicsOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FollowTopicVariables = {
  id: string;
};

export type FollowTopicMutation = {
  __typename?: 'Mutation';

  follow: Maybe<boolean>;
};

export type UnfollowTopicVariables = {
  id: string;
};

export type UnfollowTopicMutation = {
  __typename?: 'Mutation';

  unFollow: Maybe<boolean>;
};

export type UpdateDiscussionVariables = {
  input: UpdateDiscussionInput;
};

export type UpdateDiscussionMutation = {
  __typename?: 'Mutation';

  updateDiscussion: Maybe<UpdateDiscussionUpdateDiscussion>;
};

export type UpdateDiscussionUpdateDiscussion = {
  __typename?: 'Discussion';

  id: string;

  status: ContentStatus;
};

export type CreateCommentVariables = {
  input: CreateCommentInput;
};

export type CreateCommentMutation = {
  __typename?: 'Mutation';

  createComment: Maybe<CreateCommentCreateComment>;
};

export type CreateCommentCreateComment = {
  __typename?: 'Comment';

  id: string;
};

export type UpdateCommentVariables = {
  input: UpdateCommentInput;
};

export type UpdateCommentMutation = {
  __typename?: 'Mutation';

  updateComment: Maybe<UpdateCommentUpdateComment>;
};

export type UpdateCommentUpdateComment = {
  __typename?: 'Comment';

  id: string;
};

export type SetStatusVariables = {
  input: SetStatusInput;
};

export type SetStatusMutation = {
  __typename?: 'Mutation';

  setStatus: Maybe<boolean>;
};

export type DiscussionVariables = {
  id: string;
};

export type DiscussionQuery = {
  __typename?: 'Query';

  discussion: Maybe<DiscussionDiscussion>;

  me: DiscussionMe;
};

export type DiscussionDiscussion = {
  __typename?: 'Discussion';

  id: string;

  title: string;

  dateCreated: Date;

  lastUpdated: Date;

  isLiked: boolean;

  uri: string;

  likes: number;

  description: Maybe<string>;

  status: ContentStatus;

  isFollowing: boolean;

  createdBy: DiscussionCreatedBy;

  currentRevision: DiscussionCurrentRevision;

  topics: DiscussionTopics[];

  files: DiscussionFiles[];

  comments: DiscussionComments[];
};

export type DiscussionCreatedBy = FieldsOnAuthorFragment;

export type DiscussionCurrentRevision = {
  __typename?: 'ActionValueRevision';

  id: string;

  description: Maybe<string>;

  body: Maybe<string>;
};

export type DiscussionTopics = {
  __typename?: 'Topic';

  id: string;

  title: string;

  uri: string;
};

export type DiscussionFiles = FieldsOnUserFileFragment;

export type DiscussionComments = {
  __typename?: 'Comment';

  replies: DiscussionReplies[];
} & FieldsOnCommentFragment;

export type DiscussionReplies = FieldsOnRepliesFragment;

export type DiscussionMe = FieldsOnMeSimpleFragment;

export type AllDiscussionsVariables = {
  sort?: Maybe<SortEnum>;
  order?: Maybe<OrderEnum>;
  onlyMine?: Maybe<boolean>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
  organizations?: Maybe<Long[]>;
};

export type AllDiscussionsQuery = {
  __typename?: 'Query';

  me: AllDiscussionsMe;

  allDiscussions: AllDiscussionsAllDiscussions;
};

export type AllDiscussionsMe = {
  __typename?: 'Me';

  id: string;

  organization: Maybe<AllDiscussionsOrganization>;
};

export type AllDiscussionsOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;
};

export type AllDiscussionsAllDiscussions = {
  __typename?: 'AllDiscussions';

  pageInfo: AllDiscussionsPageInfo;

  totalCount: number;

  nodes: AllDiscussionsNodes[];
};

export type AllDiscussionsPageInfo = {
  __typename?: 'PageInfo';

  hasNextPage: boolean;

  hasPreviousPage: boolean;

  itemsPerPage: number;

  numberOfPages: number;

  pageNumber: number;
};

export type AllDiscussionsNodes = FieldsOnDiscussionFragment;

export type CreateDiscussion2Variables = {
  input: CreateDiscussionInput;
};

export type CreateDiscussion2Mutation = {
  __typename?: 'Mutation';

  createDiscussion: Maybe<CreateDiscussion2CreateDiscussion>;
};

export type CreateDiscussion2CreateDiscussion = FieldsOnDiscussionFragment;

export type IsUserLoggedInVariables = {};

export type IsUserLoggedInQuery = {
  __typename?: 'Query';

  isLoggedIn: boolean;
};

export type GrantsTestQueryVariables = {};

export type GrantsTestQueryQuery = {
  __typename?: 'Query';

  allActionValues: GrantsTestQueryAllActionValues;
};

export type GrantsTestQueryAllActionValues = {
  __typename?: 'AllActionValues';

  nodes: GrantsTestQueryNodes[];
};

export type GrantsTestQueryNodes = {
  __typename?: 'ActionValue';

  id: string;

  title: string;
};

export type AddTopicMutationVariables = {
  input: CreateTopicInput;
};

export type AddTopicMutationMutation = {
  __typename?: 'Mutation';

  createTopic: Maybe<AddTopicMutationCreateTopic>;
};

export type AddTopicMutationCreateTopic = {
  __typename?: 'Topic';

  id: string;

  uri: string;

  title: string;

  description: Maybe<string>;

  isRestricted: boolean;

  status: ContentStatus;

  currentRevision: Maybe<AddTopicMutationCurrentRevision>;
};

export type AddTopicMutationCurrentRevision = {
  __typename?: 'TopicRevision';

  body: Maybe<string>;
};

export type MeCreateTopicVariables = {};

export type MeCreateTopicQuery = {
  __typename?: 'Query';

  me: MeCreateTopicMe;
};

export type MeCreateTopicMe = {
  __typename?: 'Me';

  id: string;

  name: string;

  picture: Maybe<string>;

  jobTitle: Maybe<string>;

  department: Maybe<string>;

  kostraFunctions: MeCreateTopicKostraFunctions[];

  organization: Maybe<MeCreateTopicOrganization>;
};

export type MeCreateTopicKostraFunctions = {
  __typename?: 'KostraFunction';

  id: string;
};

export type MeCreateTopicOrganization = {
  __typename?: 'Organization';

  id: string;

  organizationImage: Maybe<string>;

  name: string;
};

export type UpdateTopicVariables = {
  input: UpdateTopicInput;
};

export type UpdateTopicMutation = {
  __typename?: 'Mutation';

  updateTopic: Maybe<UpdateTopicUpdateTopic>;
};

export type UpdateTopicUpdateTopic = {
  __typename?: 'Topic';

  id: string;

  uri: string;
};

export type TopicQueryVariables = {
  id: string;
};

export type TopicQueryQuery = {
  __typename?: 'Query';

  me: TopicQueryMe;

  topic: Maybe<TopicQueryTopic>;
};

export type TopicQueryMe = {
  __typename?: 'Me';

  id: string;

  name: string;

  picture: Maybe<string>;

  jobTitle: Maybe<string>;

  isAdmin: boolean;

  department: Maybe<string>;

  organization: Maybe<TopicQueryOrganization>;
};

export type TopicQueryOrganization = FieldsOnOrganizationFragment;

export type TopicQueryTopic = {
  __typename?: 'Topic';

  id: string;

  uri: string;

  access: TopicQueryAccess;

  title: string;

  isLiked: boolean;

  createdBy: Maybe<TopicQuery__CreatedBy>;

  calendars: Maybe<TopicQueryCalendars[]>;

  description: Maybe<string>;

  isRestricted: boolean;

  isLimited: boolean;

  isFollowing: boolean;

  status: ContentStatus;

  currentRevision: Maybe<TopicQueryCurrentRevision>;

  followers: Maybe<TopicQuery_Followers[]>;

  actionValues: Maybe<TopicQueryActionValues[]>;

  discussions: Maybe<TopicQueryDiscussions[]>;

  files: Maybe<TopicQuery_Files[]>;

  topics: Maybe<TopicQuery__Topics[]>;
};

export type TopicQueryAccess = {
  __typename?: 'Access';

  isPrivate: boolean;

  myAccess: AccessEnum;

  visibility: ContentVisibilityEnum;

  groups: TopicQueryGroups[];

  organizationGroups: TopicQueryOrganizationGroups[];

  organizations: TopicQueryOrganizations[];

  persons: TopicQueryPersons[];
};

export type TopicQueryGroups = {
  __typename?: 'GroupAccess';

  group: TopicQueryGroup;

  access: AccessEnum;
};

export type TopicQueryGroup = {
  __typename?: 'PersonGroup';

  id: string;

  title: string;

  createdBy: TopicQueryCreatedBy;

  dateCreated: Date;

  lastUpdated: Date;

  members: TopicQueryMembers[];
};

export type TopicQueryCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type TopicQueryMembers = {
  __typename?: 'Person';

  name: string;

  id: string;
};

export type TopicQueryOrganizationGroups = {
  __typename?: 'OrganizationGroupAccess';

  access: AccessEnum;

  organizationGroup: TopicQueryOrganizationGroup;
};

export type TopicQueryOrganizationGroup = {
  __typename?: 'OrganizationGroup';

  createdBy: TopicQuery_CreatedBy;

  id: string;

  lastUpdated: Date;

  title: string;

  members: TopicQuery_Members[];
};

export type TopicQuery_CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type TopicQuery_Members = {
  __typename?: 'Organization';

  name: string;

  id: string;
};

export type TopicQueryOrganizations = {
  __typename?: 'OrganizationAccess';

  access: AccessEnum;

  organization: TopicQuery_Organization;
};

export type TopicQuery_Organization = {
  __typename?: 'Organization';

  name: string;

  id: string;
};

export type TopicQueryPersons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: TopicQueryPerson;
};

export type TopicQueryPerson = {
  __typename?: 'Person';

  name: string;

  id: string;

  department: Maybe<string>;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery__Organization>;
};

export type TopicQuery__Organization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type TopicQuery__CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery___Organization>;
};

export type TopicQuery___Organization = FieldsOnOrganizationFragment;

export type TopicQueryCalendars = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  uri: string;

  description: Maybe<string>;

  color: string;

  status: ContentStatus;

  category: CalendarCategoryEnum;

  lastUpdated: Date;

  dateCreated: Date;

  isFollowing: boolean;

  isRestricted: boolean;

  isLimited: boolean;

  isLiked: boolean;

  likes: number;

  followers: TopicQueryFollowers[];

  topics: TopicQueryTopics[];

  createdBy: TopicQuery___CreatedBy;
};

export type TopicQueryFollowers = {
  __typename?: 'Person';

  id: string;
};

export type TopicQueryTopics = {
  __typename?: 'Topic';

  id: string;

  uri: string;

  title: string;

  likes: number;

  isLiked: boolean;

  isFollowing: boolean;

  isLimited: boolean;

  isRestricted: boolean;
};

export type TopicQuery___CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery____Organization>;
};

export type TopicQuery____Organization = FieldsOnOrganizationFragment;

export type TopicQueryCurrentRevision = {
  __typename?: 'TopicRevision';

  id: string;

  body: Maybe<string>;

  category: TopicCategoryEnum;

  sector: Maybe<TopicQuerySector>;

  links: TopicQueryLinks[];

  kostraFunctions: Maybe<TopicQueryKostraFunctions[]>;
};

export type TopicQuerySector = {
  __typename?: 'Sector';

  id: string;

  title: string;
};

export type TopicQueryLinks = {
  __typename?: 'HttpLink';

  label: string;

  href: string;
};

export type TopicQueryKostraFunctions = {
  __typename?: 'KostraFunction';

  id: string;

  kid: number;

  title: string;
};

export type TopicQuery_Followers = {
  __typename?: 'Person';

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery_____Organization>;
};

export type TopicQuery_____Organization = FieldsOnOrganizationFragment;

export type TopicQueryActionValues = FieldsOnActionValueFragment;

export type TopicQueryDiscussions = {
  __typename?: 'Discussion';

  id: string;

  title: string;

  isLiked: boolean;

  likes: number;

  views: number;

  createdBy: TopicQuery____CreatedBy;

  dateCreated: Date;

  lastUpdated: Date;

  uri: string;

  description: Maybe<string>;

  status: ContentStatus;

  isFollowing: boolean;

  currentRevision: TopicQuery_CurrentRevision;

  comments: TopicQueryComments[];

  topics: TopicQuery_Topics[];

  files: TopicQueryFiles[];

  followers: TopicQuery__Followers[];
};

export type TopicQuery____CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  department: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery______Organization>;
};

export type TopicQuery______Organization = FieldsOnOrganizationFragment;

export type TopicQuery_CurrentRevision = {
  __typename?: 'ActionValueRevision';

  id: string;

  body: Maybe<string>;
};

export type TopicQueryComments = {
  __typename?: 'Comment';

  dateCreated: Date;
};

export type TopicQuery_Topics = {
  __typename?: 'Topic';

  id: string;

  title: string;

  createdBy: Maybe<TopicQuery_____CreatedBy>;
};

export type TopicQuery_____CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  department: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery_______Organization>;
};

export type TopicQuery_______Organization = FieldsOnOrganizationFragment;

export type TopicQueryFiles = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  createdBy: TopicQuery______CreatedBy;

  access: TopicQuery_Access;

  size: number;

  isLiked: boolean;

  likes: number;

  contentType: string;
};

export type TopicQuery______CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  department: Maybe<string>;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery________Organization>;
};

export type TopicQuery________Organization = FieldsOnOrganizationFragment;

export type TopicQuery_Access = {
  __typename?: 'Access';

  myAccess: AccessEnum;
};

export type TopicQuery__Followers = {
  __typename?: 'Person';

  id: string;
};

export type TopicQuery_Files = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  size: number;

  isLiked: boolean;

  access: TopicQuery__Access;

  createdBy: TopicQuery_______CreatedBy;

  likes: number;

  contentType: string;
};

export type TopicQuery__Access = {
  __typename?: 'Access';

  myAccess: AccessEnum;
};

export type TopicQuery_______CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery_________Organization>;
};

export type TopicQuery_________Organization = FieldsOnOrganizationFragment;

export type TopicQuery__Topics = {
  __typename?: 'Topic';

  id: string;

  access: TopicQuery___Access;

  createdBy: Maybe<TopicQuery__________CreatedBy>;

  title: string;

  lastUpdated: Date;

  uri: string;

  description: Maybe<string>;

  likes: number;

  isLiked: boolean;

  isRestricted: boolean;

  followers: Maybe<TopicQuery___Followers[]>;

  isLimited: boolean;

  currentRevision: Maybe<TopicQuery__CurrentRevision>;
};

export type TopicQuery___Access = {
  __typename?: 'Access';

  visibility: ContentVisibilityEnum;

  isPrivate: boolean;

  myAccess: AccessEnum;

  groups: TopicQuery_Groups[];

  organizationGroups: TopicQuery_OrganizationGroups[];

  organizations: TopicQuery_Organizations[];

  persons: TopicQuery_Persons[];
};

export type TopicQuery_Groups = {
  __typename?: 'GroupAccess';

  group: TopicQuery_Group;

  access: AccessEnum;
};

export type TopicQuery_Group = {
  __typename?: 'PersonGroup';

  id: string;

  title: string;

  createdBy: TopicQuery________CreatedBy;

  dateCreated: Date;

  lastUpdated: Date;

  members: TopicQuery__Members[];
};

export type TopicQuery________CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type TopicQuery__Members = {
  __typename?: 'Person';

  name: string;

  id: string;
};

export type TopicQuery_OrganizationGroups = {
  __typename?: 'OrganizationGroupAccess';

  access: AccessEnum;

  organizationGroup: TopicQuery_OrganizationGroup;
};

export type TopicQuery_OrganizationGroup = {
  __typename?: 'OrganizationGroup';

  createdBy: TopicQuery_________CreatedBy;

  id: string;

  lastUpdated: Date;

  title: string;

  members: TopicQuery___Members[];
};

export type TopicQuery_________CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type TopicQuery___Members = {
  __typename?: 'Organization';

  name: string;

  id: string;
};

export type TopicQuery_Organizations = {
  __typename?: 'OrganizationAccess';

  access: AccessEnum;

  organization: TopicQuery__________Organization;
};

export type TopicQuery__________Organization = {
  __typename?: 'Organization';

  name: string;

  id: string;
};

export type TopicQuery_Persons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: TopicQuery_Person;
};

export type TopicQuery_Person = {
  __typename?: 'Person';

  name: string;

  id: string;

  department: Maybe<string>;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery___________Organization>;
};

export type TopicQuery___________Organization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type TopicQuery__________CreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<TopicQuery____________Organization>;
};

export type TopicQuery____________Organization = FieldsOnOrganizationFragment;

export type TopicQuery___Followers = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type TopicQuery__CurrentRevision = {
  __typename?: 'TopicRevision';

  id: string;

  category: TopicCategoryEnum;
};

export type AllTopicsAndMeQueryTVariables = {
  category?: Maybe<TopicCategorySortEnum>;
  sort?: Maybe<SortEnum>;
  order?: Maybe<OrderEnum>;
  status?: Maybe<ContentStatus>;
  limit?: Maybe<number>;
  offset?: Maybe<number>;
  kostraGroup?: Maybe<Long>;
  organizations?: Maybe<Long[]>;
  onlyMine?: Maybe<boolean>;
};

export type AllTopicsAndMeQueryTQuery = {
  __typename?: 'Query';

  allKostraFunctionGroups: AllTopicsAndMeQueryTAllKostraFunctionGroups[];

  me: AllTopicsAndMeQueryTMe;

  allSectors: AllTopicsAndMeQueryTAllSectors[];

  allTopics: AllTopicsAndMeQueryTAllTopics;
};

export type AllTopicsAndMeQueryTAllKostraFunctionGroups = {
  __typename?: 'KostraFunctionGroup';

  id: string;

  title: string;
};

export type AllTopicsAndMeQueryTMe = {
  __typename?: 'Me';

  id: string;

  name: string;

  isAdmin: boolean;

  userRoles: string[];

  permissions: string[];

  language: Maybe<string>;

  organization: Maybe<AllTopicsAndMeQueryTOrganization>;
};

export type AllTopicsAndMeQueryTOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;
};

export type AllTopicsAndMeQueryTAllSectors = {
  __typename?: 'Sector';

  id: string;

  title: string;
};

export type AllTopicsAndMeQueryTAllTopics = {
  __typename?: 'AllTopics';

  pageInfo: AllTopicsAndMeQueryTPageInfo;

  totalCount: number;

  nodes: AllTopicsAndMeQueryTNodes[];
};

export type AllTopicsAndMeQueryTPageInfo = {
  __typename?: 'PageInfo';

  hasNextPage: boolean;

  hasPreviousPage: boolean;

  itemsPerPage: number;

  numberOfPages: number;

  pageNumber: number;
};

export type AllTopicsAndMeQueryTNodes = FieldsOnTopicFragment;

export type SearchTopic2Variables = {
  query: string;
};

export type SearchTopic2Query = {
  __typename?: 'Query';

  search: SearchTopic2Search;
};

export type SearchTopic2Search = {
  __typename?: 'Search';

  topics: SearchTopic2Topics[];
};

export type SearchTopic2Topics = {
  __typename?: 'TopicSearch';

  id: string;

  title: string;
};

export type LikeVariables = {
  id: string;
};

export type LikeMutation = {
  __typename?: 'Mutation';

  like: Maybe<boolean>;
};

export type UnLikeVariables = {
  id: string;
};

export type UnLikeMutation = {
  __typename?: 'Mutation';

  unLike: Maybe<boolean>;
};

export type FieldsOnKostraFunctionGroupFragment = {
  __typename?: 'KostraFunctionGroup';

  id: string;

  kid: number;

  title: string;

  identifier: string;

  description: Maybe<string>;
};

export type FieldsOnKostraFunctionFragment = {
  __typename?: 'KostraFunction';

  id: string;

  kid: number;

  description: Maybe<string>;

  title: string;

  group: Maybe<FieldsOnKostraFunctionGroup>;
};

export type FieldsOnKostraFunctionGroup = FieldsOnKostraFunctionGroupFragment;

export type FieldsOnPersonFragment = {
  __typename?: 'Person';

  id: string;

  name: string;

  role: Maybe<string>;

  picture: Maybe<string>;

  organization: Maybe<FieldsOnPersonOrganization>;

  department: Maybe<string>;
};

export type FieldsOnPersonOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FieldsOnEventExtendedFragment = {
  __typename?: 'Event';

  id: string;

  title: string;

  description: Maybe<string>;

  startDate: Date;

  reminderDate: Maybe<Date>;

  files: FieldsOnEventExtendedFiles[];

  assignedBy: Maybe<FieldsOnEventExtendedAssignedBy>;

  endDate: Maybe<Date>;

  responsible: Maybe<FieldsOnEventExtendedResponsible>;

  repetitionDefinition: Maybe<string>;

  endRepetitionDate: Maybe<Date>;

  startRepetitionDate: Maybe<Date>;

  status: ContentStatus;

  todoTemplate: FieldsOnEventExtendedTodoTemplate[];

  instances: FieldsOnEventExtendedInstances[];
};

export type FieldsOnEventExtendedFiles = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  size: number;

  contentType: string;

  access: FieldsOnEventExtendedAccess;
};

export type FieldsOnEventExtendedAccess = {
  __typename?: 'Access';

  myAccess: AccessEnum;
};

export type FieldsOnEventExtendedAssignedBy = {
  __typename?: 'Person';

  department: Maybe<string>;
} & FieldsOnMiniPersonFragment;

export type FieldsOnEventExtendedResponsible = FieldsOnMiniPersonFragment;

export type FieldsOnEventExtendedTodoTemplate = {
  __typename?: 'TodoTemplate';

  id: string;

  title: string;

  description: Maybe<string>;

  status: ContentStatus;
};

export type FieldsOnEventExtendedInstances = {
  __typename?: 'EventInstance';

  id: string;

  title: string;

  files: FieldsOnEventExtended_Files[];

  description: Maybe<string>;

  isDone: boolean;

  assignedBy: Maybe<FieldsOnEventExtended_AssignedBy>;

  isFavourite: boolean;

  status: ContentStatus;

  note: Maybe<string>;

  startDate: Date;

  endDate: Maybe<Date>;

  responsible: Maybe<FieldsOnEventExtended_Responsible>;

  reminderDate: Maybe<Date>;

  createdBy: FieldsOnEventExtendedCreatedBy;

  todos: FieldsOnEventExtendedTodos[];
};

export type FieldsOnEventExtended_Files = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  size: number;

  contentType: string;

  access: FieldsOnEventExtended_Access;
};

export type FieldsOnEventExtended_Access = {
  __typename?: 'Access';

  myAccess: AccessEnum;
};

export type FieldsOnEventExtended_AssignedBy = {
  __typename?: 'Person';

  department: Maybe<string>;
} & FieldsOnMiniPersonFragment;

export type FieldsOnEventExtended_Responsible = FieldsOnMiniPersonFragment;

export type FieldsOnEventExtendedCreatedBy = FieldsOnMiniPersonFragment;

export type FieldsOnEventExtendedTodos = {
  __typename?: 'TodoInstanceType';

  id: string;

  title: string;

  description: Maybe<string>;

  isDone: boolean;

  status: ContentStatus;

  parent: Maybe<string>;

  fromTemplate: boolean;

  note: Maybe<string>;
};

export type FieldsOnMeFragment = {
  __typename?: 'Me';

  id: string;

  name: string;

  email: string;

  description: Maybe<string>;

  jobTitle: Maybe<string>;

  department: Maybe<string>;

  language: Maybe<string>;

  picture: Maybe<string>;

  phoneNumber: Maybe<string>;

  organization: Maybe<FieldsOnMeOrganization>;

  settings: FieldsOnMeSettings;

  kostraFunctions: FieldsOnMeKostraFunctions[];
};

export type FieldsOnMeOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FieldsOnMeSettings = {
  __typename?: 'UserSetting';

  showAssignedTasks: boolean;

  sendInstantNotificationMail: boolean;

  sendDailyNotificationMail: boolean;

  sendWeeklyNotificationMail: boolean;

  acceptedUserTerms: boolean;

  allowContactViaPhone: boolean;

  allowContactViaEmail: boolean;

  shouldReceiveNewsletter: boolean;
};

export type FieldsOnMeKostraFunctions = {
  __typename?: 'KostraFunction';

  id: string;

  kid: number;

  title: string;
};

export type FieldsOnRealmsFragment = {
  __typename?: 'Realm';

  id: string;

  title: string;

  i18nCode: string;

  iso3166: string;

  localId: string;

  parent: Maybe<string>;

  type: string;
};

export type FieldsOnActionValueFragment = {
  __typename?: 'ActionValue';

  id: string;

  title: string;

  dateCreated: Date;

  lastUpdated: Date;

  uri: string;

  likes: number;

  description: Maybe<string>;

  status: ContentStatus;

  isLiked: boolean;

  kostraFunctions: FieldsOnActionValueKostraFunctions[];

  isFollowing: boolean;

  currentRevision: FieldsOnActionValueCurrentRevision;

  topics: FieldsOnActionValueTopics[];

  files: FieldsOnActionValueFiles[];

  comments: FieldsOnActionValueComments[];
};

export type FieldsOnActionValueKostraFunctions = FieldsOnKostraFunctionFragment;

export type FieldsOnActionValueCurrentRevision = {
  __typename?: 'ActionValueRevision';

  id: string;

  description: Maybe<string>;

  body: Maybe<string>;

  year: Maybe<number>;

  sourceUrl: Maybe<string>;

  category: Maybe<string>;

  type: Maybe<string>;

  links: FieldsOnActionValueLinks[];

  source: Maybe<FieldsOnActionValueSource>;

  radarGraph: Maybe<FieldsOnActionValueRadarGraph[]>;

  deadlines: Maybe<FieldsOnActionValueDeadlines[]>;
};

export type FieldsOnActionValueLinks = {
  __typename?: 'HttpLink';

  label: string;

  href: string;
};

export type FieldsOnActionValueSource = {
  __typename?: 'Organization';

  id: string;

  name: string;
};

export type FieldsOnActionValueRadarGraph = {
  __typename?: 'RadarGraphType';

  name: string;

  sum: number;

  type: number;
};

export type FieldsOnActionValueDeadlines = {
  __typename?: 'DeadlineType';

  id: string;

  date: Date;

  type: string;
};

export type FieldsOnActionValueTopics = {
  __typename?: 'Topic';

  id: string;

  title: string;

  uri: string;
};

export type FieldsOnActionValueFiles = FieldsOnUserFileFragment;

export type FieldsOnActionValueComments = {
  __typename?: 'Comment';

  replies: FieldsOnActionValueReplies[];
} & FieldsOnCommentFragment;

export type FieldsOnActionValueReplies = FieldsOnRepliesFragment;

export type FieldsOnTaskFragment = {
  __typename?: 'Task';

  id: string;

  title: string;

  status: ContentStatus;
};

export type FieldsOnMiniPersonFragment = {
  __typename?: 'Person';

  id: string;

  name: string;

  role: Maybe<string>;

  picture: Maybe<string>;
};

export type FieldsOnEventFragment = {
  __typename?: 'Event';

  id: string;

  title: string;

  description: Maybe<string>;

  startDate: Date;

  endDate: Maybe<Date>;

  startRepetitionDate: Maybe<Date>;

  status: ContentStatus;

  assignedBy: Maybe<FieldsOnEventAssignedBy>;

  endRepetitionDate: Maybe<Date>;

  instances: FieldsOnEventInstances[];

  repetitionDefinition: Maybe<string>;

  responsible: Maybe<FieldsOnEventResponsible>;

  todoTemplate: FieldsOnEventTodoTemplate[];
};

export type FieldsOnEventAssignedBy = FieldsOnMiniPersonFragment;

export type FieldsOnEventInstances = FieldsOnEventInstanceFragment;

export type FieldsOnEventResponsible = FieldsOnMiniPersonFragment;

export type FieldsOnEventTodoTemplate = {
  __typename?: 'TodoTemplate';

  id: string;

  title: string;

  status: ContentStatus;
};

export type FieldsOnEventInstanceFragment = {
  __typename?: 'EventInstance';

  id: string;

  title: string;

  description: Maybe<string>;

  isDone: boolean;

  isFavourite: boolean;

  startDate: Date;

  status: ContentStatus;

  endDate: Maybe<Date>;

  assignedBy: Maybe<FieldsOnEventInstanceAssignedBy>;

  reminderDate: Maybe<Date>;

  note: Maybe<string>;

  createdBy: FieldsOnEventInstanceCreatedBy;

  responsible: Maybe<FieldsOnEventInstanceResponsible>;
};

export type FieldsOnEventInstanceAssignedBy = FieldsOnMiniPersonFragment;

export type FieldsOnEventInstanceCreatedBy = FieldsOnMiniPersonFragment;

export type FieldsOnEventInstanceResponsible = FieldsOnMiniPersonFragment;

export type FieldsOnCalendarFragment = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  uri: string;

  description: Maybe<string>;

  category: CalendarCategoryEnum;

  color: string;

  status: ContentStatus;

  lastUpdated: Date;

  dateCreated: Date;

  isFollowing: boolean;

  isRestricted: boolean;

  isLimited: boolean;

  isLiked: boolean;

  likes: number;

  access: FieldsOnCalendarAccess;

  topics: FieldsOnCalendarTopics[];

  followers: FieldsOnCalendarFollowers[];

  createdBy: FieldsOnCalendarCreatedBy;
};

export type FieldsOnCalendarAccess = {
  __typename?: 'Access';

  myAccess: AccessEnum;

  isPrivate: boolean;

  persons: FieldsOnCalendarPersons[];
};

export type FieldsOnCalendarPersons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: FieldsOnCalendarPerson;
};

export type FieldsOnCalendarPerson = {
  __typename?: 'Person';

  id: string;
};

export type FieldsOnCalendarTopics = {
  __typename?: 'Topic';

  id: string;

  title: string;

  uri: string;

  dateCreated: Date;

  isFollowing: boolean;

  isLimited: boolean;

  isRestricted: boolean;
};

export type FieldsOnCalendarFollowers = {
  __typename?: 'Person';

  id: string;
};

export type FieldsOnCalendarCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<FieldsOnCalendarOrganization>;
};

export type FieldsOnCalendarOrganization = {
  __typename?: 'Organization';

  id: string;

  organizationImage: Maybe<string>;

  name: string;
};

export type FieldsOnCalendarLookupFragment = {
  __typename?: 'Calendar';

  id: string;

  title: string;

  description: Maybe<string>;

  color: string;

  access: FieldsOnCalendarLookupAccess;

  createdBy: FieldsOnCalendarLookupCreatedBy;
};

export type FieldsOnCalendarLookupAccess = {
  __typename?: 'Access';

  persons: FieldsOnCalendarLookupPersons[];
};

export type FieldsOnCalendarLookupPersons = {
  __typename?: 'PersonAccess';

  access: AccessEnum;

  person: FieldsOnCalendarLookupPerson;
};

export type FieldsOnCalendarLookupPerson = {
  __typename?: 'Person';

  id: string;
};

export type FieldsOnCalendarLookupCreatedBy = {
  __typename?: 'Person';

  id: string;
};

export type FieldsOnCalendarRingFragment = {
  __typename?: 'CalendarRing';

  id: string;

  order: number;

  color: string;

  calendar: FieldsOnCalendarRingCalendar;
};

export type FieldsOnCalendarRingCalendar = FieldsOnCalendarLookupFragment;

export type FieldsOnUserFileFragment = {
  __typename?: 'UserFile';

  id: string;

  name: string;

  description: string;

  lastUpdated: Date;

  size: number;

  likes: number;

  isLiked: boolean;

  contentType: string;

  access: FieldsOnUserFileAccess;

  createdBy: FieldsOnUserFileCreatedBy;
};

export type FieldsOnUserFileAccess = {
  __typename?: 'Access';

  myAccess: AccessEnum;
};

export type FieldsOnUserFileCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  picture: Maybe<string>;

  organization: Maybe<FieldsOnUserFileOrganization>;
};

export type FieldsOnUserFileOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FieldsOnAuthorFragment = {
  __typename?: 'Person';

  id: string;

  name: string;

  role: Maybe<string>;

  picture: Maybe<string>;

  organization: Maybe<FieldsOnAuthorOrganization>;
};

export type FieldsOnAuthorOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FieldsOnRepliesFragment = {
  __typename?: 'Comment';

  id: string;

  message: string;

  dateCreated: Date;

  lastUpdated: Date;

  status: ContentStatus;

  likes: number;

  isLiked: boolean;

  author: FieldsOnRepliesAuthor;
};

export type FieldsOnRepliesAuthor = FieldsOnAuthorFragment;

export type FieldsOnCommentFragment = {
  __typename?: 'Comment';

  id: string;

  message: string;

  dateCreated: Date;

  likes: number;

  lastUpdated: Date;

  status: ContentStatus;

  isLiked: boolean;

  replies: FieldsOnCommentReplies[];

  author: FieldsOnCommentAuthor;

  files: FieldsOnCommentFiles[];
};

export type FieldsOnCommentReplies = FieldsOnRepliesFragment;

export type FieldsOnCommentAuthor = FieldsOnAuthorFragment;

export type FieldsOnCommentFiles = FieldsOnUserFileFragment;

export type FieldsOnMeSimpleFragment = {
  __typename?: 'Me';

  id: string;

  name: string;

  jobTitle: Maybe<string>;

  picture: Maybe<string>;

  isAdmin: boolean;

  calendarCode: string;

  organization: Maybe<FieldsOnMeSimpleOrganization>;
};

export type FieldsOnMeSimpleOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FieldsOnDiscussionFragment = {
  __typename?: 'Discussion';

  id: string;

  title: string;

  isLiked: boolean;

  likes: number;

  views: number;

  createdBy: FieldsOnDiscussionCreatedBy;

  dateCreated: Date;

  lastUpdated: Date;

  uri: string;

  description: Maybe<string>;

  status: ContentStatus;

  isFollowing: boolean;

  currentRevision: FieldsOnDiscussionCurrentRevision;

  comments: FieldsOnDiscussionComments[];

  topics: FieldsOnDiscussionTopics[];

  followers: FieldsOnDiscussionFollowers[];

  files: FieldsOnDiscussionFiles[];
};

export type FieldsOnDiscussionCreatedBy = {
  __typename?: 'Person';

  department: Maybe<string>;

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<FieldsOnDiscussionOrganization>;
};

export type FieldsOnDiscussionOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FieldsOnDiscussionCurrentRevision = {
  __typename?: 'ActionValueRevision';

  id: string;

  body: Maybe<string>;
};

export type FieldsOnDiscussionComments = {
  __typename?: 'Comment';

  id: string;

  dateCreated: Date;
};

export type FieldsOnDiscussionTopics = {
  __typename?: 'Topic';

  id: string;

  title: string;

  uri: string;

  createdBy: Maybe<FieldsOnDiscussion_CreatedBy>;
};

export type FieldsOnDiscussion_CreatedBy = {
  __typename?: 'Person';

  department: Maybe<string>;

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<FieldsOnDiscussion_Organization>;
};

export type FieldsOnDiscussion_Organization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FieldsOnDiscussionFollowers = {
  __typename?: 'Person';

  id: string;

  name: string;
};

export type FieldsOnDiscussionFiles = {
  __typename?: 'UserFile';

  contentType: string;

  createdBy: FieldsOnDiscussion__CreatedBy;

  id: string;

  isLiked: boolean;

  lastUpdated: Date;

  likes: number;

  name: string;

  description: string;

  size: number;

  access: FieldsOnDiscussionAccess;
};

export type FieldsOnDiscussion__CreatedBy = {
  __typename?: 'Person';

  department: Maybe<string>;

  id: string;

  name: string;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<FieldsOnDiscussion__Organization>;
};

export type FieldsOnDiscussion__Organization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

export type FieldsOnDiscussionAccess = {
  __typename?: 'Access';

  myAccess: AccessEnum;
};

export type FieldsOnOrganizationFragment = {
  __typename?: 'Organization';

  id: string;

  organizationImage: Maybe<string>;

  name: string;
};

export type FieldsOnTopicTopicFragment = {
  __typename?: 'Topic';

  id: string;

  uri: string;

  title: string;

  description: Maybe<string>;

  isRestricted: boolean;

  isLimited: boolean;

  status: ContentStatus;

  dateCreated: Date;

  lastUpdated: Date;

  isLiked: boolean;

  isFollowing: boolean;
};

export type FieldsOnTopicFragment = {
  __typename?: 'Topic';

  id: string;

  uri: string;

  title: string;

  description: Maybe<string>;

  isRestricted: boolean;

  isLimited: boolean;

  status: ContentStatus;

  lastUpdated: Date;

  likes: number;

  isFollowing: boolean;

  isLiked: boolean;

  topics: Maybe<FieldsOnTopicTopics[]>;

  currentRevision: Maybe<FieldsOnTopicCurrentRevision>;

  followers: Maybe<FieldsOnTopicFollowers[]>;

  createdBy: Maybe<FieldsOnTopicCreatedBy>;
};

export type FieldsOnTopicTopics = FieldsOnTopicTopicFragment;

export type FieldsOnTopicCurrentRevision = {
  __typename?: 'TopicRevision';

  id: string;

  body: Maybe<string>;

  description: Maybe<string>;

  category: TopicCategoryEnum;
};

export type FieldsOnTopicFollowers = {
  __typename?: 'Person';

  id: string;
};

export type FieldsOnTopicCreatedBy = {
  __typename?: 'Person';

  id: string;

  name: string;

  department: Maybe<string>;

  picture: Maybe<string>;

  role: Maybe<string>;

  organization: Maybe<FieldsOnTopicOrganization>;
};

export type FieldsOnTopicOrganization = {
  __typename?: 'Organization';

  id: string;

  name: string;

  organizationImage: Maybe<string>;
};

// ====================================================
// Scalars
// ====================================================

// ====================================================
// Types
// ====================================================

/** description of type Queries */
export interface Queries {
  actionValue?: Maybe<ActionValue>;
  /** List of all available action values */
  allActionValues: AllActionValues;

  topic?: Maybe<Topic>;
  /** List of all available topics */
  allTopics: AllTopics;

  discussion?: Maybe<Discussion>;
  /** List of all available discussions */
  allDiscussions: AllDiscussions;

  calendar?: Maybe<Calendar>;
  /** List of all available calendars */
  allCalendars: AllCalendars;
  /** List of your calendars */
  myCalendars: Calendar[];
  /** Get annual cycle for person */
  yearWheel: CalendarRing[];
  /** description of field myAssignedTasks */
  myAssignedTasks: CalendarRing;

  task?: Maybe<Task>;

  eventInstance?: Maybe<EventInstance>;

  me: Me;

  findPersonsByNameOrEmail: Person[];
  /** description of field findOrganizations */
  findOrganizations: Organization[];

  allSectors: Sector[];

  search: Search;

  myFollowed: MyFollowed;

  allKostraFunctions: KostraFunction[];

  allKostraFunctionGroups: KostraFunctionGroup[];

  allRealms: Realm[];

  upcomingDeadlines: EventInstance[];

  fileInfo: UserFile;

  upcomingReminders: Reminder[];

  eventNoteSummary: EventNoteSummary[];

  event: Event;

  isLoggedIn: boolean;

  cartItems: (Maybe<Launch>)[];

  yearWheelData?: Maybe<(Maybe<Calendar>)[]>;

  yearWheelDataStatus?: Maybe<(Maybe<LoadCalendarState>)[]>;
}

/** An action value */
export interface ActionValue {
  /** id */
  id: string;
  /** Date the action value was created */
  dateCreated: Date;
  /** Date the action value was last updated */
  lastUpdated: Date;
  /** The title of the action value */
  title: string;

  uri: string;
  /** Short description of the action value */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** Publication status of the action value */
  status: ContentStatus;
  /** description of field year */
  year: Date;
  /** The current revision of the action value */
  currentRevision: ActionValueRevision;
  /** Comments on this action value */
  comments: Comment[];
  /** Topics related to this action value */
  topics: Topic[];
  /** Other related action values */
  actionValues: ActionValue[];
  /** Discussions related to this action value */
  discussions: Discussion[];
  /** Files attached to this action value */
  files: UserFile[];
  /** Related calendars */
  calendars: Calendar[];
  /** If you are following this action value */
  isFollowing: boolean;
  /** If you like this */
  isLiked: boolean;
  /** description of field likes */
  likes: number;
  /** People following this action value */
  followers: Person[];

  kostraFunctions: KostraFunction[];
  /** description of field realms */
  realms: Realm[];
  /** description of field views */
  views: number;
}

/** Hello World */
export interface ActionValueRevision {
  /** id */
  id: string;
  /** Creation date of the revision */
  dateCreated: Date;
  /** Last update to this revision */
  lastUpdated: Date;
  /** The title of the action value at the time of this revision */
  title: string;
  /** The year of the action value */
  year?: Maybe<number>;
  /** Short description of the action value */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** Long description of the action value */
  body?: Maybe<string>;

  bodyText?: Maybe<string>;

  links: HttpLink[];

  sourceUrl?: Maybe<string>;

  source?: Maybe<Organization>;

  category?: Maybe<string>;

  type?: Maybe<string>;

  radarGraph?: Maybe<RadarGraphType[]>;

  deadlines?: Maybe<DeadlineType[]>;
}

export interface HttpLink {
  label: string;

  href: string;
}

/** An organization */
export interface Organization {
  /** id */
  id: string;
  /** The organizations name */
  name: string;
  /** The organizations image */
  organizationImage?: Maybe<string>;
}

export interface RadarGraphType {
  name: string;

  sum: number;

  type: number;
}

export interface DeadlineType {
  id: string;

  date: Date;

  type: string;
}

/** A comment on something */
export interface Comment {
  /** id */
  id: string;
  /** The author of the comment */
  author: Person;
  /** Date the comment was posted */
  dateCreated: Date;
  /** Date the comment was last edited */
  lastUpdated: Date;
  /** The comment message body */
  message: string;

  messageText: string;
  /** Replies to the comment */
  replies: Comment[];
  /** Files attached to this comment */
  files: UserFile[];
  /** status */
  status: ContentStatus;
  /** If you like this */
  isLiked: boolean;
  /** description of field likes */
  likes: number;
}

/** A person */
export interface Person {
  /** id */
  id: string;
  /** The persons name */
  name: string;
  /** The persons self defined role */
  role?: Maybe<string>;
  /** The persons department */
  department?: Maybe<string>;
  /** description of field picture */
  picture?: Maybe<string>;
  /** The organization the person belongs to */
  organization?: Maybe<Organization>;
}

/** A file */
export interface UserFile {
  /** id */
  id: string;
  /** Filename */
  name: string;

  lastUpdated: Date;

  description: string;

  descriptionText: string;
  /** description of field size */
  size: number;
  /** description of field contentType */
  contentType: string;
  /** If you like this */
  isLiked: boolean;
  /** description of field likes */
  likes: number;

  createdBy: Person;

  access: Access;
}

/** description of Access */
export interface Access {
  /** description of field isPrivate */
  isPrivate: boolean;
  /** description of field visibility */
  visibility: ContentVisibilityEnum;
  /** description of field persons */
  persons: PersonAccess[];
  /** description of field groups */
  groups: GroupAccess[];
  /** description of field organizations */
  organizations: OrganizationAccess[];
  /** description of field organizationGroups */
  organizationGroups: OrganizationGroupAccess[];

  myAccess: AccessEnum;
}

/** description of PersonAccess */
export interface PersonAccess {
  /** description of field person */
  person: Person;
  /** description of field access */
  access: AccessEnum;
}

/** description of GroupAccess */
export interface GroupAccess {
  /** description of field group */
  group: PersonGroup;
  /** description of field access */
  access: AccessEnum;
}

/** description of PersonGroup */
export interface PersonGroup {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field createdBy */
  createdBy: Person;
  /** description of field dateCreated */
  dateCreated: Date;
  /** description of field lastUpdated */
  lastUpdated: Date;
  /** description of field members */
  members: Person[];
}

/** description of OrganizationAccess */
export interface OrganizationAccess {
  /** description of field organization */
  organization: Organization;
  /** description of field access */
  access: AccessEnum;
}

/** description of OrganizationGroupAccess */
export interface OrganizationGroupAccess {
  /** description of field organizationGroup */
  organizationGroup: OrganizationGroup;
  /** description of field access */
  access: AccessEnum;
}

/** description of OrganizationGroup */
export interface OrganizationGroup {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field createdBy */
  createdBy: Person;
  /** description of field dateCreated */
  dateCreated: Date;
  /** description of field lastUpdated */
  lastUpdated: Date;
  /** description of field members */
  members: Organization[];
}

/** A topic */
export interface Topic {
  /** id */
  id: string;
  /** The URI of this topic */
  uri: string;
  /** Date the topic was created */
  dateCreated: Date;
  /** Date the topic was last updated */
  lastUpdated: Date;
  /** Title of the topic */
  title: string;
  /** Short description of the topic */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** Publication status of the topic */
  status: ContentStatus;

  createdBy?: Maybe<Person>;
  /** The current revision of this topic */
  currentRevision?: Maybe<TopicRevision>;
  /** Related topics */
  topics?: Maybe<Topic[]>;
  /** Related action values */
  actionValues?: Maybe<ActionValue[]>;
  /** Related discussions */
  discussions?: Maybe<Discussion[]>;
  /** Related files */
  files?: Maybe<UserFile[]>;
  /** Related calendars */
  calendars?: Maybe<Calendar[]>;
  /** If you are following this topic */
  isFollowing: boolean;
  /** If you like this */
  isLiked: boolean;
  /** description of field likes */
  likes: number;
  /** People following this topic */
  followers?: Maybe<Person[]>;
  /** If true, this topic has access restrictions */
  isRestricted: boolean;
  /** If true, your user dont have access to this topic */
  isLimited: boolean;
  /** description of field views */
  views: number;
  /** description of field access */
  access: Access;
}

/** A revision of an topic */
export interface TopicRevision {
  /** id */
  id: string;
  /** Creation date of the revision */
  dateCreated: Date;
  /** Last update to this revision */
  lastUpdated: Date;
  /** The title of the topic at the time of this revision */
  title: string;
  /** Short description of the topic */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** Long description of the topic */
  body?: Maybe<string>;

  bodyText?: Maybe<string>;

  category: TopicCategoryEnum;
  /** description of field sector */
  sector?: Maybe<Sector>;

  links: HttpLink[];
  /** description of field kostraFunctions */
  kostraFunctions?: Maybe<KostraFunction[]>;
}

export interface Sector {
  id: string;

  title: string;

  kostraFunctions: KostraFunction[];
}

export interface KostraFunction {
  id: string;

  title: string;

  kid: number;

  group?: Maybe<KostraFunctionGroup>;

  description?: Maybe<string>;
}

export interface KostraFunctionGroup {
  id: string;

  kid: number;

  title: string;

  identifier: string;

  description?: Maybe<string>;

  parent?: Maybe<KostraFunctionGroup>;
  /** description of field kostraFunctions */
  kostraFunctions: KostraFunction[];
}

/** A forum discussion */
export interface Discussion {
  /** id */
  id: string;

  createdBy: Person;
  /** Date the discussion was posted */
  dateCreated: Date;
  /** Last update to the discussion */
  lastUpdated: Date;
  /** The title of the discussion */
  title: string;

  uri: string;
  /** Short description of the discussion */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** Publication status of the discussion */
  status: ContentStatus;
  /** The current revision of the discussion */
  currentRevision: ActionValueRevision;
  /** Replies to this discussion */
  comments: Comment[];
  /** Related topics to this discussion */
  topics: Topic[];
  /** Related action values to this discussions */
  actionValues: ActionValue[];
  /** Other related discussions */
  discussions: Discussion[];
  /** Files attached to the first post of this discussion */
  files: UserFile[];
  /** Related calendars */
  calendars: Calendar[];
  /** If you are following this discussion */
  isFollowing: boolean;
  /** If you like this */
  isLiked: boolean;
  /** description of field likes */
  likes: number;
  /** People following this discussion */
  followers: Person[];
  /** description of field views */
  views: number;
}

/** An annual cycle */
export interface Calendar {
  /** id */
  id: string;

  title: string;

  uri: string;

  description?: Maybe<string>;

  descriptionText?: Maybe<string>;

  dateCreated: Date;

  lastUpdated: Date;

  createdBy: Person;
  /** Default color of the calendar */
  color: string;
  /** Tasks in this calendar */
  tasks: Task[];
  /** status */
  status: ContentStatus;
  /** description of field access */
  access: Access;
  /** If you are following this calendar */
  isFollowing: boolean;
  /** If you like this */
  isLiked: boolean;
  /** description of field likes */
  likes: number;
  /** description of field followers */
  followers: Person[];
  /** description of field topics */
  topics: Topic[];
  /** description of field actionValues */
  actionValues: ActionValue[];
  /** description of field discussions */
  discussions: Discussion[];
  /** description of field files */
  files: UserFile[];
  /** description of field calendars */
  calendars: Calendar[];
  /** description of field views */
  views: number;
  /** If true, this calendar has access restrictions */
  isRestricted: boolean;
  /** If true, you do not have access to this calendar */
  isLimited: boolean;
  /** description of field kostraFunctions */
  kostraFunctions: KostraFunction[];
  /** description of field category */
  category: CalendarCategoryEnum;

  shareableUri?: Maybe<string>;
}

/** A task */
export interface Task {
  /** id */
  id: string;

  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;

  dateCreated: Date;

  lastUpdated: Date;

  parent?: Maybe<Task>;
  /** Events in this task */
  events: Event[];
  /** status */
  status: ContentStatus;

  calendars: Calendar[];
  /** description of field type */
  type: TaskTypeEnum;
}

/** An event */
export interface Event {
  /** id */
  id: string;

  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;

  dateCreated: Date;

  lastUpdated: Date;

  createdBy: Person;

  startDate: Date;

  endDate?: Maybe<Date>;

  startRepetitionDate?: Maybe<Date>;

  endRepetitionDate?: Maybe<Date>;

  repetitionDefinition?: Maybe<string>;

  todoTemplate: TodoTemplate[];

  instances: EventInstance[];
  /** status */
  status: ContentStatus;
  /** description of field responsible */
  responsible?: Maybe<Person>;
  /** description of field assignedBy */
  assignedBy?: Maybe<Person>;
  /** Files attached to this event */
  files: UserFile[];

  task: Task;

  reminderDate?: Maybe<Date>;
}

export interface TodoTemplate {
  /** id */
  id: string;

  title: string;

  dateCreated: Date;

  lastUpdated: Date;

  description?: Maybe<string>;

  descriptionText?: Maybe<string>;
  /** status */
  status: ContentStatus;
}

/** An instance of an event. This is the object we need to complete :-) */
export interface EventInstance {
  /** id */
  id: string;
  /** This is the title to show in task list! */
  title: string;
  /** description of field description */
  description?: Maybe<string>;

  descriptionText?: Maybe<string>;

  dateCreated: Date;

  lastUpdated: Date;

  createdBy: Person;

  startDate: Date;

  endDate?: Maybe<Date>;

  isDone: boolean;

  isFavourite: boolean;
  /** status */
  status: ContentStatus;
  /** list of smaller todo items */
  todos: TodoInstanceType[];

  reminderDate?: Maybe<Date>;
  /** description of field responsible */
  responsible?: Maybe<Person>;
  /** description of field note */
  note?: Maybe<string>;

  noteText?: Maybe<string>;
  /** description of field assignedBy */
  assignedBy?: Maybe<Person>;
  /** Files attached to this event instance */
  files: UserFile[];

  event: Event;
}

export interface TodoInstanceType {
  /** id */
  id: string;

  title: string;

  dateCreated: Date;

  lastUpdated: Date;

  description?: Maybe<string>;

  descriptionText?: Maybe<string>;

  note?: Maybe<string>;

  isDone: boolean;
  /** status */
  status: ContentStatus;
  /** description of field responsible */
  responsible?: Maybe<Person>;
  /** if the TodoInstance is generated from a TodoTemplate on the event */
  fromTemplate: boolean;

  parent?: Maybe<string>;
}

export interface Realm {
  id: string;

  title: string;

  i18nCode: string;

  iso3166: string;

  localId: string;

  parent?: Maybe<string>;

  type: string;
}

/** description of AllActionValues */
export interface AllActionValues {
  /** description of field nodes */
  nodes: ActionValue[];
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: number;
}

/** description of PageInfo */
export interface PageInfo {
  /** description of field hasNextPage */
  hasNextPage: boolean;
  /** description of field hasPreviousPage */
  hasPreviousPage: boolean;
  /** description of field pageNumber */
  pageNumber: number;
  /** description of field numberOfPages */
  numberOfPages: number;
  /** description of field itemsPerPage */
  itemsPerPage: number;
}

/** description of AllTopics */
export interface AllTopics {
  /** description of field nodes */
  nodes: Topic[];
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: number;
}

/** description of AllDiscussions */
export interface AllDiscussions {
  /** description of field nodes */
  nodes: Discussion[];
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: number;
}

/** description of AllCalendars */
export interface AllCalendars {
  /** description of field nodes */
  nodes: Calendar[];
  /** description of field pageInfo */
  pageInfo: PageInfo;
  /** description of field totalCount */
  totalCount: number;
}

/** An annual cycle ring */
export interface CalendarRing {
  /** id */
  id: string;

  dateCreated: Date;

  lastUpdated: Date;
  /** Order in wheel */
  order: number;
  /** Color of the ring */
  color: string;
  /** Calendar data for this ring */
  calendar: Calendar;
}

/** Your person object */
export interface Me {
  /** id */
  id: string;
  /** Your name */
  name: string;
  /** Your users email */
  email: string;
  /** Your description */
  description?: Maybe<string>;
  /** Your job title */
  jobTitle?: Maybe<string>;
  /** Your department */
  department?: Maybe<string>;
  /** Your selected language */
  language?: Maybe<string>;
  /** Your secret calendar code */
  calendarCode: string;
  /** description of field picture */
  picture?: Maybe<string>;
  /** Your organization */
  organization?: Maybe<Organization>;
  /** Your selected KOSTRA functions */
  kostraFunctions: KostraFunction[];
  /** What functionality you have permission to use */
  permissions: string[];
  /** Your security level */
  userRoles: string[];
  /** If you have admin privileges */
  isAdmin: boolean;
  /** description of field settings */
  settings: UserSetting;
  /** description of field channel */
  channel: string;
  /** description of field phoneNumber */
  phoneNumber?: Maybe<string>;
}

/** Send a weekly email with unread notifications */
export interface UserSetting {
  /** description of field showAssignedTasks */
  showAssignedTasks: boolean;
  /** description of field allowContactViaEmail */
  allowContactViaEmail: boolean;
  /** description of field sendInstantNotificationMail */
  sendInstantNotificationMail: boolean;
  /** description of field sendDailyNotificationMail */
  sendDailyNotificationMail: boolean;
  /** description of field sendWeeklyNotificationMail */
  sendWeeklyNotificationMail: boolean;
  /** description of field acceptedUserTerms */
  acceptedUserTerms: boolean;
  /** description of field allowContactViaPhone */
  allowContactViaPhone: boolean;

  shouldReceiveNewsletter: boolean;

  calendarReverseDirection: boolean;

  calendarElementView: boolean;

  calendarUseCtrl: boolean;

  calendarStartMonth: number;

  calendarHideCompleted: boolean;
}

/** Search result */
export interface Search {
  persons: Person[];

  groups: PersonGroup[];

  organizations: Organization[];

  organizationGroups: OrganizationGroup[];

  actionValues: ActionValueSearch[];

  discussions: DiscussionSearch[];

  topics: TopicSearch[];

  calendars: CalendarSearch[];
}

/** description of ActionValueSearch */
export interface ActionValueSearch {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;
  /** description of field uri */
  uri: string;
}

/** description of DiscussionSearch */
export interface DiscussionSearch {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;
  /** description of field uri */
  uri: string;
  /** description of field answers */
  answers?: Maybe<number>;
}

/** description of TopicSearch */
export interface TopicSearch {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;
  /** description of field uri */
  uri: string;
}

/** description of CalendarSearch */
export interface CalendarSearch {
  /** description of field id */
  id: string;
  /** description of field title */
  title: string;
  /** description of field description */
  description?: Maybe<string>;
  /** description of field uri */
  uri: string;
}

export interface MyFollowed {
  actionValues: ActionValue[];

  discussions: Discussion[];

  topics: Topic[];

  calendars: Calendar[];
}

export interface Reminder {
  id: string;

  date: Date;

  reminderDate: Date;

  title: string;

  uri: string;
}

export interface EventNoteSummary {
  id: string;

  startDate: Date;

  endDate: Date;

  note: string;

  noteText: string;
}

export interface Launch {
  isInCart: boolean;
}

export interface LoadCalendarState {
  isFetching: boolean;

  calendarId: string;

  error?: Maybe<string>;

  lastFetched?: Maybe<Date>;

  lastFetchStarted?: Maybe<Date>;

  data?: Maybe<Calendar>;
}

/** description of type Mutations */
export interface Mutations {
  /** Update personal information */
  updateMe?: Maybe<Me>;
  /** Update your settings */
  updateSettings?: Maybe<Me>;
  /** Create a new topic */
  createTopic?: Maybe<Topic>;
  /** Update a topic */
  updateTopic?: Maybe<Topic>;
  /** Create a new calendar */
  createCalendar?: Maybe<Calendar>;
  /** Update an existing calendar */
  updateCalendar?: Maybe<Calendar>;
  /** Copy an existing calendar */
  copyCalendar?: Maybe<boolean>;
  /** Create a new annual cycle ring */
  createCalendarRing?: Maybe<CalendarRing>;
  /** Delete an existing annual cycle ring */
  deleteCalendarRing?: Maybe<boolean>;
  /** Create a new calendar task */
  createTask?: Maybe<Task>;
  /** Update an existing task */
  updateTask?: Maybe<Task>;
  /** Create a new task event */
  createEvent?: Maybe<Event>;
  /** Update an existing event */
  updateEvent?: Maybe<Event>;
  /** Update an existing event instance */
  updateEventInstance?: Maybe<EventInstance>;
  /** Create a new event todo template */
  createTodoTemplate?: Maybe<TodoTemplate>;
  /** Update an existing todo template */
  updateTodoTemplate?: Maybe<TodoTemplate>;
  /** Create a new event todo instance */
  createTodoInstance?: Maybe<TodoInstanceType>;
  /** Update an existing todo instance */
  updateTodoInstance?: Maybe<TodoInstanceType>;
  /** description of field createDiscussion */
  createDiscussion?: Maybe<Discussion>;
  /** description of field updateDiscussion */
  updateDiscussion?: Maybe<Discussion>;
  /** description of field createComment */
  createComment?: Maybe<Comment>;
  /** description of field updateComment */
  updateComment?: Maybe<Comment>;
  /** description of field createActionValue */
  createActionValue?: Maybe<ActionValue>;
  /** description of field updateActionValue */
  updateActionValue?: Maybe<ActionValue>;
  /** Add relation between a meta object and one or more meta objects */
  addToRelated: QueryStatus[];
  /** Remove relation between meta objects */
  removeFromRelated: QueryStatus[];
  /** Follow meta object */
  follow?: Maybe<boolean>;
  /** Un-follow meta object */
  unFollow?: Maybe<boolean>;
  /** Like meta object */
  like?: Maybe<boolean>;
  /** Unlike meta object */
  unLike?: Maybe<boolean>;
  /** Share meta object */
  share?: Maybe<boolean>;
  /** description of field setStatus */
  setStatus?: Maybe<boolean>;
  /** description of field createPersonGroup */
  createPersonGroup?: Maybe<PersonGroup>;
  /** description of field updatePersonGroup */
  updatePersonGroup?: Maybe<PersonGroup>;
  /** description of field createOrganizationGroup */
  createOrganizationGroup?: Maybe<OrganizationGroup>;
  /** description of field updateOrganizationGroup */
  updateOrganizationGroup?: Maybe<OrganizationGroup>;

  makeSharable?: Maybe<string>;
  /** Update file info */
  updateFileInfo?: Maybe<UserFile>;
  /** Delete an existing file */
  deleteFile?: Maybe<boolean>;

  addOrRemoveFromCart?: Maybe<(Maybe<Launch>)[]>;

  loadRingData?: Maybe<LoadCalendarState>;

  removeRingData?: Maybe<boolean>;
}

/** description of QueryStatusA */
export interface QueryStatus {
  /** description of field id */
  id: string;
  /** description of field success */
  success: boolean;
}

// ====================================================
// Arguments
// ====================================================

export interface ActionValueQueriesArgs {
  /** id */
  id: string;
}
export interface AllActionValuesQueriesArgs {
  /** input */
  input: AllActionValuesInput;
}
export interface TopicQueriesArgs {
  /** id */
  id: string;
}
export interface AllTopicsQueriesArgs {
  /** input */
  input: AllTopicsInput;
}
export interface DiscussionQueriesArgs {
  /** id */
  id: string;
}
export interface AllDiscussionsQueriesArgs {
  /** input */
  input: AllDiscussionsInput;
}
export interface CalendarQueriesArgs {
  /** id */
  id: string;
}
export interface AllCalendarsQueriesArgs {
  /** input */
  input: AllCalendarsInput;
}
export interface MyCalendarsQueriesArgs {
  /** input */
  input: MyCalendarsInput;
}
export interface YearWheelQueriesArgs {
  /** person */
  person: string;
}
export interface TaskQueriesArgs {
  /** id */
  id: string;
}
export interface EventInstanceQueriesArgs {
  /** id */
  id: string;
}
export interface FindPersonsByNameOrEmailQueriesArgs {
  /** query */
  query: string;
}
export interface FindOrganizationsQueriesArgs {
  /** query */
  query: string;
}
export interface SearchQueriesArgs {
  /** query */
  query: string;
}
export interface MyFollowedQueriesArgs {
  input?: Maybe<MyFollowedInput>;
}
export interface FileInfoQueriesArgs {
  id: string;
}
export interface EventNoteSummaryQueriesArgs {
  input?: Maybe<EventNoteSummaryInput>;
}
export interface EventQueriesArgs {
  id: string;
}
export interface YearWheelDataQueriesArgs {
  calendarIds: string[];
}
export interface EventsTaskArgs {
  /** year */
  year?: Maybe<number>;
  /** from */
  from?: Maybe<Date>;
  /** to */
  to?: Maybe<Date>;
}
export interface InstancesEventArgs {
  /** year */
  year?: Maybe<number>;
}
export interface UpdateMeMutationsArgs {
  /** input */
  input: UpdateMeInput;
}
export interface UpdateSettingsMutationsArgs {
  /** input */
  input: UpdateMeSettingsInput;
}
export interface CreateTopicMutationsArgs {
  /** input */
  input: CreateTopicInput;
}
export interface UpdateTopicMutationsArgs {
  /** input */
  input: UpdateTopicInput;
}
export interface CreateCalendarMutationsArgs {
  /** input */
  input: CreateCalendarInput;
}
export interface UpdateCalendarMutationsArgs {
  /** input */
  input: UpdateCalendarInput;
}
export interface CopyCalendarMutationsArgs {
  /** input */
  input: CopyCalendarInput;
}
export interface CreateCalendarRingMutationsArgs {
  /** input */
  input: CreateCalendarRingInput;
}
export interface DeleteCalendarRingMutationsArgs {
  /** id */
  id: string;
}
export interface CreateTaskMutationsArgs {
  /** input */
  input: CreateTaskInput;
}
export interface UpdateTaskMutationsArgs {
  /** input */
  input: UpdateTaskInput;
}
export interface CreateEventMutationsArgs {
  /** input */
  input: CreateEventInput;
}
export interface UpdateEventMutationsArgs {
  /** input */
  input: UpdateEventInput;
}
export interface UpdateEventInstanceMutationsArgs {
  /** input */
  input: UpdateEventInstanceInput;
}
export interface CreateTodoTemplateMutationsArgs {
  /** input */
  input: CreateTodoTemplateInput;
}
export interface UpdateTodoTemplateMutationsArgs {
  /** input */
  input: UpdateTodoTemplateInput;
}
export interface CreateTodoInstanceMutationsArgs {
  /** input */
  input: CreateTodoInstanceInput;
}
export interface UpdateTodoInstanceMutationsArgs {
  /** input */
  input: UpdateTodoInstanceInput;
}
export interface CreateDiscussionMutationsArgs {
  /** input */
  input: CreateDiscussionInput;
}
export interface UpdateDiscussionMutationsArgs {
  /** input */
  input: UpdateDiscussionInput;
}
export interface CreateCommentMutationsArgs {
  /** input */
  input: CreateCommentInput;
}
export interface UpdateCommentMutationsArgs {
  /** input */
  input: UpdateCommentInput;
}
export interface CreateActionValueMutationsArgs {
  /** input */
  input: CreateActionValueInput;
}
export interface UpdateActionValueMutationsArgs {
  /** input */
  input: UpdateActionValueInput;
}
export interface AddToRelatedMutationsArgs {
  /** input */
  input: AddToRelatedInput;
}
export interface RemoveFromRelatedMutationsArgs {
  /** input */
  input: RemoveFromRelatedInput;
}
export interface FollowMutationsArgs {
  /** id */
  id: string;
}
export interface UnFollowMutationsArgs {
  /** id */
  id: string;
}
export interface LikeMutationsArgs {
  /** id */
  id: string;
}
export interface UnLikeMutationsArgs {
  /** id */
  id: string;
}
export interface ShareMutationsArgs {
  /** input */
  input: ShareInput;
}
export interface SetStatusMutationsArgs {
  /** input */
  input: SetStatusInput;
}
export interface CreatePersonGroupMutationsArgs {
  /** input */
  input: CreatePersonGroupInput;
}
export interface UpdatePersonGroupMutationsArgs {
  /** input */
  input: UpdatePersonGroupInput;
}
export interface CreateOrganizationGroupMutationsArgs {
  /** input */
  input: CreateOrganizationGroupInput;
}
export interface UpdateOrganizationGroupMutationsArgs {
  /** input */
  input: UpdateOrganizationGroupInput;
}
export interface MakeSharableMutationsArgs {
  input: MakeSharableInput;
}
export interface UpdateFileInfoMutationsArgs {
  /** input */
  input: UpdateFileInfoInput;
}
export interface DeleteFileMutationsArgs {
  /** id */
  id: string;
}
export interface AddOrRemoveFromCartMutationsArgs {
  id: string;
}
export interface LoadRingDataMutationsArgs {
  calendarIds: string[];
}
export interface RemoveRingDataMutationsArgs {
  calendarId: string;
}
