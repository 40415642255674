import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { TextNormal } from '../../components/basic/Structural/typography';
import { Colors } from '../../components/common';
import { EmptyLayout } from '../../layouts/EmptyLayout';
import { InternalLink } from '../../components/basic/Structural/links';

const Container = styled.div`
  display: flex;
  //background-color: rebeccapurple;
  height: 100vh;
  justify-content: center;
  align-items: flex-start;
  padding: 1em;
`;

const Box = styled.div`
  border: 1px solid ${Colors.GREY};
  border-radius: 4px;
  padding: 1em;
  background-color: #fff;
  min-width: 25em;
  overflow: hidden;
  resize: none;
`;
const BigHeader = styled.div`
  font-size: 9em;
  color: ${Colors.GREYTEXT};
`;
const Text = styled.p`
  font-size: 2em;
  font-weight: 300;
`;

const Info = styled.div`
  font-weight: 400;
  font-size: 1em;
  color: ${Colors.GREYTEXT};
`;

const List = styled.ul`
  list-style: none none;
  padding-left: 0;
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    margin-top: 0;
  }
`;

const NotFoundComp: React.FunctionComponent<
  RouteComponentProps<{}>
> = props => {
  const { match: mm } = props;

  return (
    <EmptyLayout>
      <Container>
        <Box>
          <div>
            <BigHeader>Oops!</BigHeader>
            <TextNormal>
              We can't seem to find the page you're looking for.
            </TextNormal>
            <Info>
              error code: 404
              <br />
              url: {mm.url} <br />
              path: {mm.path} <br />
            </Info>
            <Info>
              Here are some useful links in stead: <br />
              <List>
                <li>
                  <InternalLink to={'/'}>Home</InternalLink>
                </li>
                <li>
                  <InternalLink to={'/about'}>About</InternalLink>
                </li>
                <li>
                  <InternalLink to={'/login'}>Login</InternalLink>
                </li>
              </List>
            </Info>
          </div>
        </Box>
      </Container>
    </EmptyLayout>
  );
};

const NotFoundPage = withRouter(NotFoundComp);

export default NotFoundPage;
