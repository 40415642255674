import * as React from 'react';
import styled from 'styled-components';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import {
  ContentStatus,
  FieldsOnEventExtendedFragment,
  FieldsOnEventExtendedInstances,
  FieldsOnEventInstances,
  GetInstanceAndTaskQuery,
  GetInstanceAndTaskVariables,
  GetReverseInstanceAndTaskQuery,
  GetReverseInstanceAndTaskVariables,
  TaskTypeEnum,
  UpdateEventInput,
  UpdateEventInstanceInput,
} from '../../../models/types';
import { Overlay } from '../../basic/Overlay/Overlay';
import { ColorTheme, safeInvokeDeprecated } from '../../common';
import { DialogBox } from '../../layout/Box/Box';
import { messages } from '../messages';
import {
  StatusMessage,
  ThemeColor,
} from '../../basic/StatusMessage/StatusMessage';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import { BlackSidebar } from './BlackSidebar';
import { QueryResult } from 'react-apollo';
import { CircleSpinner } from '../../basic/Loader/CircleSpinner';
import {
  GET_INSTANCE_AND_TASK_QUERY,
  GET_REVERSE_INSTANCE_AND_TASK_QUERY,
  GetInstanceAndTaskQueryComp,
  GetReverseInstanceAndTaskQueryComp,
  UPDATE_EVENT,
  UPDATE_EVENT_INSTANCE,
} from './eventQueries';
import InstanceForm from './InstanceForm';
import EventForm from './EventForm';
import { commonMessages } from '../../language/commonMessages';
import { Button } from '../../basic';
import { MunikumIcons } from '../../common/icons';

interface IEditEventInstanceFormProps {
  taskId: string;
  me?: any;
  year: number;

  /**
   * id to edit (initially). note that user can navigate to other instances and the event and edit those too!
   * set to undefined to edit series ... (great design..)
   */
  eventInstanceId: any;

  // eventId: string;

  // assigned tasks to find out if instance is one of your assigned tasks

  /**
   *
   * @param force - may the hac force be with you.. set to TRUE if you want to reload ALL rings...
   */
  onClose?: (force: boolean) => void;

  userHash: string;

  activeYearwheels: Array<any>;

  isCalendarPage: boolean;

  ringId: string;
}

const CustomDialogBox = styled(DialogBox)`
  padding: 0px;
  display: flex;
  border: none;
`;

const Left = styled.div`
  flex: 0 0 auto; // this doesnt stop it from growing??
  max-width: 490px;
  //background-color: red;
  border-top: 1px solid rgba(208, 211, 212, 0.7);
  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  padding: 1.875em;
`;

const Right = styled.div`
  flex: 1 1 auto;
  background-color: #efefef;
  border-top: 1px solid rgba(208, 211, 212, 0.7);
  border-right: 1px solid rgba(208, 211, 212, 0.7);
  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  padding: 3.5em 1.875em 1.875em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// this component can shoq both edit EVENT and edit INSTANCE and switch between them
enum FormMode {
  INSTANCE = 'INSTANCE',
  EVENT = 'EVENT',
}

interface IEditEventInstanceFormState {
  isDeleteMessageOpen: boolean;

  // this controls which INSTANCE we are editing
  eventInstanceId?: string;

  // this controls which EVENT we are editing
  eventId?: string;

  // set to ttrue first time we want to add repetition to a instance without repetition
  isAddingRepetition: boolean;

  isDeleting: boolean;
}

/**
 * Main component for editing INSTANCE and EVENT, with black sidebar on the left for navigation
 */
class EditEventInstanceFormComp extends React.PureComponent<
  IEditEventInstanceFormProps & InjectedIntlProps,
  IEditEventInstanceFormState
> {
  private deleteMssgRef: React.RefObject<any> = null;
  constructor(props: IEditEventInstanceFormProps & InjectedIntlProps) {
    super(props);
    this.deleteMssgRef = React.createRef();
    this.state = {
      isDeleteMessageOpen: false,
      eventInstanceId: props.eventInstanceId,
      eventId: undefined,
      isAddingRepetition: false,
      isDeleting: false,
    };
  }

  protected handleClickOutSide = (e: any) => {
    if (this.state.isDeleteMessageOpen) {
      if (
        this.deleteMssgRef &&
        !(
          this.deleteMssgRef.current.contains(e.target) ||
          this.deleteMssgRef.current.contains(e.target)
        )
      ) {
        if (
          confirm(this.props.intl.formatMessage(commonMessages.closeDialog))
        ) {
          this.setState({
            isDeleteMessageOpen: false,
          });
        }
      }
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutSide);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutSide);
  }

  handleDeleteInstance = (
    currentEventInstance: FieldsOnEventExtendedInstances
  ) => {
    if (!currentEventInstance) {
      console.warn('cannot delete..');
      return;
    }

    this.setState(
      {
        isDeleting: true,
      },
      () => {
        const input: UpdateEventInstanceInput = {
          id: currentEventInstance.id,
          title: currentEventInstance.title,
          description: currentEventInstance.description,
          startDate: currentEventInstance.startDate,
          endDate:
            currentEventInstance.endDate || currentEventInstance.startDate, // OK?
          status: ContentStatus.OBSOLETE,
          isDone: currentEventInstance.isDone,
          isFavourite: currentEventInstance.isFavourite,
          reminderDate: currentEventInstance.reminderDate,
          responsible: currentEventInstance.responsible
            ? currentEventInstance.responsible.id
            : undefined,
        };

        // query={GET_INSTANCE_AND_TASK_QUERY}
        // variables={{
        //   year: this.props.year,
        //     taskId: this.props.taskId,
        // }}

        myApolloClient
          .mutate({
            mutation: UPDATE_EVENT_INSTANCE,
            variables: { input: input },
            refetchQueries: [
              this.props.ringId !== '0'
                ? {
                    query: GET_INSTANCE_AND_TASK_QUERY,
                    variables: {
                      year: this.props.year,
                      taskId: this.props.taskId,
                    },
                  }
                : {
                    query: GET_REVERSE_INSTANCE_AND_TASK_QUERY,
                    variables: {
                      instanceId: this.props.eventInstanceId,
                    },
                  },
            ],
            awaitRefetchQueries: true, // need this for tempHack to work =)
          })
          .then(n => {
            this.setState(
              {
                isDeleteMessageOpen: false,
                isDeleting: false,
              },
              () => {
                safeInvokeDeprecated(this.props.onClose, true); // this will reload data
              }
            );
          })
          .catch(err => {
            console.log('err', err);
            // TODO: show errormessage if delete fails
            this.setState({
              isDeleting: false,
            });
          });
      }
    );
  };

  handleDeleteSeries = (currentEvent: FieldsOnEventExtendedFragment) => {
    // delete entire SERIES!

    if (!currentEvent) {
      console.warn('cannot delete event');
      return;
    }

    this.setState(
      {
        isDeleting: true,
      },
      () => {
        const input2: UpdateEventInput = {
          id: currentEvent.id,
          title: currentEvent.title,
          status: ContentStatus.OBSOLETE,
          startDate: currentEvent.startDate,
          endDate: currentEvent.endDate || currentEvent.startDate, // OK?
          endRepetitionDate: currentEvent.endRepetitionDate,
          description: currentEvent.description,
          repetitionDefinition: currentEvent.repetitionDefinition,
          // reminderDate: currentEvent.reminderDate, // cant get reminderDate out from GQL, Kristoffer?
          responsible: currentEvent.responsible
            ? currentEvent.responsible.id
            : undefined,
        };
        myApolloClient
          .mutate({
            mutation: UPDATE_EVENT,
            variables: { input: input2 },
            refetchQueries: [
              this.props.ringId !== '0'
                ? {
                    query: GET_INSTANCE_AND_TASK_QUERY, // NOTE: changed refetch query to only get task not entire annual cycle
                    variables: {
                      year: this.props.year,
                      taskId: this.props.taskId,
                    },
                  }
                : {
                    query: GET_REVERSE_INSTANCE_AND_TASK_QUERY,
                    variables: {
                      instanceId: this.props.eventInstanceId,
                    },
                  },
            ],
            awaitRefetchQueries: true,
          })
          .then(n => {
            this.setState(
              {
                isDeleteMessageOpen: false,
                isDeleting: false,
              },
              () => {
                safeInvokeDeprecated(this.props.onClose, true); // this will reload data
              }
            );
          })
          .catch(err => {
            this.setState({
              isDeleting: false,
            });

            // TODO: add errormessage if delete fails
          });
      }
    );
  };

  render() {
    const { intl } = this.props;
    console.log(this.state.eventInstanceId);
    return this.props.ringId !== '0' ? (
      <GetInstanceAndTaskQueryComp
        query={GET_INSTANCE_AND_TASK_QUERY}
        fetchPolicy={'cache-and-network'}
        variables={{
          year: this.props.year,
          taskId: this.props.taskId,
        }}
        children={(
          result: QueryResult<
            GetInstanceAndTaskQuery,
            GetInstanceAndTaskVariables
          >
        ) => {
          if (result.loading) {
            return '';
          }
          let myMode =
            this.state.eventInstanceId !== undefined
              ? FormMode.INSTANCE
              : FormMode.EVENT;

          let currentEventInstance:
            | FieldsOnEventExtendedInstances
            | undefined = undefined;
          let currentEvent:
            | FieldsOnEventExtendedFragment
            | undefined
            | null = undefined;
          const currentTask = result.data && result.data.task;
          const mySelectedCalendars =
            (currentTask && currentTask.calendars) || [];
          let me = this.props.me;

          // TODO: We could optimize and add memoize-one here! (but not 1. pri)

          if (currentTask) {
            for (let i = 0; i < currentTask.events.length; i++) {
              const myEvent = currentTask.events[i];
              if (myMode === FormMode.INSTANCE) {
                for (let y = 0; y < myEvent.instances.length; y++) {
                  const myInstance = myEvent.instances[y];
                  if (myInstance.id === this.state.eventInstanceId) {
                    currentEventInstance = myInstance;
                    currentEvent = myEvent;
                    break;
                  }
                }
              } else if (myMode === FormMode.EVENT) {
                if (myEvent.id === this.state.eventId) {
                  currentEvent = myEvent;
                  break;
                }
              }
            }
          }
          let isRepetitionOn = false;
          if (currentEvent) {
            const temp = currentEvent.repetitionDefinition;
            if (!(temp === null)) {
              isRepetitionOn = true;
            }
          }
          if (!isRepetitionOn) {
            myMode = FormMode.EVENT;
          }

          return (
            <CustomDialogBox>
              <BlackSidebar
                instances={(currentEvent && currentEvent!.instances) || []}
                selectedInstanceId={
                  (currentEventInstance && currentEventInstance!.id) ||
                  undefined
                }
                isEventSelected={myMode === FormMode.EVENT}
                onClickInstance={(item: FieldsOnEventInstances) => {
                  // TODO: check if we have changes (dirty) should have formik here...
                  this.setState({
                    eventInstanceId: item.id,
                    eventId: undefined,
                  });
                }}
                onClickSeries={() => {
                  // TODO: check for unsaved changes
                  this.setState({
                    eventInstanceId: undefined,
                    eventId: (currentEvent && currentEvent.id) || '',
                  });
                }}
                isRepetitionOn={isRepetitionOn || this.state.isAddingRepetition}
              />

              {result.loading && (
                <div style={{ display: 'flex' }}>
                  <Left
                    style={{
                      alignSelf: 'center',
                      justifySelf: 'center',
                      minWidth: '20em',
                      minHeight: '20em',
                    }}
                  >
                    <CircleSpinner />
                  </Left>
                  <Right
                    style={{
                      alignSelf: 'center',
                      justifySelf: 'center',
                      minWidth: '20em',
                      minHeight: '20em',
                    }}
                  >
                    <CircleSpinner />
                  </Right>
                </div>
              )}

              {result.error && (
                <div>
                  An error occured :-( <br />
                  {result.error.message}
                </div>
              )}

              {isRepetitionOn &&
                myMode === FormMode.INSTANCE &&
                currentEventInstance && (
                  <InstanceForm
                    isAssignedInstance={false}
                    eventFiles={currentEvent.files}
                    taskId={this.props.taskId}
                    userHash={this.props.userHash}
                    taskType={
                      (currentTask && currentTask.type) || TaskTypeEnum.DEFAULT
                    }
                    me={me}
                    taskSelectedCalendar={mySelectedCalendars[0]} // super hack...
                    year={this.props.year}
                    isRepetitionOn={
                      isRepetitionOn || this.state.isAddingRepetition
                    }
                    eventTitle={
                      (currentEvent && currentEvent!.title) || 'Event'
                    }
                    taskTitle={(currentTask && currentTask.title) || 'Task'}
                    intl={intl}
                    item={currentEventInstance}
                    mySelectedCalendar={mySelectedCalendars[0]}
                    selectedCalendars={mySelectedCalendars || []}
                    onClose={() => safeInvokeDeprecated(this.props.onClose)}
                    onDelete={() => {
                      this.setState({
                        isDeleteMessageOpen: true,
                      });
                    }}
                    onEventBreadcrumbClicked={() => {
                      // isRepetitionOn = true; // TODO: probably have to refactor and save something in state

                      this.setState({
                        eventInstanceId: undefined,
                        eventId: (currentEvent && currentEvent.id) || '',
                        isAddingRepetition: true,
                      });
                    }}
                  />
                )}

              {myMode === FormMode.EVENT && currentEvent && (
                <EventForm
                  isAssignedTask={false}
                  onDelete={() => {
                    if (confirm(intl.formatMessage(messages.deleteConfirm))) {
                      this.handleDeleteSeries(currentEvent);
                    }
                  }}
                  isCalendarPage={this.props.isCalendarPage}
                  activeYearwheels={this.props.activeYearwheels}
                  userHash={this.props.userHash}
                  onClose={(force: boolean) =>
                    safeInvokeDeprecated(this.props.onClose, force)
                  }
                  intl={intl}
                  me={me}
                  taskId={this.props.taskId}
                  year={this.props.year}
                  item={currentEvent}
                  taskTitle={(currentTask && currentTask.title) || 'Task'}
                  taskType={
                    (currentTask && currentTask.type) || TaskTypeEnum.DEFAULT
                  }
                  taskSelectedCalendar={mySelectedCalendars[0]} // super hack...
                  isRepetitionOn={isRepetitionOn}
                />
              )}
              <Overlay isOpen={this.state.isDeleteMessageOpen}>
                <div ref={this.deleteMssgRef}>
                  <StatusMessage
                    theme={ThemeColor.ERROR_RED}
                    title={intl.formatMessage(messages.deleteInstanceTitle)}
                    footer={
                      <div style={{ color: 'unset' }}>
                        <Button
                          leftIcon={MunikumIcons.Delete}
                          text={intl.formatMessage(
                            messages.deleteInstanceButtonTxt
                          )}
                          theme={ColorTheme.Black}
                          onClick={() =>
                            this.handleDeleteInstance(currentEventInstance)
                          }
                          disabled={this.state.isDeleting}
                        />

                        {isRepetitionOn && (
                          <Button
                            leftIcon={MunikumIcons.Delete}
                            text={intl.formatMessage(
                              messages.deleteInstanceButtonTxt1
                            )}
                            theme={ColorTheme.Black}
                            onClick={() =>
                              this.handleDeleteSeries(currentEvent)
                            }
                            style={{
                              marginLeft: '.25em',
                            }}
                            disabled={this.state.isDeleting}
                          />
                        )}

                        <Button
                          leftIcon={MunikumIcons.Cancel}
                          text={intl.formatMessage(commonMessages.cancel)}
                          theme={ColorTheme.White}
                          onClick={() => {
                            this.setState({
                              isDeleteMessageOpen: false,
                            });
                          }}
                          style={{
                            marginLeft: '.25em',
                          }}
                          disabled={this.state.isDeleting}
                        />

                        {this.state.isDeleting && (
                          <div style={{ marginLeft: '.25em' }}>Sletter...</div>
                        )}
                      </div>
                    }
                    text={intl.formatMessage(messages.deleteInstanceBody)}
                    // buttonText={intl.formatMessage(
                    //   messages.deleteInstanceButtonTxt
                    // )}
                    // onClickButton={() => {
                    //
                    // }}
                    // buttonText2={intl.formatMessage(
                    //   messages.deleteInstanceButtonTxt1
                    // )}
                    // onClickButton2={() => {
                    //
                    // }}
                  />
                </div>
              </Overlay>
            </CustomDialogBox>
          );
        }}
      />
    ) : (
      <div>
        <GetReverseInstanceAndTaskQueryComp
          query={GET_REVERSE_INSTANCE_AND_TASK_QUERY}
          fetchPolicy={'cache-and-network'}
          variables={{
            instanceId: this.props.eventInstanceId,
          }}
          children={(
            result2: QueryResult<
              GetReverseInstanceAndTaskQuery,
              GetReverseInstanceAndTaskVariables
            >
          ) => {
            if (result2.loading) {
              return '';
            }
            const myMode =
              this.state.eventInstanceId !== undefined
                ? FormMode.INSTANCE
                : FormMode.EVENT;

            let currentEventInstance:
              | FieldsOnEventExtendedInstances
              | undefined = undefined;
            let currentEvent: any | undefined | null = undefined;

            const eventInstance = result2.data && result2.data.eventInstance;
            const currentTask =
              result2.data &&
              result2.data.eventInstance &&
              result2.data.eventInstance.event &&
              result2.data.eventInstance.event.task;
            const mySelectedCalendars =
              (currentTask && currentTask.calendars) || [];
            let me = this.props.me;

            // TODO: We could optimize and add memoize-one here! (but not 1. pri)

            if (currentTask) {
              currentEventInstance = eventInstance;
              currentEvent = eventInstance.event;
            }
            let isRepetitionOn = false;
            if (currentEvent) {
              const temp = currentEvent.repetitionDefinition;
              if (!(temp === null)) {
                isRepetitionOn = true;
              }
            }

            return (
              <CustomDialogBox>
                <BlackSidebar
                  instances={(currentEvent && currentEvent!.instances) || []}
                  selectedInstanceId={
                    (currentEventInstance && currentEventInstance!.id) ||
                    undefined
                  }
                  isEventSelected={myMode === FormMode.EVENT}
                  onClickInstance={(item: FieldsOnEventInstances) => {
                    // TODO: check if we have changes (dirty) should have formik here...
                    this.setState({
                      eventInstanceId: item.id,
                      eventId: undefined,
                    });
                  }}
                  onClickSeries={() => {
                    // TODO: check for unsaved changes
                    this.setState({
                      eventInstanceId: undefined,
                      eventId: (currentEvent && currentEvent.id) || '',
                    });
                  }}
                  isRepetitionOn={
                    isRepetitionOn || this.state.isAddingRepetition
                  }
                />

                {result2.loading && (
                  <div style={{ display: 'flex' }}>
                    <Left
                      style={{
                        alignSelf: 'center',
                        justifySelf: 'center',
                        minWidth: '20em',
                        minHeight: '20em',
                      }}
                    >
                      <CircleSpinner />
                    </Left>
                    <Right
                      style={{
                        alignSelf: 'center',
                        justifySelf: 'center',
                        minWidth: '20em',
                        minHeight: '20em',
                      }}
                    >
                      <CircleSpinner />
                    </Right>
                  </div>
                )}

                {result2.error && (
                  <div>
                    An error occured :-( <br />
                    {result2.error.message}
                  </div>
                )}

                {myMode === FormMode.INSTANCE && currentEventInstance && (
                  <InstanceForm
                    isAssignedInstance={this.props.ringId === '0'}
                    eventFiles={currentEvent.files}
                    taskId={this.props.taskId}
                    userHash={this.props.userHash}
                    taskType={
                      (currentTask && currentTask.type) || TaskTypeEnum.DEFAULT
                    }
                    me={me}
                    taskSelectedCalendar={mySelectedCalendars[0]} // super hack...
                    year={this.props.year}
                    isRepetitionOn={
                      isRepetitionOn || this.state.isAddingRepetition
                    }
                    eventTitle={
                      (currentEvent && currentEvent!.title) || 'Event'
                    }
                    taskTitle={(currentTask && currentTask.title) || 'Task'}
                    intl={intl}
                    item={currentEventInstance}
                    mySelectedCalendar={mySelectedCalendars[0]}
                    selectedCalendars={mySelectedCalendars || []}
                    onClose={() => safeInvokeDeprecated(this.props.onClose)}
                    onDelete={() => {
                      this.setState({
                        isDeleteMessageOpen: true,
                      });
                    }}
                    onEventBreadcrumbClicked={() => {
                      // isRepetitionOn = true; // TODO: probably have to refactor and save something in state

                      this.setState({
                        eventInstanceId: undefined,
                        eventId: (currentEvent && currentEvent.id) || '',
                        isAddingRepetition: true,
                      });
                    }}
                  />
                )}

                {myMode === FormMode.EVENT && currentEvent && (
                  <EventForm
                    isAssignedTask={this.props.ringId === '0'}
                    isCalendarPage={this.props.isCalendarPage}
                    activeYearwheels={this.props.activeYearwheels}
                    userHash={this.props.userHash}
                    onClose={(force: boolean) =>
                      safeInvokeDeprecated(this.props.onClose, force)
                    }
                    intl={intl}
                    me={me}
                    taskId={this.props.taskId}
                    year={this.props.year}
                    item={currentEvent}
                    taskTitle={(currentTask && currentTask.title) || 'Task'}
                    taskType={
                      (currentTask && currentTask.type) || TaskTypeEnum.DEFAULT
                    }
                    taskSelectedCalendar={mySelectedCalendars[0]} // super hack...
                    isRepetitionOn={isRepetitionOn}
                  />
                )}
                <Overlay isOpen={this.state.isDeleteMessageOpen}>
                  <div ref={this.deleteMssgRef}>
                    <StatusMessage
                      theme={ThemeColor.ERROR_RED}
                      title={intl.formatMessage(messages.deleteInstanceTitle)}
                      footer={
                        <div style={{ color: 'unset' }}>
                          <Button
                            leftIcon={MunikumIcons.Delete}
                            text={intl.formatMessage(
                              messages.deleteInstanceButtonTxt
                            )}
                            theme={ColorTheme.Black}
                            onClick={() =>
                              this.handleDeleteInstance(currentEventInstance)
                            }
                            disabled={this.state.isDeleting}
                          />

                          {isRepetitionOn && (
                            <Button
                              leftIcon={MunikumIcons.Delete}
                              text={intl.formatMessage(
                                messages.deleteInstanceButtonTxt1
                              )}
                              theme={ColorTheme.Black}
                              onClick={() =>
                                this.handleDeleteSeries(currentEvent)
                              }
                              style={{
                                marginLeft: '.25em',
                              }}
                              disabled={this.state.isDeleting}
                            />
                          )}

                          <Button
                            leftIcon={MunikumIcons.Cancel}
                            text={intl.formatMessage(commonMessages.cancel)}
                            theme={ColorTheme.White}
                            onClick={() => {
                              this.setState({
                                isDeleteMessageOpen: false,
                              });
                            }}
                            style={{
                              marginLeft: '.25em',
                            }}
                            disabled={this.state.isDeleting}
                          />

                          {this.state.isDeleting && (
                            <div style={{ marginLeft: '.25em' }}>
                              Sletter...
                            </div>
                          )}
                        </div>
                      }
                      text={intl.formatMessage(messages.deleteInstanceBody)}
                      // buttonText={intl.formatMessage(
                      //   messages.deleteInstanceButtonTxt
                      // )}
                      // onClickButton={() => {
                      //
                      // }}
                      // buttonText2={intl.formatMessage(
                      //   messages.deleteInstanceButtonTxt1
                      // )}
                      // onClickButton2={() => {
                      //
                      // }}
                    />
                  </div>
                </Overlay>
              </CustomDialogBox>
            );
          }}
        />
      </div>
    );
  }
}
export const EditEventInstanceForm = injectIntl(EditEventInstanceFormComp);
