import { getApiRequest, parsedResponse } from './api';
import { IRefreshToken } from './models/refreshToken';

export interface IRevokeRefreshTokenForm {
  token: string;
}

export async function getRefreshTokens(): Promise<
  ReadonlyArray<IRefreshToken>
> {
  const response = await getApiRequest('/person/my-tokens', 'GET');
  return await parsedResponse<ReadonlyArray<IRefreshToken>>(response);
}

export async function revokeToken(form: IRevokeRefreshTokenForm) {
  const response = await getApiRequest('/person/revoke-token', 'POST', {
    ...form,
  });
  return await response;
}
