export default `



**Årshjul.no**

Vi leverer ei heilskapeleg løysing, som både har digitale årshjul og legg til rette for digital samhandling på tvers og støttar opp under prosessar knytt til etablering av metodar for samhandling.

Personvern og brukervilkår – OBS! link personvern og brukarvilkår

OBS! Årshjul er eit ope program, og sensitiv informasjon knytt til enkeltpersonar og hendingar skal dermed ikkje bli delt eller lagra her.

Meir informasjon om personvern finn du her: https://munikum.no/privacy-policy/

For meir informasjon om brukarvilkår kan du gå hit: https://munikum.no/userTerms/.

 
`;