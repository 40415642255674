import { combineReducers } from 'redux';
import {
  IMainLayoutState,
  reducer as mainLayoutReducer,
} from './mainLayout/mainLayout';
import { RootState } from '../rootReducer';

export interface IUiState {
  mainLayout: IMainLayoutState;
  title: string;
}

export const reducer = combineReducers<IUiState>({
  mainLayout: mainLayoutReducer,
});

export const titleSelector = (state: RootState) => {
  return state.ui.title;
};
