import moment from 'moment';
import { Option } from 'react-select';
import {
  IActionValue,
  IActionValueParameterValue,
  IHttpLink,
} from './models/actionValue';
import { getApiRequest, parsedResponse } from './api';
import {
  ContentStatus,
  CreateActionValueParameterValueInput,
} from '../models/types';
import { IFileItem } from '../components/basic/UserFile/FileUploaderPicker';
import { IContentMeta } from './models/contentMeta';

export interface IActionValueCopyForm {
  actionValue?: number;
  title?: string;
  year?: number;
  isUpdating?: boolean;
}

export interface IActionValueCreateOrUpdateForm {
  /**
   * actionvalue content id!
   */
  id?: string;
  title?: string;
  description?: string;
  body?: string;
  year?: string;
  status?: ContentStatus | '';
  category?: string;

  /**
   * source ID
   */
  source?: string;
  sourceItemHack?: Option<string>;

  /**
   * source URL
   */
  sourceUrl?: string;

  sectors?: ReadonlyArray<string>;
  counties?: ReadonlyArray<string>;
  validOrganizationTypes?: ReadonlyArray<string>;
  type?: number;
  kostraFunctions?: ReadonlyArray<string>;
  responsible?: string;

  applicationDeadlines?: ReadonlyArray<any>;
  reportingDeadlines?: ReadonlyArray<any>;
  deadlines?: ReadonlyArray<any>;

  links?: ReadonlyArray<IHttpLink>;

  // fill this with data !
  parameters?: ReadonlyArray<IActionValueParameterValue>;

  realms?: ReadonlyArray<string>; // this will be updated on meta
  newfiles?: IFileItem[];
}

/**
 * admin required
 * @param {String} actionValueContentId
 * @returns {Promise<IActionValue>}
 */
export async function getActionValue(
  actionValueContentId: String
): Promise<IActionValue> {
  const response = await getApiRequest(
    '/action-value/get/' + actionValueContentId,
    'GET'
  );
  return await parsedResponse<IActionValue>(response);
}

/**
 * Returns all actionValues from API.
 * @returns {Promise<ReadonlyArray<IActionValue>>}
 */
export async function getActionValueList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<IActionValue>> {
  if (params && params.lastUpdated !== undefined) {
    const temp1 = moment(params.lastUpdated);

    const resp = await getApiRequest('/action-value/list', 'POST', {
      lastUpdated: temp1.utc(),
    });
    // console.log('resp', resp);
    const presp = await parsedResponse<ReadonlyArray<IActionValue>>(resp);
    return presp;
  }
  const response = await getApiRequest('/action-value/list', 'GET');
  return await parsedResponse<ReadonlyArray<IActionValue>>(response);
}

export async function getActionValueMeta(
  actionValueMetaId: string
): Promise<IContentMeta> {
  const response = await getApiRequest(
    '/action-value/meta/' + actionValueMetaId,
    'GET'
  );
  return await parsedResponse<IContentMeta>(response);
}

export async function getActionValueMetaList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<IContentMeta>> {
  if (params && params.lastUpdated) {
    return await parsedResponse<ReadonlyArray<IContentMeta>>(
      await getApiRequest('/action-value/list-meta', 'POST', {
        lastUpdated: moment(params.lastUpdated).utc(),
      })
    );
  }
  const response = await getApiRequest('/action-value/list-meta', 'GET');
  return await parsedResponse<ReadonlyArray<IContentMeta>>(response);
}

export async function updateActionValue(
  createOrUpdateForm: IActionValueCreateOrUpdateForm
): Promise<IActionValue> {
  // console.log('before update', createOrUpdateForm);

  const response = await getApiRequest('/action-value/update', 'POST', {
    ...createOrUpdateForm,
  });
  return await parsedResponse<IActionValue>(response);
}

export async function createActionValue(
  createOrUpdateForm: IActionValueCreateOrUpdateForm
): Promise<IActionValue> {
  // console.log('before create', createOrUpdateForm);

  const response = await getApiRequest('/action-value/save', 'POST', {
    ...createOrUpdateForm,
  });
  // console.log('is response a actionValue?');
  return await parsedResponse<IActionValue>(response);
}

export async function copyActionValue(form: IActionValueCopyForm): Promise<{}> {
  const response = await getApiRequest('/action-value/copy', 'POST', {
    ...form,
  });
  return await parsedResponse<{}>(response);
}

/**
 * get parameters given actionvalueid
 * @param {{actionValueId?: number}} params
 * @returns {Promise<IActionValueParameters>}
 */
export async function getActionValueParametersById(params?: {
  actionValueId: string;
}): Promise<IActionValueParameters> {
  const response = await getApiRequest(
    '/action-value/get-parameters',
    'POST',
    params
  );
  return await parsedResponse<IActionValueParameters>(response);
}

/**
 * get list of available parameters for action value (generic, no id needed)
 * returned data is grouped by type effort and gain.
 * We can use this to render ui dynamically?
 * @returns {Promise<{}>}
 */
export async function getActionValueParameters(): Promise<
  IActionValueParameters
> {
  const response = await getApiRequest('/action-value/list-parameters', 'POST');
  return await parsedResponse<IActionValueParameters>(response);
}

// hard-coded strings for now..
export const economyString = 'Økonomi';
export const subjectString = 'Fag, tenestetilbod, kvalitet';
export const organizationString = 'Organisasjon';

/**
 * Get parameterValue from parameterGroup
 * @param {IActionValueParameterGroup} paramGroup
 * @param {number} value
 * @returns {IActionValueParameterValue}
 */
export function getParameterValueFromParameterGroup(
  paramGroup: IActionValueParameterGroup,
  value: number
): CreateActionValueParameterValueInput {
  const paramDbId = paramGroup.parameters[0].id;
  const dbValueToDbId: { [byId: number]: string } = {};

  paramGroup.parameters[0].options.map(
    (option: IActionValueParameterOption, index: number) => {
      dbValueToDbId[option.value] = option.id;
    }
  );

  const parameterValue: CreateActionValueParameterValueInput = {
    parameter: parseInt(paramDbId, 10),
    option: dbValueToDbId[value], // ???
  };

  return parameterValue;
}

/**
 * get a array of default actionvalueparametervalues ... ! pass groups form getActionValueParameters() . .
 * Use this when initializing a empty form with default parameters...
 * @returns {Promise<ReadonlyArray<IActionValueParameterValue>>}
 */
export async function getDefaultActionValueParameterValuesFromGroups(
  parameters: IActionValueParameters
): Promise<CreateActionValueParameterValueInput[]> {
  // TODO: Thomsa fix bug.
  return [...parameters.effort, ...parameters.gain].map(paramGroup =>
    getParameterValueFromParameterGroup(paramGroup, 3)
  );
}

/**
 * Groups of action value parameters from api
 */
export interface IActionValueParameters {
  effort: ReadonlyArray<IActionValueParameterGroup>;
  gain: ReadonlyArray<IActionValueParameterGroup>;
}

/**
 * a group can be 'effort'. group.options contains all available options for given parameter
 */
export interface IActionValueParameterGroup {
  name: string;
  parameters: {
    id: string;
    name: string;
    comment: string;
    option: number;
    options: ReadonlyArray<IActionValueParameterOption>;
  };
}

export interface IActionValueParameterOption {
  id: string;
  active: boolean;
  name: string;
  parameter: number;
  value: number;
}
