import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { Colors, safeInvokeDeprecated } from '../../common';
import { MunikumKeys } from '../../common/keys';
import { Overlay } from '../Overlay/Overlay';
import { IoIosClose } from 'react-icons/io';
import { FaTimes } from 'react-icons/fa';

export interface IDialogProps {
  isOpen: boolean;

  title?: string;

  showCloseButton?: boolean;

  // visible but disabled
  isCloseDisabled?: boolean;

  canEscapeKeyClose?: boolean;

  canOutsideClickClose?: boolean;

  /**
   * set to true if you want to display a warning "are you sure you want to close"m before closing..
   * TODO: implement..
   */
  showCloseWarning?: boolean;

  // if you want to render footer in a custom way
  renderFooter?: JSX.Element; // custom render stuff inside footer

  // TODO: include common code /built in buttons for cancel/ok? :

  onClose?: () => void;
}
// 24px = 1.5em

const DialogContainer = styled.div`
  min-width: 31.25em; // 500px;
  min-height: 15.625em; // 250px;

  max-width: 90vh;
  max-height: 90vh;

  position: static !important;
  transform: none;
  display: flex;
  flex-direction: column;
  margin: 1.875em;
  border-radius: 3px;
  background-color: ${Colors.WHITEBACKGROUND};

  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  user-select: text;

  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  animation: opacity 2s ease;
`;

const Header = styled.div`
  padding-left: 1.5em;
  padding-top: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  //background-color: pink;
`;

const Body = styled.div`
  flex: 1 1 auto;
  margin: 1.5em;
  color: #565656;
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  line-height: 1.5em;

  overflow-y: auto;
`;

const Footer = styled.div`
  flex: 0 0 auto;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-bottom: 1.5em;
  //background-color: aquamarine;
  text-align: right;
`;

const Title = styled.h2`
  color: ${Colors.BLACK};
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  line-height: 1.5em;
  font-size: 1.375em;
  margin: -0.25em 0 0 0;
  //background-color: orange;
`;

/**
 * Dialog with built in stuff like close button, escape click closes dialog, etc.
 */
export class Dialog extends PureComponent<IDialogProps, {}> {
  public static defaultProps: IDialogProps = {
    isOpen: false,
    showCloseButton: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
    title: 'Default title',
  };

  close = () => {
    safeInvokeDeprecated(this.props.onClose);
  };

  protected handleKeyUp = (e: any) => {
    if (
      this.props.isOpen &&
      this.props.canEscapeKeyClose &&
      e.which === MunikumKeys.ESCAPE
    ) {
      this.close();
    }
  };

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  public render() {
    const {
      isOpen,
      title,
      showCloseButton,
      canEscapeKeyClose,
      canOutsideClickClose,
      renderFooter,
    } = this.props;

    // console.log('render Dialog. isOpen', isOpen);

    const showHeader = (title && title.length > 0) || showCloseButton;

    return (
      <Overlay
        {...this.props}
        isOpen={isOpen}
        backgroundStyle={'shadow'}
        onClickShadow={() => {
          if (canOutsideClickClose) {
            this.close();
          }
        }}
      >
        <DialogContainer
          isOpen={isOpen}
          onClick={(e: any) => {
            // console.log('click dialog container');
            e.stopPropagation();
          }}
        >
          {showHeader && (
            <Header>
              <div>
                <Title>{title}</Title>
              </div>
              <div>
                {showCloseButton && (
                  <FaTimes
                    fill={Colors.BLACK}
                    width={'2.5em'}
                    height={'2.5em'}
                    onClick={this.close}
                    cursor={'pointer'}
                  />
                )}
              </div>
            </Header>
          )}
          <Body>{this.props.children}</Body>
          {renderFooter && <Footer>{renderFooter}</Footer>}
        </DialogContainer>
      </Overlay>
    );
  }
}
