import * as React from 'react';
import {
  injectIntl,
  FormattedMessage,
  defineMessages,
  InjectedIntlProps,
} from 'react-intl';
import styled from 'styled-components';
import { Colors, removeNonHTMLProps } from '../../common';
import { ExternalLink } from '../../basic/Structural/links';

const messages = defineMessages({
  label: {
    id: 'SupportInfo.label',
    defaultMessage: 'For support:',
  },
  emailAddress: {
    id: 'SupportInfo.emailAddress',
    defaultMessage: 'post@munikum.no',
  },
  phone: {
    id: 'SupportInfo.phone',
    defaultMessage: '919 04 691',
  },
});

const InfoBox = styled.div`
  width: 11.125em;
  height: 7.75em;
  border: 1px solid #ff5c39;
  border-radius: 4px;

  color: ${Colors.WHITE};
  font-family: 'Lato', sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SupportLink = styled.a`
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  color: ${Colors.WHITE};

  border-bottom: 1px solid transparent;
  transition: border-bottom 0.2s ease-out;
  &:hover {
    border-bottom: 1px solid ${Colors.RED};
  }
`;

const InfoLabel = styled.div`
  font-weight: bold;
`;

export interface ISupportInfoProps {
  // TODO: we should put email and phone here and store in db somewhere?
}

class SupportInfoComp extends React.PureComponent<
  ISupportInfoProps & InjectedIntlProps & React.HTMLProps<HTMLDivElement>,
  {}
> {
  render() {
    const { intl, ...rest } = this.props;

    return (
      <InfoBox {...removeNonHTMLProps(rest)}>
        <InfoLabel>
          <FormattedMessage
            id={'SupportInfo.label'}
            defaultMessage={'For support:'}
          />
        </InfoLabel>
        <div>
          <ExternalLink
            href={'mailto:' + intl.formatMessage(messages.emailAddress)}
            target={'_blank'}
            style={{ color: Colors.WHITE }}
          >
            {intl.formatMessage(messages.emailAddress)}
          </ExternalLink>
        </div>
        <div>
          <SupportLink
            href={'tel:' + intl.formatMessage(messages.phone)}
            target={'_blank'}
            style={{ color: Colors.WHITE }}
          >
            {intl.formatMessage(messages.phone)}
          </SupportLink>
        </div>
      </InfoBox>
    );
  }
}

export const SupportInfo = injectIntl(SupportInfoComp);
