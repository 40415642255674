import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { Colors } from '../../common';

/**
 * Use this if you need a internal link (a href) that navigates to an URI.
 * set property "to={'url/'}" to navigate.
 * This is a styled version of react-router-dom's Link component
 */
export const InternalLink = styled(Link)`
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  color: ${props => props.color || Colors.RED};
  border-bottom: solid 1px transparent;
  cursor: pointer;
  transition: border-bottom 0.2s ease-out;
  &:hover {
    border-bottom: solid 1px ${Colors.RED};
  }
`;

export const LinkWrapper = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

/**
 * Use this if you need a plain link (a href)
 */
export const LinkComponent = styled.a`
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  color: ${props => props.color || Colors.RED};
  border-bottom: solid 1px transparent;
  transition: border-bottom 0.2s ease-out;
  cursor: pointer;
  &:hover {
    border-bottom: solid 1px ${props => props.color || Colors.RED};
  }
`;

/**
 * looks lika a link but isnt =)
 */
export const FakeLink = styled.span`
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  color: ${props => props.color || Colors.RED};
  border-bottom: solid 1px transparent;
  transition: border-bottom 0.2s ease-out;
  cursor: pointer;
  &:hover {
    border-bottom: solid 1px ${props => props.color || Colors.RED};
  }
`;

/**
 * Use this for external links. target=_blank is added automatically
 * @param props
 * @constructor
 */
export const ExternalLink: React.SFC<
  { href: string } & React.AnchorHTMLAttributes<{}>
> = props => {
  const { href, target, ...rest } = props;
  let myTarget = target || '_blank'; // default for external links
  if (href.indexOf('mailto:') > 0) {
    return <LinkComponent href={href} {...rest} />; // no target for email:
  }
  return <LinkComponent href={href} target={myTarget} {...rest} />;
};
