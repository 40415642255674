import { createSelector } from 'reselect';
import * as _ from 'lodash';
import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { call, put, take } from 'redux-saga/effects';
import { myHistory } from '../../index';
import { ITopic, ITopicItem } from '../../services/models/topic';
import {
  getFilesActionCreator,
  getTopicMetasActionCreator,
  getTopicsActionCreator,
} from '../entities/entities';
import {
  createTopic,
  ITopicCreateOrUpdateForm,
  updateTopic,
} from '../../services/topicService';
import { IContentMeta } from '../../services/models/contentMeta';
import { createUploadFileChannel } from '../file/file';
import { IApiError, wrapApiError } from '../../services/api';
import { IFileItem } from '../../components/basic/UserFile/FileUploaderPicker';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { RootState } from '../rootReducer';

const actionCreator = actionCreatorFactory('TOPIC');

// action
// export const editTopicActionCreator = actionCreator.async<
//  { id: string; isFromEditPage?: boolean },
//   ITopicCreateOrUpdateForm,
//   IApiError
// >('UI_EDIT');

// export const cancelEditTopicActionCreator = actionCreator<{}>('UI_CANCEL_EDIT');

export const updateTopicActionCreator = actionCreator.async<
  ITopicCreateOrUpdateForm,
  {},
  IApiError
>('UPDATE');

export const saveTopicActionCreator = actionCreator.async<
  ITopicCreateOrUpdateForm,
  {},
  IApiError
>('SAVE');

interface ITopicUploadFiles {
  files: ReadonlyArray<IFileItem>;
  topicMetaId: string;
}

export const uploadFilesToTopicAtionCreator = actionCreator.async<
  ITopicUploadFiles,
  {},
  IApiError
>('UPLOADFILES');

export interface ITopicState {
  updateError?: string;
  updateIsSaving: boolean;
  createError?: string;
  createIsSaving: boolean;
}

const initialState: ITopicState = {
  updateError: undefined,
  updateIsSaving: false,
  createError: undefined,
  createIsSaving: false,
};

export const reducer = (
  state: ITopicState = initialState,
  action: Action
): ITopicState => {
  if (isType(action, updateTopicActionCreator.started)) {
    return {
      updateIsSaving: true,
      updateError: undefined,
      ...state,
    };
  }
  if (isType(action, updateTopicActionCreator.success)) {
    return {
      updateIsSaving: false,
      updateError: undefined,
      ...state,
    };
  }
  if (isType(action, updateTopicActionCreator.failed)) {
    console.log('error updating topic: ', action.payload.error);
    return {
      updateIsSaving: false,
      updateError: action.payload.error.message,
      ...state,
    };
  }
  if (isType(action, saveTopicActionCreator.started)) {
    return {
      createIsSaving: true,
      createError: undefined,
      ...state,
    };
  }
  if (isType(action, saveTopicActionCreator.success)) {
    return {
      createIsSaving: false,
      createError: undefined,
      ...state,
    };
  }
  if (isType(action, saveTopicActionCreator.failed)) {
    console.log('error updating topic: ', action.payload.error);
    return {
      createIsSaving: false,
      createError: action.payload.error.message,
      ...state,
    };
  }

  return state;
};

const topicMetaListTransformer = (topicMetaDict: {
  [byId: string]: Readonly<IContentMeta>;
}) => _.values(topicMetaDict);

const topicListTransformer = (topicDict: {
  [byId: string]: Readonly<ITopic>;
}) => {
  return _.values(topicDict);
};

export const topicMetaListSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IContentMeta> },
  ReadonlyArray<IContentMeta>
>(
  state => state.entities.topicMetas.byId,
  topicMetaListTransformer
);

export const topicMetaDictSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IContentMeta> },
  { [byId: string]: Readonly<IContentMeta> }
>(
  state => state.entities.topicMetas.byId,
  res => res
);

export const topicDictSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<ITopic> },
  { [byId: string]: Readonly<ITopic> }
>(
  state => state.entities.topics.byId,
  res => {
    return res;
  }
);

const mergeTopicMetaAndCurrentRevision = (
  metaList: ReadonlyArray<IContentMeta>,
  valueDict: { [byId: string]: Readonly<ITopic> }
) => {
  if (metaList === undefined || metaList.length === 0) {
    return [];
  }

  if (valueDict === undefined || Object.keys(valueDict).length === 0) {
    return [];
  }

  const list = metaList.map(metaItem => {
    const newItem: ITopicItem = {
      meta: metaItem,
      content: valueDict[metaItem.currentRevision],
    };
    return newItem;
  });

  return list;
};

export const topicItemListSelector = createSelector(
  topicMetaListSelector,
  topicDictSelector,
  mergeTopicMetaAndCurrentRevision
);

const mergeTopicMetaAndCurrentRevisionToDict = (
  metaDict: { [byId: string]: Readonly<IContentMeta> },
  valueDict: { [byId: string]: Readonly<ITopic> }
) => {
  if (metaDict === undefined || Object.keys(metaDict).length === 0) {
    return [];
  }
  if (valueDict === undefined || Object.keys(valueDict).length === 0) {
    return [];
  }

  const itemDict: { [byId: string]: ITopicItem } = {};

  for (let key in metaDict) {
    itemDict[key] = {
      meta: metaDict[key],
      content: valueDict[metaDict[key].currentRevision],
    };
  }

  return itemDict;
};

export const topicItemDictSelector = createSelector(
  topicMetaDictSelector,
  topicDictSelector,
  mergeTopicMetaAndCurrentRevisionToDict
);

export function* updateTopicSaga() {
  while (true) {
    const action = yield take(updateTopicActionCreator.started.type);

    try {
      if (action.payload.id === undefined) {
        throw Error('Cannot update topic without id!');
      }

      const tempMetaLimit = new Date();

      console.log('update topic...');

      const result = yield call(updateTopic, action.payload);

      // yield put(getTopicActionCreator.started({ id: result.id }));
      yield put(getTopicsActionCreator.started({ lastUpdated: tempMetaLimit }));

      yield put(
        getTopicMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      yield put(
        updateTopicActionCreator.success({
          params: action.payload,
          result: result,
        })
      );

      // yield put(push('/topic'));
    } catch (e) {
      yield put(
        updateTopicActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export function* uploadFilesToTopicSaga() {
  while (true) {
    const action = yield take(uploadFilesToTopicAtionCreator.started.type);

    try {
      const tempMetaLimit = new Date();

      console.log('upload files to topic...', action.payload);

      if (action.payload.files && action.payload.files.length > 0) {
        for (let sFile of action.payload.files) {
          const channel = yield call(createUploadFileChannel, {
            file: sFile.file,
            metaid: action.payload.topicMetaId,
          });

          while (true) {
            const { success, err } = yield take(channel);
            if (err) {
              throw Error('File upload failed');
            }
            if (success) {
              console.log('finishedUploading files');
              break;
            }
          }

          yield put(
            getFilesActionCreator.started({ lastUpdated: tempMetaLimit })
          );
        }
      }
      console.log('getting updates saga');
      // yield put(getTopicActionCreator.started({ id: result.id }));
      yield put(getTopicsActionCreator.started({ lastUpdated: tempMetaLimit }));

      yield put(
        getTopicMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      yield put(
        uploadFilesToTopicAtionCreator.success({
          params: action.payload,
          result: {},
        })
      );

      // yield put(push('/topic'));
    } catch (e) {
      yield put(
        updateTopicActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export function* saveTopicSaga() {
  while (true) {
    const action = yield take(saveTopicActionCreator.started.type);
    try {
      const tempMetaLimit = new Date();

      const result = yield call(createTopic, action.payload);

      // yield put(getTopicActionCreator.started({ id: result.id }));
      yield put(getTopicsActionCreator.started({ lastUpdated: tempMetaLimit }));

      yield put(
        getTopicMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      yield put(
        saveTopicActionCreator.success({
          params: action.payload,
          result: result,
        })
      );

      myHistory.push('/topic');
    } catch (e) {
      yield put(
        saveTopicActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export const topicFollowedListSelector = createSelector<
  RootState,
  ReadonlyArray<IContentMeta>,
  { [byId: string]: Readonly<ITopic> },
  ReadonlyArray<ITopicItem>
>(
  topicMetaListSelector,
  topicDictSelector,
  (
    metaList: ReadonlyArray<IContentMeta>,
    valueDict: { [byId: string]: Readonly<ITopic> }
  ): ReadonlyArray<ITopicItem> => {
    // if metaList or valueDict is empty we return undefined
    if (metaList === undefined || metaList.length === 0) {
      return [];
    }

    if (valueDict === undefined || Object.keys(valueDict).length === 0) {
      return [];
    }

    const allItems = metaList.map(metaItem => ({
      meta: metaItem,
      content: valueDict[metaItem.currentRevision],
    }));

    return _.filter<ITopicItem>(allItems, (item: ITopicItem) => {
      if (item.content === undefined || item.meta === undefined) {
        return false;
      }

      return item.meta.watched;
    });
  }
);
