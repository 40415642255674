// imports required by React v16 in IE11
// https://reactjs.org/blog/2017/09/26/react-v16.0.html
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/promise';
import 'core-js/es6/symbol';
import 'babel-polyfill';
import 'raf/polyfill';

// more polyfills:
// we're transpiling to ES5, so we shouldnt have to polyfill es6 / es7
// but 3rd party libs might use non-transpiled functions?
import 'core-js';
import 'core-js/library';
import 'core-js/library/es5';
import 'core-js/library/es6';
import 'core-js/library/es6/symbol';
import 'core-js/library/es7';
import { unregisterServiceWorker } from './registerServiceWorker';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import moment from 'moment';
import * as ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './fonts/typeface-lato/index.css';
import 'react-app-polyfill/ie11';
import * as React from 'react';
import App from './App';
import { History, createHashHistory, createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import {
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Store,
} from 'redux';

import { persistStore } from 'redux-persist';
import { initSagas } from './modules/rootSaga';
import { ApolloCacheGate } from './graphql/ApolloCacheGate';
import { Loader } from './components/basic/Loader/Loader';
import { rootReducer, RootState } from './modules/rootReducer';
import printLogo from './logo';
import 'blueimp-canvas-to-blob/js/canvas-to-blob';

try {
  printLogo();
} catch (err) {}

moment.locale('nn'); // nn, nb, en, we change this globally here!

const isElectron =
  navigator &&
  navigator.userAgent &&
  navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

if (
  !isElectron &&
  process.env.NODE_ENV &&
  process.env.NODE_ENV === 'production'
) {
  try {
    unregisterServiceWorker();
  } catch (err) {
    console.log('Error while unregistering service worker', err);
  }
}

export const myHistory: History = isElectron
  ? createHashHistory()
  : createBrowserHistory({ basename: '/app/' });

const sagaMiddleware = createSagaMiddleware();
const middlewareList: Array<Middleware> = [sagaMiddleware];

// enable this to debug IE11 redux stuff
// middlewareList.push(loggerMiddleware);

// tslint:disable-next-line
const twin = window as any;
const isDevtoolsReady: boolean =
  typeof twin === 'object' &&
  twin.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== undefined;
if (!isDevtoolsReady) {
  console.log(
    'Do you like time travel? install Redux DevTools Chrome extension here:' +
      ' https://github.com/zalmoxisus/redux-devtools-extension'
  );
}

const composeEnhancers = isDevtoolsReady
  ? twin.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
  : compose;

const enhancers = composeEnhancers(
  applyMiddleware(...middlewareList)
  // autoRehydrate({ log: true })
);

export const myStore: Store<RootState> = createStore<RootState>(
  rootReducer,
  enhancers
);

// TODO: we can remove this.. now we persist apollo cache in stead!
// this starts rehydration:
const persistor = persistStore(
  myStore,
  undefined, // options is obsolete in v5
  () => {
    // console.log('rehydration complete');
    // if we need rehydrated state:
    // const rehydratedState = myStore.getState();
  }
);

initSagas(sagaMiddleware);

ReactDOM.render(
  <Provider store={myStore}>
    <ApolloCacheGate
      loading={<Loader name={'bird'} />}
      // onBeforeLift={() => console.log('lifting apollo cachegate...')}
      // onAfterLift={() => console.log('apollo cachegate LIFTED')}
    >
      <PersistGate
        persistor={persistor}
        loading={<Loader name={'bird'} />}
        // onBeforeLift={() => console.log('lifting persist gate...')}
      >
        <Router history={myHistory}>
          <App />
        </Router>
      </PersistGate>
    </ApolloCacheGate>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
