import styled from 'styled-components';
import { Colors } from '../common';
import { MunikumIcons } from '../common/icons';
import * as React from 'react';

const InstanceWarning = styled.div`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 15em;
  border-radius: 4px;
  border: 1px solid rgba(208, 211, 212, 0.7);
`;

const InstanceWarningText = styled.div`
  font-family: Lato, sans-serif;
  font-size: 0.875em;
  color: ${Colors.BLACK};

  flex: 1 1 auto;
  padding-right: 0.55em;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
`;

export const Warning: React.SFC<{ msg?: string }> = props => (
  <InstanceWarning>
    <MunikumIcons.InfoOutline
      style={{
        width: '1.2em',
        height: '1.2em',
        flex: '0 0 auto',
        padding: '.5em',
        color: Colors.BLACK,
      }}
    />
    <InstanceWarningText>
      {props.msg}
      {props.children}
    </InstanceWarningText>
  </InstanceWarning>
);
