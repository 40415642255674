import * as React from 'react';
import styled, { keyframes } from 'styled-components';
const bird = require('./bird.svg') as string;

const bounce = keyframes`
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(0.85, 1.1) translateY(-1em);
  }
`;

const shadow2 = keyframes`
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(0.7, 1);
    background-color: rgba(0,0,0,.01);
  }
`;

const Container = styled.div`
  height: 6em;
  width: 6em;
  left: 50%;
  top: 50%;
  margin: -3em 0 0 -3em;
  position: absolute;
  text-align: center;
`;
const BounceImage = styled.img`
  animation: ${bounce} 1s cubic-bezier(0.51, 0.23, 0.02, 0.96) infinite;
  z-index: 10;
`;
const Shadow = styled.div`
  background-color: rgba(0, 0, 0, 0.04);
  filter: blur(0.1em);
  border-radius: 100%;
  margin-left: 1em;
  width: 4.7em;
  height: 0.8em;
  animation: ${shadow2} 1s infinite;
`;

export class Bird extends React.PureComponent<{}, {}> {
  render() {
    return (
      <Container>
        <BounceImage src={bird} alt={'bird'} />
        <Shadow />
      </Container>
    );
  }
}
