import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';

const profileImage = require('./user.svg') as string;

export interface IUserSummaryProps {
  name: string;
  role: string;

  organizationImage?: string;
  organizationName?: string;
  userImage?: string | null;
  style?: CSSProperties;
}

const NameAndRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SmallTextDiv = styled.div`
  font-family: 'Lato', sans-serif;
  color: #333333;
  font-size: 0.75em;
  margin-top: 0.2em;
  line-height: 1em;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const NameDiv = styled(SmallTextDiv)`
  font-weight: bold;
`;

const ImageAndTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ContentDiv = styled.div`
  width: 12em;
`;

/**
 * UserSummary
 * Profile image, name and role
 */
export class UserSummary extends React.PureComponent<IUserSummaryProps, {}> {
  render() {
    const {
      name,
      role,
      organizationImage,
      organizationName,
      style,
    } = this.props;
    let url = profileImage;
    if (this.props.userImage) {
      url = 'https://www.munikum.no/' + this.props.userImage;
    }
    // console.log(this.props.userImage, ' dasd');
    let temp = organizationName && organizationName.toLowerCase();
    let nameCapitalized = temp && temp.charAt(0).toUpperCase() + temp.slice(1);

    return (
      <ContentDiv style={style}>
        <ImageAndTextContainer>
          <div>
            <img
              style={{ borderRadius: '50%' }}
              src={url}
              width="35"
              height="35"
            />
          </div>
          <NameAndRoleContainer
            style={{
              marginLeft: '.75em',
            }}
          >
            <NameDiv>{name}</NameDiv>
            <SmallTextDiv>{role}</SmallTextDiv>
          </NameAndRoleContainer>
        </ImageAndTextContainer>

        <ImageAndTextContainer
          style={{
            marginTop: '.5em',
          }}
        >
          <div style={{ marginLeft: '.4em' }}>
            <StaticImage
              filename={organizationImage}
              type={IStaticImageType.ORGANIZATION}
              width={20}
              height={20}
            />
          </div>
          <SmallTextDiv
            style={{
              marginLeft: '1.6em',
              fontWeight: 'bold',
              letterSpacing: '.5px',
            }}
          >
            {nameCapitalized}
          </SmallTextDiv>
        </ImageAndTextContainer>
      </ContentDiv>
    );
  }
}
