import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../common';

export interface IMenuDividerProps {}

const Dividiv = styled.div`
  display: block;
  margin-bottom: 5px;
  margin-top: 5px;
  border-top: 1px solid ${Colors.GREYBORDER};

  box-sizing: border-box;

  &:hover {
    cursor: default;
  }
`;

export class MenuDivider extends React.PureComponent<IMenuDividerProps, {}> {
  render() {
    return <Dividiv />;
  }
}
