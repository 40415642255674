import * as React from 'react';

/**
 * simple Pie just for illustrations
 *
 * @param props
 * @constructor
 */
export const Pie: React.SFC<{ color?: string }> = props => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    style={{ width: '100%', maxWidth: '100%', height: '100%' }}
  >
    <path
      d="M272 169l-140.8 82.3-36.4 21.1C74 239.4 61.9 228.8 29 208l23-40 81-140c46 26 115 92 139 141z"
      fill={props.color || 'transparent'}
      stroke={'#333'}
      strokeOpacity={props.color ? 0 : 0.8}
      strokeWidth={8}
      strokeDasharray={8}
      strokeDashoffset={'50%'}
      strokeLinejoin={'round'}
    />
  </svg>
);
