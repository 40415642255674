import * as React from 'react';

export const getBuildNumber = () => {
  const version = process.env.REACT_APP_VERSION;
  const nodeEnv = process.env.NODE_ENV;
  let retVal = 'undefined';
  if (nodeEnv === 'development') {
    retVal = 'development';
  } else {
    if (version) {
      if (version.indexOf('beta') > 0) {
        retVal = 'develop-';
      } else if (version.indexOf('arshjul') > 0) {
        retVal = 'arshjul-';
      } else {
        retVal = 'master-';
      }
      retVal += version.substring(version.lastIndexOf('.') + 1, version.length);
    }
  }
  return retVal;
};

export const getBuildDate = () => {
  const date = process.env.REACT_APP_BUILD_DATE;
  if (date) {
    return date;
  }
  return 'NA';
};

export class VersionNumber extends React.PureComponent {
  render() {
    return (
      <div>
        <span>Build: {getBuildNumber()}</span>
        <br />
        <span>Date: {getBuildDate()}</span>
      </div>
    );
  }
}
