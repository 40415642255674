import { actionCreatorFactory, isType } from 'typescript-fsa';
import { Action } from 'redux';
import { RootState } from '../../rootReducer';

const actionCreator = actionCreatorFactory('UI/MAIN');

export const updateServiceWorker = actionCreator<{}>('UPDATE_SERVICEWORKER');

export interface IServiceWorkerState {
  serviceWorkerUpdated: boolean;
}

export const reducer = (
  state: IServiceWorkerState = { serviceWorkerUpdated: false },
  action: Action
) => {
  if (isType(action, updateServiceWorker)) {
    return {
      ...state,
      serviceWorkerUpdated: true,
    };
  }
  return state;
};

export const serviceWorkerSelector = (state: RootState) => {
  return state.serviceWorker;
};
