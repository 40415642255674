import moment from 'moment';
import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import _ from 'lodash';
import DayPicker, { DayModifiers } from 'react-day-picker';
import styled from 'styled-components';
import './datepicker.css';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { commonLabels } from '../../language/commonLabels';
import { ITabProps, Tabs, TabTheme } from '../Tabs/Tabs';
import { MunikumIcons } from '../../common/icons';
import { FieldGroup } from '../FieldGroup/FieldGroup';
import {
  Colors,
  ColorTheme,
  ILabelProps,
  safeInvoke,
  safeInvokeDeprecated,
} from '../../common';
import { FormLabel } from '../FormLabel/FormLabel';
import { localeUtils } from './localeUtils';
import { commonMessages } from '../../language/commonMessages';
import { MunikumKeys } from '../../common/keys';
import { Button, TextField } from '..';
import { Popover } from '../Popover/Popover';
import { Slider } from '../Slider/Slider';
import { IconButton, IconButtonHoverStyle } from '../IconButton/IconButton';

export interface IDateTimePickerProps {
  /**
   * initially selected date
   */
  defaultSelectedValue?: Date;

  /**
   * optionally set this to put component in controlled mode
   */
  value?: Date;

  /**
   * true = enable date picker
   */
  pickDate?: boolean;

  /**
   * true = enable TIme picker
   */
  pickTime?: boolean;

  /**
   * TRUE = hide save button, save on clicking date!
   */
  closeOnSelect?: boolean;

  /**
   * form name
   */
  name: string;

  /**
   * is datetimepicker initially open?
   */
  isDefaultOpen?: boolean;

  style?: CSSProperties;

  fieldGroupStyle?: CSSProperties;

  outerStyle?: CSSProperties;

  disabled?: boolean;

  noEndDateMode?: boolean;
  onClickTextField?: () => void;
}

export interface IDateTimePickerState {
  /**
   * selected value / date
   */
  value: Date;

  // active month in picker
  month: Date;

  /**
   * form id
   */
  id: string;

  isOpen: boolean;
}

export interface IDateTimePickerDispatch {
  /**
   * fired when dragging slider in time selector or clicking in calendar
   * @param {Date} selectedDate
   */
  onChange?: (selectedDate: Date) => void;

  /**
   * Fired when user clicks the Save button
   * @param {Date} selectedDate
   */
  onSave?: (selectedDate: Date) => void;

  /**
   * fired when closing dialog with escape or click outside
   * @param {Date} selectedDate
   */
  onCancel?: (selectedDate: Date) => void;
}

const ClockContainer = styled.div`
  //background-color: pinkpink;
  //
  display: flex;
  justify-content: center;
  align-items: center;

  height: 8em;
  font-size: 1em;
`;

const ClockSeperator = styled.div`
  color: ${Colors.RED};
  width: 1em;
  height: 2em;

  position: relative;
  top: -0.085em;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Lato, sans-serif;
  font-size: 2.375em;
  line-height: 2.875em;
  text-align: center;
  font-weight: bold;
`;

const ClockDigits = styled.div`
  background-color: ${Colors.RED};
  width: 4em;
  height: 4em;
  border-radius: 4px;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClockDigitsInner = styled.div`
  font-family: Lato, sans-serif;
  font-size: 2.375em;
  font-weight: bold;
  line-height: 2.875em;
  text-align: center;
`;

const monthNames = moment.months();
const monthNamesShort = moment.monthsShort();

/**
 * DateTimePicker
 * a component for selecting DATE and TIME
 *
 * TODO:
 * - mode to select only time, only date or both?
 * - smaller Tab component following styleguide?
 * - fix click outside handler
 *
 */
class DateTimePickerComp extends React.PureComponent<
  IDateTimePickerProps &
    ILabelProps &
    IDateTimePickerDispatch &
    InjectedIntlProps,
  IDateTimePickerState
> {
  private readonly myRef: RefObject<HTMLDivElement>;

  // use this to tell if clicks are coming from inside the target wrapper div
  private readonly wrapperRef: RefObject<HTMLDivElement>;

  // use this to whitelist content inside popover
  private readonly whiteRef: RefObject<HTMLDivElement>;

  public static defaultProps: IDateTimePickerProps &
    ILabelProps &
    IDateTimePickerDispatch = {
    pickDate: true,
    pickTime: true,
    name: '',
  };

  constructor(
    props: IDateTimePickerProps &
      ILabelProps &
      IDateTimePickerDispatch &
      InjectedIntlProps
  ) {
    super(props);

    const now = moment()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();

    this.state = {
      id: 'dp1',
      value: moment(props.value || props.defaultSelectedValue || now).toDate(),
      isOpen: props.isDefaultOpen || false,
      month: moment(props.value || props.defaultSelectedValue || now).toDate(),
    };

    this.myRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.whiteRef = React.createRef();
  }

  UNSAFE_componentWillMount() {
    const id = _.uniqueId('dtp-');
    this.setState({
      id: id,
    });
  }

  // tslint:disable-next-line
  handleKeyUp = (e: any) => {
    if (this.state === undefined) {
      return;
    }

    const { isOpen } = this.state;
    if (isOpen && e.keyCode === MunikumKeys.ESCAPE) {
      // console.log('closing you clicked escape');
      this.setState(
        {
          isOpen: false,
        },
        () => {
          safeInvokeDeprecated(this.props.onCancel, this.state.value);
        }
      );
    }
  };

  handleClickOutside = (e: any) => {
    if (this.wrapperRef.current && this.whiteRef.current) {
      const isInsideTargetWrapper = this.wrapperRef.current.contains(e.target);
      const isInsideWhiteWrapper = this.whiteRef.current.contains(e.target);

      if (
        this.state.isOpen &&
        !isInsideTargetWrapper &&
        !isInsideWhiteWrapper
      ) {
        this.setState({
          isOpen: false,
        });
      } else if (
        this.state.isOpen &&
        !isInsideTargetWrapper &&
        isInsideWhiteWrapper
      ) {
        // console.log('you clicked INSIDE children wrapper..');
        // TODO: how to close popover AFTER MenuItem has dispatched it's event???
        // I think we have to analyze MenuItem children in Menu's render() . . .
        // super hacky fix:
        // cannot hide yet! because children has to dispatch event first...
        // setTimeout(() => {
        //   this.setState({
        //     isOpen: false
        //   });
        // }, 5000);
      }
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: IDateTimePickerProps & ILabelProps
  ) {
    if (
      nextProps.value !== undefined &&
      nextProps.value !== null &&
      nextProps.value !== this.state.value
    ) {
      this.setState(
        {
          value: moment(nextProps.value).toDate(),
        },
        () => {
          safeInvokeDeprecated(this.props.onChange, this.state.value);
        }
      );
    }
  }

  formattedDate = () => {
    const m = moment(this.state.value);

    // using formatting from design guide:
    // return '1. Mars 17, kl. 08:49';
    const test2 = m.format('D.MMMM YY,') + ' kl. ' + m.format('HH:mm');

    // we could also use this?:
    const test1 = m.format('DD.MM.YY HH:mm');

    // only date:
    const onlyDate = m.format('D. MMMM YYYY');

    // only time:
    const onlyTime = m.format('HH:mm');

    if (this.props.pickTime && this.props.pickDate) {
      return test2;
    } else if (this.props.pickTime && !this.props.pickDate) {
      return onlyTime;
    } else {
      return onlyDate;
    }
  };

  handleClickTextField = () => {
    if (this.props.disabled) {
      return;
    }
    // console.log('toggle datetimepicker');
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleClickSave = () => {
    // console.log('save');
    this.setState(
      {
        isOpen: false,
      },
      () => {
        safeInvokeDeprecated(this.props.onChange, this.state.value);
        safeInvokeDeprecated(this.props.onSave, this.state.value);
      }
    );
  };

  handleDayClick = (
    day: Date,
    modifiers: DayModifiers,
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    // console.log('onClickDay', day, modifiers, e);
    const curr = moment(this.state.value);
    const clicked = moment(day);
    const fresh = moment(curr)
      .year(clicked.year())
      .month(clicked.month())
      .date(clicked.date());

    // console.log(
    //   'test',
    //   curr,
    //   clicked,
    //   fresh,
    //   curr.toDate(),
    //   clicked.toDate(),
    //   fresh.toDate()
    // );

    this.setState(
      {
        value: fresh.toDate(),
        isOpen: this.props.closeOnSelect ? false : this.state.isOpen,
      },
      () => {
        safeInvokeDeprecated(this.props.onChange, this.state.value);
        if (this.props.closeOnSelect) {
          safeInvokeDeprecated(this.props.onSave, this.state.value);
        }
      }
    );
  };

  handleChangeHours = (hour: number) => {
    const temp = moment(this.state.value)
      .hour(hour)
      .toDate();
    // console.log('temp:' + temp);
    this.setState(
      {
        value: temp,
      },
      () => {
        safeInvokeDeprecated(this.props.onChange, this.state.value);
      }
    );
  };

  handleChangeMinutes = (minute: number) => {
    const temp = moment(this.state.value)
      .minute(minute)
      .toDate();
    // console.log('temp:' + temp);
    this.setState(
      {
        value: temp,
      },
      () => {
        safeInvokeDeprecated(this.props.onChange, this.state.value);
      }
    );
  };

  handleNextMonth = () => {
    const temp = moment(this.state.month);
    const x = temp.add(1, 'month');
    this.setState({
      month: x.toDate(),
    });
  };

  handlePrevMonth = () => {
    const temp = moment(this.state.month);
    const x = temp.subtract(1, 'month');
    this.setState({
      month: x.toDate(),
    });
  };
  handleNextYear = () => {
    const temp = moment(this.state.month);
    const x = temp.add(1, 'year');
    this.setState({
      month: x.toDate(),
    });
  };

  handlePrevYear = () => {
    const temp = moment(this.state.month);
    const x = temp.subtract(1, 'year');
    this.setState({
      month: x.toDate(),
    });
  };

  render() {
    const {
      label,
      disabled,
      name,
      style,
      fieldGroupStyle,
      intl,
      outerStyle,
    } = this.props;
    const { value, id, isOpen, month } = this.state;

    const temp = moment(value);
    const hh = ('0' + temp.hour().toString()).slice(-2);
    const mm = ('0' + temp.minute().toString()).slice(-2);

    const nextMonth = () => {
      this.handleNextMonth();
    };

    const prevMonth = () => {
      this.handlePrevMonth();
    };

    const nextYear = () => {
      this.handleNextYear();
    };

    const prevYear = () => {
      this.handlePrevYear();
    };

    // const tt = temp.isValid();
    // console.log('valid: ' + tt, value);
    // var d = new Date();
    // var n = d.getTime();
    // console.log(n);
    const tab1: ITabProps = {
      id: 1,
      icon: MunikumIcons.Date,
      title: intl.formatMessage(commonLabels.date),
      content: props => (
        <DayPicker
          canChangeMonth={false}
          month={month}
          firstDayOfWeek={1}
          localeUtils={localeUtils}
          locale={intl.locale}
          onDayClick={this.handleDayClick}
          selectedDays={value}
          initialMonth={value}
          captionElement={capProps => {
            const temp = capProps.date;
            // const months = capProps.localeUtils.getMonths();
            // console.log('captionElement', capProps);
            return (
              <form className={'DayPicker-Caption'}>
                {/*{capProps.}*/}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ fontSize: '.55em' }}>
                      <IconButton
                        icon={MunikumIcons.ArrowLeft}
                        hoverStyle={IconButtonHoverStyle.SQUARE}
                        onClick={() => prevMonth()}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: '.2em',
                        marginRight: '.2em',
                        width: '5em',
                        textAlign: 'center',
                      }}
                    >
                      {monthNames[capProps.date.getMonth()]}
                    </div>
                    <div style={{ fontSize: '.55em' }}>
                      <IconButton
                        icon={MunikumIcons.ArrowRight}
                        hoverStyle={IconButtonHoverStyle.SQUARE}
                        onClick={() => nextMonth()}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ fontSize: '.55em' }}>
                      <IconButton
                        icon={MunikumIcons.ArrowLeft}
                        hoverStyle={IconButtonHoverStyle.SQUARE}
                        onClick={() => prevYear()}
                      />
                    </div>
                    <div>{capProps.date.getFullYear()}</div>
                    <div style={{ fontSize: '.55em' }}>
                      <IconButton
                        icon={MunikumIcons.ArrowRight}
                        hoverStyle={IconButtonHoverStyle.SQUARE}
                        onClick={() => nextYear()}
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      ),
    };
    const tab2: ITabProps = {
      id: 2,
      icon: MunikumIcons.Time,
      title: intl.formatMessage(commonLabels.time),
      content: props => (
        <div>
          <ClockContainer>
            <ClockDigits>
              <ClockDigitsInner>{hh}</ClockDigitsInner>
            </ClockDigits>
            <ClockSeperator>:</ClockSeperator>
            <ClockDigits>
              <ClockDigitsInner>{mm}</ClockDigitsInner>
            </ClockDigits>
          </ClockContainer>
          <Slider
            min={0}
            max={23}
            label={intl.formatMessage(commonLabels.hours)}
            onChange={this.handleChangeHours}
            value={temp.hour()}
            // initialValue={temp.hour()}
            // value={temp.hour()}
          />
          <Slider
            min={0}
            max={59}
            label={intl.formatMessage(commonLabels.minutes)}
            onChange={this.handleChangeMinutes}
            value={temp.minute()}
            // initialValue={temp.minute()}
          />
        </div>
      ),
    };

    // const quickPAddingHack = Object.assign({}, (!this.props.pickDate && !this.props.pickTime && this.props.closeOnSelect) ? { padding: '0px' } : {});
    return (
      <div ref={this.myRef} style={outerStyle}>
        <FieldGroup style={fieldGroupStyle}>
          {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
          <Popover
            isOpen={isOpen}
            style={{
              padding: '1em', // TODO: remove some padding when no tabs or buttons!
            }}
            renderTarget={ref => {
              return (
                <div
                  style={{ cursor: 'pointer' }}
                  ref={this.wrapperRef}
                  onClick={this.handleClickTextField}
                >
                  <TextField
                    autoComplete={'off'}
                    disabled={disabled}
                    name={name}
                    placeholder={
                      this.props.noEndDateMode
                        ? intl.formatMessage(commonMessages.infinity)
                        : null
                    }
                    innerRef={ref}
                    value={this.props.noEndDateMode ? '' : this.formattedDate()}
                    onChange={() => {
                      // console.log('nothing to see here..');
                    }}
                    // used in repetitionPicker
                    onClick={() => {
                      if (this.props.noEndDateMode) {
                        if (
                          new Date().getFullYear() >=
                          this.state.month.getFullYear()
                        ) {
                          nextYear();
                        }
                        safeInvoke(this.props.onClickTextField);
                      }
                    }}
                    inputStyle={{
                      cursor: 'pointer',
                      color: 'transparent',
                      // opacity: this.props.noEndDateMode ? .8: 1,
                      textShadow: '0 0 0 ' + Colors.BLACK,
                    }}
                    leftIcon={MunikumIcons.Date}
                    rightIcon={MunikumIcons.Dropdown}
                    fieldGroupStyle={{
                      padding: 0,
                    }}
                    style={style}
                  />
                </div>
              );
            }}
          >
            <div ref={this.whiteRef}>
              {this.props.pickDate && this.props.pickTime && (
                <Tabs
                  tabs={[tab1, tab2]}
                  defaultSelectedTabId={1}
                  theme={TabTheme.RED}
                />
              )}
              {this.props.pickDate && !this.props.pickTime && (
                <div>{tab1.content({})}</div>
              )}
              {!this.props.pickDate && this.props.pickTime && (
                <div>{tab2.content({})}</div>
              )}

              {!this.props.closeOnSelect && (
                <Button
                  text={intl.formatMessage(commonMessages.save)}
                  theme={ColorTheme.Red}
                  // leftIcon={MunikumIcons.Save}
                  style={{
                    width: '100%',
                    marginTop: '1em',
                  }}
                  onClick={this.handleClickSave}
                />
              )}
            </div>
          </Popover>
        </FieldGroup>
      </div>
    );
  }
}

export const DateTimePicker = injectIntl(DateTimePickerComp);
