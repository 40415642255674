import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import {
  GetUserDataQuery,
  GetUserDataVariables,
  GetUserWizardDataQuery,
  GetUserWizardDataVariables,
  UpdateMeMutation,
  UpdateMeSettingsMutation,
  UpdateMeSettingsVariables,
  UpdateMeVariables,
} from '../../models/types';

// all fields required for editing profile, kostra, etc.
export const fieldsOnMe = gql`
  fragment fieldsOnMe on Me {
    id
    name
    email
    description
    jobTitle
    department
    language
    picture
    phoneNumber
    organization {
      id
      name
      organizationImage
    }
    settings {
      showAssignedTasks
      sendInstantNotificationMail
      sendDailyNotificationMail
      sendWeeklyNotificationMail
      acceptedUserTerms
      allowContactViaPhone
      allowContactViaEmail
      shouldReceiveNewsletter
    }
    kostraFunctions {
      id
      kid
      title
    }
  }
`;

// get ME data
export const GET_USER_DATA = gql`
  query GetUserData {
    me {
      ...fieldsOnMe
    }
  }
  ${fieldsOnMe}
`;

// TODO: change from sector to kostra here:

export const GET_USER_WIZARD_DATA = gql`
  query GetUserWizardData {
    me {
      ...fieldsOnMe
    }
    allKostraFunctionGroups {
      id
      title
      kostraFunctions {
        id
        kid
        title
      }
    }
  }
  ${fieldsOnMe}
`;

export const UPDATE_ME = gql`
  mutation UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      ...fieldsOnMe
    }
  }
  ${fieldsOnMe}
`;

export const UPDATE_ME_SETTINGS = gql`
  mutation UpdateMeSettings($input: UpdateMeSettingsInput!) {
    updateSettings(input: $input) {
      ...fieldsOnMe
    }
  }
  ${fieldsOnMe}
`;

export class GetUserWizardData extends Query<
  GetUserWizardDataQuery,
  GetUserWizardDataVariables
> {}

export class GetUserData extends Query<
  GetUserDataQuery,
  GetUserDataVariables
> {}

export class UpdateMe extends Mutation<UpdateMeMutation, UpdateMeVariables> {}

export class UpdateMeSettings extends Mutation<
  UpdateMeSettingsMutation,
  UpdateMeSettingsVariables
> {}

export function getInitials(name?: string) {
  if (name === undefined || name === '') {
    return 'NN';
  }
  const temp = name.split(' ');
  if (temp.length === 1 && name.length >= 2) {
    return name.substring(0, 2).toUpperCase();
  }
  return temp[0][0] + temp[temp.length - 1][0].toUpperCase();
}
