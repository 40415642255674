import * as React from 'react';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import Helmet from 'react-helmet';
import { TextNormal } from '../../components/basic/Structural/typography';
import {
  MarkdownPreview,
  RenderMode,
} from '../../components/basic/MarkdownPreview/MarkdownPreview';
import { InternalLink } from '../../components/basic/Structural/links';
import { Page, PageTitle, PageTop } from '../../components/layout/Page/Page';
import { FlexGrid } from '../../components/layout/FlexGrid/FlexGrid';

/**
 * NOTE: uglyhack: pretend its js, lets webpack inport content. default contains raw string
 * Create react app is working on a better solution:
 * https://github.com/facebook/create-react-app/issues/3722
 */
const aboutEN = require('./about-en.md.js').default;
const aboutNN = require('./about-nn.md.js').default;
const aboutNB = require('./about-nb.md.js').default;

const messages = defineMessages({
  title: {
    id: 'MainLayout.aboutLink',
    defaultMessage: 'About Munikum',
  },
});

class AboutPageComponent extends React.Component<
  InjectedAuthRouterProps & InjectedIntlProps,
  {}
> {
  constructor(props: InjectedAuthRouterProps & InjectedIntlProps) {
    super(props);
  }

  render() {
    const { intl } = this.props;
    let about = 'default about';
    if (intl.locale.toLowerCase() === 'en') {
      about = aboutEN;
    } else if (intl.locale.toLowerCase() === 'nn') {
      about = aboutNN;
    } else if (intl.locale.toLowerCase() === 'nb') {
      about = aboutNB;
    } else {
      about = 'Terms of usage not found for language ' + intl.locale;
    }

    return (
      <Page
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Helmet>
          <title>{intl.formatMessage(messages.title)}</title>
        </Helmet>
        <div>
          <PageTop>
            <PageTitle>
              <FormattedMessage
                id={'MainLayout.aboutLink'}
                defaultMessage={'About Munikum'}
              />
            </PageTitle>
          </PageTop>
          <FlexGrid style={{ padding: '2em' }}>
            <TextNormal>
              <MarkdownPreview
                rawMarkdown={about}
                renderMode={RenderMode.Full}
              />
            </TextNormal>
            <TextNormal>
              <FormattedMessage
                values={{
                  temp: (
                    <InternalLink to={'/about/userTerms'}>
                      https://munikum.no/userTerms/
                    </InternalLink>
                  ),
                }}
                id={'MainLayout.userTerms'}
                defaultMessage={
                  'For more information about user terms you can go here: {temp} '
                }
              />
            </TextNormal>
          </FlexGrid>
        </div>
      </Page>
    );
  }
}

export const AboutPage = injectIntl(AboutPageComponent);
