import * as React from 'react';
import styled from 'styled-components';
import { CSSProperties } from 'react';
import { removeNonHTMLProps } from '../../common';

const FieldGroupDiv = styled.div`
  padding-bottom: 0.75em;
`;

/**
 * Just a container to add some padding around out form components
 * @param {React.HTMLProps<HTMLDivElement> & {children?: React.ReactNode}} props
 * @returns {any}
 * @constructor
 */
export const FieldGroup: React.SFC<React.HTMLProps<HTMLDivElement>> & {
  style?: CSSProperties;
} = props => {
  const { children, style, ...rest } = props;

  return (
    <FieldGroupDiv style={style} {...removeNonHTMLProps(rest)}>
      {children}
    </FieldGroupDiv>
  );
};
