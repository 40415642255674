import { IEntitiesState } from './entities';

type LastFetched = Date | undefined;
import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

/**
 * Selector factory func, returns a selector
 * create a selector which checks lastUpdated of given entityKeys
 * @param {keyof IEntitiesState} entityKey1
 * @param {keyof IEntitiesState} entityKey2
 * @param {keyof IEntitiesState} entityKey3
 * @returns {any}
 */
export const isDataReadySelectorFactory = (
  entityKey1: keyof IEntitiesState,
  entityKey2?: keyof IEntitiesState,
  entityKey3?: keyof IEntitiesState
) => {
  if (entityKey3 !== undefined && entityKey2 !== undefined) {
    return createSelector<
      RootState,
      LastFetched,
      LastFetched,
      LastFetched,
      boolean
    >(
      state => state.entities[entityKey1].lastFetched,
      state => state.entities[entityKey2].lastFetched,
      state => state.entities[entityKey3].lastFetched,
      (r1, r2, r3) => {
        if (!r1 || !r2 || !r3) {
          return false;
        }
        return true;
      }
    );
  } else if (entityKey2 !== undefined) {
    return createSelector<RootState, LastFetched, LastFetched, boolean>(
      state => state.entities[entityKey1].lastFetched,
      state => state.entities[entityKey1].lastFetched,
      (r1, r2) => {
        if (!r1 || !r2) {
          return false;
        }
        return true;
      }
    );
  }

  return createSelector<RootState, LastFetched, boolean>(
    state => state.entities[entityKey1].lastFetched,
    r1 => {
      if (!r1) {
        return false;
      }
      return true;
    }
  );
};
