import * as React from 'react';
import styled from 'styled-components';
import {
  ISearchForm,
  ISearchResponse,
  ISearchResponseV2,
} from '../../services/metaService';
import {
  Colors,
  hideOutline,
  safeInvoke,
  safeInvokeDeprecated,
  transitionBorder,
} from '../../components/common';
import { InternalLink } from '../../components/basic/Structural/links';
import { MunikumKeys } from '../../components/common/keys';
import { FaSearch } from 'react-icons/fa';

export interface ITopSearchProps {
  searchPlaceholder: string;

  /**
   * controlled mode
   */
  value?: string;
  /**
   * if click on searchbar omnisearch should pop up
   */
  handleSearchClick?: () => void;
  results?: ISearchResponse;
  resultsV2?: Array<ISearchResponseV2>;
}

export interface ITopSearchDispatch {
  onChange?: (form: ISearchForm) => void;
}

export interface ITopSearchState {
  value?: string;
}

const SearchContainer = styled.div`
  position: relative;
  width: 100%;

  cursor: pointer;

  margin-left: 1em;
`;

const SearchInput = styled.input`
  cursor: pointer;

  box-sizing: border-box;
  width: 100%;

  padding: 0.55em 0.75em 0.55em 3.1em; // padding makes room for icon

  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;

  border: 0px solid transparent;

  background-color: ${Colors.WHITE};

  color: ${Colors.BLACK};

  &::placeholder {
    color: ${Colors.GREYPLACEHOLDER};
  }
  &:focus:enabled {
  }
  &:disabled {
    color: ${Colors.GREYDISABLEDTEXT};
  }
  ${hideOutline()};
  ${transitionBorder()};
`;

const SearchIcon = styled(FaSearch)`
  width: 1.5em;
  height: 1.5em;

  position: absolute;
  top: 0.35em;
  left: 0.75em;
  z-index: 2;

  fill: ${Colors.BLACK};

  transition: fill 0.25s ease-in-out;

  ${SearchContainer}:hover & {
    fill: ${Colors.RED};
  }
`;

export class TopSearch extends React.PureComponent<
  ITopSearchProps & ITopSearchDispatch,
  ITopSearchState
> {
  constructor(props: ITopSearchProps) {
    super(props);
    this.state = {
      value: '',
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITopSearchProps) {
    if (nextProps.value !== undefined && nextProps.value !== this.state.value) {
      this.setState({
        ...this.state,
        value: nextProps.value,
      });
    }
  }

  // tslint:disable-next-line
  handleOnChange = (e: any) => {
    this.setState(
      {
        ...this.state,
        value: e.target.value,
      },
      () => {
        safeInvokeDeprecated(this.props.onChange, { query: this.state.value });
      }
    );
  };

  // tslint:disable-next-line
  handleKeyUp = (e: any) => {
    if (e.keyCode === MunikumKeys.ESCAPE) {
      this.setState(
        {
          ...this.state,
          value: '',
        },
        () => {
          safeInvokeDeprecated(this.props.onChange, this.state.value);
        }
      );
    }
  };

  render() {
    const { searchPlaceholder, results, resultsV2 } = this.props;
    const { value } = this.state;

    return (
      <SearchContainer
        onClick={() => {
          safeInvoke(this.props.handleSearchClick);
        }}
      >
        <SearchIcon />

        <SearchInput
          placeholder={searchPlaceholder}
          value={value}
          onChange={() => {}}
          // onChange={this.handleOnChange}
          // onKeyUp={this.handleKeyUp}
          disabled={false}
        />
        {results && (
          <div>
            <h2>Action Values: {results.actionValues.total}</h2>
            {results.actionValues.hits.map(item => {
              return (
                <div key={item.id} style={{ padding: '20px' }}>
                  <InternalLink to={'/action-value/' + item.uri}>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </InternalLink>
                </div>
              );
            })}
            <h2>Discussions: {results.discussions.total}</h2>
            {results.discussions &&
              results.discussions.hits &&
              results.discussions.hits.map(item => {
                return (
                  <div key={item.id} style={{ padding: '20px' }}>
                    <InternalLink to={'/forum/' + item.uri}>
                      <h3>{item.title}</h3>
                      <p>{item.body}</p>
                    </InternalLink>
                  </div>
                );
              })}
          </div>
        )}
        {resultsV2 &&
          resultsV2.map(item => {
            return (
              <div key={item.id} style={{ padding: '20px' }}>
                <InternalLink to={'/action-value/' + item.uri}>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </InternalLink>
              </div>
            );
          })}
      </SearchContainer>
    );
  }
}

// export const TopSearch = injectIntl(TopSearchComp);
