import * as React from 'react';
import { Colors } from './colors';
import { IMessageProps } from './props';

/**
 *  useful styling functions? aka. mixins in sass, only typescript? :)
 */

/*** truncate text */
export function truncate(width: string) {
  return `
        width: ${width};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `;
}

export function outline() {
  return `
    &:focus:enabled {
      outline: ${Colors.BLUE} solid 2px;
      outline-offset: 4px;
      //todo: shadows in styleguide.. add or not?
      //box-shadow: 0 -1px 1px rgba(255,255,255,.95), 0 1px 5px rgba(0,0,0,0.15);
      //box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    }
  `;
}

/***
 * warning: if you hide outline, make sure you visualize in a different way! (accessibility)
 * @returns {string}
 */
export function hideOutline() {
  return `
  &:focus:enabled {
    outline: none;
  }
  `;
}

export function transitionBorder() {
  return `
    transition: border-color 0.25s ease-in-out;
  `;
}

export function insetShadow() {
  const isInsetShadowEnabled = false;

  return isInsetShadowEnabled
    ? `
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
  `
    : ``;
}

// https://github.com/styled-components/styled-components/issues/630
// export function styledComponentWithProps<
//   T,
//   U extends HTMLElement = HTMLElement
// >(
//   styledFunction: StyledFunction<React.HTMLProps<U>>
// ): StyledFunction<T & React.HTMLProps<U>> {
//   return styledFunction;
// }

// new version for typescript 2.6 (still not perfect..):
// export function withProps<U>() {
//   return <P, T, O>(
//     fn: ThemedStyledFunction<P, T, O>
//   ): ThemedStyledFunction<P & U, T, O & U> =>
//     fn as ThemedStyledFunction<P & U, T, O & U>;
// }

export const getValidationColor = (msg: IMessageProps, standard: string) => {
  if (msg.error) {
    return Colors.RED;
  }
  if (msg.warning) {
    return Colors.YELLOW;
  }
  if (msg.success) {
    return Colors.BLUE;
  }
  if (msg.info) {
    return Colors.GREY;
  }
  return standard;
};

export const getValidationBorderColor = (msg: IMessageProps) =>
  getValidationColor(msg, Colors.GREYBORDER);
export const getValidationTextColor = (msg: IMessageProps) =>
  getValidationColor(msg, Colors.BLACK);
