import * as React from 'react';

/***
 * A users auth info
 */
export interface UserAuthInfo {
  username: string;
  email: string;
  hash: string;
  permissions: ReadonlyArray<string>;
  roles: ReadonlyArray<string>;
}

/***
 * info available in consumer
 */
export interface AuthInfo {
  isAuthenticated: boolean;
  isAdmin: boolean;

  /***
   * This only has a value if user is authenticated / logged in
   */
  userAuthInfo?: UserAuthInfo;
}

const dummyImplementation: AuthInfo = {
  isAuthenticated: false,
  isAdmin: false,
  userAuthInfo: undefined,
};

export const AuthContext = React.createContext<AuthInfo>(dummyImplementation);

interface AuthProviderProps {
  isAuthenticated: boolean;
  userAuthInfo?: UserAuthInfo;
}

const authIsAdminFunc = (auth: UserAuthInfo | undefined) => {
  if (!auth) {
    return false;
  }
  return auth.roles.indexOf('ROLE_ADMIN') > -1;
};

/***
 * Wrap you <AuthRoutes> with this AuthProvider
 * @param props
 * @constructor
 */
export const AuthProvider: React.FunctionComponent<
  AuthProviderProps
> = props => (
  <AuthContext.Provider
    value={{
      isAuthenticated: props.isAuthenticated,
      userAuthInfo: props.userAuthInfo,
      isAdmin: authIsAdminFunc(props.userAuthInfo),
    }}
  >
    {props.children}
  </AuthContext.Provider>
);

/***
 * A consumer if you need to get AuthInfo somewhere
 */
export const AuthConsumer = AuthContext.Consumer;
