import gql from 'graphql-tag';

export const LIKE = gql`
  mutation Like($id: ID!) {
    like(id: $id)
  }
`;

export const UN_LIKE = gql`
  mutation UnLike($id: ID!) {
    unLike(id: $id)
  }
`;
