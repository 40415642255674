import * as React from 'react';
import { EmptyLayout } from '../../layouts/EmptyLayout';
import { Loader } from '../../components/basic/Loader/Loader';

const LoadingPage: React.FunctionComponent<{}> = props => {
  return (
    <EmptyLayout>
      <Loader />
    </EmptyLayout>
  );
};

export default LoadingPage;
