import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { commonMessages } from '../language/commonMessages';

const NewxBoxCont = styled.div`
  margin: 0.5em;
  width: 244px;
  &:hover {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
`;
const ImgCont = styled.div`
  height: 188px;
  width: 244px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
`;
const BodyText = styled.div`
  height: 88px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-wrap: normal;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
  font-weight: normal;
  font-family: Lato, sans-serif;
  font-size: 14px;
  color: black;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
`;
const Link = styled.a`
  color: #333;
  text-underline: none;
  text-decoration: none;
`;

interface INewsBoxProps {
  imgSrc: any;
  url: string;
  title: string;
}
const NewsImg = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;

  background-size: cover;
  background-image: url(${(props: { img: string }) =>
    props.img ? props.img : ''});
`;
function NewsBox(props: INewsBoxProps) {
  return (
    <Link
      href={props.url}
      style={{
        border: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#fff',
      }}
    >
      <NewxBoxCont>
        <ImgCont>
          <NewsImg img={props.imgSrc} />
        </ImgCont>
        <BodyText>{props.title}</BodyText>
      </NewxBoxCont>
    </Link>
  );
}
const NewsSectionCont = styled.div`
  padding: 2em;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;
const TopDiv = styled.div`
  height: 27px;
  color: #333333;
  font-family: Lato;
  margin-bottom: 0.5em;
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
`;

interface INewsSectionCompProps {
  news: Array<any>;
  style?: CSSProperties;
}
function NewsSectionComp2(props: INewsSectionCompProps & InjectedIntlProps) {
  const sortNews = _.sortBy(props.news, 'date_published').reverse();
  const spliceNews = sortNews.slice(0, 4);
  const { intl } = props;
  // console.log(props.news);
  return (
    <NewsSectionCont style={props.style}>
      <TopDiv>{intl.formatMessage(commonMessages.news)}</TopDiv>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {spliceNews.map((c, i) => {
          return (
            <NewsBox key={i} url={c.url} imgSrc={c.image} title={c.title} />
          );
        })}
      </div>
      {/*<SeeAllContainer onClick={e => {}}>*/}
      {/*<SeeAll>Se alle</SeeAll>*/}
      {/*<MunikumIcons.ArrowRight*/}
      {/*fill={'#333'}*/}
      {/*width={'15px'}*/}
      {/*height={'15px'}*/}
      {/*style={{*/}
      {/*marginRight: '10px'*/}
      {/*}}*/}
      {/*/>*/}
      {/*</SeeAllContainer>*/}
    </NewsSectionCont>
  );
}
export const NewsSectionComp = injectIntl(NewsSectionComp2);
