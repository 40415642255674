import styled from 'styled-components';
import { Colors } from '../../common';

/**
 * this is the label above TextField, TextArea, etc.
 */
export const FormLabel = styled.label`
  display: block;
  width: 100%;
  color: ${Colors.BLACK};
  font-size: 0.875em;
  font-weight: bold;
  line-height: 1.063em;
  padding-bottom: 0.5em;
  font-family: 'Lato', sans-serif;
`;
