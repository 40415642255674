import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import {
  CalendarQuery,
  CalendarVariables,
  MyCalendarsQuery,
  MyCalendarsVariables,
  YearWheelQuery,
  YearWheelRingVariables,
  YearWheelVariables,
} from '../../models/types';

export const fieldsOnTask = gql`
  fragment fieldsOnTask on Task {
    id
    title
    status
  }
`;

export const fieldsOnMiniPerson = gql`
  fragment fieldsOnMiniPerson on Person {
    id
    name
    role
    picture
  }
`;

export const fieldsOnEvent = gql`
  fragment fieldsOnEvent on Event {
    id
    title
    description
    startDate
    endDate
    startRepetitionDate
    status
    assignedBy {
      ...fieldsOnMiniPerson
    }
    endRepetitionDate
    instances {
      ...fieldsOnEventInstance
    }
    repetitionDefinition
    responsible {
      ...fieldsOnMiniPerson
    }
    todoTemplate {
      id
      title
      status
    }
  }
  ${fieldsOnMiniPerson}
`;

export const fieldsOnEventInstance = gql`
  fragment fieldsOnEventInstance on EventInstance {
    id
    title
    description
    isDone
    isFavourite
    startDate
    status
    endDate
    assignedBy {
      ...fieldsOnMiniPerson
    }
    reminderDate
    note
    createdBy {
      ...fieldsOnMiniPerson
    }
    responsible {
      ...fieldsOnMiniPerson
    }
  }
  ${fieldsOnMiniPerson}
`;

export const fieldsOnCalendar = gql`
  fragment fieldsOnCalendar on Calendar {
    id
    title
    uri
    description
    category
    color
    status
    lastUpdated
    dateCreated
    isFollowing
    isRestricted
    isLimited
    isLiked
    likes
    access {
      myAccess
      isPrivate
      persons {
        access
        person {
          id
        }
      }
    }
    topics {
      id
      title
      uri
      dateCreated
      isFollowing
      isLimited
      isRestricted
    }
    followers {
      id
    }
    createdBy {
      id
      name
      picture
      role
      organization {
        id
        organizationImage
        name
      }
    }
  }
`;

export const fieldsOnCalendarLookup = gql`
  fragment fieldsOnCalendarLookup on Calendar {
    id
    title
    description
    color
    access {
      persons {
        access
        person {
          id
        }
      }
    }
    createdBy {
      id
    }
  }
`;

// NOTE: we removed a lot of stuff here!!
// NOTE: calendar same fields as dropdown
const fieldsOnCalendarRing = gql`
  fragment fieldsOnCalendarRing on CalendarRing {
    id
    order
    color
    calendar {
      ...fieldsOnCalendarLookup
    }
  }
  ${fieldsOnCalendarLookup}
`;

// NOTE: removed su bdata here:
export const YEAR_WHEEL = gql`
  query YearWheel($person: ID!) {
    me {
      id
      calendarCode
      picture
      isAdmin
      settings {
        showAssignedTasks
        calendarReverseDirection
        calendarElementView
        calendarUseCtrl
        calendarStartMonth
        calendarHideCompleted
      }
    }
    myCalendars(input: { sort: TITLE, order: ASCENDING }) {
      ...fieldsOnCalendarLookup
    }
    yearWheel(person: $person) {
      ...fieldsOnCalendarRing
    }
    myAssignedTasks {
      ...fieldsOnCalendarRing
    }
  }
  ${fieldsOnCalendarRing}
  ${fieldsOnCalendarLookup}
`;

// seperated into one query per ring/calendar:
export const YEAR_WHEEL_CALENDAR_RING = gql`
  query YearWheelRing($calendarId: ID!, $year: Int, $from: Date, $to: Date) {
    calendar(id: $calendarId) {
      ...fieldsOnCalendar
      tasks {
        ...fieldsOnTask
        events(year: $year, from: $from, to: $to) {
          ...fieldsOnEvent
          instances {
            ...fieldsOnEventInstance
          }
        }
      }
    }
  }
  ${fieldsOnCalendar}
  ${fieldsOnEvent}
  ${fieldsOnEventInstance}
  ${fieldsOnTask}
`;

// massive ALL IN ONE query...  (not ASYNC or streaming. . .)
// same problem again...
// So the BEST solution is either:
//  1. Add @client state, add async loading state there, pass everything to reselect. Can use query ABOVE!
//    or
//  2. Use this MASSIVE query, let backend implement streaming response. (slow until backend fixes that)
//    or
//  3. Just add another hack, event, reset local state..
// export const YEAR_WHEEL_ALL_MY_RINGS_DATA = gql`
//   query AllMyRingsData($person: ID!) {
//     yearWheel(person: $person) {
//
//     }
//   }
//
// `;

// DATA query
export const YEAR_WHEEL_MAGIC_RING = gql`
  query YearWheelMagicRing($year: Int, $from: Date, $to: Date) {
    myAssignedTasks {
      ...fieldsOnCalendarRing
      calendar {
        id
        title
        uri
        description
        category
        color
        status
        lastUpdated
        dateCreated
        isFollowing
        isRestricted
        isLimited
        access {
          myAccess
          isPrivate
          persons {
            access
            person {
              id
            }
          }
        }
        topics {
          id
          title
          uri
          dateCreated
          isFollowing
          isLimited
          isRestricted
        }
        createdBy {
          id
          name
          picture
          role
          organization {
            id
            organizationImage
            name
          }
        }
        tasks {
          ...fieldsOnTask
          events(year: $year, from: $from, to: $to) {
            ...fieldsOnEvent
            instances {
              ...fieldsOnEventInstance
            }
          }
        }
      }
    }
  }
  ${fieldsOnEvent}
  ${fieldsOnEventInstance}
  ${fieldsOnTask}
  ${fieldsOnCalendarRing}
`;

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings($input: UpdateMeSettingsInput!) {
    updateSettings(input: $input) {
      id
      calendarCode
      picture
      isAdmin
      settings {
        showAssignedTasks
        calendarReverseDirection
        calendarElementView
        calendarUseCtrl
        calendarStartMonth
        calendarHideCompleted
      }
    }
  }
`;

export const CREATE_CALENDAR_RING = gql`
  mutation CreateCalendarRingMutation($input: CreateCalendarRingInput!) {
    createCalendarRing(input: $input) {
      ...fieldsOnCalendarRing
    }
  }
  ${fieldsOnCalendarRing}
`;

export const DELETE_CALENDAR_RING = gql`
  mutation DeleteCalendarRingMutation($id: ID!) {
    deleteCalendarRing(id: $id)
  }
`;

export class YearWheelQueryComp extends Query<
  YearWheelQuery,
  YearWheelVariables
> {}

export class YearWheelRingQuery extends Query<
  YearWheelRingQuery,
  YearWheelRingVariables
> {}

export const MY_CALENDARS = gql`
  query MyCalendars {
    myCalendars(input: { sort: TITLE, order: ASCENDING }) {
      ...fieldsOnCalendarLookup
    }
  }
  ${fieldsOnCalendarLookup}
`;

export class MyCalendarsQueryComp extends Query<
  MyCalendarsQuery,
  MyCalendarsVariables
> {}

export const CALENDAR = gql`
  query Calendar($id: ID!, $year: Int) {
    me {
      id
      calendarCode
      picture
      isAdmin
      settings {
        calendarHideCompleted
        showAssignedTasks
      }
    }
    yearWheel(person: $id) {
      calendar {
        id
      }
    }
    calendar(id: $id) {
      ...fieldsOnCalendar
      tasks {
        ...fieldsOnTask
        events(year: $year) {
          ...fieldsOnEvent
          instances {
            ...fieldsOnEventInstance
          }
        }
      }
    }
  }
  ${fieldsOnCalendar}
  ${fieldsOnTask}
  ${fieldsOnEvent}
  ${fieldsOnEventInstance}
`;
const FOLLOW_CALENDAR = gql`
  mutation FollowCalendar($id: ID!) {
    follow(id: $id)
  }
`;
const UNFOLLOW_CALENDAR = gql`
  mutation UnfollowCalendar($id: ID!) {
    unFollow(id: $id)
  }
`;

export class CalendarQueryComp extends Query<
  CalendarQuery,
  CalendarVariables
> {}
