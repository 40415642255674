import * as React from 'react';
import { CSSProperties } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import muniBird from './Munin.svg';
import styled from 'styled-components';

export interface ITopicNoDataMessage {
  title: string;
  style?: CSSProperties;
}
const OuterCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const TopicTabNoDataComp: React.SFC<ITopicNoDataMessage & InjectedIntlProps> = (
  props: ITopicNoDataMessage & InjectedIntlProps
) => {
  return (
    <OuterCont style={props.style}>
      <div
        style={{
          display: 'flex',
          alignContent: 'flex-start',
          marginTop: '8px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <img
            src={muniBird}
            alt={'Munin'}
            style={{ height: '100px', width: '100px' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignContent: 'flex-start',
            flexDirection: 'column',
            maxWidth: '184px',
            marginLeft: '.5em',
          }}
        >
          <h2 style={{ marginBottom: '6px' }}>Hei!</h2>
          <div style={{ maxWidth: '184px' }}>{props.title}</div>
        </div>
      </div>
    </OuterCont>
  );
};

export const TopicTabNoData = injectIntl(TopicTabNoDataComp);
