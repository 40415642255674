import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import {
  Colors,
  ColorTheme,
  formatMunikumDate,
  safeInvoke,
  safeInvokeDeprecated,
} from '../common';
import { MunikumIcons } from '../common/icons';
import { Button, ButtonSize, NavButton } from '../basic';
import { ISector } from '../../services/sectorService';
import { Page, PageLastUpdated, PageTitle, PageTop } from '../layout/Page/Page';
import {
  ContentStatus,
  CreateCommentInput,
  FieldsOnActionValueComments,
  FieldsOnActionValueFragment,
  RadarGraphType,
  Topic,
  UpdateCommentInput,
} from '../../models/types';
import {
  ButtType,
  FollowButton2,
  MyType,
} from '../basic/FollowButton/FollowButton2';
import { AnswerDiscussionView } from '../discussion/AnswerDiscussionView';
import { TogglePopover } from '../basic/Popover/TogglePopover';
import { IFileItem } from '../basic/UserFile/FileUploaderPicker';
import { ShareButton } from '../meta/ShareButton';
import { CommentView2 } from '../comment/CommentView2';
import {
  FlexGrid,
  FlexGridItem,
  FlexGridRow,
  FlexGridRowContainer,
} from '../layout/FlexGrid/FlexGrid';
import {
  IActionValue,
  IHttpLink,
  radarType,
} from '../../services/models/actionValue';
import { Radargraph } from '../basic/Radargraph/Radargraph';
import { ExternalLink, LinkWrapper } from '../basic/Structural/links';
import { FileListResource } from '../basic/UserFile/FileListResource';
import { ICounty } from '../../services/municipalityService';
import { Popover } from '../basic/Popover/Popover';
import { myHistory } from '../../index';
import { IFile } from '../../services/fileservice';
import { IOrganizationType } from '../../services/models/organization';
import { MenuTitle } from '../basic/Menu/MenuTitle';
import MediaQuery from 'react-responsive';
import { H2, TextNormal, TextSmall } from '../basic/Structural/typography';
import { Menu, MenuItem } from '../basic/Menu';
import { ICommentEditForm } from '../../services/commentService';
import { IShareForm } from '../../services/metaService';
import { commonMessages } from '../language/commonMessages';
import { DeadlinesView } from './DeadlinesView';
import { Tag } from '../basic/Tag/Tag';
import { sizes } from '../common/media';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { DeadlineType } from '../../services/models/deadline';
import { Clap, ObjectType } from '../basic/Clap/Clap';
import { ClapDiv } from '../annualCycle/AnnualCycleCard';
import { Overlay } from '../basic/Overlay/Overlay';
import { FileInfoCard } from '../basic/UserFile/FileInfoCard';
import { convertFromRaw } from 'draft-js';

const RadarGraphBox = styled.div`
  //text-align: center;
  background-color: rgba(208, 211, 212, 0.4);
  padding: 0.875em;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SourceLinksContainer = styled.div`
  //background-color: sienna;
  display: flex;
  flex-direction: column;
`;

const SourceLinkItem = styled(ExternalLink)`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;

  border-bottom: solid 1px transparent;
  transition: border-bottom 0.2s ease-out;
  &:hover {
    border-bottom: solid 1px transparent;
    text-decoration: underline;
  }
`;

export const PageTopRight = styled.div`
  flex: 0 0 content;

  display: flex;
  align-self: flex-end;
  justify-content: flex-end;

  //background-color: paleturquoise;

  padding-top: 0.5em;
  margin-left: 0.875em;

  @media (min-width: ${sizes.medium}px) {
    justify-content: flex-start;
    //background-color: rebeccapurple;
    align-self: flex-end;

    padding-top: 0;
  }
`;

const messages = defineMessages({
  duplicateButton: {
    id: 'ActionValueView.duplicateButton',
    defaultMessage: 'Duplicate',
  },
  shortDescription: {
    id: 'ActionValueView.shortDescription',
    defaultMessage: 'Short description',
  },
  attributesHeading: {
    id: 'ActionValueView.attributesHeading',
    defaultMessage: 'Attributes',
  },
  year: {
    id: 'ActionValueView.year',
    defaultMessage: 'Year',
  },
  kostraFunction: {
    id: 'ActionValueView.kostraFunction',
    defaultMessage: 'Kostra function',
  },
  sector: {
    id: 'ActionValueView.sector',
    defaultMessage: 'Sector',
  },
  category: {
    id: 'ActionValueView.category',
    defaultMessage: 'Sector',
  },
  validFor: {
    id: 'ActionValueView.validFor',
    defaultMessage: 'Valid for',
  },
  source: {
    id: 'ActionValueView.source',
    defaultMessage: 'Source',
  },
  deadlinesHeading: {
    id: 'ActionValueView.deadlinesHeading',
    defaultMessage: 'Deadlines',
  },
  documentationHeading: {
    id: 'ActionValueView.documentationHeading',
    defaultMessage: 'Deadlines',
  },
  assessmentHeading: {
    id: 'ActionValueView.assessmentHeading',
    defaultMessage: 'Action value assessment',
  },
  assessmentEffort: {
    id: 'ActionValueView.assessmentEffort',
    defaultMessage: 'Effort assessment',
  },
  assessmentGain: {
    id: 'ActionValueView.assessmentGain',
    defaultMessage: 'Gain assessment',
  },
  comment: {
    id: 'ActionValueView.comment',
    defaultMessage: 'Comment',
  },
  economy: {
    id: 'ActionValueView.economy',
    defaultMessage: 'Economy',
  },
  profession: {
    id: 'ActionValueView.profession',
    defaultMessage: 'Profession',
  },
  organisation: {
    id: 'ActionValueView.organisation',
    defaultMessage: 'Organisation',
  },
  description: {
    id: 'ActionValueView.description',
    defaultMessage: 'Description',
  },
  comments: {
    id: 'ActionValueView.comments',
    defaultMessage: 'Share your experiences or questions',
  },
  replyTitle: {
    id: 'ActionValueView.replyTitle',
    defaultMessage: 'Reply to question',
  },
  seeCompleteAnnouncementLinkText: {
    id: 'ActionValueView.seeCompleteAnnouncementLinkText',
    defaultMessage: 'See complete announcement',
  },
  TopicConnectedToTitle: {
    id: 'ActionValueView.TopicConnectedToTitle',
    defaultMessage: 'The grant is linked to the following topics:',
  },
});

export interface IActionValueViewProps {
  item: FieldsOnActionValueFragment;
  me: any; // FieldsOnMeFragment; // TODO:
  username?: string;
  isFollowing: boolean;
  newCommentIsSaving: boolean;
  newCommentSuccess: boolean;
  isDataUpdating: boolean;
  onFollowClick?: () => void;
  onFileDelete?: (commentFileId: string) => void;
  isSharing: boolean;
  grantId: string;
  onDeleteComment?: (idComment: string, commentMessage: string) => void;
  onDeleteSubComment: (
    idSubComment: string | number,
    subCommentMessage: string,
    commentId: string | number
  ) => void;
  calendarCode: string;
  onSaveSubComment?: (values: any, files: IFileItem[]) => void;
}

export interface IActionValueViewDispatch {
  onSaveComment?: (values: any, files: IFileItem[]) => void;
  onUpdateComment?: (values: ICommentEditForm, files: IFileItem[]) => void;
  onShare?: (form: IShareForm) => void;
  onTopicClick?: (uri: string) => void;
  onShouldScrollToPosition?: (pos: { x: number; y: number }) => void;
}

export interface IActionValueViewState {
  isFileInfoCardVisible: boolean;
  fileInfoId: string;
  isMoreVisible: boolean;
}

const DeadlinesContainer = styled.div`
  //background-color: lavenderblush;
  display: flex;

  @media (min-width: ${sizes.ipad}px) {
    display: flex;
    justify-content: space-between;
  }
`;

const TopicConnectedToTitle = styled.div`
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin: 12px 6px 12px 31px;
`;

class ActionValueViewComponent extends React.PureComponent<
  IActionValueViewProps & InjectedIntlProps & IActionValueViewDispatch,
  IActionValueViewState
> {
  constructor(
    props: IActionValueViewProps & InjectedIntlProps & IActionValueViewDispatch
  ) {
    super(props);

    this.state = {
      isMoreVisible: false,
      isFileInfoCardVisible: false,
      fileInfoId: undefined,
    };
  }

  getRGComment(actionValue: IActionValue, type: number, group: string): string {
    let str = '';

    if (actionValue.radarGraph) {
      for (let it of actionValue.radarGraph) {
        if (it.type === type && it.name === group) {
          str = it.comment;
        }
      }
    }

    return str;
  }

  renderSectors(
    sectors: ReadonlyArray<string>,
    allSectors: { [byId: string]: Readonly<ISector> }
  ) {
    return sectors.map(sectorId => allSectors[sectorId].title).join(', ');
  }

  renderCounties(
    counties: ReadonlyArray<string>,
    allCounties: { [byId: string]: Readonly<ICounty> }
  ) {
    return counties.map(countyId => allCounties[countyId].name).join(', ');
  }

  renderOrganizationTypes(
    orgTypes: ReadonlyArray<string>,
    allOrgTypes: { [byId: string]: Readonly<IOrganizationType> }
  ) {
    return orgTypes.map(orgTypeId => allOrgTypes[orgTypeId].name).join(', ');
  }

  handleSaveComment = (values: any, files: IFileItem[]) => {
    safeInvokeDeprecated(this.props.onSaveComment, values, files);
  };

  handleTopicClick = (metaUri: string) => {
    safeInvokeDeprecated(this.props.onTopicClick, metaUri);
  };

  pickFile = (
    allFiles: { [byId: string]: Readonly<IFile> },
    userFiles: ReadonlyArray<number> | undefined
  ) => {
    let retVal: Array<IFile> = [];
    if (userFiles && allFiles) {
      for (let ufId of userFiles) {
        Object.keys(allFiles).map(index => {
          if (+allFiles[index].id === ufId) {
            retVal.push(allFiles[index]);
          }
        });
      }
    }
    return retVal;
  };

  render() {
    const { item, me, username, intl } = this.props;
    const { isMoreVisible } = this.state;

    let radarGraphEffort = Array<RadarGraphType>();
    let radarGraphGain = Array<RadarGraphType>();
    if (item && item.currentRevision.radarGraph) {
      item.currentRevision.radarGraph.map(value => {
        if (value.type === radarType.EFFORT) {
          radarGraphEffort.push(value);
        } else {
          radarGraphGain.push(value);
        }
      });
    }

    const applicationDeadlines = _.filter(
      item.currentRevision.deadlines,
      (value, index) => value.type === DeadlineType.APPLICATION
    );
    const reportDeadlines = _.filter(
      item.currentRevision.deadlines,
      (value, index) => value.type === DeadlineType.REPORT
    );

    // console.log('deadlines', applicationDeadlines, reportDeadlines);

    item.topics.sort(function(a: Topic, b: Topic) {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    return (
      <Page>
        <PageTop>
          <PageTitle
            style={{
              flex: '1 1 0px',
              whiteSpace: 'normal',
              marginRight: '.5em',
            }}
          >
            {item.title}
          </PageTitle>
          <PageTopRight>
            <Clap
              idToRefetch={this.props.item.id}
              idToClap={this.props.item.id}
              ObjectToClap={ObjectType.ACTION_VALUE}
              isClapped={this.props.item.isLiked}
              style={{ marginRight: '.5em' }}
            />
            <FollowButton2
              followType={MyType.ACTION_VALUE}
              buttonType={ButtType.BUTTON}
              isFollowing={this.props.isFollowing}
              followId={this.props.grantId}
            />

            <ShareButton
              meta={item.id}
              style={{
                marginLeft: '.5em',
              }}
              onShare={this.props.onShare}
              isSharing={this.props.isSharing}
            />

            {me.isAdmin && (
              <TogglePopover
                position={'bottom'}
                renderTarget={ref => (
                  <Button
                    text={intl.formatMessage(commonMessages.more)}
                    size={ButtonSize.Small}
                    theme={ColorTheme.White}
                    leftIcon={MunikumIcons.More}
                    style={{
                      marginLeft: '.5em',
                    }}
                    innerRef={ref}
                  />
                )}
              >
                <Menu>
                  <MenuTitle text={'Admin options'} />
                  <MenuItem
                    leftIcon={MunikumIcons.Duplicate}
                    text={intl.formatMessage(messages.duplicateButton)}
                    onClick={() => {
                      myHistory.push(
                        '/admin/action-value/copy/' + item.currentRevision.id
                      );
                    }}
                  />
                  <MenuItem
                    leftIcon={MunikumIcons.Edit}
                    text={intl.formatMessage(commonMessages.edit)}
                    onClick={() => {
                      myHistory.push('/admin/action-value/edit/' + item.id);
                    }}
                  />
                </Menu>
              </TogglePopover>
            )}

            {false && me.isAdmin && (
              <Popover
                isOpen={isMoreVisible}
                position={'bottom'}
                renderTarget={ref => (
                  <Button
                    text={intl.formatMessage(commonMessages.more)}
                    size={ButtonSize.Small}
                    theme={ColorTheme.White}
                    leftIcon={MunikumIcons.More}
                    style={{
                      marginLeft: '.5em',
                    }}
                    innerRef={ref}
                    onClick={() => {
                      this.setState(
                        {
                          isMoreVisible: !this.state.isMoreVisible,
                        },
                        () => {
                          console.log(this.state.isMoreVisible);
                        }
                      );
                    }}
                  />
                )}
              >
                <React.Fragment>
                  <H2>Admin options</H2>
                  <TextSmall>
                    This will be replaced by <br /> 'Menu-component' comping
                    soon ;-) <br />
                  </TextSmall>
                  <NavButton
                    navigateTo={'/admin/action-value/edit/' + item.id}
                    theme={ColorTheme.Blue}
                    leftIcon={MunikumIcons.Duplicate}
                    text={intl.formatMessage(messages.duplicateButton)}
                    size={ButtonSize.Medium}
                    style={{
                      marginBottom: '.875em',
                      marginTop: '.875em',
                      width: '100%',
                    }}
                  />

                  <br />

                  <NavButton
                    navigateTo={'/admin/action-value/edit/' + item.id}
                    theme={ColorTheme.Blue}
                    leftIcon={MunikumIcons.Edit}
                    text={intl.formatMessage(commonMessages.edit)}
                    size={ButtonSize.Medium}
                    style={{
                      width: '100%',
                    }}
                  />
                </React.Fragment>
              </Popover>
            )}
            <NavButton
              theme={ColorTheme.Red}
              navigateTo={'back'}
              leftIcon={MunikumIcons.Back}
              text={intl.formatMessage(commonMessages.goBackButton)}
              size={ButtonSize.Medium}
              style={{
                marginLeft: '.875em',
              }}
            />
          </PageTopRight>
        </PageTop>
        <div style={{ maxWidth: '84.125em', margin: '0 auto' }}>
          <PageLastUpdated>
            {intl.formatMessage(commonMessages.lastUpdated)}:{' '}
            {formatMunikumDate(item.lastUpdated)}
          </PageLastUpdated>
        </div>

        <FlexGrid>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexFlow: 'row wrap',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TopicConnectedToTitle>
                {intl.formatMessage(messages.TopicConnectedToTitle)}
              </TopicConnectedToTitle>

              {item.topics &&
                item.topics.map(values => {
                  return (
                    <LinkWrapper to={'/topic/' + values.uri} key={values.id}>
                      <Tag
                        hasShadow={true}
                        key={values.id}
                        text={values.title}
                        color={Colors.DARKBLUE}
                        style={{ marginLeft: '10px' }}
                        uppercase={false}
                      />
                    </LinkWrapper>
                  );
                })}
            </div>
            <ClapDiv>
              <MunikumIcons.Clap style={{ height: '1.3em', width: '1.3em' }} />
              <div
                style={{
                  color: '#333333',
                  fontFamily: 'Lato, sans-serif',
                  fontSize: '12px',
                  lineHeight: '15px',
                  marginLeft: '.5em',
                }}
              >
                {item.likes} {intl.formatMessage(commonMessages.claps)}
              </div>
            </ClapDiv>
          </div>

          <FlexGridRow topBorder={true}>
            <DeadlinesContainer
              style={{
                marginTop: '-8px',
                marginBottom: '-12px',
                flexWrap: 'wrap',
              }}
            >
              <FlexGridItem style={{ flexGrow: 4 }}>
                <DeadlinesView
                  style={{
                    color: '#ff5c39',
                    fontSize: '12px',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                  }}
                  title={intl.formatMessage(
                    commonMessages.applicationDeadlines
                  )}
                  deadlines={applicationDeadlines}
                  showDefaultMessage={intl.formatMessage(
                    commonMessages.defaultApplicationDeadlinesMessage
                  )}
                  meta={item.id}
                  calendarCode={this.props.calendarCode}
                />
              </FlexGridItem>
              <FlexGridItem style={{ flex: '0 0  34em' }}>
                <DeadlinesView
                  style={{
                    color: '#ff5c39',
                    fontSize: '12px',
                    letterSpacing: '1px',
                    textTransform: 'uppercase',
                  }}
                  title={intl.formatMessage(commonMessages.reportDeadline)}
                  deadlines={reportDeadlines}
                  meta={item.id}
                  calendarCode={this.props.calendarCode}
                />
              </FlexGridItem>
            </DeadlinesContainer>
          </FlexGridRow>

          <FlexGridRow topBorder={true}>
            <FlexGridRowContainer>
              <FlexGridItem style={{ flex: '1 1 auto' }}>
                <H2>{intl.formatMessage(messages.shortDescription)}</H2>
                <TextNormal>{item.currentRevision.description}</TextNormal>
                {item.currentRevision.sourceUrl &&
                  item.currentRevision.sourceUrl.length > 0 && (
                    <div>
                      <NavButton
                        text={intl.formatMessage(
                          messages.seeCompleteAnnouncementLinkText
                        )}
                        theme={ColorTheme.Red}
                        style={{
                          marginTop: '1em',
                        }}
                        navigateTo={item.currentRevision.sourceUrl}
                        openInNewWindow={true}
                      />
                    </div>
                  )}
              </FlexGridItem>
              <FlexGridItem style={{ flex: '0 0 auto' }}>
                <MediaQuery query={'(min-width: ' + sizes.medium + 'px)'}>
                  <MediaQuery
                    query={'(max-width: ' + (sizes.medium + 100 - 1) + 'px)'}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <H2>{intl.formatMessage(messages.assessmentEffort)}</H2>
                      <RadarGraphBox>
                        <Radargraph
                          width={165}
                          data={radarGraphEffort}
                          strokeWidth={1}
                          style={{ float: 'none' }}
                        />
                      </RadarGraphBox>
                    </div>
                  </MediaQuery>
                </MediaQuery>

                <MediaQuery
                  query={'(min-width: ' + (sizes.medium + 100) + 'px)'}
                >
                  <MediaQuery
                    query={'(max-width: ' + (sizes.large - 1) + 'px)'}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <H2>{intl.formatMessage(messages.assessmentEffort)}</H2>
                      <RadarGraphBox>
                        <Radargraph
                          width={200}
                          data={radarGraphEffort}
                          strokeWidth={1}
                          style={{ float: 'none' }}
                        />
                      </RadarGraphBox>
                    </div>
                  </MediaQuery>
                </MediaQuery>

                <MediaQuery query={'(min-width: ' + sizes.large + 'px)'}>
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <H2>{intl.formatMessage(messages.assessmentEffort)}</H2>
                    <RadarGraphBox>
                      <Radargraph
                        width={230}
                        data={radarGraphEffort}
                        strokeWidth={1}
                        style={{ float: 'none' }}
                      />
                    </RadarGraphBox>
                  </div>
                </MediaQuery>
              </FlexGridItem>

              <FlexGridItem style={{ flex: '0 0 auto' }}>
                <MediaQuery query={'(min-width: ' + sizes.medium + 'px)'}>
                  <MediaQuery
                    query={'(max-width: ' + (sizes.medium + 100 - 1) + 'px)'}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <H2>{intl.formatMessage(messages.assessmentGain)}</H2>
                      <RadarGraphBox>
                        <Radargraph
                          width={165}
                          data={radarGraphGain}
                          strokeWidth={1}
                          style={{ float: 'none' }}
                        />
                      </RadarGraphBox>
                    </div>
                  </MediaQuery>
                </MediaQuery>

                <MediaQuery
                  query={'(min-width: ' + (sizes.medium + 100) + 'px)'}
                >
                  <MediaQuery
                    query={'(max-width: ' + (sizes.large - 1) + 'px)'}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <H2>{intl.formatMessage(messages.assessmentGain)}</H2>
                      <RadarGraphBox>
                        <Radargraph
                          width={200}
                          data={radarGraphGain}
                          strokeWidth={1}
                          style={{ float: 'none' }}
                        />
                      </RadarGraphBox>
                    </div>
                  </MediaQuery>
                </MediaQuery>

                <MediaQuery query={'(min-width: ' + sizes.large + 'px)'}>
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <H2>{intl.formatMessage(messages.assessmentGain)}</H2>
                    <RadarGraphBox>
                      <Radargraph
                        width={230}
                        data={radarGraphGain}
                        strokeWidth={1}
                        style={{ float: 'none' }}
                      />
                    </RadarGraphBox>
                  </div>
                </MediaQuery>
              </FlexGridItem>
            </FlexGridRowContainer>
          </FlexGridRow>

          <FlexGridRow topBorder={true}>
            <FlexGridRowContainer>
              <FlexGridItem
                style={{
                  flex: '2 2 .875em',
                }}
              >
                <H2 style={{ fontWeight: 'bold', fontSize: '20px' }}>
                  <FormattedMessage
                    id={'ActionValueView.informationHeader'}
                    defaultMessage={'Information'}
                  />
                </H2>
                <TextNormal>
                  {item.currentRevision.body || (
                    <FormattedMessage
                      id={'ActionValueView.noBodyText'}
                      defaultMessage={
                        'Please let us know if you have any useful information to share.'
                      }
                    />
                  )}
                </TextNormal>
              </FlexGridItem>
              <FlexGridItem
                style={{
                  flex: '1 1 0px', // NOTE: 0px should fix IE11 bug!
                  overflow: 'hidden',
                }}
              >
                <H2>
                  <FormattedMessage
                    id={'ActionValueView.sourcesHeader'}
                    defaultMessage={'Sources'}
                  />
                </H2>
                <SourceLinksContainer>
                  {(item.currentRevision.links &&
                    item.currentRevision.links.length > 0 &&
                    item.currentRevision.links.map(
                      (link: IHttpLink, index: number) => {
                        return (
                          <Tooltip
                            content={
                              <FormattedMessage
                                id={'ActionValueView.tooltipOpenUrl'}
                                defaultMessage={
                                  'Click here to open {br}{url}{br} in a new window'
                                }
                                values={{
                                  br: <br />,
                                  url: <b>{link.href}</b>,
                                }}
                              />
                            }
                            key={'link_ds_' + index}
                          >
                            <div>
                              <SourceLinkItem
                                href={link.href}
                                key={'link_s_' + index}
                              >
                                {link.label}
                              </SourceLinkItem>
                            </div>
                          </Tooltip>
                        );
                      }
                    )) || (
                    <FormattedMessage
                      id={'ActionValueView.noLinks'}
                      defaultMessage={
                        'No links added. Please share if you know any relevant links'
                      }
                      values={{
                        br: <br />,
                      }}
                    />
                  )}
                </SourceLinksContainer>
              </FlexGridItem>
              <FlexGridItem
                style={{
                  flex: '1 1 0px',
                  overflow: 'hidden',

                  // flex: '1 1 calc((100% - 1.75em)/3)',
                  // flexBasis: '33%'
                }}
              >
                <H2>
                  <FormattedMessage
                    id={'ActionValueView.resourcesHeader'}
                    defaultMessage={'Resources'}
                  />
                </H2>
                {(item.files && item.files.length > 0 && (
                  <FileListResource
                    files={item.files}
                    onClick={(id: string) => {
                      this.setState({
                        isFileInfoCardVisible: true,
                        fileInfoId: id,
                      });
                    }}
                  />
                )) || (
                  <FormattedMessage
                    id={'ActionValueView.noResources'}
                    defaultMessage={
                      'No resources added. Please share if you know any relevant resources'
                    }
                    values={{
                      br: <br />,
                    }}
                  />
                )}
              </FlexGridItem>
            </FlexGridRowContainer>
          </FlexGridRow>

          <Overlay isOpen={this.state.isFileInfoCardVisible}>
            <FileInfoCard
              fileid={this.state.fileInfoId}
              canEdit={me.isAdmin}
              canDelete={me.isAdmin}
              onDelete={() => {
                this.setState(
                  {
                    isFileInfoCardVisible: false,
                  },
                  () => {
                    safeInvoke(this.props.onFileDelete, this.state.fileInfoId);
                  }
                );
              }}
              onCancel={() => {
                this.setState({
                  isFileInfoCardVisible: false,
                });
              }}
            />
          </Overlay>

          {/*<MainBoxRow topBorder={true}>*/}
          {/*<div>*/}
          {/*<h2>Filer</h2>*/}
          {/*<FileList metaid={+item.meta.id} />*/}
          {/*</div>*/}
          {/*</MainBoxRow>*/}

          <FlexGridRow topBorder={true}>
            <FlexGridItem>
              <H2>{intl.formatMessage(messages.comments)}</H2>

              <div style={{ marginTop: '.5em' }}>
                {me &&
                  item.comments &&
                  item.comments.map(
                    (comment: FieldsOnActionValueComments, index: number) => {
                      let canEditComment = false;
                      if (username && comment.author.name === username) {
                        canEditComment = true;
                      }
                      if (me && me.isAdmin) {
                        canEditComment = true;
                      }

                      return (
                        <CommentView2
                          parentMetaId={item.id}
                          likes={comment.likes}
                          objectToClap={ObjectType.COMMENTACTIONVALUE}
                          isLiked={comment.isLiked}
                          refetchSubCommentType={
                            ObjectType.SUBCOMMENTACTIONVALUE
                          }
                          commentId={comment.id}
                          onFileDelete={(commentFileId: string) => {
                            safeInvokeDeprecated(
                              this.props.onFileDelete,
                              commentFileId
                            );
                          }}
                          key={'cc_' + index}
                          author={comment.author}
                          rawMarkdown={comment.message}
                          me={me}
                          onDeleteClick={() => {
                            safeInvokeDeprecated(
                              this.props.onDeleteComment,
                              comment.id,
                              comment.message
                            );
                          }}
                          canEdit={canEditComment}
                          comments={comment.replies}
                          onUpdateComment={(md: string, files) => {
                            const updateForm: UpdateCommentInput = {
                              id: comment.id,
                              meta: comment.id,
                              status: ContentStatus.PUBLISHED,
                              message: md,
                              messageText: convertFromRaw(
                                JSON.parse(md)
                              ).getPlainText(), // parent: comment.id,
                            };

                            safeInvokeDeprecated(
                              this.props.onUpdateComment,
                              updateForm,
                              files
                            );
                          }}
                          onSaveSubComment={(md: string) => {
                            const saveForm: CreateCommentInput = {
                              meta: comment.id,
                              messageText: convertFromRaw(
                                JSON.parse(md)
                              ).getPlainText(),
                              message: md,
                              parent: comment.id,
                              status: ContentStatus.PUBLISHED,
                            };

                            // this.handleSaveComment(saveForm);
                            safeInvokeDeprecated(
                              this.props.onSaveSubComment,
                              saveForm
                            );
                          }}
                          onUpdateSubComment={(
                            md: string,
                            id: string,
                            meta: any
                          ) => {
                            const updateForm: ICommentEditForm = {
                              id: id,
                              meta: meta.id,
                              message: md,
                              messageText: convertFromRaw(
                                JSON.parse(md)
                              ).getPlainText(),
                            };

                            // this.handleUpdateComment(updateForm);
                            safeInvokeDeprecated(
                              this.props.onUpdateComment,
                              updateForm
                            );
                          }}
                          onSubCommentDelete={(id: any, message: string) => {
                            safeInvoke(
                              this.props.onDeleteSubComment,
                              id,
                              message,
                              comment.id
                            );
                          }}
                          onShouldScrollToPosition={(pos: {
                            x: number;
                            y: number;
                          }) =>
                            safeInvokeDeprecated(
                              this.props.onShouldScrollToPosition,
                              pos
                            )
                          }
                          files={comment.files}
                        />
                      );
                    }
                  )}
              </div>

              {me && (
                <AnswerDiscussionView
                  me={me}
                  onSave={(rawMarkdown: string, files2: IFileItem[]) => {
                    this.handleSaveComment(
                      {
                        meta: item.id,
                        message: rawMarkdown,
                        messageText: convertFromRaw(
                          JSON.parse(rawMarkdown)
                        ).getPlainText(),
                      },
                      files2
                    );
                  }}
                  // title={intl.formatMessage(messages.replyTitle)}
                  isSaving={this.props.newCommentIsSaving}
                  saveSuccess={this.props.newCommentSuccess}
                />
              )}
            </FlexGridItem>
          </FlexGridRow>
        </FlexGrid>
      </Page>
    );
  }
}

export const ActionValueView = injectIntl(ActionValueViewComponent);
