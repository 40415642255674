/*
 * colors.ts
 */
import ColorLib from 'color';

export const Colors = {
  RED: '#FF5C39',
  BADASS: '#BADA55',
  DARKRED: '#963821',
  REDCLICK: 'rgba(255,92,57,0.8)',
  BLUE: '#00B0B9',
  DARKBLUE: '#00778B',
  DARKYELLOW: '#A37F14',
  YELLOW: '#F2A900',
  BLACK: '#333333',
  REALBLACK: '#000000',
  BLACK50: ColorLib('#333333')
    .alpha(0.5)
    .string(),
  BLACK70: ColorLib('#333333')
    .alpha(0.7)
    .string(),
  GREY: '#D0D3D4',
  GREY208: 'rgba(208,211,212,1)',
  GREYCLICK: ColorLib('#D0D3D4')
    .alpha(0.5)
    .lighten(0.5)
    .string(),
  GREYLABEL: 'rgba(51,51,51,0.7)',
  GREYDISABLEDTEXT: ColorLib('#354052')
    .alpha(0.5)
    .string(),
  GREYPLACEHOLDER: ColorLib('rgba(51,51,51,0.7)')
    .alpha(0.5)
    .string(),
  GREYBORDER: 'rgba(208,211,212,0.7)',
  GREYDISABLED: 'rgba(208,211,212,0.4)',
  WHITE: '#FFFFFF',
  WHITEBACKGROUND: '#F5F5F5',
  GREYTEXT: '#565656;',
  ROW_BACKGROUND: 'rgba(208,211,212,0.2)',
  ROW_BACKGROUND_HOVER: ColorLib('#00B0B9')
    .alpha(0.2)
    .string(),
};

export enum ColorTheme {
  Red,
  Blue,
  White,
  Yellow,
  Black,
}

export const DEFAULT_COLOR_THEME = ColorTheme.Red;

export interface IThemeProps {
  backgroundColor: string;

  backgroundHoverColor: string;

  backgroundDisabledColor: string;

  backgroundActiveColor: string;

  textColor: string;

  textDisabledColor: string;

  borderColor: string;

  borderHoverColor: string;

  borderActiveColor: string;
}

export const getThemeProps = (
  theme: ColorTheme = DEFAULT_COLOR_THEME
): IThemeProps => {
  if (theme === ColorTheme.Black) {
    return {
      borderColor: Colors.BLACK,
      borderHoverColor: Colors.REALBLACK,
      backgroundColor: Colors.BLACK,
      backgroundHoverColor: Colors.REALBLACK,
      // backgroundActiveColor: Colors.BLACK70,
      backgroundDisabledColor: Colors.GREYDISABLED,
      textColor: Colors.WHITE,
      textDisabledColor: Colors.GREYDISABLEDTEXT,
      backgroundActiveColor: ColorLib(Colors.BLACK)
        .alpha(0.8)
        .string(),
      borderActiveColor: ColorLib(Colors.BLACK)
        .alpha(0.8)
        .string(),
    };
  } else if (theme === ColorTheme.Blue) {
    return {
      borderColor: Colors.BLUE,
      borderHoverColor: Colors.DARKBLUE,
      backgroundColor: Colors.BLUE,
      backgroundHoverColor: Colors.DARKBLUE,
      backgroundDisabledColor: Colors.GREYDISABLED,
      textColor: Colors.WHITE,
      backgroundActiveColor: ColorLib(Colors.BLUE)
        .alpha(0.8)
        .string(),
      textDisabledColor: Colors.GREYDISABLEDTEXT,
      borderActiveColor: ColorLib(Colors.BLUE)
        .alpha(0.8)
        .string(),
    };
  } else if (theme === ColorTheme.White) {
    return {
      borderColor: Colors.GREYBORDER,
      borderHoverColor: Colors.GREYBORDER,
      backgroundColor: Colors.WHITE,
      backgroundHoverColor: ColorLib(Colors.GREY208)
        .alpha(0.4)
        .string(),
      backgroundDisabledColor: Colors.GREYDISABLED,
      textColor: Colors.BLACK,
      backgroundActiveColor: ColorLib(Colors.GREY208)
        .alpha(0.2)
        .string(),
      textDisabledColor: Colors.GREYDISABLEDTEXT,
      borderActiveColor: Colors.GREYBORDER,
    };
  } else if (theme === ColorTheme.Yellow) {
    return {
      borderColor: Colors.YELLOW,
      borderHoverColor: Colors.DARKYELLOW,
      backgroundColor: Colors.YELLOW,
      backgroundHoverColor: Colors.DARKYELLOW,
      backgroundDisabledColor: Colors.GREYDISABLED,
      textColor: Colors.WHITE,
      backgroundActiveColor: ColorLib(Colors.YELLOW)
        .alpha(0.8)
        .string(),
      textDisabledColor: Colors.GREYDISABLEDTEXT,
      borderActiveColor: ColorLib(Colors.YELLOW)
        .alpha(0.8)
        .string(),
    };
  }

  return {
    borderColor: Colors.RED,
    borderHoverColor: Colors.DARKRED,
    backgroundColor: Colors.RED,
    backgroundHoverColor: Colors.DARKRED,
    backgroundDisabledColor: Colors.GREYDISABLED,
    textColor: Colors.WHITE,
    textDisabledColor: Colors.GREYDISABLEDTEXT,
    backgroundActiveColor: ColorLib(Colors.RED)
      .alpha(0.8)
      .string(),
    borderActiveColor: ColorLib(Colors.RED)
      .alpha(0.8)
      .string(),
  };
};
