import * as React from 'react';
import { IFile } from '../../../services/fileservice';
import { safeInvokeDeprecated } from '../../common';
import { IFileItem } from './FileUploaderPicker';

export interface IFileUploaderRenderProps {
  /**
   * persistedFiles, this is files already persisted
   */
  persistedFiles?: ReadonlyArray<IFile>;
  recentFiles?: IFileItem[];
  handleNewFiles?: (newFiles: IFileItem[]) => void;
  handleRemoveFile: (id: string) => void;
  handleClearAllFiles: () => void;

  handleDeleteFile?: (id: string) => void;
}

type FileUploaderRenderFunc = (
  props: IFileUploaderRenderProps
) => JSX.Element | null | false;

export interface IFileUploaderProps {
  files?: ReadonlyArray<IFile>;
  onUploadableFiles?: (recentFiles: IFileItem[]) => void;
  fileUploaderRenderFunc: FileUploaderRenderFunc;

  onDeleteFile?: (id: string) => void;

  liveUpload?: boolean;
}

interface IFileUploaderState {
  recentFiles: IFileItem[];
}

export class FileUploader extends React.PureComponent<
  IFileUploaderProps,
  IFileUploaderState
> {
  constructor(props: IFileUploaderProps) {
    super(props);

    this.state = {
      recentFiles: [],
    };
  }

  handleNewFiles = (newFiles: IFileItem[]) => {
    let newRecentFiles =
      this.props.liveUpload !== undefined && this.props.liveUpload
        ? newFiles
        : this.state.recentFiles.concat(newFiles);

    this.setState({ recentFiles: newRecentFiles }, () => {
      safeInvokeDeprecated(
        this.props.onUploadableFiles,
        this.state.recentFiles
      );
    });
  };

  handleRemoveFile = (id: string) => {
    let newRecentFiles = this.state.recentFiles.slice();
    newRecentFiles.forEach((it, idx, arr) => {
      if (it.uuid === id) {
        newRecentFiles.splice(idx, 1);
      }
    });

    this.setState({ recentFiles: newRecentFiles }, () => {
      safeInvokeDeprecated(
        this.props.onUploadableFiles,
        this.state.recentFiles
      );
    });
  };

  handleClearAllFiles = () => {
    this.setState({ recentFiles: [] }, () => {
      safeInvokeDeprecated(
        this.props.onUploadableFiles,
        this.state.recentFiles
      );
    });
  };

  handleDeleteFile = (id: string) => {
    safeInvokeDeprecated(this.props.onDeleteFile, id);
  };

  render() {
    return (
      <React.Fragment>
        {this.props.fileUploaderRenderFunc({
          persistedFiles: this.props.files ? this.props.files : [],
          recentFiles: this.state.recentFiles,
          handleNewFiles: this.handleNewFiles,
          handleRemoveFile: this.handleRemoveFile,
          handleClearAllFiles: this.handleClearAllFiles,
          handleDeleteFile: this.handleDeleteFile,
        })}
      </React.Fragment>
    );
  }
}
