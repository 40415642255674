import * as React from 'react';
import styled from 'styled-components';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import * as _ from 'lodash';
import memoizeOne from 'memoize-one';
import { IEventInstanceViewModel } from './AnnualCycleRightMenu';
import {
  getEmptySelection,
  SelectedMonthsDict,
  Wheel,
  WheelDirection,
  WheelSelectionMode,
  WheelToggleMode,
} from './Wheel/Wheel';
import {
  FieldsOnCalendarLookupFragment,
  FieldsOnCalendarRingFragment,
} from '../../models/types';
import { MunikumIcons } from '../common/icons';
import { SelectMode } from '../basic/DropDownSelectUltimate/UltimateDropdown';
import {
  IconButton,
  IconButtonHoverStyle,
} from '../basic/IconButton/IconButton';
import {
  ColorTheme,
  isReallyEmpty,
  safeInvoke,
  safeInvokeDeprecated,
} from '../common';
import { IWheelSettings, WheelSettings } from './Wheel/WheelSettings';
import { CalendarsDropdown } from './CalendarsDropdown';
import { Dialog } from '../basic/Dialog/Dialog';
import { Tag } from '../basic/Tag/Tag';
import { Button } from '../basic';
import { MyFollowedCalendarsDropdown } from './Wheel/MyFollowedCalendarsDropdown';
import { Title } from './CreateAnnualCycleForm';
import { commonLabels } from '../language/commonLabels';
import {
  ButtType,
  FollowButton2,
  MyType,
} from '../basic/FollowButton/FollowButton2';
import { commonMessages } from '../language/commonMessages';
import { PageTitle } from '../layout/Page/Page';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { IShareForm } from '../../services/metaService';
import { EditAnnualCycleForm } from './EditAnnualCycleForm';
import { myHistory } from '../../index';
import { Overlay } from '../basic/Overlay/Overlay';
import {
  StatusMessageDialog,
  ThemeColor,
} from '../basic/StatusMessage/StatusMessage';
import { ICalendarSettings } from '../../containers/AnnualCycle/calendar-service';
import { MuPreview } from '../basic/MuEditor/MuPreview';

const messages = defineMessages({
  newAnnualCycle: {
    id: 'AnnualCycle.newAnnualCycle',
    defaultMessage: 'New annual cycle',
  },
  wheelSettingsTitle: {
    id: 'AnnualCycle.wheelSettingstitle',
    defaultMessage: 'Annual cycle settings',
  },
  wheelSettingsTooltip: {
    id: 'AnnualCycle.settingsTooltip',
    defaultMessage: 'Change settings',
  },
  wheelClearSelectionTooltip: {
    id: 'AnnualCycle.wheelClearSelectionTooltip',
    defaultMessage: 'Remove filters',
  },
  printTooltip: {
    id: 'AnnualCycle.printTooltip',
    defaultMessage: 'Print year wheel',
  },
  noDescription: {
    id: 'AnnualCycle.noDescription',
    defaultMessage: 'There is not added description',
  },
  EditAnnualCycleSuccessMsg: {
    id: 'AnnualCycleRightMenu.EditAnnualCycleSuccessMsg',
    defaultMessage: 'Annual cycle successfully edited',
  },
});

export interface IAnnualCycleProps {
  // lookup data for dropdown
  myCalendars?: Array<FieldsOnCalendarLookupFragment>;

  // rings to show in annual cycle NOTE: reafactored to EXCLUDE the actual CALENDAr in the ring!!
  rings?: Array<FieldsOnCalendarRingFragment>;

  // magic ring if visible
  magicRing?: FieldsOnCalendarRingFragment;

  // only year, or FROM year combined with startMonth . . .
  year: number;
  onShare?: (form: IShareForm) => void;

  // TO year combined with startMonth
  year2?: number;
  calendar?: any;
  isSharing?: boolean;

  // '0' === jan
  startMonth?: string;
  // description for calendarpage
  isCalendarPage?: boolean;

  isMyYearWheel: boolean;
  isMyYearWheelCalendarPage?: boolean;
  userHash: string;
  canEdit?: boolean;

  // filtered tasks, use to show summary in middle of wheel
  eventInstances: Array<IEventInstanceViewModel>;

  // render tags or not
  showTags: boolean;

  onEditClick?: (annualCycleId: string) => void;
  onMagicRingClick?: (isActive: boolean) => void;
  onPrint?: () => void;

  // controllable props:
  direction?: WheelDirection;
  selectionMode?: WheelSelectionMode;
  toggleMode?: WheelToggleMode;
  selectedMonths?: SelectedMonthsDict;

  onSettingsChange?: (settings: ICalendarSettings) => void;
  onSelectedYearChanged?: (year: number, year2?: number) => void;
  onStartMonthChanged?: (startMonth: string) => void;
  onClickNewAnnualCycle?: () => void;
  // onClickSettings?: () => void;
  onSelectedMonthsChanged?: (selected: SelectedMonthsDict) => void;
  onHoveredMonthChanged?: (hoveredMonth?: IHoveredMonth) => void;

  onCalendarSelected?: (item: FieldsOnCalendarLookupFragment) => void;
  onCalendarUnselected?: (item: FieldsOnCalendarLookupFragment) => void;
}

export interface IHoveredMonth {
  monthIndex: number;
  monthName: string;
  ringId: string;
}

interface IAnnualCycleState {
  direction: WheelDirection; // wheel direction
  selectionMode: WheelSelectionMode;
  toggleMode: WheelToggleMode;
  isSmallScreen?: boolean;
  isEditAnnualCycleFormOpen: boolean;
  year: number; // filter year
  year2?: number; // filter year2....
  isCopyYearWheelOpen: boolean;
  selectedRings: Array<FieldsOnCalendarRingFragment>; // rings selected in dropdown. render tags

  selectedMonths: SelectedMonthsDict; // filter months

  mySelectedCalendars?: Array<FieldsOnCalendarLookupFragment>;
  isSuccessMessageActive: boolean;

  isSettingsOpen: boolean;

  startMonth: string;
}

const Container = styled.div`
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TagsDiv = styled.div`
  padding-bottom: 0.5em;
  padding-top: 1.25em;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const Header = styled.div`
  box-sizing: border-box;
  margin-bottom: 1em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  //max-width: 100%;
`;

const IconButtonsDiv = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  //align-items: center;
  box-sizing: border-box;
  margin-bottom: 0.5em;
  //max-width: 100%;
`;

const CalendarDiv = styled.div`
  margin-left: 0.5em;
  box-sizing: border-box;
  flex: 1 1 30%;
  //background-color: palegoldenrod;
`;

const ButtonDivIe11Hack = styled.div`
  margin-left: 0.5em;
  box-sizing: border-box;
  flex: 0 0 30%;
  //background-color: pink;
  max-width: 100%;
`;

const EditIcon = styled.div`
  transition: ease-in-out 0.2s;
  :hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const Description = styled.div`
  color: #333333;
  font-family: Lato;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  margin-top: 1em;
  max-height: 415px;
  overflow-y: auto;
`;

class AnnualCycleComp extends React.PureComponent<
  IAnnualCycleProps & InjectedIntlProps,
  IAnnualCycleState
> {
  getSortedTags = memoizeOne((tags?: Array<FieldsOnCalendarRingFragment>) => {
    return tags !== undefined ? _.sortBy(tags, 'calendar.title') : [];
  });
  constructor(props: IAnnualCycleProps & InjectedIntlProps) {
    super(props);
    this.state = {
      direction: (props.direction && props.direction) || WheelDirection.RIGHT,
      selectionMode:
        (props.selectionMode && props.selectionMode) ||
        WheelSelectionMode.MONTH,
      toggleMode: props.toggleMode || WheelToggleMode.REMEMBER,
      year: props.year || new Date().getFullYear(),
      year2: props.year2,
      isCopyYearWheelOpen: false,
      selectedRings: [],
      isEditAnnualCycleFormOpen: false,
      isSmallScreen: false,
      isSuccessMessageActive: false,

      selectedMonths:
        props.selectedMonths ||
        getEmptySelection(
          false,
          props.year || new Date().getFullYear(),
          props.rings || []
        ),
      isSettingsOpen: false,
      startMonth: props.startMonth || '0',
    };
  }

  getSortedCalendarsFromRings = memoizeOne(
    (
      rings?: Array<FieldsOnCalendarRingFragment>,
      magicRing?: FieldsOnCalendarRingFragment
    ) => {
      let calendarsInWheel: Array<FieldsOnCalendarLookupFragment> = [];

      const sortedRings = this.getSortedRings(rings, magicRing);
      sortedRings.map(value => {
        calendarsInWheel.push(value.calendar);
      });
      // console.log('SORT: sortedRings calculated', calendarsInWheel, sortedRings);
      return calendarsInWheel;
    }
  );

  getSortedRings = memoizeOne(
    (
      rings?: Array<FieldsOnCalendarRingFragment>,
      magicRing?: FieldsOnCalendarRingFragment
    ) => {
      if (!rings) {
        return [];
      }

      let temp = rings;
      if (magicRing) {
        temp = rings.slice();
        temp.push(magicRing);
      }
      const sortedRings = _.sortBy(temp, 'order');
      return sortedRings;
    }
  );

  UNSAFE_componentWillReceiveProps(nextProps: IAnnualCycleProps) {
    // direction?: WheelDirection;
    // selectionMode?: WheelSelectionMode;
    // toggleMode?: WheelToggleMode;
    if (nextProps.direction && nextProps.direction !== this.props.direction) {
      this.setState({
        direction: nextProps.direction,
      });
    }

    if (
      nextProps.selectionMode &&
      nextProps.selectionMode !== this.props.selectionMode
    ) {
      this.setState({
        selectionMode: nextProps.selectionMode,
      });
    }
    if (
      nextProps.toggleMode &&
      nextProps.toggleMode !== this.props.toggleMode
    ) {
      this.setState({
        toggleMode: nextProps.toggleMode,
      });
    }
    //
    // if (nextProps.year !== undefined && nextProps.year !== this.state.year) {
    //   this.setState({
    //     year: nextProps.year,
    //     // selectedMonths: getEmptySelection(
    //     //   true,
    //     //   nextProps.year,
    //     //   this.props.rings || []
    //     // ),
    //   });
    // }

    this.setState(
      {
        year: nextProps.year,
        year2: nextProps.year2,
        startMonth: nextProps.startMonth,
      },
      () => {}
    );

    if (
      nextProps.selectedMonths !== undefined &&
      nextProps.selectedMonths !== this.state.selectedMonths
    ) {
      this.setState({
        selectedMonths: nextProps.selectedMonths,
      });
    }
  }

  handleEditCalendarRing = (item: FieldsOnCalendarLookupFragment) => {
    safeInvokeDeprecated(this.props.onEditClick, item.id);
  };

  handleMagicRingClick = (isActive: boolean) => {
    safeInvokeDeprecated(this.props.onMagicRingClick, isActive);
  };

  handleWheelSelectedChanged = (selectedMonths: SelectedMonthsDict) => {
    // console.log('SSX_5_', selectedMonths);

    this.setState(
      {
        selectedMonths: selectedMonths,
      },
      () => {
        safeInvokeDeprecated(
          this.props.onSelectedMonthsChanged,
          this.state.selectedMonths
        );
      }
    );
  };

  handleHoverMonth = (monthIndex: number, month: string, ringId: string) => {
    safeInvokeDeprecated(this.props.onHoveredMonthChanged, {
      monthIndex: monthIndex,
      monthName: month,
      ringId: ringId,
    });
  };

  handleHoverMonthLeave = () => {
    safeInvokeDeprecated(this.props.onHoveredMonthChanged, undefined);
  };

  handleClearSelection = () => {
    this.setState(
      {
        selectedMonths: getEmptySelection(false, -1, this.props.rings || []),
      },
      () => {
        safeInvokeDeprecated(
          this.props.onSelectedMonthsChanged,
          this.state.selectedMonths
        );
      }
    );
  };

  render() {
    const { rings, magicRing, showTags, intl, isMyYearWheel } = this.props;
    const { direction, toggleMode, selectionMode } = this.state;
    const sortedTags = this.getSortedTags(rings);
    const calendarsInWheel = this.getSortedCalendarsFromRings(rings, magicRing);
    const ringsToRenderInWheel = this.getSortedRings(rings, magicRing);

    return (
      <Container>
        {isMyYearWheel && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Title>{intl.formatMessage(commonLabels.myYearWheels)}</Title>
              <IconButtonsDiv>
                <IconButton
                  icon={MunikumIcons.Settings}
                  style={{ fontSize: '.8em' }}
                  hoverStyle={IconButtonHoverStyle.SQUARE}
                  onClick={() => {
                    this.setState({
                      isSettingsOpen: true,
                    });
                  }}
                  tooltip={intl.formatMessage(messages.wheelSettingsTooltip)}
                />

                <IconButton
                  icon={MunikumIcons.ClearFilter}
                  style={{ fontSize: '.8em' }}
                  hoverStyle={IconButtonHoverStyle.SQUARE}
                  onClick={() => this.handleClearSelection()}
                  tooltip={intl.formatMessage(
                    messages.wheelClearSelectionTooltip
                  )}
                />

                <IconButton
                  icon={MunikumIcons.Print}
                  style={{ fontSize: '.8em' }}
                  hoverStyle={IconButtonHoverStyle.SQUARE}
                  onClick={() => safeInvokeDeprecated(this.props.onPrint)}
                  tooltip={intl.formatMessage(messages.printTooltip)}
                />
              </IconButtonsDiv>
            </div>
            <Header id={'ac_header'}>
              <CalendarDiv>
                <MyFollowedCalendarsDropdown
                  showYearWheels={false}
                  selectedItems={calendarsInWheel}
                  onSelected={item =>
                    safeInvokeDeprecated(this.props.onCalendarSelected, item)
                  }
                  onUnselected={item =>
                    safeInvokeDeprecated(this.props.onCalendarUnselected, item)
                  }
                  selectMode={SelectMode.MULTIPLE}
                  onEdit={this.handleEditCalendarRing}
                  fieldGroupStyle={{
                    paddingBottom: 0,
                  }}
                />
              </CalendarDiv>
              <CalendarDiv>
                <CalendarsDropdown
                  disableAssigned={true}
                  showYearWheels={false}
                  selectedItems={calendarsInWheel}
                  onSelected={item =>
                    safeInvokeDeprecated(this.props.onCalendarSelected, item)
                  }
                  onUnselected={item =>
                    safeInvokeDeprecated(this.props.onCalendarUnselected, item)
                  }
                  selectMode={SelectMode.MULTIPLE}
                  onEdit={this.handleEditCalendarRing}
                  onMagicRingClick={(isActive: boolean) => {
                    this.handleMagicRingClick(isActive);
                  }}
                  fieldGroupStyle={{
                    paddingBottom: 0,
                  }}
                />
              </CalendarDiv>

              <ButtonDivIe11Hack>
                <Button
                  style={{ width: '100%' }}
                  text={intl.formatMessage(messages.newAnnualCycle)}
                  onClick={() =>
                    safeInvokeDeprecated(this.props.onClickNewAnnualCycle)
                  }
                />
              </ButtonDivIe11Hack>
            </Header>
          </>
        )}

        {this.props.isCalendarPage && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '1em',
              justifyContent: 'space-between',
            }}
          >
            <Tooltip
              position={'bottom-start'}
              content={
                (this.props.calendar.title && this.props.calendar.title) || ''
              }
            >
              <PageTitle style={{ overflow: 'hidden', marginRight: '.5em' }}>
                {this.props.calendar.title}
              </PageTitle>
            </Tooltip>
            <EditIcon>
              <MunikumIcons.Edit
                style={{ color: '#00B0B9', fontSize: '1.3em' }}
                onClick={() => {
                  this.setState({
                    isEditAnnualCycleFormOpen: true,
                  });
                }}
              />
            </EditIcon>
            <Overlay isOpen={this.state.isEditAnnualCycleFormOpen}>
              <EditAnnualCycleForm
                onDeleteSuccesful={() => {
                  myHistory.push('/calendar/');
                }}
                id={this.props.calendar.id}
                onCancel={() => {
                  this.setState({
                    isEditAnnualCycleFormOpen: false,
                  });
                }}
                onSaveSuccess={() => {
                  this.setState({
                    isSuccessMessageActive: true,
                    isEditAnnualCycleFormOpen: false,
                  });
                }}
                year={this.props.year}
                userHash={this.props.userHash}
              />
            </Overlay>
            <Overlay isOpen={this.state.isSuccessMessageActive}>
              <StatusMessageDialog
                theme={ThemeColor.SUCCESS_BLUE}
                title={intl.formatMessage(commonLabels.success)}
                text={intl.formatMessage(messages.EditAnnualCycleSuccessMsg)}
                buttonText={intl.formatMessage(commonMessages.close)}
                onClickButton={() => {
                  this.setState({
                    isSuccessMessageActive: false,
                  });
                }}
              />
            </Overlay>
          </div>
        )}

        <Wheel
          onSelectedYearChanged={(year: number, year2: number) => {
            safeInvoke(this.props.onSelectedYearChanged, year, year2);
          }}
          showGrid={false}
          year={this.state.year}
          year2={this.state.year2}
          showStateAndProps={false}
          eventInstances={this.props.eventInstances}
          rings={ringsToRenderInWheel}
          selectionMode={selectionMode}
          direction={direction}
          toggleMode={toggleMode}
          onHoverMonth={this.handleHoverMonth}
          onHoverMonthLeave={this.handleHoverMonthLeave}
          startMonth={this.state.startMonth}
          onSelectedChanged={this.handleWheelSelectedChanged}
          selectedMonths={this.state.selectedMonths}
        />
        {/*<BrowserHandler />*/}
        {showTags && (
          <TagsDiv style={{}}>
            {sortedTags.map(
              (ring: FieldsOnCalendarRingFragment, index: number) => (
                <Tag
                  tooltip={true}
                  toolTipMsgLeftIcon={intl.formatMessage(
                    commonMessages.editYearWheelToolTip
                  )}
                  hasShadow={false}
                  key={'tag_asdf_' + index}
                  text={ring.calendar.title}
                  color={ring.color}
                  leftIcon={MunikumIcons.Edit}
                  uppercase={false}
                  canClose={this.props.canEdit || isMyYearWheel}
                  onClickClose={() => {
                    // console.log('todo: remove ring mutation ' + ring.calendar.id);
                    if (ring.id === '0') {
                      this.handleMagicRingClick(false);
                      return;
                    }
                    // this.handleCalenderRingUnChecked(ring.calendar);
                    safeInvokeDeprecated(
                      this.props.onCalendarUnselected,
                      ring.calendar
                    );
                  }}
                  onClickLeftIcon={() => {
                    safeInvokeDeprecated(
                      this.props.onEditClick,
                      ring.calendar.id
                    );
                  }}
                  onClick={() => {
                    // console.log('todo: toggle tag, visible rings');
                  }}
                  style={{
                    marginLeft: '.5em',
                    marginBottom: '.5em',
                    fontSize: '14px',
                    fontWeight: 'normal',
                  }}
                />
              )
            )}
          </TagsDiv>
        )}
        {this.props.isCalendarPage && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '2em',
              }}
            >
              <Title
                style={{ fontSize: '22px', marginTop: '.5em', marginBottom: 0 }}
              >
                {intl.formatMessage(commonMessages.description)}
              </Title>
              {!this.props.isMyYearWheelCalendarPage && (
                <FollowButton2
                  CalendarPageButt={true}
                  textValue={
                    this.props.calendar.isFollowing
                      ? intl.formatMessage(
                          commonMessages.removeFromMyYearWheels
                        )
                      : intl.formatMessage(commonMessages.addToMyYearWheels)
                  }
                  style={{ height: '36px', width: '151px' }}
                  buttonType={ButtType.BUTTON}
                  isFollowing={this.props.calendar.isFollowing}
                  buttonTheme={ColorTheme.Red}
                  followType={MyType.CALENDAR}
                  followId={this.props.calendar && this.props.calendar.id}
                  buttonText={false}
                />
              )}
            </div>
            <Description>
              {!isReallyEmpty(
                (this.props.calendar && this.props.calendar.description) || ''
              ) && <MuPreview rawMarkdown={this.props.calendar.description} />}
              {isReallyEmpty(
                (this.props.calendar && this.props.calendar.description) || ''
              ) && (
                <div style={{ fontStyle: 'italic', opacity: 0.7 }}>
                  {intl.formatMessage(messages.noDescription)}
                </div>
              )}
            </Description>
          </>
        )}

        {/* WHEEL SETTINGS  todo: move inside AnnualCycle.tsx?*/}
        <Dialog
          canEscapeKeyClose={true}
          canOutsideClickClose={true}
          showCloseWarning={false}
          isOpen={this.state.isSettingsOpen}
          title={intl.formatMessage(messages.wheelSettingsTitle)}
          onClose={() =>
            this.setState({
              isSettingsOpen: false,
            })
          }
        >
          <WheelSettings
            settings={{
              selectionMode: this.state.selectionMode,
              toggleMode: this.state.toggleMode,
              direction: this.state.direction,
              enableAnimations: true,
              startMonth: this.state.startMonth,
            }}
            onCancel={() => this.setState({ isSettingsOpen: false })}
            onSave={(settings: IWheelSettings) => {
              this.setState(
                {
                  direction: settings.direction,
                  selectionMode: settings.selectionMode,
                  toggleMode: settings.toggleMode,
                  isSettingsOpen: false,
                  startMonth: settings.startMonth,
                },
                () => {
                  safeInvoke(this.props.onSettingsChange, settings);
                }
              );
            }}
          />
        </Dialog>
      </Container>
    );
  }
}

export const AnnualCycle = injectIntl(AnnualCycleComp);
