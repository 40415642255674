import * as React from 'react';
import { IconType } from 'react-icons/lib';
import { IconBaseProps } from 'react-icons/src/iconBase';

export const MuActionValue: IconType = (props: IconBaseProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 282.8 200.1"
    width={props.size || '1.5em'}
    height={props.size || '1.5em'}
    style={props.style}
  >
    <g id="Layer_1-2">
      <g id="circle">
        <path
          id="b10tyK6eqM"
          d="M19.5 157.9c-4.8 0-8.7 3.9-8.7 8.7 0 4.8 3.9 8.7 8.7 8.7s8.7-3.9 8.7-8.7c0-4.8-3.9-8.7-8.7-8.7z"
          fill={props.fill}
        />
      </g>
      <g id="hand2b">
        <path
          id="e6b3l0teVo"
          d="M253.6 107.7c-5.4 2.7-49.5 23-54.9 25.7-8.3 4.4-15 4.6-33.5 4.6-18.1 0-44.9-4.7-45.2-4.8-2.6-.4-6.2 2.8-6.6 5.5-.4 2.6 1.3 5 3.8 5.5 1.1.2 28.6 4.9 47.4 4.9 19.4 0 29.1 0 39.4-5.3l54.1-26.1c2.1-1.3 4.7-1.1 7.1-.4 2.4.7 3.2 1.6 4.4 3.8 1.3 2.2 1.6 3.3 1 5.7-.7 2.5-2.2 4.3-4.4 5.6-6.3 4.1-56.7 35.9-63 40-.1.1-13.6 7.7-44 7.7-26.5 0-65.1-22.3-65.7-22.6-.6-.3-10.5-5.4-30.1-5.4-2.6 0-6.1 4-6.1 6.7s2.1 4.9 4.7 4.9c17 0 25.7 4.2 25.7 4.2 1.8 1 43.4 25 72.3 25 33.7 0 48.6-10.1 49.1-10.4 6.3-4.1 56.6-36.7 62.9-40.8 8.9-5.5 11.8-17.5 6.6-26.7-5.1-9-16.1-12.2-25-7.3z"
          fill={props.fill}
        />
      </g>
      <g id="also_hand1">
        <path
          id="d1OpghHLEG"
          d="M99.2 95c5.4 0 12.9 6.4 21.1 13 10.2 8.3 22.1 16.9 30.6 16.9h28.5c7.1 0 9.9 2.3 10 2.3.9.9 2 1.5 3.3 1.5 1.2 0 2.4-.5 3.3-1.5 1.8-2 1.8-5.2 0-7.2-.6-.7-6.3-6.5-17.3-6.5h-28c-5.4 0-14.7-7.6-22.9-14.2-10.2-8.3-20-16.2-28.5-16.2H62.1c-2.6 0-4.7 2.3-4.7 5.1s3 6.9 5.6 6.9h36.2z"
          fill={props.fill}
        />
      </g>
      <g id="also_sleeve">
        <path
          id="a383J1OMuY"
          d="M6.4 68c-2.7.1-5 3.9-4.8 6.5.1 2.4 1.8 4.9 4.4 5l29.5-.2c2.7 0 3.6.9 3.6 3.4v100.1c0 2.6-.5 3.8-3.3 3.8H6.5c-2.7.1-4.7 3.7-4.6 6.3.1 2.4 1.9 5.5 4.4 5.6H36c8.2 0 14.9-6.3 14.9-14V82c0-7.7-6.7-14-14.9-14H6.4z"
          fill={props.fill}
        />
      </g>

      <path
        id="stack3"
        d="M252.9 1.4c4.8 0 8.7 3.9 8.8 8.8v1.1c0 4.8-3.9 8.7-8.7 8.7H139.2c-4.8 0-8.8-3.9-8.8-8.8v-1.1c0-4.8 3.9-8.7 8.8-8.8l113.7.1z"
        fill={props.fill}
      />
      <path
        id="stack2"
        d="M252.9 64.5c4.8 0 8.7 3.9 8.8 8.7v1.1c0 4.8-3.9 8.7-8.8 8.8H139.2c-4.8 0-8.7-3.9-8.8-8.8v-1.1c0-4.8 3.9-8.8 8.8-8.8h113.7z"
        fill={props.fill}
      />
      <path
        id="stack1"
        d="M252.9 33.3c4.8 0 8.7 3.9 8.8 8.7v1.1c0 4.8-3.9 8.8-8.8 8.8H139.2c-4.8 0-8.8-3.9-8.8-8.8V42c0-4.8 3.9-8.8 8.8-8.8l113.7.1z"
        fill={props.fill}
      />
    </g>
  </svg>
);
