// import IoIosFlask from 'react-icons/lib/io/ios-flask';
// import IoIosBasketball from 'react-icons/lib/io/ios-basketball';
// import IoIosPerson from 'react-icons/lib/io/ios-person';
// import GoOrganization from 'react-icons/lib/go/organization';
// import MdDateRange from 'react-icons/lib/md/date-range';
// import MdAccessTime from 'react-icons/lib/md/access-time';
// import MdArrowDropDown from 'react-icons/lib/md/arrow-drop-down';
// import MdSave from 'react-icons/lib/md/save';
// import FaCloudUpload from 'react-icons/lib/fa/cloud-upload';
// import MdDone from 'react-icons/lib/md/done';
// import MdCancel from 'react-icons/lib/md/cancel';
// import MdHome from 'react-icons/lib/md/home';
// import MdChatBubbleOutline from 'react-icons/lib/md/chat-bubble-outline';
// import MdSettings from 'react-icons/lib/md/settings';
// import MdInfo from 'react-icons/lib/md/info';
// import MdNotifications from 'react-icons/lib/md/notifications';
// import MdEdit from 'react-icons/lib/md/edit';
// import MdDelete from 'react-icons/lib/md/delete';
// import MdAdd from 'react-icons/lib/md/add';
// import MdShare from 'react-icons/lib/md/share';
// import MdArrowBack from 'react-icons/lib/md/arrow-back';
// import MdMoreHoriz from 'react-icons/lib/md/more-horiz';
// import MdSearch from 'react-icons/lib/md/search';
// import MdSend from 'react-icons/lib/md/send';
// import MdPublish from 'react-icons/lib/md/publish';
// import MdFormatBold from 'react-icons/lib/md/format-bold';
// import MdFormatItalic from 'react-icons/lib/md/format-italic';
// import MdFormatUnderlined from 'react-icons/lib/md/format-underlined';
// import MdFormatClear from 'react-icons/lib/md/format-clear';
// import MdKeyboardArrowRight from 'react-icons/lib/md/keyboard-arrow-right';
// import MdLock from 'react-icons/lib/md/lock';
// import MdLink from 'react-icons/lib/md/link';
// import MdInsertDriveFile from 'react-icons/lib/md/insert-drive-file';
// import MdFileDownload from 'react-icons/lib/md/file-download';
// import MdKeyboardArrowDown from 'react-icons/lib/md/keyboard-arrow-down';
// import MdStar from 'react-icons/lib/md/star';
// import MdStarBorder from 'react-icons/lib/md/star-border';
// import MdStarOutline from 'react-icons/lib/md/star-outline';
// import FaQuestionCircle from 'react-icons/lib/fa/question-circle';
// import MdFolder from 'react-icons/lib/md/folder';
// import { FaCalendarCheckO, FaPieChart } from 'react-icons/lib/fa';
// import FaSignOut from 'react-icons/lib/fa/sign-out';
// import FaExchange from 'react-icons/lib/fa/exchange';
// import FaList from 'react-icons/lib/fa/list';
// import FaThLarge from 'react-icons/lib/fa/th-large';
// import MdKeyboardArrowUp from 'react-icons/lib/md/keyboard-arrow-up';
// import MdKeyboardArrowLeft from 'react-icons/lib/md/keyboard-arrow-left';
// import FaLeaf from 'react-icons/lib/fa/leaf';
// import FaMapO from 'react-icons/lib/fa/map-o';
// import FaTree from 'react-icons/lib/fa/tree';
// import FaFlag from 'react-icons/lib/fa/flag';
// import MdCheck from 'react-icons/lib/md/check';
// import IoIosCircleFilled from 'react-icons/lib/io/ios-circle-filled';
// import FaCircle from 'react-icons/lib/fa/circle';
// import FaCircleO from 'react-icons/lib/fa/circle-o';
// import FaDotCircleO from 'react-icons/lib/fa/dot-circle-o';
// import FaFolderO from 'react-icons/lib/fa/folder-o';
// import FaFileTextO from 'react-icons/lib/fa/file-text-o';
// import FaClockO from 'react-icons/lib/fa/clock-o';
// import FaRepeat from 'react-icons/lib/fa/repeat';
// import TiGroup from 'react-icons/lib/ti/group';
// import MdContentCopy from 'react-icons/lib/md/content-copy';
// import MdClose from 'react-icons/lib/md/close';
// import MdInfoOutline from 'react-icons/lib/md/info-outline';
// import MdPrint from 'react-icons/lib/md/print';
// import FaLightbulbO from 'react-icons/lib/md/lightbulb-outline';
// import FaCheck from 'react-icons/lib/fa/check';
import { MuActionValue } from './customIcons/MuActionValue';
import { MuClearFilter } from './customIcons/MuClearFilter';
import {
  IoIosBasketball,
  IoIosFlask,
  IoIosPerson,
  IoMdGlobe,
} from 'react-icons/io';
import {
  MdAccessTime,
  MdAdd,
  MdArrowBack,
  MdArrowDropDown,
  MdCancel,
  MdChatBubbleOutline,
  MdCheck,
  MdClose,
  MdContentCopy,
  MdDateRange,
  MdDelete,
  MdDone,
  MdEdit,
  MdFileDownload,
  MdFolder,
  MdFormatBold,
  MdFormatClear,
  MdFormatItalic,
  MdFormatUnderlined,
  MdHome,
  MdInfo,
  MdInfoOutline,
  MdInsertDriveFile,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdLink,
  MdLock,
  MdMoreHoriz,
  MdNotifications,
  MdPrint,
  MdPublish,
  MdSave,
  MdSearch,
  MdSend,
  MdSettings,
  MdShare,
  MdStar,
  MdStarBorder,
} from 'react-icons/md';
import { GoOrganization } from 'react-icons/go';
import {
  FaBriefcase,
  FaCalendarAlt,
  FaChartPie,
  FaCheck,
  FaCircle,
  FaClock,
  FaCloudUploadAlt,
  FaExchangeAlt,
  FaExclamationTriangle,
  FaEye,
  FaEyeSlash,
  FaFileAlt,
  FaFileImage,
  FaFlag,
  FaFolder,
  FaHospital,
  FaLeaf,
  FaLightbulb,
  FaList,
  FaMap,
  FaPaperclip,
  FaQuestionCircle,
  FaRedoAlt,
  FaRegCalendarPlus,
  FaRegFileExcel,
  FaSignOutAlt,
  FaTheaterMasks,
  FaThLarge,
  FaToolbox,
  FaTree,
  FaUniversity,
} from 'react-icons/fa';
import { TiGroup } from 'react-icons/ti';
import { MuClap } from './customIcons/MuClap';

/**
 * Icons used in Munikum
 * https://andy-pro.github.io/icon-viewer/
 */
export const MunikumIcons = {
  Default: IoIosBasketball,
  Home: MdHome,
  ActionValue: MuActionValue, // GoFileText,
  Discussion: MdChatBubbleOutline,
  Admin: MdSettings,
  Labs: IoIosFlask,
  About: MdInfo,
  MyProfile: IoIosPerson,
  Organization: GoOrganization,
  Alert: MdNotifications,

  Favourite: MdStarBorder,
  FavouriteActive: MdStar,

  Date: MdDateRange,
  Time: MdAccessTime,
  Dropdown: MdArrowDropDown,
  Save: MdSave,
  FileUpload: FaCloudUploadAlt,
  Ok: MdDone,
  Cancel: MdCancel,
  Edit: MdEdit,
  Delete: MdDelete,
  Add: MdAdd,
  Share: MdShare,
  Back: MdArrowBack,
  More: MdMoreHoriz,
  Duplicate: MdAdd,
  Search: MdSearch,
  Answer: MdSend,
  Resorces: MdFolder,
  Publish: MdPublish,
  ArrowRight: MdKeyboardArrowRight,
  ArrowDown: MdKeyboardArrowDown,
  ArrowUp: MdKeyboardArrowUp,
  ArrowLeft: MdKeyboardArrowLeft,
  AddTo: MdAdd,
  FormatBold: MdFormatBold,
  FormatItalic: MdFormatItalic,
  FormatUnderline: MdFormatUnderlined,
  Trash: MdFormatClear,
  Topic: TiGroup,
  Link: MdLink,
  File: MdInsertDriveFile,
  Download: MdFileDownload,
  Help: FaQuestionCircle,
  YearWheel: FaCalendarAlt,
  municipality: FaChartPie,
  copy: MdContentCopy,

  ImageFile: FaFileImage,
  Subscribe: FaRegCalendarPlus,
  Export: FaRegFileExcel,
  Padlock: MdLock,
  Star: MdStar,
  StarBorder: MdStarBorder,
  StarOutline: MdStarBorder,
  SignOut: FaSignOutAlt,
  WheelSwap: FaExchangeAlt,
  WheelList: FaList,
  Flag: FaFlag,
  lightbulb: FaLightbulb,
  Folder: FaFolder,
  SingleTask: FaFileAlt,
  Globe: IoMdGlobe,
  WheelSquares: FaThLarge,
  eyeSlash: FaEyeSlash,
  eye: FaEye,
  paperclip: FaPaperclip,
  WheelSelectionModeMonth: FaChartPie,
  WheelSelectionModePie: FaLeaf,
  warning: FaExclamationTriangle,
  WheelToggleModeRemember: FaTree,
  WheelToggleModeCtrl: FaMap,

  Settings: MdSettings,
  LeftCheck: MdCheck,
  Close: MdClose,
  Circle1: FaCircle,
  // Circle2: IoIosCircle,
  // Circle3: FaCircleO,
  // Circle4: FaDotCircleO,

  Reminder: FaClock,
  Repeat: FaRedoAlt,
  ClearFilter: MuClearFilter,
  Info: MdInfo,
  InfoOutline: MdInfoOutline,

  Print: MdPrint,
  Check: FaCheck,
  Clap: MuClap,

  KostraAdmin: FaBriefcase,
  KostraEducation: FaUniversity,
  KostraHealth: FaHospital,
  KostraTechnical: FaToolbox,
  KostraPlan: FaFolder,
  KostraCulture: FaTheaterMasks,
  KostraOther: FaCircle,
};
