import * as React from 'react';
import styled from 'styled-components';
import { CSSProperties, Suspense } from 'react';
import { SuspenseLoader } from '../components/basic/Loader/CircleSpinner';
import { Colors } from '../components/common';

export class EmptyLayout extends React.PureComponent<{
  style?: CSSProperties;
}> {
  render() {
    const Comp = styled.div`
      //background-color: pink;
      background-color: ${Colors.WHITEBACKGROUND};

      @media print {
        width: 100%;
        margin: 0;
        float: none;
        overflow: visible;
        background-color: #fff;
      }
    `;
    return (
      <Comp style={this.props.style}>
        <Suspense fallback={<SuspenseLoader />}>{this.props.children}</Suspense>
      </Comp>
    );
  }
}
