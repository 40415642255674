import * as _ from 'lodash';
import { createSelector } from 'reselect';
import { call, put, take } from 'redux-saga/effects';
import {
  IRevokeRefreshTokenForm,
  revokeToken,
} from '../../services/refreshTokenService';
import { getRefreshTokensActionCreator } from '../entities/entities';
import { IRefreshToken } from '../../services/models/refreshToken';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { RootState } from '../rootReducer';
const actionCreator = actionCreatorFactory('REFRESH_TOKEN');

const refreshTokenListTransformer = (dict: {
  [byId: string]: Readonly<IRefreshToken>;
}) => _.sortBy(_.values(dict), 'lastUsed');

export const refreshTokenListSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IRefreshToken> },
  ReadonlyArray<IRefreshToken>
>(
  state => state.entities.refreshTokens.byId,
  refreshTokenListTransformer
);

export const revokeRefreshTokenActionCreator = actionCreator<
  IRevokeRefreshTokenForm
>('REVOKE_REFRESH_TOKEN');

export function* revokeRefreshTokenSaga() {
  while (true) {
    const action = yield take(revokeRefreshTokenActionCreator.type);

    try {
      const result = yield call(revokeToken, action.payload);
      console.log('send ok:' + result);
      if (!result) {
        throw Error('send failed');
      }
      yield put(getRefreshTokensActionCreator.started({}));
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }
      console.log('error sending:', tempError, e);
    }
  }
}
