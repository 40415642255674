import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { UserWizard } from '../../../components/person/UserWizard/UserWizard';
import { myHistory } from '../../../index';

const background = require('./background.svg') as string;

const WelcomeUserPageWrapper = styled.div`
  background-image: url(${background});
  background-size: cover;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
`;

const WelcomeWizardPage: React.FunctionComponent<{}> = props => {
  return (
    <WelcomeUserPageWrapper>
      <Helmet>
        <title>Velkommen</title>
      </Helmet>
      <UserWizard
        onWizardCompleted={(data: ReadonlyArray<any>) => {
          myHistory.push('/');
        }}
      />
    </WelcomeUserPageWrapper>
  );
};

export default WelcomeWizardPage;
