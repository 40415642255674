import moment from 'moment';

// tslint:disable-next-line
export function isFunction(value: any): value is Function {
  if (value === undefined) {
    return false;
  }
  return typeof value === 'function';
}

/***
 * @deprecated don't use this, use the new safeInvoke below which supports static typing!
 * @param func
 * @param args
 */
export function safeInvokeDeprecated(
  func: Function | undefined,
  ...args: any[]
) {
  if (isFunction(func)) {
    return func(...args);
  }
}

/**
 * Awesome TypeScript PORN!
 * safeInvoke with static typing for X arguments, automatically inferred from function! :-)
 * This wasn't possible in TypeScript < 3.0..
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-0.html#rest-parameters-with-tuple-types
 *
 *
 * Example function:
 * const myFunc = (arg1: string, arg2: number): string => { return arg1 + ' ' + arg2.toString(); }
 *
 * Usage (inferred):
 * safeInvoke(myFunc, 'my age is', 36);
 *
 * Usage (explicit specifying argument types in array, and return type):
 * safeInvoke<[string, number], string>(myFunc, 'my age is', 36);
 *
 * @param func - the function to invoke
 * @param args - arguments
 */
export function safeInvoke<Args extends any[], R = void>(
  func: ((...args: Args) => R) | undefined,
  ...args: Args
) {
  if (isFunction(func)) {
    return func(...args);
  } else {
    return undefined;
  }
}

export async function sleep(ms: number) {
  return await new Promise(resolve => setTimeout(resolve, ms));
}

export function makeid(length: number) {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 5; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}

// export namespace EnumEx {
export function getNamesAndValues<T extends number>(e: {}) {
  return getNames(e).map(n => ({ name: n, value: e[n] as T }));
}

export function getNames(e: {}) {
  return getObjValues(e).filter(v => typeof v === 'string') as string[];
}

export function getValues<T extends number>(e: {}) {
  return getObjValues(e).filter(v => typeof v === 'number') as T[];
}

function getObjValues(e: {}): (number | string)[] {
  return Object.keys(e).map(k => e[k]);
}
// }

// export const EnumEx = {
//   getNames,
//   getValues,
//   getObjValues
// };

export function capitalizeFirstLetter(p: string) {
  return p.charAt(0).toUpperCase() + p.slice(1);
}

/**
 * Use this to format DATE + TIME in Munikum?
 * NO: 21.06.18 kl. 15:26
 * EN: 21/06/18 15:26
 *
 * @param {Date | moment.Moment} date
 * @returns {string}
 */
export function formatMunikumDate(
  date: Date | moment.Moment | string | undefined
): string {
  if (date === undefined) {
    return '';
  }

  const m =
    typeof date === 'string' ? moment.utc(date).local() : moment(date).local();

  const time = m.format('LT'); // locale dependent hours!
  const dp = m.format('L');

  const temp = m.format('DD.MM.YY') + ' kl. ' + m.format('HH:mm');
  const tempen = dp + ' ' + time;

  const locale = moment.locale();

  // console.log('test', locale, temp, tempen);

  if (locale.toLowerCase().indexOf('en') >= 0) {
    return tempen;
  }

  return temp;
}

// reusable typesript Omit
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function isReallyEmpty(text: string): boolean {
  if (text === null) {
    return true;
  } else {
    const myString = text
      .replace('\u21B5', '')
      .replace('\u200B', '')
      .trim();

    if (myString === '' || myString.length === 0) {
      return true;
    }

    return false;
  }
}
