import * as React from 'react';
import styled from 'styled-components';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { commonMessages } from '../../language/commonMessages';
import {
  Colors,
  ColorTheme,
  removeNonHTMLProps,
  safeInvokeDeprecated,
} from '../../common';
import { Overlay } from '../Overlay/Overlay';
import { Button } from '..';
import { FaTimes } from 'react-icons/fa';

export const WizardTitle = styled.h2`
  color: ${Colors.BLACK};
  font-family: 'Lato', sans-serif;
  line-height: 1.4em;
  font-size: 1.375em;
  font-weight: bold;
  margin: 0 1.5em 0 0;
  //background-color: pink;
`;

// IE11 BUG fux:
// https://stackoverflow.com/questions/44700068/flexbox-min-height-and-ie11
const OuterIE11Hack = styled.div`
  display: flex;
`;

const WizardContainer = styled.div`
  flex-grow: 1;

  //height: 100%;

  //min-width: 31.25em;
  min-height: 15.625em;

  position: static !important;
  transform: none;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 1.875em;
  border-radius: 3px;

  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  user-select: text;

  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  animation: opacity 2s ease;

  background-color: ${Colors.WHITEBACKGROUND};
  box-sizing: border-box;
`;

const Left = styled.div`
  background-color: rgba(208, 211, 212, 0.4);
  border-radius: 3px 0 0 3px;
  padding-left: 1.5em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;

  flex: 0 0 240px;

  min-height: 100%;
  height: 100%;

  box-sizing: border-box;
`;
const Right = styled.div`
  //background-color: #c2e0ff;
  border-radius: 0 3px 3px 0;

  border-left: 1px solid ${Colors.GREY};

  padding-top: 1.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 0.625em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  //height: 464px;
  flex: 1 1 auto;

  min-height: 100%;
  height: 100%;

  //background-color: orange;

  box-sizing: border-box;

  overflow: hidden;
`;

const StepContainer = styled.div`
  //background-color: pink;
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
`;

const StepHeaderContainer = styled.div`
  flex: 0 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  //background-color: orange;

  margin-bottom: 2em;
`;
const StepTitle = styled.div`
  color: ${Colors.BLACK};
  font-family: 'Lato', sans-serif;
  line-height: 1.2em;
  font-size: 1.125em;
  font-weight: bold;

  margin: -0.25em 0 0 0;

  //background-color: pink;
`;

const StepContent = styled.div`
  flex: 1 1 auto;

  color: rgba(51, 51, 51, 0.7);
  font-family: Lato, sans-serif;
  font-size: 0.875em;
  line-height: 1.5em;

  display: flex;
  flex-direction: column;
`;

const NavigationContainer = styled.div`
  //background-color: lightskyblue;

  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;

  flex: 0 0 auto;
  margin-top: 1em;
`;

const NavListContainer = styled.div`
  //background-color: pink;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 1em;
`;

const NavListStep = styled.div`
  border-left: 4px solid
    ${(props: { isRed: boolean }) =>
      props.isRed ? Colors.RED : Colors.GREYBORDER};
`;

const NavListStepInner = styled.div`
  background-color: ${(props: { isActive: boolean }) =>
    props.isActive ? '#d0d3d4' : 'none'};
  border-radius: 3px 0 0 3px;

  margin-left: 0.25em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;

  display: flex;

  cursor: pointer;
`;

const NavListStepNum = styled.div`
  color: rgba(51, 51, 51, 0.7);
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.2em;
  padding: 1em;
`;
const NavListStepText = styled.div`
  color: rgba(51, 51, 51, 0.7);
  font-family: 'Lato', sans-serif;
  font-size: 0.875em;
  line-height: 1.7em;

  margin-top: 0.25em;

  padding-left: 0;
  padding-top: 0.75em;
  padding-right: 0.75em;
  padding-bottom: 0.75em;

  //background-color: #D0D3D4;
`;

export interface IStepRenderProps {
  /**
   * handler to accept navigation to next and (optionally) store values in wizard component
   * this should only called with VALID data, Wizard assumes that the step is VALID if you call this
   * @param values to bes saved by wizard
   */
  // tslint:disable-next-line
  handleNavigateNextAcceptedAndSubmitStep: (values: any) => void;

  /**
   * handler to accept navigation to previous and (optionally) store  values in wizard component
   * this can be called with INVALID data, wizard does not alter valid state when calling this
   * @param values
   */
  // tslint:disable-next-line
  handleNavigatePreviousAcceptedAndSubmitStep: (values: any) => void;

  /**
   * NOTE: removed this until we need it, not sure if it works 100%
   * handler to store values in wizard component on change
   * NOTE: this could also be INVALID/INCOMPLETE data
   * @param values
   */
  // handleChangeStep: (values: any) => void;

  /**
   * explicitly say that a step is valid or not. This is stored in wizard state, and wizard cannot advance if
   * step is not valid
   * @param {boolean} isValid
   */
  handleValidateStep: (isValid: boolean) => void;

  /**
   * true if current step is valid (wizard decides)
   * if you want to set step to valid/invalid, use handleValidateStep
   */
  isStepValid: boolean;

  /**
   * stored data for current step
   */
  // tslint:disable-next-line
  stepData?: any;
}

export type StepRenderFunc = (
  props: IStepRenderProps
) => JSX.Element | null | false;

export interface IWizardStep<TValues> {
  /**
   * Step's title, text in top right corner
   */
  title: string;

  /**
   * the step's render function, render your step here
   */
  content: StepRenderFunc;

  /**
   * This is called by Wizard on init, return your step's initial data here.
   */
  // tslint:disable-next-line
  initialData?: any;

  /**
   * is the step valid initially? default = true.
   * Set this to false if you want to block navigation to next step until you call handleValidateStep in
   * steps content-render-func
   */
  isInitialValid?: boolean;

  /**
   * If your step wants to control navigation, this is called when Next button is clicked.
   */
  navigateNextRequested?: () => void;

  /**
   * If your step wants to control navigation, this is called when Prev button is clicked
   */
  navigatePreviousRequested?: () => void;
}

export interface IWizardProps {
  /**
   * Is Wizard visible or not (for overlay)
   */
  isOpen: boolean;

  /**
   * Wizard's title, top left corner
   */
  title: string;

  showCloseButton?: boolean;

  showCancelButton?: boolean;

  isCloseDisabled?: boolean;

  onCancel?: () => void;

  /**
   * Pass your Wizard's steps here. Each step has a render function with render-props
   */
  // tslint:disable-next-line
  steps: ReadonlyArray<IWizardStep<any>>;

  /**
   * Dispatched when wizard is completed. If data is stored in wizard using handleNavigateAcceptedAndSubmitStep,
   * data for all steps are returned here
   * @param {ReadonlyArray<any>} stepsData
   */
  // tslint:disable-next-line
  onCompleted?: (stepsData: ReadonlyArray<any>) => void;

  /**
   * event AFTER wizard has completed navigating to next step
   * If you need to handle navigation in a step, use navigateNextRequested
   */
  onNavigatedNext?: () => void;

  /**
   * event AFTER wizard navigated to previous step
   */
  onNavigatedBack?: () => void;

  /**
   * event AFTER wizard navigated to a step
   * @param {number} stepIndex - 0 based index
   */
  onNavigated?: (stepIndex: number) => void;
}

export interface IWizardState {
  currentStep: number;
  // tslint:disable-next-line
  stepsData: ReadonlyArray<any>;
  stepsValid: ReadonlyArray<boolean>;
  isCompleted: boolean;
}

/**
 * Wizard
 * Future improvements:
 * - disable navigation on left pane if invalid steps before current step
 * - allow step to control navigation back
 * - add support for controlled components in step (handleUpdateData)
 */
class WizardComp extends React.PureComponent<
  IWizardProps & InjectedIntlProps & React.HTMLProps<HTMLDivElement>,
  IWizardState
> {
  constructor(
    props: IWizardProps & InjectedIntlProps & React.HTMLProps<HTMLDivElement>
  ) {
    super(props);
    // console.log('CTOR Wizard');

    this.state = {
      currentStep: 0,
      isCompleted: false,
      stepsData: props.steps.map((step, index) =>
        step.initialData !== undefined
          ? Object.assign({}, step.initialData)
          : undefined
      ),
      stepsValid: props.steps.map((step, index) =>
        step.isInitialValid !== undefined ? step.isInitialValid : true
      ),
    };
  }

  navigateNextOrComplete = () => {
    const currStep = this.state.currentStep;
    const stepsLength = this.props.steps.length;

    if (currStep === stepsLength - 1) {
      // last step, dispatch confirm event..
      const allData = [...this.state.stepsData];
      safeInvokeDeprecated(this.props.onCompleted, allData);
    } else if (currStep < stepsLength) {
      const next = currStep + 1;
      this.setState(
        {
          ...this.state,
          currentStep: next,
        },
        () => {
          safeInvokeDeprecated(this.props.onNavigatedNext);
          safeInvokeDeprecated(this.props.onNavigated, next);
        }
      );
    } else {
      console.warn('this is the end, cannot navigate over the edge..');
    }
  };

  navigatePrevious = () => {
    const currStep = this.state.currentStep;
    if (currStep > 0) {
      const prev = this.state.currentStep - 1;
      this.setState(
        {
          ...this.state,
          currentStep: prev,
        },
        () => {
          safeInvokeDeprecated(this.props.onNavigatedBack);
          safeInvokeDeprecated(this.props.onNavigated, prev);
        }
      );
    } else {
      console.warn('cannot navigate back, currStep is ' + currStep);
    }
  };

  /**
   * handle when user clicks Next button in Wizard
   * navigation is controlled by Wizard or by the step itself
   */
  handleNextClicked = () => {
    const currStep = this.state.currentStep;
    const stepsLength = this.props.steps.length;

    if (currStep < stepsLength) {
      const myStep = this.props.steps[currStep];

      if (myStep.navigateNextRequested) {
        // we have a step that controls navigation to NEXT
        safeInvokeDeprecated(myStep.navigateNextRequested);
      } else {
        // wizard controls navigation NEXT
        this.navigateNextOrComplete();
      }
    }
  };

  /**
   * handle when user clicks Previous button in Wizard
   * navigation is controlled by Wizard or by the step itself
   */
  handlePreviousClicked = () => {
    if (this.state.currentStep > 0) {
      const currStep = this.state.currentStep;

      if (currStep > 0) {
        // we can move back
        const myStep = this.props.steps[currStep];

        if (myStep.navigatePreviousRequested) {
          // we have a step that controls navigation BACK
          // console.log('Previous clicked, asking step to confirm navigate BACK');
          safeInvokeDeprecated(myStep.navigatePreviousRequested);
        } else {
          // wizard controls navigation BACK
          this.navigatePrevious();
        }
      }
    }
  };

  /**
   * handle when a step controls navigation, and accepts that we move to NEXT
   * and possibly submits data. current step is set as valid!
   * @param {IWizardStep<any>} step
   * @param {number} index
   * @param values
   */
  handleNavigateNextAcceptedAndSubmitStep = (
    // tslint:disable-next-line
    step: IWizardStep<any>,
    index: number,
    // tslint:disable-next-line
    values?: any
  ) => {
    // 1. set values
    if (values !== undefined) {
      const tempData = [...this.state.stepsData];
      tempData[index] = Object.assign({}, values);

      const tempValid = [...this.state.stepsValid];
      tempValid[index] = true; // submit === valid step!!

      this.setState(
        {
          ...this.state,
          stepsData: tempData,
          stepsValid: tempValid,
        },
        () => {
          // 2. navigate NEXT
          // console.log('NEXT callback after setting submitted data', this.state);
          this.navigateNextOrComplete(); // navigate AFTER data is stored
        }
      );
    } else {
      // no values stored, still need to navigate:
      this.navigateNextOrComplete();
    }
  };

  /**
   * handle when a step controls navigation, and accepts that we move to PREVIOUS
   * and possibly submits data. curren step valid is not set!
   * @param {IWizardStep<any>} step
   * @param {number} index
   * @param values
   */
  handleNavigatePreviousAcceptedAndSubmitStep = (
    // tslint:disable-next-line
    step: IWizardStep<any>,
    index: number,
    // tslint:disable-next-line
    values?: any
  ) => {
    // 1. set values
    if (values !== undefined) {
      const tempData = [...this.state.stepsData];
      tempData[index] = Object.assign({}, values);

      const tempValid = [...this.state.stepsValid];
      // NOTE: navigating BACK doesn't mean the step is valid!!
      // tempValid[index] = true; // controlled

      this.setState(
        {
          ...this.state,
          stepsData: tempData,
        },
        () => {
          // 2. navigate BACK
          // console.log('BACK callback after setting submitted data', this.state);
          this.navigatePrevious();
        }
      );
    }
  };

  handleIsValidStep = (
    // tslint:disable-next-line
    step: IWizardStep<any>,
    index: number,
    isValid: boolean
  ) => {
    // console.log(
    //   'wizard.handleIsValidStep. Step-index: ' + index + ' isValid: ' + isValid
    // );

    const temp = [...this.state.stepsValid];
    temp[index] = isValid;

    this.setState(
      {
        ...this.state,
        stepsValid: temp,
      },
      () => {
        // console.log('state after setValid:', this.state);
      }
    );
  };

  /**
   * Handle when user clicks Cancel
   */
  handleCancelClicked = () => {
    safeInvokeDeprecated(this.props.onCancel);
  };

  /**
   * Handle when user navigates using steps on the left
   * TODO: we have to check if step is valid before navigating...
   * @param {IWizardStep<any>} step
   * @param {number} index
   */
  // tslint:disable-next-line
  handleOnClickStep = (step: IWizardStep<any>, index: number) => {
    this.setState(
      {
        ...this.state,
        currentStep: index,
      },
      () => {
        safeInvokeDeprecated(this.props.onNavigated, index);
      }
    );
  };

  /**
   * Render
   * All steps are rendered so we don't loose data, but only active step is visible using css
   * @returns {any}
   */
  render() {
    const {
      isOpen,
      title,
      showCloseButton,
      showCancelButton,
      steps,
      intl,
      onCompleted, // NOTE: don't remove this, we need
      ...rest
    } = this.props;
    const { currentStep, stepsData, stepsValid } = this.state;

    // console.log('wizard.render', this.state);

    const myStep = this.props.steps[currentStep];
    const isCurrentStepValid = this.state.stepsValid[currentStep];
    // const currentStepData = this.state.stepsData[currentStep];

    const isPrevEnabled = currentStep > 0;
    const isNextEnabled = currentStep < steps.length + 1 && isCurrentStepValid;
    const isLastStep = currentStep === steps.length - 1;

    const nextOrCompleteText = isLastStep
      ? intl.formatMessage(commonMessages.confirm)
      : intl.formatMessage(commonMessages.next);

    return (
      <Overlay isOpen={isOpen}>
        <OuterIE11Hack>
          <WizardContainer isOpen={isOpen} {...removeNonHTMLProps(rest)}>
            <Left>
              {title && <WizardTitle>{title}</WizardTitle>}
              {steps && (
                <NavListContainer>
                  {steps.map((step, index) => (
                    <NavListStep
                      isRed={index <= currentStep}
                      key={'wn_' + index}
                    >
                      <NavListStepInner
                        isActive={currentStep === index}
                        onClick={() => this.handleOnClickStep(step, index)}
                      >
                        <NavListStepNum>{index + 1}</NavListStepNum>
                        <NavListStepText>{step.title}</NavListStepText>
                      </NavListStepInner>
                    </NavListStep>
                  ))}
                </NavListContainer>
              )}
            </Left>
            <Right>
              <StepContainer>
                <StepHeaderContainer>
                  <WizardTitle>{myStep.title}</WizardTitle>

                  {showCloseButton && (
                    <div>
                      <FaTimes
                        fill={Colors.BLACK}
                        width={'2.5em'}
                        height={'2.5em'}
                        onClick={this.handleCancelClicked}
                        cursor={'pointer'}
                      />
                    </div>
                  )}
                </StepHeaderContainer>
                <StepContent>
                  {steps.map((step, index) => {
                    const isStepVisible = index === currentStep;

                    return (
                      <div
                        style={{
                          display: isStepVisible ? 'flex' : 'none',
                          flexDirection: 'column',
                          flex: '1 1 auto',
                        }}
                        key={'smallstepgiantleap' + index}
                      >
                        {step.content({
                          handleNavigateNextAcceptedAndSubmitStep: (
                            // tslint:disable-next-line
                            values: any
                          ) =>
                            this.handleNavigateNextAcceptedAndSubmitStep(
                              step,
                              index,
                              values
                            ),
                          handleNavigatePreviousAcceptedAndSubmitStep: (
                            // tslint:disable-next-line
                            values: any
                          ) =>
                            this.handleNavigatePreviousAcceptedAndSubmitStep(
                              step,
                              index,
                              values
                            ),
                          handleValidateStep: isValid =>
                            this.handleIsValidStep(step, index, isValid),
                          isStepValid: stepsValid[index],
                          stepData: stepsData[index],
                        })}
                      </div>
                    );
                  })}
                </StepContent>
              </StepContainer>

              <NavigationContainer>
                <Button
                  text={intl.formatMessage(commonMessages.previous)}
                  // style={{ width: '6em' }}
                  theme={ColorTheme.White}
                  onClick={this.handlePreviousClicked}
                  disabled={!isPrevEnabled}
                />
                <Button
                  text={nextOrCompleteText}
                  style={{ marginLeft: '1em' }}
                  theme={ColorTheme.Red}
                  onClick={this.handleNextClicked}
                  disabled={!isNextEnabled}
                />
                {showCancelButton && (
                  <Button
                    text={'Cancel'}
                    style={{ marginLeft: '1em' }}
                    onClick={this.handleCancelClicked}
                  />
                )}
              </NavigationContainer>
            </Right>
          </WizardContainer>
        </OuterIE11Hack>
      </Overlay>
    );
  }
}

export const Wizard = injectIntl(WizardComp);
