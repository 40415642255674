import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import gql from 'graphql-tag';
import Mutation from 'react-apollo/Mutation';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Option } from 'react-select';
import * as _ from 'lodash';
import Query, { QueryResult } from 'react-apollo/Query';
import { commonLabels } from '../language/commonLabels';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import {
  AccessEnum,
  AddCalendarMutationMutation,
  AddCalendarMutationVariables,
  CalendarCategoryEnum,
  ContentStatus,
  ContentVisibilityEnum,
  CreateCalendarInput,
  CreateCalendarRingMutationVariables,
  FieldsOnKostraFunctionFragment,
  GetMeQuery,
  GetMeVariables,
  OrganizationAccessInput,
  OrganizationGroupAccessInput,
  Person,
  PersonAccessInput,
  PersonGroupAccessInput,
  RefetchAnnualCycleVariables,
  RelationQueryQuery,
  RelationQueryVariables,
} from '../../models/types';
import { ColorPicker } from './ColorPicker';
import { MunikumIcons } from '../common/icons';
import {
  CREATE_CALENDAR_RING,
  YEAR_WHEEL,
} from '../../containers/AnnualCycle/calendar-queries';
import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';
import {
  ColorTheme,
  getNamesAndValues,
  isReallyEmpty,
  safeInvokeDeprecated,
} from '../common';
import { ContentBox } from '../layout/ContentGrid/ContentBox';
import {
  ADD_TO_RELATED,
  OrganzationOption,
  TopicOption,
} from './EditAnnualCycleForm';
import { Pie } from './Pie';
import { commonMessages } from '../language/commonMessages';
import {
  StatusMessageDialog,
  ThemeColor,
} from '../basic/StatusMessage/StatusMessage';
import {
  MembersOfAnnualCycleCard,
  MembersOfAnnualCycleCardEveryoneComp,
  typeToList,
} from './AnnualCycleMembers';
import { metaMessages } from '../../services/models/contentMeta';
import {
  MathiasDropDown,
  tagsPositionEnum,
} from '../basic/DropDownSelect/MathiasDropDown';
import { SEARCH_QUERY_RELATION } from '../common/newRelationComp';
import { PageTitle } from '../layout/Page/Page';
import { MuContentEditable } from '../basic/MuEditor/MuContentEditable';
import { FormLabel } from '../basic/FormLabel/FormLabel';
import ToggleButton from '../basic/ToggleButton/ToggleButton';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { AnnualCycleAddMembersCard } from './AnnualCycleAddMembersCard';
import { DisabledTextField } from '../topics/TopicForm';
import { Button } from '../basic';
import { ExternalLink } from '../basic/Structural/links';
import { MuTextField } from '../basic/MuEditor/MuTextField';
import { convertFromRaw } from 'draft-js';

const ADD_CALENDAR = gql`
  mutation AddCalendarMutation($input: CreateCalendarInput!) {
    createCalendar(input: $input) {
      id
      title
      dateCreated
      access {
        isPrivate
        persons {
          person {
            id
          }
          access
        }
      }
      createdBy {
        id
        name
      }
      color
      status
    }
  }
`;

export const GET_ME = gql`
  query GetMe {
    me {
      id
      name
      jobTitle
      picture
      isAdmin
      department
      organization {
        id
        organizationImage
        name
      }
    }
  }
`;
const CREATE_ORGANIZATION_GROUP = gql`
  mutation CreateOrganizationGroup($input: CreateOrganizationGroupInput!) {
    createOrganizationGroup(input: $input) {
      id
    }
  }
`;

export class MeQuery extends Query<GetMeQuery, GetMeVariables> {}

const messages = defineMessages({
  CycleIsCreated: {
    id: 'CreateAnnualCycleForm.CycleIsCreated',
    defaultMessage: 'Annual cycle is created',
  },
  createRelation: {
    id: 'CreateAnnualCycleForm.createRelation',
    defaultMessage: 'Connect calendar to:',
  },
  makeYearWheelPublic: {
    id: 'CreateAnnualCycleForm.makeYearWheelPublic',
    defaultMessage: 'Meke the yearwheel public',
  },
  makeYearWheelPrivate: {
    id: 'CreateAnnualCycleForm.makeYearWheelPrivate',
    defaultMessage: 'Meke the yearwheel private',
  },
});

export interface IMemberPerson extends Person {
  access?: AccessEnum;
}

let permissionOptions: string = '';

// NOTE: we dont have to create our own interface for Formik, we can use created interface in types.ts
// export interface IAnnualCycleCreateAnnualCycle {
//   title?: string;
//   description?: string;
//   color?: string;
//   status?: ContentStatus;
// }

interface ICreateAnnualCycleFormProps {
  onCancel?: () => void;
  currentPersons?: Array<any>;
  currentPersonGroups?: Array<any>;
  currentOrgGroups?: Array<any>;
  currentOrg?: Array<any>;
  onSaveSuccess?: () => void;
  onNewActivityClick?: (id: string) => void;
  year: number;
  userhash: string;
}

// To get strongly typed props in Mutation's function as a child we have to define out own class (since we cant use Typescript generic < in React)

class TypedMutation extends Mutation<
  AddCalendarMutationMutation,
  AddCalendarMutationVariables
> {}

export const Title = styled.div`
  font-size: 30px;
  font-family: Lato, sans-serif;
  font-weight: bold;
  line-height: 36px;
  color: #333;
  margin-bottom: 0.5em;
`;

export const Container = styled.div`
  display: flex;
`;

export const LeftContainer = styled.div`
  margin-right: 1em;
  flex: 0 0 auto;
  padding: 1.875em 1.875em 0;
  box-sizing: border-box;
  width: 500px;
  overflow: auto;
  max-height: 90vh;
`;

export const RightContainer = styled.div`
  flex: 1 1 auto;
  background-color: #efefef;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;

  min-width: 520px;
  max-width: 520px;
  padding: 1.875em;
`;

export const PieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  //background-color: pink;
`;
const InfoBox = styled.div`
  color: #ff5c39;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
`;

const Error = styled.div`
  color: #ff5c39;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Lato, sans-serif;
`;

interface ICreateAnnualCycleFormState {
  showError: boolean;
  showSuccess: boolean;
  currentPersons?: Array<any>;
  currentPersonGroups?: Array<any>;
  currentOrgGroups?: Array<any>;
  currentOrg?: Array<any>;
  idFromCalendar: string;

  topicItems: Array<any>;
  currentKostraFunctions: ReadonlyArray<FieldsOnKostraFunctionFragment>;
  permission: AccessEnum;
}
const REFETCH_ANNUAL_CYCLES = gql`
  query RefetchAnnualCycle {
    myCalendars(input: { sort: TITLE, order: ASCENDING }) {
      id
      title
      description
      color
      status
    }
  }
`;

class CreateAnnualCycleFormComp extends React.PureComponent<
  ICreateAnnualCycleFormProps & InjectedIntlProps,
  ICreateAnnualCycleFormState
> {
  statusList: { [id: string]: string };

  constructor(props: ICreateAnnualCycleFormProps & InjectedIntlProps) {
    super(props);

    this.state = {
      showError: false,
      showSuccess: false,
      currentPersons: [],
      currentPersonGroups: [],
      currentOrgGroups: [],
      currentOrg: [],
      idFromCalendar: '',
      topicItems: [],
      permission: undefined,
      currentKostraFunctions: [],
    };

    const contentStatuses: { [id: string]: string } = {};
    getNamesAndValues(ContentStatus).map(x => {
      contentStatuses[x.value] = props.intl.formatMessage(metaMessages[x.name]);
    });

    delete contentStatuses.TO_APPROVAL;
    this.statusList = contentStatuses;
  }

  handleError = (error: Error) => {
    this.setState({
      showError: true,
    });
  };

  handleCompleted = (result: RefetchAnnualCycleVariables) => {
    this.setState({
      showSuccess: true,
    });
  };

  handleDismissError = () => {
    this.setState({
      showError: false,
    });
  };

  handleDismissSuccess = () => {
    this.setState(
      {
        showSuccess: false,
      },
      () => safeInvokeDeprecated(this.props.onSaveSuccess)
    );
  };
  deleteFromList = obj => {
    for (let i = 0; i < obj.length; i++) {
      if (obj[i].access === AccessEnum.READ) {
        obj.splice(i, 1);
        i--;
      }
    }
  };
  handleNewActivity = () => {
    this.setState(
      {
        showSuccess: false,
      },
      () =>
        safeInvokeDeprecated(
          this.props.onNewActivityClick,
          this.state.idFromCalendar
        )
    );
  };
  checkIfArrayHasReadMembers = () => {
    for (let i = 0; i < this.state.currentPersons.length; i++) {
      if (this.state.currentPersons[i].access === AccessEnum.READ) {
        return true;
      }
    }
    for (let i = 0; i < this.state.currentPersonGroups.length; i++) {
      if (this.state.currentPersonGroups[i].access === AccessEnum.READ) {
        return true;
      }
    }
    for (let i = 0; i < this.state.currentOrgGroups.length; i++) {
      if (this.state.currentOrgGroups[i].access === AccessEnum.READ) {
        return true;
      }
    }
    for (let i = 0; i < this.state.currentOrg.length; i++) {
      if (this.state.currentOrg[i].access === AccessEnum.READ) {
        return true;
      }
    }
  };

  // handleRemoveMember = (person: IMemberPerson) => {
  //   const updatedMembers = this.state.currentMembers.slice();
  //   const tempIndex = updatedMembers.findIndex(p => p.id === person.id);
  //   updatedMembers.splice(tempIndex, 1);
  //
  //   this.setState({
  //     currentMembers: updatedMembers,
  //   });
  // };
  render() {
    const { intl } = this.props;
    delete this.statusList.OBSOLETE;

    return (
      <ContentBox
        style={{
          padding: '0',
        }}
      >
        {/* put Mutation "far out", then we can display loading and error easily */}

        <TypedMutation
          mutation={ADD_CALENDAR}
          onCompleted={this.handleCompleted}
          onError={this.handleError}
        >
          {(addCalendar, { data, loading, called, error }) => {
            // we can show messages based on function as a child props here:
            // OR we can hook into onError (if we need more control, like showing dialog and hiding again)
            if (this.state.showError && error) {
              return (
                <StatusMessageDialog
                  theme={ThemeColor.ERROR_BLACK}
                  title={'error'}
                  text={'An error occured: ' + error.message}
                  buttonText={'Dismiss'} // we could also retry? check if apollo has a retry mechanismn?
                  onClickButton={this.handleDismissError}
                />
              );
            }

            if (this.state.showSuccess && called) {
              return (
                <StatusMessageDialog
                  theme={ThemeColor.SUCCESS_BLUE}
                  title={intl.formatMessage(commonLabels.success)}
                  text={intl.formatMessage(messages.CycleIsCreated)}
                  buttonText={intl.formatMessage(commonMessages.close)} // we could also retry? check if apollo has a retry mechanismn?
                  buttonText2={'Lag ny aktivitet'}
                  onClickButton2={this.handleNewActivity}
                  onClickButton={this.handleDismissSuccess}
                />
              );
            }

            // let colors = [
            //   { id: 1, name: 'blue' },
            //   { id: 2, name: 'red' },
            //   { id: 3, name: 'green' },
            // ];

            return (
              <div>
                {/* TODO: we could display a loader covering dialog if loading===true? => or is it too quick? :-P*/}
                {/*<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'pink', height: '100%'}}>*/}
                {/*<CircleSpinner />*/}
                {/*</div>*/}

                <Formik
                  validateOnBlur={true}
                  validationSchema={Yup.object().shape({
                    title: Yup.string()
                      .required()
                      .label(intl.formatMessage(commonLabels.title)),
                    color: Yup.string()
                      .required()
                      .label(intl.formatMessage(commonLabels.Color)),
                  })}
                  onSubmit={(values: CreateCalendarInput) => {
                    if (isReallyEmpty(values.title)) {
                    } else {
                      const temp = _.cloneDeep(values);
                      addCalendar({
                        variables: {
                          input: temp,
                        },
                      }).then(result => {
                        this.setState({
                          idFromCalendar:
                            (result &&
                              result.data &&
                              result.data.createCalendar &&
                              result.data.createCalendar.id) ||
                            '',
                        });
                        let updateRingInput: CreateCalendarRingMutationVariables = {
                          input: {
                            calendar:
                              (result &&
                                result.data &&
                                result.data.createCalendar &&
                                result.data.createCalendar.id) ||
                              '',
                            order:
                              (result &&
                                result.data &&
                                result.data.createCalendar &&
                                +result.data.createCalendar.id) ||
                              1,
                            color:
                              (result &&
                                result.data &&
                                result.data.createCalendar &&
                                result.data.createCalendar.color) ||
                              '',
                          },
                        };
                        if (this.state.topicItems.length > 0) {
                          myApolloClient.mutate({
                            mutation: ADD_TO_RELATED,
                            variables: {
                              input: {
                                meta:
                                  result &&
                                  result.data &&
                                  result.data.createCalendar &&
                                  +result.data.createCalendar.id,
                                metasToAdd: this.state.topicItems,
                              },
                            },
                          });
                        }
                        myApolloClient
                          .mutate({
                            mutation: CREATE_CALENDAR_RING,
                            variables: updateRingInput,
                            refetchQueries: [
                              {
                                query: YEAR_WHEEL,
                                variables: {
                                  year: this.props.year,
                                  person: this.props.userhash,
                                },
                              },
                            ],
                          })

                          .catch(err => console.log('err', err));
                      });
                    }
                  }}
                  initialValues={{
                    category: CalendarCategoryEnum.TOPIC,
                    access: {
                      visibility: ContentVisibilityEnum.PUBLIC,
                      isPrivate: false,
                      persons: [],
                      groups: [],
                      organizations: [],
                      organizationGroups: [],
                    },
                    title: '',
                    description: '',
                    descriptionText: '',
                    color: '',
                    status: ContentStatus.PUBLISHED, // NOTE: imported from types.ts!
                  }}
                  render={(formikProps: FormikProps<CreateCalendarInput>) => {
                    return (
                      <form onSubmit={formikProps.handleSubmit}>
                        <MeQuery
                          query={GET_ME}
                          // variables={{ id: this.props.id }}
                          children={(result: QueryResult<GetMeQuery>) => {
                            const me = result.data && result.data.me;
                            return (
                              <Container>
                                <LeftContainer>
                                  {/*<Title style={{ marginBottom: '.75em' }}>*/}
                                  {/*<MuContentEditable*/}
                                  {/*rawMarkdown={formikProps.values.title}*/}
                                  {/*canEdit={true}*/}
                                  {/*onSubmit={(markdown: string) => {*/}
                                  {/*formikProps.setFieldValue(*/}
                                  {/*'title',*/}
                                  {/*markdown*/}
                                  {/*);*/}
                                  {/*}}*/}
                                  {/*/>*/}
                                  {/*</Title>*/}
                                  {/*<div*/}
                                  {/*style={{*/}
                                  {/*display: 'flex',*/}
                                  {/*alignItems: 'center',*/}
                                  {/*fontFamily: 'Lato, sans-serif',*/}
                                  {/*marginTop: '-2em',*/}
                                  {/*marginBottom: '1em',*/}
                                  {/*}}*/}
                                  {/*>*/}
                                  {/*<Checkbox*/}
                                  {/*// style={{marginTop: '-2em'}}*/}
                                  {/*checked={formikProps.values.isPublic}*/}
                                  {/*onClick={() => {*/}
                                  {/*formikProps.setFieldValue(*/}
                                  {/*'isPublic',*/}
                                  {/*!formikProps.values.isPublic*/}
                                  {/*);*/}
                                  {/*}}*/}
                                  {/*/>*/}
                                  {/*{intl.formatMessage(*/}
                                  {/*commonMessages.isYearWheelPublic*/}
                                  {/*)}*/}
                                  {/*</div>*/}
                                  <FormLabel>
                                    {intl.formatMessage(commonLabels.title)}
                                  </FormLabel>
                                  <PageTitle>
                                    <MuContentEditable
                                      id={'title'}
                                      name={'.title'}
                                      canEdit={true} // TODO: add access rights here.. disable if your user cannot edit!
                                      isMultiLine={false}
                                      value={
                                        formikProps.values.title &&
                                        formikProps.values.title
                                      }
                                      onSubmit={(md: string) => {
                                        if (!isReallyEmpty(md)) {
                                          // remove enter keys
                                          let text = md.replace(
                                            /(\r\n|\n|\r)/gm,
                                            ''
                                          );
                                          formikProps.setFieldValue(
                                            'title',
                                            text
                                          );
                                        }

                                        // prevents hidden sign in string
                                        if (isReallyEmpty(md)) {
                                          formikProps.setFieldValue(
                                            'title',
                                            ''
                                          );
                                        }
                                      }}
                                      style={{
                                        // minWidth: '6em',
                                        width: '100%',
                                        border:
                                          formikProps.touched.title &&
                                          formikProps.errors.title
                                            ? '1px solid #ff5c39'
                                            : 'none',
                                      }}
                                      placeholder={intl.formatMessage(
                                        commonLabels.titlePlaceholder
                                      )}
                                    />
                                  </PageTitle>
                                  <Error style={{ marginTop: '.5em' }}>
                                    {formikProps.touched.title &&
                                      formikProps.errors.title && (
                                        <MunikumIcons.Cancel
                                          style={{ marginRight: '.25em' }}
                                        />
                                      )}

                                    {formikProps.touched.title &&
                                      formikProps.errors.title}
                                  </Error>
                                  <div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        marginTop: '.75em',
                                        marginBottom: '1em',
                                      }}
                                    >
                                      {/*<InfoTooltip style={{marginLeft: '-2em'}} content={'test'}/>*/}
                                      <TopicOption
                                        onClick={() => {
                                          formikProps.setFieldValue(
                                            'category',
                                            CalendarCategoryEnum.TOPIC
                                          );
                                        }}
                                        style={{
                                          backgroundColor:
                                            formikProps.values.category ===
                                            CalendarCategoryEnum.ORGANIZATION
                                              ? '#fff'
                                              : '#00778B',
                                          border: '1px solid #D0D3D4 ',
                                          color:
                                            formikProps.values.category ===
                                            CalendarCategoryEnum.TOPIC
                                              ? '#fff'
                                              : 'rgba(51, 51, 51, 0.4)',
                                        }}
                                      >
                                        Temaårshjul
                                      </TopicOption>
                                      <OrganzationOption
                                        onClick={() => {
                                          formikProps.setFieldValue(
                                            'category',
                                            CalendarCategoryEnum.ORGANIZATION
                                          );
                                        }}
                                        style={{
                                          backgroundColor:
                                            formikProps.values.category ===
                                            CalendarCategoryEnum.ORGANIZATION
                                              ? '#F5A623'
                                              : '#fff',
                                          border: '1px solid #D0D3D4 ',
                                          color:
                                            formikProps.values.category ===
                                            CalendarCategoryEnum.ORGANIZATION
                                              ? '#fff'
                                              : 'rgba(51, 51, 51, 0.4)',
                                        }}
                                      >
                                        Organisasjonsårshjul
                                      </OrganzationOption>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <div
                                        style={{
                                          flex: '0 0 auto',
                                          border:
                                            formikProps.touched.color &&
                                            formikProps.errors.color
                                              ? '1px solid #ff5c39'
                                              : 'none',
                                          padding:
                                            formikProps.touched.color &&
                                            formikProps.errors.color
                                              ? '.2em'
                                              : '0',
                                          marginBottom:
                                            formikProps.touched.color &&
                                            formikProps.errors.color
                                              ? '.5em'
                                              : '0',
                                        }}
                                      >
                                        <ColorPicker
                                          label={intl.formatMessage(
                                            commonMessages.chooseColor
                                          )}
                                          selectedColor={
                                            formikProps.values.color
                                          }
                                          onChange={(color: string) =>
                                            formikProps.setFieldValue(
                                              'color',
                                              color
                                            )
                                          }
                                        />
                                      </div>

                                      <PieContainer
                                        style={{ flex: '1 1 10em' }}
                                      >
                                        <div
                                          style={{
                                            width: '10.5em',
                                            height: '10.5em',
                                          }}
                                        >
                                          <Pie
                                            color={formikProps.values.color}
                                          />
                                        </div>
                                      </PieContainer>
                                    </div>
                                    <Error style={{ marginBottom: '1em' }}>
                                      {formikProps.touched.color &&
                                        formikProps.errors.color && (
                                          <MunikumIcons.Cancel
                                            style={{ marginRight: '.25em' }}
                                          />
                                        )}

                                      {formikProps.touched.color &&
                                        formikProps.errors.color}
                                    </Error>
                                    <MathiasDropDown
                                      label={intl.formatMessage(
                                        messages.createRelation
                                      )}
                                      style={{
                                        marginBottom: '1em',
                                        width: '100%',
                                      }}
                                      tagsPosition={tagsPositionEnum.INSIDE}
                                      selectedItems={[]}
                                      onTagRemoved={item => {
                                        this.setState({
                                          topicItems: [],
                                        });
                                      }}
                                      onChange={(value: Array<Option>) => {
                                        this.setState(
                                          {
                                            topicItems: [],
                                          },
                                          () => {
                                            value.forEach(t => {
                                              this.state.topicItems.push(
                                                +t.value
                                              );
                                            });
                                          }
                                        );
                                      }}
                                      loadItems={(
                                        input: string,
                                        preloadedItems: Array<Option<string>>
                                      ): Promise<Array<Option<string>>> => {
                                        return new Promise(
                                          async (resolve, reject) => {
                                            const items: Array<
                                              Option<string>
                                            > = [];
                                            try {
                                              const res = await myApolloClient.query<
                                                RelationQueryQuery,
                                                RelationQueryVariables
                                              >({
                                                query: SEARCH_QUERY_RELATION,
                                                variables: {
                                                  query: input,
                                                },
                                                fetchPolicy: 'network-only',
                                              });
                                              res.data.search.topics.forEach(
                                                (topic, index) => {
                                                  const temp: Option<string> = {
                                                    label: topic.title,
                                                    value: topic.id,
                                                  };
                                                  items.push(temp);
                                                }
                                              );
                                              resolve(items);
                                            } catch (e) {
                                              console.error('Fail', e);
                                              reject(e);
                                            }
                                          }
                                        );
                                      }}
                                    />

                                    {/*<KostraFunctionDropDown*/}
                                    {/*style={{*/}
                                    {/*width: '385px',*/}
                                    {/*}}*/}
                                    {/*selectedItems={*/}
                                    {/*this.state.currentKostraFunctions*/}
                                    {/*}*/}
                                    {/*onSelectedItemsChanged={selectedItems => {*/}
                                    {/*formikProps.setFieldValue(*/}
                                    {/*'kostraFunctions',*/}
                                    {/*selectedItems.map(f => f.id)*/}
                                    {/*);*/}
                                    {/*this.setState({*/}
                                    {/*currentKostraFunctions: selectedItems,*/}
                                    {/*});*/}
                                    {/*}}*/}
                                    {/*/>*/}
                                    <MuTextField
                                      style={{ marginBottom: '.5em' }}
                                      shouldEditorHaveFiles={false}
                                      doIHaveAccessToAddFiles={true}
                                      label={intl.formatMessage(
                                        commonMessages.description
                                      )}
                                      placeholder={intl.formatMessage(
                                        commonMessages.writeHerePlaceholder
                                      )}
                                      onSubmit={value => {
                                        formikProps.setFieldValue(
                                          'description',
                                          value
                                        );
                                        formikProps.setFieldValue(
                                          'descriptionText',
                                          convertFromRaw(
                                            JSON.parse(value)
                                          ).getPlainText()
                                        );
                                      }}
                                      value={formikProps.values.description}
                                    />
                                    {/*<TextArea*/}
                                    {/*style={{ width: '100%' }}*/}
                                    {/*label={'Notes'}*/}
                                    {/*name={'notes'}*/}
                                    {/*value={formikProps.values.status}*/}
                                    {/*onChange={formikProps.handleChange}*/}
                                    {/*onBlur={formikProps.handleBlur}*/}
                                    {/*placeholder={'Description'}*/}
                                    {/*error={formikProps.errors.description}*/}
                                    {/*/>*/}

                                    {/*<p>*/}
                                    {/*test slow mueditor test:*/}
                                    {/*</p>*/}
                                    {/*<MuEditorTextArea*/}
                                    {/*markdown={formikProps.values.description}*/}
                                    {/*onChange={formikProps.handleChange}*/}
                                    {/*/>*/}

                                    {/*<Checkbox*/}
                                    {/*label={'This year wheel is recommended by Munikum'}*/}
                                    {/*/>*/}
                                  </div>
                                </LeftContainer>
                                <RightContainer>
                                  <div
                                    style={{
                                      minWidth: '450px', // IE 11 fix / hack
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom:
                                          formikProps.values.access
                                            .isPrivate === true
                                            ? '0'
                                            : '1em',
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: '22px',
                                          fontWeight: 'bold',
                                          fontFamily: 'Lato',
                                          color: '#333',
                                          marginRight: '1em',
                                        }}
                                      >
                                        {intl.formatMessage(
                                          commonMessages.Access
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <MunikumIcons.Globe
                                          style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                            color: formikProps.values.access
                                              .isPrivate
                                              ? '#333'
                                              : '#FF5C39',
                                          }}
                                        />
                                        <Tooltip
                                          position={'top-start'}
                                          content={
                                            formikProps.values.access.isPrivate
                                              ? intl.formatMessage(
                                                  commonMessages.makeYearWheelPublic
                                                )
                                              : intl.formatMessage(
                                                  commonMessages.makeYearWheelPrivate
                                                )
                                          }
                                        >
                                          <div>
                                            <ToggleButton
                                              style={{
                                                marginLeft: '.5em',
                                                marginRight: '.3em',
                                                marginTop: '.5em',
                                              }}
                                              checked={
                                                formikProps.values.access
                                                  .isPrivate
                                              }
                                              onClick={() => {
                                                const hasReadAccess = this.checkIfArrayHasReadMembers();
                                                if (
                                                  formikProps.values.access
                                                    .isPrivate
                                                ) {
                                                  if (hasReadAccess) {
                                                    if (
                                                      confirm(
                                                        intl.formatMessage(
                                                          commonMessages.MakeYearWheelPublicWarning
                                                        )
                                                      )
                                                    ) {
                                                      formikProps.setFieldValue(
                                                        'access.isPrivate',
                                                        !formikProps.values
                                                          .access.isPrivate
                                                      );
                                                      const personCopy = _.cloneDeep(
                                                        this.state
                                                          .currentPersons
                                                      );
                                                      const personGroupCopy = _.cloneDeep(
                                                        this.state
                                                          .currentOrgGroups
                                                      );
                                                      const orgCopy = _.cloneDeep(
                                                        this.state.currentOrg
                                                      );
                                                      const orgGroupCopy = _.cloneDeep(
                                                        this.state
                                                          .currentOrgGroups
                                                      );
                                                      this.deleteFromList(
                                                        personCopy
                                                      );
                                                      this.deleteFromList(
                                                        personGroupCopy
                                                      );
                                                      this.deleteFromList(
                                                        orgCopy
                                                      );
                                                      this.deleteFromList(
                                                        orgGroupCopy
                                                      );
                                                      this.setState({
                                                        currentPersons: personCopy,
                                                        currentPersonGroups: personGroupCopy,
                                                        currentOrg: orgCopy,
                                                        currentOrgGroups: orgGroupCopy,
                                                      });
                                                    }
                                                  } else {
                                                    formikProps.setFieldValue(
                                                      'access.isPrivate',
                                                      !formikProps.values.access
                                                        .isPrivate
                                                    );
                                                  }
                                                } else if (
                                                  !formikProps.values.access
                                                    .isPrivate
                                                ) {
                                                  formikProps.setFieldValue(
                                                    'access.isPrivate',
                                                    !formikProps.values.access
                                                      .isPrivate
                                                  );
                                                }
                                              }}
                                            />
                                          </div>
                                        </Tooltip>
                                        <MunikumIcons.Padlock
                                          style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                            color: formikProps.values.access
                                              .isPrivate
                                              ? '#FF5C39'
                                              : '#333',
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {formikProps.values.access.isPrivate && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'flex-end',
                                          marginBottom:
                                            formikProps.values.access
                                              .isPrivate === true
                                              ? '.5em'
                                              : '0',
                                        }}
                                      >
                                        <MunikumIcons.eye
                                          style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                            color:
                                              formikProps.values.access
                                                .visibility ===
                                              ContentVisibilityEnum.PRIVATE
                                                ? '#333'
                                                : '#FF5C39',
                                          }}
                                        />
                                        <Tooltip
                                          position={'top-start'}
                                          content={
                                            formikProps.values.access
                                              .visibility ===
                                            ContentVisibilityEnum.PRIVATE
                                              ? intl.formatMessage(
                                                  commonMessages.MakeYearWheelVisible
                                                )
                                              : intl.formatMessage(
                                                  commonMessages.makeYearWheelNotVisible
                                                )
                                          }
                                        >
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'flex-end',
                                            }}
                                          >
                                            <ToggleButton
                                              onClick={() => {
                                                formikProps.values.access
                                                  .visibility ===
                                                ContentVisibilityEnum.PRIVATE
                                                  ? formikProps.setFieldValue(
                                                      'access.visibility',
                                                      ContentVisibilityEnum.PUBLIC
                                                    )
                                                  : formikProps.setFieldValue(
                                                      'access.visibility',
                                                      ContentVisibilityEnum.PRIVATE
                                                    );
                                              }}
                                              style={{
                                                marginLeft: '.5em',
                                                marginRight: '.3em',
                                                marginTop: '.5em',
                                              }}
                                              checked={
                                                formikProps.values.access
                                                  .visibility ===
                                                ContentVisibilityEnum.PRIVATE
                                              }
                                              name={'da'}
                                            />
                                          </div>
                                        </Tooltip>
                                        <MunikumIcons.eyeSlash
                                          style={{
                                            height: '1.2em',
                                            width: '1.2em',
                                            color:
                                              formikProps.values.access
                                                .visibility ===
                                              ContentVisibilityEnum.PRIVATE
                                                ? '#FF5C39'
                                                : '#333',
                                          }}
                                        />
                                      </div>
                                    )}
                                    {/*<Toogle/>*/}
                                    <div style={{ marginBottom: '.5em' }}>
                                      <ExternalLink
                                        style={{
                                          color: '#333',
                                          fontSize: '12px',
                                          borderBottom: '1px solid #333',
                                        }}
                                        href={
                                          'https://support.munikum.no/hc/no/articles/360027728352-Gi-lese-og-skrivetilgang-til-%C3%A5rshjulet'
                                        }
                                      >
                                        {intl.formatMessage(
                                          commonMessages.doYouNotFindThePerson
                                        )}
                                      </ExternalLink>
                                    </div>
                                    <div style={{ marginBottom: '1.250em' }}>
                                      <AnnualCycleAddMembersCard
                                        isPrivate={
                                          formikProps.values.access.isPrivate
                                        }
                                        showOnlyPerson={false}
                                        addGroup={(group, permission) => {
                                          let isAlreadyInArray = false;
                                          for (
                                            let i = 0;
                                            i <
                                            this.state.currentPersonGroups
                                              .length;
                                            i++
                                          ) {
                                            if (
                                              this.state.currentPersonGroups[i]
                                                .group.id === group.id
                                            ) {
                                              isAlreadyInArray = true;
                                              break;
                                            }
                                          }
                                          if (isAlreadyInArray) {
                                            return '';
                                          } else {
                                            const toSend = formikProps.values.access.groups.slice();
                                            const copy = this.state.currentPersonGroups.slice();
                                            copy.push({
                                              access: permission,
                                              group: group,
                                            });
                                            toSend.push({
                                              access: permission,
                                              group: group.id,
                                            });
                                            this.setState({
                                              currentPersonGroups: copy,
                                            });
                                            formikProps.setFieldValue(
                                              'access.groups',
                                              toSend
                                            );
                                          }
                                        }}
                                        addOrg={(org, permission) => {
                                          let isAlreadyInArray = false;
                                          for (
                                            let i = 0;
                                            i < this.state.currentOrg.length;
                                            i++
                                          ) {
                                            if (
                                              this.state.currentOrg[i]
                                                .organization.id === org.id
                                            ) {
                                              isAlreadyInArray = true;
                                              break;
                                            }
                                          }
                                          if (isAlreadyInArray) {
                                            return '';
                                          } else {
                                            const toSend = formikProps.values.access.organizations.slice();
                                            const copy = this.state.currentOrg.slice();
                                            copy.push({
                                              access: permission,
                                              organization: org,
                                            });
                                            toSend.push({
                                              access: permission,
                                              organization: org.id,
                                            });
                                            this.setState({
                                              currentOrg: copy,
                                            });
                                            formikProps.setFieldValue(
                                              'access.organizations',
                                              toSend
                                            );
                                          }
                                        }}
                                        addOrgGroup={(orgGroup, permission) => {
                                          let isAlreadyInArray = false;
                                          for (
                                            let i = 0;
                                            i <
                                            this.state.currentOrgGroups.length;
                                            i++
                                          ) {
                                            if (
                                              this.state.currentOrgGroups[i]
                                                .organizationGroup.id ===
                                              orgGroup.id
                                            ) {
                                              isAlreadyInArray = true;
                                              break;
                                            }
                                          }
                                          if (isAlreadyInArray) {
                                            return '';
                                          } else {
                                            const toSend = formikProps.values.access.organizationGroups.slice();
                                            const copy = this.state.currentOrgGroups.slice();
                                            copy.push({
                                              access: permission,
                                              organizationGroup: orgGroup,
                                            });
                                            toSend.push({
                                              access: permission,
                                              organizationGroup: orgGroup.id,
                                            });
                                            this.setState({
                                              currentOrgGroups: copy,
                                            });
                                            formikProps.setFieldValue(
                                              'access.organizationGroups',
                                              toSend
                                            );
                                          }
                                        }}
                                        showTag={false}
                                        canChangePermission={
                                          formikProps.values.access.isPrivate
                                        }
                                        isPermissionActive={true}
                                        title={intl.formatMessage(
                                          commonMessages.to
                                        )}
                                        permissionLevel={permissionLevel => {
                                          permissionOptions = permissionLevel.valueOf();
                                        }}
                                        addMember={(
                                          person,
                                          permission: AccessEnum
                                        ) => {
                                          let isAlreadyInArray = false;
                                          for (
                                            let i = 0;
                                            i <
                                            this.state.currentPersons.length;
                                            i++
                                          ) {
                                            if (
                                              this.state.currentPersons[i]
                                                .person.id === person.id
                                            ) {
                                              isAlreadyInArray = true;
                                              break;
                                            }
                                          }
                                          if (isAlreadyInArray) {
                                            return '';
                                          } else {
                                            if (person.id !== me.id) {
                                              const toSend = formikProps.values.access.persons.slice();
                                              const copy = this.state.currentPersons.slice();
                                              copy.push({
                                                access: permission,
                                                person: person,
                                              });
                                              toSend.push({
                                                access: permission,
                                                person: person.id,
                                              });
                                              this.setState({
                                                currentPersons: copy,
                                              });
                                              formikProps.setFieldValue(
                                                'access.persons',
                                                toSend
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                    <div>
                                      {me && (
                                        <MembersOfAnnualCycleCard
                                          type={typeToList.PERSONS}
                                          canChangePermission={false}
                                          canRemove={false}
                                          key={me.id}
                                          profilePic={me.picture}
                                          name={me.name}
                                          organization={
                                            me.organization &&
                                            me.organization.name
                                          }
                                          department={me.department}
                                          role={me.jobTitle}
                                          handleRemoveMember={() => ''}
                                          img={
                                            <StaticImage
                                              width={21}
                                              height={21}
                                              type={
                                                IStaticImageType.ORGANIZATION
                                              }
                                              filename={
                                                (me.organization &&
                                                  me.organization
                                                    .organizationImage) ||
                                                ''
                                              }
                                            />
                                          }
                                          permissionLevel={{
                                            value: 'OWNER',
                                            name: intl.formatMessage(
                                              commonMessages.owner
                                            ),
                                          }}
                                        />
                                      )}
                                      {formikProps.values.access &&
                                        formikProps.values.access.isPrivate && (
                                          <DisabledTextField
                                            style={{ width: '460px' }}
                                            name={'t'}
                                            disabled={true}
                                            value={intl.formatMessage(
                                              commonMessages.infoBoxYearWheel
                                            )}
                                          />
                                        )}

                                      <div
                                        style={{
                                          maxHeight: '300px',
                                          overflowY: 'auto',
                                          marginBottom: '1em',
                                        }}
                                      >
                                        {/*Persons*/}
                                        {this.state.currentPersons.map(
                                          (person: any, i) => {
                                            // console.log(person, 'person');
                                            if (me) {
                                              if (
                                                person.person.id !== me.id &&
                                                me.id
                                              ) {
                                                return (
                                                  <div key={'bla' + i}>
                                                    <MembersOfAnnualCycleCard
                                                      type={typeToList.PERSONS}
                                                      canChangePermission={
                                                        formikProps.values
                                                          .access.isPrivate
                                                      }
                                                      canRemove={true}
                                                      onChangeSelected={selectedKey => {
                                                        const personGroupCopyState = this.state.currentPersons.slice();
                                                        const personGroupCopy =
                                                          formikProps.values
                                                            .access.persons;
                                                        const myIndex = _.findIndex(
                                                          formikProps.values
                                                            .access.persons,
                                                          (
                                                            p: PersonAccessInput
                                                          ) => {
                                                            return (
                                                              person.person
                                                                .id === p.person
                                                            );
                                                          }
                                                        );
                                                        const getEnum =
                                                          selectedKey ===
                                                          'WRITE'
                                                            ? AccessEnum.WRITE
                                                            : AccessEnum.READ;
                                                        personGroupCopyState[
                                                          i
                                                        ].access = getEnum;
                                                        personGroupCopy[
                                                          myIndex
                                                        ].access = getEnum;
                                                        this.setState({
                                                          currentPersons: personGroupCopyState,
                                                        });
                                                        formikProps.setFieldValue(
                                                          'access.persons',
                                                          personGroupCopy
                                                        );
                                                      }}
                                                      key={i}
                                                      profilePic={
                                                        person.picture
                                                      }
                                                      name={person.person.name}
                                                      organization={
                                                        person.person
                                                          .organization &&
                                                        person.person
                                                          .organization.name
                                                      }
                                                      department={
                                                        person.person.department
                                                      }
                                                      role={person.person.role}
                                                      handleRemoveMember={() => {
                                                        const personGroupCopyFormik = formikProps.values.access.persons.slice();
                                                        const personGroupCopy = this
                                                          .state.currentPersons;
                                                        const myIndex = _.findIndex(
                                                          formikProps.values
                                                            .access.persons,
                                                          (
                                                            p: PersonAccessInput
                                                          ) => {
                                                            return (
                                                              person.person
                                                                .id === p.person
                                                            );
                                                          }
                                                        );
                                                        personGroupCopyFormik.splice(
                                                          myIndex,
                                                          1
                                                        );
                                                        personGroupCopy.splice(
                                                          i,
                                                          1
                                                        );
                                                        this.setState({
                                                          currentPersons: personGroupCopy,
                                                        });
                                                        formikProps.setFieldValue(
                                                          'access.persons',
                                                          personGroupCopyFormik
                                                        );
                                                      }}
                                                      img={
                                                        <StaticImage
                                                          width={21}
                                                          height={21}
                                                          type={
                                                            IStaticImageType.ORGANIZATION
                                                          }
                                                          filename={
                                                            (person.person
                                                              .organization &&
                                                              person.person
                                                                .organization
                                                                .organizationImage) ||
                                                            ''
                                                          }
                                                        />
                                                      }
                                                      permissionLevel={{
                                                        value: 'WRITE',
                                                        name:
                                                          person.access ===
                                                          'WRITE'
                                                            ? 'skrivetilgang'
                                                            : 'Lesetilgang',
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              }
                                            }
                                            return '';
                                          }
                                        )}
                                        {/*Groups*/}
                                        {/*// TODO Come fixthis*/}
                                        {this.state.currentPersonGroups.map(
                                          (group: any, i) => {
                                            if (me) {
                                              return (
                                                <div key={'bla' + i}>
                                                  <MembersOfAnnualCycleCard
                                                    type={
                                                      typeToList.PERSONGROUPS
                                                    }
                                                    canChangePermission={
                                                      formikProps.values.access
                                                        .isPrivate
                                                    }
                                                    canRemove={true}
                                                    onChangeSelected={selectedKey => {
                                                      const groupCopyState = this.state.currentPersonGroups.slice();
                                                      const groupCopy =
                                                        formikProps.values
                                                          .access.groups;
                                                      const myIndex = _.findIndex(
                                                        formikProps.values
                                                          .access.groups,
                                                        (
                                                          g: PersonGroupAccessInput
                                                        ) => {
                                                          return (
                                                            group.group.id ===
                                                            g.group
                                                          );
                                                        }
                                                      );
                                                      const getEnum =
                                                        selectedKey === 'WRITE'
                                                          ? AccessEnum.WRITE
                                                          : AccessEnum.READ;
                                                      groupCopyState[
                                                        i
                                                      ].access = getEnum;
                                                      groupCopy[
                                                        myIndex
                                                      ].access = getEnum;
                                                      this.setState({
                                                        currentPersonGroups: groupCopyState,
                                                      });
                                                      formikProps.setFieldValue(
                                                        'access.groups',
                                                        groupCopy
                                                      );
                                                    }}
                                                    key={i}
                                                    name={group.group.title}
                                                    handleRemoveMember={() => {
                                                      const groupCopyFormik = formikProps.values.access.groups.slice();
                                                      const groupCopy = this
                                                        .state
                                                        .currentPersonGroups;
                                                      const myIndex = _.findIndex(
                                                        formikProps.values
                                                          .access.groups,
                                                        (
                                                          g: PersonGroupAccessInput
                                                        ) => {
                                                          return (
                                                            group.group.id ===
                                                            g.group
                                                          );
                                                        }
                                                      );
                                                      groupCopyFormik.splice(
                                                        myIndex,
                                                        1
                                                      );
                                                      groupCopy.splice(i, 1);
                                                      this.setState({
                                                        currentPersonGroups: groupCopy,
                                                      });
                                                      formikProps.setFieldValue(
                                                        'access.groups',
                                                        groupCopyFormik
                                                      );
                                                    }}
                                                    img={
                                                      <StaticImage
                                                        width={21}
                                                        height={21}
                                                        type={
                                                          IStaticImageType.ORGANIZATION
                                                        }
                                                      />
                                                    }
                                                    permissionLevel={{
                                                      value: 'WRITE',
                                                      name:
                                                        group.access === 'WRITE'
                                                          ? 'skrivetilgang'
                                                          : 'Lesetilgang',
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }
                                            return '';
                                          }
                                        )}
                                        {/*<Button text={'hhh'}*/}
                                        {/*onClick={()=> {*/}
                                        {/*myApolloClient.mutate({*/}
                                        {/*mutation: CREATE_ORGANIZATION_GROUP,*/}
                                        {/*variables: {input: {*/}
                                        {/*title: 'orgGruppe',*/}
                                        {/*members: [1]*/}
                                        {/*}}*/}
                                        {/*});*/}
                                        {/*myApolloClient.mutate({*/}
                                        {/*mutation: CREATE_ORGANIZATION_GROUP,*/}
                                        {/*variables: {input: {*/}
                                        {/*title: 'orgGruppe1',*/}
                                        {/*members: [1]*/}
                                        {/*}}*/}
                                        {/*});*/}
                                        {/*myApolloClient.mutate({*/}
                                        {/*mutation: CREATE_ORGANIZATION_GROUP,*/}
                                        {/*variables: {input: {*/}
                                        {/*title: 'orgGruppe2',*/}
                                        {/*members: [1]*/}
                                        {/*}}*/}
                                        {/*});*/}
                                        {/*}}*/}
                                        {/*/>*/}
                                        {this.state.currentOrg.map(
                                          (org: any, index) => {
                                            if (me) {
                                              return (
                                                <div key={'bla' + index}>
                                                  <MembersOfAnnualCycleCard
                                                    type={
                                                      typeToList.ORGANIZATIONS
                                                    }
                                                    canChangePermission={
                                                      formikProps.values.access
                                                        .isPrivate
                                                    }
                                                    canRemove={true}
                                                    leftImg={
                                                      org.organization
                                                        .organizationImage
                                                    }
                                                    onChangeSelected={selectedKey => {
                                                      const orgCopyState = this.state.currentOrg.slice();
                                                      const orgCopy =
                                                        formikProps.values
                                                          .access.organizations;
                                                      const myIndex = _.findIndex(
                                                        formikProps.values
                                                          .access.organizations,
                                                        (
                                                          organization: OrganizationAccessInput
                                                        ) => {
                                                          return (
                                                            org.organization
                                                              .id ===
                                                            organization.organization
                                                          );
                                                        }
                                                      );
                                                      const getEnum =
                                                        selectedKey === 'WRITE'
                                                          ? AccessEnum.WRITE
                                                          : AccessEnum.READ;
                                                      orgCopyState[
                                                        index
                                                      ].access = getEnum;
                                                      orgCopy[
                                                        myIndex
                                                      ].access = getEnum;
                                                      this.setState({
                                                        currentOrg: orgCopyState,
                                                      });
                                                      formikProps.setFieldValue(
                                                        'access.organizations',
                                                        orgCopy
                                                      );
                                                    }}
                                                    key={index}
                                                    name={org.organization.name}
                                                    handleRemoveMember={() => {
                                                      const orgCopyFormik = formikProps.values.access.organizations.slice();
                                                      const orgCopy = this.state
                                                        .currentOrg;
                                                      const myIndex = _.findIndex(
                                                        formikProps.values
                                                          .access.organizations,
                                                        (
                                                          organization: OrganizationAccessInput
                                                        ) => {
                                                          return (
                                                            org.organization
                                                              .id ===
                                                            organization.organization
                                                          );
                                                        }
                                                      );
                                                      // console.log(myIndex);

                                                      orgCopyFormik.splice(
                                                        myIndex,
                                                        1
                                                      );
                                                      orgCopy.splice(index, 1);
                                                      this.setState({
                                                        currentOrg: orgCopy,
                                                      });
                                                      formikProps.setFieldValue(
                                                        'access.organizations',
                                                        orgCopyFormik
                                                      );
                                                    }}
                                                    img={
                                                      <StaticImage
                                                        width={21}
                                                        height={21}
                                                        type={
                                                          IStaticImageType.ORGANIZATION
                                                        }
                                                        filename={
                                                          (org.organization &&
                                                            org.organization
                                                              .organizationImage) ||
                                                          ''
                                                        }
                                                      />
                                                    }
                                                    permissionLevel={{
                                                      value: 'WRITE',
                                                      name:
                                                        org.access === 'WRITE'
                                                          ? 'skrivetilgang'
                                                          : 'Lesetilgang',
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }
                                            return '';
                                          }
                                        )}
                                        {/*OrganizationGroups*/}
                                        {this.state.currentOrgGroups.map(
                                          (orgGroup: any, indexPosition) => {
                                            if (me) {
                                              return (
                                                <div
                                                  key={'bla' + indexPosition}
                                                >
                                                  <MembersOfAnnualCycleCard
                                                    type={
                                                      typeToList.ORGANIZATIONGROUPS
                                                    }
                                                    canChangePermission={
                                                      formikProps.values.access
                                                        .isPrivate
                                                    }
                                                    canRemove={true}
                                                    key={indexPosition}
                                                    name={
                                                      orgGroup.organizationGroup
                                                        .title
                                                    }
                                                    handleRemoveMember={() => {
                                                      const orgGroupCopyFormik = formikProps.values.access.organizationGroups.slice();
                                                      const orgGroupCopy = this
                                                        .state.currentOrgGroups;
                                                      const myIndex = _.findIndex(
                                                        formikProps.values
                                                          .access
                                                          .organizationGroups,
                                                        (
                                                          orgGroup2: OrganizationGroupAccessInput
                                                        ) => {
                                                          return (
                                                            orgGroup
                                                              .organizationGroup
                                                              .id ===
                                                            orgGroup2.organizationGroup
                                                          );
                                                        }
                                                      );

                                                      orgGroupCopyFormik.splice(
                                                        myIndex,
                                                        1
                                                      );
                                                      orgGroupCopy.splice(
                                                        indexPosition,
                                                        1
                                                      );
                                                      this.setState({
                                                        currentOrgGroups: orgGroupCopy,
                                                      });
                                                      formikProps.setFieldValue(
                                                        'access.organizationGroups',
                                                        orgGroupCopyFormik
                                                      );
                                                    }}
                                                    onChangeSelected={selectedKey => {
                                                      const orgGroupCopyState = this.state.currentOrgGroups.slice();
                                                      const orgGroupCopy =
                                                        formikProps.values
                                                          .access
                                                          .organizationGroups;
                                                      const myIndex = _.findIndex(
                                                        formikProps.values
                                                          .access
                                                          .organizationGroups,
                                                        (
                                                          organizationGroup: OrganizationGroupAccessInput
                                                        ) => {
                                                          return (
                                                            orgGroup
                                                              .organizationGroup
                                                              .id ===
                                                            organizationGroup.organizationGroup
                                                          );
                                                        }
                                                      );
                                                      const getEnum =
                                                        selectedKey === 'WRITE'
                                                          ? AccessEnum.WRITE
                                                          : AccessEnum.READ;
                                                      orgGroupCopyState[
                                                        indexPosition
                                                      ].access = getEnum;
                                                      orgGroupCopy[
                                                        myIndex
                                                      ].access = getEnum;
                                                      this.setState({
                                                        currentOrgGroups: orgGroupCopyState,
                                                      });
                                                      formikProps.setFieldValue(
                                                        'access.organizationGroups',
                                                        orgGroupCopy
                                                      );
                                                    }}
                                                    img={
                                                      <StaticImage
                                                        width={21}
                                                        height={21}
                                                        type={
                                                          IStaticImageType.ORGANIZATION
                                                        }
                                                      />
                                                    }
                                                    permissionLevel={{
                                                      value: 'WRITE',
                                                      name:
                                                        orgGroup.access ===
                                                        'WRITE'
                                                          ? 'skrivetilgang'
                                                          : 'Lesetilgang',
                                                    }}
                                                  />
                                                </div>
                                              );
                                            }
                                          }
                                        )}

                                        {!formikProps.values.access
                                          .isPrivate && (
                                          <MembersOfAnnualCycleCardEveryoneComp
                                            // style={{marginTop: '-1.2em'}}
                                            onCancelClick={() => {
                                              formikProps.setFieldValue(
                                                'access.isPrivate',
                                                !formikProps.values.access
                                                  .isPrivate
                                              );
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center',
                                      marginBottom: '1.2em',
                                    }}
                                  >
                                    <Button
                                      text={intl.formatMessage(
                                        commonLabels.Cancel
                                      )}
                                      theme={ColorTheme.White}
                                      onClick={() =>
                                        safeInvokeDeprecated(
                                          this.props.onCancel
                                        )
                                      }
                                      disabled={loading}
                                    />
                                    <Button
                                      isLoading={loading}
                                      text={intl.formatMessage(
                                        commonMessages.save
                                      )}
                                      theme={ColorTheme.Red}
                                      type={'submit'}
                                      style={{ marginLeft: '1em' }}
                                      leftIcon={MunikumIcons.Save}
                                      // disabled={!formikProps.isValid}
                                    />
                                  </div>
                                </RightContainer>
                              </Container>
                            );
                          }}
                        />
                      </form>
                    );
                  }}
                />
              </div>
            );
          }}
        </TypedMutation>
      </ContentBox>
    );
  }
}
export const CreateAnnualCycleForm = injectIntl(CreateAnnualCycleFormComp);
