import phoneSad from './phone-sad.svg';
import phoneHappy from './phone-smile.svg';
import phoneOhno from './phone-ohno.svg';
import phoneYes from './phone-please.svg';
import phoneStart from './phone-start.svg';

import * as React from 'react';
import { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { TextNormal } from '../../basic/Structural/typography';
import MiniErrorMessage from '../../basic/StatusMessage/MiniErrorMessage';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { CircleSpinner } from '../../basic/Loader/CircleSpinner';
import { commonMessages } from '../../language/commonMessages';
import { Checkbox } from '../../basic/Checkbox/Checkbox';
import {
  GET_USER_WIZARD_DATA,
  GetUserWizardData,
  UPDATE_ME_SETTINGS,
  UpdateMeSettings,
} from '../personQueries';
import { safeInvoke } from '../../common';

const messages = defineMessages({
  errorSave: {
    id: 'phoneSettings.error',
    defaultMessage: 'Error when saving notification settings',
  },
  info: {
    id: 'phoneSettings.info',
    defaultMessage: 'May we call you at a later time?',
  },
  buttonText: {
    id: 'phoneSettings.buttonText',
    defaultMessage: 'Sure, why not!',
  },
});

const CenterVertical = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 390px;
`;

const CenterDiv = styled.div`
  //background-color: orange;
  align-self: center;
  justify-self: center;
  flex: 1 1 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

function getMood(
  isChecked: boolean,
  isHovering: boolean,
  justDidIt: boolean,
  first: boolean
) {
  if (first && !isChecked) {
    return phoneStart;
  }
  if (isHovering && !justDidIt) {
    if (isChecked) {
      return phoneOhno;
    } else {
      return phoneYes;
    }
  } else {
    if (isChecked) {
      return phoneHappy;
    } else {
      return phoneSad;
    }
  }
}

const PhoneSettingsPureComp: React.FunctionComponent<
  {
    style?: CSSProperties;
    checked?: boolean;
    onChecked?: (isChecked: boolean) => void;
  } & InjectedIntlProps
> = props => {
  const [hover, setHover] = useState(false);
  const [justDidIt, setJustDidIt] = useState(false);
  const [first, setFirst] = useState(true);

  return (
    <MyDiv style={props.style}>
      <div>
        <TextNormal style={{ width: '200px', marginBottom: '1em' }}>
          {props.intl.formatMessage(messages.info)}
        </TextNormal>
        <div
          onMouseEnter={e => {
            setHover(true);
            setFirst(false);
          }}
          onMouseLeave={e => {
            setHover(false);
            setJustDidIt(false);
          }}
        >
          <Checkbox
            checked={props.checked}
            onClick={() => {
              setJustDidIt(true);
              safeInvoke(props.onChecked, !props.checked);
            }}
            label={props.intl.formatMessage(messages.buttonText)}
          />
        </div>
      </div>
      <div
        style={{
          marginLeft: '2em',
          display: 'flex',
          justifyContent: 'center',
          width: '200px',
        }}
      >
        <img src={getMood(props.checked, hover, justDidIt, first)} />
      </div>
    </MyDiv>
  );
};

export const PhoneSettingsPure = injectIntl(PhoneSettingsPureComp);

const PhoneSettingsComp: React.FunctionComponent<
  { style?: CSSProperties } & InjectedIntlProps
> = props => {
  return (
    <GetUserWizardData query={GET_USER_WIZARD_DATA}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <CenterDiv>
              <CircleSpinner />
            </CenterDiv>
          );
        }

        if (error || !data) {
          return (
            <CenterDiv>
              <MiniErrorMessage
                message={props.intl.formatMessage(commonMessages.errorLoadData)}
                intl={props.intl}
              />
            </CenterDiv>
          );
        }

        return (
          <UpdateMeSettings mutation={UPDATE_ME_SETTINGS}>
            {(
              updateSettings,
              { data: uData, loading: uLoading, called, error: uError }
            ) => {
              const realOk =
                (data &&
                  data.me &&
                  data.me.settings &&
                  data.me.settings.allowContactViaPhone) ||
                false; // nope, default false, legal stuff?

              let acceptedNewsletter =
                data &&
                data.me &&
                data.me.settings &&
                data.me.settings.shouldReceiveNewsletter;

              const setRealOk = (nextOk: boolean) => {
                updateSettings({
                  variables: {
                    input: {
                      acceptedUserTerms: data.me.settings.acceptedUserTerms,
                      allowContactViaEmail:
                        data.me.settings.allowContactViaEmail,
                      showAssignedTasks: data.me.settings.showAssignedTasks,
                      allowContactViaPhone: nextOk,
                      shouldReceiveNewsletter: acceptedNewsletter,
                    },
                  },
                  optimisticResponse: {
                    __typename: 'Mutation',
                    updateSettings: {
                      name: data.me.name,
                      kostraFunctions: data.me.kostraFunctions,
                      language: data.me.language,
                      jobTitle: data.me.jobTitle,
                      department: data.me.department,
                      description: data.me.description,
                      phoneNumber: data.me.phoneNumber,
                      __typename: data.me.__typename,
                      settings: {
                        __typename: data.me.settings.__typename,
                        showAssignedTasks: data.me.settings.showAssignedTasks,
                        allowContactViaEmail:
                          data.me.settings.allowContactViaEmail,
                        sendInstantNotificationMail:
                          data.me.settings.sendInstantNotificationMail,
                        sendDailyNotificationMail:
                          data.me.settings.sendDailyNotificationMail,
                        sendWeeklyNotificationMail:
                          data.me.settings.sendWeeklyNotificationMail,
                        acceptedUserTerms: data.me.settings.acceptedUserTerms,
                        allowContactViaPhone: nextOk,
                        shouldReceiveNewsletter:
                          data.me.settings.shouldReceiveNewsletter,
                      },
                      organization: data.me.organization,
                      email: data.me.email,
                      id: data.me.id,
                      picture: data.me.picture,
                    },
                  },
                }).then(res => console.log('update OK!', res));
              };

              if (uError) {
                console.error(uError);
                return (
                  <CenterDiv>
                    <MiniErrorMessage
                      message={props.intl.formatMessage(messages.errorSave)}
                      intl={props.intl}
                    />
                  </CenterDiv>
                );
              }

              return (
                <PhoneSettingsPure
                  checked={realOk}
                  onChecked={ok => setRealOk(ok)}
                  style={props.style}
                />
              );
            }}
          </UpdateMeSettings>
        );
      }}
    </GetUserWizardData>
  );
};

const PhoneSettings = injectIntl(PhoneSettingsComp);
export default PhoneSettings;
