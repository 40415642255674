import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { MunikumIcons } from '../common/icons';
import { Colors, safeInvoke, safeInvokeDeprecated } from '../common';
import { MuContentEditable } from '../basic/MuEditor/MuContentEditable';
import { CheckboxLight } from '../basic/Checkbox/CheckboxLight';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { commonMessages } from '../language/commonMessages';

export const TodoCardCont = styled.div`
  height: 36px;
  margin-bottom: 0.5em;
  padding: 0.5em;
  background-color: #fff;
  border-radius: 3px;
  min-width: 300px;

  overflow: hidden;
  display: flex;
  justify-content: space-between;

  box-sizing: border-box;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */

  align-items: center;
  flex-direction: row;

  //width: 492px;
`;

export const SmallText = styled.div`
  color: ${Colors.BLACK};
  font-family: Lato, sans-serif;
  font-size: 14px;
  max-width: 25em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RemoveDiv = styled.div`
  display: flex;
  transition: all 0.2s ease-in-out;
  width: 1.6em;
  height: 1.6em;

  color: rgb(255, 92, 57);
  margin-left: 1em;
  justify-content: center;
  align-items: center;
  :hover {
    transform: ${(props: { canDelete: boolean }) =>
      props.canDelete ? 'scale(1.3)' : ''};
    cursor: ${(props: { canDelete: boolean }) =>
      props.canDelete ? 'pointer' : 'normal'};
  }
`;

interface ITodoCardProps {
  checkBoxColor?: string;
  todoName: string;
  isChecked?: boolean;
  style?: CSSProperties;
  isCheckBoxVisible?: boolean;
  canDelete: boolean;
  onDeleteClicked?: () => void;
  onClickTodo?: () => void;
  onChange?: (md: string) => void;
  disabled?: boolean;
}

class TodoCardClass extends React.PureComponent<
  ITodoCardProps & InjectedIntlProps
> {
  private textArea: React.RefObject<any> = null;

  // componentWillReceiveProps(nextProps: ITodoCardProps) {
  //   if (nextProps.todo>Name !== this.props.todoName) {
  //     this.setState({
  //       todoName: nextProps.todoName,
  //     });
  //   }

  render() {
    return (
      <TodoCardCont style={this.props.style}>
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {this.props.isCheckBoxVisible && !this.props.disabled && (
              <div style={{ paddingRight: '.5em' }}>
                <CheckboxLight
                  color={this.props.checkBoxColor}
                  checked={this.props.isChecked}
                  onClick={() => {
                    safeInvoke(this.props.onClickTodo);
                  }}
                />
              </div>
            )}
            <SmallText>
              {this.props.isChecked && (
                <>
                  <MuContentEditable
                    name={'todoName'}
                    ref={this.textArea}
                    canEdit={this.props.canDelete && !this.props.disabled}
                    isMultiLine={false}
                    onSubmit={(md: string) => {
                      safeInvokeDeprecated(this.props.onChange, md);
                    }}
                    value={this.props.todoName || ''}
                    style={{
                      minWidth: '100px',
                    }}
                  />
                </>
              )}
              {!this.props.isChecked && (
                <>
                  <MuContentEditable
                    name={'todoName'}
                    canEdit={this.props.canDelete && !this.props.disabled}
                    isMultiLine={false}
                    onSubmit={(md: string) => {
                      safeInvokeDeprecated(this.props.onChange, md);
                    }}
                    value={this.props.todoName || ''}
                    style={{
                      minWidth: '100px',
                      margin: '1px',
                    }}
                  />
                </>
              )}
            </SmallText>
          </div>
          {!this.props.disabled && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {this.props.canDelete && (
                <RemoveDiv
                  canDelete={this.props.canDelete || false}
                  onClick={() => {
                    if (this.props.canDelete) {
                      safeInvoke(this.props.onDeleteClicked);
                    }
                  }}
                >
                  <MunikumIcons.Cancel
                    style={{
                      height: '1.2em',
                      width: '1.2em',
                      opacity: this.props.canDelete ? 1 : 0.2,
                      color: this.props.canDelete
                        ? Colors.YELLOW
                        : Colors.GREYLABEL,
                    }}
                  />
                </RemoveDiv>
              )}
              {!this.props.canDelete && (
                <Tooltip
                  position={'right'}
                  content={this.props.intl.formatMessage(
                    commonMessages.todoBelongsToTemplateWarning
                  )}
                >
                  <RemoveDiv
                    canDelete={this.props.canDelete || false}
                    onClick={() => {
                      if (this.props.canDelete) {
                        safeInvoke(this.props.onDeleteClicked);
                      }
                    }}
                  >
                    <MunikumIcons.Cancel
                      style={{
                        height: '1.2em',
                        width: '1.2em',
                        opacity: this.props.canDelete ? 1 : 0.2,
                        color: this.props.canDelete
                          ? Colors.YELLOW
                          : Colors.GREYLABEL,
                      }}
                    />
                  </RemoveDiv>
                </Tooltip>
              )}
            </div>
          )}
        </>
      </TodoCardCont>
    );
  }
}
export const TodoCard = injectIntl(TodoCardClass);
