import * as _ from 'lodash';
import { merge } from 'lodash';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { QueryResult } from 'react-apollo';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import moment from 'moment';
import { IEventInstanceViewModel } from '../../components/annualCycle/AnnualCycleRightMenu';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import { Dictionary } from 'async';
import {
  FieldsOnCalendarRingFragment,
  UpdateEventInstanceResponsible,
  YearWheelMagicRingQuery,
  YearWheelMagicRingVariables,
  YearWheelQuery,
  YearWheelRingCalendar,
  YearWheelRingQuery,
  YearWheelRingVariables,
} from '../../models/types';
import {
  getEmptySelection,
  Wheel,
} from '../../components/annualCycle/Wheel/Wheel';
import { EmptyLayout } from '../../layouts/EmptyLayout';
import {
  YEAR_WHEEL,
  YEAR_WHEEL_CALENDAR_RING,
  YEAR_WHEEL_MAGIC_RING,
  YearWheelQueryComp,
} from './calendar-queries';
import {
  filteredInstancesSelectorFactory,
  IAnnualCycleFilter,
} from './calendar-selectors';
import { H1 } from '../../components/basic/Structural/typography';
import { Avatar } from '../../components/person/Avatar';
import { Tag } from '../../components/basic/Tag/Tag';
import { RootState } from '../../modules/rootReducer';
import { WheelConstants } from '../../components/annualCycle/Wheel/utils';
import { CheckboxLight } from '../../components/basic/Checkbox/CheckboxLight';

interface IYearPrintPageProps {
  meHash: string;
  year: number;
}

interface IUrlProps {
  year?: string;
  startMonth?: string;
  person: string;
}

interface IState {
  filter: IAnnualCycleFilter;
  meHash: string;

  // TODO: refactor to use apollo state later...
  dataCalendars?: { [calendarId: string]: YearWheelRingCalendar };

  isFirstReady: boolean;
}

interface IMonthBoxProps {
  items: Array<IEventInstanceViewModel>;
  // monthIndex: number;
  // year: number;
  monthName: string;
}

const MonthTitle = styled.div`
  //display: flex;
  //justify-content: flex-start;
  //align-items: center;

  font-family: Lato, sans-serif;
  font-size: 10px;
  color: #333333;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 12px;
  padding-top: 20px;
  padding-bottom: 4px;

  text-transform: uppercase;
  //padding-left: 0.5em;
  //padding-right: 0.5em;
  //border-radius: 4px;

  //height: 36px;

  margin-bottom: 0.5em;

  //background-color: grey;

  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  page-break-inside: avoid;
`;

const MonthItem = styled.div`
  text-transform: uppercase;
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 12px;
  padding-top: 24px;
`;

const Container = styled.div`
  @media print {
    font-size: 0.7em; // scale down to fitting size
  }

  @page {
    margin: 1.25cm; // print margins left and right
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

const TopLeft = styled.div`
  //background-color: palegoldenrod;
  flex: 0 0 50%;

  padding: 3em;
  box-sizing: border-box;

  @media print {
    padding: 0px;
  }
`;

const TopRight = styled.div`
  //background-color: hotpink;

  flex: 0 0 50%;

  padding: 3em;
  box-sizing: border-box;

  @media print {
    padding: 0px;
  }
`;

const ColContainer = styled.div`
  //display: flex;
  //background-color: paleturquoise;

  column-count: 2; // text only? or divs too?
  //column-gap: 3em;
  //column-rule: 1px solid #333333;

  //justify-content: center;
  //flex-wrap: wrap;
`;

const MonthContainer = styled.div`
  //background-color: palevioletred;
  flex: 0 0 auto;
  //margin: 1em;

  @media print {
    // NOTE: enable this to avoid splitting months.. if we want that...
    //page-break-inside: avoid;

    margin-bottom: 1em;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const TaskContainer = styled.div`
  @media print {
    page-break-inside: avoid;
  }

  display: flex;
  border-radius: 4px;
  background-color: #fff;
  opacity: ${(props: { isFinished: boolean; isHighlighted: boolean }) =>
    props.isFinished ? '0.5' : '1'};

  //border: 1px solid #d0d3d4;

  box-shadow: ${(props: { isFinished: boolean; isHighlighted: boolean }) =>
    props.isHighlighted
      ? '0 2px 7px 0 rgba(0, 0, 0, 0.2)'
      : '0 2px 7px 0 rgba(0, 0, 0, 0.1)'};
  min-height: 2.25em; // 36px;
  margin-bottom: 0.5em;
  box-sizing: border-box;
  padding: 0.5em;

  overflow: hidden;

  //overflow-wrap: break-word;
  //word-break: break-word; /* Chrome, Safari */
  //word-wrap: break-word; /* IE11, Firefox */

  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    //transform: scale(1.01);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  }
`;

const TaskText = styled.div`
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 0.875em; //14px;
  line-height: 17px;

  //overflow: hidden;
  //white-space: nowrap;
  //text-overflow: ellipsis;
  flex: 1 1 auto;
  align-self: center;
  //
  //@media print {
  //  font-size: 12pt;
  //}
`;

const Year = styled.div`
  font-family: Lato, sans-serif;
  align-self: center;

  font-size: 3em;
  font-weight: bold;
`;

const TaskLeft = styled.div`
  flex: 1 1 auto;
`;
const TaskRight = styled.div`
  flex: 0 0 8em;
  margin-left: 0.5em;

  display: flex;
  justify-content: space-between;
`;

const AvatarDiv = styled.div`
  flex: 0 0 auto;

  //background-color: paleturquoise;

  font-size: 1em;
  margin-left: 0.5em;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const DateDiv = styled.div`
  flex: 1 1 auto;
  font-size: 0.85em;
  font-family: Lato, sans-serif;

  margin-left: 0.5em;

  //background-color: pink;

  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;

  text-align: right;
`;

const CircleContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: #fff;

  width: 40%;
  height: 40%;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
`;

const WheelContainer = styled.div`
  position: relative;
  width: 100%;
`;

const getName = (item: IEventInstanceViewModel): string => {
  if (item.responsible && item.responsible.name) {
    return item.responsible.name;
  }
  if (item.createdBy && item.createdBy.name) {
    return item.createdBy.name;
  }
  return '';
};

const MiniTask: React.SFC<{ item: IEventInstanceViewModel }> = props => (
  <TaskContainer isFinished={props.item.isDone} isHighlighted={false}>
    <TaskLeft>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <CheckboxLight
          color={props.item.color}
          checked={props.item.isDone}
          style={{ marginRight: '.5em' }}
        />

        {props.item.isDone && (
          <del
            style={{
              textDecoration: 'line-through',
            }}
          >
            <TaskText>{props.item.title}</TaskText>
          </del>
        )}
        {!props.item.isDone && <TaskText>{props.item.title}</TaskText>}
      </div>
    </TaskLeft>
    <TaskRight>
      <AvatarDiv>
        <Avatar name={getName(props.item)} />
      </AvatarDiv>
      <DateDiv>
        {moment(props.item.endDate || undefined).format('dddd Do MMM')}
      </DateDiv>
    </TaskRight>
  </TaskContainer>
);

const myFilteredInstancesSelector = filteredInstancesSelectorFactory();

class MonthBox extends React.PureComponent<IMonthBoxProps, {}> {
  render() {
    return (
      <MonthContainer>
        <MonthTitle>
          {/*{WheelConstants.monthNames[this.props.monthIndex]}*/}
          {this.props.monthName}
        </MonthTitle>
        {this.props.items.length === 0 && (
          <div>Ingen oppgåver i {this.props.monthName}</div>
        )}
        {/*<MiniTask item={item} />*/}
        {/*<TaskComp*/}
        {/*eventInstance={item}*/}
        {/*taskId={item.taskId}*/}
        {/*year={this.props.year}*/}
        {/*eventInstanceId={item.id}*/}
        {/*onClick={() => {}}*/}
        {/*calendarCode={'dummy'}*/}
        {/*/>*/}
        {this.props.items.map((item, index) => (
          <MiniTask item={item} key={index} />
        ))}
      </MonthContainer>
    );
  }
}

const PersonsDiv = styled.div`
  padding-bottom: 1.5em;
`;

const TagsDiv = styled.div`
  //background-color: #fff;
  //background-color: paleturquoise;
  padding-bottom: 0.5em;
  //padding-top: 1.25em;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

class YearPrintPageComp extends React.PureComponent<
  IYearPrintPageProps & InjectedIntlProps & RouteComponentProps<IUrlProps>,
  IState
> {
  constructor(
    props: IYearPrintPageProps &
      InjectedIntlProps &
      RouteComponentProps<IUrlProps>
  ) {
    super(props);

    const temp = getEmptySelection(true, this.props.year, []);
    const temp2 = _.cloneDeep(temp);

    Object.keys(temp).map(key => {
      temp2[key].isEntireMonthSelected = true;
    });

    const isYearSupplied = props.match.params.year !== undefined;
    const isSingleMode =
      props.match.params.year && props.match.params.year.indexOf('-') === -1;
    const isTwoMode =
      props.match.params.year && props.match.params.year.indexOf('-') > -1;

    this.state = {
      filter: {
        selectedYear:
          isYearSupplied && isSingleMode
            ? parseInt(props.match.params.year, 10)
            : undefined,
        selectedYearFrom:
          isYearSupplied && isTwoMode
            ? moment()
                .year(parseInt(props.match.params.year.split('-')[0], 10))
                .month(props.match.params.startMonth || 0)
                .startOf('month')
                .toDate()
            : undefined,
        selectedYearTo:
          isYearSupplied && isTwoMode
            ? moment()
                .year(parseInt(props.match.params.year.split('-')[1], 10))
                .month(props.match.params.startMonth || 0)
                .startOf('month')
                .toDate()
            : undefined,
        selectedMonths: temp2,
      },
      meHash: this.props.meHash,
      dataCalendars: undefined,
      isFirstReady: false,
    };
  }

  loadMagicRingDataAsync = async (year: number): Promise<void> => {
    try {
      const result = await myApolloClient.readQuery<
        YearWheelMagicRingQuery,
        YearWheelMagicRingVariables
      >({
        query: YEAR_WHEEL_MAGIC_RING,
        variables: {
          year: year,
        },
      });

      if (result !== null) {
        await this.setStateAsync({
          dataCalendars: merge({}, this.state.dataCalendars, {
            [0]: result.myAssignedTasks.calendar, // magic ring has magic id 0!
          }),
        });
      }
    } catch (err) {
      // dont worry, just means we didnt hit the cache..
    }

    try {
      const result = await myApolloClient.query<
        YearWheelMagicRingQuery,
        YearWheelMagicRingVariables
      >({
        query: YEAR_WHEEL_MAGIC_RING,
        variables: {
          year: year,
        },
        fetchPolicy: 'network-only',
      });

      if (result && result.data) {
        await this.setStateAsync({
          dataCalendars: merge({}, this.state.dataCalendars, {
            [0]: result.data.myAssignedTasks.calendar, // magic ring has magic id 0!
          }),
        });
      }
    } catch (err) {
      console.log('magic ring error', err);
    }

    return Promise.resolve();
  };

  setStateAsync(state: Partial<IState>) {
    return new Promise((resolve, reject) => {
      if (this !== undefined) {
        this.setState(state as any, resolve);
      } else {
        reject('this is gone');
      }
    });
  }

  /***
   * Read calendar with given calendarId and year
   * first from CACHE, setState with data, loading=false
   * then from NETWORK, setState with data
   * */
  loadRingDataAsync = async (
    calendarId: string,
    year?: number,
    from?: Date,
    to?: Date
  ): Promise<void> => {
    try {
      // console.log('RING_LOAD: ' + calendarId + ' year: ' + year + ' from/to', from, to);

      const result = await myApolloClient.readQuery<
        YearWheelRingQuery,
        YearWheelRingVariables
      >({
        query: YEAR_WHEEL_CALENDAR_RING,
        variables: {
          calendarId: calendarId,
          year: year,
          from: from,
          to: to,
        },
      });

      if (result !== null) {
        // console.log('RING_OFUNd_incache');
        await this.setStateAsync({
          dataCalendars: merge({}, this.state.dataCalendars, {
            [calendarId]: result.calendar,
          }),
          // loadingInstances: this.state.loadingInstances - 1
        });
      } else {
        // console.log('RING_not found in cache..');
      }
    } catch (err) {
      // console.log('RING_NOT_found_crash in cache:' + calendarId);
    }

    // TODO: sjekk ut result.data ?!?!??!

    try {
      // console.log('RING_query_api' + calendarId);
      const result = await myApolloClient.query<
        YearWheelRingQuery,
        YearWheelRingVariables
      >({
        query: YEAR_WHEEL_CALENDAR_RING,
        variables: {
          calendarId: calendarId,
          year: year,
          from: from,
          to: to,
        },
        fetchPolicy: 'network-only',
      });

      if (result.data.calendar === null) {
        console.warn('wtf', calendarId, result);
      }
      await this.setStateAsync({
        // errorInstances: undefined,
        dataCalendars: merge({}, this.state.dataCalendars, {
          [calendarId]: result.data.calendar,
        }),
        // loadingInstances: true
      });

      // console.log('RING_LOADED_SUCCESSFULLY_TO_STATE: ' + calendarId);
    } catch (err) {
      // console.log('RING_ERR', err);
      await this.setStateAsync({
        // errorInstances: err,
      });
    }

    return Promise.resolve();
  };

  handlePrintFinished = (e: any) => {
    window.close();
    window.removeEventListener('onfocus', this.handlePrintFinished);
  };

  loadAllRingDataAsync(
    rings: Array<FieldsOnCalendarRingFragment>,
    loadMagicRing: boolean
  ): Promise<{ loadedRingsCount: number }> {
    return new Promise((resolve, reject) => {
      const tasks: Array<Promise<void>> = [];

      rings.forEach((w: FieldsOnCalendarRingFragment, index: number) => {
        // if (this.state.dataCalendars === undefined || !this.state.dataCalendars.hasOwnProperty(w.id)) {
        const task = this.loadRingDataAsync(
          w.calendar.id,
          this.state.filter.selectedYear,
          this.state.filter.selectedYearFrom,
          this.state.filter.selectedYearTo
        );
        task.catch(err => reject(err));
        tasks.push(task);
      });

      if (loadMagicRing) {
        const magicTask = this.loadMagicRingDataAsync(
          this.state.filter.selectedYear
        );
        tasks.push(magicTask);
      }

      Promise.all(tasks).then(() => {
        // console.log('ALL rings DATA loaded ok! ringCount: ' + tasks.length);
        resolve({ loadedRingsCount: tasks.length });
      });
    });
  }

  handleMainQueryFinished = (data: YearWheelQuery) => {
    // console.log('====> Main YearWheelQuery completed!', data);
    if (data.yearWheel && !this.state.isFirstReady) {
      this.setState(
        {
          isFirstReady: true,
        },
        () => {
          // console.log('Loading all rings after first mount..');
          this.loadAllRingDataAsync(
            data.yearWheel,
            data.me.settings.showAssignedTasks
          )
            .then(res => {
              // console.log('All rings loaded OK after main query completed');

              setTimeout(() => {
                // console.log('PRINTing...');

                // window.addEventListener('onfocus', this.handlePrintFinished);
                window.print();
                // window.focus();
              }, 4000);
            })
            .catch(err =>
              console.error('error loading all rings after main completed', err)
            );
        }
      );
    }
  };

  render() {
    const year = this.state.filter.selectedYear;
    const meHash = this.state.meHash;

    return (
      <EmptyLayout
        style={{
          width: '100%',
          margin: '0',
          float: 'none',
          overflow: 'visible',
          backgroundColor: '#FFF',
        }}
      >
        <YearWheelQueryComp
          query={YEAR_WHEEL}
          variables={{ person: meHash }}
          fetchPolicy={'cache-and-network'}
          onCompleted={this.handleMainQueryFinished}
          children={(result: QueryResult<YearWheelQuery>) => {
            if (!result.data && result.loading) {
              return (
                <div>
                  <div>Loading...</div>
                </div>
              );
            }

            if (result.error) {
              return <div>Error...</div>;
            }

            const dataRings = result.data;

            const isMagicEnabled =
              (dataRings &&
                dataRings.me &&
                dataRings.me.settings.showAssignedTasks) ||
              false;
            const magicRing =
              (dataRings && dataRings.myAssignedTasks) || undefined;

            const happyRings = (dataRings && dataRings.yearWheel) || [];

            let filteredEventInstances: Array<
              IEventInstanceViewModel
            > = myFilteredInstancesSelector({
              filter: this.state.filter,
              rings: happyRings,
              calendars: this.state.dataCalendars, // calendars from local state! magic ring DATA is also here..
              magicRing: isMagicEnabled ? magicRing : undefined,
              startMonth: this.props.match.params.startMonth || '0',
            });
            if (
              result &&
              result.data &&
              result.data.me &&
              result.data.me.settings &&
              result.data.me.settings.calendarHideCompleted &&
              result.data.me.settings.calendarHideCompleted
            ) {
              filteredEventInstances = _.filter(
                filteredEventInstances,
                c => !c.isDone
              );
            }
            let groupedInstances = _.groupBy(
              filteredEventInstances,
              (instance: IEventInstanceViewModel) => {
                const month = moment(instance.endDate || undefined).month();
                return month;
              }
            );

            const tasks: Array<any> = [];
            const personsDict: Dictionary<UpdateEventInstanceResponsible> = {};
            if (
              result.data &&
              result.data.me &&
              result.data.me.settings.calendarHideCompleted
            ) {
              // _.filter(groupedInstances, c => !c.isDone);
            }
            filteredEventInstances.forEach((value: IEventInstanceViewModel) => {
              if (value.responsible) {
                if (!personsDict.hasOwnProperty(value.responsible.id)) {
                  personsDict[value.responsible.id] = value.responsible;
                }
              }
            });

            const persons = _.values(personsDict);

            const sm =
              this.props.match &&
              this.props.match.params &&
              this.props.match.params.startMonth
                ? parseInt(this.props.match.params.startMonth, 10)
                : 0;
            const isTwoMode = sm > 0;
            let printYear = -1;

            if (!isTwoMode && this.state.filter.selectedYear !== undefined) {
              printYear = this.state.filter.selectedYear;
            } else if (
              isTwoMode &&
              this.state.filter.selectedYearFrom !== undefined
            ) {
              printYear = this.state.filter.selectedYearFrom.getFullYear();
            }

            for (let count = 0, m = sm; count < 12; count++, m++) {
              const index = m % 12;
              const name = WheelConstants.monthNames[index];

              if (m === 12) {
                printYear++;
              }

              let py = name + ' ' + (printYear > 0 ? printYear : '');

              tasks.push(
                <MonthBox
                  key={name + '_' + index + '_box'}
                  items={
                    groupedInstances.hasOwnProperty(index)
                      ? groupedInstances[index]
                      : []
                  }
                  monthName={py}
                />
              );
            }

            // WheelConstants.monthNames.forEach((name: string, index: number) => {
            //   tasks.push(
            //     <MonthBox
            //       key={name + '_' + index + '_box'}
            //       items={
            //         groupedInstances.hasOwnProperty(index)
            //           ? groupedInstances[index]
            //           : []
            //       }
            //       monthIndex={index}
            //       year={year}
            //     />
            //   );
            // });

            return (
              <Container>
                <ColContainer>
                  <WheelContainer>
                    <Wheel
                      eventInstances={filteredEventInstances}
                      rings={happyRings}
                      // year={this.props.year}
                    />
                    <CircleContainer>
                      <Circle>
                        <Year>{this.props.year}</Year>
                      </Circle>
                    </CircleContainer>
                  </WheelContainer>
                  <H1 style={{ lineHeight: '2.25em' }}>
                    <FormattedMessage
                      id={'YearPrintPage.annualCycle'}
                      defaultMessage={'Annual cycle'}
                    />
                  </H1>
                  <TagsDiv>
                    {happyRings.map((ring, index) => {
                      return (
                        <Tag
                          hasShadow={false}
                          key={'tagg_' + index}
                          text={ring.calendar.title}
                          color={ring.color}
                          canClose={false}
                          style={{ marginRight: '.5em', marginBottom: '.5em' }}
                        />
                      );
                    })}
                  </TagsDiv>
                  <H1 style={{ lineHeight: '2.25em' }}>
                    <FormattedMessage
                      id={'YearPrintPage.responsible'}
                      defaultMessage={'Responsibility'}
                    />
                  </H1>
                  <PersonsDiv>
                    {persons.map(p => (
                      <div
                        key={p.id}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '.5em',
                        }}
                      >
                        <Avatar name={p.name} />
                        <div style={{ marginLeft: '.5em' }}>{p.name}</div>
                      </div>
                    ))}
                  </PersonsDiv>

                  {tasks}
                </ColContainer>
              </Container>
            );
          }}
        />
      </EmptyLayout>
    );
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: {} & RouteComponentProps<{ year: string }>
): IYearPrintPageProps => ({
  year: parseInt(ownProps.match.params.year || '2018', 10),
  meHash:
    (state.auth.user &&
      state.auth.user.profile &&
      state.auth.user.profile.hash) ||
    '',
});

const mapDispatchToProps = (dispatch: Dispatch<{}>) => ({});

const YearPrintPage = connect<IYearPrintPageProps, {}, {}>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(YearPrintPageComp)));

export default YearPrintPage;
