import * as React from 'react';
import { IconType } from 'react-icons/lib';
import styled from 'styled-components';
import ColorLib from 'color';
import {
  Colors,
  IDisabledProps,
  IStylable,
  safeInvokeDeprecated,
} from '../../common';

export interface IEditorButtonProps {
  /**
   * Icon from react-icons library, see MunikumIcons enum!
   */
  icon: IconType;

  // put in controlled mode
  checked?: boolean;
}

export interface IEditorButtonDispatch {
  onChange?: (isChecked: boolean) => void;
}

export interface IEditorButtonState {
  mouseHover: boolean;
  checked: boolean;
}

type IIconContainer = IDisabledProps & {
  size?: string;
  hover: boolean;
  checked: boolean;
};

const defaultContainerSize = '1.25em';
const defaultIconSize = '1em';

const OuterContainer = styled.div`
  border: 1px solid
    ${(props: IIconContainer) =>
      !props.disabled && props.hover ? Colors.GREYBORDER : 'transparent'};
  transition: border-color 0.25s ease-in-out, background-color 0.25s ease-in-out;

  background-color: ${(props: IIconContainer) =>
    props.checked
      ? ColorLib(Colors.GREY208)
          .alpha(0.7)
          .string()
      : props.hover
      ? ColorLib(Colors.GREY208)
          .alpha(0.4)
          .string()
      : 'transparent'};
  border-radius: 4px;

  &:hover:enabled {
    box-shadow: 0 -1px 1px rgba(255, 255, 255, 0.95),
      0 1px 5px rgba(0, 0, 0, 0.15);
  }
`;

const IconContainer = styled.div`
  
  border-radius: 50%;
  width: ${(props: IIconContainer) => props.size || defaultContainerSize};
  height: ${(props: IIconContainer) => props.size || defaultContainerSize};

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${(props: IIconContainer) =>
    props.disabled ? 'not-allowed' : 'pointer'}

  position: relative;

  &:active:enabled {
    // background-color: orange;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export class MuFormattingButton extends React.PureComponent<
  IEditorButtonProps & IEditorButtonDispatch & IDisabledProps & IStylable,
  IEditorButtonState
> {
  constructor(props: IEditorButtonProps & IEditorButtonDispatch & IStylable) {
    super(props);

    this.state = {
      mouseHover: false,
      checked: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: IEditorButtonProps) {
    if (
      nextProps.checked !== undefined &&
      nextProps.checked !== this.state.checked
    ) {
      this.setState({
        checked: nextProps.checked,
      });
    }
  }

  handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(
      {
        checked: !this.state.checked,
      },
      () => {
        safeInvokeDeprecated(this.props.onChange, this.state.checked);
      }
    );
  };

  handleMouse = (isHover: boolean) => {
    this.setState({
      mouseHover: isHover,
    });
  };

  render() {
    const Icon = this.props.icon;

    const { disabled, style } = this.props;
    const { mouseHover, checked } = this.state;

    return (
      <OuterContainer
        size={defaultContainerSize}
        hover={mouseHover}
        checked={checked}
        disabled={disabled}
        style={style}
      >
        <IconContainer
          disabled={disabled}
          onMouseEnter={() => this.handleMouse(true)}
          onMouseLeave={() => this.handleMouse(false)}
          onMouseDown={this.handleClick}
          size={defaultContainerSize}
          hover={mouseHover}
          checked={checked}
        >
          <Icon
            fill={
              disabled
                ? Colors.GREYDISABLEDTEXT
                : mouseHover
                ? Colors.BLACK
                : Colors.BLACK
            }
            onMouseDown={this.handleClick}
            width={defaultIconSize}
            height={defaultIconSize}
            style={{
              transition: 'fill .25s ease-in-out',
            }}
          />
        </IconContainer>
      </OuterContainer>
    );
  }
}
