import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { commonMessages } from '../../language/commonMessages';
import { WheelDirection, WheelSelectionMode, WheelToggleMode } from './Wheel';
import { Colors, ColorTheme, safeInvokeDeprecated } from '../../common';
import { FieldGroup } from '../../basic/FieldGroup/FieldGroup';
import { MunikumIcons } from '../../common/icons';
import { FormLabel } from '../../basic/FormLabel/FormLabel';
import { RadioButton } from '../../basic/RadioButtons/RadioButton';
import { Button } from '../../basic';
import MonthDropdown from './MonthDropdown';

const pieMode = require('./pieMode.svg') as string;
const monthMode = require('./monthMode.svg') as string;
const left = require('./arrowLeft.svg') as string;
const right = require('./arrowRight.svg') as string;

export interface IWheelSettings {
  selectionMode: WheelSelectionMode;
  toggleMode: WheelToggleMode;
  direction: WheelDirection;
  enableAnimations: boolean;

  // string '0' === january
  startMonth: string;
}

export interface IWheelSettingsProps {
  onCancel?: () => void;
  onSave?: (settings: IWheelSettings) => void;

  settings?: IWheelSettings;
}
export interface IWheelSettingsState {
  // settings: IWheelSettings;
}

const Container = styled.div`
  display: flex;
  //background-color: paleturquoise;

  justify-content: space-between;
`;

const Left = styled.div`
  width: 15em;
  //background-color: palevioletred;
`;

const Right = styled.div`
  width: 15em;
  //background-color: papayawhip;
  border-left: 1px solid ${Colors.GREY208};
  padding-left: 2em;
`;

const RadioElement = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RadioContent = styled.div`
  padding-left: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
`;

const SmallImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid ${Colors.BLUE};
`;

const Desc = styled.div`
  height: 8em;
  overflow: hidden;
  line-height: 1em;
  font-family: Lato, sans-serif;
  font-size: 1em;
  color: ${Colors.BLACK};
`;

// Wanted to use apollo-link-state to store local data in apollo cache, but didnt get it to work with gql-coden + graphql plugin :-(
// also link-state api didnt look very nice :-P

// const UPDATE_WHEEL_SETTINGS = gql`
//   mutation UpdateWheelSettings($isAnimationsEnabled: Boolean) {
//     updateWheelSettings(isAnimationsEnabled: $isAnimationsEnabled) @client
//   }
// `;
//
// const GET_WHEEL_SETTINGS = gql`
//   query GetWheelSettings {
//     wheelSettings @client {
//       isAnimationsEnabled
//     }
//   }
// `;

class WheelSettingsComp extends React.PureComponent<
  IWheelSettingsProps & InjectedIntlProps,
  IWheelSettingsState
> {
  constructor(props: IWheelSettingsProps & InjectedIntlProps) {
    super(props);
  }

  render() {
    const { intl } = this.props;

    return (
      <Formik
        initialValues={
          this.props.settings || {
            selectionMode: WheelSelectionMode.MONTH,
            toggleMode: WheelToggleMode.REMEMBER,
            direction: WheelDirection.RIGHT,
            enableAnimations: true,
            startMonth: '0',
          }
        }
        onSubmit={(values: IWheelSettings) => {
          safeInvokeDeprecated(this.props.onSave, values);
        }}
        render={(props: FormikProps<IWheelSettings>) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <Container>
                <Left>
                  <FieldGroup>
                    <FormLabel htmlFor={'direction'}>
                      <FormattedMessage
                        id={'WheelSettings.direction'}
                        defaultMessage={'Direction'}
                      />
                    </FormLabel>
                    <div style={{ display: 'flex' }}>
                      <RadioElement>
                        <RadioButton
                          name={'direction'}
                          value={WheelDirection.RIGHT}
                          style={{ marginBottom: '10px' }}
                          checked={
                            props.values.direction === WheelDirection.RIGHT
                          }
                          onChange={() =>
                            props.setFieldValue(
                              'direction',
                              WheelDirection.RIGHT
                            )
                          }
                        />
                        <RadioContent
                          onClick={() =>
                            props.setFieldValue(
                              'direction',
                              WheelDirection.RIGHT
                            )
                          }
                        >
                          <SmallImage>
                            <img src={right} alt={'right'} />
                          </SmallImage>
                          <div>{intl.formatMessage(commonMessages.right)}</div>
                        </RadioContent>
                      </RadioElement>

                      <RadioElement style={{ marginLeft: '1em' }}>
                        <RadioButton
                          name={'direction'}
                          value={WheelDirection.LEFT}
                          style={{ marginBottom: '10px' }}
                          checked={
                            props.values.direction === WheelDirection.LEFT
                          }
                          onChange={() =>
                            props.setFieldValue(
                              'direction',
                              WheelDirection.LEFT
                            )
                          }
                        />
                        <RadioContent
                          onClick={() =>
                            props.setFieldValue(
                              'direction',
                              WheelDirection.LEFT
                            )
                          }
                        >
                          <SmallImage>
                            <img src={left} alt={'left'} />
                          </SmallImage>
                          <div>{intl.formatMessage(commonMessages.left)}</div>
                        </RadioContent>
                      </RadioElement>
                    </div>
                  </FieldGroup>

                  <FieldGroup>
                    <FormLabel htmlFor={'selectionMode'}>
                      <FormattedMessage
                        id={'WheelSettings.selectionMode'}
                        defaultMessage={'Selection mode'}
                      />
                    </FormLabel>
                    <div style={{ display: 'flex' }}>
                      <RadioElement>
                        <RadioButton
                          name={'selectionMode'}
                          value={WheelSelectionMode.MONTH}
                          style={{ marginBottom: '10px' }}
                          checked={
                            props.values.selectionMode ===
                            WheelSelectionMode.MONTH
                          }
                          onChange={() =>
                            props.setFieldValue(
                              'selectionMode',
                              WheelSelectionMode.MONTH
                            )
                          }
                        />
                        <RadioContent
                          onClick={() =>
                            props.setFieldValue(
                              'selectionMode',
                              WheelSelectionMode.MONTH
                            )
                          }
                        >
                          <SmallImage>
                            <img src={monthMode} alt={'monthMode'} />
                          </SmallImage>
                          <div style={{ textTransform: 'capitalize' }}>
                            {intl.formatMessage(commonMessages.month)}
                          </div>
                        </RadioContent>
                      </RadioElement>

                      <RadioElement style={{ marginLeft: '1em' }}>
                        <RadioButton
                          name={'selectionMode'}
                          value={WheelSelectionMode.SMALLPIE}
                          style={{ marginBottom: '10px' }}
                          checked={
                            props.values.selectionMode ===
                            WheelSelectionMode.SMALLPIE
                          }
                          onChange={() =>
                            props.setFieldValue(
                              'selectionMode',
                              WheelSelectionMode.SMALLPIE
                            )
                          }
                        />
                        <RadioContent
                          onClick={() =>
                            props.setFieldValue(
                              'selectionMode',
                              WheelSelectionMode.SMALLPIE
                            )
                          }
                        >
                          <SmallImage>
                            <img src={pieMode} alt={'monthMode'} />
                          </SmallImage>
                          <div>{intl.formatMessage(commonMessages.pie)}</div>
                        </RadioContent>
                      </RadioElement>
                    </div>
                  </FieldGroup>

                  <FieldGroup>
                    <FormLabel htmlFor={'toggleMode'}>
                      <FormattedMessage
                        id={'WheelSettings.toggleMode'}
                        defaultMessage={'Toggle mode'}
                      />
                    </FormLabel>
                    <RadioElement>
                      <RadioButton
                        name={'toggleMode'}
                        value={'REMEMBER'}
                        checked={
                          props.values.toggleMode === WheelToggleMode.REMEMBER
                        }
                        onChange={() =>
                          props.setFieldValue(
                            'toggleMode',
                            WheelToggleMode.REMEMBER
                          )
                        }
                      />
                      <RadioContent
                        onClick={() =>
                          props.setFieldValue(
                            'toggleMode',
                            WheelToggleMode.REMEMBER
                          )
                        }
                      >
                        <FormattedMessage
                          id={'WheelSettings.toggleRemember'}
                          defaultMessage={'Remember selection'}
                        />
                      </RadioContent>
                    </RadioElement>
                    <RadioElement>
                      <RadioButton
                        name={'toggleMode'}
                        value={'CTRL'}
                        checked={
                          props.values.toggleMode ===
                          WheelToggleMode.CONTROL_CLICK
                        }
                        onChange={() =>
                          props.setFieldValue(
                            'toggleMode',
                            WheelToggleMode.CONTROL_CLICK
                          )
                        }
                      />
                      <RadioContent
                        onClick={() =>
                          props.setFieldValue(
                            'toggleMode',
                            WheelToggleMode.CONTROL_CLICK
                          )
                        }
                      >
                        <FormattedMessage
                          id={'WheelSettings.toggleCtrl'}
                          defaultMessage={'Use {ctrl}/{cmd}'}
                          values={{
                            ctrl: <span>Ctrl</span>,
                            cmd: <span>Cmd</span>,
                          }}
                        />
                      </RadioContent>
                    </RadioElement>
                  </FieldGroup>

                  <MonthDropdown
                    monthId={props.values.startMonth}
                    onMonthChanged={m => {
                      props.setFieldValue('startMonth', m.id);
                    }}
                  />
                </Left>
                <Right>
                  <FormLabel>
                    <FormattedMessage
                      id={'WheelSettings.instructionsTitle'}
                      defaultMessage={'Instructions'}
                    />
                  </FormLabel>
                  <Desc>
                    <FormattedMessage
                      id={'WheelSettings.instructionsDirection'}
                      defaultMessage={'In what direction does your wheel turn?'}
                      values={{
                        br: <br />,
                      }}
                    />
                  </Desc>

                  <Desc>
                    <FormattedMessage
                      id={'WheelSettings.instructionsSelectionMode'}
                      defaultMessage={
                        'Choose {month} to select an entire month. Or {pie} if you need more fine grained control'
                      }
                      values={{
                        br: <br />,
                        month: (
                          <b>{intl.formatMessage(commonMessages.month)}</b>
                        ),
                        pie: <b>{intl.formatMessage(commonMessages.pie)}</b>,
                      }}
                    />
                  </Desc>

                  <Desc
                    style={{
                      height: '4em',
                    }}
                  >
                    <FormattedMessage
                      id={'WheelSettings.instructionsToggleMode'}
                      defaultMessage={
                        'You can either hold down key Ctrl or Cmd to select multiple items, or remember selected items'
                      }
                      values={{
                        br: <br />,
                        month: (
                          <b>{intl.formatMessage(commonMessages.month)}</b>
                        ),
                        pie: <b>{intl.formatMessage(commonMessages.pie)}</b>,
                      }}
                    />
                  </Desc>
                </Right>
              </Container>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '1em',
                }}
              >
                <Button
                  text={intl.formatMessage(commonMessages.cancel)}
                  leftIcon={MunikumIcons.Cancel}
                  theme={ColorTheme.White}
                  onClick={() => safeInvokeDeprecated(this.props.onCancel)}
                />
                <Button
                  text={intl.formatMessage(commonMessages.save)}
                  leftIcon={MunikumIcons.Save}
                  theme={ColorTheme.Red}
                  style={{
                    marginLeft: '.5em',
                  }}
                  onClick={() => props.submitForm()}
                />
              </div>
            </form>
          );
        }}
      />
    );
  }
}

export const WheelSettings = injectIntl(WheelSettingsComp);
