import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import { call, put, take } from 'redux-saga/effects';
import { createSelector, Selector } from 'reselect';
import * as _ from 'lodash';
import { signoutSuccess } from '../auth/auth';
import {
  invitePerson,
  IPersonEditForm,
  IPersonMeForm,
  updateMe,
  updatePerson,
} from '../../services/personService';
import { IApiError } from '../../services/api';
import { IPerson } from '../../services/models/person';
import { updatePersonActionCreator } from '../entities/entities';
import { syncElasticSearch } from '../../services/organizationService';
import { IInviteForm } from '../../components/invite/InviteForm';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { RootState } from '../rootReducer';

const actionCreator = actionCreatorFactory('PERSON');

/**
 * Person redux module (actions, reducer, sagas, selectors). see also entities.ts for generic code!
 */

// actions:
export const editPerson = actionCreator<{ person: IPersonEditForm }>(
  'EDIT_USER'
);

export const cancelEditPerson = actionCreator<{}>('CANCEL_EDIT_USER');

export const editProfile = actionCreator<{ person: IPerson }>('EDIT_PROFILE');

export const cancelEditProfile = actionCreator<{}>('CANCEL_EDIT_PROFILE');

export const syncElasticSearchActionCreator = actionCreator<{}>(
  'SYNC_ELASTIC_SEARCH'
);

// updatePerson in entities.ts

export const updateMeActionCreator = actionCreator.async<
  IPersonMeForm,
  IPerson,
  IApiError
>('UPDATE_PROFILE');

export const invitePersonActionCreator = actionCreator.async<
  IInviteForm,
  undefined,
  IApiError
>('INVITE_PERSON');

export interface IPersonState {
  invite: {
    isInviting: boolean;
    success: boolean;
  };
  person: {
    editingPerson?: IPersonEditForm;
    isSaving: boolean;
    error?: string;
  };
  profile: {
    editingProfile?: IPersonMeForm;
    isSaving: boolean;
    error?: string;
  };
}

const initialState: IPersonState = {
  person: {
    isSaving: false,
    error: undefined,
  },
  profile: {
    isSaving: false,
    error: undefined,
  },
  invite: {
    isInviting: false,
    success: false,
  },
};

export const reducer = (
  state: IPersonState = initialState,
  action: Action
): IPersonState => {
  if (isType(action, signoutSuccess)) {
    return initialState;
  }

  if (isType(action, invitePersonActionCreator.started)) {
    return {
      ...state,
      invite: {
        ...state.invite,
        isInviting: true,
      },
    };
  }
  if (isType(action, invitePersonActionCreator.success)) {
    return {
      ...state,
      invite: {
        ...state.invite,
        isInviting: false,
        success: true,
      },
    };
  }
  if (isType(action, invitePersonActionCreator.failed)) {
    return {
      ...state,
      invite: {
        ...state.invite,
        isInviting: false,
        success: false,
      },
    };
  }
  if (isType(action, editPerson)) {
    return {
      ...state,
      person: {
        ...state.person,
        editingPerson: action.payload.person,
      },
    };
  }
  if (isType(action, cancelEditPerson)) {
    return {
      ...state,
      person: {
        ...state.person,
        editingPerson: undefined,
        isSaving: false,
      },
    };
  }
  if (isType(action, updatePersonActionCreator.started)) {
    return {
      ...state,
      person: {
        ...state.person,
        isSaving: true,
      },
    };
  }
  if (isType(action, updatePersonActionCreator.failed)) {
    return {
      ...state,
      person: {
        ...state.person,
        isSaving: false,
        error: action.payload.error.message,
      },
    };
  }
  if (isType(action, updatePersonActionCreator.success)) {
    return {
      ...state,
      person: {
        ...state.person,
        isSaving: false,
        error: undefined,
        editingPerson: undefined,
      },
    };
  }

  if (isType(action, editProfile)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        editingProfile: action.payload.person,
      },
    };
  }
  if (isType(action, cancelEditProfile)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        editingProfile: undefined,
        isSaving: false,
      },
    };
  }
  if (isType(action, updateMeActionCreator.started)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        isSaving: true,
      },
    };
  }
  if (isType(action, updateMeActionCreator.failed)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        isSaving: false,
        error: action.payload.error.message,
      },
    };
  }
  if (isType(action, updateMeActionCreator.success)) {
    return {
      ...state,
      profile: {
        ...state.profile,
        isSaving: false,
        error: undefined,
        editingProfile: undefined,
      },
    };
  }

  return state;
};

// selectors:
export const editingPersonSelector = (
  state: RootState
): IPersonEditForm | undefined =>
  state.person && state.person.person.editingPerson;

const personEntitySelector: Selector<
  RootState,
  { [byId: string]: Readonly<IPerson> }
> = state => state.entities.persons.byId;

const personsListTransformer = (personDict: {
  [byId: string]: Readonly<IPerson>;
}) => _.values(personDict);

/**
 * A memoized reselect selector that returns loaded persons as a array.
 * NOTE: cache size = 1, this selector is shared between all components
 * if we want to add filtering, we should have a reselect-selector instance per component/container?
 * @type {OutputSelector<RootState, ReadonlyArray<IPerson>, (res: {[p: string]: Readonly<IPerson>}) => ReadonlyArray<IPerson>>}
 */
export const personListSelector = createSelector<
  RootState,
  { [byId: string]: IPerson },
  Array<IPerson>
>(
  personEntitySelector,
  personsListTransformer
);

export const personDictSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IPerson> },
  { [byId: string]: Readonly<IPerson> }
>(
  personEntitySelector,
  res => res
);

// redux-sagas:
export function* editPersonSaga() {
  while (true) {
    const action = yield take(updatePersonActionCreator.started.type);

    try {
      const result = yield call(updatePerson, action.payload);
      yield put(
        updatePersonActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
      console.log('Ditta e spennande', result);
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }
      console.log('error saving person:', tempError, e); // TODO: remove in production?... useful for debugging..

      yield put(
        updatePersonActionCreator.failed({
          params: action.payload,
          error: tempError,
        })
      );
    }
  }
}

export function* editProfileSaga() {
  while (true) {
    const action = yield take(updateMeActionCreator.started.type);

    try {
      const result = yield call(updateMe, action.payload);
      yield put(
        updateMeActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
      console.log('funka ditta da', result);
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }

      console.log('error saving me:', tempError, e);

      yield put(
        updateMeActionCreator.failed({
          params: action.payload,
          error: tempError,
        })
      );
    }
  }
}

export function* syncElasticSaga() {
  while (true) {
    const action = yield take(syncElasticSearchActionCreator.type);
    try {
      console.log('sync..');
      yield call(syncElasticSearch);
      console.log('done');
    } catch (e) {
      console.log('fail');
    }
  }
}

export function* invitePersonSaga() {
  while (true) {
    const action = yield take(invitePersonActionCreator.started.type);

    try {
      const result = yield call(invitePerson, action.payload);
      yield put(
        invitePersonActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }

      yield put(
        invitePersonActionCreator.failed({
          params: action.payload,
          error: tempError,
        })
      );
    }
  }
}
