import './resize.css';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Redirect, RouteComponentProps } from 'react-router';
import Helmet from 'react-helmet';
import {
  AnnualCycleRightMenu,
  IEventInstanceViewModel,
} from '../../components/annualCycle/AnnualCycleRightMenu';
import { myApolloClient } from '../../graphql/apolloClientFactory';
import { myHistory } from '../../index';
import { AnnualCycle } from '../../components/annualCycle/AnnualCycle';
import {
  AccessEnum,
  CalendarQuery,
  FieldsOnCalendarRingFragment,
} from '../../models/types';
import {
  filteredInstancesSelectorFactory,
  getIsEverythingSelected,
  getIsNothingSelected,
  IAnnualCycleFilter,
} from './calendar-selectors';
import {
  Colors,
  safeInvoke,
  safeInvokeDeprecated,
} from '../../components/common';
import {
  getEmptySelection,
  SelectedMonthsDict,
} from '../../components/annualCycle/Wheel/Wheel';
import { Page } from '../../components/layout/Page/Page';
import { CALENDAR, CalendarQueryComp } from './calendar-queries';
import { IShareForm } from '../../services/metaService';
import { ProgressBar } from '../../components/basic/ProgressBar/ProgressBar';
import { FakeLink } from '../../components/basic/Structural/links';
import Query from 'react-apollo/Query';
import { GET_ME } from '../../components/annualCycle/CreateAnnualCycleForm';
import {
  CleanDialog,
  CloseWarningOption,
} from '../../components/basic/Dialog/CleanDialog';
import CreateEventForm from '../../components/annualCycle/CreateEventForm';
import { sizes } from '../../components/common/media';
import MediaQuery from 'react-responsive';
import { ResizableBox } from 'react-resizable';
import { SmallTitle } from '../../components/basic/RepetitionPicker/RepetitionPicker';
import { Tag } from '../../components/basic/Tag/Tag';
import { commonMessages } from '../../components/language/commonMessages';
import { SuspenseLoader } from '../../components/basic/Loader/CircleSpinner';
import { ADD_TASK } from '../../components/annualCycle/CreateTaskCollectionForm';
import { uploadFile } from '../../services/fileservice';

const myFilteredInstancesSelector = filteredInstancesSelectorFactory();

const MyContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;

  @media (min-width: ${sizes.large}px) {
    //background-color: paleturquoise;

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }
`;

const Box = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  margin: 0.5em;
  padding: 1.5em 1.875em;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
`;

const LeftMenuBox = styled(Box)`
  display: block;
  box-sizing: border-box;
  transition: height 1s ease-in-out;

  @media (min-width: ${sizes.large}px) {
    align-self: stretch;
  }

  page-break-after: always;
`;

const RightMenuBox = styled(Box)`
  display: block;
  box-sizing: border-box;

  overflow-y: hidden;
  overflow-x: hidden;

  min-width: 340px;

  @media (min-width: ${sizes.large}px) {
    flex: 1 1 auto;
    //overflow-y: auto;
    //overflow-x: hidden;
    //max-height: 100%;

    display: flex;
    justify-content: stretch;
    align-items: stretch;

    //background-color: orange;
  }
  @media (min-width: ${sizes.larger}px) {
    //overflow-y: auto;
    //overflow-x: hidden;
    //max-width: 1000px; // sets task width
  }

  @media print {
    // this removes scroll in scroll when printing
    overflow-x: visible;
    overflow-y: visible;
  }
`;

const FixedHeightContainer = styled.div`
  //                TOP/BOTT   RIGHT/LEFT
  //std   padding: 0.5em        1em;
  //ipad  padding: 1em          2em;
  //large padding: 1em          5em;     <=== over 1300 funker ok  -2.5

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  overflow: hidden;

  @media (min-width: ${sizes.ipad}px) {
    margin-left: -1em;
    margin-right: -1em;
    max-height: max-content;

    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
  }

  @media (min-width: 1300px) {
    margin-left: -2.5em;
    margin-right: -2.5em;
    max-height: calc(150vh - 3.125em - 4em);

    max-width: 84.125em;
    //background-color: yellow;
  }
`;

const TopDiv = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  @media (min-width: ${sizes.ipad}px) {
    margin-left: -0.5em;
    margin-right: -0.5em;

    display: flex;
  }

  @media (min-width: 1300px) {
    margin-left: -2em;
    margin-right: -2em;
    max-width: 83.2em;
    //background-color: yellow;
  }
  margin-bottom: 0.3em;
  flex-wrap: wrap;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 0.5em;
`;

interface ICalendarPageState {
  filter: IAnnualCycleFilter;
  isEditAnnualCycleFormOpen: boolean;
  fakeProgressValue: number;
  isSuccessMessageActive: boolean;
  isCopyYearWheelOpen: boolean;
  isNewEventOpen: boolean;
  isFirstReady: boolean;
}
interface ICalendarProps {
  onShare?: (form: IShareForm) => void;
  isSharing: boolean;
}
function CopyToastComp(
  props: {
    task: Promise<{}>;
    onDone?: () => void;
  } & InjectedIntlProps
) {
  const [progress, setProgress] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [error, setError] = useState(undefined);

  // const test = new Promise(resolve => setTimeout(()=> resolve(), 7000));
  // test.then(c => {
  //   setProgress(100);
  //   safeInvoke(props.onDone);
  // });
  props.task
    .then(c => {
      setProgress(100);
      setIsComplete(true);
      setError(undefined);
      safeInvokeDeprecated(props.onDone);
    })
    .catch(err => {
      setError(err.message);
      setIsComplete(true);
      // safeInvoke(props.onError);
    });
  const add = Math.round(Math.random() * Math.floor(5));
  const next = Math.min(progress + add, 100);
  useEffect(() => {
    let interval = setInterval(() => {
      // setProgress(c => c +  Math.round(Math.random() * Math.floor(5)));
      if (next !== progress) {
        setProgress(next);
      }

      if (next >= 100) {
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Query query={GET_ME}>
      {({ loading, data }) => {
        if (loading) {
          return <SuspenseLoader />;
        }
        let me = data && data.me && data.me.id;
        return (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              marginRight: '2em',
              maxWidth: '270px',
            }}
          >
            <div style={{ alignItems: 'center' }}>
              {next === 100 ? 'Fullført' : 'Kopierer årshjulet'}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '.5em',
              }}
            >
              <ProgressBar
                color={'#FF5C39'}
                value={progress}
                max={100}
                min={0}
                style={{
                  marginLeft: '1em',
                  marginRight: '.5em',
                  width: '100px',
                }}
              />
              {progress}%
            </div>
            {error && <div>error message!</div>}
            {isComplete && (
              <div
                style={{
                  marginTop: '.5em',
                  fontSize: '16px',
                }}
              >
                <FormattedMessage
                  id={'CalendarPage.copyingCalendar'}
                  defaultMessage={
                    'Copying finished, you can find your calendar under "my Calendars". This can take some time... '
                  }
                  values={{
                    br: <br />,
                    link: (
                      <FakeLink
                        onClick={() => {
                          myHistory.push('/person/' + me + '/annual-cycle/');
                        }}
                      >
                        Mine årshjul
                      </FakeLink>
                    ),
                  }}
                />
              </div>
            )}
          </div>
        );
      }}
    </Query>
  );
}
export const CopyToast = injectIntl(CopyToastComp);
class CalendarPageComp extends React.PureComponent<
  ICalendarProps &
    InjectedAuthRouterProps &
    RouteComponentProps<{ uri: string }> &
    InjectedIntlProps,
  ICalendarPageState
> {
  constructor(
    props: InjectedAuthRouterProps &
      ICalendarProps &
      RouteComponentProps<{ uri: string }> &
      InjectedIntlProps
  ) {
    super(props);

    this.state = {
      isNewEventOpen: false,
      isEditAnnualCycleFormOpen: false,
      isFirstReady: false,
      isSuccessMessageActive: false,
      fakeProgressValue: 0,
      isCopyYearWheelOpen: false,
      filter: {
        selectedYear: new Date().getFullYear(),
        selectedMonths: getEmptySelection(false, new Date().getFullYear(), []),
      },
    };

    // console.log('ACP_ctor');
  }

  handleOnYearChange = (
    year: number,
    rings: ReadonlyArray<FieldsOnCalendarRingFragment>
  ) => {
    this.setState({
      filter: {
        ...this.state.filter,
        selectedYear: year,
        selectedMonths: getEmptySelection(false, year, rings),
      },
    });
  };

  handleSelectedMonthsChanged = (selectedMonths: SelectedMonthsDict) => {
    // BUG: not months here. . .

    // console.log('SSX_1_', selectedMonths);
    this.setState({
      filter: {
        ...this.state.filter,
        selectedMonths: selectedMonths,
      },
    });
  };
  addToFakeValue = () => {
    if (this.state.fakeProgressValue < 100) {
      setTimeout(() => {
        this.setState({
          fakeProgressValue:
            this.state.fakeProgressValue +
            Math.round(Math.random() * Math.floor(5)),
        });
      }, 1000);
    }
  };
  render() {
    const { intl } = this.props;
    // console.log(this.stacte.fakeProgressValue, 'value');
    const uri = this.props.match.params.uri;
    const uriId = parseInt(
      uri.substring(uri.lastIndexOf('-') + 1, uri.length),
      10
    );

    return (
      <>
        <div>
          <CalendarQueryComp
            query={CALENDAR}
            variables={{
              id: uriId.toString(),
              year: this.state.filter.selectedYear,
            }}
            fetchPolicy={'cache-and-network'}
          >
            {({ loading, error, data }) => {
              // const test1 = data.me.name
              const { filter } = this.state;
              if (loading) {
                console.log('loading');
              }
              let canWrite = false;
              if (
                data &&
                data.calendar &&
                data.calendar.access &&
                data.calendar.access.myAccess === AccessEnum.WRITE
              ) {
                canWrite = true;
              }
              const isMyYearWheel: boolean =
                (data.calendar &&
                  data.calendar.createdBy &&
                  data.calendar.createdBy.id === data.me.id) ||
                false;
              if (data && data.me && data.me.isAdmin && data.me.isAdmin) {
                canWrite = true;
              }

              if (data && data.calendar) {
                let rings: Array<{
                  id: string;
                  calendar: any;
                  color: string;
                  order: number;
                }> = [
                  {
                    id: '0',
                    calendar: data.calendar,
                    color: data.calendar.color,
                    order: 1,
                  },
                ];

                const calendarData = {
                  [data.calendar.id]: data.calendar,
                };

                const filteredEventInstances: Array<
                  IEventInstanceViewModel
                > = myFilteredInstancesSelector({
                  filter: filter,
                  rings: rings || [],
                  calendars: calendarData,
                  magicRing: undefined,
                  startMonth: '0',
                });

                const leftBox = (
                  <LeftMenuBox>
                    <AnnualCycle
                      calendar={data && data.calendar && data.calendar}
                      isCalendarPage={true}
                      canEdit={canWrite}
                      onEditClick={() => {
                        this.setState({
                          isEditAnnualCycleFormOpen: true,
                        });
                      }}
                      isMyYearWheel={false}
                      isMyYearWheelCalendarPage={isMyYearWheel}
                      userHash={data.me.id}
                      rings={rings}
                      eventInstances={filteredEventInstances}
                      showTags={false}
                      year={this.state.filter.selectedYear}
                      onSelectedYearChanged={(year: number) =>
                        this.handleOnYearChange(year, rings)
                      }
                      onSelectedMonthsChanged={this.handleSelectedMonthsChanged}
                    />
                  </LeftMenuBox>
                );

                return (
                  <>
                    <Helmet>
                      <title>{data.calendar.title}</title>
                    </Helmet>
                    <Page>
                      {data &&
                        data.calendar &&
                        data.calendar.topics &&
                        data.calendar.topics.length > 0 && (
                          <TopDiv>
                            <SmallTitle
                              style={{ fontSize: '12px', marginLeft: '.5em' }}
                            >
                              {intl.formatMessage(
                                commonMessages.yearWheelIsConnectedToTheseTopics
                              )}
                            </SmallTitle>
                            {data &&
                              data.calendar &&
                              data.calendar.topics &&
                              data.calendar.topics.map((c, i) => {
                                return (
                                  <Tag
                                    onClick={() => {
                                      myHistory.push('/topic/' + c.uri);
                                    }}
                                    hasShadow={true}
                                    color={Colors.BLUE}
                                    style={{ margin: '4px' }}
                                    key={i + c.title}
                                    text={c.title.toLowerCase()}
                                  />
                                );
                              })}
                          </TopDiv>
                        )}
                      <FixedHeightContainer>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          {/*<div*/}
                          {/*style={{*/}
                          {/*display: 'flex',*/}
                          {/*alignItems: 'center',*/}
                          {/*marginRight: '.5em',*/}
                          {/*}}*/}
                          {/*>*/}
                          {/*<Toaster*/}
                          {/*ref={this.toastRef}*/}
                          {/*theme={ToastType.LOADING}*/}
                          {/*/>*/}
                          {/*<div*/}
                          {/*style={{*/}
                          {/*display: 'flex',*/}
                          {/*alignItems: 'center',*/}
                          {/*marginRight: '.5em',*/}
                          {/*}}*/}
                          {/*>*/}
                          {/*<Clap*/}
                          {/*idToRefetch={*/}
                          {/*data && data.calendar && data.calendar.id*/}
                          {/*}*/}
                          {/*idToClap={*/}
                          {/*data && data.calendar && data.calendar.id*/}
                          {/*}*/}
                          {/*ObjectToClap={ObjectType.CALENDAR}*/}
                          {/*isClapped={*/}
                          {/*data && data.calendar && data.calendar.isLiked*/}
                          {/*}*/}
                          {/*style={{*/}
                          {/*marginRight: '.5em',*/}
                          {/*fontSize: '14px',*/}
                          {/*fontFamily: 'Lato, sans-serif',*/}
                          {/*}}*/}
                          {/*/>*/}
                          {/*{data && data.calendar && data.calendar.likes}{' '}*/}
                          {/*{intl.formatMessage(commonMessages.claps)}*/}
                          {/*</div>*/}
                          {/*<Button*/}
                          {/*style={{ marginRight: '8px' }}*/}
                          {/*leftIcon={MunikumIcons.copy}*/}
                          {/*size={ButtonSize.Small}*/}
                          {/*text={intl.formatMessage(messages.copyYearWheel)}*/}
                          {/*theme={ColorTheme.White}*/}
                          {/*onClick={() => {*/}
                          {/*this.setState({*/}
                          {/*isCopyYearWheelOpen: true,*/}
                          {/*});*/}
                          {/*}}*/}
                          {/*// disabled={loading}*/}
                          {/*/>*/}

                          {/*<ShareButton*/}
                          {/*meta={data && data.calendar && data.calendar.id}*/}
                          {/*style={{*/}
                          {/*marginLeft: '.5em',*/}
                          {/*}}*/}
                          {/*isSharing={this.props.isSharing}*/}
                          {/*/>*/}
                          {/*{canWrite && (*/}
                          {/*<Button*/}
                          {/*onClick={() => {*/}
                          {/*this.setState({*/}
                          {/*isEditAnnualCycleFormOpen: true,*/}
                          {/*});*/}
                          {/*}}*/}
                          {/*theme={ColorTheme.Blue}*/}
                          {/*leftIcon={MunikumIcons.Edit}*/}
                          {/*text={intl.formatMessage(commonMessages.edit)}*/}
                          {/*size={ButtonSize.Small}*/}
                          {/*style={{*/}
                          {/*marginLeft: '8px',*/}
                          {/*}}*/}
                          {/*/>*/}
                          {/*)}*/}
                          {/*</div>*/}
                        </div>

                        <MyContainer>
                          <MediaQuery
                            query={'(max-width: ' + (sizes.large - 1) + 'px)'}
                          >
                            {leftBox}
                          </MediaQuery>
                          <MediaQuery
                            query={'(min-width: ' + sizes.large + 'px)'}
                          >
                            <ResizableBox
                              width={550}
                              height={1000}
                              minConstraints={[340, 200]}
                              maxConstraints={[1000, 1200]}
                              axis={'x'}
                              lockAspectRatio={false}
                            >
                              {leftBox}
                            </ResizableBox>
                          </MediaQuery>
                          <RightMenuBox>
                            {loading && (
                              <>
                                <Helmet>
                                  <title>
                                    {intl.formatMessage(commonMessages.loading)}
                                  </title>
                                </Helmet>
                                <SuspenseLoader
                                  text={intl.formatMessage(
                                    commonMessages.loading
                                  )}
                                />
                              </>
                            )}
                            {!loading && (
                              <AnnualCycleRightMenu
                                hideFinishedTasks={
                                  data &&
                                  data.me &&
                                  data.me.settings.calendarHideCompleted
                                }
                                doIhaveCalendars={true}
                                isCalendarPage={true}
                                isSharing={this.props.isSharing}
                                onShare={(form: IShareForm) => {
                                  safeInvoke(this.props.onShare, form);
                                }}
                                calendar={
                                  data && data.calendar && data.calendar
                                }
                                onClickNewTask={() => {
                                  this.setState({
                                    isNewEventOpen: true,
                                  });
                                }}
                                canWrite={canWrite}
                                isMyYearWheel={false}
                                year={this.state.filter.selectedYear}
                                yearFrom={this.state.filter.selectedYearFrom}
                                yearTo={this.state.filter.selectedYearTo}
                                startMonth={'0'} // TODO: Do we have settings here?!?
                                isEverythingSelected={getIsEverythingSelected(
                                  this.state.filter
                                )}
                                isNothingSelected={getIsNothingSelected(
                                  this.state.filter
                                )}
                                filter={this.state.filter}
                                eventInstances={filteredEventInstances}
                                userHash={data.me.id}
                                me={data.me || undefined}
                                // onTempHack={() => {
                                //   console.warn('should update temp hack');
                                // }}
                                onTempHack={foreceReloadAll => {
                                  // console.log('TODO: force reload..');
                                  myApolloClient
                                    .query<CalendarQuery>({
                                      query: CALENDAR,
                                      variables: {
                                        id: uriId.toString(),
                                        year: this.state.filter.selectedYear,
                                      },
                                      fetchPolicy: 'network-only',
                                    })
                                    .then(res => console.log('ok reload'))
                                    .catch(err => console.error(err));
                                }}
                              />
                            )}
                          </RightMenuBox>
                        </MyContainer>

                        <CleanDialog
                          canEscapeKeyClose={false} // NOTE: dont enable, bug =)
                          canOutsideClickClose={true}
                          showCloseWarning={CloseWarningOption.SIMPLE}
                          isOpen={this.state.isNewEventOpen}
                          onClose={() =>
                            this.setState({
                              isNewEventOpen: false,
                            })
                          }
                        >
                          <CreateEventForm
                            calendarPage={true}
                            // makes it so it doesn't run create calendar Ring mutation on calendarpage
                            disabledCalendarDropDown={true}
                            newCalendarId={data && data.calendar.id}
                            userHash={data.me && data.me.id}
                            year={this.state.filter.selectedYear}
                            myCalendars={[
                              {
                                id: data && data.calendar.id,
                                title: data && data.calendar.title,
                                description: data && data.calendar.description,
                                color: data && data.calendar.color,
                                createdBy: data && data.calendar.createdBy,
                                access: data && data.calendar.access,
                              },
                            ]}
                            onCancel={() =>
                              this.setState({
                                isNewEventOpen: false,
                              })
                            }
                            onSubmitCalendarPage={async (input, files) => {
                              myApolloClient
                                .mutate({
                                  mutation: ADD_TASK,
                                  variables: { input: input },
                                })
                                .then(result2 => {
                                  myApolloClient.query({
                                    query: CALENDAR,
                                    variables: {
                                      id: uriId.toString(),
                                      year: this.state.filter.selectedYear,
                                    },
                                    fetchPolicy: 'network-only',
                                  });
                                  const freshId =
                                    result2 &&
                                    result2.data.createTask &&
                                    result2.data.createTask.events[0].id;

                                  for (let i = 0; i < files.length; i++) {
                                    if (
                                      files[i].file !== null &&
                                      files[i].file !== null
                                    ) {
                                      try {
                                        const uploadResult = uploadFile(
                                          files[i].file!,
                                          freshId,
                                          files[i].description,
                                          p =>
                                            console.log(
                                              'upload progress:' + freshId
                                            )
                                        );
                                      } catch (e) {
                                        console.log(e);
                                      }
                                    }
                                  }
                                });
                            }}
                            onSaveSuccessful={() => {
                              // console.log('save new successful, reload..');
                              this.setState({
                                isNewEventOpen: false,
                              });
                            }}
                          />
                        </CleanDialog>
                      </FixedHeightContainer>
                    </Page>
                  </>
                );
              } else if (loading) {
                return <SuspenseLoader />;
              } else {
                const navigatingToUrl: string = location.pathname;
                const redirect =
                  navigatingToUrl.length > 0
                    ? '/?redirect=' + encodeURIComponent(navigatingToUrl)
                    : '';
                const noAccess =
                  navigatingToUrl.length > 0
                    ? '/' + encodeURIComponent(navigatingToUrl)
                    : '';
                return (
                  <Redirect
                    to={{
                      pathname: '/no-access' + noAccess,
                      state: { from: myHistory.location },
                    }}
                  />
                );
              }
            }}
          </CalendarQueryComp>
        </div>
      </>
    );
  }
}

const CalendarPage = injectIntl(CalendarPageComp);
export default CalendarPage;
