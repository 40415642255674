import _ from 'lodash';
import * as React from 'react';
import styled from 'styled-components';
import { safeInvokeDeprecated } from '../common';
import { FieldGroup } from '../basic/FieldGroup/FieldGroup';
import { FormLabel } from '../basic/FormLabel/FormLabel';

const pals = require('nice-color-palettes');

const Palette1 = {
  BLACK: '#333333',
  BLUE: '#00B0B9',
  DARKBLUE: '#00778B',
  DARKYELLOW: '#A37F14',
  YELLOW: '#F2A900',
};

const Palette2 = {
  DARKPURPLEX: '#2D2232',
  PURPLEPILLS: '#604C68',
  BLUESTUFF: '#7796B5',
  BOBBY: '#877470',
  PINKY: '#F5EDEE',
};

const PaletteBlueShades = {
  BLUE1: '#001314',
  BLUE2: '#002729',
  BLUE3: '#003a3d',
  BLUE4: '#004e52',
  BLUE5: '#006166',
  BLUE6: '#00747a',
  BLUE7: '#00888f',
  BLUE8: '#00888f',
  BLUE9: '#00888f',
  BLUE10: '#009ba3',
  BLUE11: '#00aeb8',
  BLUE12: '#00c2cc',
  BLUE13: '#00d5e0',
  BLUE14: '#00e9f5',
  BLUE15: '#0af3ff',
  BLUE16: '#1ff4ff',
  BLUE17: '#33f5ff',
  BLUE18: '#47f6ff',
  BLUE19: '#5cf7ff',
  BLUE20: '#70f8ff',
  BLUE21: '#99faff',
  BLUE22: '#99faff',
  BLUE23: '#adfbff',
  BLUE24: '#c2fcff',
  BLUE25: '#d6fdff',
  BLUE26: '#ebfeff',
  BLUE27: '#ffffff',
};

const Container = styled.div``;

const Palette = styled.div`
  display: flex;
  flex-wrap: wrap;

  //margin-right: -.5em;
  //margin-bottom: -.5em;
`;

interface IColorBoxProps {
  color: string;
  isSelected: boolean;
}

const ColorBall = styled.div`
  background-color: ${(props: IColorBoxProps) =>
    !props.isSelected ? props.color : 'transparent'};
  border-radius: 50%;
  cursor: pointer;

  position: relative;

  margin-right: 0.5em;
  margin-bottom: 0.5em;

  width: 1.75em;
  height: 1.75em;
  transform: scale(1);
  transition: transform 100ms ease 0s, box-shadow 100ms ease 0s;

  box-shadow: ${(props: IColorBoxProps) =>
    props.isSelected
      ? `${props.color} 0px 0px 0px .375em inset, ${props.color} 0px 0px 5px`
      : `${props.color} 0px 0px 0px 4px inset`};

  &:hover {
    transform: scale(1.2);
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: ' ';
  }
`;

export interface IColorPickerProps {
  onChange?: (color: string) => void;
  selectedColor?: string;
  label?: string;
  disabled?: boolean;

  palettes?: {
    showDefault: boolean;
    showSecond: boolean;
    showOthers: number;
  };
}

interface IColorPickerState {
  selectedColor?: string;
  id?: string;
}

export class ColorPicker extends React.PureComponent<
  IColorPickerProps,
  IColorPickerState
> {
  public static defaultProps: IColorPickerProps = {
    palettes: {
      showDefault: true,
      showSecond: false,
      showOthers: 0,
    },
  };

  constructor(props: IColorPickerProps) {
    super(props);

    this.state = {
      selectedColor: props.selectedColor || undefined,
    };
  }

  UNSAFE_componentWillMount() {
    const id = _.uniqueId('color-');
    this.setState({
      id: id,
    });
  }

  handleClick = (color: string) => {
    if (!this.props.disabled) {
      this.setState(
        {
          selectedColor: color,
        },
        () => {
          safeInvokeDeprecated(this.props.onChange, this.state.selectedColor);
        }
      );
    }
  };

  render() {
    const { label, palettes } = this.props;
    const { id, selectedColor } = this.state;

    return (
      <FieldGroup>
        {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
        <Container>
          {palettes && palettes.showDefault && (
            <Palette key={'palette_1asdf'}>
              {_.keys(Palette1).map(key => (
                <ColorBall
                  color={Palette1[key]}
                  onClick={() => this.handleClick(Palette1[key])}
                  key={'_adf_' + key}
                  isSelected={selectedColor === Palette1[key]}
                />
              ))}
            </Palette>
          )}
          {palettes && palettes.showSecond && (
            <Palette key={'_pasdf_asdf55'}>
              {_.keys(Palette2).map(key => (
                <ColorBall
                  color={Palette2[key]}
                  onClick={() => this.handleClick(Palette2[key])}
                  key={'ball_' + key}
                  isSelected={selectedColor === Palette2[key]}
                />
              ))}
            </Palette>
          )}
          {this.renderOtherPalettes()}
          {this.renderSelectedPalettes()}
        </Container>
      </FieldGroup>
    );
  }

  renderOtherPalettes() {
    const temp: Array<any> = [];

    if (!this.props.palettes) {
      return null;
    }

    for (let i = 0; i < this.props.palettes.showOthers; i++) {
      temp.push(
        <Palette>
          {pals[i].map((color: string) => (
            <ColorBall
              key={'oth_' + color}
              color={color}
              onClick={() => this.handleClick(color)}
              isSelected={this.state.selectedColor === color}
            />
          ))}
          {/*<div>{i}</div>*/}
        </Palette>
      );
    }

    return temp;
  }

  renderSelectedPalettes() {
    const nice = [1, 14, 15, 24];

    const temp: Array<any> = [];
    nice.map((x: number) => {
      temp.push(
        <Palette key={'palette_' + x}>
          {pals[x].map((color: string) => (
            <ColorBall
              key={'ssc_' + color}
              color={color}
              onClick={() => this.handleClick(color)}
              isSelected={this.state.selectedColor === color}
            />
          ))}
        </Palette>
      );
    });

    return temp;
  }
}
ColorPicker.defaultProps = {
  disabled: false,
};
