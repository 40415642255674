import React, { CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import { safeInvoke } from '../../common';
import { DropDownSelect } from '../DropDownSelect/DropDownSelect';
import RRule, { ByWeekday } from 'rrule';
import { SmallTitle } from './RepetitionPicker';
import { commonMessages } from '../../language/commonMessages';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';

const messages = defineMessages({
  first: {
    id: 'RepetitionCustom.first',
    defaultMessage: 'First',
  },
  second: {
    id: 'RepetitionCustom.second',
    defaultMessage: 'Second',
  },
  third: {
    id: 'RepetitionCustom.third',
    defaultMessage: 'Third',
  },
  fourth: {
    id: 'RepetitionCustom.fourth',
    defaultMessage: 'Fourth',
  },
  last: {
    id: 'RepetitionCustom.last',
    defaultMessage: 'Last',
  },
});

const RepetitionCustomContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 260px;
`;

interface IRepetitionCustomProps {
  // SafeInvokes the rule based on the options you have selected on the weekDaydropDown and the intervalDropdown
  onWeekDayChange: (rule: ByWeekday[]) => void;
  // Value for the interval dropdown so it knows the current value that is selected
  intervalValue: string;
  style?: CSSProperties;
  disabled: boolean;
  // If there is any value that should be set when the component updates
  value: any;
}

function RepetitionCustomComp(
  props: IRepetitionCustomProps & InjectedIntlProps
) {
  const { intl } = props;
  const [intervalValue, setIntevalValue] = React.useState(
    props.intervalValue || '1'
  );
  const [weekDayValue, setWeekDayValue] = React.useState('0');
  // When the component mounts it get's the values from the dropdown if there are any
  useEffect(() => {
    if (props.value) {
      props.value.map(c => {
        const temp = c.n - 1;
        setWeekDayValue(c.weekday);
        if (temp.toString() === '-2') {
          setIntevalValue('4');
        } else {
          setIntevalValue(temp.toString());
        }
      });
    }
  });
  // sets the dropdown value in state, calls the convertToRightDataFormat and safeInvokes the data to RepetitionPicker
  const handleChangeintervalValue = (key: string) => {
    setIntevalValue(key);
    if (key === '4') {
      let rule = convertToRightDataFormat(weekDayValue, '-2');
      safeInvoke(props.onWeekDayChange, rule);
    } else {
      let rule = convertToRightDataFormat(weekDayValue, key);
      safeInvoke(props.onWeekDayChange, rule);
    }
  };

  // sets the dropdown value in state, calls the convertToRightDataFormat and safeInvokes the data to RepetitionPicker
  const handleChangeweekDayValue = (key: string) => {
    setWeekDayValue(key);

    let rule = convertToRightDataFormat(
      key,
      intervalValue === '4' ? '-2' : intervalValue
    );
    safeInvoke(props.onWeekDayChange, rule);
  };

  // converting the values to the rrule format.
  const convertToRightDataFormat = (key: string, interval: string) => {
    const value = +key + 1;
    let rule = [RRule.MO.nth(1)];
    switch (value) {
      case 1:
        rule = [RRule.MO.nth(+interval + 1)];
        break;
      case 2:
        rule = [RRule.TU.nth(+interval + 1)];
        break;

      case 3:
        rule = [RRule.WE.nth(+interval + 1)];
        break;

      case 4:
        rule = [RRule.TH.nth(+interval + 1)];
        break;
      case 5:
        rule = [RRule.FR.nth(+interval + 1)];
        break;

      case 6:
        rule = [RRule.SA.nth(+interval + 1)];
        break;

      case 7:
        rule = [RRule.SU.nth(+interval + 1)];
        break;

      default:
        rule = [RRule.MO.nth(1)];
    }
    return rule;
  };

  // Dropdown values
  let intervalValueItems = [
    {
      key: 1,
      value: intl.formatMessage(messages.first),
    },
    {
      key: 2,
      value: intl.formatMessage(messages.second),
    },
    {
      key: 3,
      value: intl.formatMessage(messages.third),
    },
    {
      key: 4,
      value: intl.formatMessage(messages.fourth),
    },
    {
      key: 5,
      value: intl.formatMessage(messages.last),
    },
  ];
  let weekDayValues = [
    {
      key: 1,
      value: intl.formatMessage(commonMessages.monday),
    },
    {
      key: 2,
      value: intl.formatMessage(commonMessages.tuesday),
    },
    {
      key: 3,
      value: intl.formatMessage(commonMessages.wednesday),
    },
    {
      key: 4,
      value: intl.formatMessage(commonMessages.thursday),
    },
    {
      key: 5,
      value: intl.formatMessage(commonMessages.friday),
    },
    {
      key: 6,
      value: intl.formatMessage(commonMessages.saturday),
    },
    {
      key: 7,
      value: intl.formatMessage(commonMessages.sunday),
    },
  ];

  return (
    <RepetitionCustomContainer style={props.style}>
      <div style={{ display: 'flex' }}>
        <SmallTitle style={{ fontSize: '12px' }}>Den</SmallTitle>
      </div>
      <DropDownSelect
        disabled={props.disabled}
        items={intervalValueItems}
        value={intervalValue}
        fieldGroupStyle={{
          marginLeft: '.75em',
          width: '6em',
          height: '30px',
        }}
        showEmptyOption={false}
        accessor={'value'}
        onChangeSelected={e => handleChangeintervalValue(e.toString())}
      />
      <DropDownSelect
        items={weekDayValues}
        disabled={props.disabled}
        value={weekDayValue}
        showEmptyOption={false}
        fieldGroupStyle={{
          marginLeft: '.5em',
          width: '6em',
          height: '30px',
        }}
        accessor={'value'}
        onChangeSelected={e => handleChangeweekDayValue(e.toString())}
      />
    </RepetitionCustomContainer>
  );
}
export const RepetitionCustom = injectIntl(RepetitionCustomComp);
