import * as React from 'react';

const Grid: React.SFC<{}> = props => {
  const black: any = [];
  const blue: any = [];
  const grey = 'rgba(208,211,212,0.7)';

  for (let x = 1; x < 100; x++) {
    const temp = x * 10;
    const max = 1000;

    // vertical lines:
    black.push(
      <path
        id={'grid_x_' + x}
        key={'grid_x_' + x}
        stroke={grey}
        d={`M ${temp} 0 V ${temp} ${max}`}
      />
    );
    blue.push(
      <path
        key={'grid_y_' + x}
        id={'grid_y_' + x}
        stroke={grey}
        d={`M 0 ${temp} H ${max} ${temp} `}
      />
    );
  }

  return (
    <g id={'w_grid'}>
      <g id={'grid_a'}>{black}</g>
      <g id={'grid_b'}>{blue}</g>
    </g>
  );
};

const Origin: React.SFC<{}> = props => (
  <g id={'w_origin'}>
    <circle cx={500} cy={500} r={5} fill={'red'} />
  </g>
);

const CircleHelpers: React.SFC<{}> = props => (
  <g id="circle-helpers">
    <g id="outer-debug-ring">
      <path
        d="M500,79.6c56.8,0,111.8,11.1,163.6,33c50.1,21.2,95,51.5,133.6,90.1c38.6,38.6,68.9,83.6,90.1,133.6
			c21.9,51.8,33,106.9,33,163.6s-11.1,111.8-33,163.6c-21.2,50.1-51.5,95-90.1,133.6c-38.6,38.6-83.6,68.9-133.6,90.1
			c-51.8,21.9-106.9,33-163.6,33s-111.8-11.1-163.6-33c-50.1-21.2-95-51.5-133.6-90.1c-38.6-38.6-68.9-83.6-90.1-133.6
			c-21.9-51.8-33-106.9-33-163.6s11.1-111.8,33-163.6c21.2-50.1,51.5-95,90.1-133.6s83.6-68.9,133.6-90.1
			C388.2,90.7,443.2,79.6,500,79.6 M500,78.6C267.3,78.6,78.6,267.3,78.6,500S267.3,921.4,500,921.4S921.4,732.7,921.4,500
			S732.7,78.6,500,78.6L500,78.6z"
      />
    </g>
    <g id="inner-debug-ring_1_">
      <path
        className="st6"
        d="M500,714.5c-57.3,0-111.2-22.3-151.7-62.8s-62.8-94.4-62.8-151.7s22.3-111.2,62.8-151.7s94.4-62.8,151.7-62.8
			s111.2,22.3,151.7,62.8s62.8,94.4,62.8,151.7s-22.3,111.2-62.8,151.7S557.3,714.5,500,714.5z"
      />
      <path
        d="M500,286c28.9,0,56.9,5.7,83.3,16.8c25.5,10.8,48.4,26.2,68,45.9c19.7,19.7,35.1,42.5,45.9,68
			c11.2,26.4,16.8,54.4,16.8,83.3c0,28.9-5.7,56.9-16.8,83.3c-10.8,25.5-26.2,48.4-45.9,68s-42.5,35.1-68,45.9
			C556.9,708.3,528.9,714,500,714c-28.9,0-56.9-5.7-83.3-16.8c-25.5-10.8-48.4-26.2-68-45.9c-19.7-19.7-35.1-42.5-45.9-68
			C291.7,556.9,286,528.9,286,500c0-28.9,5.7-56.9,16.8-83.3c10.8-25.5,26.2-48.4,45.9-68c19.7-19.7,42.5-35.1,68-45.9
			C443.1,291.7,471.1,286,500,286 M500,285c-118.7,0-215,96.3-215,215s96.3,215,215,215s215-96.3,215-215S618.7,285,500,285L500,285
			z"
      />
    </g>
  </g>
);

export const DebugInfo: React.SFC<{
  showGrid: boolean;
  showCircle: boolean;
}> = props => (
  <g id={'w_debug'}>
    {props.showGrid && <Grid />}
    {props.showCircle && <CircleHelpers />}
    {props.showGrid && <Origin />}
  </g>
);
