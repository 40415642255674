import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect';
import Query, { QueryResult } from 'react-apollo/Query';
import gql from 'graphql-tag';
import {
  CREATE_COMMENT,
  fieldsOnComment,
  fieldsOnMeSimple,
  fieldsOnReplies,
  fieldsOnUserFile,
  SET_STATUS,
  UPDATE_COMMENT,
} from '../../Discussion/DiscussionItemPage/DiscussionItemPage';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import { myHistory } from '../../../index';
import {
  isAdminSelector,
  simpleMeSelector,
  usernameSelector,
} from '../../../modules/auth/auth';
import {
  FOLLOW_GRANT,
  UNFOLLOW_GRANT,
} from '../../Dashboard/ActionValueDashboard';
import { shareActionCreator } from '../../../modules/meta/meta';
import {
  StatusMessage,
  StatusMessageCenterContainer,
  ThemeColor,
} from '../../../components/basic/StatusMessage/StatusMessage';
import { Page } from '../../../components/layout/Page/Page';
import {
  ActionValueQuery,
  ActionValueVariables,
  ContentStatus,
  CreateCommentInput,
} from '../../../models/types';
import Helmet from 'react-helmet';
import { IFileItem } from '../../../components/basic/UserFile/FileUploaderPicker';
import {
  saveCommentActionCreator,
  updateCommentActionCreator,
} from '../../../modules/comment/comment';
import {
  ICommentEditForm,
  ICommentSaveForm,
} from '../../../services/commentService';
import { uploadFile } from '../../../services/fileservice';
import { IPersonSimple } from '../../../services/models/discussion';
import { IShareForm } from '../../../services/metaService';
import { SuspenseLoader } from '../../../components/basic/Loader/CircleSpinner';
import { commonMessages } from '../../../components/language/commonMessages';
import { errorMessages } from '../../../components/language/errorMessages';
import { shouldScrollToPosition } from '../../../modules/ui/mainLayout/mainLayout';
import { ActionValueView } from '../../../components/actionValue/ActionValueView';
import { RootState } from '../../../modules/rootReducer';
import {
  fieldsOnKostraFunction,
  fieldsOnKostraFunctionGroup,
} from '../../../components/actionValue/kostraQueries';
import { convertFromRaw } from 'draft-js';
// import {
//   fieldsOnKostraFunction,
//   fieldsOnKostraFunctionGroup
// } from '../../../components/actionValue/KostraFunctionDropDown';

export interface IActionValueItemPageProps {
  isAdmin: boolean;
  username?: string;

  simpleMe?: IPersonSimple;
  newCommentIsSaving: boolean;
  newCommentSuccess: boolean;
  isDataUpdating: boolean;
  isSharing: boolean;
  calendarCode: string;
}

export interface IActionValueItemPageDispatch {
  onSaveComment: (values: ICommentSaveForm, files: IFileItem[]) => void;
  onUpdateComment: (values: ICommentEditForm) => void;
  onShare: (form: IShareForm) => void;
  onTopicClick?: (uri: string) => void;
  onShouldScrollToPosition?: (pos: { x: number; y: number }) => void;
}

export const fieldsOnActionValue = gql`
  fragment FieldsOnActionValue on ActionValue {
    id
    title
    dateCreated
    lastUpdated
    uri
    likes
    description
    status
    isLiked
    kostraFunctions {
      ...FieldsOnKostraFunction
    }
    isFollowing
    currentRevision {
      id
      description
      body
      year
      sourceUrl
      category
      type
      links {
        label
        href
      }
      source {
        id
        name
      }
      radarGraph {
        name
        sum
        type
      }
      deadlines {
        id
        date
        type
      }
    }
    topics {
      id
      title
      uri
    }
    files {
      ...fieldsOnUserFile
    }
    comments {
      ...fieldsOnComment
      replies {
        ...fieldsOnReplies
      }
    }
  }
  ${fieldsOnComment}
  ${fieldsOnUserFile}
  ${fieldsOnReplies}
  ${fieldsOnKostraFunctionGroup}
  ${fieldsOnKostraFunction}
`;

export const ACTION_VALUE = gql`
  query ActionValue($id: ID!) {
    actionValue(id: $id) {
      ...FieldsOnActionValue
    }
    me {
      ...fieldsOnMeSimple
    }
  }
  ${fieldsOnMeSimple}
  ${fieldsOnActionValue}
`;
const messages = defineMessages({
  DeleteFileWarning: {
    id: 'ActionValueItemPage.DeleteFileWarning',
    defaultMessage: 'Are you sure you want to delete the file?',
  },
});

// class ActionValueQueryComp extends Query<
//   ActionValueQuery,
//   ActionValueVariables
// > {}
class ActionValueQueryComp extends Query<any, any> {}

const ActionValueComponent = class extends React.PureComponent<
  IActionValueItemPageProps &
    InjectedAuthRouterProps &
    RouteComponentProps<{ uri: string }> &
    InjectedIntlProps &
    IActionValueItemPageDispatch,
  {}
> {
  handleCreateComment = async (
    actionValueId: string,
    rawMarkdown: string,
    files2: IFileItem[]
  ) => {
    // first we create the comment
    let descText = '';
    try {
      descText = convertFromRaw(JSON.parse(rawMarkdown)).getPlainText();
    } catch (e) {
      descText = rawMarkdown;
    }
    const result = await myApolloClient.mutate({
      mutation: CREATE_COMMENT,
      variables: {
        input: {
          meta: actionValueId,
          parent: undefined, // ??
          message: rawMarkdown,
          messageText: descText,
          status: ContentStatus.PUBLISHED,
        },
      },
    });

    // then we read out the newly created comment's id (metaId) from the result:
    const freshId =
      (result.data &&
        result.data.createComment &&
        result.data.createComment.id) ||
      undefined;

    // TODO: mandag, skriv uploadMultipleFile i fileservice.ts

    // const uploadResult = await uploadMultipleFiles(files2);

    // then we upload all files attached to the comment's metaId:
    for (let i = 0; i < files2.length; i++) {
      if (files2[i].file !== null && files2[i].file !== null) {
        try {
          const uploadResult = await uploadFile(
            files2[i].file!,
            freshId,
            files2[i].description,
            p => console.log('upload progress: ' + p)
          );
          if (!uploadResult.greatSuccess) {
            console.warn('Oops, upload failed: ' + files2[i].filename);
            // NOTE: we could take some action here? Now we just continue to the next file...
          }
        } catch (uploadErr) {
          console.log('upload err', uploadErr);
        }
      }
    }

    // finally we update the cache by re-fetching the entire discussion (with comments)
    const updateCacheResult = await myApolloClient.query({
      query: ACTION_VALUE,
      variables: {
        id: actionValueId,
      },
      fetchPolicy: 'network-only',
    });

    // console.log('Borat: GREAT SUCCESS!', updateCacheResult);
  };

  handleUpdateComment = async (
    commentId: string,
    rawMarkdown: string,
    files2: IFileItem[],
    actionvalueId: string
  ) => {
    // first we create the comment
    let descText = '';
    try {
      descText = convertFromRaw(JSON.parse(rawMarkdown)).getPlainText();
    } catch (e) {
      descText = rawMarkdown;
    }
    const result = await myApolloClient.mutate({
      mutation: UPDATE_COMMENT,
      variables: {
        input: {
          id: commentId,
          meta: commentId,
          parent: undefined, // ??
          message: rawMarkdown,
          messageText: descText,
          status: ContentStatus.PUBLISHED,
        },
      },
    });
    // then we read out the newly created comment's id (metaId) from the result:
    const freshId =
      (result.data &&
        result.data.updateComment &&
        result.data.updateComment.id) ||
      undefined;
    // console.log(freshId, 'id');
    // then we upload all files attached to the comment's metaId:
    if (files2) {
      for (let i = 0; i < files2.length; i++) {
        if (files2[i].file !== null && files2[i].file !== null) {
          try {
            const uploadResult = await uploadFile(
              files2[i].file!,
              freshId,
              files2[i].description,
              p => console.log('upload progress: ' + p)
            );
            if (!uploadResult.greatSuccess) {
              console.warn('Oops, upload failed: ' + files2[i].filename);
              // NOTE: we could take some action here? Now we just continue to the next file...
            }
          } catch (uploadErr) {
            console.log('upload err', uploadErr);
          }
        }
      }
    }
    // finally we update the cache by re-fetching the entire discussion (with comments)
    const updateCacheResult = await myApolloClient.query({
      query: ACTION_VALUE,
      variables: {
        id: actionvalueId,
      },
      fetchPolicy: 'network-only',
    });

    // console.log('Borat: GREAT SUCCESS!', updateCacheResult);
  };
  render() {
    const {
      isAdmin,
      username,
      intl,
      onSaveComment,
      onUpdateComment,
      simpleMe,
    } = this.props;

    const id = this.props.match.params.uri.substring(
      this.props.match.params.uri.lastIndexOf('-') + 1,
      this.props.match.params.uri.length
    );

    return (
      <ActionValueQueryComp
        query={ACTION_VALUE}
        variables={{ id: id }}
        fetchPolicy={'cache-and-network'}
        // child
        children={(
          result: QueryResult<ActionValueQuery, ActionValueVariables>
        ) => {
          const data = result.data;
          const loading = result.loading;
          const error = result.error;

          const showLoader = !data && loading;
          let title = '';
          if (data && data.actionValue && data.actionValue.title) {
            title = data.actionValue.title;
          } else if (data && data.actionValue === null) {
            title = intl.formatMessage(errorMessages.notFound);
          }

          // console.log(
          //   'AVITEM, loading:' +
          //     loading.toString() +
          //     'data? ' +
          //     (data ? 'Y' : 'N') +
          //     ' stat: ' +
          //     result.networkStatus.toString(),
          //   result.networkStatus + ' SPIN: ' + showLoader.toString()
          // );

          return (
            <Page>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              {!data && loading && (
                <SuspenseLoader
                  text={intl.formatMessage(commonMessages.loading)}
                />
              )}
              {error && (
                <StatusMessage
                  theme={ThemeColor.ERROR_BLACK}
                  title={intl.formatMessage(errorMessages.error)}
                  text={error}
                />
              )}
              {data && data.actionValue === null && (
                <StatusMessageCenterContainer>
                  <StatusMessage
                    theme={ThemeColor.ERROR_BLACK}
                    title={intl.formatMessage(errorMessages.notFound)}
                    text={
                      <FormattedMessage
                        id={'ActionValueItemPage.notFound'}
                        defaultMessage={
                          'Grant with id {id} was not found!{br}Parsed uri: {uri}'
                        }
                        values={{
                          id: id,
                          uri: this.props.match.params.uri,
                          br: <br />,
                        }}
                      />
                    }
                    buttonText={intl.formatMessage(commonMessages.retry)}
                    onClickButton={() => window.location.reload()}
                  />
                </StatusMessageCenterContainer>
              )}
              {data && data.actionValue && (
                <ActionValueView
                  grantId={data.actionValue && data.actionValue.id}
                  isFollowing={data.actionValue && data.actionValue.isFollowing}
                  username={username}
                  item={data.actionValue}
                  me={data.me}
                  onDeleteSubComment={(
                    id2: string | number,
                    message: string,
                    commentID: string | number
                  ) => {
                    let descText = '';
                    try {
                      descText = convertFromRaw(
                        JSON.parse(message)
                      ).getPlainText();
                    } catch (e) {
                      descText = message;
                    }
                    myApolloClient
                      .mutate({
                        mutation: UPDATE_COMMENT,
                        variables: {
                          input: {
                            id: +id2,
                            meta: data.actionValue && data.actionValue.id,
                            status: ContentStatus.OBSOLETE,
                            message: message,
                            messageText: descText,
                            parent: commentID,
                          },
                        },
                      })
                      .then(() => {
                        myApolloClient.query({
                          query: ACTION_VALUE,
                          variables: {
                            id: data.actionValue && data.actionValue.id,
                          },
                          fetchPolicy: 'network-only',
                        });
                      });
                  }}
                  onFileDelete={(commentFileId: string) => {
                    if (
                      confirm(intl.formatMessage(messages.DeleteFileWarning))
                    ) {
                      myApolloClient
                        .mutate({
                          mutation: SET_STATUS,
                          variables: {
                            input: {
                              id: commentFileId,
                              status: ContentStatus.OBSOLETE,
                            },
                          },
                        })
                        .then(() => {
                          myApolloClient.query({
                            query: ACTION_VALUE,
                            variables: {
                              id: data.actionValue && data.actionValue.id,
                            },
                            fetchPolicy: 'network-only',
                          });
                        });
                    }
                  }}
                  onDeleteComment={(idComment, commentMessage) => {
                    let descText = '';
                    try {
                      descText = convertFromRaw(
                        JSON.parse(commentMessage)
                      ).getPlainText();
                    } catch (e) {
                      descText = commentMessage;
                    }
                    myApolloClient
                      .mutate({
                        mutation: UPDATE_COMMENT,
                        variables: {
                          input: {
                            id: idComment,
                            meta: idComment,
                            status: ContentStatus.OBSOLETE,
                            message: commentMessage,
                            messageText: descText,
                            parent: undefined,
                          },
                        },
                      })
                      .then(() => {
                        myApolloClient.query({
                          query: ACTION_VALUE,
                          variables: {
                            id: data.actionValue && data.actionValue.id,
                          },
                          fetchPolicy: 'network-only',
                        });
                      });
                  }}
                  onSaveSubComment={(values: CreateCommentInput) => {
                    let descText = '';
                    try {
                      descText = convertFromRaw(
                        JSON.parse(values.message)
                      ).getPlainText();
                    } catch (e) {
                      descText = values.message;
                    }
                    myApolloClient
                      .mutate({
                        mutation: CREATE_COMMENT,
                        variables: {
                          input: {
                            meta: data.actionValue && data.actionValue.id,
                            message: values.message,
                            messageText: descText,
                            parent: values.meta,
                            status: ContentStatus.PUBLISHED,
                          },
                        },
                      })
                      .then(() => {
                        myApolloClient.query({
                          query: ACTION_VALUE,
                          variables: {
                            id: data.actionValue && data.actionValue.id,
                          },
                          fetchPolicy: 'network-only',
                        });
                      });
                  }}
                  onSaveComment={(values: CreateCommentInput, files) => {
                    this.handleCreateComment(
                      values.meta,
                      values.message,
                      files
                    );
                  }}
                  onUpdateComment={(values, files) => {
                    this.handleUpdateComment(
                      values.id || '',
                      values.message || '',
                      files && files,
                      (data.actionValue && data.actionValue.id) || ''
                    );
                  }}
                  onFollowClick={() => {
                    if (data.actionValue && data.actionValue.isFollowing) {
                      myApolloClient
                        .mutate({
                          mutation: UNFOLLOW_GRANT,
                          variables: {
                            id: data.actionValue && data.actionValue.id,
                          },
                        })
                        .then(() => {
                          // console.log('unfollowed ok');
                          myApolloClient
                            .query({
                              query: ACTION_VALUE,
                              variables: {
                                id: data.actionValue && data.actionValue.id,
                              },
                              fetchPolicy: 'network-only',
                            })
                            .then(() => console.log('refetch ok'));
                        });
                    } else {
                      myApolloClient
                        .mutate({
                          mutation: FOLLOW_GRANT,
                          variables: {
                            id: data.actionValue && data.actionValue.id,
                          },
                        })
                        .then(() => {
                          // console.log('unfollowed ok');
                          myApolloClient
                            .query({
                              query: ACTION_VALUE,
                              variables: {
                                id: data.actionValue && data.actionValue.id,
                              },
                              fetchPolicy: 'network-only',
                            })
                            .then(() => console.log('refetch ok'));
                        });
                    }
                  }}
                  newCommentIsSaving={this.props.newCommentIsSaving}
                  newCommentSuccess={this.props.newCommentSuccess}
                  isDataUpdating={this.props.isDataUpdating}
                  onShare={this.props.onShare}
                  isSharing={this.props.isSharing}
                  calendarCode={this.props.calendarCode}
                  onTopicClick={this.props.onTopicClick}
                  onShouldScrollToPosition={this.props.onShouldScrollToPosition}
                />
              )}
            </Page>
          );
        }}
      />
    );
  }
};

// mapping from global state to container props:
// static typing on arrow function example:
const mapStateToProps = (
  state: RootState,
  ownProps: IActionValueItemPageProps & RouteComponentProps<{ uri: string }>
): IActionValueItemPageProps => ({
  isAdmin: isAdminSelector(state),
  username: usernameSelector(state),
  simpleMe: simpleMeSelector(state),
  newCommentIsSaving: state.meta.newComment.isSaving,
  newCommentSuccess: state.meta.newComment.saveSuccess,
  isDataUpdating:
    state.meta.newComment.isSaving ||
    state.entities.actionValueMetas.isFetchingList ||
    state.entities.actionValueMetas.isFetchingSingle ||
    state.entities.discussionMetas.isFetchingSingle ||
    state.entities.discussionMetas.isFetchingList,
  isSharing: state.meta.share.isSharing,
  calendarCode:
    (state.auth.user &&
      state.auth.user.profile &&
      state.auth.user.profile.calendarCode) ||
    '',
});

const mapDispatchToProps = (
  dispatch: Dispatch<{}>
): IActionValueItemPageDispatch => {
  return {
    onSaveComment: (values: ICommentSaveForm, files: IFileItem[]) =>
      dispatch(
        saveCommentActionCreator.started({ commentForm: values, files: files })
      ),
    onUpdateComment: (values: ICommentEditForm) =>
      dispatch(updateCommentActionCreator.started(values)),

    onShare: (form: IShareForm) => {
      dispatch(shareActionCreator.started(form));
    },
    onTopicClick: (uri: string) => {
      myHistory.push(uri);
    },
    // loadActionValue: (actionValueContentId: string) => {
    //   dispatch(getActionValueActionCreator.started({id: actionValueContentId}));
    //   // dispatch(getActionValueMetaActionCreator.started({id: ????}))
    // }
    onShouldScrollToPosition: (pos: { x: number; y: number }) => {
      dispatch(shouldScrollToPosition.started(pos));
    },
  };
};

const ActionValueItemPage = connect<IActionValueItemPageProps, {}, {}>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ActionValueComponent));
export default ActionValueItemPage;
