import * as React from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect';
import Helmet from 'react-helmet';
import gql from 'graphql-tag';
import { TOPIC_QUERY } from '../TopicItemPage/TopicItemPage';
import { TopicForm } from '../../../components/topics/TopicForm';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import { myHistory } from '../../../index';
import {
  AccessEnum,
  ContentStatus,
  ContentVisibilityEnum,
  OrganizationAccessInput,
  OrganizationGroupAccessInput,
  PersonGroupAccessInput,
  TopicCategoryEnum,
  TopicQueryLinks,
  UpdateTopicInput,
  UpdateTopicMutation,
  UpdateTopicVariables,
} from '../../../models/types';
import { FlexGrid } from '../../../components/layout/FlexGrid/FlexGrid';
import { PageTitle } from '../../../components/layout/Page/Page';
import { ITopicCreateOrUpdateForm } from '../../../services/topicService';
import { ITopicItem } from '../../../services/models/topic';
import { FetchResult, Query } from 'react-apollo';
import * as _ from 'lodash';
import { convertFromRaw } from 'draft-js';

export interface ITopicEditPageProps {
  isAdmin: boolean;
  saveErrorMessage?: string;
  topicId: string;
  isSaving: boolean;
  currentItem?: ITopicItem;
}

const messages = defineMessages({
  toopicConfirmDelete: {
    id: 'TopicEditPage.topicConfirmDelete',
    defaultMessage: 'Are you sure you want to delete your group?',
  },
});

interface ITopicEditPageDispatch {
  onSaveTopic?: (values: ITopicCreateOrUpdateForm) => void;
  onCancel?: () => void;
}
const UPDATE_TOPIC = gql`
  mutation UpdateTopic($input: UpdateTopicInput!) {
    updateTopic(input: $input) {
      id
      uri
    }
  }
`;
class TopicEditPageComp extends React.PureComponent<
  InjectedAuthRouterProps &
    InjectedIntlProps &
    ITopicEditPageProps &
    ITopicEditPageDispatch &
    RouteComponentProps<{ uri: string }>,
  {}
> {
  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <div style={{ maxWidth: '84.125em', margin: '0 auto' }}>
        <PageTitle style={{ marginBottom: '.5em' }}>Rediger gruppe</PageTitle>
        <FlexGrid>
          <Helmet>
            <title>Topics</title>
          </Helmet>
          <Query
            query={TOPIC_QUERY}
            variables={{ id: this.props.match.params.uri }}
          >
            {({ loading, error, data }) => {
              const topic = data && data.topic;

              if (loading) {
                return '';
              }
              let doIhaveAccess = false;
              if (
                data &&
                data.topic &&
                data.topic.access &&
                data.topic.access.myAccess === AccessEnum.WRITE
              ) {
                doIhaveAccess = true;
              }

              let links: TopicQueryLinks[] = [];
              if (data.topic && data.topic.currentRevision) {
                data.topic.currentRevision.links.map(link => {
                  links.push(link);
                });
              }
              return (
                <TopicForm
                  isTopicEdit={true}
                  disabled={!doIhaveAccess}
                  initialData={{
                    createdBy: topic && topic.createdBy,
                    title: (topic && topic.title) || '',
                    description: (topic && topic.description) || '',
                    body: '',
                    status: ContentStatus.PUBLISHED,
                    sector:
                      topic &&
                      topic.currentRevision.sector &&
                      topic.currentRevision.sector.id,
                    links: links || [],
                    category:
                      (topic && topic.currentRevision.category) ||
                      TopicCategoryEnum.TOPIC,
                    kostraFunctions:
                      (topic &&
                        topic.currentRevision &&
                        topic.currentRevision.kostraFunctions) ||
                      [],
                    access: topic && topic.access,
                  }}
                  errorMessage={this.props.saveErrorMessage}
                  onDelete={() => {
                    if (
                      confirm(
                        this.props.intl.formatMessage(
                          messages.toopicConfirmDelete
                        )
                      )
                    ) {
                      const organizations =
                        data.topic &&
                        data.topic.access &&
                        data.topic.access.organizations &&
                        data.topic.access.organizations.map(
                          (c: OrganizationAccessInput) => {
                            return {
                              access: c.access,
                              organization: c.organization.id,
                            };
                          }
                        );
                      const organizationGroups =
                        data.topic &&
                        data.topic.access &&
                        data.topic.access.organizationGroups &&
                        data.topic.access.organizationGroups.map(
                          (c: OrganizationGroupAccessInput) => {
                            return {
                              access: c.access,
                              organizationGroup: c.organizationGroup.id,
                            };
                          }
                        );
                      const persons =
                        data.topic &&
                        data.topic.access &&
                        data.topic.access.persons &&
                        data.topic.access.persons.map((c: any) => {
                          return {
                            access: c.access,
                            person: c.person.id,
                          };
                        });
                      const groups =
                        data.topic &&
                        data.topic.access &&
                        data.topic.access.groups &&
                        data.topic.access.groups.map(
                          (c: PersonGroupAccessInput) => {
                            return {
                              access: c.access,
                              group: c.group.id,
                            };
                          }
                        );
                      const links2 =
                        data.topic &&
                        data.topic.currentRevision &&
                        data.topic.currentRevision.links &&
                        data.topic.currentRevision.links.map((c: any) => {
                          return {
                            href: c.href,
                            label: c.label,
                          };
                        });
                      const kostras =
                        topic &&
                        topic.currentRevision &&
                        topic.currentRevision.kostraFunctions.map(x => {
                          return x.id;
                        });
                      let descText = '';
                      try {
                        descText = convertFromRaw(
                          JSON.parse(topic.description)
                        ).getPlainText();
                      } catch (e) {
                        descText = (topic && topic.description) || '';
                      }
                      const input: UpdateTopicInput = {
                        id: data.topic.id,
                        title: (topic && topic.title) || '',
                        description: (topic && topic.description) || '',
                        descriptionText: descText || '',
                        body: '',
                        status: ContentStatus.OBSOLETE,
                        sector:
                          topic &&
                          topic.currentRevision.sector &&
                          topic.currentRevision.sector.id,
                        links: links2 || [],
                        category:
                          (topic && topic.currentRevision.category) || '',
                        kostraFunctions: kostras || [],
                        access: {
                          isPrivate: data.topic.access.isPrivate,
                          visibility:
                            data.topic.access.visibility ||
                            ContentVisibilityEnum.PUBLIC,
                          organizationGroups: organizationGroups || [],
                          organizations: organizations || [],
                          groups: groups || [],
                          persons: persons || [],
                        },
                      };
                      delete data.topic.access.organizations.__typename;
                      myApolloClient
                        .mutate<UpdateTopicMutation, UpdateTopicVariables>({
                          mutation: UPDATE_TOPIC,
                          variables: {
                            input,
                          },
                        })
                        .then((res: FetchResult) => {
                          myHistory.push('/topic/list');
                        });
                    }
                  }}
                  onSave={(form: ITopicCreateOrUpdateForm) => {
                    if (form.category === '__empty__') {
                      form.category = '';
                    } else if (form.sector === '__empty__') {
                      form.sector = '';
                    } else {
                      const t =
                        form.links &&
                        form.links.map((to: any) => {
                          delete to.__typename;
                        });
                      const form2 = Object.assign({}, form, {
                        id: this.props.match.params.uri,
                      });
                      const temp = _.cloneDeep(form);
                      const accessToSend: any = {
                        persons: [],
                        groups: [],
                        organizations: [],
                        organizationGroups: [],
                      };
                      if (temp.access.persons.length > 0) {
                        temp.access.persons.map((p: any) => {
                          accessToSend.persons.push({
                            access: p.access,
                            person: p.person.id,
                          });
                        });
                      }
                      if (temp.access.groups.length > 0) {
                        temp.access.groups.map((g: PersonGroupAccessInput) => {
                          accessToSend.groups.push({
                            access: g.access,
                            group: g.group.id,
                          });
                        });
                      }
                      if (temp.access.organizations.length > 0) {
                        temp.access.organizations.map(
                          (o: OrganizationAccessInput) => {
                            accessToSend.organizations.push({
                              access: o.access,
                              organization: o.organization.id,
                            });
                          }
                        );
                      }
                      if (temp.access.organizationGroups.length > 0) {
                        temp.access.organizationGroups.map(
                          (org: OrganizationGroupAccessInput) => {
                            accessToSend.organizationGroups.push({
                              access: org.access,
                              organizationGroup: org.organizationGroup.id,
                            });
                          }
                        );
                      }

                      temp.access.persons = accessToSend.persons;
                      temp.access.groups = accessToSend.groups;
                      temp.access.organizations = accessToSend.organizations;
                      temp.access.organizationGroups =
                        accessToSend.organizationGroups;

                      delete temp.access.__typename;
                      delete temp.access.myAccess;
                      let descriptionText = '';
                      try {
                        descriptionText = convertFromRaw(
                          JSON.parse(form2.description)
                        ).getPlainText();
                      } catch (e) {
                        descriptionText = form2.description;
                      }
                      myApolloClient
                        .mutate<UpdateTopicMutation, UpdateTopicVariables>({
                          mutation: UPDATE_TOPIC,
                          variables: {
                            input: {
                              body: form2.body,
                              category: form2.category as TopicCategoryEnum,
                              description: form2.description,
                              descriptionText: form2.description
                                ? descriptionText
                                : '',
                              id: form2.id,
                              kostraFunctions: form2.kostraFunctions.map(
                                f => f.id
                              ),
                              links: form2.links as any,
                              sector: form2.sector,
                              status: form2.status,
                              title: form2.title,
                              access: temp.access,
                            },
                          },
                        })
                        .then((res: FetchResult) => {
                          myHistory.push(/topic/ + this.props.match.params.uri);
                        });
                    }
                  }}
                  onCancel={() => {
                    myHistory.push(/topic/ + (topic && topic.uri));
                  }}
                />
              );
            }}
          </Query>
        </FlexGrid>
      </div>
    );
  }
}

const TopicEditPage = injectIntl(TopicEditPageComp);
export default TopicEditPage;
