import * as _ from 'lodash';
import { InjectedIntlProps } from 'react-intl';
import * as React from 'react';
import { CSSProperties } from 'react';
import gql from 'graphql-tag';
import Query, { QueryResult } from 'react-apollo/Query';
import styled from 'styled-components';
import { commonMessages } from '../language/commonMessages';
import { myHistory } from '../../index';
import { Colors, isFunction, safeInvokeDeprecated } from '../common';
import { MunikumKeys } from '../common/keys';
import {
  OmniSearchQueryQuery,
  OmniSearchQueryVariables,
} from '../../models/types';
import { TextField } from '../basic';
import { Tag } from '../basic/Tag/Tag';
import { MunikumIcons } from '../common/icons';

interface IOmniSearchProps {
  onCancel?: () => void;
  onSaveSuccess?: () => void;
  close: () => void;
  style?: CSSProperties;
}

interface IOmniSearchState {
  query: string;
  selectedIndex: number;
  list: Array<ISearchItem>;
}

interface ISearchItem {
  id: string;
  title: string;
  description: string;
  uri: string;
  type: string;
}

const SearchDiv = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  padding: 2em;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  flex: 1 1 100%;
  display: flex;
  border: 1px solid rgba(208, 211, 212, 0.2);
  flex-direction: column;
`;

const SearchResultDiv = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const SearchItemDiv = styled.div`
  cursor: pointer;
  height: 33px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  &:hover {
    text-decoration: none;
  }
`;

const SearchItemTitle = styled.span`
  padding-left: 10px;
`;

const SEARCH_QUERY = gql`
  query OmniSearchQuery($query: String!) {
    search(query: $query) {
      #            persons {
      #                id
      #                name
      #            }
      actionValues {
        id
        title
        description
        uri
      }
      discussions {
        id
        title
        description
        uri
      }
      topics {
        id
        title
        description
        uri
      }
      calendars {
        id
        title
        description
        uri
      }
    }
  }
`;

class SearchQuery extends Query<
  OmniSearchQueryQuery,
  OmniSearchQueryVariables
> {}

export class OmniSearchComp extends React.PureComponent<
  IOmniSearchProps & InjectedIntlProps,
  IOmniSearchState
> {
  private myInputRef: any | null;
  // private myInputRef: RefHandler; // old style..
  // private myInputRef?: (instance: any) => void;

  constructor(props: IOmniSearchProps & InjectedIntlProps) {
    super(props);
    this.state = {
      query: '',
      selectedIndex: 0,
      list: [],
    };

    this.myInputRef = null;

    // this.myInputRef = React.createRef();
  }

  handleItemClick = (url: string) => {
    myHistory.push(url);
    safeInvokeDeprecated(this.props.close);
  };

  protected handleKeyUp = (e: any) => {
    if (e.which === MunikumKeys.ARROW_UP) {
      if (this.state.selectedIndex > 0) {
        this.setState({
          selectedIndex: this.state.selectedIndex - 1,
        });
      }
    } else if (e.which === MunikumKeys.ARROW_DOWN) {
      if (this.state.selectedIndex < this.state.list.length - 1) {
        this.setState({
          selectedIndex: this.state.selectedIndex + 1,
        });
      }
    }
  };

  componentDidMount() {
    // console.log('i MOUNTED');

    document.addEventListener('keyup', this.handleKeyUp);
    if (this.myInputRef && isFunction(this.myInputRef.focus)) {
      // console.log('focus that ..');
      this.myInputRef.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
  }
  //
  // shouldComponentUpdate(nextProps: any, nextState: any) {
  //   return true;
  // }

  handleQueryChange = (query: string) => {
    this.setState({
      query: query,
      selectedIndex: -1,
    });
  };

  public focus = () => {
    // console.log('FOCUS in omni!');
    if (this.myInputRef) {
      this.myInputRef.focus();
    }
  };

  render() {
    const { intl } = this.props;
    return (
      <SearchDiv style={this.props.style}>
        <TextField
          // leftIcon={MunikumIcons.Search}

          innerRef={ref => (this.myInputRef = ref)}
          // ref={this.myInputRef}
          name={'query'}
          value={this.state.query}
          onKeyDown={(e: any) => {
            if (
              e.which === MunikumKeys.ARROW_UP ||
              e.which === MunikumKeys.ARROW_DOWN
            ) {
              e.preventDefault();
              e.stopPropagation();
            }

            if (e.which === MunikumKeys.ENTER) {
              this.handleItemClick(
                this.state.list[this.state.selectedIndex].uri
              );
            }
          }}
          onChange={(e: any) => this.handleQueryChange(e.target.value)}
          style={{ width: '100%', height: '50px' }}
          placeholder={'Søk...'}
        />
        {this.state.query.length > 0 && (
          <SearchQuery
            fetchPolicy={'cache-and-network'}
            query={SEARCH_QUERY}
            variables={{ query: this.state.query }}
            onCompleted={(data: OmniSearchQueryQuery) => {
              let list: Array<ISearchItem> = [];

              data.search.actionValues.map((item, i) => {
                list.push({
                  id: item.id,
                  title: item.title,
                  uri: '/action-value/' + item.uri,
                  description: item.description || '',
                  type: 'ACTION_VALUE',
                });
              });

              data.search.discussions.map((item, i) => {
                list.push({
                  id: item.id,
                  title: item.title,
                  uri: '/forum/public/' + item.uri,
                  description: item.description || '',
                  type: 'DISCUSSION',
                });
              });

              data.search.topics.map((item, i) => {
                list.push({
                  id: item.id,
                  title: item.title,
                  uri: '/topic/' + item.uri,
                  description: item.description || '',
                  type: 'TOPIC',
                });
              });

              data.search.calendars.map((item, i) => {
                list.push({
                  id: item.id,
                  title: item.title,
                  uri: '/calendar/' + item.uri,
                  description: item.description || '',
                  type: 'CALENDAR',
                });
              });

              if (!_.isEqual(list, this.state.list)) {
                this.setState({ list: list });
              }
            }}
            children={(
              result: QueryResult<
                OmniSearchQueryQuery,
                OmniSearchQueryVariables
              >
            ) => {
              if (result.loading) {
                return (
                  <div>{intl.formatMessage(commonMessages.searching)}</div>
                );
              }

              if (result.error) {
                return <div>Error</div>;
              }

              if (this.state.list.length > 0) {
                return (
                  <SearchResultDiv>
                    {/*{data.search.persons && data.search.persons.map(person => {*/}
                    {/*return <SearchItemDiv key={person.id}>*/}
                    {/*{person.name}*/}
                    {/*</SearchItemDiv>;*/}
                    {/*})}*/}
                    {this.state.list.map((item, i) => {
                      const isActive = this.state.selectedIndex === i;

                      return (
                        <SearchItemDiv
                          onMouseEnter={() => {
                            this.setState({
                              selectedIndex: i,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({
                              selectedIndex: -1,
                            });
                          }}
                          onClick={e => this.handleItemClick(item.uri)}
                          style={{
                            backgroundColor: isActive
                              ? 'rgba(208, 211, 212, 0.7)'
                              : '#fff',
                          }}
                          key={item.id}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {(item.type === 'ACTION_VALUE' && (
                              <Tag
                                leftIcon={MunikumIcons.ActionValue}
                                hasShadow={false}
                                text={intl.formatMessage(
                                  commonMessages.actionValues
                                )}
                                color={Colors.DARKYELLOW}
                                uppercase={false}
                                style={{ height: '28px', width: '112px' }}
                              />
                            )) ||
                              (item.type === 'DISCUSSION' && (
                                <Tag
                                  leftIcon={MunikumIcons.Discussion}
                                  hasShadow={false}
                                  text={intl.formatMessage(
                                    commonMessages.forum
                                  )}
                                  color={Colors.RED}
                                  uppercase={false}
                                  style={{ height: '28px', width: '112px' }}
                                />
                              )) ||
                              (item.type === 'TOPIC' && (
                                <Tag
                                  leftIcon={MunikumIcons.Topic}
                                  hasShadow={false}
                                  text={intl.formatMessage(
                                    commonMessages.topic
                                  )}
                                  color={Colors.DARKBLUE}
                                  uppercase={false}
                                  style={{ height: '28px', width: '112px' }}
                                />
                              )) ||
                              (item.type === 'CALENDAR' && (
                                <Tag
                                  leftIcon={MunikumIcons.Circle1}
                                  hasShadow={false}
                                  text={intl.formatMessage(
                                    commonMessages.calendars
                                  )}
                                  color={Colors.DARKRED}
                                  uppercase={false}
                                  style={{ height: '28px', width: '112px' }}
                                />
                              ))}
                            <SearchItemTitle>{item.title}</SearchItemTitle>
                          </div>
                          <MunikumIcons.ArrowRight
                            style={{ marginRight: '1em', color: Colors.RED }}
                          />
                        </SearchItemDiv>
                      );
                    })}
                  </SearchResultDiv>
                );
              } else {
                return <div>{intl.formatMessage(commonMessages.noResult)}</div>;
              }
            }}
          />
        )}
      </SearchDiv>
    );
  }
}

// export const OmniSearch = injectIntl(OmniSearchComp, { withRef: true });
