import * as React from 'react';
import styled from 'styled-components';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { CSSProperties } from 'react';
import { Colors } from '../../common';
import { MdUpdate } from 'react-icons/md';

export interface IServiceWorkerUpdateProps {
  style?: CSSProperties;
}

const UpdatedDiv = styled.div`
  background-color: #ff5c39;
  //text-align: center;
  width: 100%;
  //padding: 30px;

  //position: relative;
  //top: 0;
  //z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 3em;
`;

const UpdatedA = styled.a`
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  cursor: pointer;
  text-decoration: none;
  border-bottom: solid 1px transparent;
  transition: border-bottom 0.2s ease-out;

  &:hover {
    border-bottom: solid 1px ${Colors.WHITE};
  }
`;

const messages = defineMessages({
  message: {
    id: 'ServiceWorkerUpdate.message',
    defaultMessage: 'A new update is available. Please click here.',
  },
});

class ServiceWorkerUpdateComponent extends React.PureComponent<
  IServiceWorkerUpdateProps & InjectedIntlProps
> {
  handleClick = () => {
    window.location.reload();
  };

  render() {
    const { intl, style } = this.props;

    return (
      <UpdatedDiv {...style}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <MdUpdate
            fill={'#fff'}
            width={'1.6em'}
            height={'1.6em'}
            style={{ marginRight: '.25em' }}
          />
          <UpdatedA onClick={this.handleClick}>
            {intl.formatMessage(messages.message)}
          </UpdatedA>
        </div>
      </UpdatedDiv>
    );
  }
}

export const ServiceWorkerUpdate = injectIntl(ServiceWorkerUpdateComponent);
