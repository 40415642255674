import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { safeInvokeDeprecated } from '../../common';

export interface IPortalProps {
  containerRef?: (ref: HTMLDivElement) => void;

  onChildrenMount?: () => void;
}

/***
 * @deprecated don't use this, use React 16 createPortal
 * Portal
 * - a magic portal to place divs outside the DOM.
 */
export class Portal extends React.Component<IPortalProps, {}> {
  private targetElement: HTMLElement;

  render() {
    return null;
  }

  public componentDidMount() {
    const targetElement = document.createElement('div'); // HTMLDivElement (not Component)
    targetElement.style.position = 'absolute';
    targetElement.style.top = '0';
    targetElement.style.right = '0';
    targetElement.style.left = '0';

    document.body.appendChild(targetElement);
    this.targetElement = targetElement;
    this.componentDidUpdate();
  }

  public componentDidUpdate() {
    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      <div ref={this.props.containerRef}>{this.props.children}</div>,
      this.targetElement,
      () => safeInvokeDeprecated(this.props.onChildrenMount)
    );
  }

  public componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.targetElement);
    // this.targetElement.remove();
    document.body.removeChild(this.targetElement); // Need to use remove child because of IE11
  }
}
