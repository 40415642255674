import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import { ContentState, convertToRaw } from 'draft-js';
import {
  FileUploader,
  IFileUploaderRenderProps,
} from '../UserFile/FileUploader';
import { IMuBag, IMuEditorRenderProps, MuEditor } from './MuEditor';
import { MuToolbar, MuToolbarMode } from './MuToolbar';
import { MuDiscardButton, MuSaveButton } from './MuButtons';
import { FileUploaderList } from '../UserFile/FileUploaderList';
import { ColorTheme, safeInvoke } from '../../common';
import { FileUploaderPicker, IFileItem } from '../UserFile/FileUploaderPicker';
import { MuInternalEditor } from './MuInternalEditor';
import { sizes } from '../../common/media';
import { Button } from '..';

export interface IMuEditorBasicProps {
  style?: CSSProperties;

  onValidate?: (markDown: string) => boolean;

  onSave?: (markdown: string, files: IFileItem[]) => void;

  saveText?: string;

  placeHolder?: string;

  saveIcon?: IconType;
}

const ToolbarAndButtsContainer = styled.div`
  display: block;

  @media (min-width: ${sizes.medium}px) {
    display: flex;
    justify-content: space-between;
  }
`;

const ToolbarAndFilesContainer = styled.div`
  display: flex;
  //justify-content: flex-start;
  //align-items: center;
`;

const BasicContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  //background-color: pink;

  height: 100%;
`;

/**
 * Basic editor. cannot change to preview mode
 * Toolbar: Basic
 * Buttons: Discard, Upload, Save
 *
 * use this in edit mode and answer discussion
 */

export class MuEditorBasic extends React.PureComponent<
  IMuEditorBasicProps,
  {}
> {
  // private myRef: RefObject<HTMLButtonElement>;

  private readonly internalEditorRef: RefObject<MuInternalEditor>;

  constructor(props: IMuEditorBasicProps) {
    super(props);
    // this.myRef = React.createRef();
    this.internalEditorRef = React.createRef();
  }

  // public focusTest = () => {
  //   console.log('trying to focus internal editor ref!');
  //   if (this.internalEditorRef.current) {
  //     const node = this.internalEditorRef.current;
  //     if (node) {
  //       node.focus();
  //     }
  //   }
  // };

  focus = () => {
    if (this.internalEditorRef.current) {
      this.internalEditorRef.current.focus();
    }
  };

  render() {
    // console.log('render mubasic');
    // console.log(this.props.saveText, 'mathias');
    return (
      <React.Fragment>
        <MuEditor
          onSubmit={(
            markdown: string,
            files: IFileItem[],
            contentState: ContentState,
            muEditorBag: IMuBag
          ) => {
            if (this.props.onValidate) {
              const isValid = this.props.onValidate(markdown);
              if (!isValid) {
                return;
              }
            }
            safeInvoke(
              this.props.onSave,
              JSON.stringify(convertToRaw(contentState)),
              files
            );
            // console.log('test');
            muEditorBag.resetEditor();
          }}
          editorRenderFunc={(propsEditor: IMuEditorRenderProps) => {
            // console.log('render func');
            return (
              <BasicContainer onClick={() => this.focus()}>
                <MuInternalEditor
                  editorMode={propsEditor.editorMode}
                  toolbarMode={MuToolbarMode.Basic}
                  draftState={propsEditor.draftState}
                  onChange={propsEditor.handleDraftChange}
                  ref={this.internalEditorRef}
                  placeholder={this.props.placeHolder}

                  // intl={intl}
                />
                <FileUploader
                  onUploadableFiles={propsEditor.handleUploadableFiles}
                  fileUploaderRenderFunc={(
                    propsFiles: IFileUploaderRenderProps
                  ) => {
                    return (
                      <ToolbarAndButtsContainer>
                        <ToolbarAndFilesContainer>
                          <MuToolbar
                            isBoldChecked={propsEditor.isBoldChecked}
                            isItalicChecked={propsEditor.isItalicChecked}
                            onClickCommand={propsEditor.handleCommand}
                            toolbarMode={MuToolbarMode.Basic}
                            style={{ marginRight: '.5em' }}
                          />
                          <FileUploaderList
                            persistedFiles={propsFiles.persistedFiles}
                            recentFiles={propsFiles.recentFiles}
                            onRemoveFile={propsFiles.handleRemoveFile}
                          />
                        </ToolbarAndFilesContainer>
                        <div style={{ display: 'flex' }}>
                          <MuDiscardButton
                            onClick={() => {
                              propsEditor.handleDiscard();
                              propsFiles.handleClearAllFiles();
                            }}
                          />
                          <FileUploaderPicker
                            onNewFiles={propsFiles.handleNewFiles}
                            style={{
                              marginLeft: '.5em',
                            }}
                          />
                          {this.props.saveText && (
                            <Button
                              text={this.props.saveText}
                              // ref={this.myRef}
                              onClick={() => {
                                propsEditor.handleSubmit();
                                propsFiles.handleClearAllFiles();
                              }}
                              style={{
                                marginLeft: '.5em',
                              }}
                              leftIcon={this.props.saveIcon}
                              theme={ColorTheme.Red}
                            />
                          )}
                          {!this.props.saveText && (
                            <MuSaveButton
                              onClick={() => {
                                propsEditor.handleSubmit();
                                propsFiles.handleClearAllFiles();
                              }}
                              style={{
                                marginLeft: '.5em',
                              }}
                            />
                          )}
                        </div>
                      </ToolbarAndButtsContainer>
                    );
                  }}
                />
              </BasicContainer>
            );
          }}
        />
      </React.Fragment>
    );
  }
}
