/**
 * If you want to add new entities to redux state:
 * 1. add actionCreators using factory
 * 2. add state to IEntitiesState
 * 3. add reducer using reducerFactory
 * 4. add redux-saga using sagaFactory
 * 5. (optional) add redux-saga for updates
 * 6. add selectors
 *
 */
import moment from 'moment';

import { combineReducers, Reducer, ReducersMapObject } from 'redux';
import { IRealm } from '../../services/models/realm';
import { ITopic } from '../../services/models/topic';
import { ISector } from '../../services/sectorService';
import {
  getOrganization,
  getOrganizationTypes,
} from '../../services/organizationService';
import {
  getNotification,
  getNotificationList,
} from '../../services/notificationService';
import {
  getDiscussion,
  getDiscussionList,
  getDiscussionMeta,
  getDiscussionMetaList,
  getForumList,
} from '../../services/discussionService';
import { IRefreshToken } from '../../services/models/refreshToken';
import {
  getTopicList,
  getTopicMeta,
  getTopicMetaList,
} from '../../services/topicService';
import { fork, put, select, take } from 'redux-saga/effects';
import { IPerson } from '../../services/models/person';
import {
  getPersonById,
  getPersonList,
  IPersonEditForm,
} from '../../services/personService';
import {
  getActionValue,
  getActionValueList,
  getActionValueMeta,
  getActionValueMetaList,
} from '../../services/actionValueService';
import {
  actionCreatorFactory,
  IAsyncActionCreators,
} from '../actionCreatorFactory';
import { IActionValue } from '../../services/models/actionValue';
import { IEntity } from '../../services/models/entity';
import { getAdminMetaList } from '../../services/metaService';
import { entitySagaFactory } from './entitySagaFactory';
import {
  getKostraFunctions,
  IKostraFunction,
} from '../../services/kostraService';
import { getFileList, IFile } from '../../services/fileservice';
import {
  IOrganization,
  IOrganizationType,
} from '../../services/models/organization';
import { IContentMeta } from '../../services/models/contentMeta';
import { isAdminSelector, userReady } from '../auth/auth';
import { getRealms } from '../../services/realmService';
import { getRefreshTokens } from '../../services/refreshTokenService';
import { IApiError } from '../../services/api';
import { INotification } from '../../services/models/notification';
import { createSelector } from 'reselect';
import { entityReducerFactory } from './entityReducerFactory';
import { IDiscussion } from '../../services/models/discussion';
import { RootState } from '../rootReducer';

/**
 * redux state block for entity of type T
 */
export interface IEntityState<T extends IEntity> {
  /**
   * A dictionary with all entities of type T by id(string)
   * the entity itself can be a small model from the list, or a more complete model from a get..
   */
  byId: {
    [byId: string]: Readonly<T>;
  };

  isFetchingList: boolean;

  errorList?: IApiError;

  isFetchingSingle: boolean;

  errorSingle?: IApiError;

  /**
   * when were entityList updated last time? (when was update-request issued from frontend last time)
   */
  lastFetched?: Date;

  /**
   * when was the last update-request started?
   */
  lastFetchStarted?: Date;
}

/**
 * params sent to API endpoints supporting lastUpdated
 */
export interface ILastUpdatedParams {
  lastUpdated?: Date;
}

/**
 * actionCreator supports full reload, set to true will not pass lastUpdated param
 */
export interface IFullReload {
  /**
   * if true, entities in redux state are REPLACED, not merged.
   * This is useful if you want to delete items that don't exist on server anymore
   */
  isFullReload?: boolean;
}

// ACTION CREATORS:
const entitiesActionCreator = actionCreatorFactory('DB');

export const getActionValueMetasActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IContentMeta>,
  IApiError
>('GET_ACTION_VALUE_METAS');

export const getActionValueMetaActionCreator = entitiesActionCreator.async<
  { id: string },
  IContentMeta,
  IApiError
>('GET_ACTION_VALUE_META');

export const getActionValuesActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IActionValue>,
  IApiError
>('GET_ACTION_VALUES');

export const getActionValueActionCreator = entitiesActionCreator.async<
  { id: string },
  IActionValue,
  IApiError
>('GET_ACTION_VALUE');

export const getDiscussionMetasActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IContentMeta>,
  IApiError
>('GET_DISCUSSION_METAS');

export const getDiscussionMetaActionCreator = entitiesActionCreator.async<
  { id: string },
  IContentMeta,
  IApiError
>('GET_DISCUSSION_META');

export const getDiscussionsActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IDiscussion>,
  IApiError
>('GET_DISCUSSIONS');

export const getDiscussionActionCreator = entitiesActionCreator.async<
  { id: string },
  IDiscussion,
  IApiError
>('GET_DISCUSSION');

export const getTopicMetasActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IContentMeta>,
  IApiError
>('GET_TOPIC_METAS');

export const getTopicMetaActionCreator = entitiesActionCreator.async<
  { id: string },
  IContentMeta,
  IApiError
>('GET_TOPIC_META');

export const getTopicsActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<ITopic>,
  IApiError
>('GET_TOPICS');

// export const getTopicActionCreator = entitiesActionCreator.async<
//   { id: string },
//   ITopic,
//   IApiError
// >('GET_TOPIC');

export const getForumsActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IContentMeta>,
  IApiError
>('GET_FORUMS');

export const getForumActionCreator = entitiesActionCreator.async<
  { id: string },
  IDiscussion,
  IApiError
>('GET_FORUM');

export const getPersonsActionCreator = entitiesActionCreator.async<
  {},
  ReadonlyArray<IPerson>,
  IApiError
>('GET_PERSONS');

const getPersonActionCreator = entitiesActionCreator.async<
  { id: string },
  IPerson,
  IApiError
>('GET_PERSON');

// UPDATE:

export const updatePersonActionCreator = entitiesActionCreator.async<
  IPersonEditForm,
  {},
  IApiError
>('UPDATE_PERSON');

const getSectorsActionCreator = entitiesActionCreator.async<
  {},
  ReadonlyArray<ISector>,
  IApiError
>('GET_SECTORS');

export const getOrganizationActionCreator = entitiesActionCreator.async<
  { id: string },
  IOrganization,
  IApiError
>('GET_ORGANIZATION');

const getKostraFunctionsActionCreator = entitiesActionCreator.async<
  {},
  ReadonlyArray<IKostraFunction>,
  IApiError
>('GET_KOSTRA_FUNCTIONS');

const getOrganizationTypesActionCreator = entitiesActionCreator.async<
  {},
  ReadonlyArray<IOrganizationType>,
  IApiError
>('GET_ORG_TYPES');

export const getMetasActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IContentMeta>,
  IApiError
>('GET_METAS');

export const getMetaActionCreator = entitiesActionCreator.async<
  { id: string },
  IContentMeta,
  IApiError
>('GET_META');

export const getNotificationsActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<INotification>,
  IApiError
>('GET_NOTIFICATIONS');

export const getNotificationActionCreator = entitiesActionCreator.async<
  { id: string },
  INotification,
  IApiError
>('GET_NOTIFICATION');

export const getRealmsActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IRealm>,
  IApiError
>('GET_REALMS');

export const getRefreshTokensActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IRefreshToken>,
  IApiError
>('GET_REFRESH_TOKENS');

export const getFilesActionCreator = entitiesActionCreator.async<
  ILastUpdatedParams,
  ReadonlyArray<IFile>,
  IApiError
>('GET_FILES');

export interface IEntitiesState {
  actionValueMetas: IEntityState<IContentMeta>;
  actionValues: IEntityState<IActionValue>;
  discussionMetas: IEntityState<IContentMeta>;
  discussions: IEntityState<IDiscussion>;
  persons: IEntityState<IPerson>;
  sectors: IEntityState<ISector>;
  organizations: IEntityState<IOrganization>;
  kostraFunctions: IEntityState<IKostraFunction>;
  organizationTypes: IEntityState<IOrganizationType>;
  meta: IEntityState<IContentMeta>;
  notifications: IEntityState<INotification>;
  forums: IEntityState<IContentMeta>;
  realms: IEntityState<IRealm>;
  refreshTokens: IEntityState<IRefreshToken>;
  topicMetas: IEntityState<IContentMeta>;
  topics: IEntityState<ITopic>;
  files: IEntityState<IFile>;
}

interface IEntityConfig {
  /**
   * actionCreator to dispatch get list of items
   */
  actionCreatorList?: IAsyncActionCreators<
    ILastUpdatedParams & IFullReload,
    ReadonlyArray<IEntity>,
    IApiError
  >;

  /**
   * actionCreator to dispatch get single item by id
   */
  actionCreatorItem?: IAsyncActionCreators<IEntity, IEntity, IApiError>;

  /**
   * API call to get list of items
   * @returns {Promise<ReadonlyArray<IEntity>>}
   */
  getEntities?: () => Promise<ReadonlyArray<IEntity>>;

  /**
   * API call to get single item by id
   * @param {string} id
   * @returns {Promise<IEntity>}
   */
  getEntity?: (id: string) => Promise<IEntity>;

  /**
   * How long do we cache items before asking API for a partial update? (using lastUpdated)
   * This assumes that the API endpoint for the given entity supports lastUpdated param
   */
  entityPartialUpdateIntervalInSeconds?: number;

  /**
   * How often should we ask API for a full update? (replace entities)
   */
  entityFullUpdateIntervalInSeconds?: number;

  /**
   * true if we should load data in app init
   */
  loadOnInit: boolean;

  /**
   * If enabled, we poll API for data every  entityPartialUpdateIntervalInSeconds  seconds
   * This assumes that the API endpoint for the given entity supports lastUpdated param
   */
  enablePolling?: boolean;

  /**
   * Does this entity require authoization? Specify authorize function here..
   * If user is not authorized, we don't ask API for data
   * NOTE: We can only manage this per entity for now.. do we need it to be more granular?
   * @param {RootState} state
   * @returns {boolean} return true if user is authorized to this entity
   */
  authFunc?: (state: RootState) => boolean;
}

/**
 * intervals in seconds. You can also just specify seconds..
 */
export enum EntityUpdateInterval {
  Never = -1,
  OneSecond = 1,
  OneMinute = 60,
  OneHour = 60 * 60,
  SixHours = 60 * 60 * 6,
  TwelveHours = 60 * 60 * 12,
  OneWeek = 60 * 60 * 24 * 7,
}

// we're defining entities config here:
const entitiesConfig: { [K in keyof IEntitiesState]: IEntityConfig | false } = {
  persons: {
    actionCreatorList: getPersonsActionCreator,
    actionCreatorItem: getPersonActionCreator,
    getEntities: getPersonList,
    getEntity: getPersonById,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    authFunc: (state: RootState) => isAdminSelector(state),
    loadOnInit: false,
  },
  sectors: {
    actionCreatorList: getSectorsActionCreator,
    actionCreatorItem: undefined,
    getEntities: undefined,
    getEntity: undefined,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.Never,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.TwelveHours,
    loadOnInit: false,
  },
  kostraFunctions: {
    actionCreatorList: getKostraFunctionsActionCreator,
    actionCreatorItem: undefined,
    getEntities: getKostraFunctions,
    getEntity: undefined,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.Never,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.TwelveHours,
    loadOnInit: false,
  },
  organizationTypes: {
    actionCreatorList: getOrganizationTypesActionCreator,
    actionCreatorItem: undefined,
    getEntities: getOrganizationTypes,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.Never,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.TwelveHours,
    loadOnInit: false,
  },
  actionValueMetas: {
    actionCreatorList: getActionValueMetasActionCreator,
    actionCreatorItem: getActionValueMetaActionCreator,
    getEntities: getActionValueMetaList,
    getEntity: getActionValueMeta,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
  },
  actionValues: {
    actionCreatorList: getActionValuesActionCreator,
    actionCreatorItem: getActionValueActionCreator,
    getEntities: getActionValueList,
    getEntity: getActionValue,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
  },
  discussionMetas: {
    actionCreatorList: getDiscussionMetasActionCreator,
    actionCreatorItem: getDiscussionMetaActionCreator,
    getEntities: getDiscussionMetaList,
    getEntity: getDiscussionMeta,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
  },
  discussions: {
    actionCreatorList: getDiscussionsActionCreator,
    actionCreatorItem: getDiscussionActionCreator,
    getEntities: getDiscussionList,
    getEntity: getDiscussion,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
  },
  organizations: {
    actionCreatorList: undefined, // async!
    actionCreatorItem: getOrganizationActionCreator,
    getEntities: undefined,
    getEntity: getOrganization,
    loadOnInit: false,
  },
  meta: {
    actionCreatorList: getMetasActionCreator,
    actionCreatorItem: undefined,
    getEntities: getAdminMetaList,
    getEntity: undefined,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
    authFunc: state => isAdminSelector(state),
  },
  notifications: {
    actionCreatorList: getNotificationsActionCreator,
    actionCreatorItem: getNotificationActionCreator,
    getEntities: getNotificationList,
    getEntity: getNotification,
    entityPartialUpdateIntervalInSeconds: 30,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    enablePolling: false,
    loadOnInit: false,
  },
  forums: {
    actionCreatorList: getForumsActionCreator,
    actionCreatorItem: getForumActionCreator,
    getEntities: getForumList,
    getEntity: undefined,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
  },
  realms: {
    actionCreatorList: getRealmsActionCreator,
    actionCreatorItem: undefined,
    getEntities: getRealms,
    getEntity: undefined,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
    authFunc: state => isAdminSelector(state),
  },
  refreshTokens: {
    actionCreatorList: getRefreshTokensActionCreator,
    actionCreatorItem: undefined,
    getEntities: getRefreshTokens,
    getEntity: undefined,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.Never,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.Never,
    loadOnInit: false,
  },
  topicMetas: {
    actionCreatorList: getTopicMetasActionCreator,
    actionCreatorItem: getTopicMetaActionCreator,
    getEntities: getTopicMetaList,
    getEntity: getTopicMeta,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
  },
  topics: {
    actionCreatorList: getTopicsActionCreator,
    actionCreatorItem: undefined,
    getEntities: getTopicList,
    getEntity: undefined, // TODO: add me
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
  },
  files: {
    actionCreatorList: getFilesActionCreator,
    actionCreatorItem: undefined,
    getEntities: getFileList,
    getEntity: undefined,
    entityPartialUpdateIntervalInSeconds: EntityUpdateInterval.OneMinute,
    entityFullUpdateIntervalInSeconds: EntityUpdateInterval.OneHour,
    loadOnInit: false,
  },
};

const reducersMap: ReducersMapObject = {};
// tslint:disable-next-line
const entityListenSagas: Array<any> = [];

Object.keys(entitiesConfig).map((key: keyof IEntitiesState, index: number) => {
  const config: IEntityConfig | false = entitiesConfig[key];

  if (config) {
    reducersMap[key] = entityReducerFactory({
      entityListActionCreator: config.actionCreatorList,
      entityItemActionCreator: config.actionCreatorItem,
    });

    entityListenSagas.push(
      entitySagaFactory<IEntity>({
        entityKey: key,
        actionCreatorList: config.actionCreatorList,
        getEntities: config.getEntities,
        actionCreatorItem: config.actionCreatorItem,
        getEntity: config.getEntity,
        pollingIntervalInSeconds:
          (config.entityPartialUpdateIntervalInSeconds || 10) / 2, // separate prop for polling?
        enablePolling: config.enablePolling,
      })
    );
  }
});

/**
 * Entity reducer
 * @type {Reducer<any>}
 */
export const reducer: Reducer<IEntitiesState> = combineReducers(reducersMap);

/**
 * This saga listens for fetch-action and handles side-effects
 */
export function* entitiesLoopSaga() {
  for (let knife of entityListenSagas) {
    yield fork(knife);
  }
}

// internal selector
const isEntityInitialized = (
  state: RootState,
  entityKey: keyof IEntitiesState
): boolean => {
  return (
    state.entities[entityKey] !== undefined &&
    state.entities[entityKey].lastFetched !== undefined
  );
};

// internal selector
const entityLastFetched = (
  state: RootState,
  entityKey: keyof IEntitiesState
): Date | undefined => {
  return state.entities[entityKey].lastFetched;
};

export interface ILoadEntityOptions<TEntity> {
  actionCreator: IAsyncActionCreators<
    ILastUpdatedParams & IFullReload,
    ReadonlyArray<TEntity>,
    IApiError
  >;
  entityKey: keyof IEntitiesState;
  entityPartialUpdateIntervalInSeconds?: number;
  entityFullUpdateIntervalInSeconds?: number;
  authFunc?: (state: RootState) => boolean;

  /**
   * set this to true if you want to start a saga that polls the API
   * for changes using lastUpdated and interval specified in
   */
  // spawnPartialUpdateSaga?: boolean;
}

function* loadEntityInternalByKey(entityKey: keyof IEntitiesState) {
  const config: IEntityConfig | false = entitiesConfig[entityKey];

  if (config && config.actionCreatorList) {
    yield loadEntityInternal({
      entityKey: entityKey,
      entityFullUpdateIntervalInSeconds:
        config.entityFullUpdateIntervalInSeconds,
      entityPartialUpdateIntervalInSeconds:
        config.entityPartialUpdateIntervalInSeconds,
      authFunc: config.authFunc,
      actionCreator: config.actionCreatorList,
    });
  } else {
    console.warn(
      '[entities] cannot find entities configuration for entity ' + entityKey
    );
  }
}

/**
 * loadEntity is a generator function that can be used inside sagas.
 * it checks if data is in state.entities.entityKey, when data was last updated, and
 * if data is 'too old' we dispatch a action to update data.
 *
 * partial update:
 *  - a update using lastUpdated-field.
 *  - reducer will merge result with existing data. This means that old data wont get deleted until you do a full update
 * full update:
 *  - a update without lastUpdated-field
 *  - reducer will replace existing data with result.
 *
 * @param {ILoadEntityOptions<TEntity>} options
 * @returns {IterableIterator<any>}
 */
function* loadEntityInternal<TEntity>(options: ILoadEntityOptions<TEntity>) {
  if (options.authFunc !== undefined) {
    const state = yield select();
    const isAuthorized = options.authFunc(state);
    if (!isAuthorized) {
      // console.log(
      //   '[entities] loadEntity: ' +
      //     options.entityKey +
      //     ' skipped, user is not authorized to this entity'
      // );
      return;
    }
  }

  const isLoaded = yield select(isEntityInitialized, options.entityKey);

  if (!isLoaded) {
    // no data in redux store / local storage. (we dont need isFullReload, but it doesnt hurt..)
    yield put(options.actionCreator.started({ isFullReload: true }));
  } else {
    // we have data.

    // when was data last updated?
    const lastFetched = yield select(entityLastFetched, options.entityKey);
    const lastUpdated = moment(lastFetched);
    const sinceLastUpdate = moment().diff(lastUpdated, 'seconds');

    if (
      options.entityFullUpdateIntervalInSeconds !== undefined &&
      options.entityFullUpdateIntervalInSeconds > 0 &&
      sinceLastUpdate > options.entityFullUpdateIntervalInSeconds
    ) {
      // console.log('[entities] data_full_reload: ' + options.entityKey);
      yield put(options.actionCreator.started({ isFullReload: true }));
    } else if (
      options.entityPartialUpdateIntervalInSeconds !== undefined &&
      options.entityPartialUpdateIntervalInSeconds > 0 &&
      sinceLastUpdate > options.entityPartialUpdateIntervalInSeconds
    ) {
      // console.log(
      //   '[entities] data_partial_reload: ' +
      //     options.entityKey +
      //     'lastUpdated:' +
      //     (lastUpdated ? lastUpdated.toDate() : 'NA')
      // );
      yield put(
        options.actionCreator.started(
          lastUpdated !== undefined ? { lastUpdated: lastUpdated.toDate() } : {}
        )
      );
    } else {
      // console.log(
      //   '[entities] data_ok: ' +
      //     options.entityKey +
      //     ' last updated: ' +
      //     lastUpdated.fromNow() +
      //     '(' +
      //     moment().diff(lastUpdated, 'seconds') +
      //     ')'
      // );
    }
  }
}

function* loadEntity(...args: Array<keyof IEntitiesState>) {
  // console.log('args', args);
  for (let key of args) {
    // console.log('key', key);
    yield loadEntityInternalByKey(key);
  }
}

/**
 * Dispatch this action in YourPage.onComponentDidMount() to fetch data using defined update intervals
 * @type {ActionCreator<{keys: Array<keyof IEntitiesState>}>} Array of entity keys to update
 */
export const loadDataActionCreator = entitiesActionCreator<{
  keys: Array<keyof IEntitiesState>;
}>('LOAD_DATA');

/**
 * Saga that listens for loadData actions
 * @returns {IterableIterator<any>}
 */
export function* entitiesLoadSaga() {
  while (true) {
    const action = yield take(loadDataActionCreator.type);
    // console.log(
    //   '[entities] loading data for keys: ' + action.payload.keys.join(',')
    // );
    yield loadEntity(...action.payload.keys);
  }
}

/**
 * saga that initializes data.
 * this waits for USER_READY, then we know that rehydration is complete and user is authenticated.
 * it checks if entities are loaded trough rehydration, and if not a action is dispatched.
 *
 * about updates:
 * - we don't do polling on short intervals
 * - we don't do events
 * - we DO full reload of data on long intervals (to detect deleted items, switch servers, etc)
 *
 */
export function* initEntitiesSaga() {
  // console.log('[entities] initEntitiesSaga ready');
  while (true) {
    yield take(userReady);
    // console.log(
    //   '[entities] user logged in, checking if we need to load lookup data..'
    // );

    const list: Array<ILoadEntityOptions<IEntity>> = [];

    Object.keys(entitiesConfig).map(
      (key: keyof IEntitiesState, index: number) => {
        const config: IEntityConfig | false = entitiesConfig[key];

        if (config && config.loadOnInit && config.actionCreatorList) {
          list.push({
            actionCreator: config.actionCreatorList,
            entityKey: key,
            entityPartialUpdateIntervalInSeconds:
              config.entityPartialUpdateIntervalInSeconds,
            entityFullUpdateIntervalInSeconds:
              config.entityFullUpdateIntervalInSeconds,
            authFunc: config.authFunc,
          });
        } else {
          // console.log('[entities] skip init: ' + key);
        }
      }
    );

    for (let i = 0; i < list.length; i++) {
      const x = list[i];
      // console.log('[entities] intitializing: ' + x.entityKey);
      yield loadEntityInternal(x);
    }

    // yield put(allDataReadyActionCreator({}));
  }
}

/**
 * selectors
 */

export const organizationDictSelector = (state: RootState) =>
  state.entities.organizations.byId;

export const organizationByDbIdSelector = (
  state: RootState,
  dbId: string
): IOrganization | undefined => {
  // console.log('organizationByDbIdSelector', dbId);
  return state.entities.organizations.byId[dbId] || undefined;
};

type LastFetched = Date | undefined;

/**
 * is lookupdata ready? Ready means is data initially loaded, either by rehydrate or initial fetch
 * this selector only updates when lastFetched is changed in specified entities
 * this helps us to avoid unnecessary re-renders
 * @type {OutputSelector<RootState, boolean, (res1: LastFetched, res2: LastFetched, res3: LastFetched) => boolean>}
 */
export const isLookupDataReadySelector = createSelector<
  RootState,
  LastFetched,
  LastFetched,
  LastFetched,
  boolean
>(
  state => state.entities.sectors.lastFetched,
  state => state.entities.kostraFunctions.lastFetched,
  state => state.entities.organizationTypes.lastFetched,
  (r1, r2, r3) => {
    // console.log('T_R: ', r1 ? 1 : 0, r2 ? 1 : 0, r3 ? 1 : 0);
    if (!r1 || !r2 || !r3) {
      return false;
    }
    return true;
  }
);

export const sectorsSelector = (state: RootState) =>
  state.entities.sectors.byId;

export const organizationTypesSelector = (state: RootState) =>
  state.entities.organizationTypes.byId;

export const kostraFunctionsSelector = (state: RootState) =>
  state.entities.kostraFunctions.byId;
