import * as React from 'react';
import { RefObject } from 'react';
import styled from 'styled-components';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { commonMessages } from '../language/commonMessages';
import {
  CardContainer,
  CardLeftContainerResponsive,
  ChatBubble,
} from '../basic/Card/Card';
import { ICommentSaveForm } from '../../services/commentService';
import { isReallyEmpty, safeInvokeDeprecated } from '../common';
import { FieldsOnMeSimpleFragment } from '../../models/types';
import { IFileItem } from '../basic/UserFile/FileUploaderPicker';
import { UserSummary } from './UserSummary';
import { MuEditorBasic } from '../basic/MuEditor/MuEditorBasic';

const messages = defineMessages({
  message: {
    id: 'AnswerDiscussionView.message',
    defaultMessage: 'Message',
  },
  discussion: {
    id: 'AnswerDiscussionView.discussion',
    defaultMessage: 'Discussion',
  },
  replyTitle: {
    id: 'AnswerDiscussionView.replyTitle',
    defaultMessage: 'Reply to question',
  },
});

const ContentContainer = styled.div`
  //background-color: cadetblue;
  flex: 1 1 0px;
  padding: 1.25em;
  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
`;

export interface IAnswerDiscussionViewProps {
  // set this if youre editing!
  // value?: ICommentSaveForm;
  // isAdmin: boolean;
  onSave: (rawMarkdown: string, files: IFileItem[]) => void;
  // title: string;
  // metaId: string;
  me: FieldsOnMeSimpleFragment;
  isSaving: boolean;
  saveSuccess: boolean;
}

/**
 * AnswerDiscussionView
 */
class AnswerDiscussionComp extends React.PureComponent<
  IAnswerDiscussionViewProps & InjectedIntlProps,
  {}
> {
  private editorRef: RefObject<MuEditorBasic>;

  constructor(props: IAnswerDiscussionViewProps & InjectedIntlProps) {
    super(props);

    this.editorRef = React.createRef();
  }

  handleSave = (values: ICommentSaveForm) => {
    // console.log('save1', values);

    safeInvokeDeprecated(this.props.onSave, values);
  };

  handleFocus = () => {
    if (this.editorRef.current) {
      this.editorRef.current.focus();
    }
  };

  render() {
    const { me, intl } = this.props;

    return (
      <CardContainer canHover={false} style={{}}>
        <CardLeftContainerResponsive style={{ position: 'relative' }}>
          <ChatBubble />
          <UserSummary
            userImage={me.picture}
            name={me.name}
            role={me.jobTitle || ''}
            organizationImage={
              (me.organization && me.organization.organizationImage) || ''
            }
            organizationName={(me.organization && me.organization.name) || ''}
          />
        </CardLeftContainerResponsive>
        <ContentContainer
          style={{ minHeight: '8em' }}
          onClick={this.handleFocus}
        >
          <MuEditorBasic
            placeHolder={intl.formatMessage(
              commonMessages.writeHerePlaceholder
            )}
            onValidate={(md: string) => {
              const temp = md.trim().length > 0 && md.trim() !== '\u200B';
              return temp;
            }}
            onSave={(md: string, files: IFileItem[]) => {
              if (!isReallyEmpty(md)) {
                safeInvokeDeprecated(this.props.onSave, md, files);
              }
            }}
            saveText={intl.formatMessage(commonMessages.answer)}
            ref={this.editorRef}
          />
        </ContentContainer>
      </CardContainer>
    );
  }
}

export const AnswerDiscussionView = injectIntl(AnswerDiscussionComp);
