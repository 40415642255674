import * as React from 'react';
import { RefObject } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { convertFromRaw, convertToRaw, EditorState, RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import styled from 'styled-components';
import 'draft-js-linkify-plugin/lib/plugin.css';

export interface ISimpleEditorProps {
  name?: string;
  border?: boolean;
  content?: string;
  readOnly?: boolean;
  onChange?: (content: string) => void;
  onChangedFromOriginal?: (on: boolean) => void;
}

export interface ISimpleEditorState {
  editorState: EditorState;
  contentHash: number;
}

interface IEditorBoxProps {
  border?: boolean;
  minHeight?: number;
}

const EditorBox = styled.div`
  padding: 5px 0px;
  border-top: ${(props: IEditorBoxProps) =>
    props.border ? '1px solid #eee' : '0'};
  border-bottom: ${(props: IEditorBoxProps) =>
    props.border ? '1px solid #eee' : '0'};
  .DraftEditor-root {
    min-height: 40px;
  }
  cursor: text;
`;

class SimpleEditorComp extends React.PureComponent<
  ISimpleEditorProps & InjectedIntlProps,
  ISimpleEditorState
> {
  private readonly editorRef: RefObject<any>;

  constructor(props: ISimpleEditorProps & InjectedIntlProps) {
    super(props);

    let editorState = this.props.content
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(this.props.content))
        )
      : EditorState.createEmpty();

    this.editorRef = React.createRef();

    this.state = {
      editorState: editorState,
      contentHash: hashCode(this.props.content),
    };
  }

  focus = () => {
    if (this.editorRef.current) {
      this.editorRef.current.focus();
    }
  };

  handleBoldClick(e) {
    this.handleChange(
      RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD')
    );
    e.stopPropagation();
  }

  handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.handleChange(newState);
      return 'handled';
    }

    return 'not-handled';
  };

  handleChange = editorState => {
    this.setState(
      {
        editorState,
      },
      () => {
        const newJson = JSON.stringify(
          convertToRaw(this.state.editorState.getCurrentContent())
        );

        if (this.props.onChange) {
          this.props.onChange(newJson);
        }

        // Check if changes has changed from original
        const newHash = hashCode(newJson);
        if (this.props.onChangedFromOriginal) {
          this.props.onChangedFromOriginal(newHash !== this.state.contentHash);
        }
      }
    );
  };

  render() {
    const linkifyPlugin = createLinkifyPlugin();

    const plugins = [linkifyPlugin];

    return (
      <EditorBox
        border={this.props.border}
        onClick={e => {
          this.focus();
        }}
      >
        <Editor
          ref={this.editorRef}
          readOnly={this.props.readOnly}
          editorState={this.state.editorState}
          // @ts-ignore
          handleKeyCommand={this.handleKeyCommand}
          onChange={this.handleChange}
          plugins={plugins}
        />
      </EditorBox>
    );
  }
}

const hashCode = (str: string) => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const SimpleEditor = injectIntl(SimpleEditorComp);
