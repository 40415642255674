import * as React from 'react';
import styled, { StyledFunction } from 'styled-components';

export const ContentBox = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  padding: 1.5em 1.875em;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
`;
//
// export class ContentBox extends React.PureComponent<
//   React.HTMLProps<HTMLDivElement>,
//   {}
// > {
//   render() {
//     const { ...rest } = this.props;
//
//     return (
//       <ContentDiv {...removeNonHTMLProps(rest)}>
//         {this.props.children}
//       </ContentDiv>
//     );
//   }
// }
