import * as React from 'react';

import {
  IDropdownItem,
  IItemRenderProps,
  SelectMode,
  UltimateDropdown,
} from '../../basic/DropDownSelectUltimate/UltimateDropdown';
import { WheelConstants } from './utils';
import { MenuItem } from '../../basic/Menu';
import { MunikumIcons } from '../../common/icons';
import { safeInvoke, safeInvokeDeprecated } from '../../common';
import { commonLabels } from '../../language/commonLabels';
import { injectIntl, InjectedIntlProps } from 'react-intl';

interface MonthStruct extends IDropdownItem {
  title: string;
}

const MonthDrop = UltimateDropdown.ofType<MonthStruct>();

const months = WheelConstants.monthNames.map((name: string, index: number) => ({
  id: index.toString(),
  title: name, // TODO: capitalize 1st letter. . .
}));

const MonthDropdown: React.FunctionComponent<
  {
    monthId: string;
    onMonthChanged?: (month: MonthStruct) => void;
  } & InjectedIntlProps
> = props => {
  return (
    <MonthDrop
      label={props.intl.formatMessage(commonLabels.monthWheel)}
      items={months}
      selectedItems={[months.find(f => f.id === props.monthId)]}
      onSelected={m => safeInvoke(props.onMonthChanged, m)}
      itemRenderFunc={(
        item: MonthStruct,
        itemRenderProps: IItemRenderProps
      ) => (
        <MenuItem
          key={itemRenderProps.key}
          isSelected={itemRenderProps.isSelected}
          leftIcon={
            // itemRenderProps.selectMode === SelectMode.MULTIPLE &&
            itemRenderProps.isSelected ? MunikumIcons.LeftCheck : undefined
          }
          text={item.title}
          onClick={() => {
            itemRenderProps.handleClick();
          }}
        />
      )}
      selectMode={SelectMode.SINGLE}
    />
  );
};

export default injectIntl(MonthDropdown);
