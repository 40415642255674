/**
 * ApiError - thrown by API services if an error occurs
 */
import { myStore } from '../index';

export class ApiError extends Error {
  public readonly statusCode: number;
  public readonly url?: string;

  constructor(message: string, statusCode: number, urlThatFailed?: string) {
    super(message);
    this.statusCode = statusCode;
    this.url = urlThatFailed!;
  }
}

/**
 * IApiError - standarized way to represent an error in reducers, sagas, etc.
 * Errormessage + optional statusCode from API
 */
export interface IApiError {
  message: string;
  statusCode?: number;
}

/**
 *
 * @param {IApiError} e
 * @returns {{message: string; statusCode?: number}}
 */
export function wrapApiError(
  e: IApiError
): { message: string; statusCode?: number } {
  let tempError: { message: string; statusCode?: number } = {
    message: e.message,
  };
  if (e.hasOwnProperty('statusCode')) {
    tempError.statusCode = e.statusCode;
  }
  console.log('error:', tempError, e);
  return tempError;
}

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'HEAD';

/**
 * response to json, cast to T. Throws error if mismatch between response.json and T!
 * throw error of response ie empty!
 * @param {Response} response
 * @returns {Promise<T>}
 */
export async function deserialize<T>(response: Response): Promise<T> {
  try {
    return (await response.json()) as T;
  } catch (e) {
    console.warn(
      'Unable to deserialize JSON string to object. err/response:',
      e,
      response
    );
    return {} as T;
  }
}

export async function parsedEmptyResponse(response: Response): Promise<void> {
  if (response.ok) {
    return;
  } else if (response.status === 400) {
    console.log('bad request', response);
    const test5 = await response.json();
    console.log('bad request response as json:', test5);
    throw new ApiError('Bad request', 400, response.statusText);
  } else {
    throw new ApiError('Request failed', response.status, response.statusText);
  }
}

/**
 * If the response was OK, parse it as JSON and return result.
 * If response was NOT OK, throw ApiError!
 * @param {Response} response
 * @returns {Promise<T>}
 */
export async function parsedResponse<T>(response: Response): Promise<T> {
  if (response.ok) {
    if (response.status === 204) {
      console.warn(
        '204 no content found, deserialize will probably fail. Handle this before you call parsedResponse!'
      );

      throw Error('cannot parse empty content to json and cast to T!');
    }

    return deserialize<T>(response);
  } else if (response.status === 400) {
    console.log('bad request', response);
    const test5 = await response.json();
    console.log('bad request response as json:', test5);

    throw new ApiError('Bad request', 400, response.statusText);
  } else {
    // TODO: we could extract a better errormessage from API here? => Kristoffer/Svein Arild; a standarized way to return error messages from API to client?
    // TODO: 404 not found.. == return null maybe?
    // example: API always returns {friendlyMessage: 'Could not get item, access denied. No role ADMIN'}
    throw new ApiError('Request failed', response.status, response.statusText);
  }
}

function getApiDefaultHeaders(accessToken?: string): { headers?: {} } {
  let myHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Pragma: 'no-cache', // Necessary for IE11 TODO: Maybe make this fix only for IE11?
  };

  if (accessToken) {
    myHeaders = Object.assign(myHeaders, {
      Authorization: 'Bearer ' + accessToken,
    });
  }

  return {
    headers: myHeaders,
  };
}

function getApiAbsoluteUrl(relativeApiUrl: string): string {
  // todo: fix potential double slashes..?
  let baseUrl =
    (process && process.env && process.env.REACT_APP_API) || undefined;
  if (baseUrl === undefined) {
    throw Error('You BUTT, error set REACT_APP_API in enviroment variables..');
  }

  baseUrl = baseUrl.replace(/\/$/, '');
  return `${baseUrl}${relativeApiUrl}`;
}

/**
 * This returns the fetch-Promise. use this if you want to add access token yourself
 * @param {string} relativeUrl - the APi relative url. Starts with /  !. REACT_APP_API + relativeUrl = absoluteUrl.
 * @param {HTTPMethod} method - string GET POST etc.
 * @param {string} token - access token
 * @param {{}} body - (optional) json body
 * @param {{}} options - (optional) fetch options if you want to override something
 * @returns {Promise<Response>}
 */
export function getApiRequestAdvanced(
  relativeUrl: string,
  method: HTTPMethod,
  token: string | null,
  body?: {} | undefined,
  options?: {}
): Promise<Response> {
  if (relativeUrl[0] !== '/') {
    throw new Error('relativeUrl must start with /');
  }

  const defaultHeaders = getApiDefaultHeaders(token!);
  const apiUrl = getApiAbsoluteUrl(relativeUrl);
  let tempBodyOrNothing = body ? { body: JSON.stringify(body) } : {};

  // we merge in options if specified! this means that we can
  // override headers, method, etc. if we want to in special cases
  const xOptions = Object.assign(
    defaultHeaders,
    tempBodyOrNothing,
    { method: method },
    options!
  );

  return fetch(apiUrl, xOptions);
}

export function getAccessTokenFromStore() {
  const state = myStore && myStore.getState();
  const accessToken: string | null =
    (state &&
      state.auth &&
      state.auth.token &&
      state.auth.token.access_token) ||
    null;
  return accessToken;
}

/**
 * Returns the fetch-promise. access token added from store
 * @param {string} relativeUrl - the APi relative url. Starts with /  !. REACT_APP_API + relativeUrl = absoluteUrl.
 * @param {HTTPMethod} method - string GET POST etc.
 * @param {{}} body - (optional) json body
 * @param {{}} options - (optional) fetch options if you want to override something
 * @returns {Promise<Response>}
 */
export function getApiRequest(
  relativeUrl: string,
  method: HTTPMethod,
  body?: {} | undefined,
  options?: {}
) {
  return getApiRequestAdvanced(
    relativeUrl,
    method,
    getAccessTokenFromStore(),
    body,
    options
  );
}
