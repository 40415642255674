import styled from 'styled-components';
import { Colors } from '../../common';

export const Box = styled.div`
  border: 1px solid ${Colors.GREYBORDER};
  border-radius: 0.25em;
  background-color: #ffffff;
  margin: 0.5em;
  padding: 1.5em 1.875em;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
`;

export const DialogBox = styled(Box)`
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
`;
