import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../../common';

export interface ISpinnerProps {
  /*** animation name **/
  name?: 'munikum' | 'chasing-dots';

  /*** optional color, default is white **/
  color?: string;
}

interface ICircleProps {
  rotate?: number;
  delay?: number;
  color?: string;
}

const Bar: React.SFC<ICircleProps> = props => {
  const circleFadeDelay = keyframes`
          0%, 39%, 100% {
            opacity: 0;
          }
          40% {
            opacity: 1;
          }
        `;

  const StyledBar = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    &:before {
      content: '';
      display: block;
      background-color: ${(p: { color?: string }) =>
        p.color ? p.color : Colors.WHITE};
      margin: 0 auto;
      width: 2px;
      height: 6px;
      border-radius: 1px;
      animation: ${circleFadeDelay} 1.5s infinite ease-in-out both;
    }
  `;

  const RotatedStyledBar = styled(StyledBar)`
    transform: rotate(${props.rotate + 'deg'});
    &:before {
      animation-delay: ${props.delay + 's'};
    }
  `;

  if (props.rotate) {
    return <RotatedStyledBar {...props} />;
  } else {
    return <StyledBar {...props} />;
  }
};

const Wrapper = styled.div`
  margin: 0;
  width: ${20}px;
  height: ${20}px;
  position: relative;
`;

export class ButtonSpinner extends React.PureComponent<ISpinnerProps, {}> {
  public name: string = 'fading-circles';

  render() {
    const { color } = this.props;
    console.log('color:', color);

    return (
      <Wrapper>
        <Bar color={color} />
        <Bar color={color} rotate={45} delay={-1.3} />
        <Bar color={color} rotate={90} delay={-1.1} />
        <Bar color={color} rotate={135} delay={-0.9} />
        <Bar color={color} rotate={180} delay={-0.7} />
        <Bar color={color} rotate={225} delay={-0.5} />
        <Bar color={color} rotate={270} delay={-0.3} />
        <Bar color={color} rotate={315} delay={-0.1} />
      </Wrapper>
    );
  }
}
