import * as React from 'react';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import { LanguageSelector } from '../../language/LanguageSelector/LanguageSelector';
import {
  IStepRenderProps,
  IWizardStep,
  Wizard,
} from '../../basic/Wizard/Wizard';
import { TextAreaMarked } from '../../basic/TextArea/TextAreaMarked';
import { safeInvoke } from '../../common';
import { Checkbox } from '../../basic/Checkbox/Checkbox';
import { CircleSpinner } from '../../basic/Loader/CircleSpinner';
import styled from 'styled-components';
import MiniErrorMessage from '../../basic/StatusMessage/MiniErrorMessage';
import { commonMessages } from '../../language/commonMessages';
import NotificationSettings from '../NotificationSettings/NotificationSettings';
import { TextNormal } from '../../basic/Structural/typography';
import PhoneSettings from '../PhoneSettings/PhoneSettings';
import {
  GET_USER_WIZARD_DATA,
  GetUserWizardData,
  UPDATE_ME_SETTINGS,
  UpdateMeSettings,
} from '../personQueries';
import { EditMyProfileViewComp } from '../EditMyProfileView';

// idea, we could add this lib for client side indexed fuzzy searches? =)
// for better search in kostra functions?
// http://elasticlunr.com/docs/index.html
// https://github.com/MihaiValentin/lunr-languages

// NOTE: ugly hack, fix when CRA updates https://github.com/facebook/create-react-app/issues/3722
const termsEn = require('../../../translations/terms-en.md.js').default;
const termsNn = require('../../../translations/terms-nn.md.js').default;
const termsNb = require('../../../translations/terms-nb.md.js').default;

const messages = defineMessages({
  welcomeUserPageTitle: {
    id: 'welcome.title',
    defaultMessage: 'Setup new user',
  },
  welcomeStep1Title: {
    id: 'welcome.step1.title',
    defaultMessage: 'Choose language',
  },
  welcomeStep2Title: {
    id: 'welcome.step2.title',
    defaultMessage: 'User profile',
  },
  welcomeStep25Sectors: {
    id: 'welcome.step25.title',
    defaultMessage: 'Choose your sectors of interests',
  },
  welcomeStep3Title: {
    id: 'welcome.step3.title',
    defaultMessage: 'User terms',
  },
  welcomeStep3AgreeToUserTermsCheckbox: {
    id: 'welcome.step3.agreeToUserTermsCheckbox',
    defaultMessage: 'I agree to the terms of service',
  },
  errorMustAcceptUserTerms: {
    id: 'welcome.step3.errorMustAcceptUserTerms',
    defaultMessage: 'You must agree to our terms and conditions',
  },
  welcomeStepNotificationsEmail: {
    id: 'welcome.welcomeStepNotificationsEmail',
    defaultMessage: 'E-mail notifications',
  },
  welcomeStepNotificationsPhone: {
    id: 'welcome.welcomeStepNotificationsPhone',
    defaultMessage: 'Contact via phone',
  },
});

interface IWelcomeUserWizardProps {
  // isWelcomeUserVisible: boolean;
  // myProfile?: IPerson;
  // tslint:disable-next-line
  onWizardCompleted?: (data: any) => void;
}

interface IAcceptUserTerms {
  accepted: boolean;
}

const SectorBox = styled.div`
  padding: 1em;
`;

const CenterDiv = styled.div`
  //background-color: orange;
  align-self: center;
  justify-self: center;
  flex: 1 1 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export class WelcomeUserWizardComp2 extends React.PureComponent<
  IWelcomeUserWizardProps & InjectedIntlProps,
  { direction: 'back' | 'forward' }
> {
  // ref to my profile form
  // tslint:disable-next-line

  // ref to accept terms form
  // tslint:disable-next-line
  // private userTermsFormElement: any;

  //
  // handleChangeLocale = (locale: SupportedUserLocale) => {
  //   // safeInvoke(this.props.onChangeLocale, locale);
  // };

  private editProfileRef: React.RefObject<EditMyProfileViewComp>;

  constructor(props: any) {
    super(props);

    this.editProfileRef = React.createRef();
  }

  render() {
    const { intl } = this.props;

    // align-self: center;
    // justify-self: center;
    // flex: 1 1 auto;
    //
    // display: flex;
    // justify-content: center;
    // align-items: center;

    const steps: Array<IWizardStep<any>> = [
      {
        title: intl.formatMessage(messages.welcomeStep1Title),
        content: (stepProps: IStepRenderProps) => {
          return (
            <CenterDiv>
              <div style={{ display: 'flex' }}>
                <TextNormal
                  style={{
                    width: '200px',
                  }}
                >
                  <FormattedMessage
                    id="welcome.step1.chooselanguagetext"
                    defaultMessage="Welcome to Munikum®! {br} Please choose your preferred language."
                    values={{
                      br: <br />,
                    }}
                  />
                </TextNormal>
                <div>
                  <LanguageSelector />
                </div>
              </div>
            </CenterDiv>
          );
        },
      },
      {
        title: intl.formatMessage(messages.welcomeStep2Title),
        // initialData: form2InitialValues,
        navigateNextRequested: () => {
          // console.log('navigateNextRequested in WelcomeUserWizard');
          this.setState(
            {
              ...this.state,
              direction: 'forward',
            },
            () => {
              // console.log('callback after set forward, submitting..');
              // this.profileFormElement.submitForm();
              if (this.editProfileRef.current) {
                this.editProfileRef.current.doSubmit();
              }
            }
          );
        },
        navigatePreviousRequested: () => {
          // console.log('navigatePreviousRequested in WelcomeUserWizard');
          // if (this.profileFormElement !== undefined) {
          this.setState(
            {
              ...this.state,
              direction: 'back',
            },
            () => {
              // console.log('callback after set back, submitting..');
              // this.profileFormElement.submitForm();

              if (this.editProfileRef.current) {
                this.editProfileRef.current.doSubmit();
              }
            }
          );
          // this.profileFormElement.submitForm();
          // }
        },
        content: stepProps => {
          return (
            <div>
              <FormattedMessage
                id="welcome.step2.text"
                defaultMessage="The user profile must be created the first time you log on to Årshjul.no. {br} Please enter your name and job title."
                values={{
                  br: <br />,
                }}
              />
              <br />
              <br />

              <EditMyProfileViewComp
                showButtons={false}
                intl={intl}
                ref={this.editProfileRef}
                onSaveComplete={() => {
                  if (this.state.direction === 'forward') {
                    stepProps.handleNavigateNextAcceptedAndSubmitStep({});
                  } else if (this.state.direction === 'back') {
                    stepProps.handleNavigatePreviousAcceptedAndSubmitStep({});
                  }
                }}
              />
              <br />
            </div>
          );
        },
      },
      {
        title: intl.formatMessage(messages.welcomeStepNotificationsEmail),
        content: stepProps => (
          <div
            style={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div>
              <FormattedMessage
                id="welcome.janne.email"
                defaultMessage="May we please send you emails?"
                values={{
                  br: <br />,
                }}
              />
              <br />
              <br />
              <NotificationSettings intl={intl} butt={true} />
            </div>
          </div>
        ),
      },
      {
        title: intl.formatMessage(messages.welcomeStepNotificationsPhone),
        content: stepProps => (
          <div
            style={{
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div>
              <FormattedMessage
                id="welcome.janne.phone"
                defaultMessage="Is it ok if we call you later?"
                values={{
                  br: <br />,
                }}
              />
              <br />
              <br />
              <PhoneSettings style={{ marginTop: '2em' }} />
            </div>
          </div>
        ),
      },

      // {
      //   title: intl.formatMessage(messages.welcomeStep25Sectors),
      //   content: (stepProps: IStepRenderProps) => {
      //     return (
      //       <div>
      //         <div style={{ marginTop: '-1em', marginBottom: '2em' }}>
      //           <FormattedMessage
      //             id="welcome.step25.kostraInfo"
      //             defaultMessage="Choose KOSTRA functions that are listed in your job description or you find interesting. {br}We use this information to serve you with more relevant data"
      //             values={{
      //               br: <br />,
      //             }}
      //           />
      //         </div>
      //         <KostraSelector />
      //       </div>
      //     );
      //   },
      // },
      {
        title: intl.formatMessage(messages.welcomeStep3Title),
        isInitialValid: false,
        content: stepProps => {
          return (
            <div>
              <GetUserWizardData query={GET_USER_WIZARD_DATA}>
                {({ data, loading, error }) => {
                  if (loading) {
                    return (
                      <CenterDiv>
                        <CircleSpinner />
                      </CenterDiv>
                    );
                  }

                  if (error || !data) {
                    return (
                      <CenterDiv>
                        <MiniErrorMessage
                          message={intl.formatMessage(
                            commonMessages.errorLoadData
                          )}
                          intl={intl}
                        />
                      </CenterDiv>
                    );
                  }
                  //
                  // if (
                  //   data &&
                  //   data.me &&
                  //   data.me.settings &&
                  //   data.me.settings.acceptedUserTerms
                  // ) {
                  //   console.log('ACCEPTED OK!');
                  //   if (!stepProps.isStepValid) {
                  //     stepProps.handleValidateStep(true);
                  //   }
                  // }

                  return (
                    <UpdateMeSettings mutation={UPDATE_ME_SETTINGS}>
                      {(
                        updateSettings,
                        {
                          data: uData,
                          loading: uLoading,
                          called,
                          error: uError,
                        }
                      ) => {
                        let myTerms = 'default terms of usage';
                        // console.log('hmm');
                        if (intl.locale.toLowerCase() === 'en') {
                          myTerms = termsEn;
                        } else if (intl.locale.toLowerCase() === 'nn') {
                          myTerms = termsNn;
                        } else if (intl.locale.toLowerCase() === 'nb') {
                          myTerms = termsNb;
                        } else {
                          myTerms =
                            'Terms of usage not found for language ' +
                            intl.locale.toLowerCase();
                        }

                        return (
                          <div>
                            <FormattedMessage
                              id="welcome.step3.usertermstext"
                              defaultMessage="To use the services in Munikum® you must read and approve to our terms of service."
                              values={{
                                br: <br />,
                              }}
                            />
                            <br />
                            <br />

                            <div>
                              <TextAreaMarked
                                value={myTerms}
                                style={{
                                  width: '100%',
                                  boxSizing: 'border-box',
                                  minHeight: '20em',
                                  height: '28em',
                                }}
                                readOnly={true}
                              />
                            </div>

                            <Checkbox
                              label={intl.formatMessage(
                                messages.welcomeStep3AgreeToUserTermsCheckbox
                              )}
                              name={'accepted'}
                              checked={data.me.settings.acceptedUserTerms}
                              onClick={(e: any, isChecked: boolean) => {
                                // console.log('THOMAS', isChecked);

                                updateSettings({
                                  variables: {
                                    input: {
                                      showAssignedTasks:
                                        data.me.settings.showAssignedTasks,
                                      acceptedUserTerms: isChecked,
                                      allowContactViaEmail:
                                        data.me.settings.allowContactViaEmail,
                                      sendWeeklyNotificationMail:
                                        data.me.settings
                                          .sendWeeklyNotificationMail,
                                      sendInstantNotificationMail:
                                        data.me.settings
                                          .sendInstantNotificationMail,
                                      sendDailyNotificationMail:
                                        data.me.settings
                                          .sendDailyNotificationMail,
                                      allowContactViaPhone:
                                        data.me.settings.allowContactViaPhone,
                                    },
                                  },
                                })
                                  .then(res => {
                                    // console.log('great success');
                                    stepProps.handleValidateStep(isChecked);
                                  })
                                  .catch(err => {
                                    // console.log(err);
                                    stepProps.handleValidateStep(false);
                                    alert('error saving to database');
                                  });
                              }}
                              // error={formikProps.errors.accepted}
                            />
                          </div>
                        );
                      }}
                    </UpdateMeSettings>
                  );
                }}
              </GetUserWizardData>
            </div>
          );
        },
      },
    ];

    return (
      <Wizard
        isOpen={true}
        title={intl.formatMessage(messages.welcomeUserPageTitle)}
        steps={steps}
        style={{
          // width: '68em',
          // height: '40em',
          // minWidth: '68em',
          // minHeight: '40em',
          minWidth: '850px',
          maxWidth: '950px', // TODO, change when 7 categories..
          minHeight: '575px',
          maxHeight: '80vh',
        }}
        // tslint:disable-next-line
        onCompleted={(stepsData: ReadonlyArray<any>) =>
          this.handleCompleted(stepsData)
        }
      />
    );
  }

  // tslint:disable-next-line
  handleCompleted = (stepsData: ReadonlyArray<any>) => {
    safeInvoke(this.props.onWizardCompleted, stepsData);
  };
}

export const UserWizard = injectIntl(WelcomeUserWizardComp2);
