import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Colors } from '../../common';

const Ul = styled.ul`
  margin: 0;
  border-radius: 3px;
  background-color: white;
  padding: 0px;
  list-style: none;
  text-align: left;
  color: ${Colors.BLACK};
  min-width: 9.375em;
  //width: 17.5em; // if you need
  max-height: 30em;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  display: block;
  &:hover {
    cursor: pointer; // to avoid "glitches"
  }
`;

export interface IMenuProps {
  style?: CSSProperties;
}

interface IMenuState {}

/**
 * Menu component.
 * note that this does not have menu styling, you need to wrap the menu
 * in for example a TogglePopover component =)
 */
export class Menu extends React.Component<IMenuProps, IMenuState> {
  render() {
    const { style } = this.props;

    // TODO: we could analyze children, find MenuItems, and dispatch a onClick-event on menu
    // in order to solve closing the popover when clicking menuItem...

    return <Ul style={style}>{this.props.children}</Ul>;
  }
}
