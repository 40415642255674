import { IPerson } from './models/person';
import {
  ApiError,
  getApiRequest,
  getApiRequestAdvanced,
  parsedResponse,
} from './api';
import { IPersonSimple } from './models/discussion';
import { myApolloClient } from '../graphql/apolloClientFactory';
import { GET_ME } from '../components/annualCycle/CreateAnnualCycleForm';

export interface IPersonEditForm {
  id: string;
  email: string;
  name: string;
  jobTitle: string;
  department?: string;
  description?: string;
  userStatus: number;
  language?: string;
  activated: boolean;
  deactivated: boolean;
}

export interface IPersonMeForm {
  name?: string;
  description?: string;
  jobTitle?: string;
  department?: string;
  // phone?: string;
  // userConcent?: boolean;
  oldPassword?: string;
  newPassword1?: string;
  newPassword2?: string;
  language?: string;
}

export interface IPersonInviteForm {
  email: string;
  name?: string;
  organization?: string;
}

export async function getMe(token: string): Promise<IPerson> {
  const response = await getApiRequestAdvanced('/person/me', 'GET', token);
  return await parsedResponse<IPerson>(response);
}

export async function getMeAuthenticated(): Promise<IPerson> {
  const response = await getApiRequest('/person/me', 'GET');
  return await parsedResponse<IPerson>(response);
}

export async function getPersonByHash(id: string): Promise<IPersonSimple> {
  const response = await getApiRequest('/person/get/' + id, 'GET');
  return await parsedResponse<IPersonSimple>(response);
}
export async function deleteProfileImg(): Promise<{}> {
  const response = await getApiRequest(
    '/person/remove-profile-picture/',
    'GET'
  );
  const temp = await parsedResponse<{}>(response);
  const temp2 = await myApolloClient.query({
    query: GET_ME,
    fetchPolicy: 'network-only',
  });
  return temp;
}

export async function getPersonList(): Promise<ReadonlyArray<IPerson>> {
  const response = await getApiRequest('/person/list', 'GET');
  return await parsedResponse<ReadonlyArray<IPerson>>(response);
}

// need admin?
export async function getPersonById(id: string): Promise<IPerson> {
  const response = await getApiRequest('/person/', 'POST', { id: id });
  return await parsedResponse<IPerson>(response);
}

// need admin access!
export async function updatePerson(person: IPersonEditForm): Promise<{}> {
  console.log('debug', person);
  const response = await getApiRequest('/person/update', 'POST', { ...person });
  return await parsedResponse<{}>(response);
}

export async function invitePerson(person: IPersonInviteForm): Promise<{}> {
  const response = await getApiRequest('/auth/invite', 'POST', { ...person });
  return await parsedResponse<{}>(response);
}

export async function updateMe(
  values: IPersonMeForm
  // meId: string
): Promise<IPerson> {
  console.log('ready to update me');

  // if (meId === undefined || meId === '') {
  //   throw Error('need my users id to update redux after update db :p');
  // }

  const response = await getApiRequest('/person/updateMe', 'POST', {
    ...values,
  });

  // return await parsedResponse<{}>(response);
  if (response.ok) {
    // need to fetch user also:
    const resp2 = await getMeAuthenticated();
    console.log('GETME', resp2);
    return resp2;
    console.log('No skjer det', values.department);
  } else {
    throw new ApiError('updateMe failed', response.status, response.statusText);
  }
}
