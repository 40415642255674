import * as React from 'react';
import { saveAs } from 'file-saver';
import { getAccessTokenFromStore } from '../../../services/api';

export const doDownloadFile = (id: string, baseUrl?: string) => {
  const apiUrl = process.env.REACT_APP_API;

  if (!baseUrl) {
    baseUrl = apiUrl;
  }

  const token = getAccessTokenFromStore();
  const url = baseUrl + '/file/stream/' + id;

  const xhr = new XMLHttpRequest();

  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4) {
      // console.log(xhr.response);

      const fileNameHeader = xhr.getResponseHeader('Content-Disposition');
      const fileName = fileNameHeader!.split('=')[1];

      saveAs(xhr.response, fileName);
    }
  };

  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  xhr.send(null);
};

export const getDownloadFile = (e: React.MouseEvent<HTMLElement>) => {
  const id = e.currentTarget.dataset.id as string;
  const baseUrl = e.currentTarget.dataset.url as string;

  doDownloadFile(id, baseUrl);
};

const guidgenS4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export const guid = () => {
  return (
    guidgenS4() +
    guidgenS4() +
    '-' +
    guidgenS4() +
    '-' +
    guidgenS4() +
    '-' +
    guidgenS4() +
    '-' +
    guidgenS4() +
    guidgenS4() +
    guidgenS4()
  );
};

export const humanFileSize = (bytes, si) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  let u = -1;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);

  return bytes.toFixed(1) + ' ' + units[u];
};
