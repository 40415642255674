import * as React from 'react';
import { InjectedAuthRouterProps } from 'redux-auth-wrapper/history4/redirect';
import {
  defineMessages,
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import Helmet from 'react-helmet';
import {
  MarkdownPreview,
  RenderMode,
} from '../../components/basic/MarkdownPreview/MarkdownPreview';
import { ILanguageSelectorProps } from '../../components/language/LanguageSelector/LanguageSelector';
import { Page, PageTitle, PageTop } from '../../components/layout/Page/Page';
import { FlexGrid } from '../../components/layout/FlexGrid/FlexGrid';
import { TextNormal } from '../../components/basic/Structural/typography';

/**
 * NOTE: uglyhack: pretend its js, lets webpack inport content. default contains raw string
 * Create react app is working on a better solution:
 * https://github.com/facebook/create-react-app/issues/3722
 */
const termsEn = require('../../translations/terms-en.md').default;
const termsNn = require('../../translations/terms-nn.md.js').default;
const termsNb = require('../../translations/terms-nb.md.js').default;

const messages = defineMessages({
  title: {
    id: 'MainLayout.aboutLink',
    defaultMessage: 'Brukervilkår',
  },
});

class UserTermsPageComp extends React.Component<
  InjectedAuthRouterProps & InjectedIntlProps & ILanguageSelectorProps,
  {}
> {
  constructor(
    props: InjectedAuthRouterProps & InjectedIntlProps & ILanguageSelectorProps
  ) {
    super(props);
  }

  render() {
    const { intl } = this.props;

    let myTerms = 'default terms of usage';
    // console.log('hmm');
    if (intl.locale.toLowerCase() === 'en') {
      myTerms = termsEn;
    } else if (intl.locale.toLowerCase() === 'nn') {
      myTerms = termsNn;
    } else if (intl.locale.toLowerCase() === 'nb') {
      myTerms = termsNb;
    } else {
      myTerms =
        'Terms of usage not found for language ' + intl.locale.toLowerCase();
    }
    return (
      <Page
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Helmet>
          <title>title={intl.formatMessage(messages.title)}</title>
        </Helmet>
        <div>
          <PageTop>
            <PageTitle>
              <FormattedMessage
                id={'userTerms.userTerms'}
                defaultMessage={'User terms'}
              />
            </PageTitle>
          </PageTop>
          <FlexGrid style={{ padding: '2em' }}>
            <TextNormal>
              <MarkdownPreview
                rawMarkdown={myTerms}
                renderMode={RenderMode.Full}
              />
              {/*<TextAreaMarked*/}
              {/*value={myTerms}*/}
              {/*style={{ width: '39em', height: '20em' }}*/}
              {/*readOnly={true}*/}
              {/*/>*/}
            </TextNormal>
          </FlexGrid>
        </div>
      </Page>
    );
  }
}

export const UserTermsPage = injectIntl(UserTermsPageComp);
