import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../../common';

export interface IBoxHillProps {
  color?: string;
  loadingMessages?: Array<string>;
}

const hillAnimation = keyframes`
          0% {
            transform: translate(0, -1em) rotate(-45deg);
          }
          5% {
            transform: translate(0, -1em) rotate(-50deg);
          }
          20% {
            transform: translate(1em, -2em) rotate(47deg);
          }
          25% {
            transform: translate(1em, -2em) rotate(45deg);
          }
          30% {
            transform: translate(1em, -2em) rotate(40deg);
          }
          45% {
            transform: translate(2em, -3em) rotate(137deg);
          }
          50% {
            transform: translate(2em, -3em) rotate(135deg);
          }
          55% {
            transform: translate(2em, -3em) rotate(130deg);
          }
          70% {
            transform: translate(3em, -4em) rotate(217deg);
          }
          75% {
            transform: translate(3em, -4em) rotate(220deg);
          }
          100% {
            transform: translate(0, -1em) rotate(-225deg);
          }
        `;
const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2.7em;
  margin-left: -2.7em;
  width: 5.4em;
  height: 5.4em;
`;
const Hill = styled.div`
  position: absolute;
  width: 7.1em;
  height: 7.1em;
  top: 1.7em;
  left: 1.7em;
  background-color: transparent;
  border-left: 0.25em solid ${Colors.DARKBLUE};
  transform: rotate(45deg);
  :after {
    content: '';
    position: absolute;
    width: 7.1em;
    height: 7.1em;
    left: 0;
  }
`;
const Box = styled.div`
  position: absolute;
  left: 0;
  bottom: -0.1em;
  width: 1em;
  height: 1em;
  background-color: transparent;
  border: 0.25em solid ${Colors.DARKBLUE};
  border-radius: 15%;
  transform: translate(0, -1em) rotate(-45deg);
  animation: ${hillAnimation} 2.5s cubic-bezier(0.79, 0, 0.47, 0.97) infinite;
`;

export class BoxHill extends React.PureComponent<IBoxHillProps, {}> {
  render() {
    // const myColor = this.props.color || Colors.DARKBLUE;

    return (
      <Loader>
        <Hill />
        <Box />
      </Loader>
    );
  }
}
