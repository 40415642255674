import * as React from 'react';
import { CSSProperties, PureComponent } from 'react';
import styled from 'styled-components';
import { safeInvokeDeprecated } from '../../common';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { MunikumKeys } from '../../common/keys';
import { StatusMessage, ThemeColor } from '../StatusMessage/StatusMessage';
import { Overlay } from '../Overlay/Overlay';
import { commonMessages } from '../../language/commonMessages';

export enum CloseWarningOption {
  NONE = 'NONE',
  SIMPLE = 'SIMPLE',
  COOL = 'COOL',
}

export interface ICleanDialogProps {
  isOpen: boolean;

  canEscapeKeyClose?: boolean;

  canOutsideClickClose?: boolean;

  showCloseWarning?: CloseWarningOption;

  onClose?: () => void;

  style?: CSSProperties;
}

const DialogContainer = styled.div`
  min-width: 15em; // 240px;
  min-height: 15.625em; // 250px;

  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
`;

/**
 * Dialog with built in stuff like close button, escape click closes dialog, etc.
 * BUT NO STYLING HERE like in Dialog component =) use your own dialog styling as content.
 *
 * TODO: Refactor Dialog to just use this, keep all escape, click outside etc logic in this file and remove from Dialog.tsx =)
 *
 * TODO: refactor this, remove warning logic from here, dispatch onBeforeClose event?
 */
class CleanDialogComp extends PureComponent<
  ICleanDialogProps & InjectedIntlProps,
  {
    showWarning: boolean;
  }
> {
  public static defaultProps: ICleanDialogProps = {
    isOpen: false,
    canEscapeKeyClose: true,
    canOutsideClickClose: true,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      showWarning: false,
    };
  }

  handleClickOutside = () => {
    if (this.props.canOutsideClickClose) {
      this.maybeCloseIt();
    }
  };

  maybeCloseIt = () => {
    if (this.props.showCloseWarning) {
      if (this.props.showCloseWarning === CloseWarningOption.SIMPLE) {
        const result = confirm(
          this.props.intl.formatMessage(commonMessages.clickOutsideWarning)
        );
        if (result) {
          safeInvokeDeprecated(this.props.onClose);
        }
      } else if (this.props.showCloseWarning === CloseWarningOption.COOL) {
        this.setState({
          showWarning: true,
        });
      }
    } else {
      safeInvokeDeprecated(this.props.onClose);
    }
  };

  protected handleKeyUp = (e: any) => {
    if (
      this.props.isOpen &&
      this.props.canEscapeKeyClose &&
      e.which === MunikumKeys.ESCAPE
    ) {
      // console.log('key up in CLEANDIALOG: ' + e.which + ', meta: ' + e.metaKey);
      this.maybeCloseIt();
    }
  };

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  public render() {
    const { isOpen } = this.props;

    return (
      <Overlay
        {...this.props}
        isOpen={isOpen}
        backgroundStyle={'shadow'}
        onClickShadow={() => {
          this.handleClickOutside();
        }}
      >
        <DialogContainer
          isOpen={isOpen}
          onClick={(e: any) => {
            // console.log('click dialog container');
            e.stopPropagation();
          }}
          style={this.props.style}
        >
          {this.props.children}
          <Overlay isOpen={this.state.showWarning}>
            {/* TODO: implement another Button in SuccessMessage, or new component <ConfirmMessage onCancel onOk /> */}
            {/* or we can use js built in? https://www.w3schools.com/jsref/tryit.asp?filename=tryjsref_confirm*/}
            <StatusMessage
              theme={ThemeColor.SUCCESS_BLUE}
              title={'Hang on!'}
              text={'Are you sure you wanna close? You will lose your stuff!'}
              buttonText={'Ok'}
              onClickButton={() =>
                this.setState(
                  {
                    showWarning: false,
                  },
                  () => {
                    safeInvokeDeprecated(this.props.onClose);
                  }
                )
              }
            />
          </Overlay>
        </DialogContainer>
      </Overlay>
    );
  }
}

export const CleanDialog = injectIntl(CleanDialogComp);
