import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { IHttpLink } from '../../services/models/actionValue';
import { commonMessages } from '../language/commonMessages';
import { ColorTheme, safeInvokeDeprecated } from '../common';
import { FieldGroup } from '../basic/FieldGroup/FieldGroup';
import { MunikumIcons } from '../common/icons';
import { Dialog } from '../basic/Dialog/Dialog';
import { Button, TextField } from '../basic';
import { FormLabel } from '../basic/FormLabel/FormLabel';

const messages = defineMessages({
  linksLabel: {
    id: 'editlinksview2.linkslabel',
    defaultMessage: 'Links',
  },
  url: {
    id: 'editlinksview2.url',
    defaultMessage: 'Url',
  },
  title: {
    id: 'editlinksview2.title',
    defaultMessage: 'title',
  },
});

const Container = styled.div`
  background-color: #51a0fa;
`;

const DialogContainer = styled.div`
  display: flex;
  justify-items: stretch;
`;
const DialogLeft = styled.div`
  flex: 3;
  //background-color: lightblue;
  display: flex;
  flex-direction: column;
`;

const LinkItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;

  //background-color: hotpink;
`;

const ButtonFooter = styled.div`
  text-align: right;
  align-self: stretch;
  margin-top: 1em;
`;

export interface IEditLinksView {
  links?: ReadonlyArray<IHttpLink>;
  onLinksChanged?: (newLinks: ReadonlyArray<IHttpLink>) => void;
  style?: CSSProperties;
  title?: string;
}

interface IExtraEditData {
  lastChecked?: Date;
  editIndex?: number;
}

interface IEditLinksState {
  freshUrl: string;

  isEditing: boolean;
  editingItem?: Partial<IHttpLink> & IExtraEditData;
}

function isValidUrl(str: string) {
  const pattern = new RegExp(
    '^(https?://)?' + // protocol
    '((([a-zd]([a-zd-]*[a-zd])*).)+[a-z]{2,}|' + // domain name
    '((d{1,3}.){3}d{1,3}))' + // OR ip (v4) address
    '(:d+)?(/[-a-zd%_.~+]*)*' + // port and path
    '(?[;&a-zd%_.~+=-]*)?' + // query string
      '(#[-a-zd_]*)?$',
    'i'
  ); // fragment locater
  return pattern.test(str);
}

/**
 * EditLinksView2, wuick and dirty, smaller ui
 * TODO: improvements...:
 * - validation, use formik?
 * - fix focus when opening dialog
 * - +++
 */
class EditLinksComp extends React.PureComponent<
  IEditLinksView & InjectedIntlProps,
  IEditLinksState
> {
  constructor(props: IEditLinksView & InjectedIntlProps) {
    super(props);
    this.state = {
      freshUrl: '',
      isEditing: false,
    };
  }

  // tslint:disable-next-line
  handleChangeFreshUrl = (e: any) => {
    this.setState({
      ...this.state,
      freshUrl: e.target.value,
    });
  };

  handleDelete = (value: IHttpLink, index: number) => {
    let temp: Array<IHttpLink> = [];
    if (this.props.links) {
      temp = this.props.links.slice();
      temp.splice(index, 1);
    }
    safeInvokeDeprecated(this.props.onLinksChanged, temp);
  };

  handleEdit = (value: IHttpLink, index: number) => {
    // console.log('handle edit val idnex', value, index);

    // todo: get title from url if no title?
    // todo: get site image from url?

    this.setState({
      ...this.state,
      isEditing: true,
      editingItem: {
        ...this.state.editingItem,
        href: value.href,
        label: value.label,
        lastChecked: new Date(),
        editIndex: index,
      },
    });
  };

  handleAdd = () => {
    // console.log('handle add');
    // if (this.state.freshUrl.length === 0) {
    //   // todo: set validation error on url field
    //   return;
    // }

    // todo: get title from url?
    // todo: get site image from url?
    // const isValid = isValidUrl(this.state.freshUrl);
    // if (isValid) {
    //   fetch(this.state.freshUrl).then(
    //     (response) => response.text()
    //   ).then((responseText) => {
    //    todo: get title from response text?
    //   }).catch((reason) => {
    //     this.setState({
    //       ...this.state,
    //       isEditing: true,
    //       editingItem: {
    //         ...this.state.editingItem,
    //         href: this.state.freshUrl,
    //         label: '',
    //         lastChecked: new Date(),
    //         editIndex: -1
    //       }
    //     });
    //   });
    //
    // } else {
    //   this.setState({
    //     ...this.state,
    //     isEditing: true,
    //     editingItem: {
    //       ...this.state.editingItem,
    //       href: this.state.freshUrl,
    //       label: '',
    //       lastChecked: new Date(),
    //       editIndex: -1
    //     }
    //   });
    // }

    this.setState({
      ...this.state,
      isEditing: true,
      editingItem: {
        ...this.state.editingItem,
        href: '', // this.state.freshUrl,
        label: '', // this.state.freshUrl, // note: set url as title as default?
        lastChecked: new Date(),
        editIndex: -1,
      },
    });
  };

  handleCancelEditLink = () => {
    // console.log('cancel edit link');
    this.setState({
      ...this.state,
      isEditing: false,
      editingItem: undefined,
    });
  };

  handleCreateOrUpdate = () => {
    if (!this.state.editingItem) {
      return;
    }

    let temp: Array<IHttpLink> = [];
    const item: IHttpLink = {
      label: this.state.editingItem.label || this.state.editingItem.href || '',
      href: this.state.editingItem.href || '',
    };

    const currentIndex = this.state.editingItem.editIndex;

    if (currentIndex !== undefined && currentIndex >= 0) {
      // console.log('handle update');
      // update
      temp = this.props.links!.slice();
      temp[currentIndex] = item;
    } else {
      // add
      // console.log('handle add');
      temp = this.props.links ? [...this.props.links, item] : [item];
    }

    safeInvokeDeprecated(this.props.onLinksChanged, temp);

    this.setState({
      ...this.state,
      freshUrl: 'http://',
      editingItem: undefined,
      isEditing: false,
    });
  };

  handleChangeDialog = (v: string, key: keyof IHttpLink) => {
    this.setState({
      ...this.state,
      editingItem: {
        ...this.state.editingItem,
        [key]: v,
      },
    });
  };

  handleChangeInline = (
    // tslint:disable-next-line
    e: any,
    value: IHttpLink,
    index: number,
    accessor: keyof IHttpLink
  ) => {
    if (!this.props.links) {
      return;
    }
    const temp = this.props.links.slice();
    temp[index][accessor] = e.target.value;

    safeInvokeDeprecated(this.props.onLinksChanged, temp);
  };

  render() {
    const { links, intl } = this.props;
    const { freshUrl, isEditing, editingItem } = this.state;

    // console.log('render editing item?', editingItem);

    return (
      <FieldGroup style={this.props.style}>
        <Dialog
          isOpen={isEditing}
          title={intl.formatMessage(commonMessages.edit)}
          onClose={this.handleCancelEditLink}
        >
          <DialogContainer>
            {editingItem && (
              <DialogLeft>
                <TextField
                  name={'editUrl'}
                  label={intl.formatMessage(messages.url)}
                  style={{
                    width: '100%',
                  }}
                  value={editingItem.href}
                  // tslint:disable-next-line
                  onChange={(e: any) => {
                    this.handleChangeDialog(e.target.value, 'href');
                  }}
                  placeholder={'https://'}
                />
                <TextField
                  name={'editTitle'}
                  label={intl.formatMessage(messages.title)}
                  style={{
                    width: '100%',
                  }}
                  value={editingItem.label}
                  // tslint:disable-next-line
                  onChange={(e: any) => {
                    this.handleChangeDialog(e.target.value, 'label');
                  }}
                  placeholder={
                    'Synlig tittel på siden, kan være det samme som URL'
                  }
                  info={
                    'Du kan la denne være blank, da vil URL settes som tittel'
                  }
                />
              </DialogLeft>
            )}
          </DialogContainer>
          <ButtonFooter>
            <Button
              text={'Cancel'}
              theme={ColorTheme.White}
              leftIcon={MunikumIcons.Cancel}
              onClick={this.handleCancelEditLink}
            />
            <Button
              text={'Save'}
              leftIcon={MunikumIcons.Save}
              theme={ColorTheme.Red}
              style={{ marginLeft: '.825em' }}
              onClick={this.handleCreateOrUpdate}
            />
          </ButtonFooter>
        </Dialog>

        <FormLabel>{this.props.title}</FormLabel>

        {links &&
          links.map((value, index) => {
            return (
              <LinkItem key={'link_' + index}>
                <TextField
                  name={'link_url_' + index}
                  fieldGroupStyle={{
                    padding: '0',
                    width: '100%',
                  }}
                  style={{
                    width: '100%',
                  }}
                  // tslint:disable-next-line
                  onChange={(e: any) => {
                    this.handleChangeInline(e, value, index, 'href');
                  }}
                  value={value.href}
                />

                <Button
                  text={intl.formatMessage(commonMessages.edit)}
                  theme={ColorTheme.White}
                  leftIcon={MunikumIcons.Edit}
                  style={{
                    marginLeft: '.875em',
                  }}
                  onClick={() => this.handleEdit(value, index)}
                />

                <Button
                  text={intl.formatMessage(commonMessages.discard)}
                  theme={ColorTheme.Yellow}
                  leftIcon={MunikumIcons.Delete}
                  style={{
                    marginLeft: '.875em',
                  }}
                  onClick={() => this.handleDelete(value, index)}
                />
              </LinkItem>
            );
          })}

        <LinkItem>
          <Button
            text={intl.formatMessage(commonMessages.add)}
            leftIcon={MunikumIcons.Add}
            theme={ColorTheme.Red}
            style={{
              width: '100%',
            }}
            onClick={this.handleAdd}
          />
        </LinkItem>
      </FieldGroup>
    );
  }
}

export const EditLinksView = injectIntl(EditLinksComp);
