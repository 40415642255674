import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { Button } from '..';
import { Colors, ColorTheme, safeInvoke } from '../../common';
import { MunikumIcons } from '../../common/icons';
import styled from 'styled-components';
import { SimpleEditor } from '../SimpleEditor/SimpleEditor';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import { doDownloadFile, humanFileSize } from './FileCommon';
import 'font-awesome/css/font-awesome.css';
import { Mutation } from 'react-apollo';
import {
  UpdateFileDescriptionMutation,
  UpdateFileDescriptionVariables,
} from '../../../models/types';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import moment from 'moment';
import { commonMessages } from '../../language/commonMessages';
import { convertFromRaw } from 'draft-js';

const messages = defineMessages({
  fileInfo: {
    id: 'FileCard.fileInfo',
    defaultMessage: 'File Info',
  },
  fileSize: {
    id: 'FileCard.fileSize',
    defaultMessage: 'File size',
  },
  fileLastUpdated: {
    id: 'FileCard.fileLastUpdated',
    defaultMessage: 'Last updated',
  },
  openFile: {
    id: 'FileCard.openFile',
    defaultMessage: 'Open file',
  },
  deleteFile: {
    id: 'FileCard.deleteFile',
    defaultMessage: 'Delete file',
  },
  fileBelongsToEventWarning: {
    id: 'FileCard.fileBelongsToEventWarning',
    defaultMessage:
      'The file belongs to the event. Go to the event to edit or delete the file ',
  },
});

interface IFileIconTypeProps {
  type: string;
}

interface IFileIconTypeBoxProps {
  color: string;
}

const FileIconTypeBox = styled.span`
  font-size: 12px;
  color: ${(props: IFileIconTypeBoxProps) => props.color};
`;

const FileIconTypeText = styled.span`
  margin-top: 2px;
  font-size: 8px;
`;

export class FileIconType extends React.PureComponent<IFileIconTypeProps, {}> {
  private typeMap = [
    {
      type: 'image/svg+xml',
      color: 'red',
      text: 'svg',
    },
    {
      type: 'application/pdf',
      color: 'blue',
      text: 'pdf',
    },
    {
      type: 'image/jpg',
      color: 'green',
      text: 'jpg',
    },
    {
      type: 'image/jpeg',
      color: 'green',
      text: 'jpg',
    },
  ];

  constructor(props: IFileIconTypeProps) {
    super(props);
  }

  getTypeColor = (type: string) => {
    let color = 'black';
    for (let it in this.typeMap) {
      if (this.typeMap[it].type == type) {
        color = this.typeMap[it].color;
      }
    }

    return color;
  };

  getTypeText = (type: string) => {
    let text = '';
    for (let it in this.typeMap) {
      if (this.typeMap[it].type == type) {
        text = this.typeMap[it].text;
      }
    }

    return text;
  };

  render() {
    const { type } = this.props;

    return (
      <FileIconTypeBox color={this.getTypeColor(type)} className="fa-stack">
        <FileIconTypeText className="fa-stack-1x filetype-text">
          {this.getTypeText(type)}
        </FileIconTypeText>
        <i className="fa fa-file-o fa-stack-2x" />
      </FileIconTypeBox>
    );
  }
}

interface IFileInfoCardProps {
  fileid: string;
  canDelete?: boolean;
  canEdit?: boolean;
  onCancel: (isEmpty: boolean) => void;
  onDelete?: () => void;
  isCalendarEventForm?: boolean;
  style?: CSSProperties;
  hasWriteAccessButIsEventFile?: boolean;
  onSaveSuccess?: () => void;
}

export interface IFileInfoCardState {
  description: string;
  hasChanged: boolean;
}

const Container = styled.div`
  min-height: 15em;
  padding: 2em;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 32.5em;
`;

const ButtonsDiv = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4em;
  flex-direction: row;
`;

const ButtonNormalDiv = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  margin-top: 4em;
  justify-content: flex-end;
`;

const ButtonDeleteDiv = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 4em;
  justify-content: flex-start;
  align-items: flex-start;
`;

const FileInfoTitle = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const FileInfoFileTypeBox = styled.div`
  padding: 6px 5px 0 0;
`;

const FileInfoFileNameBox = styled.div`
  padding: 8px 10px 0 0;
  flex: 1;
`;

const FileInfoMetaBox = styled.div`
  margin: 5px 0 20px 0;
  padding: 10px 5px;
  border: 2px solid #eee;
  background-color: #eee;
  border-radius: 4px;
`;

const FileInfoToolBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const FileInfoMetaData = styled.table`
  margin-left: 30px;
  font-size: 70%;
  tr {
    td:first-child {
      font-weight: bold;
      :after {
        content: ':';
      }
    }
  }
`;

const EditorTop = styled.div`
  display: flex;
  flex-direction: row;
  div:first-child {
    flex: 1;
  }
  div:last-child {
    flex: 1;
  }
`;

export const FILE_INFO_QUERY = gql`
  query getFileInfoQuery($id: ID!) {
    fileInfo(id: $id) {
      id
      name
      description
      descriptionText
      contentType
      size
      lastUpdated
    }
  }
`;

const UPDATE_FILE_DESCRIPTION = gql`
  mutation UpdateFileDescription($input: UpdateFileInfoInput!) {
    updateFileInfo(input: $input) {
      id
      description
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFileMutation($id: ID!) {
    deleteFile(id: $id)
  }
`;

export class UpdateFileInfo extends Mutation<
  UpdateFileDescriptionMutation,
  UpdateFileDescriptionVariables
> {}

export class FileInfoCardComp extends React.PureComponent<
  IFileInfoCardProps & InjectedIntlProps,
  IFileInfoCardState
> {
  private readonly containerRef: RefObject<any>;

  constructor(props: IFileInfoCardProps & InjectedIntlProps) {
    super(props);

    this.containerRef = React.createRef();

    this.state = {
      description: '',
      hasChanged: false,
    };
  }

  onEditorChange = (data: any, type: string) => {
    this.setState({
      description: data,
    });
  };

  onSave = async (id: string) => {
    const result = await myApolloClient
      .mutate({
        mutation: UPDATE_FILE_DESCRIPTION,
        variables: {
          input: {
            id: id,
            description: this.state.description,
            descriptionText: convertFromRaw(
              JSON.parse(this.state.description)
            ).getPlainText(),
          },
        },
      })
      .then(result => {
        console.log('Result: ', result);
        safeInvoke(this.props.onSaveSuccess);
      })
      .catch(error => {
        console.log('Error: ', error);
      });
  };

  onDelete = async (id: string) => {
    console.log('deleting....');

    // const result = await myApolloClient.mutate({
    //   mutation: DELETE_FILE,
    //   variables: {
    //     id: id,
    //   }
    // })
    // .then(result => { console.log('Result: ', result) })
    // .catch(error => { console.log('Error: ', error) });

    this.props.onDelete();
  };

  render() {
    const { fileid, style, onCancel, canDelete, canEdit, intl } = this.props;
    console.log('FILE', fileid);
    return (
      <Query query={FILE_INFO_QUERY} variables={{ id: fileid }}>
        {({ loading, error, data }) => {
          if (loading) {
            console.log('loading...');
            return 'Loading...';
          }

          if (error) {
            console.log('QUERYERROR: ' + error.message);
            return 'Error...';
          }

          let content = data.fileInfo;
          let fileType = data.fileInfo.contentType;

          return (
            <UpdateFileInfo mutation={UPDATE_FILE_DESCRIPTION}>
              {(
                {},
                { data: uData, loading: uLoading, called, error: uError }
              ) => {
                return (
                  <Container ref={this.containerRef} style={style}>
                    {/*A message that appears if you open a file in an instance, but it is actually connected to the event*/}

                    {this.props.isCalendarEventForm &&
                      !canDelete &&
                      this.props.hasWriteAccessButIsEventFile && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: Colors.YELLOW,
                            fontSize: '14px',
                            marginTop: '-2em',
                            marginBottom: '1em',
                            marginLeft: '-2em',
                          }}
                        >
                          <MunikumIcons.About style={{ marginRight: '.2em' }} />
                          {intl.formatMessage(
                            messages.fileBelongsToEventWarning
                          )}
                        </div>
                      )}
                    <FileInfoTitle>
                      {intl.formatMessage(messages.fileInfo)}
                    </FileInfoTitle>

                    <FileInfoMetaBox>
                      <FileInfoToolBox>
                        <FileInfoFileTypeBox>
                          <FileIconType type={fileType} />
                        </FileInfoFileTypeBox>
                        <FileInfoFileNameBox>
                          {content.name}
                        </FileInfoFileNameBox>
                        <div>
                          <Button
                            text={intl.formatMessage(messages.openFile)}
                            style={{ marginRight: '.5em' }}
                            theme={ColorTheme.Red}
                            leftIcon={MunikumIcons.ArrowRight}
                            onClick={() => {
                              doDownloadFile(content.id);
                            }}
                          />
                        </div>
                      </FileInfoToolBox>
                      <FileInfoMetaData>
                        <tbody>
                          <tr>
                            <td>{intl.formatMessage(messages.fileSize)}</td>
                            <td>{humanFileSize(content.size, true)}</td>
                          </tr>
                          <tr>
                            <td>
                              {intl.formatMessage(messages.fileLastUpdated)}
                            </td>
                            <td>
                              {moment
                                .utc(content.lastUpdated)
                                .local()
                                .format('DD.MM.YYYY hh:mm')}
                            </td>
                          </tr>
                        </tbody>
                      </FileInfoMetaData>
                    </FileInfoMetaBox>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <EditorTop>
                        <div style={{ fontWeight: 'bold' }}>
                          {intl.formatMessage(commonMessages.description)}
                        </div>
                        <div />
                      </EditorTop>
                      <SimpleEditor
                        border={true}
                        content={content.description}
                        readOnly={!canEdit}
                        onChange={data => {
                          this.onEditorChange(data, 'description');
                        }}
                        onChangedFromOriginal={on => {
                          this.setState({
                            hasChanged: on,
                          });
                        }}
                      />
                    </div>

                    <ButtonsDiv>
                      {canDelete && (
                        <ButtonDeleteDiv>
                          <Button
                            text={intl.formatMessage(messages.deleteFile)}
                            theme={ColorTheme.White}
                            leftIcon={MunikumIcons.Delete}
                            onClick={() => {
                              safeInvoke(this.onDelete, fileid);
                            }}
                          />
                        </ButtonDeleteDiv>
                      )}
                      <ButtonNormalDiv>
                        {canEdit && (
                          <Button
                            text={intl.formatMessage(commonMessages.save)}
                            style={{ marginRight: '.5em' }}
                            theme={ColorTheme.Red}
                            leftIcon={MunikumIcons.Save}
                            disabled={!this.state.hasChanged}
                            onClick={() => {
                              safeInvoke(this.onSave, fileid);
                            }}
                          />
                        )}
                        <Button
                          text={intl.formatMessage(commonMessages.close)}
                          theme={ColorTheme.White}
                          leftIcon={MunikumIcons.Cancel}
                          onClick={() => {
                            safeInvoke(onCancel, false);
                          }}
                        />
                      </ButtonNormalDiv>
                    </ButtonsDiv>
                  </Container>
                );
              }}
            </UpdateFileInfo>
          );
        }}
      </Query>
    );
  }
}

export const FileInfoCard = injectIntl(FileInfoCardComp);
