import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { IconType } from 'react-icons/lib';
import { isFunction } from 'util';
import MediaQuery from 'react-responsive';
import { Colors, safeInvokeDeprecated } from '../../components/common';
import { MunikumIcons } from '../../components/common/icons';
import { IContentMeta } from '../../services/models/contentMeta';
import { sizes } from '../../components/common/media';
import { AuthQuery } from '../../auth/AuthQuery';
import {
  authFuncCalendar,
  authFuncDiscussion,
  authFuncIsAdmin,
  authFuncTopic,
} from '../../auth';

export interface IMainMenuProps {
  userHash?: string;
  forums?: ReadonlyArray<IContentMeta>;
  style?: CSSProperties;
}

export interface IMainMenuState {
  isMobileExpanded: boolean;
}

const MainMenuDiv = styled.div`
  background-color: ${Colors.BLACK};
`;

const SidebarMenuMobile = styled.div`
  font-family: Lato, sans-serif;

  @media (max-width: ${sizes.ipad}px) {
    max-height: ${(props: { isMobileOpen: boolean }) =>
      props.isMobileOpen ? '725px' : '0px'};
    transition: max-height 0.25s ease-in;
    overflow: hidden;
  }
`;

const SidebarMenuDesktop = styled.div`
  font-family: Lato, sans-serif;
`;

interface INavUl {
  level: number;
}

const NavUl = styled.ul`
  list-style: none;
  font-size: ${(props: INavUl) => 1 - props.level * 0.2}em;
  padding-top: ${(props: INavUl) => (props.level > 0 ? 0.5 : 0)}em;
  padding-left: ${(props: INavUl) =>
    (props.level === 0 ? 2.5 : 0.2) + props.level * 0.8}em;
`;

const NavLi = styled.li`
  padding-top: ${(props: INavUl) => (props.level === 0 ? 0.625 : 0.35)}em;
  padding-bottom: ${(props: INavUl) => (props.level === 0 ? 0.625 : 0.35)}em;
`;

const NavSubUl = styled.ul`
  list-style: none;
  padding-left: 1em; // 15px;
  font-size: 0.875em;
`;

const NavSubLi = styled.li`
  padding-top: 0.35em; // 5px;
  padding-bottom: 0.35em; // 5px;
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`;

const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const MobileMenuButtonContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  padding-left: 1.5em;
  padding-bottom: 0.5em;

  @media (min-width: ${sizes.ipad}px) {
    display: none;
  }
`;

const ButtContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

const Circle = styled.div`
  cursor: pointer;
  border-radius: 50%;
  background-color: ${Colors.RED};
  width: 2em;
  height: 2em;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Mobtext = styled.div`
  cursor: pointer;
  font-size: 1em;
  color: white;
  margin-left: 0.5em;
  border-bottom: 1px solid transparent;

  transition: border 0.25s ease-in-out;

  &:hover {
    border-bottom: 1px solid ${Colors.RED};
  }
`;

type IMenuItemRenderFunc = (props: {}) => JSX.Element | null | false;

interface IMenuItemProps {
  /**
   * url to navigate to on click
   */
  url: string;

  /**
   * Icon from react-icons library, see MunikumIcons enum!
   */
  icon?: IconType;

  /**
   * text to render or intl message
   */
  content: string | IMenuItemRenderFunc;

  /**
   * we could do this different with recursion or HOCs..
   */
  level: number;

  targetBlank?: boolean;

  onClick?: () => void;
}

interface IMenuItemState {
  isHover: boolean;
}

// const MenuItem: React.SFC<IMenuItemProps> = (props: IMenuItemProps) => {
class MenuItem extends React.PureComponent<IMenuItemProps, IMenuItemState> {
  constructor(props: IMenuItemProps) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  handleMouse = (isHover: boolean) => {
    this.setState({
      isHover: isHover,
    });
  };

  render() {
    const NavIcon = this.props.icon;
    const { content, url, level, targetBlank } = this.props;
    const { isHover } = this.state;

    return (
      <NavLi
        level={level}
        onClick={(e: any) => safeInvokeDeprecated(this.props.onClick)}
      >
        <NavLink
          to={url}
          target={(targetBlank && '_blank') || ''}
          onMouseEnter={() => this.handleMouse(true)}
          onMouseLeave={() => this.handleMouse(false)}
        >
          <MenuItemContainer>
            {NavIcon && (
              <NavIcon
                size={'1.25em'}
                style={{
                  marginRight: '.5em',
                  transition: 'fill .25s ease-in-out',
                }}
                fill={isHover ? Colors.RED : Colors.WHITE}
              />
            )}
            {typeof content !== 'string' && isFunction(content) && content({})}
            {typeof content === 'string' && content}
          </MenuItemContainer>
        </NavLink>
        {this.props.children && (
          <NavUl level={level + 1}>{this.props.children}</NavUl>
        )}
      </NavLi>
    );
  }
}

class MainMenuComp extends React.PureComponent<
  IMainMenuProps & InjectedIntlProps,
  IMainMenuState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      isMobileExpanded: false,
    };
  }

  handleTest = () => {
    // console.log('hnadling it');
    this.setState({
      isMobileExpanded: !this.state.isMobileExpanded,
    });
  };

  render() {
    const { style } = this.props;

    const mainMenu = (
      <NavUl level={0}>
        <MenuItem
          icon={MunikumIcons.Home}
          url={'/home'}
          content={() => (
            <FormattedMessage id={'MainLayout.home'} defaultMessage={'Home'} />
          )}
          level={0}
        />

        <AuthQuery authFunc={authFuncCalendar}>
          <MenuItem
            icon={MunikumIcons.YearWheel}
            url={'/annual-cycle/'}
            content={() => (
              <FormattedMessage
                id={'MainLayout.annualCycle'}
                defaultMessage={'Annual Cycle'}
              />
            )}
            level={0}
          />
        </AuthQuery>

        <AuthQuery authFunc={authFuncDiscussion}>
          <MenuItem
            icon={MunikumIcons.Discussion}
            url={'/forum'}
            content={() => (
              <FormattedMessage
                id={'MainLayout.forumLink'}
                defaultMessage={'Forum'}
              />
            )}
            level={0}
          />
        </AuthQuery>

        {/*<AuthQuery authFunc={authFuncActionValue}>*/}
        {/*<MenuItem*/}
        {/*icon={MunikumIcons.ActionValue}*/}
        {/*url={'/action-value'}*/}
        {/*content={() => (*/}
        {/*<FormattedMessage*/}
        {/*id={'MainLayout.actionValueLink'}*/}
        {/*defaultMessage={'Grants'}*/}
        {/*/>*/}
        {/*)}*/}
        {/*level={0}*/}
        {/*/>*/}
        {/*</AuthQuery>*/}

        <AuthQuery authFunc={authFuncTopic}>
          <MenuItem
            icon={MunikumIcons.Topic}
            url={'/topic'}
            content={() => (
              <FormattedMessage
                id={'MainLayout.topicLink'}
                defaultMessage={'Groups '}
              />
            )}
            level={0}
          />
        </AuthQuery>

        <MenuItem
          icon={MunikumIcons.About}
          url={'/about'}
          content={() => (
            <FormattedMessage
              id={'MainLayout.aboutLink'}
              defaultMessage={'About Årshjul.no'}
            />
          )}
          level={0}
        />
        <MenuItem
          icon={MunikumIcons.Help}
          url={'/help'}
          targetBlank={true}
          content={() => (
            <FormattedMessage
              id={'MainLayout.helpLink'}
              defaultMessage={'Help'}
            />
          )}
          level={0}
        />
      </NavUl>
    );
    const adminMenu = (
      <NavUl style={{ marginTop: '1.25em' }} level={0}>
        {/*<MenuItem url={'/admin'} content={'Admin'} level={0} icon={MunikumIcons.Admin}>*/}
        {/*<MenuItem level={1} url={'/admin/action-value'} content={() => (*/}
        {/*<FormattedMessage*/}
        {/*id={'MainLayout.actionValueLink'}*/}
        {/*defaultMessage={'Grants'}*/}
        {/*/>*/}
        {/*)} >*/}
        {/*<MenuItem url={'/admin/action-value/create'} content={() => (*/}
        {/*<FormattedMessage*/}
        {/*id={'MainLayout.createActionValueLink'}*/}
        {/*defaultMessage={'- Create new'}*/}
        {/*/>*/}
        {/*)} level={2} />*/}
        {/*</MenuItem>*/}
        {/*</MenuItem>*/}

        <NavLi level={0}>
          <NavLink to="/admin">Admin</NavLink>
          <NavSubUl>
            <NavSubLi>
              <NavLink to="/admin/action-value">
                <FormattedMessage
                  id={'MainLayout.actionValueLink'}
                  defaultMessage={'Grants'}
                />
              </NavLink>
            </NavSubLi>
            <NavSubLi>
              <NavLink to="/admin/newRelation">
                <FormattedMessage
                  id={'MainLayout.newRelation'}
                  defaultMessage={'new Relation'}
                />
              </NavLink>
            </NavSubLi>
            <NavSubUl>
              <NavSubLi>
                <NavLink to="/admin/action-value/create">
                  <FormattedMessage
                    id={'MainLayout.createActionValueLink'}
                    defaultMessage={'- Create new'}
                  />
                </NavLink>
              </NavSubLi>
            </NavSubUl>
            <NavSubLi>
              <NavLink to="/admin/person">
                <FormattedMessage
                  id={'MainLayout.personLink'}
                  defaultMessage={'Users'}
                />
              </NavLink>
            </NavSubLi>
          </NavSubUl>
        </NavLi>
      </NavUl>
    );
    const labsMenu = (
      <NavUl level={0} style={{ marginTop: '1.25em' }}>
        <MenuItem
          level={0}
          url={'/labs'}
          icon={MunikumIcons.Labs}
          content={'Munikum Labs'}
        >
          <MenuItem level={1} url={'/labs/gql1'} content={'Gql1'} />
          <MenuItem level={1} url={'/labs/debug'} content={'Testing'} />
          <MenuItem level={1} url={'/labs/responsive'} content={'Responsive'} />

          <MenuItem level={1} url={'/labs/network'} content={'Network'} />
          <MenuItem level={1} url={'/admin/meta'} content={'Meta'} />
          <MenuItem level={1} url={'/labs/invite'} content={'Invite'} />
          <MenuItem
            level={1}
            url={'/labs/notification'}
            content={'Notification'}
          />
          <MenuItem level={1} url={'/labs/superadmin'} content={'Superadmin'} />
          <MenuItem level={1} url={'/labs/search'} content={'Search'} />
          <MenuItem
            level={1}
            url={'/labs/fileupload'}
            icon={MunikumIcons.FileUpload}
            content={'File Upload'}
          />
          <MenuItem level={1} url={'/labs/mueditor'} content={'MuEditor'} />
        </MenuItem>
      </NavUl>
    );

    return (
      <>
        <MediaQuery query={'(max-width: ' + sizes.ipad + 'px)'}>
          <MainMenuDiv style={style}>
            <MobileMenuButtonContainer>
              <ButtContainer
                onClick={() => {
                  this.setState({
                    isMobileExpanded: !this.state.isMobileExpanded,
                  });
                  // console.log('hello');
                }}
              >
                <Circle>
                  {this.state.isMobileExpanded && (
                    <MunikumIcons.ArrowDown size={32} fill={'white'} />
                  )}
                  {!this.state.isMobileExpanded && (
                    <MunikumIcons.ArrowRight size={32} fill={'white'} />
                  )}
                </Circle>
                <Mobtext>
                  {this.state.isMobileExpanded ? 'Hide menu' : 'Show menu'}
                </Mobtext>
              </ButtContainer>
            </MobileMenuButtonContainer>

            <SidebarMenuMobile isMobileOpen={this.state.isMobileExpanded}>
              {mainMenu}
            </SidebarMenuMobile>
          </MainMenuDiv>
        </MediaQuery>
        <MediaQuery query={'(min-width: ' + sizes.ipad + 'px)'}>
          <MainMenuDiv style={style}>
            <SidebarMenuDesktop>
              {mainMenu}
              <AuthQuery authFunc={authFuncIsAdmin}>
                {adminMenu}
                {labsMenu}
              </AuthQuery>
            </SidebarMenuDesktop>
          </MainMenuDiv>
        </MediaQuery>
      </>
    );
  }
}

export const MainMenu = injectIntl(MainMenuComp);
