import { getApiRequest, parsedResponse } from './api';
import { IContentMeta } from './models/contentMeta';

export interface ISearchForm {
  query: string;
}

export interface ISearchResponseActionValue {
  id: string;
  title: string;
  description: string;
  uri: string;
}

export interface ISearchResponseDiscussion {
  id: string;
  title: string;
  body: string;
  uri: string;
}

export interface ISearchResponse {
  actionValues: {
    total: number;
    hits: Array<ISearchResponseActionValue>;
  };
  discussions: {
    total: number;
    hits: Array<ISearchResponseDiscussion>;
  };
}

export interface ISearchResponseV2 {
  id: number;
  title: string;
  description: string;
  uri: string;
}

export interface IShareForm {
  meta: string;
  email: string;
  description: string;
}

export async function getAdminMetaList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<IContentMeta>> {
  if (params) {
    return await parsedResponse<ReadonlyArray<IContentMeta>>(
      await getApiRequest('/meta/list-admin', 'POST', params)
    );
  }
  const response = await getApiRequest('/meta/list-admin', 'GET');
  return await parsedResponse<ReadonlyArray<IContentMeta>>(response);
}

export async function search(form: ISearchForm): Promise<ISearchResponse> {
  const response = await getApiRequest('/search', 'POST', {
    ...form,
  });
  return await parsedResponse<ISearchResponse>(response);
}

export async function searchV2(form: ISearchForm): Promise<ISearchResponse> {
  // const response = await getApiRequest('/search/action-value-v2/', 'POST', {
  //   ...form,
  // });
  const response = await getApiRequest(
    '/search/action-value-v2/' + form.query,
    'GET'
  );
  return await parsedResponse<ISearchResponse>(response);
}

export async function share(form: IShareForm) {
  const response = await getApiRequest('/meta/share', 'POST', {
    ...form,
  });
  return response;
}
export function share2(form: IShareForm, callback: () => void) {
  console.log('share2');
  setTimeout(() => {
    console.log('min test');
    callback();
  }, 1000 * 3);
}

export function share3(form: IShareForm): Promise<string> {
  const p = new Promise<string>((resolve, reject) => {
    try {
      const promise = share(form);
      promise.then((response: Response) => {
        resolve('true' + response.statusText);
      });
    } catch (e) {
      reject();
    }
  });
  return p;
}
