export default `
### Munikum® - Terms of Use

Munikum AS, by the general manager, is responsible for the processing of personal information that is registered about you.
The terms of use contain information you are entitled to when collecting information from our website (section 19 of the Personal Data Act),
general information on how we process personal information (section 18 of the Personal Data Act), as well as definitions of responsibility related to the contents of Munikum®.

#### Privacy

**About personal information**  
Personal information is all information and assessments that are associated with you as an individual.
The personal information we register using the Munikum® service is information that you yourself register on munikum.no,
such as name, municipality name and function in the municipality, information about your activity on the website, which browser you use
and address area for the computer network you are working on (IP address).

**Processing of personal information at munikum.no**  
The project manager for digitization has the daily responsibility for Munikum AS's processing of personal data at munikum.no.
The treatment basis is the consent of the individual.

We use Amazon Web Services and the Matomo analysis tool on our site and have data processing agreements with them.
The data processing agreements ensure that these tools only process personal data as agreed with the processing manager, Munikum AS.
Information from these tools is not provided by Munikum AS to others.
Please contact us if you would like more information or access to the data processing agreements.

**Online statistics**  
Munikum AS collects information about visitors at munikum.no.
The purpose of this is to produce statistics that we use to improve and further develop the service.
Examples of what the statistics answer are how many people visit different pages, how long the visit lasts, which sites the users come from
and which browsers are used.

**Cookie Use**  
Cookies are small text files that are placed on your computer when you download a website.
Storing information and processing it is not permitted unless the user has been informed and has given his consent to the processing.
The Matomo Analysis Tool places cookies on your computer. This is done so that we can collect statistics,
and deleted after 90 days if you have not been active on the page.

**User profile in Munikum®**  
Your user profile with name, municipality name and which function you have in the municipality will be visible to other users of the website.
You can change or delete all your registered personal information at munikum.no at any time.

**Rights** 
Everyone who asks has the right to basic information about the processing of personal data in an enterprise pursuant to section 18, first paragraph, of the Personal Data Act.
Munikum AS has provided this information in these terms of use, and will refer to it at any request.
Those who are registered at munikum.no are entitled to access their own information.
The person in question also has the right to request that incorrect or missing information be corrected, deleted or supplemented.

You may at any time withdraw your consent wholly or in part to the processing of your personal data.
Registered personal information about you at Munikum AS will then be deleted.
Your personal information will be removed from all posts, documents and other files that you alone, or in collaboration with others, have produced in Munikum®.

If you move to a new position or for any other reason no longer continue in your function in the municipality, data (posts, document and other)
which you have produced and shared in Munikum® is continued to the person who takes over your function.
In this way, knowledge and work that is shared and obtained through Munikum® for a function and service area will be available to the municipality
even if users change or terminate their employment conditions.

### Munikum® - Limitation of liability

All users of Munikum® are required to have read and accepted the following information:

It is the users of Munikum® who are responsible for the content of posts and others that are produced and shared by users.
It is also the users who are responsible for ensuring that what is shared complies with relevant laws, regulations etc.
Munikum AS is thus not responsible for the content of Munikum® which is produced and shared by the users.

Some links on the website go to web sites managed by companies or organizations that cannot be
controlled by Munikum AS. Munikum AS is not responsible for these links or its external web sites
content or functionality.

All use of information in Munikum®, for example, as the basis for decision making for investments or other, happens
at your own risk. Munikum AS does not bare  responsibility for the result that users, in different ways, uses
information shared in Munikum®. Thus, Munikum AS cannot remain liable if the information shared in Munikum®
leads to direct or indirect damage or loss, or no income gain for the user / municipality or
others who have participated in the content poblished on Munikum®.

Munikum AS reserves the right to monitor and possibly remove content on munikum.no at its own discretion. Munikum AS also has the right
to cancel or otherwise limit the use of Munikum®. The user must be aware that Munikum AS does not
review posts on munikum.no before it is published, or is in anny way responsible for the content.

#### Contact information

Please contact us if you have any questions.

Email: support@munikum.no

Phone: 919 04 691

Postal address: Bankgata 1, 6770 Nordfjordeid

`;