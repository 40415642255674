import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import {
  Colors,
  ColorTheme,
  getThemeProps,
  IThemeProps,
  removeNonHTMLProps,
  safeInvoke,
  safeInvokeDeprecated,
} from '../../common';
import { ButtonSize } from '..';
import { MunikumIcons } from '../../common/icons';
import { Tooltip } from '../Tooltip/Tooltip';

interface ITagSpanRenderProps {
  uppercase?: boolean;
  color?: string;
  style?: CSSProperties;
  hasLeftIcon?: boolean;
  canClose?: boolean;
  hasShadow: boolean;
}

export interface ITagProps {
  text: string;
  color: string;
  uppercase?: boolean;
  canClose?: boolean;
  hasShadow: boolean;
  styleText?: CSSProperties;
  toolTipMsgLeftIcon?: string;
  tooltip?: boolean;
  leftIcon?: IconType;

  onClick?: (e: Event) => void;
  onClickLeftIcon?: () => void;
  onClickClose?: (e: any) => void;

  theme?: ColorTheme;
}

const TextDiv = styled.div`
  margin-right: ${(props: ITagSpanRenderProps) =>
    props.canClose ? '0' : '10px'};

  margin-left: ${(props: ITagSpanRenderProps) =>
    props.hasLeftIcon ? '0' : '5px'};
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const CloseDiv = styled.div`
  display: ${(props: ITagSpanRenderProps) => (props.canClose ? '0' : 'flex')};
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-in-out;

  :hover {
    transform: scale(1.3);
  }
`;

const IconDiv = styled.div`
  display: ${(props: ITagSpanRenderProps) => (props.canClose ? '0' : 'flex')};
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  :hover {
    transform: scale(1.3);
  }
`;
const TagSpan = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 4px;
  box-shadow: none;

  // no zoom/resize ok? do we want to use labels as inline elements inside text?
  min-width: 20px;

  //overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 8px;
  line-height: 16px;
  font-size: 14px;
  letter-spacing: 0.5px;

  //line-height: 1em;
  //font-size: .625em;
  //min-width: 1.250em;
  //padding: .125em 1em;

  font-family: 'Lato', sans-serif;
  font-weight: 500;

  text-transform: ${(props: ITagSpanRenderProps) =>
    props.uppercase !== undefined
      ? props.uppercase
        ? 'uppercase'
        : ''
      : 'uppercase'};

  text-align: center;

  background-color: ${(props: ITagSpanRenderProps) =>
    props.color ? props.color : Colors.BLUE};
  color: ${Colors.WHITE};

  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;

  :hover {
    cursor: pointer;
    box-shadow: ${(props: ITagSpanRenderProps) =>
      props.hasShadow ? ' 0 2px 7px 0 rgba(0, 0, 0, 0.3)' : ''};
  }
`;

/**
 * Tag
 */
export class Tag extends React.PureComponent<
  ITagProps & React.HTMLProps<HTMLDivElement>,
  {}
> {
  renderIcon(
    iconComponent: IconType,
    themeProps: IThemeProps,
    isDisabled: boolean
  ) {
    if (iconComponent === undefined || iconComponent === null) {
      return null;
    }

    const Icon = iconComponent;

    // width: ${(props: IIconRenderProps) => props.size === ButtonSize.Small ? '.75em' : props.size === ButtonSize.Normal ? '1em' : '1.25em'}; // 1.2em;
    // height: ${(props: IIconRenderProps) => props.size === ButtonSize.Small ? '.75em' : props.size === ButtonSize.Normal ? '1em' : '1.25em'}; // 1.2em;

    const small = '.75em';
    const normal = '1em';
    const large = '1.25em';
    const x =
      this.props.size === ButtonSize.Small
        ? small
        : this.props.size === ButtonSize.Medium
        ? normal
        : large;

    return (
      <Icon
        fill={isDisabled ? Colors.GREYDISABLEDTEXT : themeProps.textColor}
        width={x}
        height={x}
        style={{
          transition: 'fill .25s ease-in-out, color .25s ease-in-out',
        }}
      />
    );
  }

  handleClickLeftIcon = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    safeInvokeDeprecated(this.props.onClickLeftIcon);
  };

  handleClickClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    safeInvokeDeprecated(this.props.onClickClose);
  };

  render() {
    const {
      color,
      text,
      leftIcon,
      canClose,
      theme,
      hasShadow,
      style,
      styleText,
      uppercase,
      disabled,
      onClickClose,
      onClickLeftIcon,
      ...rest
    } = this.props;
    const myTheme = theme !== undefined ? theme : ColorTheme.Red;
    const myThemeProps = getThemeProps(myTheme);
    const close = <MunikumIcons.Cancel />;

    if (text === '') {
      return null;
    }

    return (
      <TagSpan
        onClick={(e: any) => safeInvoke(this.props.onClick, e)}
        style={style}
        color={color}
        hasLeftIcon={leftIcon !== undefined && leftIcon !== null}
        canClose={this.props.canClose}
        hasShadow={this.props.hasShadow}
        uppercase={uppercase}
        {...removeNonHTMLProps(rest)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.tooltip && (
            <Tooltip position={'top'} content={this.props.toolTipMsgLeftIcon}>
              <div>
                {leftIcon && (
                  <IconDiv hasShadow={false} onClick={this.handleClickLeftIcon}>
                    {this.renderIcon(leftIcon, myThemeProps, disabled || false)}
                  </IconDiv>
                )}
              </div>
            </Tooltip>
          )}
          {!this.props.tooltip && leftIcon && (
            <IconDiv hasShadow={false} onClick={this.handleClickLeftIcon}>
              {this.renderIcon(leftIcon, myThemeProps, disabled || false)}
            </IconDiv>
          )}

          <TextDiv style={{ ...styleText! }} hasShadow={false}>
            {text}
          </TextDiv>
          {canClose && (
            <CloseDiv hasShadow={false} onClick={this.handleClickClose}>
              {close}
            </CloseDiv>
          )}
        </div>
      </TagSpan>
    );
  }
}
