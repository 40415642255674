import { defineMessages, InjectedIntl } from 'react-intl';
import { IContentData } from './contentData';
import { getNamesAndValues } from '../../components/common';

export enum radarType {
  EFFORT = 0,
  GAIN = 1,
}

export interface IActionValueRadarGraph {
  sum: number;
  name: string;
  type: radarType;
  comment: string;
}

export interface IActionValueParameterValue {
  // id to parameter object in db
  parameter: string;

  // id to option in db
  option: string;

  comment?: string;
}

export interface IActionValue extends IContentData {
  category: number;

  kostraFunctions?: ReadonlyArray<string>;

  watchStatus?: boolean;

  /**
   * this is data returned from API that is easy to read
   */
  radarGraph?: ReadonlyArray<IActionValueRadarGraph>;

  /**
   * parameters we can send back to API
   */
  parameters?: ReadonlyArray<IActionValueParameterValue>;
  limits?: ReadonlyArray<IActionValueLimit>;
  gains?: ReadonlyArray<IActionValueGain>;
  cards?: ReadonlyArray<IActionValueCard>;

  /**
   * source URL
   */
  sourceUrl?: string;

  userFiles: ReadonlyArray<string>;
}

export interface IActionValueLimit {
  attribute: number;
  secondAttribute?: number;
  firstLimitValue: number;
  secondLimitValue?: number;
  limitType: ActionValueLimitType;
  limitOption: ActionValueLimitOption;
}

export interface IActionValueGain {
  attribute: number;
  gainMultiplier: number;
  accountingAttribute?: number;
}

export interface IActionValueCard {
  step: number;
  text?: string;
  identifier: string;
}

export interface IHttpLink {
  label: string;
  href: string;
}

// export enum ActionValueType {
//   SHORT_TERM = 0,
//   MIDDLE_TERM = 1,
//   LONG_TERM = 2,
//   YEARLY = 3,
// }

export const actionValueTypeMessages = defineMessages({
  SHORT_TERM: {
    id: 'actionValueType.SHORT_TERM',
    defaultMessage: 'Short term',
  },
  MIDDLE_TERM: {
    id: 'actionValueType.MIDDLE_TERM',
    defaultMessage: 'Middle term',
  },
  LONG_TERM: {
    id: 'actionValueType.LONG_TERM',
    defaultMessage: 'Long term',
  },
  YEARLY: {
    id: 'actionValueType.YEARLY',
    defaultMessage: 'Yearly',
  },
});

/**
 * Use this to get a translated list of types to be used in dropdowns etc.
 * @param {ReactIntl.InjectedIntl} intl
 * @returns {{[p: string]: string}}
 */
// export function getTranslatedActionValueTypes(intl: InjectedIntl) {
//   const temp: { [id: string]: string } = {};
//   EnumEx.getNamesAndValues(ActionValueType).map(x => {
//     temp[x.value] = intl.formatMessage(actionValueTypeMessages[x.name]);
//   });

//   return temp;
// }

// export const actionValueTypeDict = getNamesAndValues(ActionValueType);

export enum ActionValueCategory {
  OPERATIONAL = 0,
  INVESTMENT = 1,
}

export const actionValueCategoryDict = getNamesAndValues(ActionValueCategory);

export const actionValueCategoryMessages = defineMessages({
  OPERATIONAL: {
    id: 'ActionValueCategory.OPERATIONAL',
    defaultMessage: 'Operational',
  },
  INVESTMENT: {
    id: 'ActionValueCategory.INVESTMENT',
    defaultMessage: 'Investment',
  },
});

export function getTranslatedActionValueCategories(intl: InjectedIntl) {
  const temp: { [id: string]: string } = {};
  getNamesAndValues(ActionValueCategory).map(x => {
    temp[x.value] = intl.formatMessage(actionValueCategoryMessages[x.name]);
  });
  return temp;
}

export enum ActionValueLimitType {
  IS_ABOVE = 0,
  IS_BELOW = 1,
  IS_BETWEEN = 2,
}

export enum ActionValueLimitOption {
  CONSTANT = 0,
  VARIABLE = 1,
}
