import { call, put, take } from 'redux-saga/effects';
import {
  getActionValueMetasActionCreator,
  getActionValuesActionCreator,
  getDiscussionMetasActionCreator,
  getDiscussionsActionCreator,
  getFilesActionCreator,
} from '../entities/entities';
import {
  ICommentEditForm,
  ICommentSaveForm,
  saveComment,
  updateComment,
} from '../../services/commentService';
import { createUploadFileChannel } from '../file/file';
import { IApiError, wrapApiError } from '../../services/api';
import { IFileItem } from '../../components/basic/UserFile/FileUploaderPicker';
import { actionCreatorFactory } from '../actionCreatorFactory';

const actionCreator = actionCreatorFactory('COMMENT');

export const saveCommentActionCreator = actionCreator.async<
  { commentForm: ICommentSaveForm; files: IFileItem[] },
  {},
  IApiError
>('SAVE');

export const updateCommentActionCreator = actionCreator.async<
  ICommentEditForm,
  {},
  IApiError
>('UPDATE');

export function* saveCommentSaga() {
  while (true) {
    const action = yield take(saveCommentActionCreator.started.type);
    try {
      const tempMetaLimit = new Date();

      console.log('save comment saga');

      // 1. save comment. if great success; comment is returned as result
      const result = yield call(saveComment, action.payload.commentForm);

      console.log('Save comment result', result);

      // Save new files.
      if (action.payload.files && action.payload.files.length > 0) {
        for (let sFile of action.payload.files) {
          const channel = yield call(createUploadFileChannel, {
            file: sFile.file,
            metaid: result.id,
          });

          while (true) {
            const { success, err } = yield take(channel);
            if (err) {
              throw Error('File upload failed');
            }
            if (success) {
              break;
            }
          }
        }

        yield put(
          getFilesActionCreator.started({ lastUpdated: tempMetaLimit })
        );
      }

      // // // 2. alternative, just dispatch a get x..
      // switch (result.type) {
      //   case 'ACTION_VALUE':
      //     yield put(
      //       getActionValueActionCreator.started({ id: result.currentRevision })
      //     );
      //     yield put(
      //       getActionValueMetasActionCreator.started({
      //         lastUpdated: tempMetaLimit,
      //       })
      //     );
      //     break;
      //   case 'DISCUSSION':
      //     yield put(
      //       getDiscussionActionCreator.started({ id: result.currentRevision })
      //     );
      //     yield put(
      //       getDiscussionMetasActionCreator.started({
      //         lastUpdated: tempMetaLimit,
      //       })
      //     );
      //     break;
      //   case 'COMMENT':
      //     yield put(
      //       getDiscussionActionCreator.started({ id: result.currentRevision })
      //     );
      //     yield put(
      //       getDiscussionMetasActionCreator.started({
      //         lastUpdated: tempMetaLimit,
      //       })
      //     );
      //     break;
      //   default:
      //     break;
      // }

      // 2. mini-hack temporary solution:
      // we dont know if comment belongs to actionvalue or discussion. So we just fetch both :-P
      yield put(
        getActionValuesActionCreator.started({ lastUpdated: tempMetaLimit })
      );
      yield put(
        getActionValueMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );
      yield put(
        getDiscussionsActionCreator.started({ lastUpdated: tempMetaLimit })
      );
      yield put(
        getDiscussionMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      // 3. put save success action to update ui
      yield put(
        saveCommentActionCreator.success({
          params: action.payload,
          result: result,
        })
      );

      // // 4. navigate back
      // switch (result.type) {
      //   case 'ACTION_VALUE':
      //     yield put(push('/action-value/' + result.uri));
      //     break;
      //   case 'DISCUSSION':
      //     yield put(push('/forum/' + action.payload.forum + '/' + result.uri));
      //     break;
      //   default:
      //     break;
      // }
    } catch (e) {
      yield put(
        saveCommentActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export function* updateCommentSaga() {
  while (true) {
    const action = yield take(updateCommentActionCreator.started.type);
    try {
      const tempMetaLimit = new Date();

      console.log('update comment saga. TEST IF FILES WORK..');

      const filesHack = action.payload.files;

      delete action.payload.files; // :-(

      // 1. update comment. if great success; comment is returned as result
      const result = yield call(updateComment, action.payload);

      // Save new files.
      if (filesHack && filesHack.length > 0) {
        for (let sFile of action.payload.files) {
          const channel = yield call(createUploadFileChannel, {
            file: sFile.file,
            metaid: result.id,
          });

          while (true) {
            const { success, err } = yield take(channel);
            if (err) {
              throw Error('File upload failed');
            }
            if (success) {
              break;
            }
          }
        }

        yield put(
          getFilesActionCreator.started({ lastUpdated: tempMetaLimit })
        );
      }

      // 2. mini-hack temporary solution:
      // we dont know if comment belongs to actionvalue or discussion. So we just fetch both :-P
      yield put(
        getActionValuesActionCreator.started({ lastUpdated: tempMetaLimit })
      );
      yield put(
        getActionValueMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );
      yield put(
        getDiscussionsActionCreator.started({ lastUpdated: tempMetaLimit })
      );
      yield put(
        getDiscussionMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      // // // 2. alternative, just dispatch a get x..
      // switch (result.type) {
      //   case 'ACTION_VALUE':
      //     yield put(
      //       getActionValueActionCreator.started({ id: result.currentRevision })
      //     );
      //     yield put(
      //       getActionValueMetasActionCreator.started({
      //         lastUpdated: tempMetaLimit,
      //       })
      //     );
      //     break;
      //   case 'DISCUSSION':
      //     yield put(
      //       getDiscussionActionCreator.started({ id: result.currentRevision })
      //     );
      //     yield put(
      //       getDiscussionMetasActionCreator.started({
      //         lastUpdated: tempMetaLimit,
      //       })
      //     );
      //     break;
      //   case 'COMMENT':
      //     yield put(
      //       getDiscussionActionCreator.started({ id: result.currentRevision })
      //     );
      //     yield put(
      //       getDiscussionMetasActionCreator.started({
      //         lastUpdated: tempMetaLimit,
      //       })
      //     );
      //     break;
      //   default:
      //     break;
      // }

      // 3. put save success action to update ui
      yield put(
        updateCommentActionCreator.success({
          params: action.payload,
          result: result,
        })
      );

      // this wont work for both discussion and actionvalue? type, subcomments, etc.

      // // 4. navigate back
      // switch (result.type) {
      //   case 'ACTION_VALUE':
      //     yield put(push('/action-value/' + result.uri));
      //     break;
      //   case 'DISCUSSION':
      //     yield put(push('/forum/' + result.uri));
      //     break;
      //   default:
      //     break;
      // }
    } catch (e) {
      yield put(
        updateCommentActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}
