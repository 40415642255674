import * as React from 'react';
import * as _ from 'lodash';
import Query, { QueryResult } from 'react-apollo/Query';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import styled from 'styled-components';

import { FaDotCircle } from 'react-icons/fa';
import {
  IItemRenderProps,
  ITargetExtraInfo,
  IUltimateDropdownProps,
  UltimateDropdown,
} from '../../basic/DropDownSelectUltimate/UltimateDropdown';
import {
  AccessEnum,
  FieldsOnCalendarLookupFragment,
  GetMyFollowedCalendarsDropdownQuery,
  GetMyFollowedCalendarsDropdownVariables,
  PersonAccess,
} from '../../../models/types';
import { Colors, Omit, safeInvokeDeprecated } from '../../common';
import { TextField, ValidationSummary } from '../../basic';
import { MunikumIcons } from '../../common/icons';
import { FieldGroup } from '../../basic/FieldGroup/FieldGroup';
import { MenuItem } from '../../basic/Menu';
import { FormLabel } from '../../basic/FormLabel/FormLabel';
import { Pie } from '../Pie';
import { commonMessages } from '../../language/commonMessages';
import {
  IconButton,
  IconButtonHoverStyle,
} from '../../basic/IconButton/IconButton';
import { RefHandler } from 'react-popper';
import { TextSmall } from '../../basic/Structural/typography';
import gql from 'graphql-tag';
import { commonLabels } from '../../language/commonLabels';
import {
  ButtType,
  FollowButton2,
  MyType,
} from '../../basic/FollowButton/FollowButton2';
import { Tooltip } from '../../basic/Tooltip/Tooltip';
import { MuPreview } from '../../basic/MuEditor/MuPreview';

const messages = defineMessages({
  selectAnnualCycle: {
    id: 'CalendarsDropdown.selectAnnualCycle',
    defaultMessage: 'Select annual cycle',
  },
  showAssignedTasks: {
    id: 'AnnualCycle.showAssignedTasks',
    defaultMessage: 'Show Assigned Tasks',
  },
  myAssignedTasks: {
    id: 'AnnualCycle.myAssignedTasks',
    defaultMessage: 'My assigned tasks',
  },
  chooseYearWheel: {
    id: 'AnnualCycle.chooseYearWheel',
    defaultMessage: 'Choose yearwheel',
  },
  emptyText: {
    id: 'AnnualCycle.emptyText',
    defaultMessage: 'No items',
  },
});

// const CalDropdown = UltimateDropdown.ofType<FieldsOnCalendarLookup.Fragment>();
const CalDropdown = UltimateDropdown.ofType<FieldsOnCalendarLookupFragment>();

interface IRndrProps {
  isActive: boolean;
  isSelected: boolean;
}

const Title = styled.div`
  color: #333;
  font-size: 1em;
  font-family: Lato, sans-serif;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextAndImgContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  //width: 100%;
  //background-color: palegreen;
`;

const EditBox = styled.div`
  //display: block;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 0.5em;
  margin-right: 0.5em;

  //font-size: .75em;
`;

const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 40px;
  height: 40px;

  //background-color: palegoldenrod;
  margin-right: 0.5em;
  margin-left: 0.5em;

  border-radius: 3px;
  background-color: #fff;
  border: 1px solid
    ${(props: IRndrProps) => (props.isActive ? '#FFF' : Colors.BLUE)};
`;

const TargetContainer = styled.div`
  position: relative;
`;

export const MY_FOLLOWED_CALENDARS_DROPDOWN = gql`
  query GetMyFollowedCalendarsDropdown {
    me {
      id
      name
    }
    myFollowed(input: { excludeWrite: true }) {
      calendars {
        id
        title
        description
        color
        isFollowing
        createdBy {
          id
          name
        }
        access {
          persons {
            access
            person {
              id
              name
            }
          }
        }
      }
    }
  }
`;

class MyFollowedCalendars extends Query<
  GetMyFollowedCalendarsDropdownQuery,
  GetMyFollowedCalendarsDropdownVariables
> {}

interface ICalendarsDropdownCompleteProps
  extends IUltimateDropdownProps<FieldsOnCalendarLookupFragment> {}

type ICalendarsDropdownProps = Omit<
  ICalendarsDropdownCompleteProps,
  'items' | 'itemRenderFunc'
> & {
  onEdit?: (item: FieldsOnCalendarLookupFragment) => void;
  showYearWheels?: boolean;
};

/**
 * CalendarsDropdown
 * - set selectMode single / multiple
 * - items are loaded from graphql
 * - set selectedItems && handle onSelected etc.
 */
class MyFollowedCalendarsDropdownComp extends React.PureComponent<
  ICalendarsDropdownProps & InjectedIntlProps,
  { id: string }
> {
  public static defaultProps: ICalendarsDropdownProps = {
    showYearWheels: true,
  };
  constructor(props: any) {
    super(props);

    this.state = {
      id: 'caldal',
    };
  }

  myItemRenderFunc = (
    me: any,
    item: any,
    itemRenderProps: IItemRenderProps,
    opts: { showEditButton: boolean; handleClickEdit: () => void }
  ) => {
    let doIhaveAccess = false;

    if (item.createdBy.id === me.id) {
      doIhaveAccess = true;
    } else {
      item.access.persons.forEach((c: PersonAccess) => {
        if (c.person.id === me.id) {
          if (c.access === AccessEnum.WRITE) {
            doIhaveAccess = true;
          }
        }
      });
    }

    return (
      <MenuItem
        key={itemRenderProps.key}
        text={
          <Container>
            <TextAndImgContainer>
              {/*/>*/}
              <SvgContainer
                isActive={itemRenderProps.isActive}
                isSelected={itemRenderProps.isSelected}
              >
                <Pie color={item.color} />
              </SvgContainer>
              <div>
                <Title>{item.title}</Title>
                <TextSmall
                  style={{
                    maxWidth: '100px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxHeight: '20px',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <MuPreview rawMarkdown={item.description || ''} />
                </TextSmall>
              </div>
            </TextAndImgContainer>
            {opts.showEditButton && item.id !== '0' && (
              <EditBox>
                <Tooltip
                  position={'left'}
                  content={this.props.intl.formatMessage(
                    commonMessages.editYearWheelToolTip
                  )}
                >
                  <div>
                    <IconButton
                      icon={MunikumIcons.Edit}
                      style={{ fontSize: '.8em', marginRight: '.5em' }} // TODO: bug center icon...
                      hoverStyle={IconButtonHoverStyle.SQUARE}
                      onClick={() => {
                        opts.handleClickEdit();
                      }}
                    />
                  </div>
                </Tooltip>

                <FollowButton2
                  buttonType={ButtType.STAR}
                  followType={MyType.CALENDAR}
                  followId={item.id}
                  isFollowing={item.isFollowing}
                />
              </EditBox>
            )}
          </Container>
        }
        onClick={() => {
          if (this.props.disabled) {
            return;
          }

          itemRenderProps.handleClick();
        }}
        // hideIconContainers={itemRenderProps.selectMode === SelectMode.SINGLE}
        isSelected={itemRenderProps.isSelected}
        leftIcon={
          // itemRenderProps.selectMode === SelectMode.MULTIPLE &&
          itemRenderProps.isSelected ? MunikumIcons.LeftCheck : undefined
        }
      />
    );
  };

  myTargetRenderFunc = (
    refHandler: RefHandler,
    info: ITargetExtraInfo<FieldsOnCalendarLookupFragment>
  ) => {
    let tempText = '';
    const colors: Array<string> = [];

    if (info.selectedItems.length === 0) {
      tempText = this.props.intl.formatMessage(
        commonMessages.calendarPlaceholder
      );
    }

    info.selectedItems.forEach((item, i) => {
      tempText += item.title;
      colors.push(item.color);

      if (i < info.selectedItems.length - 1) {
        tempText += ', ';
      }
    });

    let myColor = Colors.BLACK50;
    if (colors.length === 1) {
      myColor = colors[0];
    } else if (colors.length > 1) {
      myColor = Colors.BLACK50;
    }

    return (
      <FieldGroup style={this.props.fieldGroupStyle}>
        {this.props.label && (
          <FormLabel htmlFor={this.state.id}>{this.props.label}</FormLabel>
        )}
        <TargetContainer style={this.props.style}>
          <TextField
            innerRef={refHandler}
            name={this.props.name || 'nan'}
            value={
              this.props.showYearWheels
                ? tempText
                : this.props.intl.formatMessage(commonLabels.myFollowed)
            }
            readOnly={true}
            disabled={this.props.disabled}
            onChange={() => {}}
            onBlur={this.props.onBlur}
            inputStyle={{
              cursor: this.props.disabled ? 'not-allowed' : 'pointer',
              color: 'transparent',
              textAlign: 'left', // sorry I moved it to the left ;-)
              textShadow: '0 0 0 ' + Colors.BLACK,
              fontSize: '14px',
              fontFamily: 'Lato, sans-serif',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            style={{
              width: '100%',
            }}
            leftIcon={MunikumIcons.YearWheel}
            leftIconColor={Colors.BLACK50}
            rightIcon={MunikumIcons.Dropdown}
            fieldGroupStyle={{
              padding: 0,
              cursor: this.props.disabled ? 'not-allowed' : 'pointer',
            }}
          />
          {/*<MunikumIcons.YearWheel*/}
          {/*fill={myColor}*/}
          {/*style={{*/}
          {/*position: 'absolute',*/}
          {/*left: '.5em',*/}
          {/*top: '.6em',*/}
          {/*}}*/}
          {/*/>*/}
        </TargetContainer>
        <ValidationSummary
          info={this.props.info}
          success={this.props.success}
          warning={this.props.warning}
          error={this.props.error}
        />
      </FieldGroup>
    );
  };

  render() {
    const { intl, onEdit, ...rest } = this.props;

    return (
      <MyFollowedCalendars
        query={MY_FOLLOWED_CALENDARS_DROPDOWN}
        fetchPolicy={'cache-and-network'}
        children={(
          queryResult: QueryResult<
            GetMyFollowedCalendarsDropdownQuery,
            GetMyFollowedCalendarsDropdownVariables
          >
        ) => {
          if (queryResult.error) {
            return <div>Error: {queryResult.error.message}</div>;
          }

          if (queryResult.loading) {
            return <div />;
          }

          const me = queryResult.data.me;

          let gqlitems =
            (queryResult.data && queryResult.data.myFollowed.calendars) || [];

          gqlitems = _.sortBy(gqlitems, 'order');

          return (
            <CalDropdown
              defaultText={intl.formatMessage(messages.selectAnnualCycle)}
              leftIcon={FaDotCircle}
              items={gqlitems}
              visibleRows={8}
              rowHeight={50}
              itemRenderFunc={(item, rp) =>
                this.myItemRenderFunc(me, item, rp, {
                  showEditButton: onEdit !== undefined,
                  handleClickEdit: () => {
                    safeInvokeDeprecated(this.props.onEdit, item);
                  },
                })
              }
              targetRenderFunc={this.myTargetRenderFunc}
              emptyText={intl.formatMessage(messages.emptyText)}
              {...rest}
            />
          );
        }}
      />
    );
  }
}

export const MyFollowedCalendarsDropdown = injectIntl(
  MyFollowedCalendarsDropdownComp
);
