import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import {
  ColorTheme,
  ILabelProps,
  safeInvoke,
  safeInvokeDeprecated,
} from '../../common';
import { WheelToggleMode } from '../../annualCycle/Wheel/Wheel';
import { FormattedMessage } from 'react-intl';

interface IRadioButtonProps {
  name: string;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  value: string;
  style?: CSSProperties;
  checked?: boolean;
  color?: any;
}

const Dot = styled.div`
  z-index: 1;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
`;

const Label = styled.label`
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ced0da;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  position: absolute;
  left: -3px;
  top: -1px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;

  height: 16px;
  width: 16px;

  &:checked + ${Label} {
    background-color: ${props => props.color || ColorTheme.Red};
  }
`;

const LabelContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 1.5px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

/***
 * TODO:
 * this component is only the circle right now... we should refactor, move label,
 * render prop/childrem etc. inside this component..
 */
export class RadioButton extends React.PureComponent<IRadioButtonProps> {
  handleChange = (e: any) => {
    safeInvoke(this.props.onChange, e);
  };

  render() {
    const { name, value, style } = this.props;
    return (
      <div style={style}>
        <div>
          <LabelContainer>
            <Input
              color={this.props.color || '#FF5C39'}
              checked={this.props.checked}
              name={name}
              id="input"
              type="radio"
              value={value}
              onChange={this.handleChange}
              onClick={e => safeInvoke(this.props.onClick, e)}
            />
            <Label>
              <Dot />{' '}
            </Label>
          </LabelContainer>
        </div>
      </div>
    );
  }
}

//
//
// const RadioElement = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//
//   padding-bottom: .25em;
// `;
//
// const RadioContent = styled.div`
//   padding-left: .5em;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//
//   cursor: pointer;
// `;
//
// /***
//  * A complete radioButton component with label
//  * @param props
//  * @constructor
//  */
// export const RadioButtonComplete: React.FunctionComponent<IRadioButtonProps & { label?: string | JSX.Element }> = props => {
//   const {label, onClick, ...rest} = props;
//
//   const [checked, setChecked] = useState(props.checked || false);
//
//   const handleClick = (e: any) => {}
//
//   return (
//     <RadioElement>
//       <RadioButton
//         onClick={e => safeInvoke(onClick, e)}
//         {...rest}
//       />
//       <RadioContent
//         onClick={e => safeInvoke(onClick, e)}
//       >
//         {label}
//       </RadioContent>
//     </RadioElement>
//   );
// };
