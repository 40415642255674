import styled from 'styled-components';
import { Colors } from '../../common';

export const Article = styled.article``;

export const H1 = styled.h1`
  color: ${Colors.BLACK};
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  line-height: 2.75em; // 44px;
  font-size: 2em; // 32px;
  margin: 0;
`;

export const H2 = styled.h2`
  color: ${Colors.BLACK};
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  line-height: 2em; // 32px;
  font-size: 1.375em; // 22px;
  margin: 0;
`;

export const H3 = styled.h3`
  color: ${Colors.BLACK};
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  line-height: 1.75em; // 28px;
  font-size: 1.25em; // 20px;
  margin: 0;
`;

export const H4 = styled.h4`
  color: ${Colors.BLACK};
  font-family: 'Lato', sans-serif;
  font-weight: normal;
  line-height: 1.5em; // 28px;
  font-size: 1.15em; // 20px;
  margin: 0;
`;

export const TextNormal = styled.div`
  color: ${props => (props.color ? props.color : Colors.BLACK)};
  font-family: 'Lato', sans-serif;
  font-size: 1em; // 16px
  line-height: 1.5em; // 24px
`;

export const TextSmall = styled.div`
  color: ${props => (props.color ? props.color : Colors.BLACK70)};
  font-family: 'Lato', sans-serif;
  font-size: 0.875em; // 14px
  line-height: 1.375em; // 22px
`;

export const TextVerySmall = styled.div`
  color: ${props => (props.color ? props.color : Colors.BLACK70)};
  font-family: 'Lato', sans-serif;
  font-size: 0.75em; // 12px
  line-height: 1.188em; // 19px?
`;

export const TextLarge = styled.div`
  color: ${props => (props.color ? props.color : Colors.BLACK70)};
  font-family: 'Lato', sans-serif;
  font-size: 1.125em; // 18px
  line-height: 2em; // 32px
`;
