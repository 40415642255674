import React, { CSSProperties } from 'react';
import { FileUploaderPicker, IFileItem } from '../UserFile/FileUploaderPicker';
import { ContentState, convertToRaw } from 'draft-js';
import {
  IMuBag,
  IMuEditorRenderProps,
  MuEditor,
  MuEditorMode,
} from './MuEditor';
import { safeInvoke } from '../../common';
import { MuInternalEditor } from './MuInternalEditor';
import { MuToolbar } from './MuToolbar';
import {
  FileUploader,
  IFileUploaderRenderProps,
} from '../UserFile/FileUploader';
import { FileUploaderList } from '../UserFile/FileUploaderList';
import styled from 'styled-components';
import { FormLabel } from '../FormLabel/FormLabel';
import { IFile } from '../../../services/fileservice';
import { AccessEnum } from '../../../models/types';

interface IMuTextFieldProps {
  value: string;
  onSubmit: (value: string, files: any) => void;
  placeholder?: string;
  files?: Array<IFile>;
  label?: string;
  style?: CSSProperties;
  onClickFile?: (id: string, access: AccessEnum) => void;
  doIHaveAccessToAddFiles: boolean;
  // if the editor should include file support
  shouldEditorHaveFiles: boolean;
}

const Container = styled.div`
  :hover {
    cursor: text;
  }
`;

interface IMuTextFieldState {
  persistedFiles: Array<any>;
  files: Array<any>;
  editing: boolean;
}

// MuEditor with textField with bold and italic options + files
export class MuTextField extends React.PureComponent<
  IMuTextFieldProps,
  IMuTextFieldState
> {
  constructor(props: IMuTextFieldProps) {
    super(props);
    this.state = {
      files: [],
      editing: false,
      persistedFiles: this.props.files,
    };
  }
  componentWillReceiveProps(nextProps: IMuTextFieldProps) {
    if (nextProps.files !== this.state.persistedFiles) {
      this.setState({
        persistedFiles: nextProps.files,
      });
    }
  }
  render() {
    return (
      <Container style={{ ...this.props.style, minHeight: '5em' }}>
        {this.props.label && <FormLabel>{this.props.label}</FormLabel>}
        <MuEditor
          rawMarkdown={this.props.value || ''}
          editorMode={
            this.state.editing ? MuEditorMode.Edit : MuEditorMode.Preview
          }
          onSubmit={(
            markdown: string,
            files2: IFileItem[],
            contentState: ContentState,
            muEditorBag: IMuBag
          ) => {
            safeInvoke(
              this.props.onSubmit,
              JSON.stringify(convertToRaw(contentState)),
              this.state.files
            );
          }}
          editorRenderFunc={(propsEditor: IMuEditorRenderProps) => {
            return (
              <div>
                <div
                  onClick={() => {
                    setTimeout(() => {
                      this.setState({ editing: true });
                    }, 200);
                  }}
                >
                  <MuInternalEditor
                    placeholder={this.props.placeholder || ''}
                    onBlur={() => {
                      this.setState({
                        editing: false,
                      });
                      propsEditor.handleSubmit();
                    }}
                    isFocusingEditor={this.state.editing}
                    editorMode={propsEditor.editorMode}
                    onChange={propsEditor.handleDraftChange}
                    draftState={propsEditor.draftState}
                    style={{
                      position: 'relative', // fixed so the placeholder text doesn't move on vertical scroll
                      border: '1px solid rgba(128,128,128,.3)',
                      borderRadius: '4px',
                      minHeight: '5em',
                      paddingBottom: '2em',
                      paddingLeft: '.5em',
                      paddingTop: '.5em',
                    }}
                  />
                </div>
                <FileUploader
                  onUploadableFiles={(files22: any) => {
                    this.setState(
                      {
                        files: files22,
                      },
                      () => {
                        propsEditor.handleSubmit();
                      }
                    );
                  }}
                  files={this.state.persistedFiles || []}
                  fileUploaderRenderFunc={(
                    propsFiles: IFileUploaderRenderProps
                  ) => {
                    return (
                      <div style={{}}>
                        <div
                          style={{
                            marginTop: '-2em',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <MuToolbar
                            toolbarMode={propsEditor.toolbarMode}
                            onClickCommand={propsEditor.handleCommand}
                            isItalicChecked={propsEditor.isItalicChecked}
                            isBoldChecked={propsEditor.isBoldChecked}
                            style={{
                              marginLeft: '.5em',
                            }}
                          />
                          {this.props.doIHaveAccessToAddFiles &&
                            this.props.shouldEditorHaveFiles && (
                              <FileUploaderPicker
                                shouldUsePaperClip={true}
                                onNewFiles={propsFiles.handleNewFiles}
                                style={{
                                  marginRight: '1em',
                                  zIndex: 1,
                                }}
                              />
                            )}
                        </div>
                        <div
                          style={{
                            marginTop: '.5em',
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          <FileUploaderList
                            onClick={(id, access) =>
                              safeInvoke(this.props.onClickFile, id, access)
                            }
                            style={{
                              display: 'flex',
                              padding: '3px 5px',
                              alignItems: 'center',
                              fontSize: '12px',
                              marginTop: '8px',
                            }}
                            iconStyle={{
                              width: '1em',
                              height: '1em',
                            }}
                            onFileDelete={propsFiles.handleDeleteFile}
                            persistedFiles={propsFiles.persistedFiles}
                            recentFiles={propsFiles.recentFiles}
                            onRemoveFile={propsFiles.handleRemoveFile}
                            canEdit={true}
                            isEditMode={true}
                          />
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
            );
          }}
        />
      </Container>
    );
  }
}
