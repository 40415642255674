import * as React from 'react';
import { CSSProperties, ReactNode } from 'react';
import {
  defineMessages,
  InjectedIntl,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import styled from 'styled-components';
import { IconType } from 'react-icons/lib';
import { myHistory } from '../../index';
import { Colors } from '../common';
import { LinkComponent } from '../basic/Structural/links';

const messages = defineMessages({
  youarehere: {
    id: 'BreadCrumbs.youarehere',
    defaultMessage: 'You are here:',
  },
});

const BreadCrumbDiv = styled.div`
  color: #333;
  opacity: 0.7;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BreadCrumbLink = styled(LinkComponent)`
  color: #333;
  opacity: 0.7;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Seperator = styled.div`
  padding-left: 0.5em;
  padding-right: 0.5em;

  &:before {
    content: '>';
    display: inline-block;
  }
`;

const Container = styled.div`
  font-family: Lato, sans-serif;
  text-transform: uppercase;
  font-size: 0.625em;
  line-height: 1.5em;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BreadTitle = styled.div`
  color: #ff5c39;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-right: 0.5em;
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  line-height: 1.5em;

  border-bottom: solid 1px transparent;
`;

const IconContainer = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 50%;
  //background-color: pink;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 0.5em;
`;

interface IBreadCrumbsProps {
  title?: JSX.Element | string;
  style?: CSSProperties;
}

const BreadCrumbsComp: React.SFC<IBreadCrumbsProps & InjectedIntlProps> = (
  props: IBreadCrumbsProps & InjectedIntlProps & { children?: ReactNode },
  context?: any
) => {
  if (!props.children) {
    return null;
  }

  const length = React.Children.count(props.children);

  if (length === 0) {
    return null;
  }

  const temp: Array<any> = [];

  React.Children.map(props.children, (child: any, indexChild: number) => {
    if (child) {
      const sheep = React.cloneElement(child, { key: 'sh_' + indexChild });
      temp.push(sheep);
      if (indexChild < length - 1) {
        temp.push(<Seperator key={'sep_' + indexChild} />);
      }
    }
  });

  // let tempTitle = <FormattedMessage id={'Breadcrumbs.youarehere'} defaultMessage={'You are here:'} />;

  const tempTitle2 = props.intl.formatMessage(messages.youarehere);

  return (
    <Container style={props.style}>
      <BreadTitle key={'toto'}>{props.title || tempTitle2}</BreadTitle>
      {props.title && <Seperator key={'sep33'} />}
      {temp}
    </Container>
  );
};

export const BreadCrumbs = injectIntl(BreadCrumbsComp);

export interface IBreadCrumbProps {
  isDisabled?: boolean;
  leftIcon?: IconType;
  onClick?: () => void;
  style?: CSSProperties;
}

// TODO: rewrite to class, add click handler safeInvoke(props.onClick)

const renderIcon = (iconComponent: IconType, isDisabled: boolean) => {
  if (iconComponent === undefined || iconComponent === null) {
    return null;
  }

  const Icon = iconComponent;

  return <Icon fill={isDisabled ? Colors.GREYDISABLEDTEXT : '#333'} />;
};

export const BreadCrumb: React.SFC<IBreadCrumbProps> = props => {
  const { isDisabled, onClick, leftIcon, ...rest } = props;

  // const MyIcon = leftIcon;

  const all =
    leftIcon !== undefined ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconContainer>{renderIcon(leftIcon, false)}</IconContainer>
        {props.children}
      </div>
    ) : (
      props.children
    );

  return isDisabled ? (
    <BreadCrumbDiv {...rest} onClick={onClick} style={props.style}>
      {all}
    </BreadCrumbDiv>
  ) : (
    <BreadCrumbLink {...rest} onClick={onClick}>
      {all}
    </BreadCrumbLink>
  );
};

const partDict: { [key: string]: string } = {
  home: 'MainLayout.home',
  calendar: 'MainLayout.annualCycle',
  'action-value': 'MainLayout.actionValueLink',
  topic: 'MainLayout.topicLink',
  about: 'MainLayout.aboutLink',
  help: 'MainLayout.helpLink',
};

const translatePart = (part: string, intl: InjectedIntl) => {
  return partDict[part] ? intl.formatMessage({ id: partDict[part] }) : part;
};

/**
 * simple auto router test..
 * TODO:
 * - map from url to nice name
 * - icons?
 * @param props
 * @param context
 * @constructor
 */
export const AutoBreadCrumbs: React.SFC<
  InjectedIntlProps & { style?: CSSProperties }
> = (props, context) => {
  const temp = myHistory.location.pathname;
  const parts = temp
    .split('/')
    .filter((c: string) => c !== undefined && c !== '');

  let tempComplete = '/'; // '/app';

  return (
    <BreadCrumbs style={props.style}>
      {parts &&
        parts.map((part: string, i: number) => {
          if (!part || part.length === 0) {
            return null;
          }

          const text = translatePart(part, props.intl);
          tempComplete += '/' + part;

          return (
            <BreadCrumbLink
              href={tempComplete}
              key={'bc_' + i}
              // onClick={() => {
              //   console.log('push: ' + tempComplete);
              //   myHistory.pushState(tempComplete);
              // }}
            >
              {text}
            </BreadCrumbLink>
          );
        })}
    </BreadCrumbs>
  );
};

// export const AutoBreadCrumbs: React.SFC<> = injectIntl(AutoBreadCrumbsComp);
