import * as React from 'react';
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addChecklistPlaceholder: {
    id: 'AnnualCycle.addChecklistPlaceholder',
    defaultMessage: 'Add new point to checklist',
  },
  writeTitleHere: {
    id: 'AnnualCycle.writeTitleHere',
    defaultMessage: 'Write title here...',
  },
  warningCloseInstaceFormWithoutSaving: {
    id: 'AnnualCycle.warningCloseInstaceFormWithoutSaving',
    defaultMessage:
      'You have changes that has not been saved. Do you want to discard changes?',
  },
  yearWheelisRequired: {
    id: 'AnnualCycle.yearWheelisRequired',
    defaultMessage: 'Calendar is required',
  },
  checklistTitle: {
    id: 'AnnualCycle.checklistTitle',
    defaultMessage: 'Checklist',
  },
  checklistTitleTemplate: {
    id: 'AnnualCycle.checklistTitleTemplate',
    defaultMessage: 'Checklist template',
  },
  createRelation: {
    id: 'AnnualCycle.createRelation',
    defaultMessage: 'Connect calendar to topic:',
  },

  editInstanceCheckboxText: {
    id: 'AnnualCycle.editInstanceCheckboxText',
    defaultMessage:
      'The form will not close so you can continue to edit tasks by navigating in the left menu',
  },

  descriptionPlaceholder: {
    id: 'AnnualCycle.descriptionPlaceholder',
    defaultMessage: 'Description',
  },
  calendar: {
    id: 'AnnualCycle.calendar',
    defaultMessage: 'Annual cycle',
  },
  ChangeRepetition: {
    id: 'AnnualCycle.ChangeRepetition',
    defaultMessage:
      'when you change the repetition all of your instances ahead of time will be deleted and replaced with new ones based on the new repetition',
  },
  SubscribeToolTip: {
    id: 'AnnualCycle.SubscribeToolTip',
    defaultMessage: 'Open calendar in Outlook',
  },
  ExportToolTip: {
    id: 'AnnualCycle.ExportToolTip',
    defaultMessage: 'Export calendar',
  },
  Subscribe: {
    id: 'AnnualCycle.Subscribe',
    defaultMessage: 'Subscribe',
  },
  Export: {
    id: 'AnnualCycle.Export',
    defaultMessage: 'Export',
  },
  chooseCalendar: {
    id: 'AnnualCycle.chooseCalendar',
    defaultMessage: 'Choose annual cycle',
  },
  copyTask: {
    id: 'AnnualCycle.copyTask',
    defaultMessage: 'Copy task',
  },
  toastNoteReminder: {
    id: 'AnnualCycle.toastNoteReminder',
    defaultMessage: 'Do you want to add a note to the task?',
  },
  copyFinished: {
    id: 'AnnualCycle.copyFinished',
    defaultMessage: 'Click here to go to your annualcycle',
  },
  addActivityPlaceholder: {
    id: 'AnnualCycle.addActivityPlaceholder',
    defaultMessage: 'Add new activity',
  },
  responsible: {
    id: 'AnnualCycle.responsible',
    defaultMessage: 'Responsible',
  },
  deleteInstanceTitle: {
    id: 'AnnualCycle.deleteInstanceTitle',
    defaultMessage: 'Delete activity',
  },
  deleteInstanceBody: {
    id: 'AnnualCycle.deleteInstanceBody',
    defaultMessage:
      'You are about to delete an activity that is part of a series, do you want to only delete the activity or the entire series?',
  },
  deleteInstanceButtonTxt: {
    id: 'AnnualCycle.deleteInstanceButtonTxt',
    defaultMessage: 'Delete activity',
  },
  deleteInstanceButtonTxt1: {
    id: 'AnnualCycle.deleteInstanceButtonTxt1',
    defaultMessage: 'Delete series',
  },
  deleteConfirm: {
    id: 'AnnualCycle.deleteConfirm',
    defaultMessage: 'Are you sure yo want to delete?',
  },
  copyYearWheelSuccess: {
    id: 'AnnualCycle.copyYearWheelSuccess',
    defaultMessage: 'Copying succesfull!',
  },
  copyYearWheel: {
    id: 'AnnualCycle.copyYearWheel',
    defaultMessage: 'copy year wheel',
  },
  deleteYearWheelWarning: {
    id: 'AnnualCycle.deleteYearWheelWarning',
    defaultMessage: 'Are you sure you want to delete?',
  },
  EditAnnualCycleSuccessMsg: {
    id: 'AnnualCyclePage.EditAnnualCycleSuccessMsg',
    defaultMessage: 'Annual cycle successfully edited',
  },
  DeleteAnnualCycleSuccessMsg: {
    id: 'AnnualCyclePage.DeleteAnnualCycleSuccessMsg',
    defaultMessage: 'Annual cycle successfully deleted',
  },
  warningChangeRepetition: {
    id: 'AnnualCyclePage.warningChangeRepetition',
    defaultMessage: 'Changing repetition will re-create all future activities',
  },
  infoAddTodoTemplage: {
    id: 'AnnualCyclePage.infoAddTodoTemplage',
    defaultMessage: 'Will be added to all activities!',
  },
});
