import { ReactNode } from 'react';
import * as React from 'react';
import ApolloProvider from 'react-apollo/ApolloProvider';
import ApolloClient from 'apollo-client';
import { safeInvoke, safeInvokeDeprecated } from '../components/common';
import { initApolloClient } from './apolloClientFactory';

interface IApolloCacheGateProps {
  loading?: ReactNode;
  onBeforeLift?: () => void;
  onAfterLift?: () => void;
}

interface IApolloCacheGateState {
  isApolloCacheReady: boolean;
  apolloClient?: ApolloClient<any>;
}

/**
 * Simple gate component to delay rendering of children until apollo's
 * cache is ready (rehydrated or purged)
 */
export class ApolloCacheGate extends React.PureComponent<
  IApolloCacheGateProps,
  IApolloCacheGateState
> {
  private _isMounted: boolean = false;

  constructor(props: any) {
    super(props);
    this.state = {
      isApolloCacheReady: false,
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const { myApolloClient, myApolloPersistor } = await initApolloClient();

    safeInvoke(this.props.onBeforeLift);

    if (this._isMounted) {
      this.setState(
        {
          isApolloCacheReady: true,
          apolloClient: myApolloClient,
        },
        () => {
          safeInvoke(this.props.onAfterLift);
        }
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { loading } = this.props;
    const { isApolloCacheReady, apolloClient } = this.state;

    if (!isApolloCacheReady || apolloClient === undefined) {
      return loading || null;
    }

    return (
      <ApolloProvider client={apolloClient}>
        {this.props.children}
      </ApolloProvider>
    );
  }
}
