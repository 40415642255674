import React, { CSSProperties, RefObject, useEffect } from 'react';
import styled from 'styled-components';
import { ColorTheme, isReallyEmpty, safeInvoke } from '../common';
import { Button } from '../basic';
import { MunikumIcons } from '../common/icons';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { IFileItem } from '../basic/UserFile/FileUploaderPicker';
import { ContentState, convertFromRaw } from 'draft-js';
import {
  IMuBag,
  IMuEditorRenderProps,
  MuEditor,
  MuEditorMode,
} from '../basic/MuEditor/MuEditor';
import { MuToolbarMode } from '../basic/MuEditor/MuToolbar';
import { MuInternalEditor } from '../basic/MuEditor/MuInternalEditor';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { commonLabels } from '../language/commonLabels';
import { commonMessages } from '../language/commonMessages';
import { BreadCrumb, BreadCrumbs } from '../annualCycle/BreadCrumbs';
import { FormLabel } from '../basic/FormLabel/FormLabel';
import { MuTextField } from '../basic/MuEditor/MuTextField';

interface ICreateDiscussionCardProps {
  onCancel: (isEmpty: boolean) => void;
  style?: CSSProperties;
  breadCrumbTitle: string;
  onSubmit: (
    title: string,
    description: string,
    descriptionText: string,
    files: Array<IFileItem>
  ) => void;
}

const Container = styled.div`
  min-height: 15em;
  padding: 2em;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 32.5em;
`;

const LeftSection = styled.div``;

const RightSection = styled.div`
  flex: 1 1 0px;

  padding: 1.25em;

  overflow: hidden;

  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
`;

const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 2em;
  align-items: flex-end;
`;

const Error = styled.div`
  color: #ff5c39;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Lato, sans-serif;
`;

function CreateDiscussionCardComp(
  props: ICreateDiscussionCardProps & InjectedIntlProps
) {
  let containerRef: RefObject<any>;
  let titleRef: RefObject<any>;
  let descriptionRef: RefObject<any>;
  titleRef = React.createRef();
  const [showErrorMsgTitle, setErrorMsgTitle] = React.useState(false);
  const [showErrorMsgDsc, setErrorMsgDsc] = React.useState(false);
  descriptionRef = React.createRef();
  containerRef = React.createRef();
  // function isFieldsEmpty() {
  //   if(isReallyEmpty(titleValue) && isReallyEmpty(descriptionValue)) {
  //     return true;
  //   }
  //   return false;
  // }

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus();
    }
  });

  const handleSubmit = values => {
    let text = values.descriptionText.replace(/(\r\n|\n|\r)/gm, '');
    if (isReallyEmpty(values.title) || isReallyEmpty(text)) {
      if (isReallyEmpty(values.title)) {
        setErrorMsgTitle(true);
      }
      if (isReallyEmpty(text)) {
        setErrorMsgDsc(true);
      }
    } else {
      setTimeout(() => {
        safeInvoke(
          props.onSubmit,
          values.title,
          values.description,
          values.descriptionText,
          values.files
        );
      }, 200);
    }
  };
  return (
    <Formik
      validationSchema={yup.object().shape({
        title: yup
          .string()
          .required()
          .max(255)
          .label(props.intl.formatMessage(commonLabels.title)),
        description: yup
          .string()
          .required()
          .label(props.intl.formatMessage(commonLabels.description)),
      })}
      initialValues={{
        title: '',
        description: '',
        descriptionText: '',
        files: [],
      }}
      onSubmit={values => {}}
      render={(formikProps: FormikProps<any>) => {
        return (
          <Container ref={containerRef} style={props.style}>
            <BreadCrumbs style={{ marginBottom: '2em' }}>
              <BreadCrumb
                leftIcon={MunikumIcons.Folder}
                onClick={() => {
                  if (titleRef.current) {
                    titleRef.current.focus();
                  }
                }}
              >
                {props.breadCrumbTitle}
              </BreadCrumb>
            </BreadCrumbs>
            <FormLabel>
              {props.intl.formatMessage(commonLabels.title)}
            </FormLabel>
            <MuEditor
              style={{}}
              onSubmit={(
                markdown: string,
                files2: IFileItem[],
                contentState: ContentState,
                muEditorBag: IMuBag
              ) => {
                let text = markdown.replace(/(\r\n|\n|\r)/gm, '');
                if (!isReallyEmpty(text)) {
                  formikProps.setFieldValue('title', markdown);
                  setErrorMsgTitle(false);
                } else {
                  formikProps.setFieldValue('title', '');
                  setErrorMsgTitle(true);
                }

                // NOTE: a little "flash" on save, add optimistic update?
              }}
              toolbarMode={MuToolbarMode.None}
              editorMode={MuEditorMode.Edit}
              editorRenderFunc={(props2: IMuEditorRenderProps) => {
                return (
                  <div style={{ width: '100%', display: 'flex' }}>
                    <MuInternalEditor
                      style={{
                        width: '100%',
                        fontFamily: 'Lato, sans-serif',
                        fontSize: '2em',
                        fontWeight: 'bold',
                        marginBottom: showErrorMsgTitle ? '0' : '.5em',
                      }}
                      ref={titleRef}
                      placeholder={props.intl.formatMessage(
                        commonLabels.titlePlaceholder
                      )}
                      editorMode={props2.editorMode}
                      toolbarMode={props2.toolbarMode}
                      draftState={props2.draftState}
                      onBlur={props2.handleSubmit}
                      onChange={props2.handleDraftChange}
                    />
                  </div>
                );
              }}
            />
            {showErrorMsgTitle && (
              <Error>
                <MunikumIcons.Cancel
                  style={{ marginRight: '.25em', marginBottom: '.5em' }}
                />
                {props.intl.formatMessage(commonMessages.titleRequired)}
              </Error>
            )}
            <MuTextField
              shouldEditorHaveFiles={true}
              style={{}}
              doIHaveAccessToAddFiles={true}
              label={props.intl.formatMessage(commonLabels.text)}
              placeholder={props.intl.formatMessage(
                commonLabels.descriptionPlaceholder
              )}
              files={[]}
              onSubmit={(value, files) => {
                console.log(files);
                let text = convertFromRaw(JSON.parse(value))
                  .getPlainText()
                  .replace(/(\r\n|\n|\r)/gm, '');
                if (!isReallyEmpty(text)) {
                  setErrorMsgDsc(false);
                  formikProps.setFieldValue('description', value);
                  formikProps.setFieldValue('files', files);
                  formikProps.setFieldValue(
                    'descriptionText',
                    convertFromRaw(JSON.parse(value)).getPlainText()
                  );
                } else {
                  setErrorMsgDsc(true);
                  formikProps.setFieldValue('description', '');
                  formikProps.setFieldValue('descriptionText', '');
                }
              }}
              value={formikProps.values.description}
            />
            {showErrorMsgDsc && (
              <Error>
                <MunikumIcons.Cancel
                  style={{ marginRight: '.25em', marginBottom: '.5em' }}
                />
                {props.intl.formatMessage(commonMessages.descriptionRequired)}
              </Error>
            )}
            <ButtonDiv>
              <Button
                text={props.intl.formatMessage(commonLabels.Cancel)}
                style={{ marginRight: '.5em' }}
                theme={ColorTheme.White}
                leftIcon={MunikumIcons.Cancel}
                onClick={() => {
                  safeInvoke(props.onCancel, false);
                }}
              />
              <Button
                text={props.intl.formatMessage(commonMessages.publish)}
                onClick={() => {
                  handleSubmit(formikProps.values);
                }}
              />
            </ButtonDiv>
          </Container>
        );
      }}
    />
  );
}

export const CreateDiscussionCard = injectIntl(CreateDiscussionCardComp);
