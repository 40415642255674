import letterSad from './letter-sad.svg';
import letterHappy from './letter-happy.svg';
import letterOhno from './letter-ohno.svg';
import letterYes from './letter-yes.svg';
import letterStart from './letter-start.svg';

import * as React from 'react';
import { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import { H4, TextNormal } from '../../basic/Structural/typography';
import MiniErrorMessage from '../../basic/StatusMessage/MiniErrorMessage';
import { defineMessages, InjectedIntlProps } from 'react-intl';
import { CircleSpinner } from '../../basic/Loader/CircleSpinner';
import { commonMessages } from '../../language/commonMessages';
import { Checkbox } from '../../basic/Checkbox/Checkbox';
import {
  GET_USER_WIZARD_DATA,
  GetUserWizardData,
  UPDATE_ME_SETTINGS,
  UpdateMeSettings,
} from '../personQueries';

const messages = defineMessages({
  errorSave: {
    id: 'notificationsettings.error',
    defaultMessage: 'Error when saving notification settings',
  },
  info: {
    id: 'notificationsettings.info',
    defaultMessage:
      'Can we send you an email when something important happens in Munikum?',
  },
  buttonText: {
    id: 'notificationsettings.buttonText',
    defaultMessage: 'Sure, why not!',
  },
  instant: {
    id: 'notificationsettings.instant',
    defaultMessage: 'Immediately',
  },
  daily: {
    id: 'notificationsettings.daily',
    defaultMessage: 'Daily',
  },
  weekly: {
    id: 'notificationsettings.weekly',
    defaultMessage: 'Weekly',
  },
  whenCanWeEmailYou: {
    id: 'notificationsettings.whenCanWeEmailYou',
    defaultMessage: 'How often may we send you an email?',
  },
  newsLetterInfo: {
    id: 'phoneSettings.newsLetterInfo',
    defaultMessage: 'Do you wish to be sent newsletters on e-mail?',
  },
});

const CenterVertical = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 390px;
`;

const CenterDiv = styled.div`
  //background-color: orange;
  align-self: center;
  justify-self: center;
  flex: 1 1 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

function getMood(
  isChecked: boolean,
  isHovering: boolean,
  justDidIt: boolean,
  first: boolean
) {
  if (first && !isChecked) {
    return letterStart;
  }
  if (isHovering && !justDidIt) {
    if (isChecked) {
      return letterOhno;
    } else {
      return letterYes;
    }
  } else {
    if (isChecked) {
      return letterHappy;
    } else {
      return letterSad;
    }
  }
}

const NotificationSettings: React.FunctionComponent<
  { style?: CSSProperties; butt: boolean } & InjectedIntlProps
> = props => {
  const [hover, setHover] = useState(false);
  // const [ok, setOk] = useState(false);
  const [justDidIt, setJustDidIt] = useState(false);
  const [first, setFirst] = useState(true);

  return (
    <GetUserWizardData query={GET_USER_WIZARD_DATA}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <CenterDiv>
              <CircleSpinner />
            </CenterDiv>
          );
        }

        if (error || !data) {
          return (
            <CenterDiv>
              <MiniErrorMessage
                message={props.intl.formatMessage(commonMessages.errorLoadData)}
                intl={props.intl}
              />
            </CenterDiv>
          );
        }

        return (
          <UpdateMeSettings mutation={UPDATE_ME_SETTINGS}>
            {(
              updateSettings,
              { data: uData, loading: uLoading, called, error: uError }
            ) => {
              const allowEmails =
                (data &&
                  data.me &&
                  data.me.settings &&
                  data.me.settings.allowContactViaEmail) ||
                false; // nope, default false, legal stuff?

              const setAllowEmails = (nextOk: boolean) => {
                updateSettings({
                  variables: {
                    input: {
                      acceptedUserTerms: data.me.settings.acceptedUserTerms,
                      allowContactViaEmail: nextOk, // <===
                      showAssignedTasks: data.me.settings.showAssignedTasks,
                      allowContactViaPhone:
                        data.me.settings.allowContactViaPhone,
                      sendDailyNotificationMail:
                        data.me.settings.sendDailyNotificationMail,
                      sendInstantNotificationMail:
                        data.me.settings.sendInstantNotificationMail,
                      sendWeeklyNotificationMail:
                        data.me.settings.sendWeeklyNotificationMail,
                      shouldReceiveNewsletter:
                        data.me.settings.shouldReceiveNewsletter,
                    },
                  },
                  optimisticResponse: {
                    __typename: 'Mutation',
                    updateSettings: {
                      name: data.me.name,
                      kostraFunctions: data.me.kostraFunctions,
                      language: data.me.language,
                      jobTitle: data.me.jobTitle,
                      department: data.me.department,
                      description: data.me.description,
                      phoneNumber: data.me.phoneNumber,
                      __typename: data.me.__typename,
                      settings: {
                        __typename: data.me.settings.__typename,
                        showAssignedTasks: data.me.settings.showAssignedTasks,
                        allowContactViaEmail: nextOk, // <== change here..
                        sendInstantNotificationMail:
                          data.me.settings.sendInstantNotificationMail,
                        sendDailyNotificationMail:
                          data.me.settings.sendDailyNotificationMail,
                        sendWeeklyNotificationMail:
                          data.me.settings.sendWeeklyNotificationMail,
                        acceptedUserTerms: data.me.settings.acceptedUserTerms,
                        allowContactViaPhone:
                          data.me.settings.allowContactViaPhone,
                        shouldReceiveNewsletter:
                          data.me.settings.shouldReceiveNewsletter,
                      },
                      organization: data.me.organization,
                      email: data.me.email,
                      id: data.me.id,
                      picture: data.me.picture,
                    },
                  },
                });
              };

              if (uError) {
                console.error(uError);
                return (
                  <CenterDiv>
                    <MiniErrorMessage
                      message={props.intl.formatMessage(messages.errorSave)}
                      intl={props.intl}
                    />
                  </CenterDiv>
                );
              }

              const main = (
                <MyDiv style={props.style}>
                  <div>
                    <TextNormal style={{ width: '200px', marginBottom: '1em' }}>
                      {props.intl.formatMessage(messages.info)}
                    </TextNormal>
                    <div
                      onMouseEnter={e => {
                        setFirst(false);
                        setHover(true);
                      }}
                      onMouseLeave={e => {
                        setHover(false);
                        setJustDidIt(false);
                      }}
                    >
                      <Checkbox
                        checked={allowEmails}
                        onClick={() => {
                          setJustDidIt(true);
                          // setOk(!ok);
                          setAllowEmails(!allowEmails);
                        }}
                        label={props.intl.formatMessage(messages.buttonText)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: '2em',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '200px',
                    }}
                  >
                    <img src={getMood(allowEmails, hover, justDidIt, first)} />
                  </div>
                </MyDiv>
              );

              const newsLetter = (
                <div
                  style={{
                    marginLeft: props.butt ? '4em' : '2em',
                    marginTop: props.butt ? '2em' : '',
                    marginBottom: props.butt ? '' : '-5em',
                  }}
                >
                  <H4 style={{ marginBottom: '1em' }}>
                    {props.intl.formatMessage(messages.newsLetterInfo)}
                  </H4>
                  <Checkbox
                    checked={data.me.settings.shouldReceiveNewsletter}
                    onClick={() => {
                      updateSettings({
                        variables: {
                          input: {
                            allowContactViaPhone:
                              data.me.settings.allowContactViaPhone,
                            allowContactViaEmail:
                              data.me.settings.allowContactViaEmail,
                            sendInstantNotificationMail:
                              data.me.settings.sendInstantNotificationMail,
                            sendDailyNotificationMail:
                              data.me.settings.sendDailyNotificationMail,
                            sendWeeklyNotificationMail:
                              data.me.settings.sendWeeklyNotificationMail,
                            showAssignedTasks:
                              data.me.settings.showAssignedTasks,
                            acceptedUserTerms:
                              data.me.settings.acceptedUserTerms,
                            shouldReceiveNewsletter: !data.me.settings
                              .shouldReceiveNewsletter,
                          },
                        },
                      });
                    }}
                    label={props.intl.formatMessage(messages.buttonText)}
                  />
                </div>
              );

              const details = (
                <div
                  style={{
                    paddingLeft: props.butt ? '0' : '2em',
                    paddingTop: props.butt ? '3.3em' : '1.3em',
                  }}
                >
                  <H4>
                    {props.intl.formatMessage(messages.whenCanWeEmailYou)}
                  </H4>
                  <Checkbox
                    style={{ marginTop: '.5em' }}
                    label={props.intl.formatMessage(messages.instant)}
                    onClick={() => {
                      updateSettings({
                        variables: {
                          input: {
                            allowContactViaPhone:
                              data.me.settings.allowContactViaPhone,
                            allowContactViaEmail:
                              data.me.settings.allowContactViaEmail,
                            sendInstantNotificationMail: !data.me.settings
                              .sendInstantNotificationMail,
                            sendDailyNotificationMail:
                              data.me.settings.sendDailyNotificationMail,
                            sendWeeklyNotificationMail:
                              data.me.settings.sendWeeklyNotificationMail,
                            showAssignedTasks:
                              data.me.settings.showAssignedTasks,
                            acceptedUserTerms:
                              data.me.settings.acceptedUserTerms,
                          },
                        },
                      });
                    }}
                    checked={data.me.settings.sendInstantNotificationMail}
                  />
                  <Checkbox
                    label={props.intl.formatMessage(messages.daily)}
                    onClick={() => {
                      updateSettings({
                        variables: {
                          input: {
                            allowContactViaPhone:
                              data.me.settings.allowContactViaPhone,
                            allowContactViaEmail:
                              data.me.settings.allowContactViaEmail,
                            sendInstantNotificationMail:
                              data.me.settings.sendInstantNotificationMail,
                            sendDailyNotificationMail: !data.me.settings
                              .sendDailyNotificationMail,
                            sendWeeklyNotificationMail:
                              data.me.settings.sendWeeklyNotificationMail,
                            showAssignedTasks:
                              data.me.settings.showAssignedTasks,
                            acceptedUserTerms:
                              data.me.settings.acceptedUserTerms,
                          },
                        },
                      });
                    }}
                    checked={data.me.settings.sendDailyNotificationMail}
                  />
                  <Checkbox
                    label={props.intl.formatMessage(messages.weekly)}
                    onClick={() => {
                      updateSettings({
                        variables: {
                          input: {
                            allowContactViaPhone:
                              data.me.settings.allowContactViaPhone,
                            allowContactViaEmail:
                              data.me.settings.allowContactViaEmail,
                            sendInstantNotificationMail:
                              data.me.settings.sendInstantNotificationMail,
                            sendDailyNotificationMail:
                              data.me.settings.sendDailyNotificationMail,
                            sendWeeklyNotificationMail: !data.me.settings
                              .sendWeeklyNotificationMail,
                            showAssignedTasks:
                              data.me.settings.showAssignedTasks,
                            acceptedUserTerms:
                              data.me.settings.acceptedUserTerms,
                          },
                        },
                      });
                    }}
                    checked={data.me.settings.sendWeeklyNotificationMail}
                  />
                </div>
              );

              return props.butt ? (
                <div>
                  <div style={{ display: 'flex' }}>
                    {main}
                    {newsLetter}
                  </div>
                  {allowEmails && details}
                </div>
              ) : (
                <div style={{ display: 'flex' }}>
                  {main}
                  <div>
                    {allowEmails && details}
                    <div style={{ marginTop: '2em' }}>{newsLetter}</div>
                  </div>
                </div>
              );
            }}
          </UpdateMeSettings>
        );
      }}
    </GetUserWizardData>
  );
};

export default NotificationSettings;
