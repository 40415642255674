import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import styled from 'styled-components';
import { MunikumIcons } from '../common/icons';
import gql from 'graphql-tag';
import { Query, QueryResult } from 'react-apollo';
import { RefHandler } from 'react-popper';
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { hideOutline, safeInvokeDeprecated } from '../common';
import {
  AccessEnum,
  FindPersonsOrganizationsAndGroupsQuery,
  FindPersonsOrganizationsAndGroupsVariables,
  GetMeForTagsQuery,
  GetMeForTagsVariables,
  Organization,
  OrganizationGroup,
  Person,
  PersonGroup,
} from '../../models/types';
import {
  IStaticImageType,
  StaticImage,
} from '../basic/StaticImage/StaticImage';
import { MunikumKeys } from '../common/keys';
import { Tag } from '../basic/Tag/Tag';
import { Popover } from '../basic/Popover/Popover';
import { commonLabels } from '../language/commonLabels';
import { PermissionList } from './AnnualCycleMembers';
import { Tooltip } from '../basic/Tooltip/Tooltip';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'searchPlaceholder.EmailNameOrMunicipality  ',
    defaultMessage: 'Search for email, name or organization',
  },
});
export const AddMemmbersContainer = styled.div`
  display: flex;
  border-radius: 3px;
  background-color: ${(props: { disabled: boolean }) =>
    props.disabled ? '#cccccc' : '#fff'};
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);

  border: 1px solid rgba(208, 211, 212, 0.2);
  height: 36px;
  width: 100%;

  transition: box-shadow 0.2s ease-in-out;
`;

export const AddMemberContentCont = styled.div`
  flex: 1 1 0px;

  padding: 0.5em;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
`;

const LiDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  list-style-type: none;
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  margin-left: 0.5em;
  margin-right: 0.5em;

  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  padding: 0.5em;

  font-weight: bold;
`;

export const SmallText = styled.div`
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-weight: bold;
`;

const MyCustomInput = styled.input`
  border: none;
  color: #333;
  background-color: #fff;
  font-family: Lato, sans-serif;
  font-size: 14px;
  margin-left: 0.5em;
  margin-right: 0.5em;
  font-weight: bold;
  ${hideOutline()};
`;

const SearcDiv = styled.div`
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  width: 17.75em;
`;

const AddDiv = styled.div`
  transition: all 0.2s ease-in-out;
  width: max-content;
  height: max-content;
  :hover {
    transform: scale(1.3);
    cursor: pointer;
  }
`;

export const TagDiv = styled.div`
  color: #00b0b9;
  font-family: Lato, sans-serif;
  font-size: 14px;
  display: ${(props: { isPersonSelected: boolean }) =>
    props.isPersonSelected ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
`;

const PermissionCont = styled.div`
  font-size: 12px;
  display: ${(props: { isPermissionActive: boolean }) =>
    props.isPermissionActive ? 'block' : 'none'};
  color: rgba(51, 51, 51, 0.7);
  font-family: Lato, sans-serif;
  margin-right: 2em;
  margin-left: 2em;
  margin-top: 0.3em;

  align-items: center;
  justify-content: center;
  :hover {
    cursor: ${(props: { isPermissionActive: boolean }) =>
      props.isPermissionActive ? 'normal' : 'pointer'};
  }
`;

const ResultContainer = styled.ul`
  padding: 0.5em;
`;

const FIND_PERSONS_ORGANIZATIONS_AND_GROUPS = gql`
  query FindPersonsOrganizationsAndGroups($query: String!) {
    search(query: $query) {
      persons {
        name
        id
        department
        picture
        role
        organization {
          id
          name
          organizationImage
        }
      }
      groups {
        id
        title
        createdBy {
          id
          name
        }
        dateCreated
        lastUpdated
        members {
          name
          id
        }
      }
      organizationGroups {
        createdBy {
          id
          name
        }
        id
        lastUpdated
        title
        members {
          name
          id
        }
      }
      organizations {
        id
        name
        organizationImage
      }
    }
  }
`;
const FIND_PERSONS = gql`
  query FindPersons($query: String!) {
    search(query: $query) {
      persons {
        name
        id
        department
        picture
        role
        organization {
          id
          name
          organizationImage
        }
      }
    }
  }
`;
const GET_ME_FOR_TAGS = gql`
  query GetMeForTags {
    me {
      id
      name
    }
  }
`;

class MeQuery extends Query<GetMeForTagsQuery, GetMeForTagsVariables> {}

interface IAddMemberCompState {
  searchValue: string;
  isResultOpen: boolean;
  isPersonSelected: boolean;
  selectedPerson?: Person;
  selectedIndex: number;
  searchResult: Array<any>;
  permission: AccessEnum;
  isTagShownOnStart?: boolean;
  isPermissionPopoverOpen: boolean;
  currentIndex: number;
  isPrivate: boolean;
}

interface IAddMemberCompProps {
  disabled?: boolean;
  addMember?: (person: Person, permission: AccessEnum) => void;
  addGroup?: (group: PersonGroup, permission: AccessEnum) => void;
  addOrg?: (org: Organization, permission: AccessEnum) => void;
  showOnlyPerson: boolean;
  addOrgGroup?: (orgGroup: OrganizationGroup, permission: AccessEnum) => void;
  isPrivate?: boolean;
  permissionLevel?: (nameInnerHtml: any) => void;
  style?: CSSProperties;
  tagMaxWidth?: CSSProperties;
  isPermissionActive?: boolean;
  title?: string;
  showTag: boolean;
  onTagClose?: () => void;
  currentPermissionOwner?: string;
  canChangePermission?: boolean;
}

class MembersQuery extends Query<
  FindPersonsOrganizationsAndGroupsQuery,
  FindPersonsOrganizationsAndGroupsVariables
> {}

let permissionList: Array<any> = [
  { value: 'READ', name: 'Lesetilgang' },
  { value: 'WRITE', name: 'Skrivetilgang' },
];
class AddMemberComp extends React.PureComponent<
  IAddMemberCompProps & InjectedIntlProps,
  IAddMemberCompState
> {
  private myRef: RefObject<any>;
  private resultContRef: RefObject<any>;
  private ref2: RefObject<any>;
  private ref3: RefObject<any>;

  constructor(props: IAddMemberCompProps & InjectedIntlProps) {
    super(props);
    this.myRef = React.createRef();
    this.resultContRef = React.createRef();
    this.ref2 = React.createRef();
    this.ref3 = React.createRef();
    this.state = {
      permission: this.props.isPrivate ? AccessEnum.READ : AccessEnum.WRITE,
      isPrivate: this.props.isPrivate,
      selectedIndex: -1,
      searchValue: '',
      isResultOpen: false,
      isPersonSelected: this.props.showTag,
      searchResult: [],
      isTagShownOnStart: this.props.showTag,
      isPermissionPopoverOpen: false,
      currentIndex: -1,
    };
  }

  protected handleKeyUp = (e: any) => {
    if (e.which === MunikumKeys.ARROW_UP) {
      if (this.state.selectedIndex > 0) {
        this.setState({
          selectedIndex: this.state.selectedIndex - 1,
        });
      }
    } else if (e.which === MunikumKeys.ARROW_DOWN) {
      if (this.state.selectedIndex < this.state.searchResult.length - 1) {
        // console.log(this.state.searchResult);
        this.setState({
          selectedIndex: this.state.selectedIndex + 1,
        });
      }
    } else if (e.which === MunikumKeys.ENTER) {
      const selectedItem = this.state.searchResult[this.state.selectedIndex];
      this.handleAddMember();
    }
  };
  UNSAFE_componentWillReceiveProps(nextProps: IAddMemberCompProps) {
    if (nextProps.isPrivate !== this.state.isPrivate) {
      this.setState(
        {
          isPrivate: nextProps.isPrivate,
        },
        () => {
          this.setState({
            permission: this.props.isPrivate
              ? AccessEnum.READ
              : AccessEnum.WRITE,
          });
        }
      );
    }
  }
  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyUp);
    document.addEventListener('mousedown', this.handleClickOutSide);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('mousedown', this.handleClickOutSide);
  }
  onChange = (e: any) => {
    this.setState(
      {
        searchValue: e.target.value,
        selectedIndex: -1,
      },
      () => {
        if (this.state.searchValue.length > 0) {
          this.setState({
            isResultOpen: true,
          });
        } else {
          this.setState({
            isResultOpen: false,
          });
        }
      }
    );
  };
  protected handleClickOutSide = (e: any) => {
    if (this.state.isResultOpen) {
      if (
        this.resultContRef &&
        !this.resultContRef.current.contains(e.target)
      ) {
        this.setState({
          isResultOpen: false,
        });
      }
    }
  };
  handleClickGroup = (group: PersonGroup) => {
    safeInvokeDeprecated(this.props.addGroup, group, this.state.permission);
    if (this.props.showTag) {
      this.setState(
        {
          isPersonSelected: true,
          isResultOpen: false,
          searchValue: group.title,
          // selectedPerson: person,
        },
        () => {
          if (this.myRef.current) {
            this.myRef.current.focus();
          }
        }
      );
    } else {
      this.setState(
        {
          // isPersonSelected: true,
          isResultOpen: false,
          searchValue: '',
          // selectedPerson: person,
        },
        () => {
          if (this.myRef.current) {
            this.myRef.current.focus();
          }
        }
      );
    }
  };
  handleClickOrgGroup = (orgGroup: OrganizationGroup) => {
    safeInvokeDeprecated(
      this.props.addOrgGroup,
      orgGroup,
      this.state.permission
    );
    if (this.props.showTag) {
      this.setState(
        {
          isPersonSelected: true,
          isResultOpen: false,
          searchValue: orgGroup.title,
          // selectedPerson: person,
        },
        () => {
          if (this.myRef.current) {
            this.myRef.current.focus();
          }
        }
      );
    } else {
      this.setState(
        {
          // isPersonSelected: true,
          isResultOpen: false,
          searchValue: '',
          // selectedPerson: person,
        },
        () => {
          if (this.myRef.current) {
            this.myRef.current.focus();
          }
        }
      );
    }
  };
  handleClickOrganizations = (org: Organization) => {
    safeInvokeDeprecated(this.props.addOrg, org, this.state.permission);
    if (this.props.showTag) {
      this.setState(
        {
          isPersonSelected: true,
          isResultOpen: false,
          searchValue: org.name,
          // selectedPerson: person,
        },
        () => {
          if (this.myRef.current) {
            this.myRef.current.focus();
          }
        }
      );
    } else {
      this.setState(
        {
          // isPersonSelected: true,
          isResultOpen: false,
          searchValue: '',
          // selectedPerson: person,
        },
        () => {
          if (this.myRef.current) {
            this.myRef.current.focus();
          }
        }
      );
    }
  };
  handleClickMember = (person: Person) => {
    safeInvokeDeprecated(this.props.addMember, person, this.state.permission);
    if (this.props.showTag) {
      this.setState(
        {
          isPersonSelected: true,
          isResultOpen: false,
          searchValue: person.name,
          selectedPerson: person,
        },
        () => {
          if (this.myRef.current) {
            this.myRef.current.focus();
          }
        }
      );
    } else {
      this.setState(
        {
          isPersonSelected: false,
          isResultOpen: false,
          searchValue: '',
          selectedPerson: person,
        },
        () => {
          if (this.myRef.current) {
            this.myRef.current.focus();
          }
        }
      );
    }
  };

  handleAddMember = () => {
    const tempPerson = this.state.searchResult[this.state.selectedIndex];
    if (!tempPerson) {
      return;
    }
    if (this.props.showTag) {
      safeInvokeDeprecated(
        this.props.addMember,
        this.state.searchResult[this.state.selectedIndex],
        this.state.permission
      );

      this.setState(
        {
          isPersonSelected: true,
          isResultOpen: false,
          selectedPerson: tempPerson,
          searchValue: '',
          selectedIndex: -1,
        },
        () => {
          if (this.ref2.current) {
            this.ref2.current.focus();
          }
        }
      );
    } else {
      safeInvokeDeprecated(
        this.props.addMember,
        this.state.searchResult[this.state.selectedIndex],
        this.state.permission
      );
      this.setState(
        {
          isPersonSelected: false,
          isResultOpen: false,
          selectedPerson: undefined,
          searchValue: '',
          selectedIndex: -1,
        },
        () => {
          if (this.ref2.current) {
            this.ref2.current.focus();
          }
        }
      );
    }
  };

  handleRemoveMember = () => {
    safeInvokeDeprecated(this.props.onTagClose);
    this.setState(
      {
        isPersonSelected: false,
        isResultOpen: false,
        isTagShownOnStart: false,
        searchValue: '',
        selectedPerson: undefined,
      },
      () => {
        if (this.ref2.current) {
          this.ref2.current.focus();
        }
      }
    );
  };

  render() {
    const { intl } = this.props;
    const permissionItems = {
      READ: intl.formatMessage(commonLabels.read),
      WRITE: intl.formatMessage(commonLabels.write),
    };
    // console.log(this.state.isPrivate, 'state');
    // console.log(this.props.isPrivate, 'props');

    return (
      <Popover
        isOpen={this.state.isResultOpen}
        style={{ padding: '.2em', width: '28em' }}
        position={'bottom-start'}
        renderTarget={(ref: RefHandler) => {
          return (
            <AddMemmbersContainer
              disabled={this.props.disabled}
              ref={ref}
              style={this.props.style}
              onClick={() => {
                if (this.ref2.current) {
                  this.ref2.current.focus();
                }
              }}
            >
              <AddMemberContentCont
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MunikumIcons.Search
                    style={{
                      color: '#FF5C39',
                      width: '1.250em',
                      height: '1.250em',
                      marginRight: '.5em',
                    }}
                  />
                  {/*{this.props.title && (*/}
                  {/*<SmallText>{intl.formatMessage(commonLabels.searchHere)}</SmallText>*/}
                  {/*)}*/}
                  {!this.state.isPersonSelected &&
                    !this.state.isTagShownOnStart &&
                    !this.props.disabled && (
                      <SearcDiv>
                        <MyCustomInput
                          ref={this.ref2}
                          autoComplete={'off'}
                          onKeyDown={(event: any) => {
                            if (event.keyCode === MunikumKeys.ENTER) {
                              event.preventDefault();
                              event.stopPropagation();
                            }
                          }}
                          style={{ border: 'none', width: '100%' }}
                          placeholder={intl.formatMessage(
                            messages.searchPlaceholder
                          )}
                          value={this.state.searchValue}
                          onChange={this.onChange}
                        />
                      </SearcDiv>
                    )}
                  {this.state.isPersonSelected && this.props.showTag && (
                    <MeQuery
                      query={GET_ME_FOR_TAGS}
                      // variables={{ id: this.props.id }}
                      children={(result: QueryResult<GetMeForTagsQuery>) => {
                        const me = result.data && result.data.me;
                        return (
                          <Tooltip
                            content={
                              (this.state.selectedPerson &&
                                this.state.selectedPerson.name) ||
                              this.props.currentPermissionOwner ||
                              (me && me.name) ||
                              ''
                            }
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <TagDiv
                                isPersonSelected={this.state.isPersonSelected}
                                ref={this.myRef}
                              >
                                <Tag
                                  uppercase={false}
                                  hasShadow={true}
                                  styleText={this.props.tagMaxWidth}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#fff',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Lato',
                                  }}
                                  text={
                                    (this.state.selectedPerson &&
                                      this.state.selectedPerson.name) ||
                                    this.props.currentPermissionOwner ||
                                    (me && me.name) ||
                                    ''
                                  }
                                  color={'#00B0B9'}
                                  canClose={
                                    this.props.showTag && !this.props.disabled
                                  }
                                  onClickClose={this.handleRemoveMember}
                                />
                              </TagDiv>
                            </div>
                          </Tooltip>
                        );
                      }}
                    />
                  )}
                </div>
                <PermissionCont
                  isPermissionActive={this.props.isPermissionActive || false}
                >
                  {/*<DropDownSelect*/}
                  {/*onChangeSelected={(e: PersonAccessEnum) => {*/}
                  {/**/}
                  {/*}}*/}
                  {/*showEmptyOption={false}*/}
                  {/*items={permissionItems}*/}
                  {/*style={{*/}
                  {/*width: '100px'*/}
                  {/*}}*/}
                  {/*/>*/}
                  <div style={{ display: 'flex', flex: '0 0 20%' }}>
                    {this.props.canChangePermission && !this.props.disabled && (
                      <Popover
                        style={{ padding: '.5em' }}
                        isDefaultOpen={false}
                        position={'bottom-start'}
                        isOpen={this.state.isPermissionPopoverOpen}
                        usePortal={false}
                        // target={(<div>Hello</div>)}
                        renderTarget={ref2 => (
                          <PermissionCont
                            style={{ marginBottom: '.7em' }}
                            isPermissionActive={true}
                            ref={ref2}
                            onClick={() => {
                              this.setState({
                                isPermissionPopoverOpen: !this.state
                                  .isPermissionPopoverOpen,
                              });
                            }}
                          >
                            {this.state.permission === 'WRITE'
                              ? intl.formatMessage(commonLabels.write)
                              : intl.formatMessage(commonLabels.read)}
                            <MunikumIcons.ArrowDown
                              style={{
                                marginLeft: '.1em',
                                marginBottom: '-.1em',
                              }}
                            />
                          </PermissionCont>
                        )}
                      >
                        <div>
                          {permissionList.map((c, i) => {
                            const isActive = this.state.currentIndex === i;
                            return (
                              <PermissionList
                                key={i}
                                // onMouseEnter={() => {
                                //   this.setState({
                                //     currentIndex: i,
                                //   });
                                // }}
                                // onMouseLeave={() => {
                                //   this.setState({
                                //     currentIndex: -1,
                                //   });
                                // }}
                                style={{
                                  backgroundColor: isActive
                                    ? 'rgba(208, 211, 212, 0.7)'
                                    : '#fff',
                                }}
                                onClick={() => {
                                  this.setState({
                                    isPermissionPopoverOpen: false,
                                    permission: c.value,
                                  });
                                }}
                              >
                                {c.name}
                              </PermissionList>
                            );
                          })}
                        </div>
                      </Popover>
                    )}
                    {!this.props.canChangePermission && (
                      <PermissionCont
                        style={{ marginTop: '0' }}
                        isPermissionActive={true}
                      >
                        Skrivetilgang
                      </PermissionCont>
                    )}
                  </div>
                </PermissionCont>

                {/*<AddDiv onClick={this.handleAddMember}>*/}
                {/*<MunikumIcons.Add*/}
                {/*color={'#FF5C39'}*/}
                {/*style={{*/}
                {/*height: '1.6em',*/}
                {/*width: '1.6em',*/}
                {/*marginLeft: '8px',*/}
                {/*}}*/}
                {/*/>*/}
                {/*</AddDiv>*/}
              </AddMemberContentCont>
            </AddMemmbersContainer>
          );
        }}
      >
        <div>
          <MembersQuery
            query={
              this.props.showOnlyPerson
                ? FIND_PERSONS
                : FIND_PERSONS_ORGANIZATIONS_AND_GROUPS
            }
            variables={{ query: this.state.searchValue }}
            onCompleted={(data: FindPersonsOrganizationsAndGroupsQuery) => {
              let totalSearchResult = [];
              data.search.persons.forEach(p => {
                totalSearchResult.push(p);
              });

              if (!this.props.showOnlyPerson) {
                data.search.groups.forEach(g => {
                  totalSearchResult.push(g);
                });
                data.search.organizationGroups.forEach(o => {
                  totalSearchResult.push(o);
                });
                data.search.organizations.forEach(org => {
                  totalSearchResult.push(org);
                });
              }

              // this.setState({
              //   searchResult: totalSearchResult,
              // });
            }}
            children={(
              result: QueryResult<
                FindPersonsOrganizationsAndGroupsQuery,
                FindPersonsOrganizationsAndGroupsVariables
              >
            ) => {
              return (
                <ResultContainer ref={this.resultContRef}>
                  {result.data.search &&
                    result.data.search.persons.map((person: Person, i) => {
                      const isActive = this.state.selectedIndex === i;
                      return (
                        <LiDiv
                          key={i}
                          style={{
                            backgroundColor: isActive ? '#00B0B9' : '#fff',
                          }}
                          onMouseEnter={() => {
                            this.setState({
                              selectedIndex: i,
                            });
                          }}
                          onMouseLeave={() => {
                            this.setState({
                              selectedIndex: -1,
                            });
                          }}
                          onClick={() => {
                            this.handleClickMember(person);
                          }}
                        >
                          {' '}
                          {person.name}
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div
                              style={{
                                marginRight: '1em',
                                alignItems: 'center',
                              }}
                            >
                              {person.organization && person.organization.name}{' '}
                            </div>
                            {person.organization &&
                              person.organization.organizationImage && (
                                <StaticImage
                                  key={i}
                                  width={21}
                                  height={21}
                                  type={IStaticImageType.ORGANIZATION}
                                  filename={
                                    person.organization.organizationImage
                                  }
                                >
                                  {' '}
                                </StaticImage>
                              )}{' '}
                          </div>
                        </LiDiv>
                      );
                    })}
                  {!this.props.showOnlyPerson && (
                    <>
                      {result.data.search &&
                        result.data.search.groups.map(
                          (group: PersonGroup, i) => {
                            const isActive =
                              this.state.selectedIndex ===
                              i + result.data.search.persons.length;
                            return (
                              <LiDiv
                                key={i}
                                style={{
                                  backgroundColor: isActive
                                    ? '#00B0B9'
                                    : '#fff',
                                }}
                                onClick={() => {
                                  this.handleClickGroup(group);
                                }}
                                onMouseEnter={() => {
                                  this.setState(
                                    {
                                      selectedIndex:
                                        i + result.data.search.persons.length,
                                    },
                                    () => {}
                                  );
                                }}
                                onMouseLeave={() => {
                                  this.setState({
                                    selectedIndex: -1,
                                  });
                                }}
                              >
                                {' '}
                                {group.title}
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <MunikumIcons.Topic
                                    style={{
                                      width: '1.6em',
                                      height: '1.6em',
                                    }}
                                  />
                                </div>
                              </LiDiv>
                            );
                          }
                        )}
                      {result.data.search &&
                        result.data.search.organizationGroups.map(
                          (orgGroup: OrganizationGroup, i) => {
                            const isActive =
                              this.state.selectedIndex ===
                              i +
                                result.data.search.groups.length +
                                result.data.search.persons.length;
                            return (
                              <LiDiv
                                key={i}
                                style={{
                                  backgroundColor: isActive
                                    ? '#00B0B9'
                                    : '#fff',
                                }}
                                onClick={() => {
                                  this.handleClickOrgGroup(orgGroup);
                                }}
                                onMouseEnter={() => {
                                  this.setState(
                                    {
                                      selectedIndex:
                                        i +
                                        result.data.search.groups.length +
                                        result.data.search.persons.length,
                                    },
                                    () => {}
                                  );
                                }}
                                onMouseLeave={() => {
                                  this.setState({
                                    selectedIndex: -1,
                                  });
                                }}
                              >
                                {' '}
                                {orgGroup.title}
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <MunikumIcons.KostraEducation
                                    style={{
                                      width: '1.6em',
                                      height: '1.6em',
                                    }}
                                  />
                                </div>
                              </LiDiv>
                            );
                          }
                        )}
                      {result.data.search &&
                        result.data.search.organizations.map(
                          (org: Organization, i) => {
                            const isActive =
                              this.state.selectedIndex ===
                              i +
                                result.data.search.groups.length +
                                result.data.search.persons.length +
                                result.data.search.organizationGroups.length;
                            return (
                              <LiDiv
                                key={i}
                                style={{
                                  backgroundColor: isActive
                                    ? '#00B0B9'
                                    : '#fff',
                                }}
                                onClick={() => {
                                  this.handleClickOrganizations(org);
                                }}
                                onMouseEnter={() => {
                                  this.setState(
                                    {
                                      selectedIndex:
                                        i +
                                        result.data.search.groups.length +
                                        result.data.search.persons.length +
                                        result.data.search.organizationGroups
                                          .length,
                                    },
                                    () => {}
                                  );
                                }}
                                onMouseLeave={() => {
                                  this.setState({
                                    selectedIndex: -1,
                                  });
                                }}
                              >
                                {' '}
                                {org.name}
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {org.organizationImage && (
                                    <StaticImage
                                      key={i}
                                      width={21}
                                      height={21}
                                      type={IStaticImageType.ORGANIZATION}
                                      filename={org.organizationImage}
                                    >
                                      {' '}
                                    </StaticImage>
                                  )}{' '}
                                </div>
                              </LiDiv>
                            );
                          }
                        )}
                    </>
                  )}
                </ResultContainer>
              );
            }}
          />
        </div>
      </Popover>
    );
  }
}

export const AnnualCycleAddMembersCard = injectIntl(AddMemberComp);
