import styled from 'styled-components';
import { Colors } from '../../common';
import { sizes } from '../../common/media';

export const Page = styled.div`
  max-width: 84.125em;
  margin: 0 auto;
`;

export const PageTop = styled.div`
  display: block;
  position: relative;
  margin: 0 auto 0.5em 0;

  @media (min-width: ${sizes.medium}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PageTopPure = styled.div`
  position: relative;
  margin: 0 auto 0.5em 0;
`;

export const PageTopSplitContainer = styled.div`
  display: block;

  @media (min-width: ${sizes.medium}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PageTitle = styled.div`
  //background-color: rebeccapurple;
  color: ${Colors.BLACK};
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  font-size: 2em;
  word-wrap: break-word;
  hyphens: auto;
  max-width: 40em;
  overflow: visible; // NOTE: needs to be visible to enable content editable!
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 1px; /* IE11 Fix */
`;

export const PageLastUpdated = styled.div`
  font-family: Lato, sans-serif;
  font-size: 0.875em;
  color: #333333;
  margin-top: 0.5em;
  margin-bottom: 1.5em;
`;
