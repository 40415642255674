import * as React from 'react';
import { CSSProperties, RefObject } from 'react';
import styled from 'styled-components';
import { hideOutline, safeInvokeDeprecated } from '../common';
import { MunikumKeys } from '../common/keys';

const MyCustomInput = styled.input`
  width: 100%;
  height: 100%;

  border: none;
  ${hideOutline()};
`;

const InputDiv = styled.div`
  width: 100%;
  color: #000000;
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  margin-left: 0.25em;
`;
const ItemContainer = styled.div`
  border-radius: 3px;
  background-color: ${(props: { disabled: boolean }) =>
    props.disabled ? '#cccccc' : '#fff'};
  box-sizing: border-box;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
  height: 2.25em; // 36px
  width: 30em; // can be overridden using style

  display: flex;

  align-items: center;
  justify-content: space-between;
  padding: 0.5em;

  overflow: hidden;
`;

export const SubmitCont = styled.div`
  opacity: 0.5;
  color: #000000;
  font-family: Lato, sans-serif;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: right;

  &:hover {
    cursor: pointer;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  flex: 0 0 auto;
`;

interface ICreateCarsProps {
  placeholder?: string;
  style?: CSSProperties;
  onSubmit?: (title: string) => void;
  onBlur?: (e: any) => void;
  name?: string;
  disabled: boolean;
}

interface ICreateCardState {
  title: string;
}

export class CreateCard extends React.PureComponent<
  ICreateCarsProps,
  ICreateCardState
> {
  private myRef: RefObject<HTMLInputElement>;

  constructor(props: ICreateCarsProps) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      title: '',
    };
  }

  handleTodoChange = (e: any) => {
    this.setState({
      title: e.target.value,
    });
  };

  handleKeyDown = (event: any) => {
    if (this.props.disabled) {
      return;
    }

    // console.log('createcard.handleKeyDown', event);
    if (event.keyCode === MunikumKeys.ENTER) {
      event.preventDefault();
      event.stopPropagation();

      if (this.state.title.length > 0) {
        safeInvokeDeprecated(this.props.onSubmit, this.state.title);
        this.setState({
          title: '',
        });
      }
    } else if (event.keyCode === MunikumKeys.ESCAPE) {
      // console.log('CANCEL escape.. not working?!?');

      // TODO: this doesnt cancel window.onkeyup.. but it should??
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        title: '',
      });
    }
  };

  submitEvent = () => {
    safeInvokeDeprecated(this.props.onSubmit, this.state.title);
    this.setState({
      title: '',
    });
  };

  render() {
    return (
      <ItemContainer
        disabled={this.props.disabled}
        onClick={() => {
          if (this.myRef.current) {
            this.myRef.current.focus();
          }
        }}
        style={this.props.style}
      >
        {!this.props.disabled && (
          <InputDiv>
            <MyCustomInput
              ref={this.myRef}
              name={this.props.name}
              onKeyDown={this.handleKeyDown}
              style={{ border: 'none' }}
              placeholder={this.props.placeholder}
              value={this.state.title}
              onChange={this.handleTodoChange}
              onBlur={this.props.onBlur}
            />
          </InputDiv>
        )}

        <SubmitCont onClick={this.submitEvent}>Enter</SubmitCont>
      </ItemContainer>
    );
  }
}
