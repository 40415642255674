import { getApiRequest, parsedResponse } from './api';
import { IEntity } from './models/entity';

export interface IKostraFunctionGroup extends IEntity {}

export interface IKostraFunction extends IEntity {
  title: string;
  kid: number;
}
export async function getKostraFunctions(): Promise<
  ReadonlyArray<IKostraFunction>
> {
  const response = await getApiRequest('/kostra/function-list', 'GET');
  return await parsedResponse<ReadonlyArray<IKostraFunction>>(response);
}
