import React from 'react';
import styled from 'styled-components';
import { MunikumIcons } from '../common/icons';
import { ColorTheme, safeInvokeDeprecated } from '../common';

import { Button, TextField } from '../basic';
import { commonMessages } from '../language/commonMessages';

import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl';
import { Formik, FormikProps } from 'formik';

const TopDiv = styled.div`
  margin-bottom: 2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Cont = styled.div`
  width: 300px;
  padding: 2em;
  background-color: #fff;
`;
const IconDiv = styled.div`
  transition: 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }
`;
const Title = styled.div`
  color: #333333;
  font-family: Lato;
  font-size: 32px;
  font-weight: bold;
`;
const messages = defineMessages({
  ChangeAnnulCycleTitle: {
    id: 'CopyAnnualCycle.ChangeAnnulCycleTitle',
    defaultMessage: 'Change Annulcycle title',
  },
  CopyOF: {
    id: 'CopyAnnualCycle.CopyOF',
    defaultMessage: 'Copy of',
  },
  StartCopying: {
    id: 'CopyAnnualCycle.StartCopying',
    defaultMessage: 'Start copying',
  },
});

interface ICopyAnnualCycle {
  onClickCancel?: () => void;
  onSubmit?: (form: any) => void;
  initialTitle: string;
}
function CopyAnnualCycleComp(props: ICopyAnnualCycle & InjectedIntlProps) {
  return (
    <Formik
      initialValues={{
        title: '',
      }}
      onSubmit={() => {}}
      render={(formikProps: FormikProps<any>) => {
        return (
          <Cont>
            <TopDiv>
              <Title>Kopier årshjul</Title>
              {/*<IconDiv>*/}
              {/*<MunikumIcons.Cancel*/}
              {/*onClick={() => {*/}
              {/*safeInvoke(props.onClickCancel);*/}
              {/*}}*/}
              {/*/>*/}
              {/*</IconDiv>*/}
            </TopDiv>
            <TextField
              style={{ width: '100%' }}
              label={props.intl.formatMessage(messages.ChangeAnnulCycleTitle)}
              name={'title'}
              value={formikProps.values && formikProps.values.title}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              placeholder={
                props.intl.formatMessage(messages.CopyOF) +
                ' ' +
                props.initialTitle
              }
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: '2em',
              }}
            >
              <Button
                text={props.intl.formatMessage(commonMessages.cancel)}
                theme={ColorTheme.White}
                onClick={() => {
                  safeInvokeDeprecated(props.onClickCancel);
                }}
                style={{ marginLeft: '.5em' }}
              />
              <Button
                text={props.intl.formatMessage(messages.StartCopying)}
                theme={ColorTheme.Red}
                onClick={() => {
                  safeInvokeDeprecated(props.onSubmit, formikProps.values);
                }}
                style={{ marginLeft: '.5em' }}
                leftIcon={MunikumIcons.Save}
                disabled={formikProps.values.title === ''}
              />
            </div>
          </Cont>
        );
      }}
    />
  );
}
export const CopyAnnualCycle = injectIntl(CopyAnnualCycleComp);
