import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import update from 'immutability-helper';
import { RadarGraphType } from '../../../models/types';

export interface IRadarGraphData {
  sum?: number;
  name?: string;
  type?: number;
}

export interface IRadarGraphProps {
  data?: RadarGraphType[];
  width?: number;
  showLegend?: boolean;
  hideLabels?: boolean;
  padTriangle?: number;
  strokeWidth?: number;
  type?: number;
  style?: CSSProperties;
}

interface ITrigType {
  [index: number]: number;
}

interface ILegendDataType {
  color?: string;
  opacity?: number;
  style?: string;
  title?: string;
}

interface ILegendType {
  [index: number]: ILegendDataType;
}

interface ITriangleValueType {
  sum: number;
  value: number;
}

interface ITriangleType {
  values: ITriangleValueType[];
  hasValue: boolean;
}

interface ILabelGeoType {
  left: number;
  top: number;
}

interface IRadargraphState {
  triangles: ITriangleType[];
  values: {};
  hasValue: boolean;
  readonly maxValue: number;
  readonly legends: ILegendType;
  showLegend: boolean;
  hideLabels: boolean;
  weight: number;
  readonly valuePadding: number;
  triColor: string;
  triPadding: number;
  triPaddingOnLegend: number;
  exactlyEqual: boolean;
  cLabels: {
    big: string[];
    small: string[];
  };
  cLabelGeometrics: ILabelGeoType[];
  cLabelType: string;
  container: {
    width: number;
    height: number;
  };
  cDims: {
    top: number;
    left: number;
    width: number;
    height: number;
    padding: number;
  };
  shpDims: {
    triSide: number;
    triHeight: number;
    triMidDivDist: number;
  };
  cvsGeo: {
    triEnc: ITrigType[]; // Geometry of triangle on canvas
    triMidPoint: [number, number]; // Mid point of triangle on canvas
  };
}

interface IMainBoxProps {
  width: number;
  height: number;
}

interface ILegendProps {
  color?: string;
}

interface ITriangleProps {
  top: number;
  left: number;
}

interface ITriangleLabelsProps {
  width: number;
  height: number;
}

interface ITriangleLabelProps {
  top: number;
  left: number;
}

const MainBox = styled.div`
  float: left;
  position: relative;
  width: ${(p: IMainBoxProps) => p.width}px;
  height: ${(p: IMainBoxProps) => p.height}px;
`;

const Legend = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  width: 80px;
  height: 40px;
`;

const LegendItem = styled.div`
  height: 20px;
  width: 100%;
`;

const TriangleBox = styled.div`
  position: absolute;
  top: ${(p: ITriangleProps) => p.top}px;
  left: ${(p: ITriangleProps) => p.left}px;
  width: 100%;
  height: 100%;
`;

const TriangleLabels = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${(p: ITriangleLabelsProps) => p.width}px;
  height: ${(p: ITriangleLabelsProps) => p.height}px;
`;

const TriangleLabel = styled.div`
  position: absolute;
  top: ${(p: ITriangleLabelProps) => p.top}px;
  left: ${(p: ITriangleLabelProps) => p.left}px;
  height: 15px;
  font-family: Arial, Verdana, snas-serif;
  font-size: 11px;
  text-transform: uppercase;
`;

const ColorSpecifier = styled.div`
  float: left;
  margin-top: 5px;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  background-color: ${(p: ILegendProps) => p.color as string};
`;
const TitleSpecifier = styled.div`
  text-transform: capitalize;
`;

export class Radargraph extends React.PureComponent<
  IRadarGraphProps,
  IRadargraphState
> {
  private rgCanvas: HTMLCanvasElement | null;
  private ctx: CanvasRenderingContext2D | null;
  private settings: IRadargraphState;
  private baseSettings: IRadargraphState;
  private data: RadarGraphType[] | undefined;

  constructor(props: IRadarGraphProps) {
    super(props);

    this.settings = {
      triangles: [],
      values: {},
      hasValue: false,
      maxValue: 5,
      exactlyEqual: false,
      legends: [
        {
          color: '#fe5e3b',
          opacity: 1,
          style: 'solid',
          title: 'Innsats',
        },
        {
          color: '#00b0b9',
          opacity: 1,
          style: 'dotted',
          title: 'Gevinst',
        },
      ],
      cLabels: {
        big: ['Økonomi', 'Fag', 'Organisasjon'],
        small: ['Øk.', 'Fag', 'Org.'],
      },
      cLabelType: 'big',
      cLabelGeometrics: [
        {
          left: 0,
          top: 0,
        },
        {
          left: 0,
          top: 0,
        },
        {
          left: 0,
          top: 0,
        },
      ],
      showLegend: this.props.showLegend || false,
      hideLabels: this.props.hideLabels || false,
      weight: this.props.strokeWidth || 3,
      valuePadding: 2,
      triPadding: this.props.padTriangle ? this.props.padTriangle : 0,
      triPaddingOnLegend: 40,
      triColor: '#555',
      container: {
        width: this.props.width as number,
        height: 0,
      },
      cDims: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        padding: 20,
      },
      shpDims: {
        triSide: 0,
        triHeight: 0,
        triMidDivDist: 0,
      },
      cvsGeo: {
        triEnc: [],
        triMidPoint: [0, 0],
      },
    };

    this.data = props.data;

    this.ctx = null;

    // Fix settings if triangle is small.
    if ((this.props.width as number) < 200) {
      // If thickness is big.
      if (this.settings.weight > 2) {
        this.settings.weight = this.settings.weight / 2;
      }

      // Change label type.
      this.settings.cLabelType = 'small';
    }

    this.calculateCanvas();
    this.baseSettings = this.settings;
    this.calculateValues();
  }

  componentDidMount() {
    // console.log('RG: componentDidMount', this.state.container.height);
    if (this.settings.container && this.settings.container.height !== 0) {
      this.connectCanvas();
      this.startShapeRendering();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: IRadarGraphProps) {
    if (nextProps.data) {
      this.data = nextProps.data;
      this.settings = this.baseSettings;
      this.calculateValues();
      this.clearCanvas();
      this.startShapeRendering();
    }
  }

  startShapeRendering() {
    this.renderMainShape();
  }

  connectCanvas() {
    if (this.rgCanvas) {
      this.ctx = this.rgCanvas.getContext('2d');
    }
  }

  calculateValues(settings?: IRadargraphState) {
    // console.log('RG: calculateValues');

    if (!this.data || !(this.data.length === 3 || this.data.length === 6)) {
      return;
    }

    const hackyParamMap = {
      Økonomi: 0,
      'Fag, tenestetilbod, kvalitet': 1,
      Organisasjon: 2,
    };

    if (this.data && this.data.length > 0) {
      let newTriangles = this.settings.triangles.slice();
      let hasValue = false;

      for (let i of this.data) {
        // Filter on data type set in props.
        if (
          typeof this.props.type !== 'undefined' &&
          typeof i.type !== 'undefined' &&
          i.type !== this.props.type
        ) {
          continue;
        }

        if (typeof i.type !== 'undefined' && !newTriangles[i.type]) {
          const newValueSet = {
            values: [],
            hasValue: false,
          };

          newTriangles[i.type] = newValueSet;
        }
        if (typeof i.type !== 'undefined' && i.sum != null && i.name != null) {
          const value = i.sum === 0 ? 0 : i.sum / this.settings.maxValue;

          const newValueSet = {
            sum: i.sum,
            value: value,
          };

          const triIdx = hackyParamMap[i.name];

          newTriangles[i.type].values[triIdx] = newValueSet;
          newTriangles[i.type].hasValue = true;
          hasValue = true;
        }
      }

      // Hack to check if 2 triangle value lists are the same.
      let exactlyEqual = this.settings.exactlyEqual;
      if (
        this.settings.triangles[0] &&
        this.settings.triangles[1] &&
        this.arraysEqual(
          this.settings.triangles[0].values,
          this.settings.triangles[1].values
        )
      ) {
        exactlyEqual = true;
      }

      this.settings = update(this.settings, {
        triangles: { $set: newTriangles },
        hasValue: { $set: hasValue },
        exactlyEqual: { $set: exactlyEqual },
      });
    }
  }

  arraysEqual(arr1: ITriangleValueType[], arr2: ITriangleValueType[]) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (var i = arr1.length; i--; ) {
      if (arr1[i].value !== arr2[i].value) {
        return false;
      }
    }

    return true;
  }

  calculateCanvas() {
    // Setting triangle padding if legend should be shown and triangle padding has not been explicitly set already.
    let newTriPadding = this.settings.triPadding;
    if (newTriPadding === 0 && this.settings.showLegend) {
      newTriPadding = this.settings.triPaddingOnLegend;
    }

    const newCDimsWidth = this.settings.container.width - newTriPadding;
    const newShpDimsTriSide = newCDimsWidth - 2 * this.settings.cDims.padding;
    const newShpDimsTriHeight = Math.sqrt(
      Math.pow(newShpDimsTriSide, 2) - Math.pow(newShpDimsTriSide / 2, 2)
    );
    const newShpDimsTriMidDivDist = newShpDimsTriSide / Math.sqrt(3);
    const newCDimsHeight = Math.round(
      newShpDimsTriHeight + 2 * this.settings.cDims.padding
    );
    // Set container height to the same ratio as the triangle.
    const newContainerHeight =
      (newCDimsHeight / newCDimsWidth) * this.settings.container.width;
    // Center triangle position, considering paddings.
    const newCDimsLeft = newTriPadding > 0 ? Math.floor(newTriPadding / 2) : 0;
    const newCDimsTop = newTriPadding > 0 ? Math.floor(newTriPadding / 2) : 0;
    // Calculate label positions.
    const newCLabelGeometrics = [
      {
        left:
          this.settings.cLabelType === 'big'
            ? newCDimsWidth / 2 - 25
            : newCDimsWidth / 2 - 12,
        top: 5,
      },
      {
        left: newCDimsWidth - this.settings.cDims.padding - 20,
        top: newCDimsHeight - 15,
      },
      {
        left:
          this.settings.cLabelType === 'big'
            ? this.settings.cDims.padding * 2 - 20
            : this.settings.cDims.padding,
        top: newCDimsHeight - 15,
      },
    ];

    this.settings = update(this.settings, {
      triPadding: { $set: newTriPadding },
      cDims: {
        width: { $set: newCDimsWidth },
        height: { $set: newCDimsHeight },
        left: { $set: newCDimsLeft },
        top: { $set: newCDimsTop },
      },
      shpDims: {
        triSide: { $set: newShpDimsTriSide },
        triHeight: { $set: newShpDimsTriHeight },
        triMidDivDist: { $set: newShpDimsTriMidDivDist },
      },
      container: {
        height: { $set: newContainerHeight },
      },
      cLabelGeometrics: { $set: newCLabelGeometrics },
    });
  }

  clearCanvas() {
    if (!this.ctx === null) {
      return;
    }

    if (this.ctx && this.rgCanvas !== null) {
      this.ctx.clearRect(0, 0, this.rgCanvas.width, this.rgCanvas.height);
    }
  }

  drawMainShape() {
    // Draw outer triangle.
    if (this.ctx) {
      this.ctx.beginPath();
      this.ctx.moveTo(
        this.settings.cvsGeo.triEnc[0][0],
        this.settings.cvsGeo.triEnc[0][1]
      );
      this.ctx.lineTo(
        this.settings.cvsGeo.triEnc[1][0],
        this.settings.cvsGeo.triEnc[1][1]
      );
      this.ctx.lineTo(
        this.settings.cvsGeo.triEnc[2][0],
        this.settings.cvsGeo.triEnc[2][1]
      );
      this.ctx.lineTo(
        this.settings.cvsGeo.triEnc[0][0],
        this.settings.cvsGeo.triEnc[0][1]
      );
      this.ctx.strokeStyle = this.settings.triColor;
      // this.ctx.strokeStyle = '#000';
      this.ctx.lineWidth = 1;
      this.ctx.setLineDash([1, 0]);
      this.ctx.stroke();
      this.ctx.closePath();

      // Draw inner dividers.
      this.ctx.beginPath();
      this.ctx.moveTo(
        this.settings.cvsGeo.triMidPoint[0],
        this.settings.cvsGeo.triMidPoint[1]
      );
      this.ctx.lineTo(
        this.settings.cvsGeo.triEnc[0][0],
        this.settings.cvsGeo.triEnc[0][1]
      );
      this.ctx.moveTo(
        this.settings.cvsGeo.triMidPoint[0],
        this.settings.cvsGeo.triMidPoint[1]
      );
      this.ctx.lineTo(
        this.settings.cvsGeo.triEnc[1][0],
        this.settings.cvsGeo.triEnc[1][1]
      );
      this.ctx.moveTo(
        this.settings.cvsGeo.triMidPoint[0],
        this.settings.cvsGeo.triMidPoint[1]
      );
      this.ctx.lineTo(
        this.settings.cvsGeo.triEnc[2][0],
        this.settings.cvsGeo.triEnc[2][1]
      );
      this.ctx.strokeStyle = this.settings.triColor;
      this.ctx.lineWidth = 1;
      this.ctx.setLineDash([2, 3]);
      this.ctx.stroke();
      this.ctx.closePath();

      // Start drawing triangle shapes.
      for (let i in this.settings.triangles) {
        if (this.settings.triangles[i] && this.settings.triangles[i].hasValue) {
          this.createValueShape(i);
        }
      }
    }

    // Draw texts.
    /* this.settings.ctx.font = '10px Arial';
    this.settings.ctx.fillStyle = '#000';
    this.settings.ctx.textAlign = 'center';
    this.settings.ctx.fillText(this.settings.cLabels[this.settings.cLabelType][0], this.settings.cLabelGeometrics[0].left, this.settings.cLabelGeometrics[0].top);
    this.settings.ctx.fillText(this.settings.cLabels[this.settings.cLabelType][1], this.settings.cLabelGeometrics[1].left, this.settings.cLabelGeometrics[1].top);
    this.settings.ctx.fillText(this.settings.cLabels[this.settings.cLabelType][2], this.settings.cLabelGeometrics[2].left, this.settings.cLabelGeometrics[2].top); */
  }

  renderMainShape() {
    if (this.ctx === null) {
      return;
    }

    // Define edges of triangle on canvas.
    const newTriEnc = [
      [this.settings.cDims.width / 2, this.settings.cDims.padding],
      [
        this.settings.cDims.width - this.settings.cDims.padding,
        this.settings.cDims.height - this.settings.cDims.padding,
      ],
      [
        this.settings.cDims.padding,
        this.settings.cDims.height - this.settings.cDims.padding,
      ],
    ];

    this.settings = update(this.settings, {
      cvsGeo: {
        triEnc: { $set: newTriEnc },
        triMidPoint: {
          $set: [
            newTriEnc[0][0],
            this.settings.cDims.padding + this.settings.shpDims.triMidDivDist,
          ],
        },
      },
    });
    this.drawMainShape();
  }

  createValueShape(triid: string) {
    if (this.ctx === null) {
      return;
    }

    let color = this.settings.legends[triid];
    let weight = this.settings.weight;
    let style = [1, 0];
    if (this.settings.exactlyEqual && color.style === 'dotted') {
      style = [10, 10];
    }

    // let offset = -2 * weight * parseInt(triid, 10);
    /*let offset = parseInt(triid, 10)
      ? weight * parseInt(triid, 10) * this.settings.offsetDelta
      : 0;*/
    let offset = 0;

    let t = [
      this.settings.triangles[triid].values[0].value - offset,
      this.settings.triangles[triid].values[1].value - offset,
      this.settings.triangles[triid].values[2].value - offset,
    ];

    let valTri = [
      [
        ((1 - t[0]) * this.settings.shpDims.triSide) / 2 +
          (t[0] * this.settings.shpDims.triSide) / 2,
        (1 - t[0]) * this.settings.shpDims.triMidDivDist +
          t[0] * 0 +
          2 * this.settings.valuePadding,
      ],
      [
        ((1 - t[1]) * this.settings.shpDims.triSide) / 2 +
          t[1] * this.settings.shpDims.triSide -
          2 * this.settings.valuePadding,
        (1 - t[1]) * this.settings.shpDims.triMidDivDist +
          t[1] * this.settings.shpDims.triHeight -
          this.settings.valuePadding,
      ],
      [
        ((1 - t[2]) * this.settings.shpDims.triSide) / 2 +
          t[2] * 0 +
          2 * this.settings.valuePadding,
        (1 - t[2]) * this.settings.shpDims.triMidDivDist +
          t[2] * this.settings.shpDims.triHeight -
          this.settings.valuePadding,
      ],
    ];

    if (this.ctx) {
      this.ctx.beginPath();
      this.ctx.moveTo(
        this.settings.cDims.padding + valTri[0][0],
        this.settings.cDims.padding + valTri[0][1]
      );
      this.ctx.lineTo(
        this.settings.cDims.padding + valTri[1][0],
        this.settings.cDims.padding + valTri[1][1]
      );
      this.ctx.lineTo(
        this.settings.cDims.padding + valTri[2][0],
        this.settings.cDims.padding + valTri[2][1]
      );
      this.ctx.lineTo(
        this.settings.cDims.padding + valTri[0][0],
        this.settings.cDims.padding + valTri[0][1]
      );
      // this.settings.ctx.strokeStyle = '#f00';
      this.ctx.globalAlpha = color.opacity;
      this.ctx.strokeStyle = color.color;
      this.ctx.lineWidth = 2 * weight;
      this.ctx.setLineDash(style);
      this.ctx.stroke();
      this.ctx.globalAlpha = 1;
      this.ctx.closePath();
    }
  }

  render() {
    // console.log('render radargraph');

    const { style } = this.props;

    return (
      this.settings &&
      this.settings.container && (
        <MainBox
          width={this.settings.container.width}
          height={this.settings.container.height}
          style={style}
        >
          {this.settings.showLegend && (
            <Legend>
              <LegendItem>
                <ColorSpecifier color={this.settings.legends[0].color} />
                <TitleSpecifier>
                  {this.settings.legends[0].title}
                </TitleSpecifier>
              </LegendItem>
              <LegendItem>
                <ColorSpecifier color={this.settings.legends[1].color} />
                <TitleSpecifier>
                  {this.settings.legends[1].title}
                </TitleSpecifier>
              </LegendItem>
            </Legend>
          )}
          <TriangleBox
            left={this.settings.cDims.left}
            top={this.settings.cDims.top}
          >
            {!this.settings.hideLabels && (
              <TriangleLabels
                width={this.settings.cDims.width}
                height={this.settings.cDims.height}
              >
                <TriangleLabel
                  left={this.settings.cLabelGeometrics[0].left}
                  top={this.settings.cLabelGeometrics[0].top}
                >
                  {this.settings.cLabels[this.settings.cLabelType][0]}
                </TriangleLabel>
                <TriangleLabel
                  left={this.settings.cLabelGeometrics[1].left}
                  top={this.settings.cLabelGeometrics[1].top}
                >
                  {this.settings.cLabels[this.settings.cLabelType][1]}
                </TriangleLabel>
                <TriangleLabel
                  left={this.settings.cLabelGeometrics[2].left}
                  top={this.settings.cLabelGeometrics[2].top}
                >
                  {this.settings.cLabels[this.settings.cLabelType][2]}
                </TriangleLabel>
              </TriangleLabels>
            )}
            <canvas
              ref={canvas => {
                this.rgCanvas = canvas;
              }}
              width={this.settings.cDims.width}
              height={this.settings.cDims.height}
            />
          </TriangleBox>
        </MainBox>
      )
    );
  }
}
