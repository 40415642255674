import { InjectedIntlProps } from 'react-intl';
import {
  AccessEnum,
  ContentStatus,
  CreateCalendarRingMutationVariables,
  GetInstanceAndTaskEvents,
  GetTaskQuery,
  GetTaskVariables,
  Person,
  PersonAccess,
  TaskTypeEnum,
  UpdateEventInput,
  UpdateEventInstanceInput,
  UpdateTaskInput,
  UpdateTaskMutation,
  UserFile,
  YearWheelRingQuery,
  YearWheelRingVariables,
} from '../../../models/types';
import {
  IRepetitionData,
  RepetitionPicker,
} from '../../basic/RepetitionPicker/RepetitionPicker';
import * as React from 'react';
import RRule from 'rrule';
import Mutation, { FetchResult } from 'react-apollo/Mutation';
import {
  CREATE_CALENDAR_RING,
  YEAR_WHEEL,
  YEAR_WHEEL_CALENDAR_RING,
  YEAR_WHEEL_MAGIC_RING,
} from '../../../containers/AnnualCycle/calendar-queries';
import { FieldArray, Formik, FormikProps } from 'formik';
import {
  Colors,
  ColorTheme,
  isReallyEmpty,
  MessageType,
  safeInvoke,
  safeInvokeDeprecated,
} from '../../common';
import moment from 'moment';
import {
  getReminderDate,
  getReminderIntervalDropdown,
  ReminderDropdown,
  ReminderOptions,
} from '../ReminderDropdown';
import { BreadCrumb, BreadCrumbs } from '../BreadCrumbs';
import { MunikumIcons } from '../../common/icons';
import { commonLabels } from '../../language/commonLabels';
import { FieldGroup } from '../../basic/FieldGroup/FieldGroup';
import { FormLabel } from '../../basic/FormLabel/FormLabel';
import { PageTitle } from '../../layout/Page/Page';
import { MuContentEditable } from '../../basic/MuEditor/MuContentEditable';
import {
  Button,
  TextField,
  ValidationMessage,
  ValidationSummary,
} from '../../basic';
import { ResponibleContainer } from '../CreateEventForm';
import {
  AnnualCycleAddMembersCard,
  SmallText,
} from '../AnnualCycleAddMembersCard';
import { messages } from '../messages';
import { DateTimePicker } from '../../basic/DateTimePicker/DateTimePicker';
import { H4 } from '../../basic/Structural/typography';
import { CreateCard } from '../CreateCard';
import { TodoCard } from '../TodoCard';
import { commonMessages } from '../../language/commonMessages';
import {
  CREATE_TODO_TEMPLATE,
  CreateTodoTemplateMutationComp,
  GET_INSTANCE_AND_TASK_QUERY,
  GET_TASK_QUERY,
  UPDATE_EVENT,
  UPDATE_EVENT_INSTANCE,
  UPDATE_TASK,
  UPDATE_TODO,
  UPDATE_TODO_TEMPLATE,
  UpdateTodoTemplateMutationComp,
} from './eventQueries';
import styled from 'styled-components';
import { CalendarsDropdown } from '../CalendarsDropdown';
import { SelectMode } from '../../basic/DropDownSelectUltimate/UltimateDropdown';
import { myApolloClient } from '../../../graphql/apolloClientFactory';
import { DataProxy } from 'apollo-cache';
import { Tooltip } from '../../basic/Tooltip/Tooltip';
import _ from 'lodash';
import { Toaster } from '../../basic/Toaster/Toaster';
import { uploadFile } from '../../../services/fileservice';
import { SET_STATUS_FILES } from './InstanceForm';
import { FileInfoCard } from '../../basic/UserFile/FileInfoCard';
import { Overlay } from '../../basic/Overlay/Overlay';
import { MuTextField } from '../../basic/MuEditor/MuTextField';
import { convertFromRaw, convertToRaw } from 'draft-js';
import { MuField } from '../../basic/MuEditor/form/MuField';

const Left = styled.div`
  flex: 0 0 auto; // this doesnt stop it from growing??
  max-width: 490px;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #ffffff;
  border-top: 1px solid rgba(208, 211, 212, 0.7);
  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  padding: 1.875em 1.5em 1.875em 1.875em;
`;

const Right = styled.div`
  flex: 1 1 auto;
  background-color: #efefef;
  border-top: 1px solid rgba(208, 211, 212, 0.7);
  border-right: 1px solid rgba(208, 211, 212, 0.7);
  border-bottom: 1px solid rgba(208, 211, 212, 0.7);
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  padding: 3.5em 1.875em 1.875em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Butts = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
`;

interface IEventFormProps extends InjectedIntlProps {
  taskId: string;
  year: number;
  onClose: (forceTotalReload: boolean) => void;
  item: GetInstanceAndTaskEvents;
  userHash: string;
  taskTitle: string;
  me?: any;
  taskType: TaskTypeEnum;
  taskSelectedCalendar: any;
  isRepetitionOn: boolean;
  activeYearwheels: Array<any>;
  isCalendarPage: boolean;
  onDelete?: () => void;
  isAssignedTask: boolean;
  // calendarLookup: Array<FieldsOnCalendarLookupFragment>;
}

interface IEventFormState {
  repData: IRepetitionData;
  selectedCalendar: any;
  copyEndDate: Date;
  filesToDelete: Array<any>;
  files: Array<any>;
  reminderItem: ReminderOptions;
  isFileInfoCardVisible: boolean;
  fileInfoId: string | undefined;
  fileAccess: AccessEnum;
}

class EventForm extends React.PureComponent<IEventFormProps, IEventFormState> {
  private toastRef: React.RefObject<Toaster> = null;
  constructor(props: IEventFormProps) {
    super(props);
    this.toastRef = React.createRef();

    // TODO: get frqquency from repetitiondefinition
    const myFreq = RRule.parseString(props.item.repetitionDefinition || '');
    // console.log(typeof myFreq.freq);

    // console.log('test thomas', myFreq.freq, props.item.repetitionDefinition);

    this.state = {
      filesToDelete: [],
      files: this.props.item.files,
      isFileInfoCardVisible: false,
      fileInfoId: undefined,
      reminderItem: ReminderOptions.NONE,
      fileAccess: AccessEnum.READ,
      repData: {
        freq: myFreq.freq,
        repetitionDefinition: props.item.repetitionDefinition || undefined,
        startRepetitionDate: props.item.startRepetitionDate || undefined,
        endRepetitionDate: props.item.endRepetitionDate || undefined,
      },
      copyEndDate: new Date(),
      selectedCalendar: props.taskSelectedCalendar,
    };
  }
  componentDidMount() {
    this.setState(
      {
        copyEndDate: _.cloneDeep(this.props.item.endDate),
      },
      () => {}
    );
  }

  render() {
    const { intl, item, year } = this.props;
    // console.log(this.state.selectedCalendar, 'mathias');
    let isDescriptionDraftJsFormat = false;
    try {
      convertFromRaw(JSON.parse(item.description)).getPlainText();
      isDescriptionDraftJsFormat = true;
    } catch (e) {
      isDescriptionDraftJsFormat = false;
    }
    let doIhaveAccess = false;
    const publishedTodos: Array<any> = [];
    this.props.item.todoTemplate.forEach(ccc => {
      if (ccc.status === ContentStatus.PUBLISHED) {
        publishedTodos.push(ccc);
      }
    });

    return (
      <Mutation
        mutation={UPDATE_EVENT}
        awaitRefetchQueries={true} // WAIT FOR UPDATED CORRECT DATA ? => safe but slow..
        refetchQueries={[
          {
            query: YEAR_WHEEL,
            variables: {
              year: this.props.year,
              person: this.props.userHash,
            },
          },
          {
            query: YEAR_WHEEL_MAGIC_RING,
            variables: {
              year: year,
            },
          },
          {
            query: YEAR_WHEEL_CALENDAR_RING,
            variables: {
              calendarId: this.state.selectedCalendar.id,
              year: year,
            },
          },
          {
            query: YEAR_WHEEL_CALENDAR_RING,
            variables: {
              calendarId: this.props.taskSelectedCalendar.id,
              year: year,
            },
          },
        ]}
      >
        {(updateEventFunc, { data, loading, called, error }) => {
          return (
            <div style={{ display: 'flex' }}>
              {/*<Toaster ref={this.toastRef} theme={ToastType.LOADING} />*/}
              <Formik
                onSubmit={async (values: any, formikBag: any) => {
                  // FIRST: update TASK if we changed task.calendar!
                  this.state.filesToDelete.forEach(c => {
                    myApolloClient.mutate({
                      mutation: SET_STATUS_FILES,
                      variables: {
                        input: {
                          id: +c,
                          status: ContentStatus.OBSOLETE,
                        },
                      },
                    });
                  });
                  if (
                    this.state.selectedCalendar.id !==
                    this.props.taskSelectedCalendar.id
                  ) {
                    try {
                      // console.log(
                      //   'you are brave and changed calendar on task!!!! fetching task..'
                      // );
                      //
                      // const cacheRes = await myApolloClient.readQuery<
                      //   YearWheelRingQuery,
                      //   YearWheelRingVariables
                      //   >({
                      //   query: YEAR_WHEEL_CALENDAR_RING,
                      //   variables: {
                      //     calendarId: this.props.taskSelectedCalendar.id,
                      //     year: this.props.year,
                      //   },
                      // });

                      // REMOVE stuff that will soon be invalid from apollo cache:
                      // cacheRes.calendar.tasks.forEach(task => {
                      //   task.events.forEach(ev => {
                      //     ev.instances.forEach(instagram => {
                      //       await myApolloClient.writeQuery()
                      //     });
                      //   });
                      // });

                      // custom apollo cahce func.. . in stead..

                      const result = await myApolloClient.query<
                        GetTaskQuery,
                        GetTaskVariables
                      >({
                        query: GET_TASK_QUERY, // NO EVENT OR INSTANCES!!
                        // query: GET_TASK_FOR_INVALID, // events here, but slow. . .
                        variables: {
                          taskId: this.props.taskId,
                        },
                        fetchPolicy: 'network-only',
                      });

                      const input3: UpdateTaskInput = {
                        calendar: this.state.selectedCalendar.id,
                        description: result.data.task.description,
                        id: this.props.taskId,
                        status: ContentStatus.PUBLISHED, // result.data.task.status,
                        title: result.data.task.title,
                        type: result.data.task.type,
                      };
                      // console.log('updating task...', input);
                      // Now BOLLE sohuld have updated task, events, instances? => that should update data in cache??!
                      const bolle = await myApolloClient.mutate({
                        mutation: UPDATE_TASK,
                        variables: {
                          input: input3,
                        },
                        update: (
                          proxy: DataProxy,
                          result: FetchResult<UpdateTaskMutation>
                        ) => {
                          // custom update CACHE FUNC....
                          // result contains updated TASK.
                          // what we need to do:
                          // 1. read OLD CALENDAR from CACHE, with TASKS
                          // 2. remove OBSOLETE TASK from tasks[]
                          // 3. write updated calendar with removed TASK to CACHE
                          //
                          // then everyone is happy, we reload data from cache(before network)
                          // also check: does API return cached, outdated data??
                          const oldCalendar = proxy.readQuery<
                            YearWheelRingQuery,
                            YearWheelRingVariables
                          >({
                            query: YEAR_WHEEL_CALENDAR_RING,
                            variables: {
                              calendarId: this.props.taskSelectedCalendar.id, // OLD calendar where task is moved away from . .
                              year: year, // NOTE: another pssible bug here.. we should update ALL cached data... not only current year?
                            },
                          });
                          // console.log(oldCalendar, 'before removing task');

                          // console.log(oldCalendar, 'calendar11');
                          if (oldCalendar.calendar) {
                            let x = -1;
                            oldCalendar.calendar.tasks.forEach((task, i) => {
                              if (task.id === this.props.taskId) {
                                x = i;
                              }
                            });

                            if (x > -1) {
                              // console.log(
                              //   'removing task from cal',
                              //   this.props.taskId,
                              //   this.props.taskSelectedCalendar.id
                              // );
                              oldCalendar.calendar.tasks.splice(x, 1);
                            }
                          }

                          if (oldCalendar) {
                            // console.log('manually update cache', oldCalendar);
                            proxy.writeQuery<
                              YearWheelRingQuery,
                              YearWheelRingVariables
                            >({
                              query: YEAR_WHEEL_CALENDAR_RING,
                              variables: {
                                calendarId: this.props.taskSelectedCalendar.id,
                                year: year,
                              },
                              data: oldCalendar,
                            });

                            // console.log('cache ok');
                          }
                        },
                      });

                      // console.log('task updated!!!!', bolle.data);
                    } catch (e) {
                      console.error('Error updating task..');
                      console.log(e);
                    }
                  }

                  // SECOND: update event...

                  const input: UpdateEventInput = {
                    id: values.id || '',
                    title: values.title,
                    description: values.description,
                    descriptionText: values.descriptionText,
                    startDate: values.endDate,
                    endDate: values.endDate,
                    status: ContentStatus.PUBLISHED,
                    responsible: values.responsible,
                    reminderDate: values.reminderDate,
                    repetitionDefinition: values.repetitionDefinition,
                    endRepetitionDate: values.endRepetitionDate,
                  };

                  // add year wheel ring if it is not on calendarpage
                  if (!this.props.isCalendarPage) {
                    let isCalendarAlreadyActive = false;
                    // checking if the calendar is already selected
                    this.props.activeYearwheels.forEach(calendar => {
                      if (calendar.order == this.state.selectedCalendar.id) {
                        isCalendarAlreadyActive = true;
                      }
                    });
                    // if it is not active create a ring
                    if (!isCalendarAlreadyActive) {
                      let mutationVariables: CreateCalendarRingMutationVariables = {
                        input: {
                          calendar: this.state.selectedCalendar.id,
                          order: +this.state.selectedCalendar.id,
                        },
                      };

                      const result1 = await myApolloClient.mutate({
                        mutation: CREATE_CALENDAR_RING,
                        variables: mutationVariables,
                        refetchQueries: [
                          {
                            query: YEAR_WHEEL,
                            variables: {
                              year: this.props.year,
                              person: this.props.userHash,
                            },
                          },
                        ],
                        awaitRefetchQueries: true, // <== Mathias this fixed the bug with
                      });
                    }
                  }

                  for (let i = 0; i < values.files.length; i++) {
                    if (
                      values.files[i].file !== null &&
                      values.files[i].file !== null
                    ) {
                      try {
                        const uploadResult = await uploadFile(
                          values.files[i].file!,
                          values.id,
                          values.files[i].description,
                          p => console.log('upload progress:' + values.id)
                        );
                        if (!uploadResult.greatSuccess) {
                          // console.warn('Oops, upload failed: ' + files2[i].filename);
                          // NOTE: we could take some action here? Now we just continue to the next file...
                        }
                      } catch (uploadErr) {
                        console.log('upload err', uploadErr);
                      }
                    } else {
                    }
                  }

                  const getTask = await myApolloClient.mutate({
                    mutation: UPDATE_EVENT,
                    variables: {
                      input: input,
                    },
                    refetchQueries: [
                      {
                        query: GET_TASK_QUERY,
                        variables: {
                          taskId: this.props.taskId,
                        },
                      },
                    ],
                    update: (
                      proxy: DataProxy,
                      result: FetchResult<UpdateTaskMutation>
                    ) => {
                      // custom update CACHE FUNC....
                      // result contains updated TASK.
                      // what we need to do:
                      // 1. read CALENDAR from CACHE, with events
                      // 2. remove OBSOLETE event from events[]
                      // 3. write updated calendar with removed Event to CACHE
                      //
                      // then everyone is happy, we reload data from cache(before network)
                      // also check: does API return cached, outdated data??
                      const calendar = proxy.readQuery<
                        YearWheelRingQuery,
                        YearWheelRingVariables
                      >({
                        query: YEAR_WHEEL_CALENDAR_RING,
                        variables: {
                          calendarId: this.props.taskSelectedCalendar.id, // calendar where event is moved away from . .
                          year: year,
                        },
                      });

                      if (calendar.calendar) {
                        let x = -1;
                        let e = -1;
                        calendar.calendar.tasks.forEach((task, i) => {
                          if (task.id === this.props.taskId) {
                            x = i;
                          }
                        });
                        if (
                          calendar &&
                          calendar.calendar &&
                          calendar.calendar.tasks
                        ) {
                          calendar.calendar.tasks[x].events.forEach(
                            (event, i) => {
                              if (event.id === values.id) {
                                e = i;
                              }
                            }
                          );
                        }
                        if (x > -1 && e > -1) {
                          calendar.calendar.tasks[x].events.splice(e, 1);
                        }
                        if (calendar) {
                          // console.log('manually update cache', oldCalendar);
                          proxy.writeQuery<
                            YearWheelRingQuery,
                            YearWheelRingVariables
                          >({
                            query: YEAR_WHEEL_CALENDAR_RING,
                            variables: {
                              calendarId: this.props.taskSelectedCalendar.id,
                              year: year,
                            },
                            data: calendar,
                          });

                          // console.log('cache ok');
                        }
                      }
                    },
                  });
                  if (
                    !this.props.isRepetitionOn &&
                    !isReallyEmpty(values.note) &&
                    !this.props.isAssignedTask
                  ) {
                    let instance = this.props.item.instances[0];
                    let noteText = values.note;
                    let descText = values.note;
                    try {
                      descText = convertFromRaw(
                        JSON.parse(instance.description)
                      ).getPlainText();
                    } catch (e) {
                      descText = instance.description;
                    }
                    try {
                      noteText = convertFromRaw(
                        JSON.parse(values.note)
                      ).getPlainText();
                    } catch (e) {
                      noteText = values.note;
                    }
                    const instanceInput: UpdateEventInstanceInput = {
                      id: instance.id || '',
                      title: instance.title,
                      description: instance.description
                        ? instance.description
                        : '',
                      descriptionText: descText ? descText : '',
                      startDate: instance.endDate,
                      endDate: instance.endDate,
                      status: ContentStatus.PUBLISHED,
                      isDone: instance.isDone,
                      isFavourite: instance.isFavourite,
                      reminderDate: instance.reminderDate,
                      responsible: instance.responsible.id,
                      note: values.note ? values.note : '',
                      noteText: noteText ? noteText : '',
                    };
                    myApolloClient.mutate({
                      mutation: UPDATE_EVENT_INSTANCE,
                      variables: { input: instanceInput },
                    });
                  }

                  // TODO: update calendar on TASK if it has changed!
                  safeInvoke(this.props.onClose, true);
                }}
                initialValues={{
                  id: item.id,
                  title: item.title,
                  startDate: moment(item.endDate || undefined).toDate(),
                  endDate: moment(item.endDate || undefined).toDate(),
                  endRepetitionDate:
                    item.endRepetitionDate === null
                      ? null
                      : moment(item.endRepetitionDate || undefined).toDate(),
                  repetitionDefinition: item.repetitionDefinition,
                  description: item.description,
                  descriptionText: item.description
                    ? isDescriptionDraftJsFormat
                      ? convertFromRaw(
                          JSON.parse(item.description)
                        ).getPlainText()
                      : item.description
                    : '',
                  responsible: (item.responsible && item.responsible.id) || '',
                  reminderDate: item.reminderDate
                    ? moment(item.reminderDate).toDate()
                    : undefined,
                  status: ContentStatus.PUBLISHED,
                  files: [],
                  note: this.props.isRepetitionOn ? '' : item.instances[0].note,
                }}
                render={(formikProps: FormikProps<any>) => {
                  // const sortedTodos = _.sortBy(this.props.item.todoTemplate, 'title');
                  if (this.props.me.isAdmin) {
                    doIhaveAccess = true;
                  } else {
                    if (
                      this.state.selectedCalendar &&
                      this.state.selectedCalendar.createdBy &&
                      this.state.selectedCalendar.createdBy.id &&
                      this.state.selectedCalendar.createdBy.id ===
                        this.props.me.id
                    ) {
                      doIhaveAccess = true;
                    } else {
                      this.state.selectedCalendar &&
                        this.state.selectedCalendar.access.persons.forEach(
                          (c: PersonAccess) => {
                            if (c.person.id === this.props.me.id) {
                              if (c.access === AccessEnum.WRITE) {
                                doIhaveAccess = true;
                              }
                            }
                          }
                        );
                    }
                  }

                  let todoInstance: any = null;

                  const myReminderDate = getReminderIntervalDropdown(
                    intl,
                    formikProps.values.endDate,
                    formikProps.values.reminderDate || undefined
                  );

                  // put reminder In state so it updates the reminder when changing endDate

                  return (
                    <div style={{ display: 'flex' }}>
                      <Left>
                        {!doIhaveAccess && (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '.5em',
                              marginTop: '-1em',
                            }}
                          >
                            <MunikumIcons.warning
                              style={{ color: Colors.RED, marginRight: '.2em' }}
                            />
                            <SmallText
                              style={{
                                fontSize: '12px',
                                color: Colors.YELLOW,
                                marginLeft: '0em',
                              }}
                            >
                              {intl.formatMessage(commonMessages.noWriteAccess)}
                            </SmallText>
                          </div>
                        )}
                        <BreadCrumbs>
                          {this.props.taskType === TaskTypeEnum.DEFAULT && (
                            <BreadCrumb leftIcon={MunikumIcons.Folder}>
                              {this.props.taskTitle}
                            </BreadCrumb>
                          )}

                          {this.props.isRepetitionOn && (
                            <BreadCrumb
                              leftIcon={MunikumIcons.Repeat}
                              isDisabled={true}
                            >
                              {intl.formatMessage(commonLabels.serie)}
                            </BreadCrumb>
                          )}
                        </BreadCrumbs>
                        <div
                          style={{
                            marginTop: '1em',
                            overflowX: 'hidden',
                          }}
                        >
                          {/*<TextField*/}

                          {/*style={{ width: '100%' }}*/}
                          {/*label={intl.formatMessage(commonLabels.title)}*/}
                          {/*name={'title'}*/}
                          {/*value={formikProps.values.title}*/}
                          {/*onChange={formikProps.handleChange}*/}
                          {/*onBlur={formikProps.handleBlur}*/}
                          {/*placeholder={intl.formatMessage(*/}
                          {/*commonMessages.writeTitleHere*/}
                          {/*)}*/}
                          {/*error={*/}
                          {/*formikProps.errors.title &&*/}
                          {/*formikProps.touched.title*/}
                          {/*}*/}
                          {/*/>*/}

                          {/*<TextArea*/}
                          {/*label={intl.formatMessage(commonLabels.description)}*/}
                          {/*name={'description'}*/}
                          {/*onChange={formikProps.handleChange}*/}
                          {/*value={formikProps.values.description || ''}*/}
                          {/*onBlur={formikProps.handleBlur}*/}
                          {/*placeholder={intl.formatMessage(*/}
                          {/*commonMessages.writeDescriptionHere*/}
                          {/*)}*/}
                          {/*style={{*/}
                          {/*width: '100%',*/}
                          {/*height: '10em',*/}
                          {/*height: '10em',*/}
                          {/*}}*/}
                          {/*/>*/}

                          <FieldGroup>
                            <FormLabel htmlFor={'title'}>Tittel</FormLabel>
                            <PageTitle>
                              <MuContentEditable
                                name={'title'}
                                canEdit={doIhaveAccess} // TODO: add access rights here.. disable if your user cannot edit!
                                isMultiLine={false}
                                value={formikProps.values.title}
                                onSubmit={(md: string) => {
                                  let text = md.replace(/(\r\n|\n|\r)/gm, '');
                                  formikProps.setFieldValue('title', text);
                                }}
                                style={{
                                  // minWidth: '6em',
                                  width: '100%',
                                  // fixes overflow problem
                                  margin: '.1px',
                                }}
                                placeholder={intl.formatMessage(
                                  commonLabels.titlePlaceholder
                                )}
                              />
                            </PageTitle>
                            <ValidationSummary
                              error={
                                formikProps.errors.title &&
                                formikProps.touched.title
                              }
                            />
                          </FieldGroup>

                          <MuTextField
                            shouldEditorHaveFiles={true}
                            doIHaveAccessToAddFiles={doIhaveAccess}
                            onClickFile={(id, access) => {
                              this.setState({
                                isFileInfoCardVisible: true,
                                fileInfoId: id,
                                fileAccess: access || AccessEnum.READ,
                              });
                            }}
                            label={intl.formatMessage(
                              commonMessages.description
                            )}
                            placeholder={intl.formatMessage(
                              commonMessages.writeHerePlaceholder
                            )}
                            files={
                              this.state.files &&
                              this.state.files.map((file: UserFile) => {
                                const temp: any = {
                                  size: file.size,
                                  contenttype: file.contentType,
                                  lastupdated: file.lastUpdated,
                                  filename: file.name,
                                  description: file.description,
                                  id: file.id,
                                  access: file.access.myAccess,
                                };
                                return temp;
                              })
                            }
                            onSubmit={(value, files) => {
                              formikProps.setFieldValue('description', value);
                              formikProps.setFieldValue(
                                'descriptionText',
                                convertFromRaw(JSON.parse(value)).getPlainText()
                              );
                              formikProps.setFieldValue('files', files);
                            }}
                            value={formikProps.values.description}
                          />
                          <Overlay isOpen={this.state.isFileInfoCardVisible}>
                            <FileInfoCard
                              fileid={this.state.fileInfoId}
                              canDelete={this.state.fileAccess === 'WRITE'}
                              canEdit={this.state.fileAccess === 'WRITE'}
                              onSaveSuccess={() => {
                                this.setState({
                                  isFileInfoCardVisible: false,
                                });
                              }}
                              onDelete={() => {
                                let filesToDeleteCopy = this.state.filesToDelete.slice();
                                let filesToRemoveFromUi = this.state.files.slice();
                                filesToRemoveFromUi.forEach((file, i) => {
                                  if (file.id === this.state.fileInfoId) {
                                    filesToRemoveFromUi.splice(i, 1);
                                  }
                                });
                                filesToDeleteCopy.push(this.state.fileInfoId);
                                this.setState({
                                  filesToDelete: filesToDeleteCopy,
                                  isFileInfoCardVisible: false,
                                  files: filesToRemoveFromUi,
                                });
                              }}
                              onCancel={() => {
                                this.setState({
                                  isFileInfoCardVisible: false,
                                });
                              }}
                            />
                          </Overlay>
                          <ResponibleContainer
                            style={{ display: 'flex', width: '100%' }}
                          >
                            <div
                              style={{
                                width: '100%',
                                marginRight:
                                  item.assignedBy.id !== this.props.userHash
                                    ? '12px'
                                    : '4px',
                              }}
                            >
                              <FormLabel>
                                {intl.formatMessage(messages.responsible)}
                              </FormLabel>
                              <AnnualCycleAddMembersCard
                                disabled={!doIhaveAccess}
                                showOnlyPerson={true}
                                currentPermissionOwner={
                                  (item.responsible && item.responsible.name) ||
                                  ''
                                }
                                onTagClose={() => {
                                  formikProps.setFieldValue('responsible', '');
                                }}
                                showTag={true}
                                addMember={(person: Person) => {
                                  formikProps.setFieldValue(
                                    'responsible',
                                    person.id
                                  );
                                }}
                              />
                            </div>
                            {item.assignedBy.id !== this.props.userHash && (
                              <Tooltip content={item.assignedBy.name}>
                                <div>
                                  <TextField
                                    style={{ minWidth: '10em' }}
                                    disabled={true}
                                    name={'assignedBy'}
                                    value={item.assignedBy.name}
                                    label={intl.formatMessage(
                                      commonMessages.assignedBy
                                    )}
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </ResponibleContainer>

                          {/*<DateTimePicker*/}
                          {/*disabled={!doIhaveAccess}*/}
                          {/*label={'Start'}*/}
                          {/*onChange={(selectedDate: Date) => {*/}
                          {/*formikProps.setFieldValue(*/}
                          {/*'startDate',*/}
                          {/*selectedDate*/}
                          {/*);*/}

                          {/*if (*/}
                          {/*moment(selectedDate).isAfter(*/}
                          {/*moment(formikProps.values.endDate)*/}
                          {/*)*/}
                          {/*) {*/}
                          {/*const fresh = moment(selectedDate)*/}
                          {/*.subtract(0, 'days')*/}
                          {/*.toDate();*/}
                          {/*formikProps.setFieldValue('endDate', fresh);*/}
                          {/*}*/}

                          {/*}}*/}
                          {/*onSave={(selectedDate: Date) => {*/}
                          {/*formikProps.setFieldValue(*/}
                          {/*'startDate',*/}
                          {/*selectedDate*/}
                          {/*);*/}
                          {/*}}*/}
                          {/*value={formikProps.values.startDate}*/}
                          {/*// onBlur={formikProps.handleBlur}*/}
                          {/*name={'startDate'}*/}
                          {/*pickTime={false}*/}
                          {/*pickDate={true}*/}
                          {/*closeOnSelect={true}*/}
                          {/*/>*/}
                          <CalendarsDropdown
                            disabled={!doIhaveAccess}
                            disableAssigned={true}
                            // name={'selectedCalendars'}
                            // disabled={true}
                            // warning={'You are crazy if you change this!'}
                            label={intl.formatMessage(messages.calendar)}
                            onBlur={formikProps.handleBlur}
                            selectMode={SelectMode.SINGLE}
                            onSelected={item2 =>
                              this.setState(
                                {
                                  selectedCalendar: item2,
                                },
                                () => {
                                  console.log(item2);
                                }
                              )
                            }
                            selectedItems={[this.state.selectedCalendar]}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              // marginBottom: '.5em',
                            }}
                          >
                            <DateTimePicker
                              disabled={!doIhaveAccess}
                              label={'Start'}
                              onChange={(selectedDate: Date) => {
                                formikProps.setFieldValue(
                                  'endDate',
                                  selectedDate
                                );
                                this.setState({
                                  copyEndDate: _.cloneDeep(selectedDate),
                                });
                                if (
                                  moment(selectedDate).isBefore(
                                    moment(formikProps.values.startDate)
                                  )
                                ) {
                                  const fresh = moment(selectedDate)
                                    .subtract(0, 'days')
                                    .toDate();
                                  // console.log('setting start to: ', fresh);

                                  formikProps.setFieldValue('startDate', fresh);
                                }
                              }}
                              onSave={(selectedDate: Date) => {
                                let initialReminderOption: ReminderOptions = this
                                  .state.reminderItem;
                                if (
                                  this.state.reminderItem ===
                                  ReminderOptions.NONE
                                ) {
                                  switch (myReminderDate.id) {
                                    case 'ONE_WEEK':
                                      initialReminderOption =
                                        ReminderOptions.ONE_WEEK;
                                      break;
                                    case 'TWO_WEEK':
                                      initialReminderOption =
                                        ReminderOptions.TWO_WEEK;
                                      break;
                                    case 'FOUR_WEEKS':
                                      initialReminderOption =
                                        ReminderOptions.FOUR_WEEKS;
                                      break;
                                    default:
                                      initialReminderOption =
                                        ReminderOptions.ONE_WEEK;
                                  }
                                  this.setState({
                                    reminderItem: initialReminderOption,
                                  });
                                }
                                console.log(initialReminderOption);
                                if (
                                  formikProps.values.reminderDate &&
                                  initialReminderOption !== ReminderOptions.NONE
                                ) {
                                  const calculatedDate = getReminderDate(
                                    selectedDate,
                                    initialReminderOption
                                  );
                                  formikProps.setFieldValue(
                                    'reminderDate',
                                    calculatedDate
                                  );
                                }
                                this.setState({
                                  repData: {
                                    ...this.state.repData,
                                    startRepetitionDate: selectedDate,
                                  },
                                });
                                formikProps.setFieldValue(
                                  'endDate',
                                  selectedDate
                                );
                              }}
                              value={formikProps.values.endDate}
                              name={'endDate'}
                              fieldGroupStyle={{ width: '232px' }}
                              pickTime={false}
                              pickDate={true}
                              closeOnSelect={true}
                            />
                            <ReminderDropdown
                              name={'reminderDate'}
                              leftIcon={MunikumIcons.Reminder}
                              onBlur={formikProps.handleBlur}
                              label={intl.formatMessage(commonLabels.reminder)}
                              fieldGroupStyle={{
                                marginLeft: '1em',
                              }}
                              error={
                                formikProps.touched.reminderDate &&
                                formikProps.errors.reminderDate
                              }
                              onSelected={selectedDate => {
                                this.setState(
                                  {
                                    reminderItem: selectedDate.id as ReminderOptions,
                                  },
                                  () => {
                                    const calculatedDate = getReminderDate(
                                      formikProps.values.endDate,
                                      selectedDate.id as ReminderOptions
                                    );
                                    formikProps.setFieldValue(
                                      'reminderDate',
                                      calculatedDate
                                    );
                                  }
                                );
                              }}
                              selectedItems={
                                (myReminderDate && [myReminderDate]) || []
                              }
                            />
                          </div>

                          <RepetitionPicker
                            orgEndDate={this.state.copyEndDate}
                            handleCancel={startDate => {
                              formikProps.setFieldValue('startDate', startDate);
                            }}
                            disabled={!doIhaveAccess}
                            name={'repetitionDefinition'}
                            label={intl.formatMessage(commonLabels.repetition)}
                            startDate={formikProps.values.endDate}
                            fieldGroupStyle={{ width: '60em' }}
                            handleRemoveRepetition={() => {
                              formikProps.setFieldValue(
                                'repetitionDefinition',
                                null
                              );
                              formikProps.setFieldValue(
                                'endRepetitionDate',
                                null
                              );
                              formikProps.setFieldValue(
                                'repetitionDefinition',
                                null
                              );
                              this.setState({
                                repData: {
                                  freq: 'none',
                                  repetitionDefinition: null,
                                  startRepetitionDate: null,
                                  endRepetitionDate: null,
                                },
                              });
                            }}
                            onSave={(repData: IRepetitionData) => {
                              this.setState(
                                {
                                  repData: repData,
                                },
                                () => {
                                  // console.log('repData: ', repData);

                                  if (repData.freq === 'none') {
                                    formikProps.setFieldValue(
                                      'endRepetitionDate',
                                      undefined
                                    );
                                    formikProps.setFieldValue(
                                      'repetitionDefinition',
                                      undefined
                                    );
                                  } else {
                                    formikProps.setFieldValue(
                                      'endDate',
                                      repData.startRepetitionDate
                                    );
                                    formikProps.setFieldValue(
                                      'startDate',
                                      repData.startRepetitionDate
                                    );
                                    formikProps.setFieldValue(
                                      'endRepetitionDate',
                                      repData.endRepetitionDate
                                    );
                                    formikProps.setFieldValue(
                                      'repetitionDefinition',
                                      repData.repetitionDefinition
                                    );
                                  }
                                }
                              );
                            }}
                            // currentUntilValue={
                            //   formikProps.values.endRepetitionDate
                            // }
                            value={this.state.repData}
                            info={
                              this.props.isRepetitionOn
                                ? intl.formatMessage(
                                    messages.warningChangeRepetition
                                  )
                                : ''
                            }
                          />
                          {!this.props.isRepetitionOn &&
                            !this.props.isAssignedTask && (
                              <MuField
                                label={intl.formatMessage(commonLabels.notes)}
                                name={'note'}
                                canEdit={doIhaveAccess}
                                isMultiLine={true}
                                onSubmit={(md: string, draftJsFormat: any) => {
                                  try {
                                    formikProps.setFieldValue(
                                      'note',
                                      JSON.stringify(
                                        convertToRaw(draftJsFormat)
                                      )
                                    );
                                  } catch (e) {
                                    formikProps.setFieldValue('note', md);
                                  }
                                }}
                                value={formikProps.values.note || ''}
                                placeholder={intl.formatMessage(
                                  commonLabels.notesPlaceholder
                                )}
                                style={{
                                  width: '90%',
                                  margin: '1px',
                                }}
                                error={
                                  formikProps.errors.note &&
                                  formikProps.touched.note
                                }
                              />
                            )}
                        </div>
                      </Left>
                      <Right style={{ position: 'relative' }}>
                        <FieldArray
                          name={'todoTemplate'}
                          render={arrayHelpers => {
                            return (
                              <div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <H4>
                                    {intl.formatMessage(
                                      messages.checklistTitleTemplate
                                    )}
                                  </H4>
                                  {this.props.isRepetitionOn && (
                                    <ValidationMessage
                                      type={MessageType.WARNING}
                                      msg={intl.formatMessage(
                                        messages.infoAddTodoTemplage
                                      )}
                                      // color={Colors.RED}
                                      style={{
                                        marginLeft: '.5em',
                                      }}
                                    />
                                  )}
                                </div>
                                <CreateTodoTemplateMutationComp
                                  mutation={CREATE_TODO_TEMPLATE}
                                  refetchQueries={[
                                    {
                                      query: GET_INSTANCE_AND_TASK_QUERY, // NOTE: changed refetch query to only get task not entire annual cycle
                                      variables: {
                                        year: this.props.year,
                                        taskId: this.props.taskId,
                                      },
                                    },
                                  ]}
                                >
                                  {(
                                    createTodoTemplate,
                                    {
                                      data: updateData,
                                      error: updateError,
                                      called: updateCalled,
                                      loading: updateLoading,
                                    }
                                  ) => {
                                    return (
                                      <>
                                        <CreateCard
                                          disabled={!doIhaveAccess}
                                          placeholder={'Legg til sjekkliste'}
                                          style={{
                                            marginBottom: '1em',
                                            marginTop: '.5em',
                                          }}
                                          onSubmit={title => {
                                            // console.log(temp);
                                            createTodoTemplate({
                                              variables: {
                                                input: {
                                                  event: item.id,
                                                  title: title,
                                                  status:
                                                    ContentStatus.PUBLISHED,
                                                },
                                              },
                                            });
                                          }}
                                        />
                                      </>
                                    );
                                  }}
                                </CreateTodoTemplateMutationComp>

                                <UpdateTodoTemplateMutationComp
                                  mutation={UPDATE_TODO_TEMPLATE}
                                >
                                  {(
                                    updateTodoTemplate,
                                    {
                                      data: updateData,
                                      error: updateError,
                                      called: updateCalled,
                                      loading: updateLoading,
                                    }
                                  ) => {
                                    return (
                                      <div
                                        style={{
                                          overflowY: 'auto',
                                          maxHeight: '300px',
                                        }}
                                      >
                                        {publishedTodos.map(t => {
                                          if (!this.props.isRepetitionOn) {
                                            todoInstance = _.find(
                                              this.props.item.instances[0]
                                                .todos,
                                              (inTodo: any) => {
                                                return inTodo.parent === t.id;
                                              }
                                            );
                                          }
                                          return (
                                            <TodoCard
                                              disabled={!doIhaveAccess}
                                              key={'tr45t_' + t.id}
                                              canDelete={true}
                                              todoName={t.title}
                                              isCheckBoxVisible={
                                                !this.props.isRepetitionOn
                                              }
                                              isChecked={
                                                todoInstance
                                                  ? todoInstance.isDone
                                                  : false
                                              }
                                              onClickTodo={() => {
                                                if (
                                                  !this.props.isRepetitionOn
                                                ) {
                                                  todoInstance = _.find(
                                                    this.props.item.instances[0]
                                                      .todos,
                                                    (inTodo: any) => {
                                                      return (
                                                        inTodo.parent === t.id
                                                      );
                                                    }
                                                  );
                                                  const result = myApolloClient.mutate(
                                                    {
                                                      mutation: UPDATE_TODO,
                                                      variables: {
                                                        input: {
                                                          id: todoInstance.id,
                                                          title:
                                                            todoInstance.title,
                                                          status:
                                                            ContentStatus.PUBLISHED,
                                                          isDone: !todoInstance.isDone,
                                                          description:
                                                            todoInstance.description,
                                                          note:
                                                            todoInstance.note,
                                                        },
                                                      },
                                                    }
                                                  );
                                                }
                                              }}
                                              onChange={(md: string) => {
                                                updateTodoTemplate({
                                                  variables: {
                                                    input: {
                                                      id: t.id,
                                                      title: md,
                                                      description: md,
                                                      status:
                                                        ContentStatus.PUBLISHED,
                                                    },
                                                  },
                                                });
                                              }}
                                              onDeleteClicked={() => {
                                                updateTodoTemplate({
                                                  variables: {
                                                    input: {
                                                      id: t.id,
                                                      title: t.title,
                                                      description:
                                                        t.description,
                                                      status:
                                                        ContentStatus.OBSOLETE,
                                                    },
                                                  },
                                                  optimisticResponse: {
                                                    updateTodoTemplate: {
                                                      id: t.id,
                                                      title: t.title,
                                                      description:
                                                        t.description,
                                                      status:
                                                        ContentStatus.OBSOLETE,
                                                      __typename:
                                                        'TodoTemplate',
                                                    },
                                                  },
                                                });
                                              }}
                                            />
                                          );
                                        })}
                                      </div>
                                    );
                                  }}
                                </UpdateTodoTemplateMutationComp>
                              </div>
                            );
                          }}
                        />
                        <Butts>
                          {!this.props.isRepetitionOn && (
                            <Button
                              text={intl.formatMessage(commonMessages.delete)}
                              theme={ColorTheme.White}
                              leftIcon={MunikumIcons.Delete}
                              onClick={() =>
                                safeInvokeDeprecated(this.props.onDelete)
                              }
                              disabled={!doIhaveAccess}
                            />
                          )}

                          <Button
                            text={intl.formatMessage(commonMessages.cancel)}
                            theme={ColorTheme.White}
                            leftIcon={MunikumIcons.Cancel}
                            onClick={() =>
                              safeInvokeDeprecated(this.props.onClose)
                            }
                            style={{ marginLeft: '.5em' }}
                            disabled={loading}
                          />

                          <Button
                            isLoading={loading}
                            disabled={!doIhaveAccess}
                            text={intl.formatMessage(commonMessages.save)}
                            theme={ColorTheme.Red}
                            onClick={() => {
                              let hasRepetitionChanged = false;
                              let hasStartDateNotChanged = true;
                              let hasEndDateNotChanged = true;
                              if (item.repetitionDefinition !== null) {
                                hasRepetitionChanged =
                                  item.repetitionDefinition !==
                                  formikProps.values.repetitionDefinition;
                                hasStartDateNotChanged =
                                  new Date(item.startDate).getFullYear() ===
                                    formikProps.values.startDate.getFullYear() &&
                                  new Date(item.startDate).getMonth() ===
                                    formikProps.values.startDate.getMonth() &&
                                  new Date(item.startDate).getDate() ===
                                    formikProps.values.startDate.getDate();
                                hasEndDateNotChanged =
                                  new Date(item.endDate).getFullYear() ===
                                    formikProps.values.endDate.getFullYear() &&
                                  new Date(item.endDate).getMonth() ===
                                    formikProps.values.endDate.getMonth() &&
                                  new Date(item.endDate).getDate() ===
                                    formikProps.values.endDate.getDate();
                              }

                              if (
                                hasRepetitionChanged ||
                                !hasStartDateNotChanged ||
                                !hasEndDateNotChanged
                              ) {
                                if (
                                  confirm(
                                    intl.formatMessage(
                                      messages.ChangeRepetition
                                    )
                                  )
                                ) {
                                  // timeout so mufields looses focus
                                  setTimeout(() => {
                                    formikProps.submitForm();
                                  }, 200);
                                }
                              } else {
                                setTimeout(() => {
                                  formikProps.submitForm();
                                }, 200);
                              }
                            }}
                            style={{ marginLeft: '.5em' }}
                            leftIcon={MunikumIcons.Save}
                          />
                        </Butts>
                      </Right>
                    </div>
                  );
                }}
              />
            </div>
          );
        }}
      </Mutation>
    );
  }
}

export default EventForm;
