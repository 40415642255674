import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import { call, put, take } from 'redux-saga/effects';
import moment from 'moment';
import { createSelector, Selector } from 'reselect';
import { RouteComponentProps } from 'react-router';
import {
  createActionValue,
  getActionValueParameters,
  getDefaultActionValueParameterValuesFromGroups,
  IActionValueCreateOrUpdateForm,
  IActionValueParameters,
  updateActionValue,
} from '../../services/actionValueService';
import {
  ActionValueCategory,
  IActionValue,
  IActionValueParameterValue,
} from '../../services/models/actionValue';
import { myHistory } from '../../index';
import { getUsedSources } from '../../services/organizationService';
import { IFile } from '../../services/fileservice';
import {
  getActionValueActionCreator,
  getActionValueMetasActionCreator,
  getFilesActionCreator,
} from '../entities/entities';
import { IContentMeta } from '../../services/models/contentMeta';
import { createUploadFileChannel } from '../file/file';
import { IActionValueItem } from '../../services/models/actionValueItem';
import { isDataReadySelectorFactory } from '../entities/entitySelectorFactory';
import { signoutSuccess } from '../auth/auth';
import { IApiError, wrapApiError } from '../../services/api';
import { IOrganization } from '../../services/models/organization';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { ContentStatus, OrderEnum, SortEnum } from '../../models/types';
import { RootState } from '../rootReducer';
import { getValues } from '../../components/common';

const actionCreator = actionCreatorFactory('ACTION_VALUE');

/**
 * ActionValue redux module (actions, reducer, sagas, selectors)
 * list: state for showing list of actionvalues for user (not admin view?)
 *        filter, pagination, etc.
 * edit: edit could be removed...
 *        the plan was to show a list of actionvalues the user is editing..
 *
 *
 *
 */

export const getActionValueParametersActionCreator = actionCreator.async<
  {},
  {
    groups: IActionValueParameters;
    defaultValues: ReadonlyArray<IActionValueParameterValue>;
  },
  IApiError
>('ACTION_VALUE_PARAMETERS');

// action to start editing actionValue. get data from api, put everything we need in state, and redirect to editpage
export const editActionValueActionCreator = actionCreator.async<
  { actionValueContentId: string },
  {
    form: IActionValueCreateOrUpdateForm;
    actionValueMetaId: string;
    actionValueContentId: string;
    source?: IOrganization;
  },
  IApiError
>('UI_EDIT');

// close edit page
export const cancelEditActionValueActionCreator = actionCreator<{}>(
  'UI_CANCEL_EDIT'
);

// update actions
export const updateActionValueActionCreator = actionCreator.async<
  IActionValueCreateOrUpdateForm,
  {},
  IApiError
>('UPDATE');

// we also need one for create, since create is different than update:
export const saveActionValueActionCreator = actionCreator.async<
  IActionValueCreateOrUpdateForm,
  {},
  IApiError
>('SAVE');

// special action to load data when showing list:
// export const loadActionValueList = actionCreator<{}>('LOAD');

export const clientNavigateToPageActionCreator = actionCreator<{
  page: number;
}>('CLIENT_CHANGE_PAGE');

export const clientFilterActionValuesActionCreator = actionCreator<
  IActionValueFilter
>('CLIENT_CHANGE_FILTER');

export const clientResetActionValuesFilterActionCreator = actionCreator<{}>(
  'CLIENT_RESET_FILTER'
);

export const adminNavigateToPageActionCreator = actionCreator<{ page: number }>(
  'ADMIN_CHANGE_PAGE'
);

export const adminFilterActionValuesActionCreator = actionCreator<
  IActionValueFilter
>('ADMIN_CHANGE_FILTER');

export const adminResetActionValuesFilterActionCreator = actionCreator<{}>(
  'ADMIN_RESET_FILTER'
);

export const expanderToggleActionValueList = actionCreator<{}>(
  'EXPANDER_TOGGLE'
);
export const changeShowOnlyMyFollowedActionCreator = actionCreator<{
  showOnlyMyFollowed: boolean;
}>('CHANGE_SHOW_ONLY_MY_FOLLOWED_ACTION_CREATOR');

export const loadUsedOrganizationsActionCreator = actionCreator.async<
  {},
  {},
  IApiError
>('LOAD_USED_ORGS');

export interface IActionValueFilter {
  /**
   * true if you want to show grants with deadlines where all are expired
   */
  showExpired: boolean;

  /***
   * if true it onlys show actionvalues you follow in the actionvaluelist
   */
  showOnlyMyFollowed: boolean;

  /**
   * mandatory, empty string ofr nothing ;)
   */
  search: string;

  /**
   * A list of contentstatuses you want to filter on
   */
  status: Array<ContentStatus>;

  /**
   * operational or investment
   */
  category: Array<ActionValueCategory>;

  /**
   * array of ids to organizations you want to filter on (source)
   */
  source: Array<string>;

  sortBy?: 'title' | 'lastUpdated.desc' | 'deadline';

  // sortByFields: Array<string>;

  // sortDirection: 'asc' | 'desc';

  sector: Array<string>;

  kostraFunctions: Array<string>;
  kostraGroup: number | null;
  kostraGroupKey: string | undefined;
  sortKey: string | undefined;
  sort: SortEnum;
  order: OrderEnum;
}

const defaultClientFilter: IActionValueFilter = {
  showExpired: true,
  sort: SortEnum.DEADLINE,
  order: OrderEnum.DESCENDING,
  showOnlyMyFollowed: false,
  search: '',
  status: [ContentStatus.PUBLISHED],
  category: [],
  sortKey: '2',
  source: [],
  sortBy: 'title',
  sector: [],
  kostraFunctions: [],
  kostraGroup: null,
  kostraGroupKey: undefined,
};

const defaultAdminFilter: IActionValueFilter = {
  showExpired: true,
  kostraGroupKey: undefined,
  search: '',
  showOnlyMyFollowed: false,
  status: [],
  category: [],
  sort: SortEnum.DEADLINE,
  order: OrderEnum.DESCENDING,
  source: [],
  sortKey: '2',
  sortBy: 'title',
  sector: [],
  kostraGroup: null,
  kostraFunctions: [],
};

export interface IActionValueState {
  edit: {
    isPreparingToEdit: boolean;
    error?: string;
    forms: {
      [byActionValueContentId: string]: Readonly<
        IActionValueCreateOrUpdateForm
      >;
    };
  };
  update: {
    isUpdating: boolean;
    error?: string;
    isSavingDraft: boolean;
    isSavingPublished: boolean;
  };
  create: {
    isSaving: boolean;
    error?: string;
  };
  parameters?: {
    /**
     * Valid parameters from DB grouped by effort and gain.
     */
    groups: IActionValueParameters;

    /**
     * Default parametervalues when creating new actionvalues
     */
    defaultValues: ReadonlyArray<IActionValueParameterValue>;
  };
  parametersError?: string;

  clientList: {
    showOnlyMyFollowed: false;
    filter: IActionValueFilter;
    paging: {
      page: number;
    };
    expanderIsOpen: boolean;
  };

  adminList: {
    filter: IActionValueFilter;
  };

  usedSources: {
    isLoading: boolean;
    error?: string;
    organizations: { [byId: string]: IOrganization };
  };
}

const initialState: IActionValueState = {
  edit: {
    isPreparingToEdit: false,
    forms: {},
  },
  update: {
    isUpdating: false,
    isSavingDraft: false,
    isSavingPublished: false,
  },
  create: {
    isSaving: false,
  },
  clientList: {
    showOnlyMyFollowed: false,
    filter: defaultClientFilter,
    paging: {
      page: 0,
    },
    expanderIsOpen: true,
  },
  adminList: {
    filter: defaultAdminFilter,
  },
  usedSources: {
    isLoading: false,
    organizations: {},
  },
};

export const reducer = (
  state: IActionValueState = initialState,
  action: Action
): IActionValueState => {
  if (isType(action, signoutSuccess)) {
    return initialState;
  }

  if (isType(action, loadUsedOrganizationsActionCreator.started)) {
    return {
      ...state,
      usedSources: {
        ...state.usedSources,
        isLoading: true,
        error: undefined,
      },
    };
  }

  if (isType(action, loadUsedOrganizationsActionCreator.success)) {
    return {
      ...state,
      usedSources: {
        isLoading: false,
        error: undefined,
        organizations: action.payload.result,
      },
    };
  }

  if (isType(action, loadUsedOrganizationsActionCreator.failed)) {
    return {
      ...state,
      usedSources: {
        isLoading: false,
        error: action.payload.error.message,
        organizations: {},
      },
    };
  }

  if (isType(action, expanderToggleActionValueList)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        expanderIsOpen: !state.clientList.expanderIsOpen,
      },
    };
  }

  if (isType(action, clientFilterActionValuesActionCreator)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        filter: action.payload,
        paging: {
          ...state.clientList.paging,
          page: 0, // reset paging when changing filter
        },
      },
    };
  }
  if (isType(action, changeShowOnlyMyFollowedActionCreator)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        filter: {
          ...state.clientList.filter,
          showOnlyMyFollowed: action.payload.showOnlyMyFollowed,
        },
      },
    };
  }
  if (isType(action, clientResetActionValuesFilterActionCreator)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        filter: defaultClientFilter,
        paging: {
          ...state.clientList.paging,
          page: 0,
        },
      },
    };
  }

  if (isType(action, adminFilterActionValuesActionCreator)) {
    return {
      ...state,
      adminList: {
        ...state.adminList,
        filter: action.payload,
      },
    };
  }

  if (isType(action, adminResetActionValuesFilterActionCreator)) {
    return {
      ...state,
      adminList: {
        ...state.adminList,
        filter: defaultAdminFilter,
      },
    };
  }

  if (isType(action, clientNavigateToPageActionCreator)) {
    return {
      ...state,
      clientList: {
        ...state.clientList,
        paging: {
          page: action.payload.page,
        },
      },
    };
  }

  if (isType(action, saveActionValueActionCreator.started)) {
    return {
      ...state,
      create: {
        error: undefined,
        isSaving: true,
      },
    };
  }
  if (isType(action, saveActionValueActionCreator.success)) {
    return {
      ...state,
      create: {
        isSaving: false,
        error: undefined,
      },
    };
  }
  if (isType(action, saveActionValueActionCreator.failed)) {
    return {
      ...state,
      create: {
        isSaving: false,
        error: action.payload.error.message,
      },
    };
  }

  if (isType(action, getActionValueParametersActionCreator.started)) {
    return {
      ...state,
      parametersError: undefined,
    };
  }
  if (isType(action, getActionValueParametersActionCreator.success)) {
    console.log('success', action.payload.result);
    return {
      ...state,
      parameters: {
        groups: action.payload.result.groups,
        defaultValues: action.payload.result.defaultValues,
      },
      parametersError: undefined,
    };
  }
  if (isType(action, getActionValueParametersActionCreator.failed)) {
    return {
      ...state,
      parametersError: action.payload.error.message,
    };
  }

  if (isType(action, updateActionValueActionCreator.started)) {
    return {
      ...state,
      update: {
        isUpdating: true,
        error: undefined,
        isSavingPublished: action.payload.status === ContentStatus.PUBLISHED,
        isSavingDraft: action.payload.status === ContentStatus.DRAFT,
      },
    };
  }
  if (isType(action, updateActionValueActionCreator.success)) {
    return {
      ...state,
      update: {
        isUpdating: false,
        error: undefined,
        isSavingPublished: false,
        isSavingDraft: false,
      },
    };
  }
  if (isType(action, updateActionValueActionCreator.failed)) {
    return {
      ...state,
      update: {
        isUpdating: false,
        error: action.payload.error.message,
        isSavingPublished: false,
        isSavingDraft: false,
      },
    };
  }

  if (isType(action, editActionValueActionCreator.started)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: true,
        error: undefined,
        forms: {}, // remove if we want to edit many items..
      },
    };
  }
  if (isType(action, editActionValueActionCreator.success)) {
    // for now we'll only support editing one actionvalue at a time
    const myDict: Dictionary<IActionValueCreateOrUpdateForm> = {};
    myDict[action.payload.result.actionValueContentId] =
      action.payload.result.form;

    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: undefined,
        forms: myDict,
      },
    };
  }
  if (isType(action, editActionValueActionCreator.failed)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: action.payload.error.message,
        forms: {},
      },
    };
  }
  if (isType(action, cancelEditActionValueActionCreator)) {
    return {
      ...state,
      edit: {
        ...state.edit,
        isPreparingToEdit: false,
        error: undefined,
        forms: {},
      },
    };
  }
  return state;
};

// redux-sagas

export function* getActionValueParametersSaga() {
  while (true) {
    const action = yield take(
      getActionValueParametersActionCreator.started.type
    );
    try {
      const paramValueGroups = yield call(getActionValueParameters);
      const paramValueValues = yield call(
        getDefaultActionValueParameterValuesFromGroups,
        paramValueGroups
      );

      // console.log('test 22', paramValueGroups, paramValueValues);

      yield put(
        getActionValueParametersActionCreator.success({
          result: {
            groups: paramValueGroups,
            defaultValues: paramValueValues,
          },
          params: action.parameters,
        })
      );
    } catch (e) {
      let tempError: { message: string; statusCode?: number } = {
        message: e.message,
      };
      if (e.hasOwnProperty('statusCode')) {
        tempError.statusCode = e.statusCode;
      }

      yield put(
        getActionValueParametersActionCreator.failed({
          params: action.params,
          error: tempError,
        })
      );
    }
  }
}

/**
 * saga that listens for edit action value action.
 * fetches latest data from api, sets up form, redirects to edit url
 *
 *  # alternative solution:
 *    - we could solve this in ActionValueEditPage and remove this saga
 *    - only store form-state in formik, not redux-state
 */
// export function* editActionValueListenSaga() {
//   while (true) {
//     const action = yield take(editActionValueActionCreator.started.type);
//
//     try {
//       const avContentId = action.payload.actionValueContentId;
//       if (!avContentId) {
//         throw Error('no id specified, edit aborted');
//       }
//
//       const content = yield call(getActionValue, avContentId);
//       const meta = yield call(getActionValueMeta, content.meta);
//
//       if (meta.currentRevision !== content.id) {
//         console.warn('warning, not editing latest version...');
//       }
//
//       const tempAppDead: Array<any> = [];
//       const tempRepDead: Array<any> = [];
//       content.deadlines.map((dl: IDeadline) => {
//         if (dl.type === DeadlineType.APPLICATION) {
//           tempAppDead.push({
//             date: moment(dl.date).toDate(),
//             type: dl.type,
//           });
//         } else if (dl.type === DeadlineType.REPORT) {
//           tempRepDead.push({
//             date: moment(dl.date).toDate(),
//             type: dl.type,
//           });
//         } else {
//           tempAppDead.push({
//             date: moment(dl.date).toDate(),
//             type: dl.type,
//           });
//         }
//       });
//       console.log('deadloines', tempAppDead, tempRepDead);
//
//       // setup edit actionvalue data:
//       const form: IActionValueCreateOrUpdateForm = {
//         id: content.id ? content.id.toString() : '',
//         title: content.title || '',
//         description: content.description || '',
//         body: content.body || '',
//         year: meta.year
//           ? meta.year.substring(0, 4)
//           : moment()
//               .year()
//               .toString(),
//         status: meta.status || '',
//         category: content.category !== null ? content.category.toString() : '',
//         source: content.source !== null ? content.source.toString() : '',
//         sourceUrl: content.sourceUrl,
//         sectors: content.sectors
//           ? content.sectors.map((s: number) => s.toString())
//           : [],
//         validOrganizationTypes: content.validOrganizationTypes
//           ? content.validOrganizationTypes.map((s: number) => s.toString())
//           : [],
//         type: content.type !== null ? content.type.toString() : '',
//         kostraFunctions: content.kostraFunctions
//           ? content.kostraFunctions.map((s: number) => s.toString())
//           : [],
//         // deadlines: massageDeadlinesHack(content.deadlines),
//         reportingDeadlines: tempRepDead,
//         applicationDeadlines: tempAppDead,
//         deadlines: tempRepDead.concat(tempAppDead),
//         links: content.links,
//         parameters: content.parameters,
//         realms: meta.realms,
//         responsible:
//           content.responsible !== null ? content.responsible.toString() : '',
//       };
//
//       let org: IOrganization | undefined;
//
//       if (content.source !== undefined) {
//         // 1. check if in redux state:
//         org = yield select(organizationByDbIdSelector, content.source);
//         if (!org) {
//           yield put(
//             getOrganizationActionCreator.started({ id: content.source })
//           );
//           const action2 = yield take(getOrganizationActionCreator.success.type);
//           org = yield select(organizationByDbIdSelector, content.source);
//         }
//
//         // ugly hack:
//         if (org !== null && org !== undefined) {
//           console.log('set sourceItemHack to please async component..');
//           form.sourceItemHack = { value: org.id, label: org.title };
//         }
//       }
//
//       // put item ready to edit on redux global state:
//       yield put(
//         editActionValueActionCreator.success({
//           params: { actionValueContentId: content.actionValueContentId },
//           result: {
//             form: form,
//             actionValueMetaId: content.meta,
//             actionValueContentId: content.id,
//             source: org,
//           },
//         })
//       );
//
//       // redirect to edit page if needed
//       // const myLocation = yield select(routerPathSelector);
//       // if (
//       //   myLocation !== undefined &&
//       //   myLocation.indexOf('/admin/action-value/edit') < 0
//       // ) {
//       //
//       // }
//     } catch (e) {
//       yield put(
//         editActionValueActionCreator.failed({
//           params: { actionValueContentId: action.payload.actionValueContentId },
//           error: wrapApiError(e),
//         })
//       );
//
//       // TODO: show as toast? or just show 404, not found, error, ??
//       console.log('error editing', e);
//
//       myHistory.push(`/action-value/`);
//     }
//   }
// }

// TODO: we could write a generic factory-func/HOC to keep this DRY...

export function* updateActionValueSaga() {
  while (true) {
    const action = yield take(updateActionValueActionCreator.started.type);

    try {
      if (action.payload.id === undefined) {
        throw Error('Cannot update actionvalue without id!');
      }

      const tempMetaLimit = new Date();

      console.log('before update', action.payload);

      // updating actionvalue
      const result = yield call(updateActionValue, action.payload);

      // Save new files.
      if (action.payload.newfiles && action.payload.newfiles.length > 0) {
        for (let sFile of action.payload.newfiles) {
          console.log('Meta id: ', result);
          const channel = yield call(createUploadFileChannel, {
            file: sFile.file,
            metaid: result.meta,
          });

          while (true) {
            const { success, err } = yield take(channel);
            if (err) {
              throw Error('File upload failed');
            }
            if (success) {
              break;
            }
          }
        }

        yield put(
          getFilesActionCreator.started({ lastUpdated: tempMetaLimit })
        );
      }

      // get new actionvalue
      yield put(getActionValueActionCreator.started({ id: result.id }));

      // make sure meta is updated
      yield put(
        getActionValueMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      // update success state
      yield put(
        updateActionValueActionCreator.success({
          params: action.payload,
          result: result,
        })
      );

      // navigate back to list
      myHistory.push(`/action-value/`);
    } catch (e) {
      yield put(
        updateActionValueActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export function* saveActionValueSaga() {
  while (true) {
    const action = yield take(saveActionValueActionCreator.started.type);
    try {
      console.log('SAVE! action vlue1');
      const tempMetaLimit = new Date();

      const result = yield call(createActionValue, action.payload);

      // Save new files.
      if (action.payload.newfiles && action.payload.newfiles.length > 0) {
        for (let sFile of action.payload.newfiles) {
          const channel = yield call(createUploadFileChannel, {
            file: sFile.file,
            metaid: result.meta,
          });

          while (true) {
            const { success, err } = yield take(channel);
            if (err) {
              throw Error('File upload failed');
            }
            if (success) {
              break;
            }
          }
        }

        yield put(
          getFilesActionCreator.started({ lastUpdated: tempMetaLimit })
        );
      }

      yield put(getActionValueActionCreator.started({ id: result.id }));
      yield put(
        getActionValueMetasActionCreator.started({ lastUpdated: tempMetaLimit })
      );

      yield put(
        saveActionValueActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
      myHistory.push(`/action-value/`);
    } catch (e) {
      yield put(
        saveActionValueActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export function* loadUsedOrganizationsSaga() {
  while (true) {
    const action = yield take(loadUsedOrganizationsActionCreator.started.type);
    try {
      console.log('load used orangiszations');

      const result = yield call(getUsedSources);

      const dict: { [byId: string]: IOrganization } = {};
      result.map((org: IOrganization) => {
        dict[org.id] = org;
      });

      yield put(
        loadUsedOrganizationsActionCreator.success({
          params: action.payload,
          result: dict,
        })
      );
    } catch (e) {
      yield put(
        loadUsedOrganizationsActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export const actionValueParametersStructureSelector = (
  state: RootState
): IActionValueParameters | undefined => {
  return state.actionValue.parameters && state.actionValue.parameters.groups;
};

export const actionValueDefaultParameterValuesSelector = (
  state: RootState
): ReadonlyArray<IActionValueParameterValue> | undefined => {
  return (
    state.actionValue.parameters && state.actionValue.parameters.defaultValues
  );
};

// ACTION VALUE SELECTORS:

/**
 * A list of IActionValueMetas
 * @type {OutputSelector<RootState, ReadonlyArray<IActionValueMeta>, (res: {[p: string]: Readonly<IActionValueMeta>}) => ReadonlyArray<IActionValueMeta>>}
 */
export const actionValueMetaListSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IContentMeta> },
  ReadonlyArray<IContentMeta>
>(
  state => state.entities.actionValueMetas.byId,
  (actionValueMetaDict: { [byId: string]: Readonly<IContentMeta> }) =>
    _.values(actionValueMetaDict)
);

/**
 * A dictionary where KEY = actionValueMetaId and VALUE = IActionValueMeta
 * TODO: check if this is SMØR PÅ FLESK! :)
 * @type {OutputSelector<RootState, {[p: string]: Readonly<IActionValueMeta>}, (res: {[p: string]: Readonly<IActionValueMeta>}) => {[p: string]: Readonly<IActionValueMeta>}>}
 */
export const actionValueMetaDictSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IContentMeta> },
  { [byId: string]: Readonly<IContentMeta> }
>(
  state => state.entities.actionValueMetas.byId,
  res => res
);

/**
 * A dictionary where KEY = actionValueContentId and VALUE = IActionValue
 * @type {OutputSelector<RootState, {[p: string]: Readonly<IActionValue>}, (res: {[p: string]: Readonly<IActionValue>}) => {[p: string]: Readonly<IActionValue>}>}
 */
export const actionValueDictSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IActionValue> },
  { [byId: string]: Readonly<IActionValue> }
>(
  state => state.entities.actionValues.byId,
  res => res
);

export const actionValueFollowedListSelector = createSelector(
  actionValueMetaListSelector,
  actionValueDictSelector,
  (
    metaList: ReadonlyArray<IContentMeta>,
    valueDict: { [byId: string]: Readonly<IActionValue> }
  ) => {
    // if metaList or valueDict is empty we return undefined
    if (metaList === undefined || metaList.length === 0) {
      return undefined;
    }

    if (valueDict === undefined || Object.keys(valueDict).length === 0) {
      return undefined;
    }
    const allItems = metaList.map(metaItem => ({
      meta: metaItem,
      content: valueDict[metaItem.currentRevision],
    }));

    return _.filter(allItems, (item: IActionValueItem) => {
      if (item.content === undefined || item.meta === undefined) {
        return false;
      }

      return item.meta.watched;
    });
  }
);

export const isActionValueItemsLoadingSelector = (state: RootState) => {
  return (
    state.entities.actionValues.isFetchingList ||
    state.entities.actionValues.isFetchingSingle ||
    state.entities.actionValueMetas.isFetchingList ||
    state.entities.actionValueMetas.isFetchingSingle
  );
};

// TODO: make something generic.. handle 500, 404, 401 etc.

export const actionValueListErrorSelector = (state: RootState) => {
  const errors = [
    state.entities.actionValues.errorList,
    state.entities.actionValues.errorSingle,
    state.entities.actionValueMetas.errorList,
    state.entities.actionValueMetas.errorSingle,
  ];

  const err2: Array<IApiError> = [];
  for (const e of errors) {
    if (e !== undefined) {
      err2.push(e);
    }
  }

  if (err2.length === 0) {
    return undefined;
  }

  return err2;
};

export const isActionValueDataReadySelector = isDataReadySelectorFactory(
  'actionValues',
  'actionValueMetas'
);

/**
 * Selector that returns a dictionary where KEY = actionValueContentId and VALUE = IActionValueItem
 * Used in ActinoValueItemPage
 *
 * @type {OutputSelector<RootState, any, (res1: {[p: string]: Readonly<IActionValueMeta>}, res2: {[p: string]: Readonly<IActionValue>}) => any>}
 */
export const actionValueItemDictMetaSelector = createSelector(
  actionValueMetaDictSelector,
  actionValueDictSelector,
  (
    metaDict: { [byId: string]: Readonly<IContentMeta> },
    valueDict: { [byId: string]: Readonly<IActionValue> }
  ) => {
    if (metaDict === undefined || Object.keys(metaDict).length === 0) {
      return undefined;
    }

    if (valueDict === undefined || Object.keys(valueDict).length === 0) {
      return undefined;
    }

    const itemDict: { [byId: string]: IActionValueItem } = {};

    for (let key in metaDict) {
      itemDict[key] = {
        meta: metaDict[key],
        content: valueDict[metaDict[key].currentRevision],
      };
    }

    return itemDict;
  }
);

export const actionValueItemDictDataSelector = createSelector(
  actionValueMetaDictSelector,
  actionValueDictSelector,
  (
    metaDict: { [byId: string]: Readonly<IContentMeta> },
    valueDict: { [byId: string]: Readonly<IActionValue> }
  ) => {
    if (metaDict === undefined || Object.keys(metaDict).length === 0) {
      return undefined;
    }

    if (valueDict === undefined || Object.keys(valueDict).length === 0) {
      return undefined;
    }

    const itemDict: { [byId: string]: IActionValueItem } = {};

    for (let key in valueDict) {
      if (
        valueDict[key] !== undefined &&
        metaDict[valueDict[key].meta] !== undefined
      ) {
        itemDict[key] = {
          meta: metaDict[valueDict[key].meta],
          content: valueDict[metaDict[valueDict[key].meta].currentRevision],
        };
      }
    }

    return itemDict;
  }
);

type ActionValItemDict = { [byId: string]: IActionValueItem };

/**
 * A selector which returns a IActionValueItem if data is ready and id is in uri/props
 * @type {OutputParametricSelector<RootState, RouteComponentProps<{uri: string}>, IActionValueItem | undefined, (res1: (ActionValItemDict | undefined), res2: (string | undefined)) => (IActionValueItem | undefined)>}
 */
export const actionValueItemMetaSelector = createSelector<
  RootState,
  RouteComponentProps<{ uri: string }>,
  ActionValItemDict | undefined,
  string | undefined,
  IActionValueItem | undefined
>(
  actionValueItemDictMetaSelector,
  (state: RootState, props: RouteComponentProps<{ uri: string }>) => {
    return props.match && props.match.params && props.match.params.uri
      ? props.match.params.uri.substring(
          props.match.params.uri.lastIndexOf('-') + 1,
          props.match.params.uri.length
        )
      : undefined;
  },
  (r1: ActionValItemDict, r2: string) => {
    if (r1 !== undefined && r2 !== undefined) {
      return r1[r2];
    }
    return undefined;
  }
);

export const actionValueItemDataSelector = createSelector<
  RootState,
  RouteComponentProps<{ uri: string }>,
  ActionValItemDict | undefined,
  string | undefined,
  IActionValueItem | undefined
>(
  actionValueItemDictDataSelector,
  (state: RootState, props: RouteComponentProps<{ uri: string }>) => {
    return props.match && props.match.params && props.match.params.uri
      ? props.match.params.uri.substring(
          props.match.params.uri.lastIndexOf('/') + 1,
          props.match.params.uri.length
        )
      : undefined;
  },
  (r1: ActionValItemDict, r2: string) => {
    if (r1 !== undefined && r2 !== undefined) {
      return r1[r2];
    }
    return undefined;
  }
);

// export function createSelector<S, P, R1, R2, T>(
//   selector1: ParametricSelector<S, P, R1>,
//   selector2: ParametricSelector<S, P, R2>,
//   combiner: (res1: R1, res2: R2) => T,
// ): OutputParametricSelector<S, P, T, (res1: R1, res2: R2) => T>;
export const actionValueFilesMetaSelector = createSelector<
  RootState,
  RouteComponentProps<{ uri: string }>,
  IActionValueItem | undefined,
  {
    [byId: string]: Readonly<IFile>;
  },
  ReadonlyArray<IFile>
>(
  actionValueItemMetaSelector,
  (state: RootState) => {
    return state.entities.files.byId;
  },
  (
    r1: IActionValueItem | undefined,
    r2: {
      [byId: string]: Readonly<IFile>;
    }
  ) => {
    if (r1 !== undefined && r2 !== undefined) {
      // r1.content.files
      const temp: Array<IFile> = [];
      if (r1.content.userFiles !== undefined) {
        r1.content.userFiles.map((filevalue: string) => {
          temp.push(r2[filevalue]);
        });
        return temp;
      }
    }
    return [];
  }
);

// export function createSelector<S, P, R1, R2, T>(
//   selector1: ParametricSelector<S, P, R1>,
//   selector2: ParametricSelector<S, P, R2>,
//   combiner: (res1: R1, res2: R2) => T,
// ): OutputParametricSelector<S, P, T, (res1: R1, res2: R2) => T>;
export const actionValueFilesDataSelector = createSelector<
  RootState,
  RouteComponentProps<{ uri: string }>,
  IActionValueItem | undefined,
  {
    [byId: string]: Readonly<IFile>;
  },
  ReadonlyArray<IFile>
>(
  actionValueItemDataSelector,
  (state: RootState) => {
    return state.entities.files.byId;
  },
  (
    r1: IActionValueItem | undefined,
    r2: {
      [byId: string]: Readonly<IFile>;
    }
  ) => {
    if (r1 !== undefined && r2 !== undefined) {
      // r1.content.files
      const temp: Array<IFile> = [];
      r1.content.userFiles.map((filevalue: string) => {
        temp.push(r2[filevalue]);
      });
      return temp;
    }
    return [];
  }
);

// used in editpage
/**
 * A selector which returns a dictionary where KEY = actionValueContentId and VALUE = IActionValueItem
 * content is merged using meta.currentRevision mergeMetaAndCurrentRevisionByCurrentRevisionIdToDict
 * @type {OutputSelector<RootState, {[p: string]: IActionValueItem}, (res1: {[p: string]: Readonly<IActionValue>}, res2: {[p: string]: Readonly<IActionValueMeta>}) => {[p: string]: IActionValueItem}>}
 */
export const actionValueContentItemDictSelector = createSelector(
  actionValueDictSelector,
  actionValueMetaDictSelector,
  (
    valueDict: { [byId: string]: Readonly<IActionValue> },
    metaDict: { [byId: string]: Readonly<IContentMeta> }
  ) => {
    if (metaDict === undefined || Object.keys(metaDict).length === 0) {
      return [];
    }

    if (valueDict === undefined || Object.keys(valueDict).length === 0) {
      return [];
    }

    const itemDict: { [byId: string]: IActionValueItem } = {};

    // hmm, we're using valueDict as a starting-point,
    // older versions will be available, but I guess that is a good thing? =)
    for (let key in valueDict) {
      itemDict[key] = {
        meta: metaDict[valueDict[key].meta],
        content: valueDict[key],
      };
    }

    return itemDict;
  }
);

// export const organizationUsedSourceSelector = (state: RootState) => {
//   return state.actionValue.usedSources;
// };

export const organizationUsedSourceSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IOrganization> },
  { [byId: string]: Readonly<IOrganization> }
>(
  state => state.actionValue.usedSources.organizations,
  res => res
);

/**
 * selector factory function to create your own selector with actionvaluefilter!
 * @param state
 * @param filterSelector
 */
export const makeFilteredActionValueItemListSelector = (
  state: RootState,
  filterSelector: Selector<RootState, IActionValueFilter>
) => {
  return createSelector(
    actionValueMetaListSelector,
    actionValueDictSelector,
    filterSelector,
    (
      metaList: ReadonlyArray<IContentMeta>,
      valueDict: { [byId: string]: Readonly<IActionValue> },
      filter: IActionValueFilter
    ) => {
      // console.log('filter transform func');

      // if metaList or valueDict is empty we return undefined
      if (metaList === undefined || metaList.length === 0) {
        return undefined;
      }

      if (valueDict === undefined || Object.keys(valueDict).length === 0) {
        return undefined;
      }
      const allItems = metaList.map(metaItem => ({
        meta: metaItem,
        content: valueDict[metaItem.currentRevision],
      }));

      const filteredItems = _.filter(allItems, (item: IActionValueItem) => {
        if (item.content === undefined || item.meta === undefined) {
          // console.log('item is not complete!', item);
          return false;
        }

        if (filter.status !== undefined && filter.status.length > 0) {
          if (filter.status.indexOf(item.meta.status) === -1) {
            return false;
          }
        }

        if (
          filter.kostraFunctions !== undefined &&
          filter.kostraFunctions.length > 0
        ) {
          if (!item.content.kostraFunctions) {
            return false;
          }
          if (item.content.kostraFunctions.length === 0) {
            return false;
          }
          if (
            item.content.kostraFunctions.filter(
              value => -1 !== filter.kostraFunctions.indexOf(value)
            ).length === 0
          ) {
            return false;
          }
        }

        if (!filter.showExpired) {
          if (
            item.content.deadlines !== undefined &&
            item.content.deadlines.length > 0
          ) {
            const deadlines = _.sortBy(item.content.deadlines, 'date');
            let foundDeadlineInTheFuture = false;
            const now = moment();
            for (const dl of deadlines) {
              const future = moment(dl.date);

              if (future.isAfter(now)) {
                foundDeadlineInTheFuture = true;
                break;
              }
            }
            if (!foundDeadlineInTheFuture) {
              // exclude grants with deadlines where all deadlines has passed
              return false;
            }
          }
        }

        const values = getValues<number>(ActionValueCategory);

        if (filter.category !== undefined && filter.category.length > 0) {
          const filterNumberList = filter.category.map(cat => values[cat]);
          if (filterNumberList.indexOf(item.content.category) < 0) {
            return false;
          }
        }

        if (filter.source !== undefined && filter.source.length > 0) {
          const mySource = item.content.source;

          // return (
          //   mySource !== undefined &&
          //   filter.source.indexOf(mySource.toString()) > -1
          // );
          if (mySource === undefined) {
            return false; // if were filtering on source, we dont include avs without source
          }
          if (filter.source.indexOf(mySource.toString()) < 0) {
            return false;
          }
        }

        if (filter.search !== undefined && filter.search.length > 0) {
          const test1 =
            item.content.title !== undefined &&
            item.content.title !== null &&
            item.content.title
              .toLowerCase()
              .indexOf(filter.search.toLowerCase()) > -1;
          const test2 =
            item.content.description !== undefined &&
            item.content.description !== null &&
            item.content.description
              .toLowerCase()
              .indexOf(filter.search.toLowerCase()) > -1;
          const test3 =
            item.content.body !== undefined &&
            item.content.body !== null &&
            item.content.body
              .toLowerCase()
              .indexOf(filter.search.toLowerCase()) > -1;

          if (!test1 && !test2 && !test3) {
            return false;
          }
        }

        return true;
      });

      // console.log(
      //   'FILTER. total: ' +
      //     allItems.length +
      //     ' filtered: ' +
      //     filteredItems.length
      // );
      // if (filteredItems.length < 20) {
      //   console.log('filtered ', filteredItems);
      // }

      if (filter.sortBy !== undefined) {
        let sortedItems: Array<IActionValueItem>;

        if (filter.sortBy === 'deadline') {
          const rightNow = moment();
          sortedItems = _.sortBy<IActionValueItem>(
            filteredItems,
            (item: IActionValueItem) => {
              if (
                !item.content ||
                !item.content.deadlines ||
                item.content.deadlines.length === 0
              ) {
                const test = moment().endOf('year');
                return test.unix();
              }

              const sortedDeadlines = _.reverse(
                _.sortBy(item.content.deadlines, 'date')
              );

              let nextDeadline = moment(sortedDeadlines[0].date);
              let nextDeadlineIndex = 0;

              const now = moment();
              sortedDeadlines.map((value, index) => {
                const x = moment(value.date);
                if (x.isAfter(now)) {
                  nextDeadline = x;
                  nextDeadlineIndex = index;
                }
              });

              // now we have nextDeadline and now:
              if (nextDeadline.isBefore(rightNow)) {
                // return false; // ??

                // deadline has passed.. but is still valid? (if included in list!)
                return nextDeadline.unix();
              } else {
                return nextDeadline.unix();
              }
            }
          );
        } else if (filter.sortBy === 'lastUpdated.desc') {
          sortedItems = _.sortBy<IActionValueItem>(
            filteredItems,
            (item: IActionValueItem) => {
              console.log('sorting last updated..');
              return moment(item.meta.lastUpdated).unix();
            }
          );
          sortedItems = _.reverse(sortedItems);
        } else if (filter.sortBy === 'title') {
          sortedItems = _.sortBy<IActionValueItem>(
            filteredItems,
            (item: IActionValueItem) => {
              return item.meta.title;
            }
          );
        } else {
          sortedItems = filteredItems;
        }
        return sortedItems;
      }
      return filteredItems;
    }
  );
};
