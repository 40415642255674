import * as _ from 'lodash';
import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import { call, put, take } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
  ISearchForm,
  ISearchResponse,
  ISearchResponseV2,
  IShareForm,
  search,
  searchV2,
  share,
} from '../../services/metaService';
import { IContentMeta } from '../../services/models/contentMeta';
import { IApiError, wrapApiError } from '../../services/api';
import { saveCommentActionCreator } from '../comment/comment';
import { actionCreatorFactory } from '../actionCreatorFactory';
import { RootState } from '../rootReducer';

const actionCreator = actionCreatorFactory('META');

/**
 * Discussion redux module (actions, reducer, sagas, selectors). see also entities.ts for generic code!
 */

// actions

export interface IMetaState {
  newComment: {
    isSaving: boolean;
    saveSuccess: boolean;
  };
  // follow: {
  //   isFollowing: boolean;
  //   error?: string;
  //   form?: IMetaFollowForm;
  //   touched: boolean;
  // };
  search: {
    isSearching: boolean;
    error?: string;
    form?: ISearchForm;
    results?: ISearchResponse;
    resultsV2?: Array<ISearchResponseV2>;
  };
  share: {
    isSharing: boolean;
    error?: string;
    form?: IShareForm;
  };
}

const initialState: IMetaState = {
  newComment: {
    isSaving: false,
    saveSuccess: false,
  },
  // follow: {
  //   isFollowing: false,
  //   touched: false,
  // },
  search: {
    isSearching: false,
  },
  share: {
    isSharing: false,
  },
};

// export const loadMetaList = actionCreator<{}>('LOAD');

export const searchActionCreator = actionCreator.async<
  ISearchForm,
  ISearchResponse,
  IApiError
>('SEARCH');

export const searchV2ActionCreator = actionCreator.async<
  ISearchForm,
  Array<ISearchResponseV2>,
  IApiError
>('SEARCH_V2');

export const shareActionCreator = actionCreator.async<
  IShareForm,
  {},
  IApiError
>('SHARE');

export const reducer = (
  state: IMetaState = initialState,
  action: Action
): IMetaState => {
  if (isType(action, shareActionCreator.started)) {
    return {
      ...state,
      share: {
        isSharing: true,
        error: undefined,
      },
    };
  }
  if (isType(action, shareActionCreator.success)) {
    return {
      ...state,
      share: {
        isSharing: false,
        error: undefined,
      },
    };
  }
  if (isType(action, shareActionCreator.failed)) {
    return {
      ...state,
      share: {
        isSharing: false,
        error: action.payload.error.message,
      },
    };
  }

  if (isType(action, searchActionCreator.started)) {
    return {
      ...state,
      search: {
        isSearching: true,
        error: undefined,
      },
    };
  }
  if (isType(action, searchActionCreator.success)) {
    return {
      ...state,
      search: {
        isSearching: false,
        error: undefined,
        results: action.payload.result,
      },
    };
  }
  if (isType(action, searchActionCreator.failed)) {
    return {
      ...state,
      search: {
        isSearching: false,
        error: action.payload.error.message,
      },
    };
  }

  if (isType(action, searchV2ActionCreator.started)) {
    return {
      ...state,
      search: {
        isSearching: true,
        error: undefined,
      },
    };
  }
  if (isType(action, searchV2ActionCreator.success)) {
    return {
      ...state,
      search: {
        isSearching: false,
        error: undefined,
        resultsV2: action.payload.result,
      },
    };
  }
  if (isType(action, searchV2ActionCreator.failed)) {
    return {
      ...state,
      search: {
        isSearching: false,
        error: action.payload.error.message,
      },
    };
  }

  // if (isType(action, followMetaActionCreator.started)) {
  //   return {
  //     ...state,
  //     follow: {
  //       ...state.follow,
  //       isFollowing: true,
  //       error: undefined,
  //     },
  //   };
  // }
  // if (isType(action, followMetaActionCreator.success)) {
  //   return {
  //     ...state,
  //     follow: {
  //       isFollowing: false,
  //       error: undefined,
  //       touched: true,
  //     },
  //   };
  // }
  // if (isType(action, followMetaActionCreator.failed)) {
  //   return {
  //     ...state,
  //     follow: {
  //       ...state.follow,
  //       isFollowing: false,
  //       error: action.payload.error.message,
  //     },
  //   };
  // }

  if (isType(action, saveCommentActionCreator.started)) {
    return {
      ...state,
      newComment: {
        ...state.newComment,
        isSaving: true,
      },
    };
  }
  if (isType(action, saveCommentActionCreator.success)) {
    return {
      ...state,
      newComment: {
        ...state.newComment,
        isSaving: false,
        saveSuccess: true,
      },
    };
  }
  if (isType(action, saveCommentActionCreator.failed)) {
    return {
      ...state,
      newComment: {
        ...state.newComment,
        isSaving: false,
        saveSuccess: false,
      },
    };
  }

  return state;
};

// export function* followOrUnFollowMetaSaga() {
//   while (true) {
//     const action = yield take(followMetaActionCreator.started.type);
//     try {
//       let result;
//       if (action.payload.follow) {
//         result = yield call(followMeta, action.payload.id);
//       } else {
//         result = yield call(unFollowMeta, action.payload.id);
//       }
//
//       switch (action.payload.type) {
//         case 'ACTION_VALUE':
//           yield put(
//             getActionValueMetaActionCreator.started({ id: action.payload.id })
//           );
//           break;
//         case 'DISCUSSION':
//           yield put(
//             getDiscussionMetaActionCreator.started({ id: action.payload.id })
//           );
//           break;
//         case 'TOPIC':
//           yield put(
//             getTopicMetaActionCreator.started({ id: action.payload.id })
//           );
//           break;
//         default:
//           break;
//       }
//
//       yield put(
//         followMetaActionCreator.success({
//           params: action.payload,
//           result: result,
//         })
//       );
//     } catch (e) {
//       yield put(
//         followMetaActionCreator.failed({
//           params: action.payload,
//           error: wrapApiError(e),
//         })
//       );
//     }
//   }
// }

export function* searchSaga() {
  while (true) {
    const action = yield take(searchActionCreator.started.type);
    try {
      const result = yield call(search, action.payload);

      yield put(
        searchActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
    } catch (e) {
      yield put(
        searchActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export function* searchV2Saga() {
  while (true) {
    const action = yield take(searchV2ActionCreator.started.type);
    try {
      const result = yield call(searchV2, action.payload);

      yield put(
        searchV2ActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
    } catch (e) {
      yield put(
        searchV2ActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

export function* shareSaga() {
  while (true) {
    const action = yield take(shareActionCreator.started.type);
    try {
      const result = yield call(share, action.payload);

      yield put(
        shareActionCreator.success({
          params: action.payload,
          result: result,
        })
      );
    } catch (e) {
      yield put(
        shareActionCreator.failed({
          params: action.payload,
          error: wrapApiError(e),
        })
      );
    }
  }
}

// export function* loadMetaListSaga() {
//   while (true) {
//     const action = yield take(loadMetaList.type);
//
//     yield call(getMetaList);
//
//     yield loadEntity({
//       actionCreator: getMetasActionCreator,
//       entityKey: 'meta',
//       entityPartialUpdateIntervalInSeconds: 0,
//       isAdminRequired: false,
//     });
//   }
// }

const metaListTransformer = (metaDict: {
  [byId: string]: Readonly<IContentMeta>;
}) => _.values(metaDict);

export const metaListSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IContentMeta> },
  ReadonlyArray<IContentMeta>
>(
  state => state.entities.meta.byId,
  metaListTransformer
);

export const metaDictSelector = createSelector<
  RootState,
  { [byId: string]: Readonly<IContentMeta> },
  { [byId: string]: Readonly<IContentMeta> }
>(
  state => state.entities.meta.byId,
  res => res
);

// a selector to return undefined if data is not ready, 10 if total count is 10, and 11 if it's updated..
// export const followedMetasCountSelector = createSelector<
//   RootState,
//   boolean,
//   ReadonlyArray<ITopicItem>,
//   ReadonlyArray<IDiscussionItem>,
//   IActionValueItem[] | undefined,
//   number | undefined
// >(
//   state => state.meta.follow.touched,
//   topicFollowedListSelector,
//   discussionFollowedListSelector,
//   actionValueFollowedListSelector,
//   (
//     touched: boolean,
//     topics: ReadonlyArray<ITopicItem>,
//     discussions: ReadonlyArray<IDiscussionItem>,
//     actionValues: IActionValueItem[] | undefined
//   ) => {
//     if (!touched) {
//       return undefined;
//     }
//
//     return (
//       topics.length +
//       discussions.length +
//       (actionValues ? actionValues.length : 0)
//     );
//   }
// );
