import * as React from 'react';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { MunikumIcons } from '../common/icons';
import moment from 'moment';
import Mutation from 'react-apollo/Mutation';
import gql from 'graphql-tag';
import { IEventInstanceViewModel } from './AnnualCycleRightMenu';
import {
  ColorTheme,
  isReallyEmpty,
  safeInvoke,
  safeInvokeDeprecated,
} from '../common';
import {
  ContentStatus,
  UpdateEventInstanceInput,
  UpdateInstanceStatusVariables,
} from '../../models/types';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { CheckboxLight } from '../basic/Checkbox/CheckboxLight';
import { Avatar } from '../person/Avatar';
import { sizes } from '../common/media';
import { Tooltip } from '../basic/Tooltip/Tooltip';
import { TextNormal } from '../basic/Structural/typography';
import { ToastType } from '../basic/Toaster/Toast';
import { Button } from '../basic';
import { commonLabels } from '../language/commonLabels';
import { Formik, FormikProps } from 'formik';
import { IToastHelpers, ToasterContext } from '../basic/Toaster/ToasterContext';
import { commonMessages } from '../language/commonMessages';
import { convertFromRaw } from 'draft-js';
import { MuTextField } from '../basic/MuEditor/MuTextField';

const TaskDiv = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;
`;

const TaskDate = styled.div`
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 12px;
  display: flex;
  margin-right: 1.5em;
  justify-content: flex-end;
  @media (min-width: ${sizes.larger}px) {
    flex: 1 1 25%;
  }
`;

const TaskText = styled.div`
  color: #333333;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 17px;
  //max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1 auto;
  align-self: center;
`;

const Border = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props: { isFinished: boolean }) =>
    props.isFinished ? '#333' : ''};
  z-index: 1;
  position: absolute;
  bottom: 50%;
`;

const CalanderDiv = styled.a`
  height: 24px;
  width: 29px;
  border: 1px solid #d0d3d4;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  margin-right: 1.25em;
`;

const InfoDiv = styled.div`
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  //width: 220px;
  justify-content: flex-end;
  overflow: hidden;
  flex: 0 0 230px;

  @media (min-width: ${sizes.large}px) {
    flex: 0 0 170px;
  }
  @media (min-width: ${sizes.larger}px) {
    flex: 0 0 270px;
  }
`;

const AvatarDiv = styled.div`
  display: none;
  @media (min-width: ${sizes.larger}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 15%;
    //overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;
    color: rgba(51, 51, 51, 0.7);
    font-family: Lato, sans-serif;
    //font-size: 12px;
  }
  @media (max-width: ${sizes.large}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 15%;
    //overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;
    color: rgba(51, 51, 51, 0.7);
    //font-family: Lato, sans-serif;
    //font-size: 12px;
  }
`;

export const TaskContainer = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: #fff;
  opacity: ${(props: { isFinished: boolean; isHighlighted: boolean }) =>
    props.isFinished ? '0.5' : '1'};

  //border: 1px solid #d0d3d4;

  box-shadow: ${(props: { isFinished: boolean; isHighlighted: boolean }) =>
    props.isHighlighted
      ? '0 2px 7px 0 rgba(0, 0, 0, 0.2)'
      : '0 2px 7px 0 rgba(0, 0, 0, 0.1)'};
  height: 36px;
  margin-bottom: 8px;
  box-sizing: border-box;
  padding: 0.5em;

  overflow: hidden;

  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */

  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    //transform: scale(1.01);
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.2);
  }

  page-break-inside: avoid;
`;

const StarDiv = styled.div`
  transition: 0.2s ease-in-out;
  color: ${(props: { isFavourited: boolean }) =>
    props.isFavourited ? '#FF5C39' : 'lightgray'};
  :hover {
    transform: scale(1.2);
    cursor: pointer;
  }
`;

const MySpecialField = styled.div`
  color: #fff;
  font-size: 22px;
  line-height: 26px;
  font-family: 'Bitstream Vera Sans Mono';
`;

export interface ITaskCompProps {
  eventInstance: IEventInstanceViewModel;
  canWrite: boolean;
  taskId: string;
  year: number;
  // eventInstanceId: string;
  disabled?: boolean;
  style?: CSSProperties;
  checkBoxColor?: string;
  isHighlighted?: boolean;
  isFinishAllTodosClicked?: boolean;
  calendarCode: string;
  me?: any;
  onFavouriteClick?: () => void;

  /**
   * this is dispatched after a task is completed successfully (isDone is set to true)
   */
  onCompleted?: () => void;

  // stupid temp name...
  onRestored?: () => void;

  /**
   * onClick card (open edit task)
   */
  onClick: () => void;
}
const UPDATE_INSTANCE_STATUS = gql`
  mutation UpdateInstanceStatus($input: UpdateEventInstanceInput!) {
    updateEventInstance(input: $input) {
      id
      isDone
      isFavourite
      status
    }
  }
`;

interface ITaskCompUpdateState {
  isFavourite: boolean;
  isDone: boolean;
  // myTodosToSend: Array<Todos>;
  // myInstanceToSend: any;
  noteText: string;
}

interface IAddTaskComp {
  onToastRemove?: () => void;
  eventInstance: IEventInstanceViewModel;
  isDone?: boolean;
}
class AddTaskComp extends React.PureComponent<
  IAddTaskComp & InjectedIntlProps,
  ITaskCompUpdateState
> {
  // constructor(props: any) {
  //   super(props);
  //   this.state = {
  //     isDone: this.props.eventInstance.isDone
  //   };
  // }
  render() {
    const { intl } = this.props;

    return (
      <Mutation
        mutation={UPDATE_INSTANCE_STATUS}
        // optimisticResponse={{
        //   updateEventInstance: {
        //     id: this.props.eventInstance.id,
        //     isDone: this.props.eventInstance.isDone,
        //     note: this.props.eventInstance.note,
        //     __typename: 'EventInstance',
        //
        //   },
        // }}
      >
        {(updateInstanceStatus, { data, client }) => {
          return (
            <Formik
              initialValues={{
                note: this.props.eventInstance.note || '',
              }}
              onSubmit={(values: any) => {
                let noteText = values.note;
                let descText = values.note;
                try {
                  noteText = convertFromRaw(
                    JSON.parse(values.note)
                  ).getPlainText();
                } catch (e) {
                  noteText = values.note;
                }
                try {
                  descText = convertFromRaw(
                    JSON.parse(this.props.eventInstance.description)
                  ).getPlainText();
                } catch (e) {
                  descText = this.props.eventInstance.description;
                }
                const values2: UpdateEventInstanceInput = {
                  id: this.props.eventInstance.id,
                  title: this.props.eventInstance.title,
                  description: this.props.eventInstance.description,
                  descriptionText: descText,
                  startDate: this.props.eventInstance.startDate,
                  endDate:
                    this.props.eventInstance.endDate ||
                    this.props.eventInstance.startDate,
                  status: ContentStatus.PUBLISHED,
                  note: values.note,
                  noteText: noteText,
                  isDone: this.props.isDone,
                  isFavourite: this.props.eventInstance.isFavourite,
                  responsible: this.props.eventInstance.responsible
                    ? this.props.eventInstance.responsible.id
                    : null,
                  reminderDate: this.props.eventInstance.reminderDate,
                };
                const temp2: UpdateInstanceStatusVariables = {
                  input: values2,
                };
                updateInstanceStatus({ variables: temp2 }).then(c => {
                  safeInvokeDeprecated(this.props.onToastRemove);
                });
              }}
              render={(props: FormikProps<any>) => {
                return (
                  <div
                    style={{
                      marginRight: '1em',
                    }}
                  >
                    <TextNormal
                      style={{
                        color: '#fff',
                        fontSize: '16px',
                        marginBottom: '.5em',
                      }}
                    >
                      <FormattedMessage
                        id={'TaskComp.ToastNoteTitle'}
                        defaultMessage={'Do you want to add a note?'}
                        values={{
                          br: <br />,
                        }}
                      />
                    </TextNormal>
                    <MuTextField
                      style={{
                        backgroundColor: '#fff',
                        color: '#333',
                        marginBottom: 0,
                      }}
                      value={props.values.note}
                      onSubmit={value => {
                        props.setFieldValue('note', value);
                      }}
                      placeholder={intl.formatMessage(
                        commonLabels.notesPlaceholder
                      )}
                      doIHaveAccessToAddFiles={false}
                      shouldEditorHaveFiles={false}
                    />
                    <div
                      style={{
                        marginTop: '1em',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <ToasterContext.Consumer>
                        {(helpers: IToastHelpers) => (
                          <>
                            <Button
                              style={{ marginRight: '.5em' }}
                              onClick={() => {
                                safeInvokeDeprecated(this.props.onToastRemove);
                              }}
                              // size={ButtonSize.Small}
                              theme={ColorTheme.White}
                              text={'Nei'}
                            />
                            <Button
                              // size={ButtonSize.Small}
                              text={'Lagre'}
                              onClick={() => {
                                props.submitForm();
                              }}
                            />
                          </>
                        )}
                      </ToasterContext.Consumer>
                    </div>
                  </div>
                );
              }}
            />
          );
        }}
      </Mutation>
    );
  }
}
export const AddTask = injectIntl(AddTaskComp);
class TaskCompComp extends React.PureComponent<
  ITaskCompProps & InjectedIntlProps,
  ITaskCompUpdateState
> {
  constructor(props: ITaskCompProps & InjectedIntlProps) {
    super(props);
    this.state = {
      isFavourite: this.props.eventInstance.isFavourite,
      isDone: this.props.eventInstance.isDone,
      noteText: this.props.eventInstance.note || '',
      // myTodosToSend: [],
      // myInstanceToSend: undefined,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: ITaskCompProps) {
    if (nextProps.eventInstance.isFavourite !== this.state.isFavourite) {
      this.setState({
        isFavourite: nextProps.eventInstance.isFavourite,
      });
    }
    if (nextProps.eventInstance.note !== this.state.noteText) {
      this.setState({
        noteText: nextProps.eventInstance.note,
      });
    }
    if (nextProps.eventInstance.isDone !== this.state.isDone) {
      this.setState({
        isDone: nextProps.eventInstance.isDone,
      });
    }
  }

  render() {
    let me = this.props.me;
    const { intl } = this.props;
    // const { currentTodo } = this.props;
    // const Icon = () => {
    //   if (this.props.eventInstance.isFavourite) {
    //     return (
    //       <svg height="28" width="17">
    //         <path d="M0 0 L17 0 L17 28 L9 22 L0 28 Z" fill="#ff5242" />
    //         <path
    //           d="M9 7 L11 12 L16 12 L12 15 L13 21 L9 17 L5 21 L6 15 L2 12 L7 12 Z"
    //           fill="#fff"
    //         />
    //       </svg>
    //     );
    //   } else {
    //     return (
    //       <svg height="28" width="17">
    //         <path
    //           d="M9 7 L11 12 L16 12 L12 15 L13 21 L9 17 L5 21 L6 15 L2 12 L7 12 Z"
    //           fill="#fff"
    //           stroke="#aaa"
    //         />
    //       </svg>
    //     );
    //   }
    // };

    const isCrapBrowser = false;

    let profilePic: any = undefined;
    if (
      this.props.eventInstance.responsible &&
      this.props.eventInstance.responsible.picture
    ) {
      profilePic =
        'https://www.munikum.no/' +
        this.props.eventInstance.responsible.picture;
    }

    return (
      <>
        <>
          <Mutation
            mutation={UPDATE_INSTANCE_STATUS}
            optimisticResponse={{
              updateEventInstance: {
                id: this.props.eventInstance.id,
                isDone: this.state.isDone,
                isFavourite: this.state.isFavourite,
                status: ContentStatus.PUBLISHED,
                __typename: 'EventInstance',
              },
            }}
          >
            {(updateInstanceStatus, { data, client }) => {
              return (
                <TaskContainer
                  isHighlighted={this.props.isHighlighted || false}
                  style={this.props.style}
                  isFinished={this.state.isDone}
                  onClick={() => {
                    safeInvoke(this.props.onClick);
                  }}
                >
                  <TaskDiv>
                    <ToasterContext.Consumer>
                      {(toastHelper: IToastHelpers) => (
                        <CheckboxLight
                          style={{
                            flex: '0 0 auto',
                          }}
                          color={this.props.checkBoxColor}
                          checked={this.state.isDone}
                          onClick={() => {
                            if (this.props.canWrite) {
                              this.setState(
                                {
                                  isDone: !this.state.isDone,
                                },
                                () => {
                                  if (!this.props.eventInstance.id) {
                                    throw Error('id not defined');
                                  }
                                  let isDescriptionDraftJsFormat = false;
                                  try {
                                    convertFromRaw(
                                      JSON.parse(
                                        this.props.eventInstance.description
                                      )
                                    ).getPlainText();
                                    isDescriptionDraftJsFormat = true;
                                  } catch (e) {
                                    isDescriptionDraftJsFormat = false;
                                  }

                                  const values: UpdateEventInstanceInput = {
                                    id: this.props.eventInstance.id,
                                    title: this.props.eventInstance.title,
                                    description: this.props.eventInstance
                                      .description,
                                    descriptionText: this.props.eventInstance
                                      .description
                                      ? isDescriptionDraftJsFormat
                                        ? convertFromRaw(
                                            JSON.parse(
                                              this.props.eventInstance
                                                .description
                                            )
                                          ).getPlainText()
                                        : ''
                                      : this.props.eventInstance.description,
                                    startDate: this.props.eventInstance
                                      .startDate,
                                    endDate:
                                      this.props.eventInstance.endDate ||
                                      this.props.eventInstance.startDate,
                                    status: ContentStatus.PUBLISHED,
                                    note: this.props.eventInstance.note,
                                    isDone: this.state.isDone,
                                    isFavourite: this.state.isFavourite,
                                    responsible: this.props.eventInstance
                                      .responsible
                                      ? this.props.eventInstance.responsible.id
                                      : null,
                                    reminderDate: this.props.eventInstance
                                      .reminderDate,
                                  };
                                  const temp2: UpdateInstanceStatusVariables = {
                                    input: values,
                                  };
                                  updateInstanceStatus({
                                    variables: temp2,
                                  }).then(() => {
                                    this.state.isDone
                                      ? safeInvokeDeprecated(
                                          this.props.onCompleted
                                        )
                                      : safeInvokeDeprecated(
                                          this.props.onRestored
                                        );
                                  });

                                  if (
                                    this.state.isDone &&
                                    (this.props.eventInstance.note === null ||
                                      this.props.eventInstance.note.length ===
                                        0 ||
                                      isReallyEmpty(
                                        this.props.eventInstance.note
                                      ))
                                  ) {
                                    const myId = toastHelper.addToast({
                                      message: (
                                        <AddTask
                                          isDone={this.state.isDone}
                                          eventInstance={
                                            this.props.eventInstance
                                          }
                                          onToastRemove={() => {
                                            toastHelper.removeToast(myId);
                                          }}
                                        />
                                      ),
                                      theme: ToastType.SUCCESS_BLUE,
                                      leftIcon: MunikumIcons.Info,
                                      rightIcon: MunikumIcons.Cancel,
                                      onClickRightIcon: e => {
                                        toastHelper.removeToast(myId);
                                      },
                                    });
                                  }
                                }
                              );
                            } else {
                              alert(
                                intl.formatMessage(commonMessages.noWriteAccess)
                              );
                            }
                          }}
                        />
                      )}
                    </ToasterContext.Consumer>

                    {/*<Checkbox*/}
                    {/*fieldGroupStyle={{*/}
                    {/*paddingBottom: '0px',*/}
                    {/*}}*/}
                    {/*color={this.props.checkBoxColor}*/}
                    {/*unCheckedColor={this.props.checkBoxColor}*/}
                    {/*borderColor={this.props.checkBoxColor}*/}
                    {/*checked={this.state.isDone}*/}
                    {/*onClick={() => {*/}
                    {/*if (this.state.isDone) {*/}
                    {/*// UNCHECK:*/}
                    {/*this.setState(*/}
                    {/*{*/}
                    {/*isDone: !this.state.isDone,*/}
                    {/*isFinishTemAllOpen: false,*/}
                    {/*},*/}
                    {/*() => {*/}
                    {/*if (!this.props.eventInstance.id) {*/}
                    {/*throw Error('id not defined');*/}
                    {/*}*/}

                    {/*const values: UpdateEventInstanceInput = {*/}
                    {/*id: this.props.eventInstance.id,*/}
                    {/*title: this.props.eventInstance.title,*/}
                    {/*description: this.props.eventInstance.description,*/}
                    {/*startDate: this.props.eventInstance.startDate,*/}
                    {/*endDate:*/}
                    {/*this.props.eventInstance.endDate ||*/}
                    {/*this.props.eventInstance.startDate,*/}
                    {/*status: ContentStatus.PUBLISHED,*/}
                    {/*isDone: this.state.isDone,*/}
                    {/*isFavourite: this.state.isFavourite,*/}
                    {/*responsible: this.props.eventInstance.responsible*/}
                    {/*? this.props.eventInstance.responsible.id*/}
                    {/*: null,*/}
                    {/*reminderDate: this.props.eventInstance.reminderDate,*/}
                    {/*};*/}
                    {/*const temp2: UpdateInstanceStatusVariables = {*/}
                    {/*input: values,*/}
                    {/*};*/}
                    {/*updateInstanceStatus({ variables: temp2 }).then(() => {*/}
                    {/*safeInvoke(this.props.onRestored);*/}
                    {/*});*/}
                    {/*}*/}
                    {/*);*/}
                    {/*} else {*/}
                    {/*// CHECK, well just show dialog:*/}
                    {/*this.setState({*/}
                    {/*isFinishTemAllOpen: true,*/}
                    {/*});*/}
                    {/*}*/}
                    {/*}}*/}
                    {/*/>*/}

                    <TaskText
                      style={{
                        marginLeft: '.5em',
                      }}
                    >
                      {this.state.isDone && (
                        <del
                          style={{
                            textDecoration: 'line-through',
                          }}
                        >
                          {this.props.eventInstance.title}
                        </del>
                      )}

                      {!this.state.isDone && (
                        <Tooltip
                          style={{ fontSize: '16px', maxWidth: '100em' }}
                          content={this.props.eventInstance.title}
                          position={'top-start'}
                        >
                          <div
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            {this.props.eventInstance.title}
                          </div>
                        </Tooltip>
                      )}
                      {/*<Border isFinished={this.state.isDone} />*/}
                    </TaskText>
                  </TaskDiv>
                  <InfoDiv>
                    <AvatarDiv>
                      {!isCrapBrowser && (
                        <Tooltip
                          style={{ fontSize: '16px' }}
                          content={
                            'Ansvarlig:' +
                              ' ' +
                              (this.props.eventInstance.responsible &&
                                this.props.eventInstance.responsible.name) ||
                            this.props.eventInstance.createdBy.name
                          }
                        >
                          <div
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            {profilePic && (
                              <img
                                style={{
                                  marginLeft: '4em',
                                  borderRadius: '50%',
                                }}
                                src={profilePic}
                                width="25px"
                                height="25px"
                              />
                            )}
                            {!profilePic && (
                              <Avatar
                                name={
                                  (this.props.eventInstance.responsible &&
                                    this.props.eventInstance.responsible
                                      .name) ||
                                  this.props.eventInstance.createdBy.name
                                }
                                style={{ marginLeft: '6em' }}
                              />
                            )}
                          </div>
                        </Tooltip>
                      )}
                    </AvatarDiv>
                    <TaskDate>
                      {!isCrapBrowser && (
                        <Tooltip
                          style={{ fontSize: '16px' }}
                          content={
                            'Frist:' +
                            ' ' +
                            moment(
                              this.props.eventInstance.endDate || undefined
                            ).format('dddd Do MMM')
                          }
                        >
                          <div
                            style={{
                              display: 'inline-block',
                            }}
                          >
                            {moment(
                              this.props.eventInstance.endDate || undefined
                            ).format('dddd Do MMM')}
                          </div>
                        </Tooltip>
                      )}
                    </TaskDate>
                    {!isCrapBrowser && (
                      <Tooltip content={'Legg til i Outlook'}>
                        <div
                          style={{
                            display: 'inline-block',
                          }}
                        >
                          <CalanderDiv
                            onClick={(e: any) => e.stopPropagation()}
                            href={
                              process.env.REACT_APP_API +
                              '/meta/calendar?calendarCode=' +
                              this.props.calendarCode +
                              '&metaId=' +
                              this.props.eventInstance.id
                            }
                          >
                            <MunikumIcons.YearWheel />
                          </CalanderDiv>
                        </div>
                      </Tooltip>
                    )}

                    {/*<StarDiv*/}
                    {/*isFavourited={this.state.isFavourite}*/}
                    {/*style={{*/}
                    {/*display: 'flex',*/}
                    {/*alignItems: 'center',*/}
                    {/*justifyContent: 'center',*/}
                    {/*}}*/}
                    {/*>*/}
                    {/*<MunikumIcons.Flag*/}
                    {/*style={{ width: '1em', height: '1em' }}*/}
                    {/*onClick={e => {*/}
                    {/*e.stopPropagation();*/}

                    {/*if (this.props.canWrite) {*/}
                    {/*if (!this.props.eventInstance.id) {*/}
                    {/*return;*/}
                    {/*}*/}

                    {/*this.setState(*/}
                    {/*{*/}
                    {/*isFavourite: !this.state.isFavourite,*/}
                    {/*},*/}
                    {/*() => {*/}
                    {/*const values: UpdateEventInstanceInput = {*/}
                    {/*id: this.props.eventInstance.id || '',*/}
                    {/*title: this.props.eventInstance.title,*/}
                    {/*description: this.props.eventInstance*/}
                    {/*.description,*/}
                    {/*startDate: this.props.eventInstance.startDate,*/}
                    {/*endDate:*/}
                    {/*this.props.eventInstance.endDate ||*/}
                    {/*this.props.eventInstance.startDate,*/}
                    {/*status: ContentStatus.PUBLISHED,*/}
                    {/*isDone: this.state.isDone,*/}
                    {/*isFavourite: this.state.isFavourite,*/}
                    {/*responsible: this.props.eventInstance*/}
                    {/*.responsible*/}
                    {/*? this.props.eventInstance.responsible.id*/}
                    {/*: null,*/}
                    {/*reminderDate: this.props.eventInstance*/}
                    {/*.reminderDate,*/}
                    {/*};*/}
                    {/*const temp2: UpdateInstanceStatusVariables = {*/}
                    {/*input: values,*/}
                    {/*};*/}
                    {/*updateInstanceStatus({*/}
                    {/*variables: temp2,*/}
                    {/*});*/}
                    {/*}*/}
                    {/*);*/}
                    {/*} else {*/}
                    {/*alert(*/}
                    {/*intl.formatMessage(commonMessages.noWriteAccess)*/}
                    {/*);*/}
                    {/*}*/}
                    {/*}}*/}
                    {/*/>*/}
                    {/*</StarDiv>*/}
                  </InfoDiv>
                </TaskContainer>
              );
            }}
          </Mutation>
        </>
      </>
    );
  }
}

export const TaskComp = injectIntl(TaskCompComp);
