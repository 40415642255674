import {
  ActionCreator,
  EmptyActionCreator,
  Failure,
  Meta,
  Success,
  Action as ActionFSA,
  AnyAction,
} from 'typescript-fsa';

/**
 * Custom type (as alternative to typescript-fsa lib)
 */
export interface IAsyncActionCreators<P, S, E> {
  type: string;
  started: ActionCreator<P>;
  success: ActionCreator<Success<P, S>>;
  failed: ActionCreator<Failure<P, E>>;
}

export interface IActionCreatorFactory {
  (type: string, commonMeta?: Meta, error?: boolean): EmptyActionCreator;
  <P>(type: string, commonMeta?: Meta, isError?: boolean): ActionCreator<P>;
  <P>(
    type: string,
    commonMeta?: Meta,
    isError?: (payload: P) => boolean
  ): ActionCreator<P>;

  async<P, S>(
    type: string,
    commonMeta?: Meta
  ): // tslint:disable-next-line
  IAsyncActionCreators<P, S, any>;

  async<P, S, E>(
    type: string,
    commonMeta?: Meta
  ): IAsyncActionCreators<P, S, E>;
}

/**
 * Custom actionCreatorFactory, use this for async-actions (and other actions)
 * @param {string} prefix
 * @param {(payload: any) => boolean} defaultIsError
 * @returns {IActionCreatorFactory}
 */
export function actionCreatorFactory(
  prefix?: string | null,
  // tslint:disable-next-line
  defaultIsError: (payload: any) => boolean = p => p instanceof Error
): IActionCreatorFactory {
  const actionTypes: { [type: string]: boolean } = {};

  const base = prefix ? `${prefix}/` : '';

  function myActionCreator<P>(
    type: string,
    commonMeta?: Meta,
    isError: ((payload: P) => boolean) | boolean = defaultIsError
  ): ActionCreator<P> {
    const fullType = base + type;

    if (process.env.NODE_ENV !== 'production') {
      if (actionTypes[fullType]) {
        throw new Error(`Duplicate action type: ${fullType}`);
      }

      actionTypes[fullType] = true;
    }

    return Object.assign(
      (payload: P, meta?: Meta) => {
        const action: ActionFSA<P> = {
          type: fullType,
          payload,
        };

        const customMeta = {
          actionCreated: new Date(), // this is invoked when actionCreator is creating a action
        };

        action.meta = Object.assign({}, commonMeta, meta, customMeta);

        if (isError && (typeof isError === 'boolean' || isError(payload))) {
          action.error = true;
        }

        return action;
      },
      {
        type: fullType,
        toString: () => fullType,
        match: (action: AnyAction): action is ActionFSA<P> =>
          action.type === fullType,
      }
    );
  }

  function asyncActionCreators<P, S, E>(
    type: string,
    commonMeta?: Meta
  ): IAsyncActionCreators<P, S, E> {
    // here we add metadata on when action was created;
    // const mergedMeta = Object.assign({}, { actionCreated: new Date()}, commonMeta);

    return {
      type: base + type,
      started: myActionCreator<P>(`${type}_STARTED`, commonMeta, false),
      success: myActionCreator<Success<P, S>>(
        `${type}_SUCCESS`,
        commonMeta,
        false
      ),
      failed: myActionCreator<Failure<P, E>>(
        `${type}_FAILED`,
        commonMeta,
        true
      ),
    };
  }

  return Object.assign(myActionCreator, { async: asyncActionCreators });
}
