import { defineMessages } from 'react-intl';

/**
 * A set of reusable error messages
 * @type {{next: {id: string; defaultMessage: string}}}
 */
export const errorMessages = defineMessages({
  notFound: {
    id: 'error.notFound',
    defaultMessage: 'Not found',
  },
  error: {
    id: 'error.error',
    defaultMessage: 'Error',
  },
  unhandledErrorTitle: {
    id: 'error.unhandledErrorTitle',
    defaultMessage: 'An error occurred :-(',
  },
  reload: {
    id: 'error.reload',
    defaultMessage: 'Reload page',
  },
  moreinfo: {
    id: 'error.moreinfo',
    defaultMessage: 'More info',
  },
});
