import { getApiRequest, parsedResponse } from './api';
import { IFileItem } from '../components/basic/UserFile/FileUploaderPicker';
import { ContentStatus, IContentMeta } from './models/contentMeta';
import { IDiscussion } from './models/discussion';

export interface IDiscussionCreateOrUpdateForm {
  id?: string;
  title?: string;
  description?: string;
  body?: string;
  status?: ContentStatus;
  source?: string;
  sectors?: ReadonlyArray<string>;
  validOrganizationTypes?: ReadonlyArray<string>;
  type?: number;
  realms?: ReadonlyArray<string>; // this will be updated on meta
  master?: string;

  forum?: string;
  files?: IFileItem[];
}

export async function getDiscussionMeta(id: string): Promise<IContentMeta> {
  const response = await getApiRequest('/forum/get/' + id, 'GET');
  return await parsedResponse<IContentMeta>(response);
}

export async function getDiscussion(id: string): Promise<IDiscussion> {
  const response = await getApiRequest('/forum/revision/' + id, 'GET');
  return await parsedResponse<IDiscussion>(response);
}

export async function getDiscussionMetaList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<IContentMeta>> {
  if (params) {
    return await parsedResponse<ReadonlyArray<IContentMeta>>(
      await getApiRequest('/forum/list-discussions', 'POST', params)
    );
  }
  const response = await getApiRequest('/forum/list-discussions', 'GET');
  return await parsedResponse<ReadonlyArray<IContentMeta>>(response);
}

export async function getDiscussionList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<IDiscussion>> {
  if (params) {
    return await parsedResponse<ReadonlyArray<IDiscussion>>(
      await getApiRequest('/forum/list', 'POST', params)
    );
  }
  const response = await getApiRequest('/forum/list', 'GET');
  return await parsedResponse<ReadonlyArray<IDiscussion>>(response);
}

export async function updateDiscussion(
  discussion: IDiscussionCreateOrUpdateForm
): Promise<IDiscussion> {
  const response = await getApiRequest('/forum/update', 'POST', {
    ...discussion,
  });
  return await parsedResponse<IDiscussion>(response);
}

export async function createDiscussion(
  discussion: IDiscussionCreateOrUpdateForm
): Promise<IDiscussion> {
  const response = await getApiRequest('/forum/save', 'POST', {
    ...discussion,
  });
  console.log('is response a discussion?');
  return await parsedResponse<IDiscussion>(response);
}

export async function getForumList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<IContentMeta>> {
  if (params) {
    return await parsedResponse<ReadonlyArray<IContentMeta>>(
      await getApiRequest('/forum/list-forums', 'POST', params)
    );
  }
  const response = await getApiRequest('/forum/list-forums', 'GET');
  return await parsedResponse<ReadonlyArray<IContentMeta>>(response);
}
