import { getAccessTokenFromStore, getApiRequest, parsedResponse } from './api';
import { isFunction } from 'util';
import { IEntity } from './models/entity';
import { convertFromRaw } from 'draft-js';

export interface IFile extends IEntity {
  filename: string;
  size: number;
  description?: string;
  contenttype: string;
  lastupdated: Date;
}

export async function getFileList(params?: {
  lastUpdated?: Date;
}): Promise<ReadonlyArray<IFile>> {
  const response = await getApiRequest('/file/listall', 'POST', {
    lastUpdated: params ? params.lastUpdated : undefined,
  });
  return await parsedResponse<ReadonlyArray<IFile>>(response);
}

export interface IUploadResult {
  greatSuccess: boolean;
}

/**
 * Use this function to upload files without redux :-=)
 * I think we can use this for updating files too?
 *
 * TODO: possible memory leak? no unsubscribe??? do we catch all possible errors?
 * @param file
 * @param metaId
 * @param progressCallback
 */
export async function uploadFile(
  file: File,
  metaId: string,
  description: string,
  progressCallback?: (p: number) => void
): Promise<IUploadResult> {
  let apiUrl = process.env.REACT_APP_API;
  let url = apiUrl + '/file/upload';

  const progHandler = (p: ProgressEvent) => {
    if (isFunction(progressCallback)) {
      const temp = p.lengthComputable ? (p.loaded / p.total) * 100.0 : 0;
      progressCallback!(temp);
    }
  };

  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, true);

  xhr.upload.addEventListener('progress', progHandler);
  // xhr.upload.addEventListener('error', e => console.log(e));
  // xhr.upload.addEventListener('abort', e => console.log(e));

  const myPromise = new Promise<IUploadResult>((resolve, reject) => {
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      console.log('onreadystatechanged');

      if (readyState === 4) {
        if (status === 200) {
          resolve({ greatSuccess: true });
        } else {
          // reject(new Error('Failed uploading file...'));
          console.log('hmm...:', readyState, status);
          resolve({ greatSuccess: false });
        }
      }
    };
    xhr.onerror = err => {
      // reject(err);
      console.error(err);
      resolve({ greatSuccess: false });
    };
  });

  myPromise.then(() => {
    console.log('TODO: cleaning up...');
    try {
      xhr.upload.removeEventListener('progress', progHandler);
      xhr.onreadystatechange = null;
      xhr.abort();
    } catch (effe) {
      console.warn('error when cleaning up after uplaod..: ', effe);
    }
  });

  let token = getAccessTokenFromStore();

  let formData = new FormData();
  formData.append('type', file.type);
  formData.append('file', file);
  formData.append('metaid', metaId);
  formData.append('description', description);
  formData.append(
    'descriptionText',
    convertFromRaw(JSON.parse(description)).getPlainText()
  );

  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  xhr.send(formData);

  console.log('started..');

  return myPromise;
}

export async function uploadProfilePicture(
  file: File,
  progressCallback?: (p: number) => void
): Promise<IUploadResult> {
  let apiUrl = process.env.REACT_APP_API;
  let url = apiUrl + '/file/upload-profile-picture';

  const progHandler = (p: ProgressEvent) => {
    if (isFunction(progressCallback)) {
      const temp = p.lengthComputable ? (p.loaded / p.total) * 100.0 : 0;
      progressCallback!(temp);
    }
  };

  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, true);

  xhr.upload.addEventListener('progress', progHandler);

  const myPromise = new Promise<IUploadResult>((resolve, reject) => {
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      console.log('onreadystatechanged');

      if (readyState === 4) {
        if (status === 200) {
          resolve({ greatSuccess: true });
        } else {
          // reject(new Error('Failed uploading file...'));
          console.log('hmm...:', readyState, status);
          resolve({ greatSuccess: false });
        }
      }
    };
    xhr.onerror = err => {
      // reject(err);
      console.error(err);
      resolve({ greatSuccess: false });
    };
  });

  myPromise.then((res: any) => {
    console.log('TODO: cleaning up...', res);
    try {
      xhr.upload.removeEventListener('progress', progHandler);
      xhr.onreadystatechange = null;
      xhr.abort();
    } catch (effe) {
      console.warn('error when cleaning up after uplaod..: ', effe);
    }
  });

  let token = getAccessTokenFromStore();

  let formData = new FormData();
  formData.append('type', file.type);
  formData.append('file', file);
  // formData.append('metaid', metaId);

  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  xhr.send(formData);

  console.log('started..');

  return myPromise;
}
