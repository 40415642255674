import * as React from 'react';
import styled from 'styled-components';
import { CSSProperties } from 'react';
import { Colors, MessageType } from '../../common';
import {
  FaBeer,
  FaCheckCircle,
  FaExclamationCircle,
  FaInfoCircle,
  FaTimesCircle,
} from 'react-icons/fa';

const ValidationMessageContainer = styled.div`
  display: flex;
  margin-top: 0.25em;
  align-items: center;
`;

const TextWrapper = styled.div`
  //background-color: pink;
  font-size: 0.75em;
  font-family: 'Lato', sans-serif;
  line-height: 1.1875em;
  //align-self: flex-start;
  color: ${(props: { color: string }) => props.color};

  margin-left: 0.2em;
`;

const IconWrapper = styled.div`
  //background-color: coral;
  align-self: flex-start;
  width: 1.4em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getMessageTypeColor = (type: MessageType, color?: string) => {
  if (color) {
    return color;
  }

  switch (type) {
    case MessageType.SUCCESS:
      return Colors.BLUE;
    case MessageType.WARNING:
      return Colors.YELLOW;
    case MessageType.ERROR:
      return Colors.RED;
    case MessageType.INFO:
      return Colors.GREY;
    default:
      return Colors.GREY;
  }
};

const getMessageTypeIcon = (type: MessageType, color2?: string) => {
  const color = getMessageTypeColor(type, color2);

  switch (type) {
    case MessageType.SUCCESS:
      return <FaCheckCircle color={color} />;
    case MessageType.WARNING:
      return <FaExclamationCircle color={color} />;
    case MessageType.ERROR:
      return <FaTimesCircle color={color} />;
    case MessageType.INFO:
      return <FaInfoCircle color={color} />;
    default:
      return <FaBeer color={color} />;
  }
};

export interface IValidationMessageProps {
  type: MessageType;
  msg: string;

  // override color...
  color?: string;

  style?: CSSProperties;
}

export class ValidationMessage extends React.Component<
  IValidationMessageProps,
  {}
> {
  render() {
    const { type, msg, color } = this.props;

    const myColor = getMessageTypeColor(type, color);
    const icon = getMessageTypeIcon(type, color);

    return (
      <ValidationMessageContainer style={this.props.style}>
        <IconWrapper>{icon}</IconWrapper>
        <TextWrapper color={myColor}>{msg}</TextWrapper>
      </ValidationMessageContainer>
    );
  }
}
